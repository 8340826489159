import { AppConfigManager } from '@gtn/app-common/config/AppConfigManager';
import { SentryErrorTracker } from '@gtn/app-common/utils/error-tracking/SentryErrorTracker';
import {
  ConfigManager,
  ConfigManagerToken,
} from '@gtn/common/config/ConfigManager';
import {
  ErrorLogLevel,
  IErrorTracker,
} from '@gtn/app-common/utils/error-tracking/IErrorTracker';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { singleton } from 'tsyringe';

@singleton()
export class ErrorTrackingService implements IErrorTracker {
  private trackers?: IErrorTracker[];

  private getTrackers(): IErrorTracker[] {
    if (!this.trackers) {
      this.trackers = [];

      const config = this.getConfig();
      if (config.isProduction) {
        if (config.errorTracking?.sentry) {
          this.trackers.push(
            new SentryErrorTracker(config.errorTracking.sentry.dsn)
          );
        }
      }
    }
    return this.trackers;
  }

  private execTrackers(action: (tracker: IErrorTracker) => void) {
    for (const tracker of this.getTrackers()) {
      action(tracker);
    }
  }

  init(): void {
    this.execTrackers((t) => t.init());

    this.setTag('build-timestamp', this.getConfig().buildTimeString);
  }

  setTag(key: string, value?: string): void {
    this.execTrackers((t) => t.setTag(key, value));
  }

  trackError(error: any): void {
    this.execTrackers((t) => t.trackError(error));
  }

  trackLog(message: string, level: ErrorLogLevel, args?: any[]): void {
    if (args?.length === 0) {
      args = undefined;
    }

    this.execTrackers((t) => t.trackLog(message, level, args));
  }

  sendManuallyTriggeredReport(): void {
    this.execTrackers((t) => t.sendManuallyTriggeredReport());
  }

  private getConfig() {
    return InjectionContainer.resolve<AppConfigManager>(
      ConfigManagerToken
    ).getConfig();
  }
}
