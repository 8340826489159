import {
  navigationFeatureKey,
  navigationReducer,
} from '@gtn/app-common/store/navigation.state';
import { sharedReducers } from '@gtn/common/store/shared.store';
import { combineReducers } from '@reduxjs/toolkit';

export const appCommonReducer = {
  ...sharedReducers,
  [navigationFeatureKey]: navigationReducer,
};

const reducer = combineReducers({ ...appCommonReducer });

export type AppCommonState = ReturnType<typeof reducer>;
