import { getItemStatusIcon, ITEM_STATUS_DISPLAY_VALUES_STUDENT } from '@gtn/app-common/components/example-item-list/ItemStatusDisplay';
import { CreateEditExample } from '@gtn/app-common/components/examples/create-edit-example/CreateEditExample';
import ExampleItemDialog from '@gtn/app-common/components/submit-item/ExampleItemDialog';
import { useAppDispatch } from '@gtn/app-common/store/app.store.hooks';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { GtnSnackbar } from '@gtn/common/components/GtnSnackbar';
import LoadingContainer from '@gtn/common/components/loading-container/LoadingContainer';
import { useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useIsTeacher, useUser } from '@gtn/common/store/user/user.hooks';
import { ThemeManager } from '@gtn/common/theme/ThemeManager';
import { useEventListener } from '@gtn/common/utils/events';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { ProgressState } from '@gtn/common/utils/ProgressState';
import { Utils } from '@gtn/common/utils/Utils';
import { DakoraAPI } from '@gtn/dakora/api/DakoraAPI';
import { PlanningStorageExample } from '@gtn/common/api/model/exacomp';
import { AddLearningPlanContentDialog } from '@gtn/dakora/routes/learning-plans/add-learning-plan-content/AddLearningPlanContentDialog';
import { appDAKORAActions } from '@gtn/dakora/store/DakoraState';
import { useDakoraSelector } from '@gtn/dakora/store/DakoraStore';
import { IconButton, Tooltip } from '@material-ui/core';
import { Add, Delete, History, Lock } from '@material-ui/icons';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './PlanningStorage.module.scss';

export interface PlanningStorageProps {
  courseId: number;
  userId?: number; // undefined if distribution buffer should be used

  title?: string;
  description?: string;

  hideItemStatus?: boolean;
}

export function PlanningStorage(props: PlanningStorageProps) {
  const t = useAppTranslation();
  const dispatch = useAppDispatch();
  const dakoraAPI = InjectionContainer.resolve(DakoraAPI);
  const themeManager = InjectionContainer.resolve(ThemeManager);
  const isTeacher = useIsTeacher();
  const user = useUser();

  const createEditExampleDialog = useGtnDialog(CreateEditExample);
  const exampleItemDialog = useGtnDialog(ExampleItemDialog);

  const draggingExample = useDakoraSelector((state) => state.appdakora.learningPlan.draggingPlanningStorageExample);
  const planningStorageDistributionBuffer = useDakoraSelector((state) => state.appdakora.learningPlan.planningStorageDistributionBuffer);

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isDraggingHover, setIsDraggingHover] = useState<boolean>(false);
  const [saveFailed, setSaveFailed] = React.useState(false);

  const addContentDialog = useGtnDialog(AddLearningPlanContentDialog);

  useEffect(() => {
    if (draggingExample) {
      setIsDragging(false);
      dispatch(appDAKORAActions.setDraggingExercise());
    }
  }, []);

  const { data: planningStorageExamples, progressState, mutate: reloadExamples } = useAPI(dakoraAPI.getPlanningStorageExamples, [props.courseId, props.userId]);

  useEventListener((event) => {
    if (event.type === 'RELOAD_PLANNING_STORAGE') {
      reloadExamples();
    }
  });

  const shownExamples = useMemo(() => {
    if (props.userId != null) {
      return planningStorageExamples;
    }
    return planningStorageDistributionBuffer;
  }, [props.userId, planningStorageExamples, planningStorageDistributionBuffer]);

  const onDragEnter = (e: React.DragEvent<HTMLElement>) => {
    if (!isDragging) {
      e.dataTransfer.dropEffect = 'move';
      setIsDraggingHover(true);
    }
    e.preventDefault();
  };

  const onDragLeave = (e: React.DragEvent<HTMLElement>) => {
    if (isDraggingHover) {
      setIsDraggingHover(false);
    }
  };

  const onDragOver = (e: React.DragEvent<HTMLElement>) => {
    if (!isDragging) {
      e.preventDefault();
    }
  };

  const onDrop = async (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();

    if (draggingExample && !shownExamples?.some((e) => e.exampleid === draggingExample.exampleid)) {
      // if not yet added to this planungsspeicher (schüler oder lehrer)

      try {
        // disabled, example niemals aus dem lehrer planungsspeicher löschen
        // if (!e.ctrlKey && draggingExample.scheduleid) {
        //   // Don't remove example from source if STRG-Key is pressed
        //   await dakoraAPI.deleteScheduledExample(draggingExample.scheduleid);
        // }

        if (props.userId != null) {
          const result = await dakoraAPI.addExampleToPlanningStorage(props.courseId, draggingExample.exampleid, props.userId);
          if (!result.success) {
            await reloadExamples();
          }
        } else {
          await dispatch(appDAKORAActions.addExampleToPlanningStorageDistributionBuffer(draggingExample));
        }
      } catch (e) {
        GtnLogger.warn(e);
      }
    }

    await onDragEnd(e);
    await reloadExamples();
  };

  const onDragStart = (e: React.DragEvent<HTMLElement>) => {
    setIsDragging(true);

    const example = shownExamples?.find((ex) => ex.exampleid === Number((e.target as HTMLElement).id));
    if (example) {
      dispatch(appDAKORAActions.setDraggingExercise({ ...example, userId: props.userId }));
    }
  };

  const onDragEnd = async (e: React.DragEvent<HTMLElement>) => {
    setIsDragging(false);
    dispatch(appDAKORAActions.setDraggingExercise());
  };

  const removeExampleFromPlanningStorage = async (exampleScheduleId: number) => {
    if (props.userId != null) {
      try {
        // hide example immediately, revalidate=false => don't reload from server for now
        await reloadExamples(
          planningStorageExamples?.filter((example) => example.scheduleid != exampleScheduleId),
          { revalidate: false }
        );

        const result = await dakoraAPI.deleteScheduledExample(exampleScheduleId);
        if (result.success) {
          await reloadExamples();
          return;
        }
      } catch (e) {
        GtnLogger.warn(e);
      }
      setSaveFailed(true);
    } else {
      dispatch(appDAKORAActions.removeExampleFromPlanningStorageDistributionBuffer(exampleScheduleId));
    }
  };

  const removeAllExamplesFromPlanningStorage = async () => {
    if (shownExamples?.length) {
      if (props.userId != null) {
        try {
          if (window.confirm(t('planning-storage.confirm-clear'))) {
            const promises = shownExamples.filter((e) => canRemoveExample(e)).map((e) => dakoraAPI.deleteScheduledExample(e.scheduleid));
            const result = await Promise.all(promises);
            if (result.some((r) => !r.success)) {
              setSaveFailed(true);
            }
          } else {
            return;
          }
        } catch (e) {
          GtnLogger.warn(e);
          setSaveFailed(true);
        }
        await reloadExamples();
      } else {
        dispatch(appDAKORAActions.resetPlanningStorageDistributionBuffer());
      }
    }
  };

  function canRemoveExample(example: PlanningStorageExample) {
    if (!isTeacher && example.creatorid && user.profile?.id && example.creatorid !== user.profile?.id) {
      // students who haven't added this example in the planningStorage (=was created by the teacher)
      return false;
    }
    return true;
  }

  const renderExample = (example: PlanningStorageExample) => {
    const itemStatusDisplay = ITEM_STATUS_DISPLAY_VALUES_STUDENT.find((s) => s.statusMapping === example.itemstatus);

    return (
      <div
        key={example.scheduleid}
        id={String(example.exampleid)}
        className={classNames({ [styles.example]: true, [styles.dragSource]: isDragging && draggingExample?.exampleid === example.exampleid, [styles.overdue]: example.is_overdue })}
        style={{ backgroundColor: themeManager.getColorForId(example.courseid, 'grey') }}
        draggable
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      >
        <img src={getItemStatusIcon(example.itemstatus)} className={styles.icon} />
        <div className={styles.textContainer}>
          <p
            className={styles.title}
            onClick={() => {
              if (isTeacher && !props.userId) {
                createEditExampleDialog.open({ exampleId: example.exampleid });
              } else {
                exampleItemDialog.open({ exampleId: example.exampleid, studentId: props.userId });
              }
            }}
          >
            {example.title}
          </p>
          <p className={styles.description}>{[props.hideItemStatus ? null : t(itemStatusDisplay?.textResId ?? ''), example.taxonomies?.[0]?.title].filter(Utils.filterNullOrUndefined).join(' • ')}</p>
        </div>

        {canRemoveExample(example) ? (
          <IconButton className={styles.buttonRemove} size="small" onClick={() => removeExampleFromPlanningStorage(example.scheduleid)}>
            <Delete />
          </IconButton>
        ) : (
          <Lock className={styles.buttonRemove} />
        )}

        {example.is_overdue && (
          <Tooltip title={t('planning-storage.overdue')!}>
            <History style={{ cursor: 'help' }} />
          </Tooltip>
        )}
      </div>
    );
  };

  const title = t('planning-storage.headline', { name: props.title });
  return (
    <>
      <div
        className={classNames(styles.container, !isDragging && draggingExample ? styles.dragHover : undefined)}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        onDragOver={onDragOver}
      >
        <div className={styles.header}>
          <h3 className={styles.title}>{title}</h3>

          {props.userId != null && (
            <IconButton size="small" onClick={() => addContentDialog.open()}>
              <Add />
            </IconButton>
          )}

          <Tooltip title={t('planning-storage.clear') as string}>
            <IconButton size="small" disabled={!shownExamples?.some((e) => canRemoveExample(e))} onClick={() => removeAllExamplesFromPlanningStorage()}>
              <Delete />
            </IconButton>
          </Tooltip>
        </div>

        <LoadingContainer
          state={progressState}
          className={styles.examplesContainer}
          style={{
            alignItems: progressState === ProgressState.Content ? 'stretch' : 'center',
            justifyContent: progressState === ProgressState.Content ? 'flex-start' : 'center',
          }}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
        >
          {props.description && <p className={styles.info}>{props.description}</p>}

          {shownExamples?.map((example) => {
            return renderExample(example);
            // return (
            //   <Tooltip title={props.description as string} enterDelay={isTeacher ? 2000 : 99999} key={`planning-storage-${props.courseId}-${props.userId}-${example.exampleid}`}>
            //     {renderExample(example)}
            //   </Tooltip>
            // );
          })}
        </LoadingContainer>
      </div>

      {props.userId != null && <addContentDialog.Component addToText={title} onSave={() => reloadExamples()} courseId={props.courseId} userId={props.userId} />}

      <exampleItemDialog.Component onSave={reloadExamples} />
      <createEditExampleDialog.Component onSave={reloadExamples} />

      <GtnSnackbar message={t('save-failed')} open={saveFailed} onClose={() => setSaveFailed(false)} />
    </>
  );
}
