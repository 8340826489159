import { MoodleWebservice } from '@gtn/common/api/webservice/MoodleWebservice';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import GtnForm from '@gtn/common/components/forms/GtnForm';
import GtnTextField from '@gtn/common/components/forms/GtnTextField';
import GtnDialog, { GtnDialogProps } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import React, { useState } from 'react';
import * as Yup from 'yup';
import styles from './CreateEditLearningPathDialog.module.scss';

export interface CreateEditLearningPathDialogProps extends GtnDialogProps {
  courseId: number;
  learningPath?: { id: number; title: string; description?: string };
}

interface LearningPathFormValue {
  title: string;
  description?: string;
}

export default function CreateEditLearningPathDialog(props: CreateEditLearningPathDialogProps) {
  const t = useAppTranslation();
  const moodleAPI = InjectionContainer.resolve(MoodleWebservice);

  const [formValues, setFormValues] = useState<LearningPathFormValue>(
    props.learningPath ?? {
      title: '',
    }
  );
  const [error, setError] = React.useState<any>(null);

  const validationSchema = Yup.object().shape<LearningPathFormValue>({
    title: Yup.string().required().label(t('learning-paths.title')),
    description: Yup.string().label(t('learning-paths.description')),
  });

  const onSave = async (values: Partial<LearningPathFormValue>, formHelper) => {
    setError(null);

    try {
      if (props.learningPath == null) {
        await moodleAPI.diggrplus_learningpath_add({
          courseid: props.courseId,
          title: values.title!,
          description: values.description ?? '',
          visible: false,
        });
      } else {
        await moodleAPI.diggrplus_learningpath_update({ id: props.learningPath.id, title: values.title, description: values.description });
      }

      props.onClose?.();
    } catch (exception) {
      setError(exception);
      GtnLogger.warn(exception);
    }

    formHelper.setSubmitting(false);
  };

  return (
    <GtnDialog {...props} title={t('learning-paths.add')}>
      <GtnForm initialValues={formValues} onSubmit={onSave} validationSchema={validationSchema} className={styles.dialogContentContainer}>
        {(formHelper) => (
          <>
            <div className={styles.scrollContainer}>
              <GtnTextField name="title" label={t('learning-paths.title')} />
              <GtnTextField name="description" label={t('learning-paths.description')} multiline rows={4} />
            </div>

            <div className={styles.submitContainer}>
              <p>{error}</p>

              <GtnButton
                type="button"
                actionType="primary"
                label={t('save')}
                disabled={!formHelper.dirty}
                onClick={async () => {
                  await formHelper.setValues({ ...formHelper.values }, true);
                  await formHelper.submitForm();
                }}
              />
            </div>
          </>
        )}
      </GtnForm>
    </GtnDialog>
  );
}
