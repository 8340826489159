import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppCommonState } from '@gtn/app-common/store/app.store';
import { useMemo } from 'react';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { ServerInfoManager } from '../utils/ServerInfoManager';

export const useAppCommonSelector: TypedUseSelectorHook<AppCommonState> = useSelector;

export function useAppDispatch(): any {
  return useDispatch();
}

export function useSelectedCourse() {
  return useAppCommonSelector((state) => state.navigation.selectedCourse);
}

export function useSelectedStudent() {
  return useAppCommonSelector((state) => {
    if (Array.isArray(state.navigation.selectedStudent)) {
      return state.navigation.selectedStudent[0];
    }
    return state.navigation.selectedStudent;
  });
}
export function useSelectedStudents() {
  const selectedStudent = useAppCommonSelector((state) => state.navigation.selectedStudent);

  return useMemo(() => {
    if (Array.isArray(selectedStudent)) {
      return selectedStudent;
    } else if (selectedStudent != null) {
      return [selectedStudent];
    }
    return selectedStudent;
  }, [selectedStudent]);
}

export function useServerInfoManager() {
  const serverInfoManager = InjectionContainer.resolve(ServerInfoManager);

  // also listen for serverInfo changes, so the serverInfoManager returns correct data
  const preferences = useAppCommonSelector((state) => state.preferences);
  const { serverInfo } = preferences;

  return serverInfoManager;
}
