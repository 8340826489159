import classNames from 'classnames';
import React from 'react';
import { User } from '@gtn/app-common/api/model/User';
import { GtnAvatar } from '@gtn/common/components/gtn-avatar/GtnAvatar';
import { StyleProps } from '@gtn/common/components/StyleProps';
import styles from '@gtn/app-common/components/workbook/collaborators-overview/CollaboratorsOverview.module.scss';

const OVERLAY_WIDTH = 4;

export interface CollaboratorsOverviewProps extends StyleProps {
  users?: User[];
  visibleNamesCount?: number;
}

export default function CollaboratorsOverview(props: CollaboratorsOverviewProps) {
  const visibleNamesCount = props.visibleNamesCount ?? 1;

  if (props.users && props.users.length > 0) {
    return (
      <div className={classNames(styles.container, props.className)} style={props.style}>
        <div className={styles.imagesContainer}>
          {props.users.map((user, i) => (
            <GtnAvatar key={i} imageUrl={user.profileimageurl} name={user.fullname} style={{ left: i * -OVERLAY_WIDTH }} className={styles.avatar} />
          ))}
        </div>

        <p className={styles.names} style={{ left: props.users.length * -OVERLAY_WIDTH }}>
          {props.users
            .slice(0, visibleNamesCount)
            .map((user) => user.fullname)
            .join(', ')}{' '}
          {props.users.length > visibleNamesCount && <span>+{props.users.length - visibleNamesCount}</span>}
        </p>
      </div>
    );
  }
  return null;
}
