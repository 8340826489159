import { ExampleAndItemResponse, ItemStatus } from '@gtn/app-common/api/model/ExampleAndItemResponse';
import { ITEM_STATUS_DISPLAY_VALUES_TEACHER } from '@gtn/app-common/components/example-item-list/ItemStatusDisplay';
import { CreateEditExample } from '@gtn/app-common/components/examples/create-edit-example/CreateEditExample';
import styles from '@gtn/app-common/components/example-item-list/example-items-dialog/ExampleItemsDialog.module.scss';
import { ExamplesOverview } from '@gtn/app-common/components/example-item-list/examples-overview/ExamplesOverview';
import { TypeFilter } from '@gtn/app-common/components/workbook/Workbook';
import FilterBar, { Filter } from '@gtn/common/components/filter-bar/FilterBar';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import GtnDialog, { GtnDialogProps, useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import React, { useMemo, useState } from 'react';

export interface ExampleItemsDialogProps {
  exampleId?: number;
  topicId?: number;

  items?: ExampleAndItemResponse[];

  preSelectedStatusFilter?: ItemStatus;
  onItemClicked?: (entry: ExampleAndItemResponse) => void;
}

export function ExampleItemsDialog(props: ExampleItemsDialogProps & GtnDialogProps) {
  const t = useAppTranslation();
  const createEditExampleDialog = useGtnDialog(CreateEditExample);

  const [selectedStatusFilter, setSelectedStatusFilter] = useState<ItemStatus>(props.preSelectedStatusFilter ?? ItemStatus.SUBMITTED);

  const items = useMemo(() => {
    if (props.exampleId) {
      props.items?.forEach((i) => (i.example ??= { id: props.exampleId } as any));
    }
    return props.items?.filter((i) => i.status === selectedStatusFilter);
  }, [selectedStatusFilter, props.items]);

  const statusFilters: Filter<ItemStatus>[] = [
    ...ITEM_STATUS_DISPLAY_VALUES_TEACHER.map((status) => ({
      value: status.statusMapping,
      displayName: t(status.textResId),
      icon: status.color,
    })),
  ];

  const onStatusFilterChanged = (filter: Filter<ItemStatus>) => {
    setSelectedStatusFilter(filter.value);
  };

  return (
    <>
      <GtnDialog title={t('menu.workbook.teacher')} {...props}>
        <div className={styles.dialogContentContainer}>
          <div className={styles.scrollContainer}>
            <h2 className={styles.title}>{props.title}</h2>
            <FilterBar filters={statusFilters} selectedFilter={selectedStatusFilter} onFilterSelected={onStatusFilterChanged} />

            <ExamplesOverview
              statusFilter={selectedStatusFilter}
              typeFilter={props.topicId ? TypeFilter.OwnItems : TypeFilter.Examples}
              items={items}
              onItemClicked={props.onItemClicked}
              showStudentInsteadOfExample={true}
            />
          </div>

          <div className={styles.submitContainer}>
            <GtnButton type="button" onClick={() => createEditExampleDialog.open({ exampleId: props.exampleId })} label={t('create-edit-example.edit-title')} />
            <GtnButton type="button" onClick={props.onClose} actionType="primary" label={t('ok')} />
          </div>
        </div>
      </GtnDialog>

      <createEditExampleDialog.Component />
    </>
  );
}
