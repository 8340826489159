import preval from 'preval.macro';
import { IBaseConfig } from '@gtn/common/config/IBaseConfig';

const [buildTimestamp, buildTimeString] = preval`
var format = require("date-fns-tz/format");
var utcToZonedTime = require("date-fns-tz/utcToZonedTime");

const date = utcToZonedTime(new Date(), "Europe/Vienna");
module.exports = [
  date,
  format(date, 'yyyy-MM-dd HH:mm')
]
`;

export const createPartialBaseConfig = <T extends Partial<IBaseConfig>>(t: T) =>
  t;

export const SHARED_BASE_CONFIG = createPartialBaseConfig({
  buildTimestamp,
  buildTimeString,
  isProduction: process.env['NODE_ENV'] === 'production',
  basePath: process.env['REACT_APP_BASE_PATH'] || './',
  allowChangeMoodleUrl: true,
});
