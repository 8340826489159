import { MoodleFileUploadResponse } from '@gtn/common/api/model/moodle-core/MoodleFileUploadResponse';
import { HttpService } from '@gtn/common/api/webservice/HttpService';
import { encodeUrlParams } from '@gtn/common/api/webservice/Utils';
import { ConfigManager, ConfigManagerToken } from '@gtn/common/config/ConfigManager';
import { SharedState, StoreToken } from '@gtn/common/store/shared.store';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { Utils } from '@gtn/common/utils/Utils';
import { Store } from 'redux';
import { singleton } from 'tsyringe';
import MoodleWebserviceDefinitions from './MoodleWebserviceDefinitions';
import ExapdfWebserviceDefinitions from './ExapdfWebserviceDefinitions';

export interface MoodleError {
  exception: string;
  errorcode: string;
  message: string;
}

@singleton()
class MoodleWebserviceBase {
  private readonly httpService = InjectionContainer.resolve(HttpService);
  private readonly configManager = InjectionContainer.resolve<ConfigManager>(ConfigManagerToken);
  private readonly store = InjectionContainer.resolve<Store<SharedState>>(StoreToken);

  public async callWebservice<T = any>(wsfunction: string, payload: any = {}) {
    try {
      let queryString = '';
      if (process.env.NODE_ENV == 'development') {
        // this does not work, if payload contains arrays or objects
        // is aber egal im dev, weil die Parameter eh mit post gesendet werden
        queryString = encodeUrlParams({
          wsfunction, // in get and in post! (easier for debugging)
          ...payload,
          moodlewsrestformat: 'json',
          wstoken: this.getToken(wsfunction),
        });
      } else {
        queryString = 'wsfunction=' + wsfunction;
      }

      return await this.moodlePostJSON<T>(this.getWebserviceUrl() + '?' + queryString, {
        moodlewsrestformat: 'json',
        wsfunction, // in get and in post! (easier for debugging)
        wstoken: this.getToken(wsfunction),
        ...payload,
      });
    } catch (e: any) {
      if (e.errorcode === 'invalidtoken') {
        console.error('Token invalid or expired! Logging out user...');
        // window.location.href = Utils.normalizeBasePath(this.configManager.getConfig().basePath) + '/logout';
      }
      throw e;
    }
  }

  public async callWebserviceBinary(wsfunction: string, payload: any = {}) {
    return await this.moodlePostBinary(this.getWebserviceUrl() + '?wsfunction=' + wsfunction, {
      moodlewsrestformat: 'json',
      wsfunction, // in get and in post! (easier for debugging)
      wstoken: this.getToken(wsfunction),
      ...payload,
    });
  }

  public async uploadFile(file: File): Promise<MoodleFileUploadResponse> {
    const response = await this.moodlePostJSON<MoodleFileUploadResponse[]>(
      this.getUploadUrl(),
      {
        token: this.store.getState().user.authTokens?.moodleMobile,
        file_box: file,
        filepath: '/',
        filearea: 'draft',
      },
      true
    );

    // Somehow it always returns an array
    return response[0];
  }

  private async moodlePostJSON<T>(url: string, payload: any, useFormData: boolean = false): Promise<T> {
    const response = await this.httpService.postWithJSONResponse<T | MoodleError>(url, payload, { useFormData });

    // moodle errors come with status-code 200, but are an object like
    // {"exception":"invalid_parameter_exception","errorcode":"invalidparameter","message":"Ung\u00fcltiger Parameterwert"}
    if (typeof response === 'object' && (response as MoodleError).exception) {
      // response is an moodle error
      throw response;
    }
    return response as T;
  }

  private async moodlePostBinary(url: string, payload: any, useFormData: boolean = false): Promise<ArrayBuffer> {
    const response = await this.httpService.post(url, payload, { useFormData });

    let jsonResult;
    try {
      // If a json is returned, it's most likely an error since we are expecting a binary
      jsonResult = await response.clone().json();
    } catch (e) {}
    if (typeof jsonResult === 'object' && (jsonResult as MoodleError).exception) {
      // response is an moodle error
      throw jsonResult;
    }

    return response.arrayBuffer();
  }

  private getWebserviceUrl() {
    const state = this.store.getState();

    return `${state.preferences.moodleUrl}/webservice/rest/server.php`;
  }

  private getUploadUrl() {
    const state = this.store.getState();

    return `${state.preferences.moodleUrl}/webservice/upload.php`;
  }

  private getToken(webserviceFunction: string) {
    const moodleTokens = this.store.getState().user.authTokens;

    if (moodleTokens) {
      if (webserviceFunction.match(/^(core_|message_|mod_|assignfeedback)/)) {
        return moodleTokens.moodleMobile;
      } else if (webserviceFunction.match(/^(block_exacomp_|diggrplus_|dakora_|dakoraplus_)/)) {
        return moodleTokens.exacomp;
      } else if (webserviceFunction.match(/^exaport_/)) {
        return moodleTokens.exaport;
      } else {
        throw new Error(`Unknown token type for webservice function: ${webserviceFunction}`);
      }
    } else {
      throw new Error(`No tokens found for webservice function: ${webserviceFunction}`);
    }
  }

  public getWsfunctionUrl(webserviceFunction: string, payload: any) {
    payload = {
      moodlewsrestformat: 'json',
      wsfunction: webserviceFunction,
      wstoken: this.getToken(webserviceFunction),
      ...payload,
    };

    return this.getWebserviceUrl() + '?' + encodeUrlParams(payload);
  }
}

const MoodleWebserviceCombined = ExapdfWebserviceDefinitions(MoodleWebserviceDefinitions(MoodleWebserviceBase));

@singleton()
export class MoodleWebservice extends MoodleWebserviceCombined {}
