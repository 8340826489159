import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { Add, ExpandMore, Remove } from '@material-ui/icons';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ChildrenProps } from '@gtn/common/components/ChildrenProps';
import GtnSearchBar from '@gtn/common/components/search-bar/GtnSearchBar';
import { StyleProps } from '@gtn/common/components/StyleProps';
import styles from '@gtn/common/components/gtn-tree-view/GtnTreeView.module.scss';

export interface GtnTreeNodeProps extends ChildrenProps {
  id: string | number;
  node: React.ReactNode;
  defaultExpanded?: boolean;
  canExpand?: boolean;
}

export function GtnTreeNode(props: GtnTreeNodeProps) {
  const [expanded, setExpanded] = useState(props.defaultExpanded ?? false);

  useEffect(() => {
    if (props.canExpand === false) {
      setExpanded(false);
    }
  }, [props.canExpand]);

  return (
    <Accordion
      key={props.id}
      classes={{ root: styles.accordion, expanded: styles.expanded }}
      defaultExpanded={props.defaultExpanded}
      expanded={expanded}
      TransitionProps={{ unmountOnExit: true }}
      onChange={(e, expanded) => {
        if (props.canExpand !== false) {
          setExpanded(expanded);
        }
      }}
    >
      <AccordionSummary
        expandIcon={<>{props.canExpand !== false ? expanded ? <Remove /> : <Add /> : null}</>}
        classes={{
          root: styles.nodeAccordionSummary,
          content: styles.nodeAccordionSummaryContent,
        }}
        style={{ cursor: props.canExpand !== false ? 'pointer' : 'default' }}
      >
        {props.node}
      </AccordionSummary>
      <AccordionDetails classes={{ root: styles.nodeAccordionDetails }}>{props.children}</AccordionDetails>
    </Accordion>
  );
}

export function GtnTreeParent(props: GtnTreeNodeProps) {
  return (
    <Accordion key={props.id} classes={{ root: styles.accordion, expanded: styles.expanded }} defaultExpanded={props.defaultExpanded} disabled={props.canExpand === false}>
      <AccordionSummary expandIcon={<ExpandMore />} classes={{ root: styles.parentAccordionSummary }}>
        {props.node}
      </AccordionSummary>
      <AccordionDetails classes={{ root: styles.parentAccordionDetails }}>{props.children}</AccordionDetails>
    </Accordion>
  );
}

export interface GtnTreeViewProps extends ChildrenProps, StyleProps {
  showSearchBar?: boolean;
  searchBarLabel?: string;
  searchText?: string;
  onSearchTextChanged?: (searchText: string) => void;
}

export function GtnTreeView(props: GtnTreeViewProps) {
  return (
    <div className={classNames(styles.container, props.className)} style={props.style}>
      {props.showSearchBar && <GtnSearchBar className={styles.searchBar} label={props.searchBarLabel ?? ''} value={props.searchText} onValueChanged={props.onSearchTextChanged} />}

      {props.children}
    </div>
  );
}
