import { Utils } from '@gtn/common/utils/Utils';
import { Store } from 'redux';
import { SharedState, StoreToken } from '@gtn/common/store/shared.store';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { MoodleWebservice } from './webservice/MoodleWebservice';

export class BaseMoodleAPI {
  public readonly moodleWebservice = InjectionContainer.resolve(MoodleWebservice);
  protected readonly store = InjectionContainer.resolve<Store<SharedState>>(StoreToken);

  protected toList(values?: any[], addBrackets: boolean = false): string {
    const result = values?.join(',') ?? '';
    if (addBrackets) {
      return '[' + result + ']';
    }
    return result;
  }

  protected createArrayParams(name: string, values?: any[]) {
    const paramsObj: { [key: string]: string | number } = {};
    if (values?.length) {
      for (let i = 0; i < values.length; i++) {
        paramsObj[`${name}[${i}]`] = String(values[i]);
      }
    }
    return paramsObj;
  }

  protected toBoolean(value?: boolean) {
    return value ? 1 : 0;
  }

  protected toTimestamp(date: Date) {
    return Utils.toTimestamp(date);
  }

  protected getCurrentUserId() {
    return this.store.getState().user.profile?.id ?? 0;
  }
}
