import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import { ExamplesNiveau } from '@gtn/app-common/api/model/ExamplesTreeResponse';
import CourseStudentSelectorSidebar from '@gtn/app-common/components/app-navigation/course-student-selection/sidebar/CourseStudentSelectorSidebar';
import { getItemStatusIcon, ITEM_STATUS_DISPLAY_VALUES_STUDENT } from '@gtn/app-common/components/example-item-list/ItemStatusDisplay';
import {
  ExampleVisibilityManager,
  NavigatableElement,
  NavigatableExamplesTreeDescriptor,
  NavigatableExamplesTreeExample,
  NavigatableExamplesTreeSubject,
  NavigatableExamplesTreeTopic,
} from '@gtn/app-common/components/examples-list/ExampleVisibilityManager';
import { CreateEditExample } from '@gtn/app-common/components/examples/create-edit-example/CreateEditExample';
import { GradingInput } from '@gtn/app-common/components/grading/GradingInput';
import { SidebarCollapse } from '@gtn/app-common/components/sidebar-collapse/SidebarCollapse.component';
import ExampleItemDialog from '@gtn/app-common/components/submit-item/ExampleItemDialog';
import { useAppDispatch, useSelectedCourse, useSelectedStudent } from '@gtn/app-common/store/app.store.hooks';
import { navigationActions } from '@gtn/app-common/store/navigation.state';
import { convertComptypeStringToNumber } from '@gtn/app-common/utils/utils';
import { MoodleWebservice } from '@gtn/common/api/webservice/MoodleWebservice';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import { GTN_SELECT_DEFAULT_OPTION } from '@gtn/common/components/forms/gtn-select/GtnSelect';
import GtnForm from '@gtn/common/components/forms/GtnForm';
import { GtnChooser, GtnChooserOption } from '@gtn/common/components/gtn-chooser/GtnChooser';
import LoadingContainer from '@gtn/common/components/loading-container/LoadingContainerV2';
import { useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import GtnSearchBar from '@gtn/common/components/search-bar/GtnSearchBar';
import { useIsTeacher, useRole, useUser } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { ProgressState } from '@gtn/common/utils/ProgressState';
import { Utils } from '@gtn/common/utils/Utils';
import { OtherTeacherGradingsDialog } from '@gtn/dakora/routes/competence-grids/other-teacher-gradings-dialog/OtherTeacherGradingsDialog';
import PlanningStorageLearningPathsSwitcher from '@gtn/dakora/routes/learning-plans/planning-storage-learning-paths-switcher/PlanningStorageLearningPathsSwitcher';
import { PlanningStorage } from '@gtn/dakora/routes/learning-plans/planning-storage/PlanningStorage';
import { appDAKORAActions } from '@gtn/dakora/store/DakoraState';
import { useDakoraSelector } from '@gtn/dakora/store/DakoraStore';
import { Fab, IconButton, Menu, MenuItem, Popover, Tooltip } from '@material-ui/core';
import { CheckCircle, ChevronRight, ExpandMore, Fullscreen, FullscreenExit, GroupOutlined, MoreVert, PostAdd, RadioButtonUnchecked } from '@material-ui/icons';
import { TreeItem, TreeView } from '@material-ui/lab';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import Split from 'react-split';
import styles from './CompetenceGrids.module.scss';

export enum CompetenceVisibilityFilter {
  ALL = 'all',
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
}

export function CompetenceGrids() {
  const t = useAppTranslation();
  const selectedCourse = useSelectedCourse();
  const selectedStudent = useSelectedStudent();
  const isTeacher = useIsTeacher();
  const role = useRole();
  const user = useUser();
  const dispatch = useAppDispatch();
  const moodleWebservice = InjectionContainer.resolve(MoodleWebservice);

  const planningStorageCollapsed = useDakoraSelector((state) => state.appdakora.planningStorageCollapsed);
  const [fullscreenEnabled, setFullscreenEnabled] = useState<boolean>(false);

  const [selectedTopic, setSelectedTopic] = useState<{ element: HTMLElement; topic: NavigatableExamplesTreeTopic }>();
  const [selectedNiveau, setSelectedNiveau] = useState<{ element: HTMLElement; niveau: ExamplesNiveau }>();
  const [selectedNode, setSelectedNode] = useState<{ element: HTMLElement; node: NavigatableElement }>();

  const [selectedSubject, setSelectedSubject] = useState<NavigatableExamplesTreeSubject>();
  const [visibleTopics, setVisibleTopics] = useState<NavigatableExamplesTreeTopic[]>();
  const [visibleNiveaus, setVisibleNiveaus] = useState<ExamplesNiveau[]>();
  const [searchFilter, setSearchFilter] = useState<string | undefined>(undefined);
  const [niveausSelectorAnchor, setNiveausSelectorAnchor] = React.useState<HTMLElement | null>(null);

  const [isDragging, setIsDragging] = useState<boolean>(false);

  const visibilityFilterButtons: GtnChooserOption[] = [
    {
      title: t('competence-grids.visibility-filter.all'),
      id: CompetenceVisibilityFilter.ALL,
    },
    {
      title: t('competence-grids.visibility-filter.visible'),
      id: CompetenceVisibilityFilter.VISIBLE,
    },
    {
      title: t('competence-grids.visibility-filter.hidden'),
      id: CompetenceVisibilityFilter.HIDDEN,
    },
  ];
  const [visibilityFilter, setVisibilityFilter] = useState<GtnChooserOption>(visibilityFilterButtons[0]);

  const createEditExampleDialog = useGtnDialog(CreateEditExample);
  const exampleAndItemDialog = useGtnDialog(ExampleItemDialog);
  const otherTeacherGradingsDialog = useGtnDialog(OtherTeacherGradingsDialog);

  const appCommonAPI = InjectionContainer.resolve(AppCommonAPI);
  const {
    data: examplesTree,
    progressState,
    mutate: reloadItems,
  } = useAPI(appCommonAPI.getExamplesForCourse, [selectedCourse?.id ?? 0, selectedStudent?.id ?? 0, undefined, 'teacherevaluationcount']);

  const exampleVisibilityManager = new ExampleVisibilityManager(appCommonAPI, selectedCourse?.id || 0);

  // Add references for easier navigation
  const navigableElementsTree = useMemo(() => exampleVisibilityManager.createNavigateableExamplesTree(examplesTree), [examplesTree]);

  useEffect(() => {
    if (navigableElementsTree?.length) {
      let subject = navigableElementsTree[0];
      if (selectedSubject) {
        subject = navigableElementsTree.find((s) => s.id === selectedSubject.id) ?? subject;
      }
      setSelectedSubject(subject);
    }
  }, [navigableElementsTree]);

  useEffect(() => {
    if (selectedSubject) {
      const filteredSubject = Object.assign({}, selectedSubject);
      if (searchFilter) {
        const filteredTopics = Utils.filterTree<NavigatableExamplesTreeTopic, NavigatableExamplesTreeDescriptor>(
          selectedSubject.topics,
          'descriptors',
          (topic) => Utils.includesSearch(topic.title, searchFilter),
          (descriptors) =>
            Utils.filterTree<NavigatableExamplesTreeDescriptor, NavigatableExamplesTreeExample>(
              descriptors,
              'examples',
              (example) => Utils.includesSearch(example.title, searchFilter),
              (examples) => examples.filter((example) => Utils.includesSearch(example.title, searchFilter))
            )
        );
        filteredSubject.topics = filteredTopics!;
        filteredSubject.used_niveaus = filteredSubject.used_niveaus?.filter((niveau) => filteredTopics?.some((topic) => getDescriptorsForNiveau(topic, niveau).length > 0));
      }

      setVisibleTopics(filteredSubject.topics);
      setVisibleNiveaus(filteredSubject.used_niveaus);
    }
  }, [selectedSubject, searchFilter]);

  function getNavigatableElementFormValueName(element: NavigatableElement) {
    return element.type + '-' + element.id;
  }

  function getDescriptorsForNiveau(topic: NavigatableExamplesTreeTopic, niveau: ExamplesNiveau) {
    return topic.descriptors.filter((descriptor) => String(descriptor.niveauid) === niveau.id);
  }

  function isNiveauVisible(niveau: ExamplesNiveau) {
    return visibleNiveaus?.some((n) => n.id === niveau.id);
  }

  function renderTableCell(topic: NavigatableExamplesTreeTopic, niveau: ExamplesNiveau) {
    const descriptorsForNiveau = getDescriptorsForNiveau(topic, niveau);

    return (
      <TreeView className={styles.topicsTree} defaultCollapseIcon={<ExpandMore />} defaultExpanded={['root']} defaultExpandIcon={<ChevronRight />} disableSelection={true}>
        {descriptorsForNiveau?.filter(filterOnVisibility)?.map((topic) => renderDescriptor(topic))}
      </TreeView>
    );
  }

  const saveGrading = async (value: string, element: NavigatableElement) => {
    try {
      if (selectedCourse && (isTeacher ? selectedStudent != null : true)) {
        const grading = value != null && value !== GTN_SELECT_DEFAULT_OPTION ? Number(value) : -1;
        await appCommonAPI.gradeElement({
          id: element.id,
          type: element.type,
          grading: grading,
          userId: isTeacher ? selectedStudent!.id : user.profile!.id,
          courseId: selectedCourse.id,
          role,
        });
        await reloadItems();
      }
    } catch (e) {
      GtnLogger.warn(e);
      // setGradingErrorSnackbarOpened(true);
    }
  };

  const renderGrading = (element: NavigatableElement) => {
    if (isTeacher && selectedStudent === null) {
      return <></>;
    }

    const otherTeacherGradingsCount = isTeacher ? element.teacherevaluationcount || 0 : 0;

    return (
      <div className={styles.gradingsContainer}>
        <GradingInput
          readonly={isTeacher}
          gradingType="student"
          grading={element.studentevaluation}
          name={getNavigatableElementFormValueName(element)}
          className={styles.gradingInput}
          noGradingDisplay={'-'}
          onChange={(event) => {
            event.stopPropagation();
            saveGrading(event.target.value as string, element);
          }}
        />
        <GradingInput
          readonly={!isTeacher}
          gradingType="teacher"
          grading={element.teacherevaluation}
          name={getNavigatableElementFormValueName(element)}
          className={styles.gradingInput}
          noGradingDisplay={'-'}
          onChange={(event) => {
            event.stopPropagation();
            saveGrading(event.target.value as string, element);
          }}
        />
        {otherTeacherGradingsCount > 0 && (
          <div
            className={styles.otherTeacherGradings}
            onClick={() => otherTeacherGradingsDialog.open({ compid: element.id, comptype: convertComptypeStringToNumber(element.type), userid: selectedStudent!.id })}
          >
            <GroupOutlined fontSize="small" />
            {otherTeacherGradingsCount}
          </div>
        )}
      </div>
    );
  };

  function renderDescriptor(descriptor: NavigatableExamplesTreeDescriptor) {
    if (!isTeacher && !descriptor.visible) {
      return <></>;
    }

    return (
      <TreeItem
        classes={{ root: styles.treeItem, label: styles.label, content: styles.treeItemHeader }}
        key={`topic-${descriptor.id}`}
        nodeId={`tree-topic-${descriptor.id}`}
        label={
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            onContextMenu={(e) => {
              if (isTeacher) {
                e.stopPropagation();
                e.preventDefault();
                setSelectedNode({ element: e.target as HTMLElement, node: descriptor });
                return false;
              }
              return true;
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>{descriptor.title}</span>

              {renderGrading(descriptor)}
            </div>

            {isTeacher && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedNode({ element: e.target as HTMLElement, node: descriptor });
                }}
              >
                <MoreVert className={styles.menuIcon} />
              </IconButton>
            )}
          </div>
        }
        style={{ opacity: exampleVisibilityManager.shouldNodeByShownAsVisible(descriptor) ? '1' : '0.6' }}
      >
        {descriptor.childdescriptors?.filter(filterOnVisibility).map((descriptor) => renderDescriptor(descriptor))}

        {descriptor.examples?.length ? (
          descriptor.examples?.filter(filterOnVisibility)?.map((example) => renderExample(example))
        ) : descriptor.childdescriptors?.length ? (
          ''
        ) : (
          <i>{t('competence-grids.no-examples')}</i>
        )}
      </TreeItem>
    );
  }

  function renderExample(example: NavigatableExamplesTreeExample) {
    if (!isTeacher && !example.visible) {
      return <></>;
    }

    const itemStatusDisplay = ITEM_STATUS_DISPLAY_VALUES_STUDENT.find((s) => s.statusMapping === example.status);
    return (
      <div
        key={`example-${example.id}`}
        className={styles.exampleContainer}
        onClick={(e) => onExampleClicked(e, example)}
        style={{ opacity: exampleVisibilityManager.shouldNodeByShownAsVisible(example) ? '1' : '0.6' }}
        draggable
        onDragStart={(e) => onDragStart(e, example)}
        onDragEnd={onDragEnd}
      >
        <>
          <img src={getItemStatusIcon(example.status)} className={styles.exampleIcon} />
          <p className={styles.exampleTitle}>
            {example.title}
            {(!isTeacher || selectedStudent != null) && (
              <>
                <br />
                <small style={{ color: itemStatusDisplay?.color }}>{[t(itemStatusDisplay?.textResId ?? ''), example.taxonomies?.[0]?.title].filter(Utils.filterNullOrUndefined).join(' • ')}</small>
              </>
            )}
          </p>

          {renderGrading(example)}
        </>
      </div>
    );
  }

  function filterOnVisibility(data: { visible?: boolean }) {
    return visibilityFilter?.id === CompetenceVisibilityFilter.VISIBLE ? data.visible : visibilityFilter?.id === CompetenceVisibilityFilter.HIDDEN ? !data.visible : true;
  }

  const onDragStart = (e: React.DragEvent<HTMLElement>, example: NavigatableExamplesTreeExample) => {
    setIsDragging(true);

    dispatch(appDAKORAActions.setDraggingExercise({ exampleid: example.id, userId: selectedStudent?.id } as any));
  };

  const onDragEnd = async (e: React.DragEvent<HTMLElement>) => {
    setIsDragging(false);
    dispatch(appDAKORAActions.setDraggingExercise());
  };

  async function onExampleClicked(e: React.MouseEvent<HTMLElement>, example: NavigatableExamplesTreeExample) {
    if (isTeacher) {
      if (selectedStudent == null) {
        createEditExampleDialog.open({ exampleId: example.id });
        return;
      }
    }

    exampleAndItemDialog.open({ exampleId: example.id, studentId: selectedStudent?.id });
  }

  function renderToolbar() {
    return (
      <div className={styles.toolbar}>
        {!!examplesTree?.length && (
          <GtnChooser
            options={examplesTree.map((subject) => ({ id: subject.id, title: subject.title, data: subject }))}
            label={t('competence-grids.title')}
            selected={selectedSubject}
            onSelected={(o) => setSelectedSubject(o.data as NavigatableExamplesTreeSubject)}
          />
        )}
        <div className={styles.space} />
        {progressState === ProgressState.Content && selectedSubject && (
          <>
            {/*{isTeacher && <GtnSwitchButtons selected={visibilityFilter} onSelected={(option) => setVisibilityFilter(option)} options={visibilityFilterButtons} />}*/}
            <GtnSearchBar className={styles.searchBar} label={'workbook.search'} value={searchFilter} onValueChanged={setSearchFilter} />
            <GtnButton
              label={t('competence-grids.number-of-niveaus', { visibleCount: visibleNiveaus?.length, totalCount: selectedSubject.used_niveaus?.length })}
              onClick={(event) => setNiveausSelectorAnchor(event.currentTarget)}
            />

            <Tooltip title={t(fullscreenEnabled ? 'competence-grids.fullscreen.disable' : 'competence-grids.fullscreen.enable') as string}>
              <IconButton
                onClick={() => {
                  if (!fullscreenEnabled) {
                    dispatch(appDAKORAActions.setPlanningStorageCollapsed(true));
                    dispatch(navigationActions.setCourseStudentDrawerOpened(false));
                  }
                  setFullscreenEnabled(!fullscreenEnabled);
                }}
              >
                {fullscreenEnabled ? <FullscreenExit /> : <Fullscreen />}
              </IconButton>
            </Tooltip>
          </>
        )}
      </div>
    );
  }

  function renderTable() {
    return (
      <LoadingContainer state={progressState} emptyText={t('list-empty')} className={styles.competenceTableContainer}>
        <GtnForm
          initialValues={{
            // Hack: wenn sich der initialValue ändert (Formik macht ein deep compare) wird die Form erneut initialisiert
            // => wenn sich Kurs oder Student ändert, dann die Form Werte löschen!
            // würde auch mit resetForm() ggf. funktionieren, allerdings finde ich die "initialValues" Variante sauberer
            key: `${selectedCourse?.id} ${selectedStudent?.id}`,
          }}
        >
          {selectedSubject && (
            <table className={styles.competenceTable}>
              <tr>
                <th className={styles.topicHeaderCell}></th>
                {visibleNiveaus?.map((niveau) => (
                  <th className={styles.niveauHeaderCell}>
                    <div className={styles.headerClickContainer} onClick={(e) => setSelectedNiveau({ element: e.target as HTMLElement, niveau })}>
                      <span>{niveau.title}</span>
                      <MoreVert className={styles.menuIcon} />
                    </div>
                  </th>
                ))}
              </tr>

              {visibleTopics?.map((topic) => (
                <tr key={`table-row-${topic.id}`}>
                  <th className={styles.topicHeaderCell}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 4 }}>
                      <div className={styles.headerClickContainer} onClick={(e) => setSelectedTopic({ element: e.target as HTMLElement, topic })}>
                        <span>{topic.title}</span>
                        <MoreVert className={styles.menuIcon} />
                      </div>
                      {renderGrading(topic)}
                    </div>
                  </th>

                  {visibleNiveaus?.map((niveau) => (
                    <td className={styles.cell}>{renderTableCell(topic, niveau)}</td>
                  ))}
                </tr>
              ))}
            </table>
          )}
        </GtnForm>
      </LoadingContainer>
    );
  }

  return (
    <CourseStudentSelectorSidebar
      selectionMode={isTeacher ? 'single-and-all-students' : 'course-only'}
      mainContainerStyle={{ style: { padding: 0 } }}
      className={fullscreenEnabled ? styles.fullscreen : undefined}
    >
      {selectedCourse?.id && (
        <>
          {renderToolbar()}

          <Split
            className={classNames('split horizontal', styles.mainSplit)}
            direction="horizontal"
            gutterSize={2}
            sizes={[75, 25]}
            minSize={40}
            maxSize={[Infinity, 500]}
            expandToMin={false}
            collapsed={planningStorageCollapsed ? 1 : undefined}
          >
            {renderTable()}

            <SidebarCollapse
              title={t('planning-storage.title')}
              collapsed={planningStorageCollapsed}
              setCollapsed={(collapsed) => dispatch(appDAKORAActions.setPlanningStorageCollapsed(collapsed))}
              minSize={200}
              collapseSide="right"
              style={{ width: '100%' }}
            >
              <PlanningStorageLearningPathsSwitcher planningStorageUserId={selectedStudent == null ? 0 : selectedStudent?.id} hideDescription />
            </SidebarCollapse>
          </Split>

          {isTeacher && (
            <Fab className={styles.createExampleButton} variant="extended" color="secondary" onClick={() => createEditExampleDialog.open()}>
              <PostAdd className={styles.createExampleButtonIcon} />
              <span className={styles.createExampleButtonText}>{t('examples.add')}</span>
            </Fab>
          )}

          <Menu anchorEl={selectedTopic?.element} keepMounted open={selectedTopic != null} onClose={() => setSelectedTopic(undefined)}>
            <MenuItem
              disabled={visibleTopics && visibleTopics.length <= 1}
              onClick={() => {
                setVisibleTopics([selectedTopic!.topic]);
                setSelectedTopic(undefined);
              }}
            >
              {t('competence-grids.show-only-this-topic')}
            </MenuItem>
            {/*<MenuItem*/}
            {/*  disabled={visibleTopics && visibleTopics.length <= 1}*/}
            {/*  onClick={() => {*/}
            {/*    setVisibleTopics(visibleTopics?.filter((t) => t.id !== selectedTopic!.topic.id));*/}
            {/*    setSelectedTopic(undefined);*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {t('competence-grids.hide-topic')}*/}
            {/*</MenuItem>*/}
          </Menu>

          <Menu anchorEl={selectedNiveau?.element} keepMounted open={selectedNiveau != null} onClose={() => setSelectedNiveau(undefined)}>
            <MenuItem
              disabled={visibleNiveaus && visibleNiveaus.length <= 1}
              onClick={() => {
                setVisibleNiveaus([selectedNiveau!.niveau]);
                setSelectedNiveau(undefined);
              }}
            >
              {t('competence-grids.show-only-this-niveau')}
            </MenuItem>
            {/*<MenuItem*/}
            {/*  disabled={visibleNiveaus && visibleNiveaus.length <= 1}*/}
            {/*  onClick={() => {*/}
            {/*    setVisibleNiveaus(visibleNiveaus?.filter((t) => t.id !== selectedNiveau!.niveau.id));*/}
            {/*    setSelectedNiveau(undefined);*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {t('competence-grids.hide-niveau')}*/}
            {/*</MenuItem>*/}
          </Menu>

          {selectedNode && (
            <Menu anchorEl={selectedNode.element} keepMounted open onClose={() => setSelectedNode(undefined)}>
              {/*<MenuItem*/}
              {/*  disabled={selectedNode.node.used}*/}
              {/*  onClick={async () => {*/}
              {/*    await exampleVisibilityManager.toggleNodeVisibility(selectedNode.node);*/}
              {/*    setSelectedNode(undefined);*/}
              {/*    reloadItems();*/}
              {/*  }}*/}
              {/*>*/}
              {/*  {t(selectedNode.node.visible ? 'competence-grids.hide' : 'competence-grids.show')}*/}
              {/*</MenuItem>*/}
              {selectedNode.node.type === 'example' && (
                <MenuItem
                  onClick={() => {
                    createEditExampleDialog.open({ exampleId: (selectedNode.node as NavigatableExamplesTreeExample).id });
                    setSelectedNode(undefined);
                  }}
                >
                  {t('create-edit-example.edit-title')}
                </MenuItem>
              )}
              {selectedNode.node.type !== 'example' && (
                <MenuItem
                  onClick={() => {
                    const descriptor = selectedNode.node as NavigatableExamplesTreeDescriptor;
                    createEditExampleDialog.open({ preSelectedDescriptors: [{ id: descriptor.id, title: descriptor.title }] });
                    setSelectedNode(undefined);
                  }}
                >
                  {t('examples.add')}
                </MenuItem>
              )}
            </Menu>
          )}

          <Popover
            open={niveausSelectorAnchor != null}
            onClose={() => setNiveausSelectorAnchor(null)}
            anchorEl={niveausSelectorAnchor}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <div
              className={styles.niveauItem}
              style={{ borderBottom: '1px solid gray' }}
              onClick={() => {
                setVisibleNiveaus(selectedSubject?.used_niveaus);
              }}
            >
              <h3>{t('competence-grids.show-all-niveaus')}</h3>
            </div>

            {selectedSubject?.used_niveaus?.map((niveau) => (
              <div
                key={niveau.id}
                className={styles.niveauItem}
                onClick={() => {
                  if (isNiveauVisible(niveau)) {
                    setVisibleNiveaus(visibleNiveaus?.filter((n) => n.id !== niveau.id));
                  } else {
                    setVisibleNiveaus([...(visibleNiveaus ?? []), niveau]);
                  }
                }}
              >
                <div className={styles.checkIcon}>{isNiveauVisible(niveau) ? <CheckCircle /> : <RadioButtonUnchecked />}</div>
                <h3>{niveau.title}</h3>
              </div>
            ))}
          </Popover>
        </>
      )}

      <exampleAndItemDialog.Component onSave={reloadItems} />
      <otherTeacherGradingsDialog.Component />

      <createEditExampleDialog.Component onSave={reloadItems} />
    </CourseStudentSelectorSidebar>
  );
}
