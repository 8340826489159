import CourseStudentSelectorSidebar from '@gtn/app-common/components/app-navigation/course-student-selection/sidebar/CourseStudentSelectorSidebar';
import { SidebarCollapse } from '@gtn/app-common/components/sidebar-collapse/SidebarCollapse.component';
import { useAppDispatch, useSelectedCourse, useSelectedStudents } from '@gtn/app-common/store/app.store.hooks';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import { GtnSnackbar } from '@gtn/common/components/GtnSnackbar';
import { useIsTeacher, useRole } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { DakoraAPI } from '@gtn/dakora/api/DakoraAPI';
import { ChooseLearningPathExample } from '@gtn/dakora/routes/learning-paths/choose-learning-path-example/ChooseLearningPathExample';
import { PlanningCalendar } from '@gtn/dakora/routes/learning-plans/planning-calendar/PlanningCalendar';
import PlanningStorageLearningPathsSwitcher from '@gtn/dakora/routes/learning-plans/planning-storage-learning-paths-switcher/PlanningStorageLearningPathsSwitcher';
import { PlanningStorage } from '@gtn/dakora/routes/learning-plans/planning-storage/PlanningStorage';
import { appDAKORAActions } from '@gtn/dakora/store/DakoraState';
import { useDakoraSelector } from '@gtn/dakora/store/DakoraStore';
import { IconButton, Tooltip } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import classNames from 'classnames';
import React, { useState } from 'react';
import Split from 'react-split';
import styles from './LearningPlans.module.scss';

export function LearningPlans() {
  const t = useAppTranslation();
  const isTeacher = useIsTeacher();
  const role = useRole();
  const dispatch = useAppDispatch();
  const dakoraAPI = InjectionContainer.resolve(DakoraAPI);

  const selectedCourse = useSelectedCourse();
  const selectedStudents = useSelectedStudents();

  const planningStorageCollapsed = useDakoraSelector((state) => state.appdakora.planningStorageCollapsed);
  const planningStorageDistributionBuffer = useDakoraSelector((state) => state.appdakora.learningPlan.planningStorageDistributionBuffer);

  const [createPlanModeActive, setCreatePlanModeActive] = useState(false);
  const [saveFailed, setSaveFailed] = useState(false);
  const [distributeToStudentsResult, setDistributeToStudentsResult] = useState<number | undefined>(undefined);

  const distributeScheduleToSelectedStudents = async () => {
    if (selectedStudents && selectedCourse) {
      try {
        const result = await dakoraAPI.distributeMyScheduleToStudentSchedules(
          selectedCourse.id,
          selectedStudents?.map((s) => s.id)
        );
        if (result.success) {
          setDistributeToStudentsResult(selectedStudents?.length);
        }
      } catch (e) {
        GtnLogger.warn(e);
        setSaveFailed(true);
      }
    }
  };

  const distributePlanningStorageToSelectedStudents = async () => {
    if (selectedStudents && selectedCourse && planningStorageDistributionBuffer?.length) {
      try {
        const result = await dakoraAPI.distributeExamplesToStudentsPlanningStorage(
          selectedCourse.id,
          planningStorageDistributionBuffer.map((e) => e.exampleid),
          selectedStudents?.map((s) => s.id)
        );
        if (result.success) {
          dispatch(appDAKORAActions.resetPlanningStorageDistributionBuffer());
          setDistributeToStudentsResult(selectedStudents?.length);
        }
      } catch (e) {
        GtnLogger.warn(e);
        setSaveFailed(true);
      }
    }
  };

  return (
    <CourseStudentSelectorSidebar selectionMode={isTeacher ? (createPlanModeActive ? 'multiple-students' : 'single-student-only') : 'course-only'} mainContainerStyle={{ style: { padding: 0 } }}>
      {selectedCourse?.id != null && (
        <div className={styles.container}>
          {isTeacher && !createPlanModeActive && (
            <div className={styles.toolbar}>
              {selectedStudents?.[0] && <h3 className={styles.title}>{t('learning-plans.headline', { name: selectedStudents[0].fullname })}</h3>}
              <GtnButton label={t('learning-plans.create-new-plan')} actionType="secondary" onClick={() => setCreatePlanModeActive(true)} />
            </div>
          )}

          {isTeacher && createPlanModeActive && (
            <div className={styles.multiSelectToolbar}>
              <Tooltip title={t('cancel') as string}>
                <IconButton size="small" onClick={() => setCreatePlanModeActive(false)}>
                  <Close />
                </IconButton>
              </Tooltip>

              <div className={styles.textContainer}>
                <h3>{t('learning-plans.distribute.description')}</h3>
                <p className={styles.subtitle}>{t('learning-plans.distribute.select-students')}</p>
              </div>

              <GtnButton
                disabled={selectedStudents == null || selectedStudents.length === 0}
                label={t('learning-plans.distribute.planning-storage-and-learning-plan')}
                onClick={async () => {
                  await distributePlanningStorageToSelectedStudents();
                  await distributeScheduleToSelectedStudents();
                  setCreatePlanModeActive(false);
                }}
              />
            </div>
          )}
          <Split
            className={classNames('split horizontal', styles.mainSplit)}
            direction="horizontal"
            gutterSize={2}
            sizes={[75, 25]}
            minSize={40}
            maxSize={[Infinity, 500]}
            expandToMin={false}
            collapsed={planningStorageCollapsed ? 1 : undefined}
          >
            <PlanningCalendar className={styles.calendar} courseId={selectedCourse.id} userId={createPlanModeActive ? 0 : selectedStudents?.[0]?.id ?? 0} />

            <SidebarCollapse
              title={t('planning-storage.headline', { name: t(`planning-storage.${role}.title`) })}
              collapsed={planningStorageCollapsed}
              setCollapsed={(collapsed) => dispatch(appDAKORAActions.setPlanningStorageCollapsed(collapsed))}
              minSize={100}
              collapseSide="right"
            >
              <Split className={classNames('split vertical', styles.planningStorageContainer)} direction="vertical" gutterSize={2}>
                <PlanningStorageLearningPathsSwitcher planningStorageUserId={0} />

                {isTeacher && (
                  <PlanningStorage
                    courseId={selectedCourse.id}
                    userId={createPlanModeActive ? undefined : selectedStudents?.[0]?.id}
                    title={createPlanModeActive ? t('planning-storage.students.title') : selectedStudents?.[0]?.fullname}
                    description={t('planning-storage.students.description')}
                  />
                )}
              </Split>
            </SidebarCollapse>
          </Split>
        </div>
      )}

      <GtnSnackbar
        message={t(`learning-plans.distribute.success`, { studentCount: distributeToStudentsResult ?? 0 })}
        open={distributeToStudentsResult != null}
        onClose={() => setDistributeToStudentsResult(undefined)}
      />
      <GtnSnackbar message={t('save-failed')} open={saveFailed} onClose={() => setSaveFailed(false)} />
    </CourseStudentSelectorSidebar>
  );
}
