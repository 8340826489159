import { ConfigManagerToken } from '@gtn/common/config/ConfigManager';
import React from 'react';
import { AppConfigManager } from '@gtn/app-common/config/AppConfigManager';
import { useAppDispatch, useAppCommonSelector, useSelectedCourse } from '@gtn/app-common/store/app.store.hooks';
import { navigationActions } from '@gtn/app-common/store/navigation.state';
import { useShowInlineCourseStudentSelector } from '@gtn/app-common/utils/AppHookUtils';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import styles from '@gtn/app-common/components/app-navigation/course-student-selection/ChooseCourseInfo.module.scss';
import { LoadingIndicatorOverlay } from '@gtn/common/components/LoadingIndicator';

export function CourseProblemInfo() {
  const t = useAppTranslation();
  const dispatch = useAppDispatch();

  const coursesLoaded = useAppCommonSelector((state) => state.navigation.coursesLoaded);

  const selectedCourse = useSelectedCourse();
  const role = useAppCommonSelector((state) => state.user.profile?.role);
  const showInlineCourseStudentSelector = useShowInlineCourseStudentSelector();

  const configManager = InjectionContainer.resolve<AppConfigManager>(ConfigManagerToken);

  if (!coursesLoaded) {
    return <LoadingIndicatorOverlay />;
  }
  
  const getErrorText = () => {
    if (!selectedCourse) {
      return 'navigation.choose-course';
    } else if (selectedCourse?.exacompActive === false) {
      return 'navigation.exacomp-not-activated';
    } else if (selectedCourse?.role !== role) {
      return 'navigation.no-teacher-role';
    }
    return '';
  };

  return (
    <div className={styles.chooseCourseContainer}>
      <img src={configManager.getAssetPath('img/logo192x192.png')} />
      <h3>{t(getErrorText())}</h3>
      <br />
      {!showInlineCourseStudentSelector && <GtnButton label={t('navigation.choose-course-short')} onClick={() => dispatch(navigationActions.setCourseStudentDrawerOpened(true))} />}
    </div>
  );
}
