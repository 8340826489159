import { diggrplus_get_config_returns } from '@gtn/common/api/webservice/MoodleWebserviceDefinitions';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PreferencesState {
	moodleUrl?: string;
	language?: string;
	initialQueryParams?: string;
	unusedEnrolCode?: string;
	pspdfkitAnnotationPresets?: any;
	redirectAfterLogin?: any;
	serverInfo?: diggrplus_get_config_returns
}

const initialState: PreferencesState = {};

export const preferencesFeatureKey = 'preferences';

const preferencesSlicer = createSlice({
	name: preferencesFeatureKey,
	initialState,
	reducers: {
		set: (state, action: PayloadAction<Partial<PreferencesState>>): PreferencesState => ({
			...state,
			...action.payload,
		}),
	},
});

export const preferencesActions = preferencesSlicer.actions;
export const preferencesReducer = preferencesSlicer.reducer;
