import {
  CommonRoutingParams,
  CommonRoutingPaths,
} from '@gtn/app-common/routes/AppCommonRouting';
import { ThemeManager } from '@gtn/common/theme/ThemeManager';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { Utils } from '@gtn/common/utils/Utils';
import * as microsoftTeams from '@microsoft/teams-js';
import { singleton } from 'tsyringe';

export interface MSTeamsTabSettings {
  url: string;
  websiteUrl?: string;
}

@singleton()
export class MSTeamsManager {
  private readonly themeManager = InjectionContainer.resolve(ThemeManager);

  private basePath?: string;
  private isInitialized = false;

  public init(basePath?: string) {
    this.basePath = basePath;

    microsoftTeams.initialize(() => (this.isInitialized = true));
  }

  public isInMsTeams() {
    return this.isInitialized;
  }

  public showLoginDialog(
    successCallback?: (result?: string) => void,
    failureCallback?: (reason?: string) => void,
    url?: string
  ) {
    const basePath = Utils.normalizeBasePath(this.basePath ?? '');

    const params = new URLSearchParams(window.location.search);
    params.set(CommonRoutingParams.MS_TEAMS_NOTIFY_AUTH_SUCCESS, String(true));

    const loginUrl =
      url ??
      `${document.location.origin + basePath}/${
        CommonRoutingPaths.MS_TEAMS_SSO_START
      }?${params.toString()}`;
    GtnLogger.log('opening login dialog: ' + loginUrl);
    microsoftTeams.authentication.authenticate({
      url: loginUrl,
      width: 600,
      height: 535,
      successCallback: (result) => {
        GtnLogger.log('Teams login successful!');
        successCallback?.(result);
      },
      failureCallback: (reason) => {
        GtnLogger.warn(`Teams login failed (reason: ${reason})!`);
        failureCallback?.(reason);
      },
    });
  }

  public getContext() {
    return new Promise<microsoftTeams.Context>((resolve, reject) => {
      if (this.isInitialized) {
        microsoftTeams.getContext((context) => resolve(context));
      } else {
        reject();
      }
    });
  }

  public setConfigurationPageSaveHandler(
    getSettings: () => MSTeamsTabSettings
  ): void {
    if (this.isInitialized) {
      microsoftTeams.settings.registerOnSaveHandler((evt) => {
        this.setSettings(getSettings());
        evt.notifySuccess();
      });
    }
  }

  public setConfigurationPageValidity(valid: boolean): void {
    if (this.isInitialized) {
      microsoftTeams.settings.setValidityState(valid);
    }
  }

  public setSettings(settings: MSTeamsTabSettings) {
    if (this.isInitialized) {
      GtnLogger.log('MS Teams setting tab settings: %o', settings);
      const msTeamsTabSettings = {
        websiteUrl: settings.url,
        contentUrl: settings.websiteUrl || settings.url,
        entityId: 'diggr-plus', // todo change?
        suggestedDisplayName: this.themeManager.theme?.appName,
      };
      microsoftTeams.settings.setSettings(
        msTeamsTabSettings,
        (status, reason) =>
          GtnLogger.log(
            `Saving settings: ${status} (${reason}) %o`,
            msTeamsTabSettings
          )
      );
    }
  }

  public getSettings(
    callback: (settings: microsoftTeams.settings.Settings) => void
  ) {
    if (this.isInitialized) {
      microsoftTeams.settings.getSettings(callback);
    }
  }

  public getAuthToken() {
    if (this.isInitialized) {
      return new Promise<string>((resolve, reject) => {
        microsoftTeams.authentication.getAuthToken({
          successCallback: (token) => resolve(token),
          failureCallback: (reason) => {
            GtnLogger.warn('Get Teams auth token failed: ' + reason);
            reject();
          },
        });
      });
    }
    return undefined;
  }

  notifyAuthSuccess() {
    microsoftTeams.authentication.notifySuccess();
  }

  public showFilePreview(objectURL: string) {
    if (this.isInitialized) {
      microsoftTeams.openFilePreview({
        type: 'pdf',
        title: 'pdf title',
        objectUrl: objectURL,
        entityId: objectURL,
      });
    }
  }
}
