import { useEffect, useMemo, useState } from 'react';
import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import { CourseProblemInfo } from '@gtn/app-common/components/app-navigation/course-student-selection/CourseProblemInfo';
import CourseStudentSelectorSidebar from '@gtn/app-common/components/app-navigation/course-student-selection/sidebar/CourseStudentSelectorSidebar';
import { useAppCommonSelector } from '@gtn/app-common/store/app.store.hooks';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { Utils } from '@gtn/common/utils/Utils';
import { CommonRoutingParams, CommonRoutingPaths } from '../AppCommonRouting';
import { StudentEnrolCode } from '@gtn/app-common/api/model/StudentEnrolCode';
import { AppConfigManager } from '@gtn/app-common/config/AppConfigManager';
import { ConfigManagerToken } from '@gtn/common/config/ConfigManager';
import { ProgressState } from '@gtn/common/utils/ProgressState';
import QRCode from 'react-qr-code';
import LoadingContainerV2 from '@gtn/common/components/loading-container/LoadingContainerV2';
import styles from './CreateEnrolcode.module.scss';

export default function CreateEnrolcode() {
  const t = useAppTranslation();
  const configManager = InjectionContainer.resolve<AppConfigManager>(ConfigManagerToken);
  const config = configManager.getConfig();
  const preferences = useAppCommonSelector((state) => state.preferences);

  const selectedCourse = useAppCommonSelector((state) => state.navigation.selectedCourse);
  const courseId = selectedCourse?.id || 0;

  const appCommonAPI = InjectionContainer.resolve(AppCommonAPI);
  const { data: getEnrolcode, progressState: getEnrolCodeProgressState } = useAPI(appCommonAPI.getStudentEnrolCode, [courseId], {
    enabled: courseId > 0,
  });
  const [enrolcode, setEnrolcode] = useState<StudentEnrolCode | undefined>();

  useEffect(() => {
    if (getEnrolcode != null) {
      setEnrolcode(getEnrolcode);
    }
  }, [getEnrolcode]);

  const link = useMemo(() => {
    if (enrolcode?.code) {
      const params = new URLSearchParams();
      params.set(CommonRoutingParams.ENROL_CODE, enrolcode.code);
      // use url of currently signed in moodle
      params.set(CommonRoutingParams.MOODLE_URL, preferences.moodleUrl!);
      params.set(CommonRoutingParams.ALLOW_CHANGE_MOODLE_URL, String(false));

      const basePath = Utils.normalizeBasePath(config.basePath);
      return `${document.location.origin + basePath}/${CommonRoutingPaths.LOGIN}?${params.toString()}`;
    }
    return undefined;
  }, [enrolcode]);

  async function createNewEnrollmentCode() {
    try {
      setEnrolcode(undefined);
      const createEnrolcodeResponse = await appCommonAPI.createStudentEnrolCode(courseId);
      setEnrolcode(createEnrolcodeResponse);
    } catch (e) {
      GtnLogger.warn(e);
    }
  }

  function getValidUntilText() {
    const validUntilDate = new Date((enrolcode?.valid_until ?? 0) * 1000);
    return t('invite-students.valid-until', {
      date: validUntilDate.toLocaleDateString() + ' ' + validUntilDate.toLocaleTimeString(),
    });
  }

  let content;

  if (!selectedCourse) {
    content = <CourseProblemInfo />;
  } else {
    content = (
      <LoadingContainerV2
        className={styles.codeContainer}
        emptyText={''}
        state={enrolcode === undefined ? ProgressState.Loading : getEnrolCodeProgressState}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 30,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className={styles.textContainer}>
            <h2>{enrolcode?.code}</h2>
            {enrolcode?.valid_until && <p className={styles.validUntil}>{getValidUntilText()}</p>}

            {link && window.isSecureContext && (
              <GtnButton label={t('invite-students.copy-link')} actionType="secondary" className={styles.copyLinkButton} onClick={() => navigator.clipboard.writeText(link)}></GtnButton>
            )}
          </div>

          {link && <QRCode value={link} bgColor="transparent" className={styles.qrCode} />}
        </div>

        <GtnButton label={t('invite-students.create-new-enrolcode')} actionType="primary" className={styles.createNewCodeButton} onClick={() => createNewEnrollmentCode()}></GtnButton>
      </LoadingContainerV2>
    );
  }

  return <CourseStudentSelectorSidebar selectionMode="course-only">{content}</CourseStudentSelectorSidebar>;
}
