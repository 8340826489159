import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import React from 'react';
import { ChildrenProps } from '@gtn/common/components/ChildrenProps';
import { StyleProps } from '@gtn/common/components/StyleProps';
import styles from '@gtn/common/components/navigation/gtn-drawer/GtnDrawer.module.scss';

export interface GtnDrawerProps extends ChildrenProps, StyleProps {
  isOpen: boolean;
  onClose: () => void;
  side: 'left' | 'right';
  variant?: 'permanent' | 'persistent' | 'temporary';
}

export default function GtnDrawer(props: GtnDrawerProps) {
  return (
    <nav
      className={classNames({
        [styles.permanentDrawer]: props.variant === 'persistent',
      })}
    >
      <Drawer
        variant={props.variant ?? 'temporary'}
        anchor={props.side ?? 'right'}
        open={props.isOpen}
        onClose={props.onClose}
        classes={{
          paper: classNames(styles.container, props.className),
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {props.variant !== 'persistent' && (
          <IconButton
            onClick={props.onClose}
            style={{
              alignSelf: props.side === 'left' ? 'flex-end' : 'flex-start',
            }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        )}
        <div className={styles.contentContainer}>{props.children}</div>
      </Drawer>
    </nav>
  );
}
