import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import GtnTextField from '@gtn/common/components/forms/GtnTextField';
import { Checkbox, Chip } from '@material-ui/core';
import { CheckCircle, GroupAdd, RadioButtonUnchecked } from '@material-ui/icons';
import { Field as FormikField } from 'formik';
import React, { useMemo } from 'react';
import { MoodleUserUtils } from '@gtn/common/api/model/moodle-core/MoodleUser';
import { MoodleCoreAPI } from '@gtn/common/api/MoodleCoreAPI';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { StyleProps } from '@gtn/common/components/StyleProps';
import { useUser } from '@gtn/common/store/user/user.hooks';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import { GtnAvatar } from '@gtn/common/components/gtn-avatar/GtnAvatar';
import GtnDialog, { DialogProps, useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import styles from '@gtn/common/components/choose-collaborators/ChooseCollaborators.module.scss';

export interface ChooseCollaboratorsProps {
  name: string;
  title?: string;
  courseId: number;
  disabled?: boolean;
  onChange?: (newValue: any[]) => void;
}

export const ChooseCollaboratorsDialog = ({
  isOpen,
  onClose,
  students,
  value,
  onChange,
  title,
}: {
  students: any[] | undefined;
  value: any[];
  onChange: any;
  title?: string;
} & DialogProps) => {
  const t = useAppTranslation();
  return (
    <GtnDialog isOpen={isOpen} onClose={onClose} title={t(title ?? 'choose-collaborators.who')}>
      <div className={styles.dialogContentContainer}>
        <div className={styles.scrollContainer}>
          {students?.map((user) => {
            const checked = !!value.find((userId) => user.id === userId);
            return (
              <div
                className={styles.dialogRow}
                onClick={() => {
                  let newValue = [...value];
                  if (checked) {
                    newValue = newValue.filter((id) => id !== user.id);
                  } else {
                    newValue.push(user.id);
                  }

                  onChange(newValue);
                }}
                key={user.id}
              >
                <GtnAvatar imageUrl={user.profileimageurlsmall} name={user.fullname} className={styles.userAvatar} />
                <p className={styles.userName}>{user.fullname}</p>
                <Checkbox icon={<RadioButtonUnchecked />} checkedIcon={<CheckCircle />} checked={checked} />
              </div>
            );
          })}
        </div>

        <div className={styles.submitContainer}>
          <p></p>

          <GtnButton
            type="button"
            actionType="primary"
            label={t('save')}
            onClick={() => {
              onClose?.();
            }}
          />
        </div>
      </div>
    </GtnDialog>
  );
};

export function ChooseCollaborators(props: ChooseCollaboratorsProps & StyleProps) {
  const moodleCoreAPI = InjectionContainer.resolve(MoodleCoreAPI);
  const { data: users } = useAPI(moodleCoreAPI.getEnrolledUsersForCourse, [props.courseId]);
  const t = useAppTranslation();
  const chooseCollaboratorsDialog = useGtnDialog(ChooseCollaboratorsDialog);
  const user = useUser();

  const students = useMemo(() => {
    // filter for only students
    // and also filter out current user
    return users?.filter((classUser) => MoodleUserUtils.isStudent(classUser) && classUser.id !== user.profile?.id);
  }, [users]);

  const getSelectedUsers = (userIds: number[] | null) => {
    if (!students) {
      return [];
    }
    return students.filter((student) => !!userIds?.find((userId) => student.id === userId));
  };

  return (
    <FormikField
      name={props.name}
      children={({ field, form }) => {
        const currentValue = field.value || [];
        const selectedUsers = getSelectedUsers(currentValue);

        return (
          <>
            <div className={styles.container}>
              {selectedUsers.map((student, index) => (
                <Chip
                  key={index}
                  avatar={<GtnAvatar imageUrl={student.profileimageurlsmall} name={student.fullname} />}
                  className={styles.chip}
                  label={student.fullname}
                  onDelete={
                    props.disabled
                      ? undefined
                      : () => {
                          let newValue = [...currentValue];
                          newValue = newValue.filter((id) => id !== student.id);
                          form.setFieldValue(props.name, newValue);
                          props.onChange?.(newValue);
                        }
                  }
                />
              ))}
              {!props.disabled && <Chip avatar={<GroupAdd />} label={t('choose-collaborators.add-students')} onClick={() => chooseCollaboratorsDialog.open()} />}
              {!users && t('loading')}
            </div>
            <chooseCollaboratorsDialog.Component
              students={students}
              value={currentValue}
              onClose={() => {
                props.onChange?.([]);
                chooseCollaboratorsDialog.close();
              }}
              onChange={(value) => form.setFieldValue(props.name, value)}
            />
          </>
        );
      }}
    />
  );
}
