import { singleton } from 'tsyringe';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { BaseMoodleAPI } from '@gtn/common/api/BaseMoodleAPI';
import { MoodleCourse } from '@gtn/common/api/model/moodle-core/MoodleCourse';
import { MoodleNotificationResponse } from '@gtn/common/api/model/moodle-core/MoodleNotification';
import { MoodleUser } from '@gtn/common/api/model/moodle-core/MoodleUser';

@singleton()
export class MoodleCoreAPI extends BaseMoodleAPI {
  public getCoursesForUser = async (userId: number = this.getCurrentUserId()): Promise<MoodleCourse[]> => {
    return this.moodleWebservice.core_enrol_get_users_courses({
      userid: userId,
    });
  };

  public getVisibleCoursesForUser = async (userId: number = this.getCurrentUserId()): Promise<MoodleCourse[]> => {
    let courses = await this.getCoursesForUser(userId);

    const now = new Date().getTime();
    courses = courses.filter((course) => course.visible === 1 && (course.enddate ? course.enddate * 1000 >= now : true));

    return courses;
  };

  public getEnrolledUsersForCourse = async (courseId: number): Promise<MoodleUser[]> => {
    const users: MoodleUser[] = await this.moodleWebservice.core_enrol_get_enrolled_users({
      courseid: courseId,
    });

    // webservice liefert user sortiert nach id
    // -> nach name sortieren
    return users.sort((a, b) => a.fullname.localeCompare(b.fullname));
  };

  public getSiteInfo = async () => await this.moodleWebservice.core_webservice_get_site_info({});

  // alternativer service "core_message_get_unread_conversations_count" ist für conversations
  // liefert unread count für alle notifications, auch nicht diggr-plus!
  public getAllUnreadNotificationCount = async () =>
    parseInt(
      // TODO: is parseInt() + String() here needed?
      String(
        await this.moodleWebservice.message_popup_get_unread_popup_notification_count({
          useridto: this.getCurrentUserId(),
        })
      ),
      10
    );

  // alternativer service "core_message_get_messages" ist für alle messages
  // mit parameter type: notifications, conversations and both
  // liefert alle notifications, auch nicht diggr-plus notifications!
  public getAllNotifications = async (): Promise<MoodleNotificationResponse> => {
    const response = await this.moodleWebservice.message_popup_get_popup_notifications({
      useridto: this.getCurrentUserId(),
    });

    return {
      notifications: response.notifications.map((notification) => {
        let customdata = undefined;
        if (notification.customdata) {
          try {
            customdata = JSON.parse(notification.customdata);
          } catch (e) {
            GtnLogger.warn('Notification customdata parse error:', e);
          }
        }

        return {
          ...notification,
          eventtype: notification.eventtype || '',
          customdata,
        };
      }),
      unreadcount: response.unreadcount,
    };
  };

  public getExacompNotifications = async (): Promise<MoodleNotificationResponse> => {
    const notificationData = await this.getAllNotifications();

    const exacompNotifications = notificationData.notifications.filter((n) => n.component === 'block_exacomp');

    return {
      unreadcount: exacompNotifications.filter((n) => !n.read).length,
      notifications: exacompNotifications,
    };
  };

  public markNotificationRead = async (notificationId) =>
    this.moodleWebservice.core_message_mark_notification_read({
      notificationid: notificationId,
    });

  public markAllNotificationsAsRead = async () =>
    this.moodleWebservice.core_message_mark_all_notifications_as_read({
      useridto: this.getCurrentUserId(),
    });

  public getUsersByField = async (field: string, values: any[]) =>
    this.moodleWebservice.core_user_get_users_by_field({
      field,
      values,
    });
}
