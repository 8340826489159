import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthTokens {
  sessionKey: string;
  moodleMobile: string;
  exacomp: string;
  exaport: string;
}

export interface UserState {
  isLoggedIn: boolean;
  profile?: UserProfile;
  sessionId?: string;
  authTokens?: AuthTokens;
}

export enum UserRole {
  Student = 'student',
  Teacher = 'teacher',
}

export interface UserProfile {
  id: number;
  userName: string;
  pictureUrl: string;
  fullName: string;
  firstName: string;
  lastName: string;
  role: UserRole;
}

const initialState: UserState = { isLoggedIn: false };

export const userFeatureKey = 'user';

const userSlicer = createSlice({
  name: userFeatureKey,
  initialState,
  reducers: {
    authTokenLoadSuccess: (state, action: PayloadAction<AuthTokens>): UserState => {
      return { ...state, authTokens: action.payload };
    },
    authTokenClear: (state, action: PayloadAction<void>): UserState => {
      return { ...state, authTokens: undefined };
    },

    userProfileLoadSuccess: (state, action: PayloadAction<UserProfile>): UserState => {
      const profile = action.payload;
      if (!profile.fullName) {
        profile.fullName = profile.firstName + ' ' + profile.lastName;
      }

      return { ...state, profile, isLoggedIn: true };
    },

    userUpdateProfile: (state, action: PayloadAction<{ profile: UserProfile }>): UserState => ({
      ...state,
      profile: { ...state.profile, ...action.payload.profile },
    }),

    userSetSessionId: (state, action: PayloadAction<{ sessionId: string }>): UserState => ({
      ...state,
      sessionId: action.payload.sessionId,
    }),

    userLogout: (): UserState => initialState,
  },
});

export const userActions = userSlicer.actions;
export const userReducer = userSlicer.reducer;
