import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import { DialogProps } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import styles from '@gtn/common/components/navigation/gtn-dialog/GtnDialog.module.scss';

export interface AlertDialogProps {
  title?: string;
  message?: string;

  primaryButtonText?: string;
  onPrimaryButtonClicked?: () => void;

  hideSecondaryButton?: boolean;
  secondaryButtonText?: string;
  onSecondaryButtonClicked?: () => void;
}

export function AlertDialog(props: AlertDialogProps & DialogProps) {
  const t = useAppTranslation();

  const trigger = (fn?: () => void) => {
    props.onClose?.();
    fn?.();
  };

  return (
    <Dialog
      className={styles.dialogContainer}
      open={props.isOpen ?? false}
      onClose={props.onClose}
      keepMounted={false}
    >
      {props.title && <DialogTitle>{props.title}</DialogTitle>}

      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>

      <DialogActions>
        {!props.hideSecondaryButton && (
          <Button
            onClick={() => trigger(props.onSecondaryButtonClicked)}
            color="default"
          >
            {props.secondaryButtonText ?? t('cancel')}
          </Button>
        )}

        <Button
          onClick={() => trigger(props.onPrimaryButtonClicked)}
          color="primary"
          autoFocus
        >
          {props.primaryButtonText ?? t('ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
