import GtnDialog, { GtnDialogProps } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import styles from '@gtn/app-common/routes/login/tutorial-dialog/TutorialDialog.module.scss';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import { Utils } from '@gtn/common/utils/Utils';
import { useState } from 'react';

enum SchoolType {
  Austria = 'austria',
  External = 'external',
}

export function TutorialDialog(props: GtnDialogProps) {
  const t = useAppTranslation();
  const [selectedSchoolType, setSelectedSchoolType] = useState<SchoolType | undefined>();

  const onRegisterOrganisationClicked = () => {
    window.open(`mailto:support@lernmanagement.at?bcc=andreas.riepl@eeducation.at&subject=${t('tutorial.austria.registration-email.subject')}&body=${t('tutorial.austria.registration-email.body')}`);
  };

  return (
    <GtnDialog {...props} title={t(`tutorial.title`)}>
      <div className={styles.container}>
        <div style={{ display: 'flex', gap: 16, alignItems: 'center', marginBottom: 24, justifyContent: 'space-around' }}>
          <GtnButton label={t('tutorial.austria.title')} onClick={() => setSelectedSchoolType(SchoolType.Austria)} />
          <GtnButton label={t('tutorial.external.title')} onClick={() => setSelectedSchoolType(SchoolType.External)} />
        </div>

        {selectedSchoolType && (
          <>
            <p dangerouslySetInnerHTML={Utils.getAsInnerHTML(t(`tutorial.${selectedSchoolType}.intro`))}></p>
            {selectedSchoolType === SchoolType.Austria && (
              <>
                <br />
                <ol className={styles.list}>
                  <li>
                    <span dangerouslySetInnerHTML={Utils.getAsInnerHTML(t(`tutorial.austria.steps.1`))} />
                    <GtnButton label={t(`tutorial.austria.steps.1-cta`)} onClick={onRegisterOrganisationClicked} />
                  </li>
                  <li dangerouslySetInnerHTML={Utils.getAsInnerHTML(t(`tutorial.austria.steps.2`))} />
                  <li dangerouslySetInnerHTML={Utils.getAsInnerHTML(t(`tutorial.austria.steps.3`))} />
                </ol>
              </>
            )}
            <br />
            <p dangerouslySetInnerHTML={Utils.getAsInnerHTML(t(`tutorial.${selectedSchoolType}.footer`))} />
          </>
        )}
      </div>
    </GtnDialog>
  );
}
