import { ChooseExamples, ChooseExamplesProps } from '@gtn/app-common/components/choose-examples/ChooseExamples';
import GtnDialog, { GtnDialogProps } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';

export function ChooseExamplesDialog(props: ChooseExamplesProps & GtnDialogProps) {
  return (
    <GtnDialog {...props}>
      <ChooseExamples {...props} />
    </GtnDialog>
  );
}
