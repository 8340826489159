import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import { CompetenceType } from '@gtn/app-common/api/model/CompetenceType';
import { ItemStatus } from '@gtn/app-common/api/model/ExampleAndItemResponse';
import { ExamplesTreeExample } from '@gtn/app-common/api/model/ExamplesTreeResponse';
import { LearningPathExample } from '@gtn/app-common/api/model/models';
import CourseStudentSelectorSidebar from '@gtn/app-common/components/app-navigation/course-student-selection/sidebar/CourseStudentSelectorSidebar';
import { ChooseExamplesDialog } from '@gtn/app-common/components/choose-examples/ChooseExamplesDialog';
import { ExampleItemsDialog } from '@gtn/app-common/components/example-item-list/example-items-dialog/ExampleItemsDialog';
import { ITEM_STATUS_DISPLAY_VALUES_STUDENT, ITEM_STATUS_DISPLAY_VALUES_TEACHER } from '@gtn/app-common/components/example-item-list/ItemStatusDisplay';
import ExampleItemDialog from '@gtn/app-common/components/submit-item/ExampleItemDialog';
import { useAppCommonSelector, useAppDispatch, useSelectedCourse, useSelectedStudent } from '@gtn/app-common/store/app.store.hooks';
import { MoodleWebservice } from '@gtn/common/api/webservice/MoodleWebservice';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { ChooseCollaboratorsDialog } from '@gtn/common/components/choose-collaborators/ChooseCollaborators';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import LoadingContainer from '@gtn/common/components/loading-container/LoadingContainerV2';
import { useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useIsTeacher } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { Utils } from '@gtn/common/utils/Utils';
import CreateEditLearningPathDialog from '@gtn/dakora/routes/learning-paths/create-edit-learning-path-dialog/CreateEditLearningPathDialog';
import { appDAKORAActions } from '@gtn/dakora/store/DakoraState';
import { useDakoraSelector } from '@gtn/dakora/store/DakoraStore';
import { LearningPathUtils } from '@gtn/dakora/utils/LearningPathUtils';
import { IconButton, Tooltip } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ArrowDownward, ArrowUpward, DeleteForeverOutlined, Visibility, VisibilityOff } from '@material-ui/icons';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import styles from './LearningPaths.module.scss';

export default function LearningPaths() {
  const t = useAppTranslation();
  const selectedCourse = useSelectedCourse();
  const selectedStudent = useSelectedStudent();
  const selectedCourseStudents = useAppCommonSelector((state) => state.navigation.selectedCourseStudents);
  const isTeacher = useIsTeacher();
  const dispatch = useAppDispatch();

  const [selectedLearningPathId, setSelectedLearningPathId] = useState(0);
  const [selectedStudentIds, setSelectedStudentIds] = useState<number[]>([]);
  const [selectedExamples, setSelectedExamples] = useState<ExamplesTreeExample[]>();

  const chooseExamplesDialog = useGtnDialog(ChooseExamplesDialog);
  const createEditLearningPathDialog = useGtnDialog(CreateEditLearningPathDialog);
  const exampleItemsDialog = useGtnDialog(ExampleItemsDialog);
  const exampleItemDialog = useGtnDialog(ExampleItemDialog);
  const chooseCollaboratorsDialog = useGtnDialog(ChooseCollaboratorsDialog);

  const appCommonAPI = InjectionContainer.resolve(AppCommonAPI);
  const moodleAPI = InjectionContainer.resolve(MoodleWebservice);
  const {
    data: learningPathsData,
    progressState: learningPathsProgressState,
    mutate: reloadLearningPaths,
  } = useAPI(moodleAPI.diggrplus_learningpath_list, [
    {
      courseid: selectedCourse?.id ?? 0,
      studentid: selectedStudent?.id ?? 0,
    },
  ]);

  const learningPaths = learningPathsData?.learningpaths || [];

  const {
    data: learningPathDetails,
    progressState: learningPathDetailsProgressState,
    mutate: reloadLearningPathDetails,
  } = useAPI(
    moodleAPI.diggrplus_learningpath_details,
    [
      {
        id: selectedLearningPathId,
        studentid: selectedStudent?.id ?? 0,
      },
    ],
    {
      enabled: !!selectedLearningPathId,
    }
  );

  useEffect(() => {
    setSelectedLearningPathId(0);
  }, [selectedCourse]);

  // immer ersten learning path auswählen
  useEffect(() => {
    if (learningPaths?.length > 0 && !selectedLearningPathId) {
      setSelectedLearningPathId(learningPaths[0]?.id ?? 0);
    }
  }, [learningPaths]);

  function renderAllStudentsStatusIndicators(learningPathExample: LearningPathExample) {
    function renderStatusIndicator(itemStatus: ItemStatus, count?: number) {
      const itemStatusDisplay = ITEM_STATUS_DISPLAY_VALUES_TEACHER.find((s) => s.statusMapping === itemStatus);
      if (!itemStatusDisplay) {
        return;
      }

      return (
        <Tooltip title={count + ' ' + t(itemStatusDisplay.textResId)}>
          <div
            className={styles.statusCount}
            style={{
              color: count === 0 ? 'gray' : itemStatusDisplay.color,
              opacity: count === 0 ? 0.4 : 1,
            }}
            onClick={(e) => {
              onExampleItemClick(learningPathExample, itemStatus);
              e.stopPropagation();
            }}
          >
            <img src={'img/item-' + itemStatusDisplay.statusMapping + '.svg'} style={{ filter: count === 0 ? 'grayscale(1)' : 'none' }} />
            <span>{count}</span>
          </div>
        </Tooltip>
      );
    }

    return (
      <div className={styles.statusCountContainer}>
        {renderStatusIndicator(ItemStatus.INPROGRESS, learningPathExample.count_inprogress)}
        {renderStatusIndicator(ItemStatus.SUBMITTED, learningPathExample.count_submitted)}
        {renderStatusIndicator(ItemStatus.COMPLETED, learningPathExample.count_completed)}
      </div>
    );
  }

  function renderSingleStudentStatusIndicator(learningPathExample: LearningPathExample) {
    const itemStatus = ITEM_STATUS_DISPLAY_VALUES_TEACHER.find((s) => s.statusMapping === learningPathExample.status);

    if (itemStatus) {
      return (
        <div className={styles.statusCount} style={{ color: itemStatus.color }}>
          <img src={'img/item-' + itemStatus.statusMapping + '.svg'} />
          <span>{t(itemStatus.textResId)}</span>
        </div>
      );
    }
    return null;
  }

  async function changeItemSorting(event: React.MouseEvent, index: number, direction: '+' | '-') {
    event.stopPropagation();
    if (learningPathDetails) {
      const newItems = Utils.moveItemInArray(learningPathDetails.items, index, direction);
      await moodleAPI.diggrplus_learningpath_item_sorting({ learningpathid: learningPathDetails.id, itemids: newItems.map((i) => i.id) });
      reloadLearningPathDetails();
    }
  }

  async function addExamplesToLearningPath(examples: ExamplesTreeExample[], studentIds?: number[]) {
    await moodleAPI.diggrplus_learningpath_add_items({
      learningpathid: learningPathDetails!.id,
      exampleids: examples.map((example) => example.id),
      studentids: studentIds,
    });

    reloadLearningPathDetails();
  }

  async function onExampleItemClick(item: LearningPathExample, preSelectedStatusFilter?: ItemStatus) {
    if (isTeacher && !selectedStudent) {
      const items = await appCommonAPI.getTeacherExamplesAndItems('', CompetenceType.Example, item.exampleid, 0, '', null, selectedCourse!.id);

      exampleItemsDialog.open({
        title: '',
        exampleId: item.exampleid,
        preSelectedStatusFilter,
        items,
        onItemClicked: (exampleItem) => {
          exampleItemDialog.open({ exampleId: item.exampleid, studentId: exampleItem.item?.owner?.userid });
        },
      });
    } else {
      exampleItemDialog.open({ exampleId: item.exampleid, studentId: selectedStudent?.id });
    }
  }

  return (
    <>
      <CourseStudentSelectorSidebar selectionMode={isTeacher ? 'single-and-all-students' : 'course-only'} mainContainerStyle={{ style: { padding: 0, flexDirection: 'row' } }}>
        <div className={styles.learningPathsContainer}>
          <LoadingContainer state={learningPathsProgressState} style={{ gap: 12 }}>
            {learningPaths?.map((learningPath) => (
              <ListItem
                className={classNames(styles.listItem, {
                  [styles.selected]: selectedLearningPathId == learningPath.id,
                  [styles.visiblyOnlyForTeachers]: isTeacher && selectedStudent != null && !learningPath.visible,
                })}
                onClick={() => setSelectedLearningPathId(learningPath.id)}
                button
                key={'learning-path-' + learningPath.id}
              >
                <ListItemText
                  primary={learningPath.title}
                  secondary={
                    isTeacher && selectedStudent == null
                      ? t(learningPath.visible ? 'learning-paths.visible-for-all-students' : 'learning-paths.visible-for-only-teachers')
                      : t(LearningPathUtils.getLearningPathStatus(learningPath)?.textResId ?? '')
                  }
                />
              </ListItem>
            ))}
          </LoadingContainer>

          {isTeacher && (
            <p className={styles.addExamples}>
              <GtnButton
                label={t('learning-paths.add')}
                actionType="primary"
                onClick={async () =>
                  createEditLearningPathDialog.open({
                    courseId: selectedCourse!.id,
                    onClose: () => reloadLearningPaths(),
                  })
                }
              />
            </p>
          )}
        </div>

        {!!selectedLearningPathId && (
          <LoadingContainer
            state={learningPathDetailsProgressState}
            style={{
              flexGrow: 1,
              padding: 'var(--page-padding)',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            {!!learningPathDetails && (
              <>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
                  <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                    <h2>{learningPathDetails.title}</h2>
                    <p>{learningPathDetails.description}</p>
                  </div>

                  {isTeacher && (
                    <>
                      <GtnButton
                        label={t('learning-paths.edit')}
                        style={{ marginRight: 8 }}
                        onClick={async () =>
                          createEditLearningPathDialog.open({
                            courseId: selectedCourse!.id,
                            learningPath: learningPathDetails,
                            onClose: () => {
                              reloadLearningPaths();
                              reloadLearningPathDetails();
                            },
                          })
                        }
                      />
                      <GtnButton
                        label={t('learning-paths.delete')}
                        style={{ marginRight: 8 }}
                        onClick={async () => {
                          if (window.confirm(t('learning-paths.confirm-delete'))) {
                            await moodleAPI.diggrplus_learningpath_delete({ id: learningPathDetails.id });
                            setSelectedLearningPathId(0);
                            reloadLearningPaths();
                          }
                        }}
                      />

                      <GtnButton
                        label={t(learningPathDetails.visible ? 'learning-paths.unpublish' : 'learning-paths.publish')}
                        actionType={learningPathDetails.visible ? undefined : 'secondary'}
                        onClick={async () => {
                          await moodleAPI.diggrplus_learningpath_update({
                            id: learningPathDetails.id,
                            visible: !learningPathDetails.visible,
                          });

                          reloadLearningPaths();
                          reloadLearningPathDetails();
                        }}
                      />
                    </>
                  )}
                </div>

                <div className={styles.learningPathExamplesContainer}>
                  {learningPathDetails.items.map((item, index) => {
                    const isAdditionalItem = selectedStudent && !item.visibleall && item.visiblestudent;
                    const isRemovedItem = selectedStudent && item.visibleall && !item.visiblestudent;

                    if (selectedStudent && !item.visibleall && !item.visiblestudent) {
                      return <></>;
                    }

                    return (
                      <ListItem
                        className={styles.listItem}
                        style={{
                          opacity: isRemovedItem ? 0.6 : 1,
                          border: isAdditionalItem ? 'green dashed 1px' : isRemovedItem ? 'red dashed 1px' : undefined,
                          flexGrow: 1,
                        }}
                        onClick={async () => await onExampleItemClick(item)}
                      >
                        <div style={{ width: 20 }}>{index + 1}.</div>
                        <img className={styles.icon} src="img/example.svg" />
                        <ListItemText primary={item.exampletitle} secondary={item.topictitle} />

                        {isTeacher && (
                          <>
                            {!selectedStudent ? renderAllStudentsStatusIndicators(item) : renderSingleStudentStatusIndicator(item)}

                            {!selectedStudent && (
                              <IconButton
                                className={styles.itemButton}
                                onClick={async (event) => {
                                  event.stopPropagation();
                                  if (!window.confirm(t('learning-paths.remove-example'))) {
                                    return;
                                  }

                                  await moodleAPI.diggrplus_learningpath_item_delete({
                                    id: item.id,
                                  });

                                  reloadLearningPathDetails();
                                }}
                              >
                                <DeleteForeverOutlined />
                              </IconButton>
                            )}
                            {selectedStudent && (
                              <IconButton
                                className={styles.itemButton}
                                onClick={async (event) => {
                                  event.stopPropagation();

                                  await moodleAPI.diggrplus_learningpath_item_update({
                                    id: item.id,
                                    studentid: selectedStudent.id,
                                    visiblestudent: !item.visiblestudent,
                                  });
                                  reloadLearningPathDetails();
                                }}
                              >
                                {item.visiblestudent ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            )}

                            {!selectedStudent && (
                              <>
                                <IconButton className={styles.itemButton} disabled={index === 0} onClick={async (event) => await changeItemSorting(event, index, '+')}>
                                  <ArrowUpward />
                                </IconButton>

                                <IconButton
                                  className={styles.itemButton}
                                  disabled={index >= learningPathDetails.items.length - 1}
                                  onClick={async (event) => await changeItemSorting(event, index, '-')}
                                >
                                  <ArrowDownward />
                                </IconButton>
                              </>
                            )}
                          </>
                        )}

                        {!isTeacher && renderSingleStudentStatusIndicator(item)}
                      </ListItem>
                    );
                  })}

                  {learningPathDetails.items.length === 0 && (
                    <p>
                      <i>{t('learning-paths.no-examples')}</i>
                    </p>
                  )}

                  {isTeacher && (
                    <p className={styles.addExamples}>
                      <GtnButton
                        label={t('learning-paths.add-examples')}
                        actionType="primary"
                        onClick={async () => {
                          chooseExamplesDialog.open({
                            courseId: selectedCourse!.id,
                            onPrimaryButtonClick: (examples) => addExamplesToLearningPath(examples, selectedStudent?.id ? [selectedStudent.id] : undefined),
                            onSecondaryButtonClick: async (examples) => {
                              chooseCollaboratorsDialog.open();
                              setSelectedExamples(examples);
                            },
                            primaryButtonText: 'learning-paths.add-for-all-students',
                            secondaryButtonText: 'learning-paths.add-for-specific-students',
                            title: t('learning-paths.add-examples'),
                          });
                        }}
                      />
                    </p>
                  )}
                </div>
              </>
            )}
          </LoadingContainer>
        )}
      </CourseStudentSelectorSidebar>

      <chooseExamplesDialog.Component />
      <chooseCollaboratorsDialog.Component
        students={selectedCourseStudents}
        value={selectedStudentIds}
        onChange={setSelectedStudentIds}
        title="learning-paths.add-for-specific-students"
        onClose={() => {
          chooseCollaboratorsDialog.close();
          if (selectedStudentIds?.length && selectedExamples?.length) {
            addExamplesToLearningPath(selectedExamples, selectedStudentIds);
          }
        }}
      />
      <createEditLearningPathDialog.Component />
      <exampleItemDialog.Component onSave={() => reloadLearningPathDetails()} />
      <exampleItemsDialog.Component />
    </>
  );
}
