import { Dialog, IconButton, useMediaQuery } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React, { useState } from 'react';
import { ChildrenProps } from '@gtn/common/components/ChildrenProps';
import styles from '@gtn/common/components/navigation/gtn-dialog/GtnDialog.module.scss';

export interface DialogProps {
  isOpen?: boolean;
  onClose?: () => void;
  fullscreen?: 'never' | 'always' | 'auto';
  disableCloseByClickingOutside?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

export interface GtnDialogProps extends ChildrenProps, DialogProps {
  title?: string;
}

export function useGtnDialog<P extends DialogProps>(Dialog: React.ComponentType<P>) {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState<Partial<P>>();

  const open = (data?: Partial<P>) => {
    setDialogProps(data);
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
    dialogProps?.onClose?.();
  };

  const render = (props?: P) =>
    isOpen && (
      // @ts-ignore
      <Dialog isOpen={isOpen} {...dialogProps} onClose={close} {...props} />
    );

  const renderRef = React.useRef<any>(() => null);
  const { current: Component } = React.useRef((props: Partial<P>) => renderRef.current(props));
  renderRef.current = render;

  return {
    open,
    close,
    render,
    Component,
  };
}

export default function GtnDialog(props: GtnDialogProps) {
  const isMobileOrTablet = useMediaQuery('(max-width:800px)');

  const onCloseClicked = () => {
    props.onClose?.();
  };

  const isFullscreen = () => {
    switch (props.fullscreen) {
      case 'always':
        return true;
      case 'never':
        return false;
      case 'auto':
      default:
        return isMobileOrTablet;
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown={props.disableCloseByClickingOutside}
      disableBackdropClick={props.disableCloseByClickingOutside}
      className={styles.dialogContainer}
      fullScreen={isFullscreen()}
      open={props.isOpen ?? false}
      maxWidth={props.maxWidth}
      onClose={onCloseClicked}
      keepMounted={false}
    >
      <div className={styles.header}>
        <h3 className={styles.title}>{props.title ?? ''}</h3>
        <IconButton aria-label="close" className={styles.closeButton} onClick={onCloseClicked}>
          <Close />
        </IconButton>
      </div>

      <div className={styles.contentContainer}>{props.children}</div>
    </Dialog>
  );
}
