import { AppInitializer } from '@gtn/app-common/AppInitializer';
import { CourseProblemInfo } from '@gtn/app-common/components/app-navigation/course-student-selection/CourseProblemInfo';
import CourseStudentSelectorSidebar from '@gtn/app-common/components/app-navigation/course-student-selection/sidebar/CourseStudentSelectorSidebar';
import { useAppCommonSelector } from '@gtn/app-common/store/app.store.hooks';
import { CourseExperienceLevel } from '@gtn/common/api/model/Course';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import GtnCheckbox from '@gtn/common/components/forms/GtnCheckbox';
import GtnForm from '@gtn/common/components/forms/GtnForm';
import { GtnSnackbar } from '@gtn/common/components/GtnSnackbar';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { DakoraAPI } from '@gtn/dakora/api/DakoraAPI';
import React, { useEffect, useState } from 'react';
import styles from './ExperienceLevelSettings.module.scss';

interface ExperienceLevelSettingsFormValues {
  experienceLevelBasic: boolean;
  experienceLevelAdvanced: boolean;
}

export default function ExperienceLevelSettings() {
  const dakoraAPI = InjectionContainer.resolve(DakoraAPI);
  const appInitializer = InjectionContainer.resolve(AppInitializer);

  const t = useAppTranslation();
  const selectedCourse = useAppCommonSelector((state) => state.navigation.selectedCourse);

  const [formValues, setFormValues] = useState<ExperienceLevelSettingsFormValues>();
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  useEffect(() => {
    setFormValues({
      experienceLevelBasic: selectedCourse?.experienceLevel === CourseExperienceLevel.BASIC,
      experienceLevelAdvanced: selectedCourse?.experienceLevel === CourseExperienceLevel.ADVANCED,
    });
  }, [selectedCourse]);

  const onSave = async (values: Partial<ExperienceLevelSettingsFormValues>, formHelper) => {
    if (!selectedCourse) {
      return;
    }

    const experienceLevel = values.experienceLevelBasic ? CourseExperienceLevel.BASIC : CourseExperienceLevel.ADVANCED;
    try {
      const submitResult = await dakoraAPI.saveCourseSettings(selectedCourse.id, { experienceLevel });
      if (submitResult.success) {
        appInitializer.initCourseConfigs();
        setSnackbarMessage(t('saved'));
      }
    } catch (e) {
      setSnackbarMessage(t('save-failed'));
      GtnLogger.warn(e);
    }

    formHelper.setSubmitting(false);
  };

  function renderCourseSettings() {
    if (!selectedCourse) {
      return <CourseProblemInfo />;
    } else {
      return (
        <GtnForm initialValues={formValues} onSubmit={onSave} className={styles.container}>
          {(formHelper) => {
            return (
              <div className={styles.container}>
                <GtnCheckbox
                  name="experienceLevelBasic"
                  label={t('experience-level.basic.title')}
                  onChange={(value) => {
                    if (value.target.checked) {
                      formHelper.setFieldValue('experienceLevelBasic', true);
                      formHelper.setFieldValue('experienceLevelAdvanced', false);
                    }
                  }}
                />
                <p dangerouslySetInnerHTML={{ __html: t('experience-level.basic.info') }}></p>

                <GtnCheckbox
                  name="experienceLevelAdvanced"
                  label={t('experience-level.advanced.title')}
                  onChange={(value) => {
                    if (value.target.checked) {
                      formHelper.setFieldValue('experienceLevelAdvanced', true);
                      formHelper.setFieldValue('experienceLevelBasic', false);
                    }
                  }}
                />
                <p dangerouslySetInnerHTML={{ __html: t('experience-level.advanced.info') }}></p>

                <GtnButton
                  label={t('save')}
                  disabled={!formHelper.dirty || (!formHelper.values['experienceLevelBasic'] && !formHelper.values['experienceLevelAdvanced'])}
                  actionType="primary"
                  type="submit"
                  loading={formHelper.isSubmitting}
                  style={{ marginTop: 24 }}
                />
              </div>
            );
          }}
        </GtnForm>
      );
    }
  }

  return (
    <CourseStudentSelectorSidebar selectionMode="course-only">
      {renderCourseSettings()}
      <GtnSnackbar message={snackbarMessage} open={!!snackbarMessage} onClose={() => setSnackbarMessage('')} />
    </CourseStudentSelectorSidebar>
  );
}
