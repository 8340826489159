import React, { useEffect, useMemo, useState } from 'react';
import { SchooltypeTreeSchoolType, SchooltypeTreeSubject, SchooltypeTreeTopic } from '@gtn/app-common/api/model/SchooltypeTreeResponse';
import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import { CourseProblemInfo } from '@gtn/app-common/components/app-navigation/course-student-selection/CourseProblemInfo';
import CourseStudentSelectorSidebar from '@gtn/app-common/components/app-navigation/course-student-selection/sidebar/CourseStudentSelectorSidebar';
import { useAppCommonSelector } from '@gtn/app-common/store/app.store.hooks';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { AlertDialog } from '@gtn/common/components/alert-dialog/AlertDialog';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import GtnCheckbox from '@gtn/common/components/forms/GtnCheckbox';
import GtnForm from '@gtn/common/components/forms/GtnForm';
import { GtnTreeParent, GtnTreeView } from '@gtn/common/components/gtn-tree-view/GtnTreeView';
import { GtnSnackbar } from '@gtn/common/components/GtnSnackbar';
import { LoadingIndicatorInline } from '@gtn/common/components/LoadingIndicator';
import { useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { Utils } from '@gtn/common/utils/Utils';
import styles from './CourseTopics.module.scss';

interface ActivatedCompetencesSettingsFormValues {
  [topicId: number]: boolean;
}

export default function CourseTopics() {
  const t = useAppTranslation();
  const sharedAPI = InjectionContainer.resolve(AppCommonAPI);

  const [searchFilter, setSearchFilter] = useState<string | undefined>(undefined);
  const [formValues, setFormValues] = useState<ActivatedCompetencesSettingsFormValues>();
  const [saveFailed, setSaveFailed] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const hideAllExamplesInitiallyDialog = useGtnDialog(AlertDialog);

  const selectedCourse = useAppCommonSelector((state) => state.navigation.selectedCourse);
  const { data: availableCompetences } = useAPI(sharedAPI.getCourseSchooltypeTree, selectedCourse ? [selectedCourse?.id] : null);
  const filteredCompetencies = useMemo(() => {
    const schoolTypes = availableCompetences?.schooltypes;

    if (searchFilter) {
      return Utils.filterTree<SchooltypeTreeSchoolType, SchooltypeTreeSubject>(
        schoolTypes,
        'subjects',
        (schoolType) => Utils.includesSearch(schoolType.title, searchFilter),
        (subjects) => {
          return Utils.filterTree<SchooltypeTreeSubject, SchooltypeTreeTopic>(
            subjects,
            'topics',
            (subject) => Utils.includesSearch(subject.title, searchFilter),
            (topics) => topics.filter((descriptor) => Utils.includesSearch(descriptor.title, searchFilter))
          );
        }
      );
    } else {
      return schoolTypes;
    }
  }, [availableCompetences, searchFilter]);

  useEffect(() => {
    const formValues: ActivatedCompetencesSettingsFormValues = {};
    availableCompetences?.schooltypes.forEach((schoolType) =>
      schoolType.subjects.forEach((subject) =>
        subject.topics.forEach((topic) => {
          formValues[topic.id] = topic.active;
        })
      )
    );

    setFormValues(formValues);
  }, [availableCompetences]);

  const onSave = async (values, formHelper) => {
    setSaveFailed(false);
    hideAllExamplesInitiallyDialog.open({
      title: t('course-topics.hide-new-examples.title'),
      message: t('course-topics.hide-new-examples.text'),
      primaryButtonText: t('course-topics.hide-new-examples.yes'),
      secondaryButtonText: t('course-topics.hide-new-examples.no'),
      onPrimaryButtonClicked: () => saveActiveCourseTopics(values, formHelper, true),
      onSecondaryButtonClicked: () => saveActiveCourseTopics(values, formHelper, false),
      onClose: () => {
        formHelper.setSubmitting(false);
      },
    });
  };

  const saveActiveCourseTopics = async (values, formHelper, hideNewExamples: boolean) => {
    formHelper.setSubmitting(true);
    try {
      const activeTopics = { ...values };
      const activeTopicIds: number[] = [];
      Object.keys(activeTopics).forEach((topicId) => {
        if (activeTopics[topicId]) {
          activeTopicIds.push(parseInt(topicId));
        }
      });

      // TODO remove double save (current workaround for server error on first save)
      try {
        await sharedAPI.setActiveCourseTopics(selectedCourse!.id, activeTopicIds, hideNewExamples);
      } catch (e) {}
      const submitResult = await sharedAPI.setActiveCourseTopics(selectedCourse!.id, activeTopicIds, hideNewExamples);

      if (submitResult.success) {
        setSnackbarMessage(t('saved'));
      }
    } catch (exception) {
      setSaveFailed(true);
      GtnLogger.warn(exception);
    }

    formHelper.setSubmitting(false);
  };

  let content;

  if (!selectedCourse) {
    content = <CourseProblemInfo />;
  } else if (availableCompetences) {
    content = (
      <GtnForm initialValues={formValues} onSubmit={onSave} className={styles.container}>
        {(formHelper) => {
          return (
            <>
              <div className={styles.submitContainer}>
                <p>
                  {t('course-topics.selected-competence-areas', {
                    num: Object.keys(formHelper.values).filter((key) => formHelper.values[key])?.length,
                  })}
                </p>
                <p>{saveFailed ? t('submit-item.error') : ''}</p>

                <GtnButton type="submit" actionType="primary" label={t('save')} disabled={formHelper.isSubmitting || !formHelper.isValid || !formHelper.dirty} loading={formHelper.isSubmitting} />
              </div>

              <div className={styles.scrollContainer}>
                <GtnTreeView showSearchBar={true} searchBarLabel={t('course-topics.search')} searchText={searchFilter} onSearchTextChanged={setSearchFilter}>
                  {filteredCompetencies?.map((schoolType) => (
                    <>
                      <h2 className={styles.schoolTypeTitle}>{schoolType.title}</h2>

                      {schoolType.subjects?.map((subject) => (
                        <GtnTreeParent
                          id={subject.id}
                          node={<h2 className={styles.competenceTitle} dangerouslySetInnerHTML={Utils.highlightSearchText(subject.title, searchFilter)} />}
                          defaultExpanded={subject.topics.some((topic) => topic.active)}
                        >
                          {subject.topics?.map((topic) => (
                            <div key={topic.id} className={styles.topicContainer}>
                              <GtnCheckbox name={`${topic.id}`} label={topic.title} />
                              {/*<p dangerouslySetInnerHTML={Utils.highlightSearchText(topic.title, searchFilter)} />*/}
                            </div>
                          ))}
                        </GtnTreeParent>
                      ))}
                    </>
                  ))}
                </GtnTreeView>

                {!filteredCompetencies?.length && <p>{t('list-empty')}</p>}
              </div>
            </>
          );
        }}
      </GtnForm>
    );
  } else {
    content = <LoadingIndicatorInline />;
  }

  return (
    <CourseStudentSelectorSidebar selectionMode="course-only">
      {content}
      <hideAllExamplesInitiallyDialog.Component />
      <GtnSnackbar message={snackbarMessage} open={!!snackbarMessage} onClose={() => setSnackbarMessage('')} />
    </CourseStudentSelectorSidebar>
  );
}
