import { MoodleQuiz } from '@gtn/common/api/model/quizzes/MoodleQuiz';
import { QuizAttempt } from '@gtn/common/api/model/quizzes/QuizAttempt';
import { QuizAttemptData } from '@gtn/common/api/model/quizzes/QuizAttemptData';
import { singleton } from 'tsyringe';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { BaseMoodleAPI } from '@gtn/common/api/BaseMoodleAPI';
import { MoodleCourse } from '@gtn/common/api/model/moodle-core/MoodleCourse';
import { MoodleNotificationResponse } from '@gtn/common/api/model/moodle-core/MoodleNotification';
import { MoodleUser } from '@gtn/common/api/model/moodle-core/MoodleUser';

@singleton()
export class MoodleQuizAPI extends BaseMoodleAPI {
  public getQuizzes = async (courseIds?: number[]): Promise<MoodleQuiz[]> => {
    let payload: object = {};
    if (courseIds) {
      payload = this.createArrayParams('courseids', courseIds);
    }

    const result = await this.moodleWebservice.mod_quiz_get_quizzes_by_courses(payload);
    return result.quizzes;
  };

  public getQuizAttempts = async (quizId: number, userId?: number): Promise<QuizAttempt[]> => {
    const result = await this.moodleWebservice.mod_quiz_get_user_attempts({
      userid: userId,
      quizid: quizId,
      status: 'all',
    });
    return result.attempts;
  };

  public getQuizBestGrade = async (quizId: number, userId: number = 0) =>
    this.moodleWebservice.mod_quiz_get_user_best_grade({
      userid: userId,
      quizid: quizId,
    });

  public startQuizAttempt = async (quizId: number): Promise<QuizAttempt> => {
    const result = await this.moodleWebservice.mod_quiz_start_attempt({
      quizid: quizId,
    });
    return result.attempt;
  };

  public getQuizAttempt = async (attemptId: number, pageNumber: number = 0): Promise<QuizAttemptData> =>
    this.moodleWebservice.mod_quiz_get_attempt_data({
      attemptid: attemptId,
      page: pageNumber,
    });

  public processQuizAnswer = async (attemptId: number, data) =>
    this.moodleWebservice.mod_quiz_process_attempt({
      attemptid: attemptId,
      data,
    });

  public getQuizAttemptSummary = async (attemptId: number) =>
    this.moodleWebservice.mod_quiz_get_attempt_summary({
      attemptid: attemptId,
    });

  public finishQuizAttempt = async (attemptId: number, data) =>
    this.moodleWebservice.mod_quiz_process_attempt({
      attemptid: attemptId,
      data,
      finishattempt: 1,
    });
}
