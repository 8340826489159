import { IBaseConfig } from '@gtn/common/config/IBaseConfig';

export enum AppType {
  DIGGR_PLUS = 'diggr-plus',
  SET = 'set',
  DAKORA = 'dakora',
}

export interface IAppConfig extends IBaseConfig {
  appType: AppType;

  hideNavigationItems?: string[];

  showLoginTutorial?: boolean;
  pspdfLicenceKey?: string;

  predefinedMoodleInstances?: MoodleInstance[];

  errorTracking?: {
    sentry?: {
      dsn: string;
    };
  };
}

export interface MoodleInstance {
  displayName?: string;
  url: string;
}
