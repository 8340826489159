import React from 'react';
import { Notifications } from '@gtn/app-common/routes/profile/notifications/Notifications';
import {
  useAppDispatch,
  useAppCommonSelector,
} from '@gtn/app-common/store/app.store.hooks';
import { navigationActions } from '@gtn/app-common/store/navigation.state';
import GtnDrawer from '@gtn/common/components/navigation/gtn-drawer/GtnDrawer';
import styles from '@gtn/app-common/components/app-navigation/AppNavigation.module.scss';

export function NotificationDrawer() {
  const dispatch = useAppDispatch();
  const notificationsDrawerOpened = useAppCommonSelector(
    (state) => state.navigation.notificationsDrawerOpened
  );

  const closeDrawer = () =>
    dispatch(navigationActions.setNotificationsDrawerOpened(false));

  return (
    <GtnDrawer
      isOpen={notificationsDrawerOpened}
      onClose={closeDrawer}
      side="right"
      className={styles.notificationDrawer}
    >
      <Notifications onAllNotificationsMarkedAsRead={closeDrawer} />
    </GtnDrawer>
  );
}
