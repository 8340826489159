import { AppConfigManager } from '@gtn/app-common/config/AppConfigManager';
import { MoodleWebservice } from '@gtn/common/api/webservice/MoodleWebservice';
import { ConfigManagerToken } from '@gtn/common/config/ConfigManager';
import { TranslationManager } from '@gtn/common/i18n/TranslationManager';
import { useUser } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation, useQueryParams } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { Utils } from '@gtn/common/utils/Utils';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import React, { useEffect, useRef, useState } from 'react';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { initPspdfInstance } from '@gtn/app-common/components/pdf-viewer/PdfViewer';

export const AssignmentGrading: React.FC = () => {
  const t = useAppTranslation();
  const containerRef = useRef(null);
  const configManager = InjectionContainer.resolve<AppConfigManager>(ConfigManagerToken);
  const translationManager = InjectionContainer.resolve(TranslationManager);
  const [pspdfInstance, setPspdfInstance] = useState<any>();
  const moodleAPI = InjectionContainer.resolve(MoodleWebservice);
  const user = useUser();

  const [annotationsChanged, _setAnnotationsChanged] = useState(false);
  const annotationsChangedRef = useRef(false);
  const setAnnotationsChanged = (changed) => _setAnnotationsChanged((annotationsChangedRef.current = changed));

  const queryParams = useQueryParams();

  const [fileid, setFileid] = useState(queryParams.fileid);

  const {
    data: fileInfos,
    mutate: reloadFileInfo,
    error,
  } = useAPI(moodleAPI.assignfeedback_exapdf_get_file_infos, [
    {
      fileid,
    },
  ]);

  useEffect(() => {
    if (!fileInfos) {
      return;
    }

    setAnnotationsChanged(fileInfos.annotations_changed);

    let instantJSON = null;
    if (fileInfos.annotations) {
      instantJSON = JSON.parse(fileInfos.annotations);
    }

    const container = containerRef.current;
    let instance, PSPDFKit;

    let messageReceived: any;

    (async function () {
      PSPDFKit = await import('pspdfkit');
      instance = await PSPDFKit.load({
        licenseKey: configManager.getConfig().pspdfLicenceKey,
        // Container where PSPDFKit should be mounted.
        container,
        locale: translationManager.getLanguage(),
        document: fileInfos.submission_file_url + '?token=' + user.authTokens?.moodleMobile,
        enableClipboardActions: true,
        enableHistory: true,
        instantJSON,
        autoSaveMode: PSPDFKit.AutoSaveMode.IMMEDIATE, // PSPDFKit.AutoSaveMode.INTELLIGENT,
        // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
        baseUrl: `${window.location.protocol}//${window.location.host}${Utils.normalizeBasePath(configManager.getConfig().basePath)}/`,
      });


      // store page rotations
      // const pageRotations = [] as number[];

      // try {
      //   let operations = [] as any[];
      //   for (let pageIndex = 0; pageIndex < pageRotations.length; pageIndex++) {
      //     if (pageRotations[pageIndex]) {
      //       operations.push({
      //         type: 'rotatePages',
      //         pageIndexes: [pageIndex],
      //         rotateBy: pageRotations[pageIndex],
      //       });
      //     }
      //   }

      //   if (operations.length) {
      //     await instance.applyOperations(operations);
      //   }
      // } catch (e) {
      //   console.log(e);
      // }

      setPspdfInstance(instance);
      await initPspdfInstance(PSPDFKit, instance);

      // async function rotatePage(rotateBy) {
      //   const pageIndex = instance.viewState.currentPageIndex;

      //   await instance.applyOperations([
      //     {
      //       type: 'rotatePages',
      //       pageIndexes: [pageIndex],
      //       rotateBy,
      //     },
      //   ]);

      //   pageRotations[pageIndex] = (pageRotations[pageIndex] || 0) + rotateBy;
      //   if (pageRotations[pageIndex] >= 360) {
      //     pageRotations[pageIndex] = 0;
      //   }
      //   if (pageRotations[pageIndex] < 0) {
      //     pageRotations[pageIndex] = 360 + pageRotations[pageIndex];
      //   }

      //   saveAnnotations();
      // }

      // https://pspdfkit.com/guides/web/user-interface/main-toolbar/create-a-new-tool/
      // instance.setToolbarItems((items) => {
      //   // rotate page buttons
      //   items.push({
      //     type: 'custom',
      //     id: 'rotate-left',
      //     title: 'Rotate Left',
      //     icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z"/></svg>',
      //     onPress: () => rotatePage(-90),
      //   });
      //   items.push({
      //     type: 'custom',
      //     id: 'rotate-right',
      //     title: 'Rotate Right',
      //     icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M463.5 224H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1c-87.5 87.5-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5z"/></svg>',
      //     onPress: () => rotatePage(90),
      //   });

      //   return items;
      // });

      const saveAnnotations = Utils.debounce(
        async () => {
          const instantJSON = await instance.exportInstantJSON();
          const annotations = JSON.stringify(instantJSON);

          // console.log(pageRotations);
          moodleAPI.assignfeedback_exapdf_save_annotations({
            fileid,
            annotations,
          });
        },
        1000,
        { leading: true, trailing: true }
      );

      instance.addEventListener('document.saveStateChange', async (event) => {
        if (!event.hasUnsavedChanges) {
          setAnnotationsChanged(true);
          saveAnnotations();

          // inform parent frame about changed document
          // window.parent?.postMessage(
          //   {
          //     app: 'dakoraplus',
          //     type: 'document_changed',
          //     fileid,
          //   },
          //   '*'
          // );
        }
      });

      async function saveDocument() {
        if (!annotationsChangedRef.current) {
          // nothing to save
          return;
        }

        const content = await instance.exportPDF();

        try {
          const blob = new Blob([content], { type: 'application/pdf' });
          const file = new File([blob], 'tmp.pdf', { type: 'application/pdf' });

          const fileUploadResponse = await moodleAPI.uploadFile(file);
          const response = await moodleAPI.assignfeedback_exapdf_save_annotated({
            fileid,
            draftitemid: fileUploadResponse.itemid,
          });
        } catch (e) {
          GtnLogger.warn(e);
        }
      }

      messageReceived = async (event) => {
        const data = event.data;
        if (data && data.app == 'dakoraplus') {
          // handle
          console.log('dakora received message:', data);
          if (data.type == 'load_file') {
            // if (data.fileid == fileid) {
            //   // gleiches file wird nochmals aufgerufen => reload
            //   reloadFileInfo();
            // } else {
            setFileid(data.fileid);
            // }
          } else if (data.type == 'savechanges' || data.type == 'saveandshownext') {
            // save buttons in moodle
            await saveDocument();

            setAnnotationsChanged(false);

            window.parent?.postMessage(
              {
                app: 'dakoraplus',
                type: data.type,
              },
              '*'
            );
          }
        }
      };

      window.addEventListener('message', messageReceived);

      window.parent?.postMessage(
        {
          app: 'dakoraplus',
          type: 'dakoraplus_loaded',
        },
        '*'
      );
    })();

    return () => {
      PSPDFKit && PSPDFKit.unload(container);
      window.removeEventListener('message', messageReceived);
      messageReceived = null;
    };
  }, [fileInfos]);

  if (error) {
    return <div style={{ padding: 20, fontWeight: 'bold' }}>Aufgabe kann nicht geöffnet werden!</div>;
  }

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div ref={containerRef} style={{ width: '100%', flex: '1' }} />
      {annotationsChanged && (
        <div style={{ position: 'absolute', bottom: 10, right: 30, backgroundColor: 'yellow', zIndex: 100000, fontWeight: 'bold', borderRadius: 10, padding: '5px 10px', border: '1px solid #999' }}>
          Noch nicht gespeichert!
        </div>
      )}
    </div>
  );
};
