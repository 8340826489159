import { GradingInput } from '@gtn/app-common/components/grading/GradingInput';
import { MoodleWebservice } from '@gtn/common/api/webservice/MoodleWebservice';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { GtnAvatar } from '@gtn/common/components/gtn-avatar/GtnAvatar';
import LoadingContainer from '@gtn/common/components/loading-container/LoadingContainer';
import GtnDialog, { GtnDialogProps } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { Utils } from '@gtn/common/utils/Utils';
import React from 'react';
import styles from './OtherTeacherGradingsDialog.module.scss';

interface OtherTeacherGradingsDialogProps extends GtnDialogProps {
  compid: number;
  comptype: number;
  userid: number;
}

export function OtherTeacherGradingsDialog(props: OtherTeacherGradingsDialogProps) {
  const t = useAppTranslation();

  const moodleWebservice = InjectionContainer.resolve(MoodleWebservice);
  const { data: gradings, progressState } = useAPI(moodleWebservice.diggrplus_get_all_competency_gradings, [
    {
      compid: props.compid,
      comptype: props.comptype,
      userid: props.userid,
    },
  ]);

  return (
    <GtnDialog {...props} title={t('competency-gradings.title')}>
      <LoadingContainer state={progressState} style={{ justifyContent: 'start' }}>
        {gradings?.map((grading) => (
          <div className={styles.gradingRow}>
            <GtnAvatar imageUrl={grading.reviewer?.profileimageurl} name={grading.reviewer?.fullname} />
            <h3>{grading.reviewer?.fullname || t('competency-gradings.unknown-user')}</h3>

            <p>{Utils.toDate(grading.timestamp).toLocaleString()}</p>
            <GradingInput readonly={true} grading={grading.grading} gradingType="teacher" />
          </div>
        ))}
      </LoadingContainer>
    </GtnDialog>
  );
}
