import { CompetenceType } from "../api/model/CompetenceType";

export function convertComptypeStringToNumber(elementtype: string) {
  if (elementtype == 'descriptor' || elementtype == 'childdescriptor') {
    return CompetenceType.Descriptor;
  } else if (elementtype == 'topic') {
    return CompetenceType.Topic;
  } else if (elementtype == 'crosssubject') {
    return CompetenceType.CrossSubject;
  } else if (elementtype == 'subject') {
    return CompetenceType.Subject;
  } else if (elementtype == 'example') {
    return CompetenceType.Example;
  } else {
    throw new Error(`elementtyp '${elementtype} not supported`);
  }
}
