import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { MoodleCoreAPI } from '@gtn/common/api/MoodleCoreAPI';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';

export const useUnreadItemNotifications = () => {
  const moodleAPI = InjectionContainer.resolve(MoodleCoreAPI);
  const { data: notificationData, revalidate: reloadNotifications } = useAPI(
    moodleAPI.getExacompNotifications,
    [],
    {
      revalidateOnFocus: true,
      refreshInterval: 60 * 1000,
    }
  );
  const notifications = notificationData?.notifications;
  const unreadNotificationsForItemId: any = {};
  notifications?.forEach((notification) => {
    if (notification.read) {
      return;
    }
    if (notification.customdata?.itemid) {
      if (!unreadNotificationsForItemId[notification.customdata?.itemid]) {
        unreadNotificationsForItemId[notification.customdata?.itemid] = [];
      }
      unreadNotificationsForItemId[notification.customdata?.itemid].push(
        notification
      );
    }
  });

  return {
    hasUnreadNotifications: (itemId) => !!unreadNotificationsForItemId[itemId],
    markNotificationRead: async (itemId) => {
      if (unreadNotificationsForItemId[itemId]) {
        // mark all notifications related to this item as read
        await Promise.all(
          unreadNotificationsForItemId[itemId].map((notification) =>
            moodleAPI.markNotificationRead(notification.id)
          )
        );

        // reload
        reloadNotifications();
      }
    },
  };
};
