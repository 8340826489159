import { createPartialBaseConfig, SHARED_BASE_CONFIG } from '@gtn/common/config/default-base.config';

export const DEFAULT_APP_CONFIG = createPartialBaseConfig({
  ...SHARED_BASE_CONFIG,
  version: { name: '1.6.1', code: 160 },
  minRequiredExacompVersion: 2023061700, // letztes eduvidual update
  moodleUrl: 'https://apps.gtn-solutions.com',
  i18n: {
    defaultLanguage: 'de',
    availableLanguages: [
      { displayName: 'Deutsch', code: 'de' },
      { displayName: 'English', code: 'en' },
    ],
    translations: {},
  },
  predefinedMoodleInstances: [
    {
      displayName: 'eduvidual.at',
      url: 'https://www.eduvidual.at',
    },
    {
      displayName: 'eEducation Austria',
      url: 'https://community.eeducation.at',
    },
    {
      displayName: 'Denkebildung.eu (Demo Access)',
      url: 'https://denkebildung.eu/moodle/',
    },
  ],
  errorTracking: {
    sentry: {
      dsn: 'https://95e21d3c672146d688595e20e5774888@o1048362.ingest.sentry.io/6033440',
    },
  },
});
