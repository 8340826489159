import { IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { Attachment, Create, Delete, PictureAsPdfTwoTone } from '@material-ui/icons';
import React from 'react';
import { StyleProps } from '@gtn/common/components/StyleProps';
import GtnFileUpload from '@gtn/common/components/forms/gtn-file-upload/GtnFileUpload';
import styles from '@gtn/common/components/forms/gtn-file-manager/GtnFileManager.module.scss';

export interface GtnFile {
  name: string;
  type: string;

  id?: number;
  url?: string;
}

export interface GtnFileManagerProps {
  files?: GtnFile[];
  disabled?: boolean;

  onFilesAdded?(files: GtnFile[]): void;
  onFileRemoved?(file: GtnFile): void;
  onEditFile?(file: GtnFile): void;

  openFile?(file: GtnFile): boolean;
}

export default function GtnFileManager(props: GtnFileManagerProps & StyleProps) {
  const [files, setFiles] = React.useState<File[]>([]);

  function isImage(file: GtnFile) {
    return file.type.includes('image');
  }

  function canEditFile(file: GtnFile) {
    return file.type.includes('image') || file.type.includes('pdf');
  }

  function getFilePreview(file: GtnFile) {
    if (isImage(file)) {
      if (file.url) {
        return <img src={file.url} />;
      } else {
        return <img src={URL.createObjectURL(file)} />;
      }
    } else if (file.type.includes('pdf')) {
      return <PictureAsPdfTwoTone className={styles.fileIcon} />;
    }
    return <Attachment className={styles.fileIcon} />;
  }

  function openFile(file: GtnFile) {
    if (props.openFile?.(file)) {
      return;
    }

    let url = file.url || URL.createObjectURL(file);
    if (url) {
      window.open(url);
    }
  }

  function onChange(updateFileList: File[]) {
    // figure out, which files were newly added
    const newlyAddedFiles = updateFileList.filter((file) => files.indexOf(file) === -1);
    // save files for next time
    setFiles(updateFileList);
    // call callback with only newly added files
    props.onFilesAdded?.(newlyAddedFiles);
  }

  return (
    <div className={styles.container + ' ' + props.className} style={props.style}>
      {props.files?.map((file) => (
        <div className={styles.filePreview} key={file.name} onClick={() => openFile(file)}>
          <Tooltip title={file.name}>{getFilePreview(file)}</Tooltip>

          {!props.disabled && (
            <div className={styles.actionContainer}>
              <IconButton
                onClick={(e) => {
                  props.onFileRemoved?.(file);
                  e.stopPropagation();
                }}
              >
                <Delete />
              </IconButton>

              {canEditFile(file) && props.onEditFile && (
                <IconButton
                  onClick={(e) => {
                    props.onEditFile?.(file);
                    e.stopPropagation();
                  }}
                >
                  <Create />
                </IconButton>
              )}
            </div>
          )}
        </div>
      ))}
      {!props.disabled && (
        <GtnFileUpload
          className={styles.filePreview}
          onChange={onChange}
          // because of a bug we just allow adding any number of files
          filesLimit={9999}
        />
      )}
    </div>
  );
}
