import { useAppConfig } from '@gtn/app-common/config/AppConfigHooks';
import { AppConfigManager } from '@gtn/app-common/config/AppConfigManager';
import { CommonRoutingPaths } from '@gtn/app-common/routes/AppCommonRouting';
import Settings from '@gtn/app-common/routes/settings/Settings';
import styles from '@gtn/app-common/routes/settings/Settings.module.scss';
import { useAppCommonSelector, useAppDispatch } from '@gtn/app-common/store/app.store.hooks';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import GtnCheckbox from '@gtn/common/components/forms/GtnCheckbox';
import GtnForm from '@gtn/common/components/forms/GtnForm';
import { useIsTeacher } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation, useHistory } from '@gtn/common/utils/HookUtils';
import { DakoraRoutingPaths } from '@gtn/dakora/routes/DakoraRouting';
import { appDAKORAActions } from '@gtn/dakora/store/DakoraState';
import { useDakoraSelector } from '@gtn/dakora/store/DakoraStore';
import { ServerInfoManager } from '@gtn/app-common/utils/ServerInfoManager';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import React from 'react';
import { Utils } from '@gtn/common/utils/Utils';

export function DakoraSettings() {
  const serverInfo = InjectionContainer.resolve(ServerInfoManager).getServerInfo();
  const t = useAppTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const selfDiagnosisEnabled = useDakoraSelector((state) => state.appdakora.enableSelfDiagnosis);
  const moodleUrl = useAppCommonSelector((state) => state.preferences.moodleUrl);
  const isTeacher = useIsTeacher();
  const shouldShowExperienceSettings = moodleUrl?.includes('denkebildung.eu') || Utils.getCookie('feature_experience_level');

  return (
    <Settings>
      <GtnForm>
        <GtnCheckbox
          name="enableSelfDiagnosis"
          checked={selfDiagnosisEnabled}
          label={t('settings.enable-self-diagnosis')}
          onChange={(e) => dispatch(appDAKORAActions.setSelfDiagnosis(e.target.checked))}
        />
      </GtnForm>

      {isTeacher && (
        <>
          <hr style={{ marginTop: 30, marginBottom: 30 }} />
          <h2>{t('settings.competence-areas')}</h2>
          <p className={styles.competenceFieldsHelp}>{t('settings.choose-competencies-help')}</p>
          <GtnButton actionType="primary" label={t('settings.choose-competencies')} onClick={() => history.push(CommonRoutingPaths.COURSE_TOPICS)} />
        </>
      )}

      {isTeacher && shouldShowExperienceSettings && (
        <>
          <hr style={{ marginTop: 30, marginBottom: 30 }} />
          <h2>{t('experience-level.title')}</h2>
          <p className={styles.competenceFieldsHelp}>{t('experience-level.help')}</p>
          <GtnButton actionType="primary" label={t('experience-level.choose')} onClick={() => history.push(DakoraRoutingPaths.EXPERIENCE_LEVEL_SETTINGS)} />
        </>
      )}

      {isTeacher && serverInfo?.enrolcode_enabled && (
        <>
          <hr style={{ marginTop: 30, marginBottom: 30 }} />
          <h2>{t('invite-students.title')}</h2>
          {/* <p className={styles.competenceFieldsHelp}>{t('settings.choose-competencies-help')}</p> */}
          <GtnButton actionType="primary" label={t('invite-students.title')} onClick={() => history.push(CommonRoutingPaths.CREATE_ENROLCODE)} />
        </>
      )}
    </Settings>
  );
}
