export enum ErrorLogLevel {
	Info,
	Warning,
	Error,
}

export interface IErrorTracker {
	init(): void;

	setTag(key: string, value?: string): void;

	trackLog(message: string, level: ErrorLogLevel, args?: Array<any>): void;

	trackError(error: any): void;

	sendManuallyTriggeredReport(): void;
}
