import { CheckboxProps } from '@material-ui/core';
import { CheckCircle, RadioButtonUnchecked } from '@material-ui/icons';
import { Field, FieldAttributes } from 'formik';
import { CheckboxWithLabel as MaterialCheckbox } from 'formik-material-ui';
import React from 'react';

export interface GtnCheckboxProps extends FieldAttributes<any>, CheckboxProps {
  disabled?: boolean;
  name: string;
  label?: string;
  indeterminate?: boolean;
}

export default function GtnCheckbox(props: GtnCheckboxProps) {
  return (
    <Field
      component={MaterialCheckbox}
      Label={{ label: props.label }}
      type="checkbox"
      disabled={props.disabled ?? false}
      icon={<RadioButtonUnchecked />}
      checkedIcon={<CheckCircle />}
      color="primary"
      style={{ marginRight: 0, ...props.style }}
      indeterminate={props.indeterminate}
      {...props}
    />
  );
}
