import { useSharedSelector } from '@gtn/common/store/shared.store.hooks';
import { UserRole } from '@gtn/common/store/user/user.state';

export const useUser = () => useSharedSelector((state) => state.user);

export const useIsTeacher = () =>
  useSharedSelector((state) => state.user.profile?.role === UserRole.Teacher);
export const useRole = () =>
  useSharedSelector((state) =>
    state.user.profile?.role === UserRole.Teacher
      ? UserRole.Teacher
      : UserRole.Student
  );
