import { useSelectedCourse, useSelectedStudents } from '@gtn/app-common/store/app.store.hooks';
import { ServerInfoManager } from '@gtn/app-common/utils/ServerInfoManager';
import { useIsTeacher, useRole } from '@gtn/common/store/user/user.hooks';
import { UserRole } from '@gtn/common/store/user/user.state';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { ChooseLearningPathExample } from '@gtn/dakora/routes/learning-paths/choose-learning-path-example/ChooseLearningPathExample';
import { PlanningStorage } from '@gtn/dakora/routes/learning-plans/planning-storage/PlanningStorage';
import { DakoraExacompVersions } from '@gtn/dakora/utils/DakoraExacompVersions';
import classNames from 'classnames';
import React, { useState } from 'react';
import styles from './PlanningStorageLearningPathsSwitcher.module.scss';

interface PlanningStorageLearningPathsSwitcherProps {
  planningStorageUserId: number;
  hideDescription?: boolean;
}

export default function PlanningStorageLearningPathsSwitcher(props: PlanningStorageLearningPathsSwitcherProps) {
  const t = useAppTranslation();
  const isTeacher = useIsTeacher();
  const role = useRole();
  const serverInfoManager = InjectionContainer.resolve(ServerInfoManager);

  const selectedCourse = useSelectedCourse();
  const selectedStudents = useSelectedStudents();

  const [sidebarView, setSidebarView] = useState<'planning-storage' | 'learning-paths'>('planning-storage');

  if (!selectedCourse?.id) {
    return <></>;
  }

  function getTitle(userId?: number) {
    if (isTeacher && userId && selectedStudents?.length) {
      return selectedStudents[0].fullname;
    }
    return t(`planning-storage.${role}.title`);
  }

  return (
    <div className={styles.planningStorageLearningPathsSwitcherContainer}>
      {serverInfoManager.hasMinRequiredExacompVersion(DakoraExacompVersions.MIN_VERSION_REPORTS_LEARNING_PATHS) && (
        <div className={styles.planningStorageLearningPathsSwitcher}>
          <p onClick={() => setSidebarView('planning-storage')} className={classNames({ [styles.active]: sidebarView === 'planning-storage' })}>
            {t('planning-storage.title')}
          </p>

          <p onClick={() => setSidebarView('learning-paths')} className={classNames({ [styles.active]: sidebarView === 'learning-paths' })}>
            {t.withRole('menu.learning-paths')}
          </p>
        </div>
      )}

      {sidebarView === 'learning-paths' && (
        <ChooseLearningPathExample
          courseId={selectedCourse.id}
          userId={isTeacher && selectedStudents?.length === 1 ? selectedStudents[0].id : 0}
          title={getTitle(selectedStudents?.[0]?.id)}
          description={props.hideDescription ? '' : t(`learning-paths.drag-drop-description`)}
        />
      )}

      {sidebarView === 'planning-storage' && (
        <PlanningStorage
          courseId={selectedCourse.id}
          userId={props.planningStorageUserId}
          title={getTitle(props.planningStorageUserId ?? selectedStudents?.[0]?.id)}
          description={props.hideDescription ? '' : t(`planning-storage.${role}.description`)}
          hideItemStatus={isTeacher}
        />
      )}
    </div>
  );
}
