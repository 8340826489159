import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import { ExamplesTreeDescriptor } from '@gtn/app-common/api/model/ExamplesTreeResponse';
import styles from '@gtn/app-common/components/examples/choose-descriptor-dialog/ChooseDescriptorDialog.module.scss';
import { useAppCommonSelector } from '@gtn/app-common/store/app.store.hooks';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import GtnCheckbox from '@gtn/common/components/forms/GtnCheckbox';
import GtnForm from '@gtn/common/components/forms/GtnForm';
import { GtnTreeNode, GtnTreeParent, GtnTreeView } from '@gtn/common/components/gtn-tree-view/GtnTreeView';
import GtnDialog, { DialogProps } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { Utils } from '@gtn/common/utils/Utils';
import React, { useEffect } from 'react';

interface ChooseDescriptorFormValues {
  [key: number]: boolean;
}

export interface ChooseDescriptorDialogProps {
  chosenDescriptors?: Partial<ExamplesTreeDescriptor>[];
  onDescriptorsChosen: (descriptors?: ExamplesTreeDescriptor[]) => void;

  showChildDescriptors?: boolean;
}

export function ChooseDescriptorDialog(props: ChooseDescriptorDialogProps & DialogProps) {
  const t = useAppTranslation();
  const selectedCourse = useAppCommonSelector((state) => state.navigation.selectedCourse);
  const [initialFormValues, setInitialFormValues] = React.useState<ChooseDescriptorFormValues>();

  const appCommonAPI = InjectionContainer.resolve(AppCommonAPI);
  const { data: subjects } = useAPI(appCommonAPI.getExamplesForCourse, [selectedCourse?.id ?? 0]);

  useEffect(() => {
    const formValues: ChooseDescriptorFormValues = {};
    props.chosenDescriptors?.forEach((descriptor) => (formValues[descriptor.id ?? ''] = true));

    setInitialFormValues(formValues);
  }, [props.chosenDescriptors]);

  const onSave = (values: ChooseDescriptorFormValues) => {
    const descriptors = subjects?.flatMap((subject) =>
      subject.topics.flatMap((topic) => {
        const result: ExamplesTreeDescriptor[] = [];
        topic.descriptors.forEach((d) => {
          result.push(d);
          if (d.childdescriptors) {
            result.push(...d.childdescriptors);
          }
        });
        return result;
      })
    );

    const chosenDescriptors = Object.keys(values)
      .filter((key) => values[key])
      .map((key) => descriptors?.find((descriptor) => descriptor.id === Number(key)))
      .filter(Utils.filterNullOrUndefined);

    props.onDescriptorsChosen(chosenDescriptors);
    props.onClose?.();
  };

  const renderDescriptor = (descriptor: ExamplesTreeDescriptor) => {
    if (props.showChildDescriptors && descriptor.childdescriptors?.length) {
      return (
        <GtnTreeNode id={'desc-' + descriptor.id} node={<GtnCheckbox className={styles.title} style={{ paddingLeft: 16 }} name={String(descriptor.id)} label={descriptor.title} />}>
          {descriptor.childdescriptors?.map((childDescriptor) => renderDescriptor(childDescriptor))}
        </GtnTreeNode>
      );
    }
    return (
      <div key={'desc-' + descriptor.id} className={styles.exampleContainer}>
        <GtnCheckbox className={styles.title} name={String(descriptor.id)} label={descriptor.title} />
      </div>
    );
  };

  return (
    <GtnDialog {...props} title={t('create-edit-example.add-competencies')}>
      <GtnForm className={styles.dialogContentContainer} initialValues={initialFormValues} onSubmit={onSave}>
        {(formHelper) => (
          <>
            <GtnTreeView className={styles.scrollContainer}>
              {subjects?.map((subject) => (
                <GtnTreeParent id={'subj-' + subject.id} defaultExpanded={subjects.length === 1} node={<h2>{subject.title}</h2>}>
                  {subject.topics?.map((topic) => (
                    <GtnTreeNode id={'topic-' + topic.id} node={<h3 className={styles.topicTitle}>{topic.title}</h3>}>
                      {topic.descriptors?.map((descriptor) => renderDescriptor(descriptor))}
                    </GtnTreeNode>
                  ))}
                </GtnTreeParent>
              ))}
            </GtnTreeView>

            <div className={styles.submitContainer}>
              <GtnButton
                type="button"
                actionType="primary"
                label={t('ok')}
                disabled={!formHelper.dirty}
                onClick={async () => {
                  await formHelper.setValues({ ...formHelper.values }, true);
                  await formHelper.submitForm();
                }}
              />
            </div>
          </>
        )}
      </GtnForm>
    </GtnDialog>
  );
}
