import { StyleProps } from '@gtn/common/components/StyleProps';
import classNames from 'classnames';
import React from 'react';
import { GtnChooserOption } from '@gtn/common/components/gtn-chooser/GtnChooser';
import styles from './GtnSwitchButtons.module.scss';

interface GtnSwitchButtonsProps<T> extends StyleProps {
  disabled?: boolean;
  options: GtnChooserOption<T>[];
  selected?: GtnChooserOption<T>;
  onSelected?: (option: GtnChooserOption<T>) => void;
}

export const GtnSwitchButtons = (props: GtnSwitchButtonsProps<any>) => {
  return (
    <div className={classNames(styles.container, props.className)} style={props.style}>
      {props.options.map((option) => (
        <div
          onClick={() => !option.disabled && props.onSelected?.(option)}
          className={classNames({ [styles.item]: true, [styles.selected]: props.selected?.id === option.id, [styles.disabled]: option.disabled })}
        >
          {option.title}
        </div>
      ))}
    </div>
  );
};
