import { Avatar } from '@material-ui/core';
import React from 'react';
import { StyleProps } from '@gtn/common/components/StyleProps';

export interface GtnAvatarProps extends StyleProps {
  imageUrl?: string;
  name?: string;
}

export function GtnAvatar(props: GtnAvatarProps) {
  return (
    <Avatar
      src={props.imageUrl}
      className={props.className}
      style={props.style}
    >
      {props.name?.substr(0, 1)}
    </Avatar>
  );
}
