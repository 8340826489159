import { MoodleQuizAPI } from '@gtn/common/api/MoodleQuizAPI';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import LoadingContainer from '@gtn/common/components/loading-container/LoadingContainer';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { ProgressState } from '@gtn/common/utils/ProgressState';
import { QuizQuestion } from '@gtn/dakora/routes/quizzes/quiz/conduct-quiz/quiz-question/QuizQuestion';
import { useRef, useState } from 'react';
import styles from './ConductQuiz.module.scss';

export interface ConductQuizProps {
  quizId: number;
  attemptId: number;
  initialPageNumber?: number;

  onFinished?: () => void;
}
export function ConductQuiz(props: ConductQuizProps) {
  const t = useAppTranslation();
  const moodleQuizAPI = InjectionContainer.resolve(MoodleQuizAPI);
  const formRef = useRef<HTMLFormElement>(null);

  const [pageNumber, setPageNumber] = useState(props.initialPageNumber ?? 0);

  const { data: attempt, progressState } = useAPI(moodleQuizAPI.getQuizAttempt, [props.attemptId, pageNumber]);
  const hasNextPage = attempt && attempt.nextpage > -1;

  const processAnswer = async (nextPageNumber: number) => {
    try {
      await moodleQuizAPI.processQuizAnswer(props.attemptId, getValues());
      setPageNumber(nextPageNumber);
    } catch (e) {
      GtnLogger.warn(e);
    }
  };

  const finishAttempt = async () => {
    try {
      await moodleQuizAPI.finishQuizAttempt(props.attemptId, getValues());
      props.onFinished?.();
    } catch (e) {
      GtnLogger.warn(e);
    }
  };

  const getValues = () => {
    const values: { name: string; value: any }[] = [];
    if (formRef.current) {
      const formData = new FormData(formRef.current);
      formData.forEach((value, key) => {
        values.push({ name: key, value });
      });
    }
    return values;
  };

  return (
    <form ref={formRef} className={styles.form}>
      <div className={styles.scrollContainer}>
        <LoadingContainer state={progressState} className={styles.container}>
          {attempt && <QuizQuestion questionHTML={attempt.questions[0].html} />}
        </LoadingContainer>
      </div>

      <div className={styles.submitContainer}>
        <GtnButton disabled={pageNumber === 0} label={t('Vorherige Frage')} actionType="secondary" onClick={() => processAnswer(pageNumber - 1)} />
        {hasNextPage && <GtnButton disabled={progressState !== ProgressState.Content} label={t('Nächste Frage')} actionType="primary" onClick={() => processAnswer(pageNumber + 1)} />}
        {!hasNextPage && <GtnButton disabled={progressState !== ProgressState.Content} label={t('Abschließen')} actionType="primary" onClick={() => finishAttempt()} />}
      </div>
    </form>
  );
}
