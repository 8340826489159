import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import React from 'react';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import { ProgressState } from '@gtn/common/utils/ProgressState';
import { ChildrenProps } from '@gtn/common/components/ChildrenProps';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import { StyleProps } from '@gtn/common/components/StyleProps';
import styles from './LoadingContainer.module.scss';

interface LoadingContainerProps extends ChildrenProps, StyleProps {
  state: ProgressState;
  onRetryClick?: () => void;

  emptyText?: string;
  errorText?: string;

  [key: string]: any;
}

export default function LoadingContainerV2(props: LoadingContainerProps) {
  const t = useAppTranslation();

  let containerStyles = {};
  if (props.state === ProgressState.Loading) {
    containerStyles = { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' };
  }

  return (
    <div className={classNames(props.className)} style={{ ...props.style, ...containerStyles }} {...props}>
      {props.state === ProgressState.Loading && <CircularProgress />}
      {props.state === ProgressState.Empty && <p className={styles.emptyText}>{props.emptyText ?? t('list-empty')}</p>}
      {props.state === ProgressState.Error && (
        <>
          <p className={styles.errorText}>{props.errorText ?? t('list-error-loading')}</p>
          {props.onRetryClick && <GtnButton label={t('retry')} onClick={props.onRetryClick} />}
        </>
      )}
      {props.state === ProgressState.Content && props.children}
    </div>
  );
}
