export enum CommonRoutingPaths {
  HOME = '/',
  PROFILE = 'profile',
  SETTINGS = 'settings',
  MS_TEAMS_TAB = 'teams-tab',
  MS_TEAMS_AUTH = 'teams-tab-auth',
  MS_TEAMS_CONFIG = 'teams-tab-config',
  MS_TEAMS_SSO_START = 'teams-tab-sso-start',
  LOGIN = 'login',
  LOGOUT = 'logout',
  COMPETENCE_PROFILE = 'competency-profile',
  WORKBOOK = 'workbook',
  EXAMPLES = 'examples',
  COURSE_TOPICS = 'competence-areas',
  CREATE_ENROLCODE = 'create-enrolcode',
}

export enum CommonRoutingParams {
  MS_TEAMS_NOTIFY_AUTH_SUCCESS = 'ms_teams_notify_auth_success',
  MOODLE_TOKEN = 'moodle_token',
  DO_LOGIN = 'do_login',
  MOODLE_URL = 'moodle_url',
  // MOODLE_USERID = 'moodle_userid',
  RECHECK_LOGIN = 'recheck_login',
  ALLOW_CHANGE_MOODLE_URL = 'allow_change_moodle_url',
  CONFIG = 'config',
  ENROL_CODE = 'enrol_code',
}
