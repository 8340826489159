import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import CourseStudentSelectorSidebar from '@gtn/app-common/components/app-navigation/course-student-selection/sidebar/CourseStudentSelectorSidebar';
import { useSelectedCourse, useSelectedStudents } from '@gtn/app-common/store/app.store.hooks';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import GtnCheckbox from '@gtn/common/components/forms/GtnCheckbox';
import GtnDateField from '@gtn/common/components/forms/GtnDateField';
import GtnForm from '@gtn/common/components/forms/GtnForm';
import { GtnChooserOption } from '@gtn/common/components/gtn-chooser/GtnChooser';
import { GtnSwitchButtons } from '@gtn/common/components/gtn-switch-buttons/GtnSwitchButtons.component';
import { GtnTreeParent, GtnTreeView } from '@gtn/common/components/gtn-tree-view/GtnTreeView';
import LoadingContainer from '@gtn/common/components/loading-container/LoadingContainerV2';
import { useAppTranslation, useForceUpdate } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { ProgressState } from '@gtn/common/utils/ProgressState';
import { DakoraAPI } from '@gtn/dakora/api/DakoraAPI';
import { IconButton } from '@material-ui/core';
import { VisibilityOff, VisibilityOutlined } from '@material-ui/icons';
import { FormikHelpers } from 'formik';
import React, { useMemo, useState } from 'react';
import styles from './Reports.module.scss';
import { MoodleWebservice } from '@gtn/common/api/webservice/MoodleWebservice';
import { date } from 'yup';
import { dakoraplus_create_report_parameters_result_type, dakoraplus_create_report_parameters_output_style } from '@gtn/common/api/webservice/MoodleWebserviceDefinitions';

interface ReportOptionsFormValues {
  withChildDescriptors: boolean;
  withExamples: boolean;
  showOnlyAchievedCompetencies: boolean;
  outputStyle: dakoraplus_create_report_parameters_output_style;
  dateRestriction: boolean;
  fromDate?: string;
  toDate?: string;
}

const initialReportOptions: ReportOptionsFormValues = {
  outputStyle: dakoraplus_create_report_parameters_output_style.LIST,
  dateRestriction: false,
  withChildDescriptors: false,
  withExamples: false,
  showOnlyAchievedCompetencies: false,
};

const Label = ({ children }) => <h3 style={{ fontWeight: 'bold', marginTop: 10 }}>{children}</h3>;
const InputGroup = ({ children }) => <div style={{ paddingLeft: 20, marginBottom: 15 }}>{children}</div>;

export default function Reports() {
  const t = useAppTranslation();
  const selectedCourse = useSelectedCourse();
  const selectedStudents = useSelectedStudents();
  const forceUpdate = useForceUpdate();
  const [hiddenCompetencies, setHiddenCompetencies] = useState<number[]>([]);
  const [generateReportState, setGenerateReportState] = useState(ProgressState.Empty);

  const moodleAPI = InjectionContainer.resolve(MoodleWebservice);
  const sharedAPI = InjectionContainer.resolve(AppCommonAPI);
  const { data: subjects, progressState } = useAPI(sharedAPI.getSubjectsAndTopicsForUser, [selectedCourse?.id]);

  const formatOptions: GtnChooserOption<string>[] = useMemo(() => {
    return [
      { id: dakoraplus_create_report_parameters_output_style.LIST, title: t('reports.format.list') },
      { id: dakoraplus_create_report_parameters_output_style.GRID, title: t('reports.format.grid') },
    ];
  }, []);

  const createReport = async (values: ReportOptionsFormValues, formHelper: FormikHelpers<Partial<ReportOptionsFormValues>>) => {
    setGenerateReportState(ProgressState.Loading);
    formHelper.setSubmitting(true);
    try {
      const time_from = values.dateRestriction && values.fromDate ? Math.floor(new Date(values.fromDate).getTime() / 1000) : 0;
      const time_to = values.dateRestriction && values.toDate ? Math.floor(new Date(values.toDate).getTime() / 1000) + 24 * 60 * 60 : 0;

      const topicids = ([] as number[])
        .concat(...(subjects?.map((subject) => subject.topics.filter((d) => d.visible).map((topic) => topic.id)) || []))
        .filter((id) => !hiddenCompetencies.includes(id));

      const params = {
        courseid: selectedCourse?.id!,
        studentids: selectedStudents?.map((u) => u.id) || undefined,
        topicids,
        with_childdescriptors: values.withChildDescriptors,
        with_examples: values.withExamples,
        only_achieved_competencies: values.showOnlyAchievedCompetencies,
        time_from,
        time_to,
        output_style: values.outputStyle,
        result_type: dakoraplus_create_report_parameters_result_type.PDF,
      };
      // this line does nothing, but typescript parser checks if the params are correct
      false && moodleAPI.dakoraplus_create_report(params);

      if (false) {
        // TODO: html
        const string = await moodleAPI.dakoraplus_create_report(params);
      } else {
        const url = moodleAPI.getWsfunctionUrl('dakoraplus_create_report', params);
        window.open(url);
      }

      setGenerateReportState(ProgressState.Content);
    } catch (e) {
      GtnLogger.warn(e);
      setGenerateReportState(ProgressState.Error);
    }
    formHelper.setSubmitting(false);
  };

  function renderVisibilityToggle(id: number) {
    const isVisible = !hiddenCompetencies.includes(id);
    return (
      <IconButton
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          let newCompetencies = [...hiddenCompetencies];
          if (isVisible) {
            newCompetencies.push(id);
          } else {
            newCompetencies = newCompetencies.filter((cid) => cid !== id);
          }
          setHiddenCompetencies(newCompetencies);
        }}
        color={isVisible ? 'primary' : undefined}
      >
        {isVisible ? <VisibilityOutlined /> : <VisibilityOff />}
      </IconButton>
    );
  }

  function renderTopicsChooser() {
    return (
      <LoadingContainer
        state={progressState}
        style={{
          alignItems: progressState === ProgressState.Content ? 'stretch' : 'center',
        }}
      >
        <GtnTreeView className={styles.scrollContainer}>
          {subjects?.map((subject) => (
            <GtnTreeParent id={subject.id} defaultExpanded={subjects.length === 1} node={<h2 className={styles.title}>{subject.title}</h2>}>
              {subject.topics
                ?.filter((d) => d.visible)
                ?.map((topic, topicIndex) => (
                  <p key={topicIndex} className={styles.topicContainer}>
                    {renderVisibilityToggle(topic.id)}
                    {topic.title}
                  </p>
                ))}
            </GtnTreeParent>
          ))}
        </GtnTreeView>
      </LoadingContainer>
    );
  }

  return (
    <>
      <CourseStudentSelectorSidebar selectionMode="multiple-students">
        <GtnForm initialValues={initialReportOptions} className={styles.optionsContainer} onSubmit={(values, formHelper) => createReport(values as ReportOptionsFormValues, formHelper)}>
          {(formHelper) => (
            <>
              <h3 style={{ fontWeight: 'bold' }}>{t('reports.report-info')}</h3>
              <Label>{t('reports.output-style-label')}</Label>
              <InputGroup>
                <GtnSwitchButtons
                  selected={{ id: formHelper.values['outputStyle'] } as any}
                  options={formatOptions}
                  style={{ minHeight: 40 }}
                  onSelected={(option) => {
                    formHelper.setFieldValue('outputStyle', option.id, false);
                    forceUpdate();
                  }}
                />
              </InputGroup>

              <Label>{t('reports.report-content-label')}</Label>
              <InputGroup>
                <div style={{ margin: '2px 0 4px 0', fontSize: '1rem' }}>{t('reports.target-competencies-info')}</div>
                <div>
                  <GtnCheckbox name="withChildDescriptors" label={t('reports.show-childdescriptors')} />
                </div>
                <div>
                  <GtnCheckbox name="withExamples" label={t('reports.show-examples')} />
                </div>
              </InputGroup>

              <Label>{t('reports.filter-label')}</Label>
              <InputGroup>
                <GtnCheckbox name="showOnlyAchievedCompetencies" label={t('reports.show-only-achieved-competences')} />
                <div className={styles.dateRangeContainer}>
                  <GtnCheckbox name="dateRestriction" label={t('reports.date-restriction')} />
                  <GtnDateField name="fromDate" placeholder={t('reports.from-date')} disabled={!formHelper.values['dateRestriction']} />
                  <GtnDateField name="toDate" placeholder={t('reports.to-date')} disabled={!formHelper.values['dateRestriction']} />
                </div>
              </InputGroup>

              {selectedCourse && (
                <>
                  <Label>{t('competence-areas')}</Label>
                  <InputGroup>{renderTopicsChooser()}</InputGroup>
                </>
              )}

              <GtnButton
                type="submit"
                actionType="secondary"
                style={{ marginTop: 12 }}
                label={t('reports.create')}
                loading={formHelper.isSubmitting}
                disabled={progressState != ProgressState.Content}
              />
            </>
          )}
        </GtnForm>
      </CourseStudentSelectorSidebar>
    </>
  );
}
