import { combineReducers } from '@reduxjs/toolkit';
import {
  preferencesFeatureKey,
  preferencesReducer,
} from '@gtn/common/store/preferences/preferences.state';
import { userFeatureKey, userReducer } from '@gtn/common/store/user/user.state';

export const StoreToken = Symbol('Store');

// TODO: better way to infer SharedState typings or export shared reducers?
export const sharedReducers = {
  [userFeatureKey]: userReducer,
  [preferencesFeatureKey]: preferencesReducer,
};

const sharedReducer = combineReducers({ ...sharedReducers });
export type SharedState = ReturnType<typeof sharedReducer>;
