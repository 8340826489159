import { PlanningStorageExample } from '@gtn/common/api/model/exacomp';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserPlanningStorageExample extends PlanningStorageExample {
  userId?: number;
}

export interface DraggingExample {
  exampleid: number;
  scheduleid?: number;
  timeframe?: string;
  userId?: number;
}

export interface DakoraState {
  learningPlan: {
    draggingPlanningStorageExample?: UserPlanningStorageExample;
    planningStorageDistributionBuffer?: UserPlanningStorageExample[];
  };
  selectedSubjectId?: number;
  enableSelfDiagnosis: boolean;
  planningStorageCollapsed: boolean;
}

const initialState: DakoraState = {
  learningPlan: {},
  enableSelfDiagnosis: false,
  planningStorageCollapsed: true,
};

export const appDAKORAFeatureKey = 'appdakora';

// Redux Toolkit's createReducer (also createSlice) API uses Immer internally automatically.
// So, it's already safe to "mutate" state inside of any case reducer function that is passed to createReducer:
// https://redux-toolkit.js.org/usage/immer-reducers
const navigationSlicer = createSlice({
  name: appDAKORAFeatureKey,
  initialState,
  reducers: {
    setDraggingExercise: (state, action: PayloadAction<UserPlanningStorageExample | undefined>): DakoraState => {
      state.learningPlan.draggingPlanningStorageExample = action.payload;
      return state;
    },

    // TODO: remove logic, because there is no client side planning storage?
    resetPlanningStorageDistributionBuffer: (state): DakoraState => {
      return { ...state, learningPlan: { ...state.learningPlan, planningStorageDistributionBuffer: undefined } };
    },

    // TODO: remove logic, because there is no client side planning storage?
    addExampleToPlanningStorageDistributionBuffer: (state, action: PayloadAction<UserPlanningStorageExample>): DakoraState => {
      const buffer = state.learningPlan.planningStorageDistributionBuffer?.slice() ?? [];
      buffer.push(action.payload);
      return { ...state, learningPlan: { ...state.learningPlan, planningStorageDistributionBuffer: buffer } };
    },

    // TODO: remove logic, because there is no client side planning storage?
    removeExampleFromPlanningStorageDistributionBuffer: (state, action: PayloadAction<number>): DakoraState => {
      const buffer = state.learningPlan.planningStorageDistributionBuffer?.filter((e) => e.scheduleid !== action.payload);
      return { ...state, learningPlan: { ...state.learningPlan, planningStorageDistributionBuffer: buffer } };
    },

    setSelectedSubjectId: (state, action: PayloadAction<number | undefined>): DakoraState => {
      state.selectedSubjectId = action.payload;
      return state;
    },

    setSelfDiagnosis: (state, action: PayloadAction<boolean>): DakoraState => {
      state.enableSelfDiagnosis = action.payload;
      return state;
    },

    setPlanningStorageCollapsed: (state, action: PayloadAction<boolean>): DakoraState => {
      state.planningStorageCollapsed = action.payload;
      return state;
    },

    reset: (state): DakoraState => {
      return { ...initialState };
    },
  },
});

export const appDAKORAActions = navigationSlicer.actions;
export const appDAKORAReducer = navigationSlicer.reducer;
