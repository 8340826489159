import { AnnotateExampleRequest } from '@gtn/app-common/api/model/AnnotateExampleRequest';
import { CompetenceProfile } from '@gtn/app-common/api/model/CompetenceProfile';
import { CompetenceType } from '@gtn/app-common/api/model/CompetenceType';
import { CreateEditExampleRequest } from '@gtn/app-common/api/model/CreateEditExampleRequest';
import { ExacompDescriptor } from '@gtn/app-common/api/model/ExacompDescriptor';
import { ExacompNiveau } from '@gtn/app-common/api/model/ExacompNiveau';
import { ExampleAndItemResponse, ItemStatus } from '@gtn/app-common/api/model/ExampleAndItemResponse';
import { ExampleOverviewResponse } from '@gtn/app-common/api/model/ExampleOverviewResponse';
import { ExamplesTreeResponse } from '@gtn/app-common/api/model/ExamplesTreeResponse';
import { GradeDescriptorRequest } from '@gtn/app-common/api/model/GradeDescriptorRequest';
import { GradeElementRequest } from '@gtn/app-common/api/model/GradeElementRequest';
import { GradeItemRequest } from '@gtn/app-common/api/model/GradeItemRequest';
import { ItemComment } from '@gtn/app-common/api/model/ItemComment';
import { SchooltypeTreeResponse } from '@gtn/app-common/api/model/SchooltypeTreeResponse';
import { StudentEnrolCode } from '@gtn/app-common/api/model/StudentEnrolCode';
import { SubmitItemRequest } from '@gtn/app-common/api/model/SubmitItemRequest';
import { BaseMoodleAPI } from '@gtn/common/api/BaseMoodleAPI';
import { ExacompSubject } from '@gtn/common/api/model/exacomp/ExacompSubject';
import { ItemOperationResponse } from '@gtn/common/api/model/ItemOperationResponse';
import { UserRole } from '@gtn/common/store/user/user.state';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { Utils } from '@gtn/common/utils/Utils';
import { singleton } from 'tsyringe';
import { ServerInfoManager } from '../utils/ServerInfoManager';
import { convertComptypeStringToNumber } from '../utils/utils';

@singleton()
export class AppCommonAPI extends BaseMoodleAPI {
  public getServerInfo = () => this.moodleWebservice.diggrplus_get_config();

  public getUserInfo = async (): Promise<{ role: string }> => {
    try {
      return await this.moodleWebservice.diggrplus_get_user_info({});
    } catch (e) {
      // fallback to old getUserInfo (for old exacomp versions without the getUserInfo service / eg. eduvidual)
      const userRole = await this.moodleWebservice.block_exacomp_get_user_role({});

      return {
        role: userRole.role === 1 ? UserRole.Teacher : UserRole.Student,
      };
    }
  };

  public getSubjectsAndTopicsForUser = async (courseId: number = -1, userId: number = 0): Promise<ExamplesTreeResponse> => {
    return this.moodleWebservice.diggrplus_get_all_subjects_for_course_as_tree({
      userid: userId,
      courseid: courseId,
    });
  };

  public getCourseSchooltypeTree = (courseid: number): Promise<SchooltypeTreeResponse> =>
    this.moodleWebservice.diggrplus_get_course_schooltype_tree({
      courseid,
    });

  public exchangeMSTeamsAuthToken = async (authToken: string, tenantId: string): Promise<{ access_token: string }> =>
    this.moodleWebservice.diggrplus_msteams_get_access_token({
      authentication_token: authToken,
      tenantid: tenantId,
    });

  public setActiveCourseTopics = (courseId: number, topicIds: number[], hideExamplesInitially: boolean) =>
    this.moodleWebservice.diggrplus_set_active_course_topics({
      courseid: courseId,
      topicids: topicIds,
      hide_new_examples: hideExamplesInitially,
    });

  public getExamplesForCourse = async (courseId: number, userId: number = 0, subjectId?: number, $select?: string): Promise<ExamplesTreeResponse> => {
    return this.moodleWebservice.diggrplus_get_all_subjects_for_course_as_tree({
      courseid: courseId,
      userid: userId,
      subjectid: subjectId,
      $select,
    });
  };

  public getDescriptorsForExample = async (courseId: number, exampleId: number, userId = 0, mindVisibility: boolean = true): Promise<ExacompDescriptor[]> =>
    this.moodleWebservice.diggrplus_get_descriptors_for_example({
      courseid: courseId,
      exampleid: exampleId,
      userid: userId,
      forall: false,
      mindvisibility: mindVisibility,
    });

  public getUserCompetenceProfile = async (courseId: number, userId: number = 0): Promise<CompetenceProfile> => {
    return this.moodleWebservice.diggrplus_get_competence_profile_statistic({
      courseid: courseId,
      userid: userId,
    });
  };

  public getDescriptorsForExampleItem = async (courseId: number, userId = 0, exampleId?: number, topicId?: number): Promise<ExacompDescriptor[] | undefined> => {
    if (exampleId != null) {
      return await this.getDescriptorsForExample(courseId, exampleId, userId);
    } else {
      // TODO create API to get descriptors for topic with evaluations
      const competenceProfile = await this.getUserCompetenceProfile(courseId, userId);
      for (const subject of competenceProfile.competencetree) {
        for (const topic of subject.topics) {
          if (topic.id === topicId) {
            return topic.descriptors.map((d) => ({
              ...d,
              topicid: topic.id,
              descriptorid: d.id,
              niveauid: 0,
            }));
          }
        }
      }
      return undefined;
    }
  };

  public submitItem = async (submitItem: SubmitItemRequest): Promise<ItemOperationResponse> => {
    return this.moodleWebservice.diggrplus_submit_item({
      ...submitItem,
      filenames: this.toList(submitItem.filenames),
      fileitemids: this.toList(submitItem.fileitemids),
      collabuserids: this.toList(submitItem.collabuserids),
      removefiles: this.toList(submitItem.removefiles),
      descriptorgradings: submitItem.descriptorgradings,
      submit: submitItem.submit ? 1 : 0,
    });
  };

  public getLastH5pActivityResult = async (exampleId: number) => {
    return this.moodleWebservice.dakora_get_example_h5p_activity_results({ exampleid: exampleId });
  };

  public getItemComments = async (itemId?: number): Promise<ItemComment[]> => {
    if (!itemId) {
      return [];
    }

    return this.moodleWebservice.diggrplus_get_item_comments({
      itemid: itemId,
    });
  };

  public submitComment = async (itemId: number, comment: string, fileItemId?: number): Promise<ItemOperationResponse> => {
    return this.moodleWebservice.diggrplus_submit_item_comment({ itemid: itemId, fileitemid: fileItemId, comment });
  };

  public deleteExample = async (id: number) => {
    return this.moodleWebservice.diggrplus_delete_custom_example({ exampleid: id });
  };

  public updateVisibility = async (courseId: number, ids: number[], visible: boolean, type: 'example' | 'childdescriptor' | 'descriptor' | 'topic'): Promise<void> => {
    const params = {
      ids: this.toList(ids),
      courseid: courseId,
      userid: 0,
      forall: true,
      visible,
    };

    if (type == 'topic') {
      await this.moodleWebservice.diggrplus_set_topic_visibility(params);
    } else if (type == 'descriptor') {
      await this.moodleWebservice.diggrplus_set_descriptor_visibility(params);
    } else if (type == 'childdescriptor') {
      await this.moodleWebservice.diggrplus_set_descriptor_visibility(params);
    } else if (type == 'example') {
      await this.moodleWebservice.diggrplus_set_example_visibility(params);
    } else {
      throw `type ${type} not allowed`;
    }
  };

  public gradeItem = async (grading: GradeItemRequest): Promise<any> => {
    return this.moodleWebservice.diggrplus_grade_item({
      ...grading,
    });
  };

  public gradeDescriptor = async (grading: GradeDescriptorRequest): Promise<any> => {
    return this.moodleWebservice.diggrplus_grade_descriptor({
      descriptorid: grading.descriptorId,
      grading: grading.grading,
      courseid: grading.courseId,
      userid: grading.userId,
      role: grading.role === UserRole.Teacher ? 1 : 0,
      subjectid: -1,
    });
  };

  public gradeElement = async (grading: GradeElementRequest): Promise<any> => {
    return this.moodleWebservice.diggrplus_grade_competency({
      compid: grading.id,
      comptype: convertComptypeStringToNumber(grading.type),
      grading: grading.grading,
      courseid: grading.courseId,
      userid: grading.userId,
      role: grading.role === UserRole.Teacher ? 1 : 0,
    });
  };

  public getStudentExamplesAndItems = async (
    itemType: string = '',
    competenceType: CompetenceType = CompetenceType.Descriptor,
    competenceId: number = 0,
    niveauId: number = 0,
    search: string = '',
    status: ItemStatus | null,
    courseId?: number
  ): Promise<ExampleAndItemResponse[]> => {
    const response = await this.moodleWebservice.diggrplus_get_examples_and_items({
      type: itemType,
      comptype: competenceType,
      compid: competenceId,
      niveauid: niveauId,
      search,
      status,
      userid: 0,
      courseid: courseId,
    });

    this.cacheStudentCommentUrls(response);

    return response;
  };

  public studentGetExampleAndItem = async (exampleid: number, courseid: number, exampleTitle?: string): Promise<ExampleAndItemResponse> => {
    const serverInfoManager = InjectionContainer.resolve(ServerInfoManager);
    if (serverInfoManager.hasMinRequiredExacompVersion(2023061700)) {
      // new call
      return this.moodleWebservice.dakoraplus_get_example_and_item({ exampleid, courseid });
    } else {
      // old logic for BW legacy versions
      // search with exampleTitle
      const response = await this.moodleWebservice.diggrplus_get_examples_and_items({
        type: '',
        comptype: CompetenceType.Descriptor,
        compid: 0,
        niveauid: 0,
        search: exampleTitle,
        status: null,
        userid: 0,
      });
      return response[0];
    }
  };

  public teacherGetStudentExampleAndItem = async (studentid: number, exampleid: number, courseid: number, exampleTitle?: string): Promise<ExampleAndItemResponse> => {
    const serverInfoManager = InjectionContainer.resolve(ServerInfoManager);
    if (serverInfoManager.hasMinRequiredExacompVersion(2023061700)) {
      // new call
      return this.moodleWebservice.dakoraplus_get_teacher_example_and_item({ studentid, exampleid, courseid });
    } else {
      // old logic for BW legacy versions
      // search with exampleTitle
      const response = await this.moodleWebservice.diggrplus_get_teacher_examples_and_items({
        type: '',
        comptype: CompetenceType.Descriptor,
        compid: 0,
        niveauid: 0,
        search: exampleTitle,
        status: null,
        courseid: courseid,
        studentid: studentid,
      });

      this.cacheStudentCommentUrls(response);

      return response[0];
    }
  };

  public getTeacherExamplesAndItems = async (
    itemType: string = '',
    competenceType: CompetenceType = CompetenceType.Descriptor,
    competenceId: number = 0,
    niveauId: number = 0,
    search: string = '',
    status: ItemStatus | null,
    courseId: number = 0,
    studentId: number = 0
  ): Promise<ExampleAndItemResponse[]> => {
    const response = await this.moodleWebservice.diggrplus_get_teacher_examples_and_items({
      comptype: competenceType,
      compid: competenceId,
      type: itemType,
      niveauid: niveauId,
      search,
      status,
      courseid: courseId,
      studentid: studentId,
    });

    this.cacheStudentCommentUrls(response);

    return response;
  };

  public getSubjectsForCourse = async (courseId: number) => {
    return this.moodleWebservice.dakora_get_subjects({
      courseid: courseId,
    });
  };

  public getNiveausForSubject = async (subjectId: number): Promise<ExacompNiveau[]> => {
    return this.moodleWebservice.diggrplus_get_niveaus_for_subject({
      subjectid: subjectId,
    });
  };

  public createEditExample = async (example: CreateEditExampleRequest) => {
    return this.moodleWebservice.diggrplus_create_or_update_example({
      ...example,
      removefiles: this.toList(example.removefiles),
      taxonomies: this.toList(example.taxonomies),
      fileitemids: example.fileitemids ? this.toList(example.fileitemids) : undefined,
      comps: example.comps ? this.toList(example.comps) : undefined,
    });
  };

  public annotateExample = async (exampleAnnotation: AnnotateExampleRequest) => {
    return this.moodleWebservice.diggrplus_annotate_example(exampleAnnotation);
  };

  public getExample = async (courseId: number, exampleId: number, userId: number = 0): Promise<ExampleOverviewResponse> => {
    return this.moodleWebservice.diggrplus_get_example_overview({
      courseid: courseId,
      exampleid: exampleId,
      userid: userId,
    });
  };

  private cacheStudentCommentUrls(response: { item?: { solutiondescription?: string; extractedStudentCommentUrls?: string[] } }[]) {
    if (response && response?.length > 0) {
      response.forEach((item) => {
        if (item.item?.solutiondescription) {
          item.item.extractedStudentCommentUrls = Utils.extractUrls(item.item.solutiondescription);
        }
      });
    }
  }

  public getRequestExternalFileUrl(url: string) {
    if (url.match(/^http:\/\/(komet\.|www\.)eeducation\.at/)) {
      // Hack: für eeducation urls immer https
      // da eduvidual server nur über https auf eeducation zugreifen kann
      console.warn(`changing eeducation url ${url} to https`);
      url = url.replace(/^http:/, 'https:');
    }

    return this.moodleWebservice.getWsfunctionUrl('diggrplus_request_external_file', {
      url,
    });
  }

  public getStudentEnrolCode = async (courseId: number): Promise<StudentEnrolCode | null> => {
    return this.moodleWebservice.diggrplus_get_student_enrolcode({
      courseid: courseId,
    });
  };

  public createStudentEnrolCode = async (courseId: number): Promise<StudentEnrolCode> => {
    return this.moodleWebservice.diggrplus_create_student_enrolcode({
      courseid: courseId,
    });
  };

  public enrolByCode = async (enrolCode: string) => {
    return this.moodleWebservice.diggrplus_enrol_by_enrolcode({
      code: enrolCode,
    });
  };

  public setItemStatus = async (itemId: number, status: ItemStatus) => {
    return this.moodleWebservice.diggrplus_set_item_status({
      itemid: itemId,
      status: status as any,
    });
  };
}
