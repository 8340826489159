import { ExamplesTreeExample } from '@gtn/app-common/api/model/ExamplesTreeResponse';
import { ExamplesList } from '@gtn/app-common/components/examples-list/ExamplesList';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import { GtnSelectionIndicator } from '@gtn/common/components/gtn-selection-indicator/GtnSelectionIndicator.component';
import { useIsTeacher } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import AssignmentIcon from '@material-ui/icons/Assignment';
import classNames from 'classnames';
import React from 'react';
import styles from './ChooseExamples.module.scss';

export interface ChooseExamplesProps {
  courseId: number;
  primaryButtonText?: string;
  secondaryButtonText?: string;

  onPrimaryButtonClick(examples: ExamplesTreeExample[]): Promise<void>;
  onSecondaryButtonClick?: (examples: ExamplesTreeExample[]) => Promise<void>;

  onClose(): void;
}

export function ChooseExamples(props: ChooseExamplesProps) {
  const t = useAppTranslation();
  const isTeacher = useIsTeacher();

  const [error, setError] = React.useState<any>(null);
  const [selectedExamples, setSelectedExamples] = React.useState<ExamplesTreeExample[]>([]);

  const onSave = async (callback: (examples: ExamplesTreeExample[]) => Promise<void>, example?: ExamplesTreeExample) => {
    setError(null);

    try {
      await callback(example ? [example] : selectedExamples);
      props.onClose?.();
    } catch (exception) {
      setError(exception);
      GtnLogger.warn(exception);
    }
  };

  function toggleExampleSelected(example: ExamplesTreeExample) {
    if (selectedExamples.some((e) => e.id === example.id)) {
      setSelectedExamples(selectedExamples.filter((e) => e.id !== example.id));
    } else {
      setSelectedExamples([...selectedExamples, example]);
    }
  }

  return (
    <div className={classNames(styles.dialogContentContainer)} style={{ height: 'calc(100% - 48px)' }}>
      <ExamplesList
        className={styles.scrollContainer}
        courseId={props.courseId}
        showCreateExample={isTeacher}
        showEditExample={isTeacher}
        hideNotVisible={!isTeacher}
        showExamples={true}
        renderPrefix={(node) => {
          if (node.type === 'example') {
            return <AssignmentIcon color="primary" style={{ margin: '4px 12px' }} />;
          }
          return <></>;
        }}
        renderSuffix={(node) => {
          if (node.type === 'example') {
            return <GtnSelectionIndicator onClick={() => toggleExampleSelected(node as any)} isSelected={selectedExamples.some((e) => e.id === node.id)} style={{ margin: '0 12px' }} />;
          }
          return <></>;
        }}
        onExampleClicked={(e) => {
          // const isMultiSelect = selectedExamples.length > 0;
          // if (!isMultiSelect) {
          //   onSave(e);
          // } else {

          // always multiselect
          toggleExampleSelected(e);

          // }
          return true;
        }}
      />

      {selectedExamples.length > 0 && (
        <div className={styles.submitContainer}>
          <p>{error && t('save-failed')}</p>

          {props.onSecondaryButtonClick && (
            <GtnButton
              type="button"
              actionType="secondary"
              label={t(props.secondaryButtonText ?? 'add')}
              disabled={selectedExamples.length === 0}
              onClick={async () => onSave(props.onSecondaryButtonClick!)}
            />
          )}

          <GtnButton type="button" actionType="primary" label={t(props.primaryButtonText ?? 'add')} disabled={selectedExamples.length === 0} onClick={async () => onSave(props.onPrimaryButtonClick)} />
        </div>
      )}
    </div>
  );
}
