import {
  ConfigManager,
  ConfigManagerToken,
} from '@gtn/common/config/ConfigManager';
import { TranslationManager } from '@gtn/common/i18n/TranslationManager';
import { preferencesActions } from '@gtn/common/store/preferences/preferences.state';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import styles from './LanguageChooser.module.scss';

export function LanguageChooser() {
  const t = useAppTranslation();
  const config =
    InjectionContainer.resolve<ConfigManager>(ConfigManagerToken).getConfig();
  const translationManager = InjectionContainer.resolve(TranslationManager);
  const dispatch = useDispatch();

  const onLanguageChange = (event: any) => {
    const newLanguage = event.target.value;

    translationManager.setLanguage(newLanguage);
    dispatch(preferencesActions.set({ language: newLanguage }));
  };

  return (
    <FormControl variant="outlined" className={styles.formControl}>
      <InputLabel id="language">{t('settings.language')}</InputLabel>
      <Select
        labelId="language"
        value={translationManager.getLanguage()}
        onChange={onLanguageChange}
        label={t('settings.language')}
      >
        {config.i18n.availableLanguages.map((language) => (
          <MenuItem key={language.code} value={language.code}>
            {language.displayName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
