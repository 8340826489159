import { useRef } from 'react';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { ProgressState } from '@gtn/common/utils/ProgressState';

export type APIFunction<T> = (...params) => Promise<T>;

export interface APIReturn<Data> extends SWRResponse<Data, any> {
  progressState: ProgressState;
  isLoading: boolean;
  hasFinished: boolean; // has loading finished, also true if no loading is needed at all!
}

type WSConfig = SWRConfiguration & { useCache?: boolean; enabled?: boolean };

// use JSON.stringify for swr key
// so passing the same value (although its a different object/array) won't result in multiple requests!
const getStringifiedIdentifier = (identifier) => [
  identifier[0], // this is the function itself
  JSON.stringify(identifier), // all the parameters
];

function useAPIsInternal<T>(identifier: string | any[] | null, apis: APIFunction<T>[], config?: WSConfig): APIReturn<T[]> {
  const shouldDoRequest =
    identifier !== null &&
    apis?.length > 0 &&
    // allow same way as in react useQuery
    // https://tanstack.com/query/v4/docs/react/guides/dependent-queries
    config?.enabled !== false;

  const { data, error, ...rest } = useSWR(
    shouldDoRequest ? getStringifiedIdentifier(identifier) : null,
    () => Promise.all(apis.map((api) => api())), // TODO: don't swallow exceptions
    {
      revalidateOnFocus: false,
      ...config,
    }
  );

  let progressState: ProgressState;
  if (error == null) {
    // data is an array when finished loading because of Promise.all
    if (Array.isArray(data)) {
      const hasData = data.some((d) => (Array.isArray(d) ? d.length > 0 : d != null));
      if (hasData) {
        progressState = ProgressState.Content;
      } else {
        progressState = ProgressState.Empty;
      }
    } else {
      progressState = ProgressState.Loading;
    }
  } else {
    progressState = ProgressState.Error;
  }

  // data doesn't need to load or data has loaded
  const hasFinished = !shouldDoRequest || data !== undefined;

  if (error) {
    GtnLogger.error(`useAPI() failed:`, error);
  }

  return {
    data,
    progressState,
    hasFinished,
    isLoading: progressState == ProgressState.Loading,
    error,
    ...rest,
  };
}

export function useAPI<T>(api: () => Promise<T>, params?: [] | null, useSWRConfig?: WSConfig): APIReturn<T>;
export function useAPI<T, Params extends any[]>(api: (...params: Params) => Promise<T>, params: Params | null, useSWRConfig?: WSConfig): APIReturn<T>;
export function useAPI(api, params, config?: WSConfig) {
  if (api === null) {
    params = null;
  }

  // if params === null, then set identifier to null, so the query won't be executed at all
  const identifier = params === null ? null : [api, ...(params ?? [])];

  const random = useRef(Date.now());
  if (identifier != null && config?.useCache === false) {
    identifier.push(random);
  }

  const { data, mutate: mutateAll, ...rest } = useAPIsInternal(identifier, [() => api(...(params ?? []))], config);

  const mutate: typeof mutateAll = async (data?, options?) => {
    const passedData = data;

    if (passedData) {
      return mutateAll(async (data) => {
        if (!data) {
          return data;
        }

        const newData = [...data];

        if (typeof passedData == 'function') {
          newData[0] = await passedData(data[0] as any);
        } else {
          newData[0] = await passedData;
        }

        return newData;
      }, options);
    } else {
      // just reload
      return mutateAll();
    }
  };

  return { data: data?.[0] as any, mutate, mutateAll, ...rest };
}

// export function useAPICallback(callback: Function | null, dependencies?: any[]) {
//   const unique = useRef(new Date().getTime());

//   // calculate the identifier from the callback and dependencies
//   let identifier: string | null = null;
//   if (callback) {
//     console.log(dependencies);
//     identifier = JSON.stringify([unique.current, callback.toString(), ...(dependencies || [])]);
//   }

//   return useSWR(identifier, callback as any, {
//     revalidateOnFocus: false,
//   });
// }

// export function conditionalUseAPI<T, Params extends any[]>(api?: (...params: Params) => Promise<T>, params?: Params | null, useSWRConfig?: WSConfig) {
//   return {
//     shouldFetch: api !== null && params !== null,
//     use: () => {
//       // @ts-ignore
//       const ret = useAPI(api, params, useSWRConfig);
//       console.log('ret', ret);
//       return ret;
//     },
//   };
// }
