import { ExamplesTreeExample } from '@gtn/app-common/api/model/ExamplesTreeResponse';
import { ChooseExamples } from '@gtn/app-common/components/choose-examples/ChooseExamples';
import { MoodleWebservice } from '@gtn/common/api/webservice/MoodleWebservice';
import { Filter } from '@gtn/common/components/filter-bar/FilterBar';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import GtnFileManager, { GtnFile } from '@gtn/common/components/forms/gtn-file-manager/GtnFileManager';
import GtnCheckbox from '@gtn/common/components/forms/GtnCheckbox';
import GtnForm from '@gtn/common/components/forms/GtnForm';
import GtnTextField from '@gtn/common/components/forms/GtnTextField';
import GtnDialog, { GtnDialogProps } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { DakoraAPI } from '@gtn/dakora/api/DakoraAPI';
import { BlockingEvent } from '@gtn/dakora/api/model/BlockingEvent';
import { FreeExample } from '@gtn/dakora/api/model/FreeExample';
import { Tab, Tabs } from '@material-ui/core';
import { AssignmentInd, Event } from '@material-ui/icons';
import AssignmentIcon from '@material-ui/icons/Assignment';
import React, { useState } from 'react';
import * as Yup from 'yup';
import styles from './AddLearningPlanContentDialog.module.scss';

export interface AddLearningPlanContentDialogProps extends GtnDialogProps {
  addToText: string;
  courseId: number;
  userId: number;

  onSave?: (scheduleId?: number, suggestedTimeframe?: string) => void;
}

enum ContentType {
  Example,
  FreeEvent,
  FreeMaterial,
}

export function AddLearningPlanContentDialog(props: AddLearningPlanContentDialogProps) {
  const t = useAppTranslation();

  const contentTypes: Filter<ContentType>[] = [
    {
      value: ContentType.Example,
      displayName: t('planning-storage.task'),
      icon: <AssignmentIcon />,
    },
    {
      value: ContentType.FreeEvent,
      displayName: t('planning-storage.appointment'),
      icon: <Event />,
    },
    { value: ContentType.FreeMaterial, displayName: t('planning-storage.free-material'), icon: <AssignmentInd /> },
  ];

  const [selectedContentType, setSelectedContentType] = useState<ContentType>(ContentType.Example);

  return (
    <GtnDialog {...props} title={t('planning-storage.add', { name: props.addToText })}>
      <Tabs
        value={selectedContentType}
        onChange={(e, contentType) => setSelectedContentType(contentType)}
        centered
        className={styles.tabs}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
      >
        {contentTypes.map((typeFilter, index) => (
          <Tab
            key={index}
            value={typeFilter.value}
            label={
              <div className={styles.tabLabelContainer}>
                {typeFilter.icon} {t(typeFilter.displayName)}
              </div>
            }
          />
        ))}
      </Tabs>

      {selectedContentType === ContentType.Example && <ChooseExamplesForm {...props} />}
      {selectedContentType === ContentType.FreeEvent && <BlockingEventForm {...props} />}
      {selectedContentType === ContentType.FreeMaterial && <FreeMaterialForm {...props} />}
    </GtnDialog>
  );
}

function ChooseExamplesForm(props: AddLearningPlanContentDialogProps) {
  const dakoraAPI = InjectionContainer.resolve(DakoraAPI);
  const moodleWebservice = InjectionContainer.resolve(MoodleWebservice);

  const onSave = async (examples: ExamplesTreeExample[]) => {
    const addToPlanningStorageResult = await Promise.all(
      examples.map(async (example) => {
        const ret = await dakoraAPI.addExampleToPlanningStorage(props.courseId, example.id, props.userId);
        return {
          ...ret,
          example,
        };
      })
    );
    if (addToPlanningStorageResult.some((result) => result.success)) {
      // get timeframe when adding, so the event in the calender has the correct duration
      const { example } = addToPlanningStorageResult[0];
      const { timeframe } = await moodleWebservice.diggrplus_get_example_overview({ courseid: props.courseId, exampleid: example.id, userid: props.userId });

      props.onSave?.(addToPlanningStorageResult[0].scheduleid, timeframe);
      props.onClose?.();
    }
  };

  return <ChooseExamples courseId={props.courseId} onPrimaryButtonClick={onSave} onClose={() => props.onClose?.()} />;
}

export interface AddBlockingEventFormValues {
  title: string;
  description?: string;
  timeframe?: string;
  externalurl?: string;
}

function BlockingEventForm(props: AddLearningPlanContentDialogProps) {
  const t = useAppTranslation();
  const dakoraAPI = InjectionContainer.resolve(DakoraAPI);

  const validationSchema = Yup.object().shape<AddBlockingEventFormValues>({
    title: Yup.string().required().label(t('create-edit-example.example-title')),
    description: Yup.string().label(t('create-edit-example.example-description')),
    timeframe: Yup.string().label(t('create-edit-example.duration')),
    externalurl: Yup.string().label(t('create-edit-example.example-external-url')),
  });

  const [error, setError] = React.useState<any>(null);
  const onSave = async (values: Partial<AddBlockingEventFormValues>, formHelper) => {
    setError(null);

    try {
      const result = await dakoraAPI.addBlockingEventToPlanningStorage(props.courseId, props.userId, values as BlockingEvent);
      if (result.scheduleid != null) {
        props.onSave?.(result.scheduleid, values.timeframe);
        props.onClose?.();
      }
    } catch (exception) {
      setError(exception);
      GtnLogger.warn(exception);
    }

    formHelper.setSubmitting(false);
  };

  return (
    <GtnForm
      onSubmit={onSave}
      validationSchema={validationSchema}
      className={styles.dialogContentContainer}
      style={{ height: 'calc(100% - 48px)' }}
      initialValues={{ title: '', description: '', timeframe: '', externalurl: '' }}
    >
      {(formHelper) => (
        <>
          <div className={styles.scrollContainer}>
            <GtnTextField name="title" label={t('create-edit-example.example-title')} className={styles.formElement} />
            <GtnTextField name="description" label={t('create-edit-example.example-description')} className={styles.formElement} multiline rows={4} />
            <GtnTextField name="timeframe" label={t('create-edit-example.duration')} className={styles.formElement} placeholder={t('create-edit-example.duration-format-info')} />
            <GtnTextField name="externalurl" label={t('create-edit-example.example-external-url')} className={styles.formElement} />
          </div>

          <div className={styles.submitContainer}>
            <p>{error && t('save-failed')}</p>

            <GtnButton
              type="button"
              actionType="primary"
              label={t('add')}
              disabled={!formHelper.dirty}
              onClick={async () => {
                await formHelper.setValues({ ...formHelper.values }, true);
                await formHelper.submitForm();
              }}
            />
          </div>
        </>
      )}
    </GtnForm>
  );
}

function FreeMaterialForm(props: AddLearningPlanContentDialogProps) {
  const t = useAppTranslation();
  const dakoraAPI = InjectionContainer.resolve(DakoraAPI);
  const moodleWebservice = InjectionContainer.resolve(MoodleWebservice);

  const validationSchema = Yup.object().shape<FreeExample>({
    name: Yup.string().required().label(t('create-edit-example.example-title')),
    description: Yup.string().label(t('create-edit-example.example-description')),
    timeframe: Yup.string().label(t('create-edit-example.duration')),
    externalurl: Yup.string().label(t('create-edit-example.example-external-url')),
    isTeacherExample: Yup.boolean(),
  });

  const [files, setFiles] = React.useState<GtnFile[]>([]);
  const [removeFiles, setRemoveFiles] = React.useState<GtnFile[]>([]);
  const [error, setError] = React.useState<any>(null);

  const onSave = async (values: Partial<FreeExample>, formHelper) => {
    setError(null);

    try {
      const filesToUpload = files.filter((file) => file instanceof File);
      const moodleFiles = await Promise.all(filesToUpload.map((file) => moodleWebservice.uploadFile(file as File)));

      const createExampleResult = await dakoraAPI.createFreeExample(props.courseId, {
        name: values.name ?? '',
        description: values.description,
        timeframe: values.timeframe,
        externalurl: values.externalurl,
        isTeacherExample: values.isTeacherExample ?? false,
        fileitemIds: moodleFiles.map((file) => file.itemid),
      });

      if (createExampleResult.exampleid) {
        const addToPlanningStorageResult = await dakoraAPI.addExampleToPlanningStorage(props.courseId, createExampleResult.exampleid, props.userId);

        if (addToPlanningStorageResult.success) {
          props.onSave?.(addToPlanningStorageResult.scheduleid, values.timeframe);
          props.onClose?.();
        }
      }
    } catch (exception) {
      setError(exception);
      GtnLogger.warn(exception);
    }

    formHelper.setSubmitting(false);
  };

  function addFiles(addedFiles: GtnFile[]) {
    const oldFiles = files.filter((file) => addedFiles.some((addedFile) => addedFile.name === file.name));
    const newFiles = files.filter((file) => !oldFiles.some((oldFile) => oldFile === file));
    setFiles([...newFiles, ...addedFiles]);

    setRemoveFiles([...removeFiles, ...oldFiles.filter((oldFile) => oldFile?.id)]);
  }

  return (
    <GtnForm onSubmit={onSave} validationSchema={validationSchema} style={{ height: 'calc(100% - 48px)' }} className={styles.dialogContentContainer}>
      {(formHelper) => (
        <>
          <div className={styles.scrollContainer}>
            <GtnTextField name="name" label={t('create-edit-example.example-title')} className={styles.formElement} />
            <GtnTextField name="description" label={t('create-edit-example.example-description')} className={styles.formElement} multiline rows={4} />
            <GtnTextField name="timeframe" label={t('create-edit-example.duration')} className={styles.formElement} placeholder={t('create-edit-example.duration-format-info')} />
            <GtnTextField name="externalurl" label={t('create-edit-example.example-external-url')} className={styles.formElement} />

            <h3 style={{ marginTop: 12 }}>{t('create-edit-example.files')}</h3>
            <GtnFileManager
              className={styles.formElement}
              files={files}
              onFilesAdded={addFiles}
              onFileRemoved={(removedFile) => {
                if (removedFile.id) {
                  setRemoveFiles([...removeFiles, removedFile]);
                }
                setFiles(files.filter((file) => file !== removedFile));
              }}
            />

            <GtnCheckbox name="isTeacherExample" label={t('create-edit-example.only-for-teacher')} />
          </div>

          <div className={styles.submitContainer}>
            <p>{error && t('create-edit-example.error')}</p>

            <GtnButton
              type="button"
              actionType="primary"
              label={t('add')}
              disabled={!formHelper.dirty}
              onClick={async () => {
                await formHelper.setValues({ ...formHelper.values }, true);
                await formHelper.submitForm();
              }}
            />
          </div>
        </>
      )}
    </GtnForm>
  );
}
