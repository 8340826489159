import {
  CommonRoutingParams,
  CommonRoutingPaths,
} from '@gtn/app-common/routes/AppCommonRouting';
import React from 'react';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import useAsyncEffect from 'use-async-effect';
import { MSTeamsManager } from '@gtn/app-common/microsoft/MSTeamsManager';
import { LoginService } from '@gtn/app-common/api/LoginService';
import { useHistory } from '@gtn/common/utils/HookUtils';
import { useAppCommonSelector } from '@gtn/app-common/store/app.store.hooks';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';

export default function MSTeamsSSOStart() {
  const msTeamsManager = InjectionContainer.resolve(MSTeamsManager);
  const loginService = InjectionContainer.resolve(LoginService);

  const history = useHistory();
  const moodleUrl = useAppCommonSelector(
    (state) => state.preferences.moodleUrl
  );

  useAsyncEffect(async () => {
    setTimeout(async () => {
      GtnLogger.log('Getting teams auth token...');
      const teamsAuthToken = await msTeamsManager.getAuthToken();
      GtnLogger.log('Got teams auth token: ' + teamsAuthToken);
      const loginResult = await loginService.loginWithMSTeamsToken(
        moodleUrl!,
        teamsAuthToken!
      );
      GtnLogger.log('Got login response %o', loginResult);

      if (loginResult.moodle_token) {
        // MS Teams user is already connected with a Moodle user
        const params = new URLSearchParams(window.location.search);
        params.set(CommonRoutingParams.MOODLE_TOKEN, loginResult.moodle_token);
        params.set(
          CommonRoutingParams.MS_TEAMS_NOTIFY_AUTH_SUCCESS,
          String(true)
        );

        history.push(`${CommonRoutingPaths.LOGIN}?${params.toString()}`);
      } else if (loginResult.login_url) {
        // MS Teams user is not yet connected with a Moodle user
        window.location.href = loginResult.login_url;
      } else if (loginResult.error) {
        GtnLogger.log(loginResult.error);
      }
    }, 1000);
  }, []);

  return <>Login wird durchgeführt...</>;
}
