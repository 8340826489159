import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';

export const LoadingIndicatorOverlay = ({ children = null }) => {
  const t = useAppTranslation();

  return (
    <div
      style={{
        textAlign: 'center',
        position: 'fixed',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <CircularProgress />
      <div style={{ marginTop: 20 }}>{children || t('loading')}</div>
    </div>
  );
};

export const LoadingIndicatorInline = ({ children = null }) => {
  const t = useAppTranslation();

  return (
    <div style={{ textAlign: 'center', padding: 80 }}>
      <CircularProgress />
      <div style={{ marginTop: 20 }}>{children || t('loading')}</div>
    </div>
  );
};
