import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import { AddCircleRounded } from '@material-ui/icons';
import { DropzoneArea as MaterialUiDropzone, DropzoneAreaProps } from 'material-ui-dropzone';
import React from 'react';
import { StyleProps } from '@gtn/common/components/StyleProps';
import styles from '@gtn/common/components/forms/gtn-file-upload/GtnFileUpload.module.scss';

const GtnFileUpload = (props: DropzoneAreaProps & { disabled?: boolean } & StyleProps) => {
  const t = useAppTranslation();

  return (
    <div className={props.className} style={props.style}>
      <MaterialUiDropzone
        showPreviews={false}
        showPreviewsInDropzone={false}
        inputProps={{ disabled: props.disabled }}
        Icon={AddCircleRounded as any}
        dropzoneClass={styles.container}
        getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => t('file-too-large-error', { size: `${Math.round(maxFileSize / 1_024 / 1_024)}mb` })}
        maxFileSize={20_000_000}
        dropzoneText=""
        alertSnackbarProps={{ autoHideDuration: 2000 }}
        {...props}
      />
    </div>
  );
};

export default GtnFileUpload;
