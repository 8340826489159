import { BaseMoodleAPI } from '@gtn/common/api/BaseMoodleAPI';
import { Course, CourseExperienceLevel } from '@gtn/common/api/model/Course';
import { AssessmentConfig } from '@gtn/common/api/model/exacomp/AssessmentConfig';
import { ExacompCourseResponse } from '@gtn/common/api/model/exacomp/ExacompCourseResponse';
import { LinkPreviewData } from '@gtn/common/api/model/exacomp/LinkPreviewData';
import { MoodleCoreAPI } from '@gtn/common/api/MoodleCoreAPI';
import { UserRole } from '@gtn/common/store/user/user.state';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { singleton } from 'tsyringe';

@singleton()
export class ExacompAPI extends BaseMoodleAPI {
  private readonly moodleCoreAPI = InjectionContainer.resolve(MoodleCoreAPI);

  public getLinkPreview = async (url: string): Promise<LinkPreviewData> => {
    return this.moodleWebservice.block_exacomp_get_url_preview({
      url,
    });
  };

  public getCourseConfigs = async () => this.moodleWebservice.dakora_get_courseconfigs();

  public getCoursesForUser = async (userId: number = this.getCurrentUserId()): Promise<Course[]> => {
    const moodleCourses = await this.moodleCoreAPI.getCoursesForUser(userId);
    const courseConfigs = await this.getCourseConfigs();
    const exacompCourses = courseConfigs.courses;

    const role = this.store.getState().user?.profile?.role;
    const now = new Date().getTime();

    return (
      moodleCourses
        .map((course) => {
          const exacompCourse = exacompCourses.find((c) => course.id === c.courseid);
          const courseUserRole = exacompCourse?.exarole === 1 ? UserRole.Teacher : UserRole.Student;
          const courseConfig = courseConfigs.courses.find((c) => c.courseid === course.id);

          const mapped: Course = {
            id: course.id,
            shortname: course.shortname,
            displayname: course.fullname,
            fullname: course.fullname,
            visible: course.visible === 1,
            enddate: course.enddate,
            exacompActive: exacompCourse != null,
            role: courseUserRole,
            assessmentConfig:
              courseConfig?.assessment_config != null
                ? courseConfigs.configs.find((config) => config.id === /* assessment_config war in alten exacomp version ein string */ Number(courseConfig.assessment_config))
                : undefined,
            experienceLevel: exacompCourse?.experience_level,
            disabled: course.visible !== 1 || (course.enddate ? course.enddate * 1000 < now : false) || courseUserRole !== role || exacompCourse == null,
          };

          return mapped;
        })
        // only get courses for current role
        // .filter((course) => (course.exarole === 1 ? UserRole.Teacher : UserRole.Student) === role)
        .sort((a, b) => {
          if (a.disabled === b.disabled) {
            return a.displayname.localeCompare(b.displayname);
          }
          return a.disabled ? 1 : -1;
        })
    );
  };

  public getTranslationOverrides = async (lang: string, appType: string = 'diggr-plus'): Promise<object> => {
    return this.moodleWebservice.block_exacomp_get_lang({
      app: appType,
      lang,
    });
  };
}
