import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import CourseStudentSelectorSidebar from '@gtn/app-common/components/app-navigation/course-student-selection/sidebar/CourseStudentSelectorSidebar';
import { ExamplesList } from '@gtn/app-common/components/examples-list/ExamplesList';
import { ExampleVisibilityManager, NavigatableElement } from '@gtn/app-common/components/examples-list/ExampleVisibilityManager';
import { useSelectedCourse } from '@gtn/app-common/store/app.store.hooks';
import { GtnSnackbar } from '@gtn/common/components/GtnSnackbar';
import { useIsTeacher } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation, useForceUpdate } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { IconButton, Tooltip } from '@material-ui/core';
import { Visibility, VisibilityOff, VisibilityOutlined } from '@material-ui/icons';
import React, { useState } from 'react';

export default function Examples() {
  const t = useAppTranslation();
  const selectedCourse = useSelectedCourse();
  const isTeacher = useIsTeacher();
  const forceUpdate = useForceUpdate();

  const appCommonAPI = InjectionContainer.resolve(AppCommonAPI);
  const exampleVisibilityManager = new ExampleVisibilityManager(appCommonAPI, selectedCourse!.id);

  const [visibilityChangeErrorSnackbarOpen, setVisibilityChangeErrorSnackbarOpened] = useState(false);

  async function toggleVisibility(event: any, node: NavigatableElement) {
    event.stopPropagation();

    if (selectedCourse) {
      try {
        await exampleVisibilityManager.toggleNodeVisibility(node);
      } catch (e) {
        setVisibilityChangeErrorSnackbarOpened(true);
        GtnLogger.warn(e);
      }

      forceUpdate();
    }
  }

  function renderVisibilityToggle(node: NavigatableElement) {
    const isVisible = exampleVisibilityManager.shouldNodeByShownAsVisible(node);
    const canChange = !node.used;
    const allChildrenVisible = exampleVisibilityManager.areAllChildrenVisible(node);

    return (
      <Tooltip title={t(canChange ? 'examples.change-visibility' : 'examples.change-visibility-not-allowed') as string}>
        {/* div required to show tooltip even when button is disabled */}
        <div>
          <IconButton onClick={(event) => toggleVisibility(event, node)} disabled={!canChange} color={isVisible ? 'primary' : undefined}>
            {isVisible ? allChildrenVisible ? <Visibility /> : <VisibilityOutlined /> : <VisibilityOff />}
          </IconButton>
        </div>
      </Tooltip>
    );
  }

  return (
    <>
      <CourseStudentSelectorSidebar selectionMode="all-students-only">
        {selectedCourse && (
          <ExamplesList
            courseId={selectedCourse.id}
            renderPrefix={renderVisibilityToggle}
            showCreateExample={isTeacher}
            showEditExample={isTeacher}
            showDeleteExample={isTeacher}
            showExamples={true}
          />
        )}

        <GtnSnackbar textResId="examples.error-changing-visibility" open={visibilityChangeErrorSnackbarOpen} onClose={() => setVisibilityChangeErrorSnackbarOpened(false)} />
      </CourseStudentSelectorSidebar>
    </>
  );
}
