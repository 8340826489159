import { IAppConfig, AppType } from '@gtn/app-common/config/IAppConfig';
import { ConfigManager } from '@gtn/common/config/ConfigManager';

export class AppConfigManager<C extends IAppConfig = IAppConfig> extends ConfigManager<C> {
  constructor(config: C) {
    super(config);
  }

  public override async loadRemoteConfig(name = 'config.json') {
    return super.loadRemoteConfig(`./${name}`);
  }

  public isAppType(...appTypes: AppType[]) {
    return appTypes.some((type) => this.config.appType === type);
  }

  public getAssetPath(asset: string) {
    return `${this.config.basePath}/${asset}`;
  }
}
