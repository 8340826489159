import React from 'react';
import ReactDOM from 'react-dom';
import 'reflect-metadata';
import { IAppConfig } from '@gtn/app-common/config/IAppConfig';
import { AppConfigManager } from '@gtn/app-common/config/AppConfigManager';
import { DEFAULT_DAKORA_CONFIG } from '@gtn/dakora/config/default-dakora.config';
import AppShell from '@gtn/app-common/AppShell';
import '@gtn/app-common/styles/index.scss';
import { ConfigManagerToken } from '@gtn/common/config/ConfigManager';
import { StoreToken } from '@gtn/common/store/shared.store';
import { GtnLogger, LogLevel } from '@gtn/common/utils/logger/GtnLogger';
import { DakoraApp } from 'apps/dakora/src/DakoraApp';
import { dakoraStore } from '@gtn/dakora/store/DakoraStore';
import { container } from 'tsyringe';

GtnLogger.setLogLevel(LogLevel.Info);

container.registerInstance(StoreToken, dakoraStore);
container.registerInstance(ConfigManagerToken, new AppConfigManager<IAppConfig>(DEFAULT_DAKORA_CONFIG));

ReactDOM.render(
  <React.StrictMode>
    <AppShell>
      <DakoraApp />
    </AppShell>
  </React.StrictMode>,
  document.getElementById('root')
);

// logging
// this matches live site dakoraplus.eu
// does not match dev.dakoraplus.eu or dakoraplus.eu:3000 (for testing)
if (window.location.host.match(/^(www\.)?dakoraplus\.eu$/)) {
  // @ts-ignore
  var _paq = (window._paq = window._paq || []);
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function () {
    var u = '//matomo.gtn-solutions.com/';
    _paq.push(['setTrackerUrl', u + 'matomo.php']);
    _paq.push(['setSiteId', '10']);
    var d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = u + 'matomo.js';
    s.parentNode?.insertBefore(g, s);
  })();
}
