import { appCommonReducer } from '@gtn/app-common/store/app.store';
import { navigationFeatureKey } from '@gtn/app-common/store/navigation.state';
import { preferencesFeatureKey } from '@gtn/common/store/preferences/preferences.state';
import { userFeatureKey } from '@gtn/common/store/user/user.state';
import { appDAKORAFeatureKey, appDAKORAReducer } from '@gtn/dakora/store/DakoraState';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

const appReducer = combineReducers({
  ...appCommonReducer,
  [appDAKORAFeatureKey]: appDAKORAReducer,
});

export type DakoraState = ReturnType<typeof appReducer>;
export const useDakoraSelector: TypedUseSelectorHook<DakoraState> = useSelector;

const persistConfig = {
  key: 'root-dakora',
  storage,
  whitelist: [userFeatureKey, preferencesFeatureKey, navigationFeatureKey, appDAKORAFeatureKey],
  stateReconciler: autoMergeLevel2,
};

export const dakoraStore = configureStore({
  reducer: persistReducer(persistConfig, appReducer as any),
});
