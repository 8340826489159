import { Button, Snackbar } from '@material-ui/core';
import React from 'react';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';

export interface GtnSnackbarProps {
  textResId?: string;
  message?: string;
  open?: boolean;
  onClose?: () => void;
  retry?: () => void;
}

export function GtnSnackbar(props: GtnSnackbarProps) {
  const t = useAppTranslation();

  let message = props.message;
  if (!message && props.textResId) {
    message = t(props.textResId);
  }

  return (
    <Snackbar
      open={props.open}
      autoHideDuration={6000}
      onClose={props.onClose}
      message={message}
      action={
        props.retry && (
          <Button color="secondary" size="small" onClick={props.retry}>
            {t('retry')}
          </Button>
        )
      }
    />
  );
}
