import { Card } from '@material-ui/core';
import React from 'react';
import { ExacompAPI } from '@gtn/common/api/ExacompAPI';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import styles from '@gtn/common/components/link-preview/LinkPreview.module.scss';

export interface LinkPreviewProps {
  url: string;
}

export default function LinkPreview(props: LinkPreviewProps) {
  const exacompAPI = InjectionContainer.resolve(ExacompAPI);

  const { data: preview } = useAPI(exacompAPI.getLinkPreview, [props.url]);

  if (preview) {
    return (
      <a
        className={styles.href}
        href={props.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Card className={styles.container}>
          {preview.imageurl && <img src={preview.imageurl} />}
          <div className={styles.textContainer}>
            {preview.title && <h6>{preview.title}</h6>}
            {preview.description && <p>{preview.description}</p>}
          </div>
        </Card>
      </a>
    );
  }
  return null;
}
