import { ConfigManagerToken } from '@gtn/common/config/ConfigManager';
import { Store } from 'redux';
import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import { AppConfigManager } from '@gtn/app-common/config/AppConfigManager';
import { TranslationManager } from '@gtn/common/i18n/TranslationManager';
import { StoreToken } from '@gtn/common/store/shared.store';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { singleton } from 'tsyringe';
import { AppCommonState } from '@gtn/app-common/store/app.store';
import { diggrplus_get_config_returns } from '@gtn/common/api/webservice/MoodleWebserviceDefinitions';
import { preferencesActions } from '@gtn/common/store/preferences/preferences.state';

export type ServerInfoResponse = diggrplus_get_config_returns;

@singleton()
export class ServerInfoManager {
  private readonly sharedAPI = InjectionContainer.resolve(AppCommonAPI);
  private readonly translationManager = InjectionContainer.resolve(TranslationManager);
  private readonly configManager = InjectionContainer.resolve<AppConfigManager>(ConfigManagerToken);
  private readonly store = InjectionContainer.resolve<Store<AppCommonState>>(StoreToken);

  private serverInfo?: ServerInfoResponse;

  public async init() {
    // Only check if user is logged in, because then we have a valid Moodle server
    if (this.store.getState().user.isLoggedIn) {
      const minRequiredExacompVersion = this.configManager.getConfig().minRequiredExacompVersion;
      if (minRequiredExacompVersion != null) {
        try {
          this.serverInfo = await this.sharedAPI.getServerInfo();

          if (!this.hasMinRequiredExacompVersion(minRequiredExacompVersion)) {
            this.showServerMinVersionError(minRequiredExacompVersion);
          }

          if (!this.serverInfo.plugins) {
            // exacomp < 2023071000 didn't have the plugins list, so use empty list
            this.serverInfo.plugins = [];
          }

          this.store.dispatch(preferencesActions.set({ serverInfo: this.serverInfo }));
        } catch (e) {
          GtnLogger.warn("Couldn't load server info! %o", e);
        }
      }
    }
  }

  public getServerInfo() {
    return this.serverInfo;
  }

  public hasMinRequiredExacompVersion(minRequiredExacompVersion: number) {
    return this.serverInfo && this.serverInfo.exacompversion >= minRequiredExacompVersion;
  }

  private showServerMinVersionError(minRequiredExacompVersion: number) {
    alert(
      this.translationManager.translate('error-server-min-version', {
        minRequiredExacompVersion,
      })
    );
  }

  public isPluginInstalled(pluginName) {
    const pluginInfo = this.serverInfo?.plugins.find((plugin) => plugin.name == pluginName);

    // plugin is installed if versiondb > 0
    return !!pluginInfo?.versiondb;
  }
}
