import { ConfigManagerToken } from '@gtn/common/config/ConfigManager';
import * as Sentry from '@sentry/browser';
import { Event, EventHint } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Store } from 'redux';
import { AppConfigManager } from '@gtn/app-common/config/AppConfigManager';
import { MSTeamsManager } from '@gtn/app-common/microsoft/MSTeamsManager';
import { AppCommonState } from '@gtn/app-common/store/app.store';
import { StoreToken } from '@gtn/common/store/shared.store';
import {
  ErrorLogLevel,
  IErrorTracker,
} from '@gtn/app-common/utils/error-tracking/IErrorTracker';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';

export class SentryErrorTracker implements IErrorTracker {
  private readonly store =
    InjectionContainer.resolve<Store<AppCommonState>>(StoreToken);
  private readonly config =
    InjectionContainer.resolve<AppConfigManager>(
      ConfigManagerToken
    ).getConfig();
  private readonly msTeamsManager = InjectionContainer.resolve(MSTeamsManager);

  constructor(private dsn: string) {}

  init(): void {
    Sentry.init({
      dsn: this.dsn,
      integrations: [new Integrations.BrowserTracing()],
      release: this.config.version.name,
      environment: this.config.isProduction
        ? window.location.hostname
        : 'debug',
      beforeSend: (event: Event, hint?: EventHint) => {
        const state = this.store.getState();

        event.tags = event.tags || {};

        event.tags['app-type'] = this.config.appType;
        event.tags['moodle-url'] = state.preferences.moodleUrl;
        event.tags['ui-lang'] = state.preferences.language;
        event.tags['ms-teams'] = this.msTeamsManager.isInMsTeams();
        event.tags['user-name'] = state.user?.profile?.userName;
        event.tags['role'] = state.user?.profile?.role;

        if (state.user.profile) {
          event.user = {
            id: String(state.user.profile.id),
            username: state.user.profile.userName,
          };
        }

        return event;
      },
    });
  }

  setTag(key: string, value?: string): void {
    try {
      Sentry.setTag(key, value);
    } catch (e) {
      GtnLogger.warn(e);
    }
  }

  trackError(error: any) {
    try {
      return Sentry.captureException(error.originalError || error);
    } catch (e) {
      GtnLogger.warn(e);
      return null;
    }
  }

  trackLog(message: string, level: ErrorLogLevel, args?: any[]): void {
    try {
      Sentry.addBreadcrumb({
        category: 'log',
        message,
        level: this.getSentryLevel(level),
        data: {
          arguments: args,
        },
      });
    } catch (e) {
      GtnLogger.warn(e);
    }
  }

  sendManuallyTriggeredReport(): void {
    try {
      const eventId = this.trackError(new Error('Custom triggered report'));
      if (eventId) {
        const state = this.store.getState();
        Sentry.showReportDialog({
          eventId,
          lang: state.preferences.language,
          user: {
            name: state.user?.profile?.fullName,
            email: 'anonymous@gtn-solutions.com',
          },
        });
      }
    } catch (e) {
      console.warn(e);
    }
  }

  private getSentryLevel(level: ErrorLogLevel): Sentry.Severity {
    if (level === ErrorLogLevel.Error) {
      return Sentry.Severity.Error;
    } else if (level === ErrorLogLevel.Warning) {
      return Sentry.Severity.Warning;
    } else {
      return Sentry.Severity.Info;
    }
  }
}
