import { GRADING_VALUES_EMOJI_3_STUDENTS, GRADING_VALUES_EMOJI_3_TEACHERS } from '@gtn/app-common/components/grading/GradingValues';
import { GtnSelect } from '@gtn/common/components/forms/gtn-select/GtnSelect';
import { StyleProps } from '@gtn/common/components/StyleProps';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

export interface GradingInputProps extends StyleProps {
  name?: string;
  label?: string;
  grading?: number | null;
  disabled?: boolean;
  readonly?: boolean;
  onChange?: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  noGradingDisplay?: ReactNode; // shown if readonly = true and no grading exists
  gradingType: 'teacher' | 'student';
}

export function GradingInput(props: GradingInputProps) {
  const t = useAppTranslation();
  const gradingValues = props.gradingType === 'teacher' ? GRADING_VALUES_EMOJI_3_TEACHERS : GRADING_VALUES_EMOJI_3_STUDENTS;

  if (props.readonly === true) {
    const grading = gradingValues.find((gradingValue) => gradingValue.value === props.grading);

    if (grading || props.noGradingDisplay) {
      return (
        <div
          className={classNames(props.className)}
          style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: grading?.backgroundColor,
            color: grading?.color,
            border: grading ? '' : '1px solid #e2e2e2',
            minWidth: 40,
            borderRadius: 24,
            paddingRight: 12,
            paddingLeft: 12,
            paddingTop: 4,
            paddingBottom: 4,
            textAlign: 'center',
            whiteSpace: 'nowrap',
            ...props.style,
          }}
        >
          {grading ? (typeof grading.displayValue === 'string' ? t(grading.displayValue) : grading.displayValue) : props.noGradingDisplay}
          {}
        </div>
      );
    }

    return <></>;
  } else {
    // den value auf string convertieren, wenn gesetzt
    let value;
    if (props.grading === null || props.grading === undefined) {
      value = props.grading;
    } else {
      value = String(props.grading);
    }

    return (
      <GtnSelect
        name={props.name ?? ''}
        label={props.label ?? t('evaluation.gradings.default')}
        className={props.className}
        style={props.style}
        disabled={props.disabled}
        options={gradingValues}
        onChange={props.onChange}
        value={value}
      />
    );
  }
}
