import { useAppConfig } from '@gtn/app-common/config/AppConfigHooks';
import { MSTeamsManager } from '@gtn/app-common/microsoft/MSTeamsManager';
import { CommonRoutingParams, CommonRoutingPaths } from '@gtn/app-common/routes/AppCommonRouting';
import { useAppCommonSelector, useAppDispatch } from '@gtn/app-common/store/app.store.hooks';
import { MainContent } from '@gtn/common/components/MainContent';
import { preferencesActions } from '@gtn/common/store/preferences/preferences.state';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { Utils } from '@gtn/common/utils/Utils';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';

export default function MSTeamsTabConfig() {
  const t = useAppTranslation();
  const dispatch = useAppDispatch();

  const teamsManager = InjectionContainer.resolve(MSTeamsManager);
  const config = useAppConfig();

  const preferences = useAppCommonSelector((state) => state.preferences);
  const [moodleUrl, setMoodleUrl] = useState(preferences.moodleUrl || config.moodleUrl);

  useEffect(() => {
    setTimeout(() => {
      if (!config.allowChangeMoodleUrl || config.moodleUrl) {
        teamsManager.setConfigurationPageValidity(true);
      }
      teamsManager.setConfigurationPageSaveHandler(saveHandler);
    }, 1000);
  }, []);

  useEffect(() => {
    teamsManager.setConfigurationPageValidity(moodleUrl?.startsWith('http'));
    teamsManager.setConfigurationPageSaveHandler(saveHandler);
  }, [moodleUrl]);

  const saveHandler = () => {
    dispatch(preferencesActions.set({ moodleUrl }));

    const queryParams = Utils.parseQuery(window.location.search);
    const configPath = queryParams[CommonRoutingParams.CONFIG];

    const basePath = Utils.normalizeBasePath(config.basePath);
    const params = new URLSearchParams();
    params.set(CommonRoutingParams.MOODLE_URL, moodleUrl);
    params.set(CommonRoutingParams.ALLOW_CHANGE_MOODLE_URL, String(false));

    if (configPath) {
      params.set(CommonRoutingParams.CONFIG, configPath);
    }

    const url = `${document.location.origin + basePath}/${CommonRoutingPaths.MS_TEAMS_TAB}?${params.toString()}`;
    return {
      url,
    };
  };

  return (
    <MainContent>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label={t('login.moodle-url')}
        name="moodleUrl"
        disabled={!config.allowChangeMoodleUrl}
        value={moodleUrl}
        onChange={(event) => setMoodleUrl(event.target.value)}
      />
    </MainContent>
  );
}
