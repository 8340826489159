import PdfViewer from '@gtn/app-common/components/pdf-viewer/PdfViewer';
import GtnDialog, { DialogProps } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import React from 'react';

interface AnnotatePdfDialogProps {
  fileUrl?: string;
  fileName?: string;
  onSave?: (pdfContent: ArrayBuffer, originalFileName?: string) => void;
}

export function AnnotatePdfDialog(props: AnnotatePdfDialogProps & DialogProps) {
  return (
    <GtnDialog {...props} fullscreen="always">
      {props.fileUrl && <PdfViewer fileUrl={props.fileUrl} fileName={props.fileName} onSave={props.onSave}></PdfViewer>}
    </GtnDialog>
  );
}
