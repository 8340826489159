import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import styles from '@gtn/app-common/components/workbook/choose-competences/ChooseCompetenceFilterDialog.module.scss';
import { useAppCommonSelector } from '@gtn/app-common/store/app.store.hooks';
import { ExacompSubject } from '@gtn/common/api/model/exacomp/ExacompSubject';
import { ExacompTopic } from '@gtn/common/api/model/exacomp/ExacompTopic';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { GtnTreeParent, GtnTreeView } from '@gtn/common/components/gtn-tree-view/GtnTreeView';
import LoadingContainer from '@gtn/common/components/loading-container/LoadingContainer';
import GtnDialog, { DialogProps } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { ProgressState } from '@gtn/common/utils/ProgressState';
import React from 'react';

export interface ChooseCompetenceFilterProps {
  onSubjectClicked?: (params: { subject: ExacompSubject }) => void;
  onTopicClicked?: (params: { subject: ExacompSubject; topic: ExacompTopic }) => void;
}

function ChooseCompetenceFilterDialog(props: ChooseCompetenceFilterProps & DialogProps) {
  const t = useAppTranslation();
  const selectedCourse = useAppCommonSelector((state) => state.navigation.selectedCourse); // only used when user is teacher

  const sharedAPI = InjectionContainer.resolve(AppCommonAPI);
  const { data: subjects, progressState } = useAPI(sharedAPI.getSubjectsAndTopicsForUser, [selectedCourse?.id]);

  function filterHidden<T extends { visible?: boolean }>(data?: T[]): T[] | undefined {
    return data?.filter((d) => d.visible);
  }

  function renderContent() {
    return (
      <LoadingContainer
        className={styles.dialogContentContainer}
        state={progressState}
        style={{
          alignItems: progressState === ProgressState.Content ? 'stretch' : 'center',
        }}
      >
        <GtnTreeView className={styles.scrollContainer}>
          {subjects?.map((subject) => (
            <GtnTreeParent id={subject.id} defaultExpanded={subjects.length === 1} node={<h2 className={styles.title}>{subject.title}</h2>}>
              {props.onSubjectClicked && (
                <p className={styles.allTopics} onClick={() => props.onSubjectClicked?.({ subject })}>
                  {t('workbook.competence-filter.all-topics')}
                </p>
              )}

              {filterHidden(subject.topics)?.map((topic, topicIndex) => (
                <p key={topicIndex} className={styles.topic} onClick={() => props.onTopicClicked?.({ subject, topic })}>
                  {topic.title}
                </p>
              ))}
            </GtnTreeParent>
          ))}
        </GtnTreeView>
      </LoadingContainer>
    );
  }

  return (
    <GtnDialog title={t('workbook.competence-filter.title')} {...props}>
      {renderContent()}
    </GtnDialog>
  );
}

export default ChooseCompetenceFilterDialog;
