import { StyleProps } from '@gtn/common/components/StyleProps';
import Badge from '@material-ui/core/Badge';
import React from 'react';
import { ChildrenProps } from '@gtn/common/components/ChildrenProps';
import styles from '@gtn/common/components/gtn-badge/GtnBadge.module.scss';

export interface GtnBadgeProps extends ChildrenProps, StyleProps {
  showBadge?: boolean;
  content?: number;
  onClick?: () => void;
}

export function GtnBadge(props: GtnBadgeProps) {
  return (
    <Badge
      onClick={props.onClick}
      classes={{ badge: styles.badge }}
      overlap="circle"
      badgeContent={props.content}
      invisible={!props.showBadge}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      variant={props.content ? 'standard' : 'dot'}
      className={props.className}
      style={props.style}
    >
      {props.children}
    </Badge>
  );
}
