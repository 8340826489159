import { ChildrenProps } from '@gtn/common/components/ChildrenProps';
import { StyleProps } from '@gtn/common/components/StyleProps';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import { ExpandMoreRounded } from '@material-ui/icons';
import useResizeObserver from '@react-hook/resize-observer';
import classNames from 'classnames';
import React, { createRef, useEffect, useState } from 'react';
import styles from './SidebarCollapse.module.scss';

interface SidebarCollapseProps extends ChildrenProps, StyleProps {
  title: string;
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  minSize?: number;
  collapseSide: 'left' | 'right';
}

const DEFAULT_MIN_SIZE = 80;

export const SidebarCollapse = (props: SidebarCollapseProps) => {
  const divRef = createRef<HTMLDivElement>();
  const t = useAppTranslation();
  const [minSize, setMinSize] = useState<number>(DEFAULT_MIN_SIZE);

  useEffect(() => {
    setMinSize(props.minSize ?? DEFAULT_MIN_SIZE);
  }, [props.minSize]);

  useResizeObserver(divRef, (entry) => {
    const shouldBeCollapsed = entry.contentRect.width < minSize;

    if (shouldBeCollapsed !== props.collapsed) {
      props.setCollapsed(shouldBeCollapsed);
    }
  });

  function toggle() {
    props.setCollapsed(!props.collapsed);
  }

  return (
    <div className={classNames(styles.container, props.className)} style={props.style} ref={divRef}>
      {props.collapsed && (
        <p className={styles.collapsedTitle} onClick={() => toggle()}>
          {props.title}
        </p>
      )}
      {!props.collapsed && (
        <div style={{ display: 'flex', flexDirection: 'column', minWidth: minSize, height: '100%', maxWidth: '100%', ...props.style }}>
          <p id="collapseToggle" className={styles.collapseToggle} onClick={() => toggle()} style={{ flexDirection: props.collapseSide === 'left' ? 'row-reverse' : 'row' }}>
            <ExpandMoreRounded style={{ transform: props.collapseSide === 'left' ? 'rotate(90deg)' : 'rotate(-90deg)' }} />
            {t('navigation.collapse')}
          </p>
          {props.children}
        </div>
      )}
    </div>
  );
};
