import CourseUsersList from '@gtn/app-common/components/app-navigation/course-student-selection/course-users-list/CourseUsersList';
import { CourseProblemInfo } from '@gtn/app-common/components/app-navigation/course-student-selection/CourseProblemInfo';
import { CourseStudentSelector } from '@gtn/app-common/components/app-navigation/course-student-selection/selector/CourseStudentSelector';
import styles from '@gtn/app-common/components/app-navigation/course-student-selection/sidebar/CourseStudentSelectorSidebar.module.scss';
import { SidebarCollapse } from '@gtn/app-common/components/sidebar-collapse/SidebarCollapse.component';
import { useAppCommonSelector, useAppDispatch, useSelectedCourse, useSelectedStudents } from '@gtn/app-common/store/app.store.hooks';
import { navigationActions } from '@gtn/app-common/store/navigation.state';
import { useShowInlineCourseStudentSelector } from '@gtn/app-common/utils/AppHookUtils';
import { MoodleUser } from '@gtn/common/api//model/moodle-core/MoodleUser';
import { useAPI } from '@gtn/common/api//webservice/WebserviceHookUtils';
import { ExacompAPI } from '@gtn/common/api/ExacompAPI';
import { Course } from '@gtn/common/api/model/Course';
import { GtnAvatar } from '@gtn/common/components/gtn-avatar/GtnAvatar';
import { LoadingIndicatorInline } from '@gtn/common/components/LoadingIndicator';
import { MainContent } from '@gtn/common/components/MainContent';
import GtnDrawer from '@gtn/common/components/navigation/gtn-drawer/GtnDrawer';
import { StyleProps } from '@gtn/common/components/StyleProps';
import { useIsTeacher } from '@gtn/common/store/user/user.hooks';
import { ThemeManager } from '@gtn/common/theme/ThemeManager';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { Popover } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ArrowDropDown } from '@material-ui/icons';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import Split from 'react-split';

export interface CourseStudentSelectorSidebarProps {
  selectionMode?: 'all-students-only' | 'single-student-only' | 'single-and-all-students' | 'multiple-students' | 'course-only';
  children?: any;
  onStudentSelected?: (student: MoodleUser | MoodleUser[] | null) => boolean | void;
  onCourseSelected?: (course: Course) => boolean | void;
  footer?: any;
  mainContainerStyle?: StyleProps;
}

export interface CourseStudentSelectorSidebarContentProps {
  onStudentSelected?: (student: MoodleUser | MoodleUser[] | null, mutliSelection?: boolean) => boolean | void;
  onCourseSelected?: (course: Course) => boolean | void;
  selectionMode?: 'all-students-only' | 'single-student-only' | 'single-and-all-students' | 'multiple-students' | 'course-only';
  footer?: any;
}

export default function CourseStudentSelectorSidebar(props: CourseStudentSelectorSidebarProps & StyleProps) {
  const t = useAppTranslation();
  const dispatch = useAppDispatch();
  const isTeacher = useIsTeacher();
  const selectedCourse = useAppCommonSelector((state) => state.navigation.selectedCourse);

  const showInlineCourseStudentSelector = useShowInlineCourseStudentSelector();
  const courseStudentSelectionDrawerOpened = useAppCommonSelector((state) => state.navigation.courseStudentDrawerOpened);
  const role = useAppCommonSelector((state) => state.user.profile?.role);

  const closeDrawer = () => dispatch(navigationActions.setCourseStudentDrawerOpened(false));

  const renderMainContent = (children) => {
    let showMainContent = true;
    if (!selectedCourse) {
      showMainContent = false;
    } else if (selectedCourse?.exacompActive === false) {
      // NEU: auch ohne exacomp block kann mit diggr/dakora gearbeitet werden
      showMainContent = true;
    } else if (selectedCourse?.role !== role) {
      showMainContent = false;
    }

    return (
      <MainContent {...props.mainContainerStyle}>
        {!showInlineCourseStudentSelector && <CourseStudentSelector />}
        {isTeacher && !showMainContent ? <CourseProblemInfo /> : children}
      </MainContent>
    );
  };

  if (!isTeacher && props.selectionMode !== 'course-only') {
    // don't display for students
    return renderMainContent(props.children);
  } else if (!showInlineCourseStudentSelector) {
    return (
      <>
        <GtnDrawer side="left" isOpen={courseStudentSelectionDrawerOpened} onClose={closeDrawer}>
          <CourseStudentSelectorSidebarContent
            {...props}
            style={{ maxWidth: 'unset', border: 'none', padding: 0, margin: 0 }}
            onCourseSelected={(course) => {
              const result = props.onCourseSelected?.(course);
              if (result !== false && props.selectionMode === 'all-students-only') {
                closeDrawer();
              }
              return result;
            }}
            onStudentSelected={(student) => {
              const result = props.onStudentSelected?.(student);
              if (result !== false) {
                closeDrawer();
              }
              return result;
            }}
          />
        </GtnDrawer>
        {renderMainContent(props.children)}
      </>
    );
  } else {
    return (
      <Split
        className={classNames('split horizontal', styles.container, props.className)}
        direction="horizontal"
        sizes={[20, 80]}
        minSize={40}
        maxSize={[500, Infinity]}
        expandToMin={true}
        collapsed={courseStudentSelectionDrawerOpened ? undefined : 0}
        style={props.style}
      >
        <SidebarCollapse
          title={t('navigation.switch-course-and-student')}
          className={styles.leftContainer}
          collapsed={!courseStudentSelectionDrawerOpened}
          setCollapsed={(collapsed) => dispatch(navigationActions.setCourseStudentDrawerOpened(!collapsed))}
          minSize={100}
          collapseSide="left"
        >
          <CourseStudentSelectorSidebarContent {...props} />
        </SidebarCollapse>

        <div className={styles.mainContentContainer}>{renderMainContent(props.children)}</div>
      </Split>
    );
  }
}

export function CourseStudentSelectorSidebarContent(props: CourseStudentSelectorSidebarContentProps & StyleProps) {
  const t = useAppTranslation();
  const dispatch = useAppDispatch();

  const themeManager = InjectionContainer.resolve(ThemeManager);
  const [courses, coursesLoaded] = useAppCommonSelector((state) => [state.navigation.courses, state.navigation.coursesLoaded]);

  const selectedCourse = useSelectedCourse();
  const selectedStudents = useSelectedStudents();
  const [coursePopoverAnchor, setCoursePopoverAnchor] = React.useState<HTMLElement | null>(null);

  useEffect(() => {
    if (!selectedCourse && courses?.length) {
      onCourseClicked(courses[0]);
    }
  }, [selectedCourse, courses]);

  const onCourseSelectorClicked = (event: React.MouseEvent<HTMLElement>) => {
    setCoursePopoverAnchor(event.currentTarget);
  };

  const onCourseClicked = (course: Course) => {
    const result = props.onCourseSelected?.(course);
    if (result !== false) {
      dispatch(navigationActions.setSelectedCourse(course));
    }
    setCoursePopoverAnchor(null);
  };

  if (!coursesLoaded) {
    return <LoadingIndicatorInline />;
  } else if (courses?.length) {
    if (props.selectionMode === 'course-only') {
      return (
        <>
          <h3>{t('navigation.choose-course-short')}</h3>
          <div className={styles.list}>
            {courses.map((course) => (
              <ListItem
                className={classNames(styles.listItem, course.id === selectedCourse?.id ? styles.selected : null)}
                onClick={() => onCourseClicked(course)}
                button
                key={course.id}
                title={course.fullname}
              >
                <GtnAvatar className={styles.icon} name={course.displayname} style={{ backgroundColor: themeManager.getColorForId(course.id) }} />
                <ListItemText primary={course.displayname} style={{ wordBreak: 'break-all' }} />
              </ListItem>
            ))}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={styles.header} onClick={onCourseSelectorClicked}>
            <div>
              <p>{t('navigation.course')}</p>
              <h3>{selectedCourse?.displayname ?? t('navigation.choose-course-short')}</h3>
            </div>
            <ArrowDropDown />
          </div>

          {selectedCourse && (
            <CourseUsersList
              className={styles.courseUsersList}
              courseId={selectedCourse.id}
              courseName={selectedCourse.displayname}
              selectedUsers={selectedStudents}
              canSelect={props.selectionMode}
              onUserSelected={(selectedStudent, multiSelection) => {
                const result = props.onStudentSelected?.(selectedStudent, multiSelection);

                if (result !== false) {
                  dispatch(navigationActions.setSelectedStudent({ student: selectedStudent, multiSelection }));
                }
              }}
            />
          )}

          {props.footer}

          <Popover
            open={coursePopoverAnchor != null}
            onClose={() => setCoursePopoverAnchor(null)}
            anchorEl={coursePopoverAnchor}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {courses?.map((course) => (
              <div
                key={course.id}
                className={classNames(styles.courseItem, {
                  [styles.selected]: selectedCourse?.id === course.id,
                  [styles.disabled]: course?.disabled,
                })}
                onClick={() => onCourseClicked(course)}
              >
                <h3>{course.displayname}</h3>
              </div>
            ))}
          </Popover>
        </>
      );
    }
  } else {
    return <p className={styles.emptyText}>{t('navigation.no-courses')}</p>;
  }
}
