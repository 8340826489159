import { useUser } from '@gtn/common/store/user/user.hooks';
import { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { DakoraRoutingPaths } from './DakoraRouting';
import { useAppCommonSelector, useAppDispatch } from '@gtn/app-common/store/app.store.hooks';
import { useAllParams } from '@gtn/common/utils/HookUtils';
import { CommonRoutingParams, CommonRoutingPaths } from '@gtn/app-common/routes/AppCommonRouting';
import { userActions } from '@gtn/common/store/user/user.state';
import { navigationActions } from '@gtn/app-common/store/navigation.state';
import { preferencesActions } from '@gtn/common/store/preferences/preferences.state';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { AppConfigManager } from '@gtn/app-common/config/AppConfigManager';
import { ConfigManagerToken } from '@gtn/common/config/ConfigManager';

export function Home() {
  const user = useUser();
  let location = useLocation();
  const moodleUrl = useAppCommonSelector((state) => state.preferences.moodleUrl);
  const params = useAllParams();
  const dispatch = useAppDispatch();
  const configManager = InjectionContainer.resolve<AppConfigManager>(ConfigManagerToken);
  const config = configManager.getConfig();

  let content: any;
  let shouldRelogin = false;

  if (user.isLoggedIn) {
    // wenn eine unterschiedliche moodle_url übergeben wurde, dann erneut einloggen!
    if (params[CommonRoutingParams.MOODLE_URL] && config.allowChangeMoodleUrl) {
      const currentMoodleUrl = moodleUrl?.replace(/\/+$/, '');
      const wantedMoodleUrl = params[CommonRoutingParams.MOODLE_URL].replace(/\/+$/, '');

      if (currentMoodleUrl != wantedMoodleUrl) {
        shouldRelogin = true;
      }
    }

    // wenn ein anderere User gewünscht ist, dann erneut einloggen!
    // Info: derzeit auskommentiert, weil nicht benötigt
    // if (params[CommonRoutingParams.MOODLE_USERID]) {
    //   if (params[CommonRoutingParams.MOODLE_USERID] != String(user.profile?.id)) {
    //     shouldRelogin = true;
    //   }
    // }

    if (shouldRelogin) {
      // remove unwanted parameters, nicht relevant für das login formular
      const params = new URLSearchParams(location.search);
      // params.delete('moodle_userid');
      params.set('do_login', '1');
      params.delete('recheck_login');

      content = (
        <Redirect
          to={{
            pathname: '/login',
            // forward parameters, eg. moodle_url or do_login for eg. annotation tool
            search: params.toString(),
          }}
        />
      );
    } else if (params['recheck_login']) {
      content = (
        <Redirect
          to={{
            pathname: '/login',
            // forward parameters, eg. moodle_url or do_login for eg. annotation tool
            search: location.search,
          }}
        />
      );
    } else {
      content = <Redirect to={`/${DakoraRoutingPaths.LEARNING_PLANS}`} />;
    }
  } else {
    // Home wird nur angezeigt, wenn eingeloggt, sonst greift die logik in GtnRouteSwitch.tsx (Weiterleitung auf /login)
    content = <div>Error #58239kfds123 (Should not happen)!</div>;
  }

  useEffect(() => {
    if (shouldRelogin) {
      dispatch(userActions.userLogout());
      // dispatch(appSETActions.reset());
      dispatch(navigationActions.reset());
      dispatch(preferencesActions.set({ moodleUrl }));
    }
  }, [shouldRelogin]);

  return content;
}
