import { ChildrenProps } from '@gtn/common/components/ChildrenProps';
import { LanguageChooser } from '@gtn/common/components/language-chooser/LanguageChooser';
import { MainContent } from '@gtn/common/components/MainContent';
import React from 'react';

export default function Settings(props: ChildrenProps) {
  return (
    <MainContent>
      <LanguageChooser />
      {props.children}
    </MainContent>
  );
}
