import { ExacompFile } from '@gtn/common/api/model/exacomp/ExacompFile';
import { Attachment } from '@material-ui/icons';
import classNames from 'classnames';
import React from 'react';
import { GtnAvatar } from '@gtn/common/components/gtn-avatar/GtnAvatar';
import { StyleProps } from '@gtn/common/components/StyleProps';
import styles from '@gtn/common/components/chat/ChatMessage.module.scss';

export interface ChatMessageProps {
  message: string;
  date: Date;

  userName: string;
  userProfileImageUrl: string;

  incoming: boolean;

  file?: ExacompFile;

  onOpenFile?: (file: ExacompFile) => void;
}

export default function ChatMessage(props: ChatMessageProps & StyleProps) {
  return (
    <div className={classNames(styles.container, { [styles.incoming]: props.incoming }, props.className)} style={props.style}>
      <GtnAvatar imageUrl={props.userProfileImageUrl} name={props.userName} />

      <div className={styles.message}>
        <p className={styles.info}>
          {props.userName} -{' '}
          {props.date.toLocaleDateString(undefined, {
            dateStyle: 'short',
          } as any)}{' '}
          {props.date.toLocaleTimeString(undefined, {
            timeStyle: 'short',
          } as any)}
        </p>
        <p className={styles.bubble}>
          {props.message}
          {props.file && (
            <>
              {props.message && (
                <>
                  <br />
                  <br />
                </>
              )}
              <a href="#" onClick={() => props.onOpenFile?.(props.file!)} className={styles.attachment}>
                <Attachment />
                <span>{props.file.filename}</span>
              </a>
            </>
          )}
        </p>
      </div>
    </div>
  );
}
