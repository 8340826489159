import { useEffect, useRef } from 'react';

// idea from:
// https://www.falldowngoboone.com/blog/talk-to-your-react-components-with-custom-events/
// https://react.dev/reference/react/useCallback

function on(eventType, listener) {
  document.addEventListener(eventType, listener);
}

function off(eventType, listener) {
  document.removeEventListener(eventType, listener);
}

function once(eventType, listener) {
  on(eventType, handleEventOnce);

  function handleEventOnce(event) {
    listener(event);
    off(eventType, handleEventOnce);
  }
}

function trigger(eventType, data) {
  const event = new CustomEvent(eventType, { detail: data });
  document.dispatchEvent(event);
}

interface Event {
  type: string;
  [otherOptions: string]: unknown;
}

export function dispatchEvent(event: Event) {
  trigger('custom-events', event);
}

export function useEventListener(callback: (event: Event) => void) {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    const internalCallback = (data) => callbackRef.current(data.detail);
    on('custom-events', internalCallback);

    return () => {
      off('custom-events', internalCallback);
    };
  }, []);
}
