import styles from './AppNavigation.module.scss';
import AppNavigationDrawer from '@gtn/app-common/components/app-navigation/drawer/AppNavigationDrawer';
import { NotificationDrawer } from '@gtn/app-common/components/app-navigation/drawer/NotificationDrawer';
import { AppConfigManager } from '@gtn/app-common/config/AppConfigManager';
import { useAppCommonSelector, useAppDispatch } from '@gtn/app-common/store/app.store.hooks';
import { navigationActions } from '@gtn/app-common/store/navigation.state';
import GtnAppBar from '@gtn/common/components/navigation/gtn-app-bar/GtnAppBar';
import { NavigationItem } from '@gtn/common/components/navigation/NavigationItem';
import { ConfigManagerToken } from '@gtn/common/config/ConfigManager';
import { TranslationManager } from '@gtn/common/i18n/TranslationManager';
import { useUser } from '@gtn/common/store/user/user.hooks';
import { useIsScreenMinWidth } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { MetaTagsManager } from '@gtn/common/utils/MetaTagsManager';
import { GtnRoute } from '@gtn/common/utils/routing/GtnRoute';
import { GtnRouteSwitch } from '@gtn/common/utils/routing/GtnRouteSwitch';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ErrorBoundary } from '@sentry/react';
import { useRouteMatch } from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';

export interface AppNavigationProps {
  routes: GtnRoute[];
  primaryNavigationItems: NavigationItem[];

  showNotifications?: boolean;
}

export const AppNavigation = (props: AppNavigationProps) => {
  const metaTagsManager = InjectionContainer.resolve(MetaTagsManager);
  const translationManager = InjectionContainer.resolve(TranslationManager);
  const config = InjectionContainer.resolve<AppConfigManager>(ConfigManagerToken).getConfig();

  const dispatch = useAppDispatch();
  const user = useUser();

  const isAssignmentGradingRoute = useRouteMatch('/assignment-grading');
  const isHomeRoute = useRouteMatch({path: '/', exact: true});
  const isDrawerVisible = user.isLoggedIn && !isAssignmentGradingRoute && !isHomeRoute;


  const isMinWidth1500 = useIsScreenMinWidth(1500);
  const showPersistentDrawer = isDrawerVisible && isMinWidth1500;

  const navigationDrawerOpened = useAppCommonSelector((state) => state.navigation.navigationDrawerOpened);
  const showDrawer = () => dispatch(navigationActions.setNavigationDrawerOpened(true));

  useEffect(() => {
    if (!showPersistentDrawer) {
      dispatch(navigationActions.setNavigationDrawerOpened(false));
    }
  }, [showPersistentDrawer]);

  const primaryNavigationItems = useMemo(() => {
    return props.primaryNavigationItems.filter((navItem) => (config.hideNavigationItems ? !config.hideNavigationItems.includes(navItem.href ?? '') : true));
  }, [props.primaryNavigationItems, config.hideNavigationItems]);

  useEffect(() => {
    if (!navigationDrawerOpened && showPersistentDrawer) {
      showDrawer();
    }
  }, [navigationDrawerOpened, showPersistentDrawer]);

  return (
    <div className={styles.container}>
      <CssBaseline />

      {isDrawerVisible && (
        <>
          <AppNavigationDrawer showAsPersistent={showPersistentDrawer} primaryItems={primaryNavigationItems} />

          {props.showNotifications && <NotificationDrawer />}
        </>
      )}

      <div className={styles.mainContainer} style={{ maxWidth: showPersistentDrawer && navigationDrawerOpened ? 'calc(100vw - 280px)' : undefined }}>
        {isDrawerVisible && (
          <GtnAppBar
            routes={props.routes}
            showDrawerIcon={!showPersistentDrawer}
            onDrawerIconClick={showDrawer}
            showNotifications={props.showNotifications}
            onNotificationsClick={() => dispatch(navigationActions.setNotificationsDrawerOpened(true))}
            config={config}
          />
        )}
        <div className={styles.content}>
          <ErrorBoundary>
            <GtnRouteSwitch
              routes={props.routes}
              render={(route) => {
                metaTagsManager.setTitle(route.title ? translationManager.translate(route.title) : undefined);
                return route.content();
              }}
            />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};
