import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect, useState } from 'react';
import GtnDialog, { DialogProps } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';

export interface IframeDialogProps {
  title?: string;
  src?: string;

  onMessage?(data: string): void;
}

export default function IframeDialog(props: IframeDialogProps & DialogProps) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handler = (ev: MessageEvent<string>) => {
      if (ev.type === 'message') {
        props.onMessage?.(ev.data);
      }
    };

    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, []);

  return (
    <GtnDialog title={props.title} {...props}>
      {isLoading && <CircularProgress size={40} style={{ position: 'absolute', alignSelf: 'center', top: 96 }} />}
      <iframe src={props.src} frameBorder="0" onLoad={() => setIsLoading(false)} style={{ width: '100%', height: '100%' }} />
    </GtnDialog>
  );
}
