import Badge from '@material-ui/core/Badge';
import classNames from 'classnames';
import React from 'react';
import { useIsTeacher, useUser } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import { GtnAvatar } from '@gtn/common/components/gtn-avatar/GtnAvatar';
import styles from '@gtn/common/components/profile/Profile.module.scss';

export interface ProfileProps {
  onClick?: () => void;
}

export default function Profile(props: ProfileProps) {
  const isTeacher = useIsTeacher();
  const user = useUser();
  const t = useAppTranslation();

  return (
    <div
      onClick={props.onClick}
      className={classNames(styles.userProfileContainer, {
        [styles.clickable]: props.onClick != null,
      })}
    >
      <Badge color="secondary" overlap="circle" badgeContent={null}>
        <GtnAvatar className={styles.avatar} imageUrl={user.profile?.pictureUrl} name={user.profile?.fullName} />
      </Badge>
      <div className={styles.userProfileContainerText}>
        <h3>{user.profile?.fullName}</h3>
        <p>
          {user.profile?.userName} ({isTeacher ? t('role.teacher') : t('role.student')})
        </p>
      </div>
    </div>
  );
}
