import { StyleProps } from '@gtn/common/components/StyleProps';
import { IconButton } from '@material-ui/core';
import { CheckCircle, RadioButtonUnchecked } from '@material-ui/icons';
import classNames from 'classnames';
import React from 'react';
import styles from './GtnSelectionIndicator.module.scss';

interface GtnSelectionIndicatorProps extends StyleProps {
  isSelected?: boolean;
  onClick?: (isSelected: boolean) => void;
}

export function GtnSelectionIndicator(props: GtnSelectionIndicatorProps) {
  return (
    <IconButton
      className={classNames({ [styles.checkbox]: true, [styles.checked]: props.isSelected }, props.className)}
      style={props.style}
      onClick={(e) => {
        if (props.onClick) {
          props.onClick(props.isSelected === true);
          e.stopPropagation();
        }
      }}
    >
      {props.isSelected ? <CheckCircle /> : <RadioButtonUnchecked />}
    </IconButton>
  );
}
