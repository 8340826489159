// moodle release: 4.1.6+ (Build: 20231102)
// block_exacomp version: 2024092300

// https://stackoverflow.com/questions/49580725/is-it-possible-to-restrict-typescript-object-to-contain-only-properties-defined/57117594#57117594
// ensure that an object passed to a function does not contain any properties beyond those in a specified (object) type.
type Impossible<K extends keyof any> = {
  [P in K]: never;
};
type NoExtraProperties<T, U extends T = T> = U extends Array<infer V>
  ? NoExtraProperties<V>[]
  : U & Impossible<Exclude<keyof U, keyof T>>;

export enum enum_inprogress_submitted_completed {
  INPROGRESS = 'inprogress',
  SUBMITTED = 'submitted',
  COMPLETED = 'completed',
}

export { enum_inprogress_submitted_completed as diggrplus_set_item_status_parameters_status };

export interface diggrplus_set_item_status_parameters {
  itemid: number;
  status: enum_inprogress_submitted_completed; // ENUM(inprogress, submitted, completed)
}

export interface diggrplus_set_item_status_returns {
  success: boolean;
}

export interface block_exacomp_get_courses_parameters {
  userid?: number; // default: NULL // id of user
}

export enum enum_basic_advanced {
  BASIC = 'basic',
  ADVANCED = 'advanced',
}

export { enum_basic_advanced as block_exacomp_get_courses_returns_item_experience_level };

export interface block_exacomp_get_courses_returns_item {
  courseid: number; // id of course
  fullname: string; // fullname of course
  shortname: string; // shortname of course
  exarole: number; // 1=trainer, 2=student
  teachercanedit: boolean;
  course_topics_configured?: boolean; // only available for teachers (used in diggr+)
  assessment_config: number; // which course specific assessment_config is used
  experience_level?: enum_basic_advanced; // ENUM(basic, advanced)
}

export interface block_exacomp_get_examples_for_subject_parameters {
  subjectid: number; // id of subject
  courseid: number; // id of course
  userid: number; // id of user
}

export interface block_exacomp_get_examples_for_subject_returns_item_quizes {
  quizid: number; // id of quiz
  quiz_title: string; // title of quiz
  quiz_grade: number; // sum grade of quiz
}

export interface block_exacomp_get_examples_for_subject_returns_item_examples {
  exampleid: number; // id of example
  example_title: string; // title of example
  example_item: number; // current item id
  example_status: number; // status of current item
  example_creatorid: number; // creator of example
}

export interface block_exacomp_get_examples_for_subject_returns_item {
  topicid: number; // id of topic
  title: string; // title of topic
  requireaction: boolean; // trainer action required or not
  examples: block_exacomp_get_examples_for_subject_returns_item_examples[];
  quizes?: block_exacomp_get_examples_for_subject_returns_item_quizes[]; // quiz data
}

export interface block_exacomp_get_examples_for_subject_with_lfs_infos_parameters {
  subjectid: number; // id of subject
  courseid: number; // id of course
  userid: number; // id of user
}

export interface block_exacomp_get_examples_for_subject_with_lfs_infos_returns_item_quizes {
  quizid: number; // id of quiz
  quiz_title: string; // title of quiz
  quiz_grade: number; // sum grade of quiz
}

export interface block_exacomp_get_examples_for_subject_with_lfs_infos_returns_item_examples {
  exampleid: number; // id of example
  numbering: string; // descriptor numbering
  example_title: string; // title of example
  example_item: number; // current item id
  example_status: number; // status of current item
  example_creatorid: number; // creator of example
}

export interface block_exacomp_get_examples_for_subject_with_lfs_infos_returns_item {
  topicid: number; // id of topic
  title: string; // title of topic
  requireaction: boolean; // trainer action required or not
  totalCompetencies: number; // amount of total competencies of this topic
  examples: block_exacomp_get_examples_for_subject_with_lfs_infos_returns_item_examples[];
  quizes?: block_exacomp_get_examples_for_subject_with_lfs_infos_returns_item_quizes[]; // quiz data
}

export interface block_exacomp_get_example_by_id_parameters {
  exampleid: number; // id of example
}

export interface block_exacomp_get_example_by_id_returns_quiz {
  quizid: number; // id of quiz
  quiz_title: string; // title of quiz
  quiz_grade: number; // sum grade of quiz
}

export interface block_exacomp_get_example_by_id_returns {
  title: string; // title of example
  description: string; // description of example
  taskfileurl: string; // task fileurl
  taskfilenames: string; // task filename
  externalurl: string; // externalurl of example
  task: string; // @deprecated
  solution: string; // solution(url/description) of example
  timeframe: string; // timeframe as string
  hassubmissions: boolean; // true if example has already submissions
  quiz: block_exacomp_get_example_by_id_returns_quiz;
}

export interface block_exacomp_get_descriptors_for_example_parameters {
  exampleid: number; // id of example
  courseid: number; // id of course
  userid: number; // id of user
}

export interface block_exacomp_get_descriptors_for_example_returns_item {
  descriptorid: number; // id of descriptor
  title: string; // title of descriptor
  evaluation: number; // evaluation of descriptor
}

export interface block_exacomp_get_descriptors_for_quiz_parameters {
  quizid: number; // id of quiz
  courseid: number; // id of course
  userid: number; // id of user
}

export interface block_exacomp_get_descriptors_for_quiz_returns_item {
  descriptorid: number; // id of descriptor
  title: string; // title of descriptor
  evaluation: number; // evaluation of descriptor
}

export interface block_exacomp_get_user_role_parameters {}

export interface block_exacomp_get_user_role_returns {
  role: number; // 1=trainer, 2=student
  diwipassapp_login: number; // 0=first, 1=not first
}

export interface block_exacomp_diggr_get_user_role_parameters {}

export interface block_exacomp_diggr_get_user_role_returns {
  role: number; // 1=trainer, 2=student
  diwipassapp_login: number; // 0=first, 1=not first
}

export interface block_exacomp_get_external_trainer_students_parameters {}

export interface block_exacomp_get_external_trainer_students_returns_item_examples {
  total: number;
  submitted: number;
  reached: number;
}

export interface block_exacomp_get_external_trainer_students_returns_item_cohorts {
  cohortid: number; // id of cohort
  name: string; // title of cohort
}

export interface block_exacomp_get_external_trainer_students_returns_item {
  userid: number; // id of user
  name: string; // name of user
  cohorts: block_exacomp_get_external_trainer_students_returns_item_cohorts[];
  requireaction: boolean; // trainer action required or not
  examples: block_exacomp_get_external_trainer_students_returns_item_examples;
}

export interface block_exacomp_get_subjects_for_user_parameters {
  userid: number; // id of user
}

export interface block_exacomp_get_subjects_for_user_returns_item {
  subjectid: number; // id of subject
  title: string; // title of subject
  courseid: number; // id of course
  requireaction: boolean; // whether example in this subject has been edited or not by the selected student
}

export interface diggrplus_get_subjects_and_topics_for_user_parameters {
  userid: number; // id of user
  courseid?: number; // default: int(-1) // id of course. This is used for teachers.
  showonlywithexamples?: boolean; // default: bool(true) // id of course. This is used for teachers.
}

export interface diggrplus_get_subjects_and_topics_for_user_returns_item_topics_descriptors {
  descriptorid: number; // id of example
  descriptortitle: string; // title of example
}

export interface diggrplus_get_subjects_and_topics_for_user_returns_item_topics {
  id: number; // id of example
  title: string; // title of example
  descriptors: diggrplus_get_subjects_and_topics_for_user_returns_item_topics_descriptors[];
}

export interface diggrplus_get_subjects_and_topics_for_user_returns_item {
  id: number; // id of subject
  title: string; // title of subject
  courseid: number; // id of course
  courseshortname: string; // courseshortname
  coursefullname: string; // coursefullname
  topics: diggrplus_get_subjects_and_topics_for_user_returns_item_topics[];
}

export interface diggrplus_get_niveaus_for_subject_parameters {
  subjectid: number; // id of subject
}

export interface diggrplus_get_niveaus_for_subject_returns_item {
  niveauid: number; // id of subject
  niveautitle: string; // title of subject
}

export interface block_exacomp_delete_item_parameters {
  itemid: number; // id of item
}

export interface block_exacomp_delete_item_returns {
  success: boolean; // status
}

export interface block_exacomp_set_competence_parameters {
  courseid: number; // id of course
  descriptorid: number; // id of descriptor
  value: number; // evaluation value
}

export interface block_exacomp_set_competence_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface block_exacomp_get_item_for_example_parameters {
  userid: number; // id of user
  itemid: number; // id of item
}

export enum enum_note_file_link {
  NOTE = 'note',
  FILE = 'file',
  LINK = 'link',
}

export { enum_note_file_link as block_exacomp_get_item_for_example_returns_type };

export interface block_exacomp_get_item_for_example_returns {
  id: number; // id of item
  name: string; // title of item
  type: enum_note_file_link; // type of item ENUM(note,file,link)
  url: string; // url
  effort: string; // description of the effort
  filename: string; // title of item
  file: string; // file url
  isimage: boolean; // true if file is image
  status: number; // status of the submission
  teachervalue: number; // teacher grading
  studentvalue: number; // student grading
  teachercomment: string; // teacher comment
  studentcomment: string; // student comment
}

export interface block_exacomp_get_competencies_for_upload_parameters {
  userid: number; // id of user
}

export interface block_exacomp_get_competencies_for_upload_returns_item_topics_descriptors {
  descriptorid: number; // id of example
  descriptortitle: string; // title of example
}

export interface block_exacomp_get_competencies_for_upload_returns_item_topics {
  topicid: number; // id of example
  topictitle: string; // title of example
  descriptors: block_exacomp_get_competencies_for_upload_returns_item_topics_descriptors[];
}

export interface block_exacomp_get_competencies_for_upload_returns_item {
  subjectid: number; // id of topic
  subjecttitle: string; // title of topic
  topics: block_exacomp_get_competencies_for_upload_returns_item_topics[];
}

export interface block_exacomp_submit_example_parameters {
  exampleid: number; // exampleid
  studentvalue: number; // studentvalue
  url: string; // url
  effort: string; // effort
  filename: string; // filename, used to look up file and create a new one in the exaport file area
  fileitemid: number; // fileitemid, used to look up file and create a new one in the exaport file area
  studentcomment: string; // studentcomment
  title: string; // title
  itemid: number; // itemid
  courseid: number; // courseid
}

export interface block_exacomp_submit_example_returns {
  success: boolean; // status
  itemid: number; // itemid
}

export interface block_exacomp_create_or_update_example_parameters {
  exampleid?: number; // default: int(-1) // id of the example that is to be updated
  name: string; // title of example
  description: string; // description of example
  timeframe?: string; // default: string(0) "" // description of example
  externalurl?: string; // default: string(4) "wwww"
  comps?: string; // default: string(1) "0" // list of competencies, seperated by comma, or "freemat" if freematerial should be created
  fileitemids?: string; // default: string(0) "" // fileitemids separated by comma
  solutionfileitemid?: string; // default: string(0) "" // fileitemid
  taxonomies?: string; // default: string(0) "" // list of taxonomies
  newtaxonomy?: string; // default: string(0) "" // new taxonomy to be created
  courseid?: number; // default: int(0) // courseid
  filename?: string; // default: string(0) "" // deprecated (old code for maybe elove?) filename, used to look up file and create a new one in the exaport file area
  crosssubjectid?: number; // default: int(-1) // id of the crosssubject if it is a crosssubjectfile
  activityid?: number; // default: int(0) // id of related activity
  is_teacherexample?: number; // default: int(0) // is a teacher example?
}

export interface block_exacomp_create_or_update_example_returns_newtaxonomy {
  id: number; // amount of total competencies
  source: string; // amount of reached competencies
  title: string; // amount of reached competencies
}

export interface block_exacomp_create_or_update_example_returns {
  exampleid: number; // id of created example
  newtaxonomy: block_exacomp_create_or_update_example_returns_newtaxonomy;
}

export interface diggrplus_create_or_update_example_parameters {
  exampleid?: number; // default: int(-1) // id of the example that is to be updated
  name?: string; // default: string(0) "" // title of example
  description?: string; // default: string(0) "" // description of example
  timeframe?: string; // default: string(0) "" // description of example
  externalurl?: string; // default: string(4) "wwww"
  comps?: string; // default: string(1) "0" // list of descriptorids, seperated by comma, or "freemat" if freematerial should be created
  taxonomies?: string; // default: string(0) "" // list of taxonomies (comma seperated)
  newtaxonomy?: string; // default: string(0) "" // new taxonomy to be created
  courseid?: number; // default: int(0) // courseid
  crosssubjectid?: number; // default: int(-1) // id of the crosssubject if it is a crosssubjectfile
  fileitemids?: string; // default: string(0) "" // fileitemids separated by comma, used to look up file and create a new one in the exaport file area
  removefiles?: string; // default: string(0) "" // fileindizes/pathnamehashes of the files that should be removed, separated by comma
  solutionfileitemid?: string; // default: string(0) "" // fileitemid for the solutionfile
  activityid?: number; // default: int(0) // id of related activity
  is_teacherexample?: number; // default: int(0) // is a teacher example?
}

export interface diggrplus_create_or_update_example_returns {
  exampleid: number; // id of created example
  success: boolean; // status
}

export interface diggrplus_grade_descriptor_parameters {
  descriptorid: number; // id of descriptor
  grading: number; // grade for this descriptor
  courseid: number; // id of course
  userid: number; // id of user, if 0 current user
  role: number; // user role (0 == student, 1 == teacher)
  subjectid?: number; // default: int(-1) // subjectid
}

export interface diggrplus_grade_descriptor_returns {
  success: boolean; // true if grading was successful
}

export interface diggrplus_grade_element_parameters {
  elementid: number; // id of element
  type: string; // example, descriptor, topic
  grading: number; // grade for this element
  courseid: number; // id of course
  userid: number; // id of user, if 0 current user
  role: number; // user role (0 == student, 1 == teacher)
}

export interface diggrplus_grade_element_returns {
  success: boolean; // true if grading was successful
}

export interface diggrplus_grade_competency_parameters {
  compid: number; // competency id
  comptype: number; // competency type
  grading: number; // grade for this element
  courseid: number; // id of course
  userid: number; // id of user, if 0 current user
  role: number; // user role (0 == student, 1 == teacher)
}

export interface diggrplus_grade_competency_returns {
  success: boolean; // true if grading was successful
}

export interface diggrplus_get_all_competency_gradings_parameters {
  compid: number; // competence id
  comptype: number; // type of competence: descriptor, topic, subject
  userid: number;
}

export interface diggrplus_get_all_competency_gradings_returns_item_reviewer {
  userid: number;
  fullname: string;
  profileimageurl: string;
}

export interface diggrplus_get_all_competency_gradings_returns_item {
  id: number; // id of grading
  reviewer?: diggrplus_get_all_competency_gradings_returns_item_reviewer; // reviewing teacher
  courseid: number; // id of course
  coursefullname: string; // id of course
  grading: number; // grade for this element
  timestamp: number; // timemodified
}

export interface diggrplus_msteams_import_students_parameters {
  courseid: number; // id of course to import to
  access_token: string; // msteams access token
  teamid: string; // uuid of msteams team to import from
}

export interface diggrplus_msteams_import_students_returns {
  total_count: number; // number of users in the team
  imported_count: number; // number of newly imported users
}

export interface diggrplus_msteams_get_access_token_parameters {
  tenantid: string;
  authentication_token: string; // msteams authentication token
}

export interface diggrplus_msteams_get_access_token_returns {
  access_token: string;
}

export interface block_exacomp_grade_item_parameters {
  userid: number; // id of user
  value: number; // value for grading
  status: number; // status
  comment: string; // comment of grading
  itemid: number; // id of item
  comps: string; // comps for example - positive grading
  courseid: number; // if of course
}

export interface block_exacomp_grade_item_returns {
  success: boolean; // true if grading was successful
}

export interface block_exacomp_get_user_examples_parameters {}

export interface block_exacomp_get_user_examples_returns_item {
  exampleid: number; // id of example
  exampletitle: string; // title of example
  example_status: number; // status of example
  exampletopicid: number; // topic id where example belongs to
}

export interface block_exacomp_get_user_profile_parameters {
  userid: number; // id of user
}

export interface block_exacomp_get_user_profile_returns_subjects_topics_data_examples {
  total: number; // amount of total competencies
  reached: number; // amount of reached competencies
}

export interface block_exacomp_get_user_profile_returns_subjects_topics_data_competencies {
  total: number; // amount of total competencies
  reached: number; // amount of reached competencies
}

export interface block_exacomp_get_user_profile_returns_subjects_topics_data {
  competencies: block_exacomp_get_user_profile_returns_subjects_topics_data_competencies;
  examples: block_exacomp_get_user_profile_returns_subjects_topics_data_examples;
}

export interface block_exacomp_get_user_profile_returns_subjects_topics {
  title: string; // topic title
  data: block_exacomp_get_user_profile_returns_subjects_topics_data;
}

export interface block_exacomp_get_user_profile_returns_subjects_data_examples {
  total: number; // amount of total competencies
  reached: number; // amount of reached competencies
}

export interface block_exacomp_get_user_profile_returns_subjects_data_competencies {
  total: number; // amount of total competencies
  reached: number; // amount of reached competencies
}

export interface block_exacomp_get_user_profile_returns_subjects_data {
  competencies: block_exacomp_get_user_profile_returns_subjects_data_competencies;
  examples: block_exacomp_get_user_profile_returns_subjects_data_examples;
}

export interface block_exacomp_get_user_profile_returns_subjects {
  title: string; // subject title
  data: block_exacomp_get_user_profile_returns_subjects_data;
  topics: block_exacomp_get_user_profile_returns_subjects_topics[];
}

export interface block_exacomp_get_user_profile_returns_user_examples {
  total: number; // amount of total competencies
  reached: number; // amount of reached competencies
}

export interface block_exacomp_get_user_profile_returns_user_competencies {
  total: number; // amount of total competencies
  reached: number; // amount of reached competencies
}

export interface block_exacomp_get_user_profile_returns_user {
  competencies: block_exacomp_get_user_profile_returns_user_competencies;
  examples: block_exacomp_get_user_profile_returns_user_examples;
}

export interface block_exacomp_get_user_profile_returns {
  user: block_exacomp_get_user_profile_returns_user;
  subjects: block_exacomp_get_user_profile_returns_subjects[];
}

export interface block_exacomp_update_example_parameters {
  exampleid: number; // id of example
  name: string; // title of example
  description: string; // description of example
  externalurl: string;
  comps: string; // list of competencies, seperated by comma
  filename: string; // filename, used to look up file and create a new one in the exaport file area
  fileitemid: number; // fileitemid
}

export interface block_exacomp_update_example_returns {
  success: boolean; // true if successful
}

export interface block_exacomp_delete_example_parameters {
  exampleid: number; // id of example
}

export interface block_exacomp_delete_example_returns {
  success: boolean; // true if successful
}

export interface block_exacomp_get_competencies_by_topic_parameters {
  userid: number; // id of user
  topicid: number; // id of topic
}

export interface block_exacomp_get_competencies_by_topic_returns_item {
  descriptorid: number; // id of example
  descriptortitle: string; // title of example
}

export interface dakora_set_competence_parameters {
  courseid: number; // id of course
  userid: number; // id of user, if 0 current user
  compid: number; // competence id
  comptype: number; // type of competence: descriptor, topic, subject
  role: number; // user role (0 == student, 1 == teacher)
  value: number; // evaluation value, only set for TK (0 to 3)
  additionalinfo: number; // decimal between 1 and 6
  evalniveauid: number; // evaluation niveau (-1, 1, 2, 3)
  subjectid?: number; // default: int(-1) // subjectid
}

export interface dakora_set_competence_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_get_courses_parameters {
  userid?: number; // default: NULL // id of user
}

export { enum_basic_advanced as dakora_get_courses_returns_item_experience_level };

export interface dakora_get_courses_returns_item {
  courseid: number; // id of course
  fullname: string; // fullname of course
  shortname: string; // shortname of course
  exarole: number; // 1=trainer, 2=student
  teachercanedit: boolean;
  course_topics_configured?: boolean; // only available for teachers (used in diggr+)
  assessment_config: number; // which course specific assessment_config is used
  experience_level?: enum_basic_advanced; // ENUM(basic, advanced)
}

export interface dakora_get_topics_by_course_parameters {
  courseid: number; // id of course
  userid?: number; // default: int(0) // id of user, 0 for current user
  forall?: boolean; // default: int(0) // for all users = true, for one user = false
}

export interface dakora_get_topics_by_course_returns_item {
  topicid: number; // id of topic
  topictitle: string; // title of topic
  numbering: string; // numbering for topic
  subjectid: number; // id of subject
  subjecttitle: string; // title of subject
  visible: number; // visibility of topic in current context
  used: number; // used in current context
}

export interface dakora_get_all_topics_by_course_parameters {
  courseid: number; // id of course
  userid?: number; // default: int(0) // id of user, 0 for current user
  forall?: boolean; // default: int(0) // for all users = true, for one user = false
  groupid?: number; // default: NULL // id of user, 0 for current user
}

export interface dakora_get_all_topics_by_course_returns_activitylist {
  id: number; // id
  name: string; // name
}

export interface dakora_get_all_topics_by_course_returns_topics {
  topicid: number; // id of topic
  topictitle: string; // title of topic
  topicdescription: string; // description of topic
  numbering: string; // numbering for topic
  subjectid: number; // id of subject
  subjecttitle: string; // title of subject
  visible: number; // visibility of topic in current context
  used: number; // used in current context
}

export interface dakora_get_all_topics_by_course_returns {
  topics: dakora_get_all_topics_by_course_returns_topics[];
  activitylist: dakora_get_all_topics_by_course_returns_activitylist[];
}

export interface dakora_get_descriptors_parameters {
  courseid: number; // id of course
  topicid: number; // id of topic
  userid: number; // id of user, 0 for current user
  forall: boolean; // for all users = true, for one user = false
}

export interface dakora_get_descriptors_returns_item {
  descriptorid: number; // id of descriptor
  descriptortitle: string; // title of descriptor
  numbering: string; // numbering for descriptor
  niveautitle: string; // title of niveau
  niveaudescription: string; // description of niveau
  niveauid: number; // id of niveau
  visible: number; // visibility of topic in current context
  niveauvisible: boolean; // if niveau is visible
  used: number; // used in current context
  niveausort: number; // sorting for ids
}

export interface dakora_get_all_descriptors_parameters {
  courseid: number; // id of course
  topicid: number; // id of topic
  userid: number; // id of user, 0 for current user
  forall: boolean; // for all users = true, for one user = false
  editmode?: boolean; // default: int(0) // when editmode is active, descriptors fo hidden niveaus should be loaded
}

export interface dakora_get_all_descriptors_returns_item {
  descriptorid: number; // id of descriptor
  descriptortitle: string; // title of descriptor
  numbering: string; // numbering for descriptor
  niveautitle: string; // title of niveau
  niveauid: number; // id of niveau
  niveaudescription: string; // description of niveau
  visible: number; // visibility of topic in current context
  used: number; // used in current context
  gradingisold: boolean; // default: NULL // true when there are newer gradings in the childcompetences
  niveauvisible: boolean; // if niveau is visible
  niveausort: number; // default: NULL // sorting for ids
}

export interface dakora_get_descriptor_children_parameters {
  courseid: number; // id of course
  descriptorid: number; // id of parent descriptor
  userid: number; // id of user, 0 for current user
  forall: boolean; // for all users = true, for one user = false
}

export interface dakora_get_descriptor_children_returns_examples {
  exampleid: number; // id of example
  exampletitle: string; // title of example
  examplestate: number; // state of example, always 0 if for all students
  visible: number; // visibility of example
  used: number; // used in current context
}

export interface dakora_get_descriptor_children_returns_children {
  descriptorid: number; // id of child
  descriptortitle: string; // title of child
  numbering: string; // numbering for child
  teacherevaluation: number; // grading of child
  evalniveauid: number; // evaluation niveau id
  timestampteacher: number; // timestamp of teacher evaluation
  studentevaluation: number; // self evaluation of child
  timestampstudent: number; // timestamp of student evaluation
  examplestotal: number; // total number of material
  examplesvisible: number; // visible number of material
  examplesinwork: number; // edited number of material
  visible: number; // visibility of child
  used: number; // used in current context
}

export interface dakora_get_descriptor_children_returns {
  children: dakora_get_descriptor_children_returns_children[];
  examples: dakora_get_descriptor_children_returns_examples[];
  examplestotal: number; // number of total examples
  examplesvisible: number; // number of visible examples
  examplesinwork: number; // number of examples in work
}

export interface dakora_get_examples_for_descriptor_parameters {
  courseid: number; // id of course
  descriptorid: number; // id of parent descriptor
  userid: number; // id of user, if 0 current user
  forall: boolean; // if all users = true, only one user = false
}

export interface dakora_get_examples_for_descriptor_returns_item {
  exampleid: number; // id of descriptor
  exampletitle: string; // title of descriptor
  examplestate: number; // state of example, always 0 if for all students
  visible: number; // visibility of example in current context
  used: number; // used in current context
}

export interface dakora_get_examples_for_descriptor_with_grading_parameters {
  courseid: number; // id of course
  descriptorid: number; // id of parent descriptor
  userid: number; // id of user, if 0 current user
  forall: boolean; // if all users = true, only one user = false
}

export interface dakora_get_examples_for_descriptor_with_grading_returns_item {
  exampleid: number; // id of descriptor
  exampletitle: string; // title of descriptor
  examplestate: number; // state of example, always 0 if for all students
  visible: number; // visibility of example in current context
  used: number; // used in current context
  teacherevaluation: number; // example evaluation of teacher
  studentevaluation: number; // example evaluation of student
  evalniveauid: number; // evaluation niveau id
  timestampteacher: number; // timestamp for teacher evaluation
  timestampstudent: number; // timestamp for student evaluation
}

export interface dakora_get_examples_for_descriptor_for_crosssubject_parameters {
  courseid: number; // id of course
  descriptorid: number; // id of parent descriptor
  userid: number; // id of user, if 0 current user
  forall: boolean; // if all users = true, only one user = false
  crosssubjid: number; // id of crosssubject
}

export interface dakora_get_examples_for_descriptor_for_crosssubject_returns_item {
  exampleid: number; // id of descriptor
  exampletitle: string; // title of descriptor
  examplestate: number; // state of example, always 0 if for all students
  visible: number; // visibility of example in current context
  used: number; // used in current context
}

export interface dakora_get_examples_for_descriptor_for_crosssubject_with_grading_parameters {
  courseid: number; // id of course
  descriptorid: number; // id of parent descriptor
  userid: number; // id of user, if 0 current user
  forall: boolean; // if all users = true, only one user = false
  crosssubjid: number; // id of crosssubject
}

export interface dakora_get_examples_for_descriptor_for_crosssubject_with_grading_returns_item {
  exampleid: number; // id of descriptor
  exampletitle: string; // title of descriptor
  examplestate: number; // state of example, always 0 if for all students
  visible: number; // visibility of example in current context
  used: number; // used in current context
  teacherevaluation: number; // example evaluation of teacher
  studentevaluation: number; // example evaluation of student
  evalniveauid: number; // evaluation niveau id
  timestampteacher: number; // timestamp for teacher evaluation
  timestampstudent: number; // timestamp for student evaluation
}

export interface dakora_get_example_overview_parameters {
  courseid: number; // id of course
  exampleid: number; // id of example
  userid: number; // id of user, if 0 current user
}

export interface dakora_get_example_overview_returns {
  title: string; // title of example
  description: string; // description of example
  taskfileurl: string; // task fileurl
  taskfilenames: string; // task filename
  solutionfilename?: string; // task filename
  externalurl: string; // externalurl of example
  externaltask: string; // url of associated module
  externaltask_embedded?: string; // url of associated module, link to embedded view in exacomp
  task: string; // @deprecated
  taskfilecount: string; // number of files for the task
  solution: string; // solution(url/description) of example
  timeframe: string; // timeframe as string
  hassubmissions: boolean; // true if example has already submissions
  solution_visible: boolean; // visibility for example solution in current context
  exampletaxonomies?: string; // taxonomies seperated by comma
  exampletaxids?: string; // taxids seperated by comma
  is_teacherexample?: boolean; // is teacher example?
}

export interface diggrplus_get_example_overview_parameters {
  courseid: number; // id of course
  exampleid: number; // id of example
  userid: number; // id of user, if 0 current user
}

export interface diggrplus_get_example_overview_returns_taskfiles {
  name: string; // title of taskfile
  url: string; // file url
  type: string; // mime type for file
}

export interface diggrplus_get_example_overview_returns {
  id: number; // id of example
  visible: boolean; // visibility of example
  title: string; // title of example
  description: string; // description of example
  solutionfilename?: string; // task filename
  externalurl: string; // externalurl of example
  externaltask: string; // url of associated module
  externaltask_embedded?: string; // url of associated module, link to embedded view in exacomp
  solution: string; // solution(url/description) of example
  timeframe: string; // timeframe as string
  hassubmissions: boolean; // true if example has already submissions
  solution_visible: boolean; // visibility for example solution in current context
  exampletaxonomies?: string; // taxonomies seperated by comma
  exampletaxids?: string; // taxids seperated by comma
  is_teacherexample?: boolean; // is teacher example?
  creatorid: number; // creatorid
  annotation: string; // annotation by the teacher for this example in this course
  taskfiles?: diggrplus_get_example_overview_returns_taskfiles[]; // taskfiles of the example
  completefile?: string; // completefile (url/description) of example
  completefilefilename?: string; // completefile filename
}

export interface dakora_add_example_to_learning_calendar_parameters {
  courseid: number; // id of course
  exampleid: number; // id of example
  creatorid: number; // id of creator
  userid: number; // id of user, if 0 current user
  forall: boolean; // for all users = true, for one user = false
  groupid?: number; // default: NULL // id of group
}

export interface dakora_add_example_to_learning_calendar_returns {
  scheduleid: number; // id of the single added example
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_get_descriptors_for_example_parameters {
  exampleid: number; // id of example
  courseid: number; // id of course
  userid: number; // id of user
  forall: boolean; // for all users = true, for one user = false
}

export interface dakora_get_descriptors_for_example_returns_item {
  descriptorid: number; // id of descriptor
  title: string; // title of descriptor
  teacherevaluation: number; // evaluation of descriptor
  studentevaluation: number; // student evaluation of descriptor
  evalniveauid: number; // evaluation niveau id
  niveauid: number; // id of niveau
  additionalinfo: number; // additional grading for descriptor
  topicid: number; // id of topic
  numbering: string; // descriptor numbering
  child: boolean; // true: child, false: parent
  parentid: number; // parentid if child, 0 otherwise
  gradingisold: boolean; // default: NULL // true when there are newer gradings in the childcompetences
  reviewerid: number; // id of reviewer
  reviewername: string; // name of reviewer
}

export interface dakora_get_example_grading_parameters {
  exampleid: number; // id of example
  courseid: number; // id of course
  userid: number; // id of user, if 0 current user
}

export interface dakora_get_example_grading_returns {
  teacherevaluation: number; // teacher evaluation for student and example
  studentevaluation: number; // self evaluation for example
  evalniveauid: number; // evaluation niveau id
}

export interface dakora_get_topic_grading_parameters {
  topicid: number; // id of topic
  courseid: number; // id of course
  userid: number; // id of user, if 0 current user
}

export interface dakora_get_topic_grading_returns {
  teacherevaluation: number; // teacher evaluation for student and topic
  additionalinfo: number; // teacher additional info for student and topic
  studentevaluation: number; // self evaluation for topic
  evalniveauid: number; // evaluation niveau id
  timestampteacher: number; // timestamp for teacher evaluation
  timestampstudent: number; // timestamp for student evaluation
}

export interface dakora_get_subject_grading_parameters {
  subjectid: number; // id of subject
  courseid: number; // id of course
  userid: number; // id of user, if 0 current user
}

export interface dakora_get_subject_grading_returns {
  teacherevaluation: number; // teacher evaluation for student and subject
  additionalinfo: number; // teacher additional info for student and subject
  studentevaluation: number; // self evaluation for subject
  evalniveauid: number; // evaluation niveau id
  timestampteacher: number; // timestamp for teacher evaluation
  timestampstudent: number; // timestamp for student evaluation
}

export interface dakora_get_user_role_parameters {}

export interface dakora_get_user_role_returns {
  role: number; // 1=trainer, 2=student
}

export interface dakora_get_students_and_groups_for_course_parameters {
  courseid: number; // id of course
}

export interface dakora_get_students_and_groups_for_course_returns_groups {
  id: number; // id of group
  name: string; // name of group
  picture: string; // link to picture
}

export interface dakora_get_students_and_groups_for_course_returns_students {
  studentid: number; // id of student
  firstname: string; // firstname of student
  lastname: string; // lastname of student
  profilepicture: string; // link to profile picture
}

export interface dakora_get_students_and_groups_for_course_returns {
  students: dakora_get_students_and_groups_for_course_returns_students[];
  groups: dakora_get_students_and_groups_for_course_returns_groups[];
}

export interface dakora_get_students_for_teacher_parameters {
  userid: number; // id of user
}

export interface dakora_get_students_for_teacher_returns_item {
  id: number; // id of student
  firstname: string; // firstname of student
  lastname: string; // lastname of student
}

export interface dakora_get_teachers_for_student_parameters {
  userid: number; // id of user
}

export interface dakora_get_teachers_for_student_returns_item {
  id: number; // id of teacher
  firstname: string; // firstname of teacher
  lastname: string; // lastname of teacher
}

export interface dakora_get_examples_pool_parameters {
  courseid: number; // id of course
  userid: number; // id of user, if 0 current user
}

export interface dakora_get_examples_pool_returns_item_taxonomies {
  id: number; // id
  title: string; // name
  source: string; // source
}

export enum enum_new_inprogress_submitted_completed {
  NEW = 'new',
  INPROGRESS = 'inprogress',
  SUBMITTED = 'submitted',
  COMPLETED = 'completed',
}

export { enum_new_inprogress_submitted_completed as dakora_get_examples_pool_returns_item_itemstatus };

export interface dakora_get_examples_pool_returns_item {
  exampleid: number; // id of example
  title: string; // title of example
  student_evaluation: number; // self evaluation of student
  teacher_evaluation: number; // evaluation of teacher
  evalniveauid: number; // evaluation niveau id
  courseid: number; // example course
  state: number; // state of example
  scheduleid: number; // id in schedule context
  creatorid: number; // example added to pool by userid
  lastmodifiedbyid: number; // example pool state last edited by userid
  courseshortname: string; // shortname of example course
  coursefullname: string; // full name of example course
  exampletaxonomies?: string; // taxonomies seperated by comma
  exampletaxids?: string; // taxids seperated by comma
  source?: string; // tag where the material comes from
  timeframe: string; // timeframe, suggested time
  itemstatus: enum_new_inprogress_submitted_completed; // status of the item as text ENUM(new, inprogress, submitted, completed)
  is_overdue: boolean;
  taxonomies: dakora_get_examples_pool_returns_item_taxonomies[]; // values
}

export interface dakora_get_examples_trash_parameters {
  courseid: number; // id of course
  userid: number; // id of user, if 0 current user
}

export interface dakora_get_examples_trash_returns_item {
  exampleid: number; // id of example
  title: string; // title of example
  student_evaluation: number; // self evaluation of student
  teacher_evaluation: number; // evaluation of teacher
  evalniveauid: number; // evaluation niveau id
  courseid: number; // example course
  state: number; // state of example
  scheduleid: number; // id in schedule context
  courseshortname: string; // shortname of example course
  coursefullname: string; // full name of example course
  source?: string; // tag where the material comes from
}

export interface dakora_set_example_time_slot_parameters {
  scheduleid: number; // id in schedule context
  start: number; // start timestamp
  end: number; // end timestamp
  deleted: number; // delete item
}

export interface dakora_set_example_time_slot_returns {
  timeremaining: string; // time planned minus timeframe = timeremaining
  timeplanned: string; // time planned
  timesuggested: string; // timeframe
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_remove_example_from_schedule_parameters {
  scheduleid: number; // id of schedule entry
}

export interface dakora_remove_example_from_schedule_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_add_examples_to_schedule_for_all_parameters {
  courseid: number; // id of course
}

export interface dakora_add_examples_to_schedule_for_all_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_get_examples_for_time_slot_parameters {
  userid: number; // id of user, if 0 current user
  start: number; // start timestamp
  end: number; // end timestamp
}

export { enum_new_inprogress_submitted_completed as dakora_get_examples_for_time_slot_returns_item_itemstatus };

export interface dakora_get_examples_for_time_slot_returns_item_taxonomies {
  id: number; // id
  title: string; // name
  source: string; // source
}

export interface dakora_get_examples_for_time_slot_returns_item {
  exampleid: number; // id of example
  title: string; // title of example
  start: number; // start of event
  end: number; // end of event
  timeframe: string; // timeframe
  student_evaluation: number; // self evaluation of student
  teacher_evaluation: number; // evaluation of teacher
  evalniveauid: number; // evaluation niveau id
  courseid: number; // example course
  state: number; // state of example
  scheduleid: number; // id in schedule context
  creatorid: number; // example added to pool by userid
  lastmodifiedbyid: number; // example pool state last edited by userid
  addedtoschedulebyid: number; // example added to plan by userid (may be 0, if added outside of dakora!)
  courseshortname: string; // shortname of example course
  coursefullname: string; // full name of example course
  exampletaxonomies?: string; // taxonomies seperated by comma
  exampletaxids?: string; // taxids seperated by comma
  taxonomies: dakora_get_examples_for_time_slot_returns_item_taxonomies[]; // values
  source?: string; // tag where the material comes from
  schedule_marker?: string; // tag for the marker on the material in the weekly schedule
  editable: boolean; // for blocking events: show if editable (special for dakora?)
  itemstatus: enum_new_inprogress_submitted_completed; // status of the item as text ENUM(new, inprogress, submitted, completed)
}

export interface dakora_get_cross_subjects_by_course_parameters {
  courseid: number; // id of course
  userid: number; // id of user, if 0 current user
  forall: boolean; // for all users = true, for one user = false
  allcrosssubjects?: boolean; // default: int(0) // for all allcross subjects = true (no course selected)
}

export interface dakora_get_cross_subjects_by_course_returns_item_examples {
  exampleid: number; // id of example
  exampletitle: string; // title of example
  examplestate: number; // state of example, always 0 if for all students
  visible: number; // visibility of example in current context
  used: number; // used in current context
  teacherevaluation: number; // example evaluation of teacher
  studentevaluation: number; // example evaluation of student
  timestampteacher: number; // timestamp of teacher evaluation
  timestampstudent: number; // timestamp of student evaluation
  evalniveauid: number; // evaluation niveau id
  solution_visible: boolean; // visibility for example solution in current context
}

export interface dakora_get_cross_subjects_by_course_returns_item {
  id: number; // id of cross subject
  title: string; // title of cross subject
  description: string; // description of cross subject
  subjectid: number; // subject id, cross subject is associated with
  visible: number; // visibility of crosssubject for selected student
  groupcategory: string; // name of groupcategory
  hasmaterial: boolean; // true or false if crosssubject has material
  examples: dakora_get_cross_subjects_by_course_returns_item_examples[];
  additionalinfo: number; // additional grading
  teacherevaluation?: number; // grading of child
  evalniveauid?: number; // evaluation niveau id
  timestampteacher?: number; // timestamp of teacher evaluation
  studentevaluation?: number; // self evaluation of child
  timestampstudent?: number; // timestamp of student evaluation
}

export interface dakora_get_descriptors_by_cross_subject_parameters {
  courseid: number; // id of course
  crosssubjid: number; // id of cross subject
  userid: number; // id of user, if 0 current user
  forall: boolean; // for all users = true, for one user = false
}

export interface dakora_get_descriptors_by_cross_subject_returns_item {
  descriptorid: number; // id of descriptor
  descriptortitle: string; // title of descriptor
  numbering: string; // numbering for descriptor
  niveautitle: string; // title of nivaue
  niveauid: number; // id of niveau
  visible: number; // visibility of example in current context
  used: number; // used in current context
}

export interface dakora_get_all_descriptors_by_cross_subject_parameters {
  courseid: number; // id of course
  crosssubjid: number; // id of cross subject
  userid: number; // id of user, if 0 current user
  forall: boolean; // for all users = true, for one user = false
}

export interface dakora_get_all_descriptors_by_cross_subject_returns_item {
  descriptorid: number; // id of descriptor
  descriptortitle: string; // title of descriptor
  numbering: string; // numbering for descriptor
  niveautitle: string; // title of nivaue
  niveauid: number; // id of niveau
  visible: number; // visibility of example in current context
  used: number; // used in current context
}

export interface dakora_get_descriptor_children_for_cross_subject_parameters {
  courseid: number; // id of course
  descriptorid: number; // id of parent descriptor
  userid: number; // id of user, 0 for current user
  forall: boolean; // for all users = true, for one user = false
  crosssubjid: number; // id of cross subject
}

export interface dakora_get_descriptor_children_for_cross_subject_returns_examples {
  exampleid: number; // id of example
  exampletitle: string; // title of example
  examplestate: number; // state of example, always 0 if for all students
  visible: number; // visibility of example in current context
  used: number; // used in current context
}

export interface dakora_get_descriptor_children_for_cross_subject_returns_children {
  descriptorid: number; // id of child
  descriptortitle: string; // title of child
  numbering: string; // numbering for child
  teacherevaluation: number; // grading of children
  evalniveauid: number; // evaluation niveau id
  timestampteacher: number; // timestamp of teacher evaluation
  studentevaluation: number; // self evaluation of children
  timestampstudent: number; // timestamp of student evaluation
  examplestotal: number; // total number of material
  examplesvisible: number; // visible number of material
  examplesinwork: number; // edited number of material
  visible: number; // visibility of child in current context
  used: number; // used in current context
}

export interface dakora_get_descriptor_children_for_cross_subject_returns {
  children: dakora_get_descriptor_children_for_cross_subject_returns_children[];
  examples: dakora_get_descriptor_children_for_cross_subject_returns_examples[];
  examplestotal: number; // number of total examples
  examplesvisible: number; // number of visible examples
  examplesinwork: number; // number of examples in work
}

export interface dakora_get_all_descriptor_children_for_cross_subject_parameters {
  courseid: number; // id of course
  descriptorid: number; // id of parent descriptor
  userid: number; // id of user, 0 for current user
  forall: boolean; // for all users = true, for one user = false
  crosssubjid: number; // id of cross subject
}

export interface dakora_get_all_descriptor_children_for_cross_subject_returns_examples {
  exampleid: number; // id of example
  exampletitle: string; // title of example
  examplestate: number; // state of example, always 0 if for all students
  visible: number; // visibility of example in current context
  used: number; // used in current context
}

export interface dakora_get_all_descriptor_children_for_cross_subject_returns_children {
  descriptorid: number; // id of child
  descriptortitle: string; // title of child
  numbering: string; // numbering for child
  teacherevaluation: number; // grading of children
  evalniveauid: number; // evaluation niveau id
  timestampteacher: number; // timestamp of teacher evaluation
  studentevaluation: number; // self evaluation of children
  timestampstudent: number; // timestamp of student evaluation
  hasmaterial: boolean; // true or false if child has materials
  examplestotal: number; // total number of material
  examplesvisible: number; // visible number of material
  examplesinwork: number; // edited number of material
  visible: number; // visibility of children in current context
  used: number; // used in current context
}

export interface dakora_get_all_descriptor_children_for_cross_subject_returns {
  children: dakora_get_all_descriptor_children_for_cross_subject_returns_children[];
  examples: dakora_get_all_descriptor_children_for_cross_subject_returns_examples[];
  examplestotal: number; // number of total examples
  examplesvisible: number; // number of visible examples
  examplesinwork: number; // number of examples in work
}

export interface dakora_get_schedule_config_parameters {}

export interface dakora_get_schedule_config_returns_periods {
  title: string; // id of example
}

export interface dakora_get_schedule_config_returns {
  units: number; // number of units per day
  interval: string; // duration of unit in minutes
  begin: string; // begin time for the first unit, format hh:mm
  periods: dakora_get_schedule_config_returns_periods[];
}

export interface dakora_get_pre_planning_storage_examples_parameters {
  courseid: number; // id of course
}

export interface dakora_get_pre_planning_storage_examples_returns_item {
  exampleid: number; // id of example
  title: string; // title of example
  courseid: number; // example course
  state: number; // state of example
  scheduleid: number; // id in schedule context
}

export interface dakora_get_pre_planning_storage_students_parameters {
  courseid: number; // id of course
}

export interface dakora_get_pre_planning_storage_students_returns_item {
  studentid: number; // id of student
  firstname: string; // firstname of student
  lastname: string; // lastname of student
  has_examples: boolean; // already has examples from current pre planning storage
}

export interface dakora_get_pre_planning_storage_groups_parameters {
  courseid: number; // id of course
}

export interface dakora_get_pre_planning_storage_groups_returns_item {
  id: number; // id of group
  name: string; // name of group
  has_examples: boolean; // already has examples from current pre planning storage
}

export interface dakora_has_items_in_pre_planning_storage_parameters {
  courseid: number; // id of course
}

export interface dakora_has_items_in_pre_planning_storage_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_empty_pre_planning_storage_parameters {
  courseid: number; // id of course
}

export interface dakora_empty_pre_planning_storage_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_add_example_to_pre_planning_storage_parameters {
  courseid: number; // id of course
  exampleid: number; // id of example
}

export interface dakora_add_example_to_pre_planning_storage_returns {
  success: boolean; // status of success
}

export interface dakora_add_examples_to_students_schedule_parameters {
  courseid: number; // id of course
  examples: string; // json array of examples
  students: string; // json array of students
  groups?: string; // default: string(0) "" // json array of groups
}

export interface dakora_add_examples_to_students_schedule_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_add_examples_to_selected_students_schedule_parameters {
  courseid: number; // id of course
  students: string; // json array of students
  groups?: string; // default: string(0) "" // json array of groups
  distributionid?: string; // default: NULL // distribution id. used for undo button
}

export interface dakora_add_examples_to_selected_students_schedule_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_delete_examples_from_schedule_parameters {
  teacherid: number; // id of teacher
  distributionid: number; // distribution id
}

export interface dakora_delete_examples_from_schedule_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_undo_examples_from_schedule_parameters {
  teacherid: number; // id of teacher
  distributionid: number; // distribution id
}

export interface dakora_undo_examples_from_schedule_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_submit_example_parameters {
  exampleid: number; // exampleid
  studentvalue?: number; // default: int(-1) // studentvalue for grading
  url: string; // url
  filenames: string; // filenames, separated by comma, used to look up files and create a new ones in the exaport file area
  studentcomment: string; // studentcomment
  itemid: number; // itemid (<=0 for insert, >0 for update)
  courseid: number; // courseid
  fileitemids: string; // fileitemids separated by comma
}

export interface dakora_submit_example_returns {
  success: boolean; // status
  itemid: number; // itemid
}

export interface diggrplus_submit_item_parameters_descriptorgradings {
  descriptorid: number; // id of descriptor
  studentvalue: number; // studentvalue of descriptorgrading
}

export interface diggrplus_submit_item_parameters {
  compid: number; // id of topic/example
  studentvalue?: number; // default: int(-1) // studentvalue for grading
  url: string; // url
  filenames: string; // filenames, separated by comma, used to look up files and create a new ones in the exaport file area
  studentcomment: string; // studentcomment
  fileitemids: string; // fileitemids separated by comma, used to look up file and create a new one in the exaport file area
  itemid: number; // itemid (0 for insert, >0 for update)
  courseid: number; // courseid
  comptype: number; // comptype (example, topic, descriptor)
  itemtitle?: string; // default: string(0) "" // name of the item (for examples, the exampletitle is fitting, but for topics, using the topic would not be very useful
  collabuserids?: string; // default: string(0) "" // userids of collaborators separated by comma
  submit?: number; // default: int(0) // 1 for submitting definitely (submitted), 0 for only creating/updating the item (inprogress)
  removefiles: string; // fileindizes/pathnamehashes of the files that should be removed, separated by comma
  solutiondescription: string; // description of what the student has done
  descriptorgradings?: diggrplus_submit_item_parameters_descriptorgradings[]; // descriptors and gradingds
}

export interface diggrplus_submit_item_returns {
  success: boolean; // status
  itemid: number; // itemid
}

export interface diggrplus_submit_item_comment_parameters {
  itemid: number; // id of item
  comment: string; // comment text
  fileitemid?: string; // default: string(0) ""
}

export interface diggrplus_submit_item_comment_returns {
  success: boolean; // status
  itemid: number; // itemid
}

export interface diggrplus_get_item_comments_parameters {
  itemid: number; // id of item
}

export interface diggrplus_get_item_comments_returns_item_file {
  filename: string; // filename
  file: string; // file url
  mimetype: string; // mime type for file
}

export interface diggrplus_get_item_comments_returns_item {
  id: number; // commentid
  userid: number; // userid
  fullname: string; // fullname of user
  comment: string; // commenttext
  timemodified: number; // timemodified
  profileimageurl: string;
  file?: diggrplus_get_item_comments_returns_item_file;
}

export interface diggrplus_get_examples_and_items_parameters {
  courseid?: number; // default: int(-1) // courseid. Used if a topic is selected as filter
  userid: number; // id of user
  compid: number; // id of subject(3)/topic(1)/descriptor(0)/example(4) if <= 0 then show all items for user
  comptype: number; // Type of competence: subject/topic/descriptor/example if <= 0 then show all items for user
  type?: string; // default: string(0) "" // examples, own_items or empty
  search?: string; // default: string(0) "" // search string
  niveauid?: number; // default: int(-1) // niveauid normally stands for LFS1, LFS2, etc.
  status?: string; // default: string(0) "" // new, inprogress, submitted, completed. acts as a filter
}

export interface diggrplus_get_examples_and_items_returns_item_item_collaborators {
  userid: number; // userid of collaborator
  fullname: string;
  profileimageurl: string;
}

export interface diggrplus_get_examples_and_items_returns_item_item_studentfiles {
  id: number; // id
  filename: string; // filename
  file: string; // file url
  mimetype: string; // mime type for file
  fileindex: string; // fileindex, used for deleting this file
}

export interface diggrplus_get_examples_and_items_returns_item_item_owner {
  userid: number;
  fullname: string;
  profileimageurl: string;
}

export { enum_note_file_link as diggrplus_get_examples_and_items_returns_item_item_type };

export interface diggrplus_get_examples_and_items_returns_item_item {
  id: number; // id of item
  name: string; // title of item
  solutiondescription?: string; // description of item
  type?: enum_note_file_link; // type of item ENUM(note,file,link)
  url?: string; // url
  effort?: string; // description of the effort
  teachervalue?: number; // teacher grading
  studentvalue?: number; // student grading
  teachercomment?: string; // teacher comment
  studentcomment?: string; // student comment
  owner: diggrplus_get_examples_and_items_returns_item_item_owner;
  studentfiles?: diggrplus_get_examples_and_items_returns_item_item_studentfiles[]; // files of the student's submission
  collaborators?: diggrplus_get_examples_and_items_returns_item_item_collaborators[]; // collaborators
}

export interface diggrplus_get_examples_and_items_returns_item_example_taskfiles {
  name: string; // title of taskfile
  url: string; // file url
  type: string; // mime type for file
}

export interface diggrplus_get_examples_and_items_returns_item_example {
  id: number; // id of example
  title: string; // title of example
  description: string; // description of example
  annotation: string; // annotation by the teacher for this example in this course
  solutionfilename: string; // task filename
  externalurl: string; // externalurl of example
  externaltask: string; // url of associated module
  externaltask_embedded?: string; // url of associated module, link to embedded view in exacomp
  solution: string; // solution(url/description) of example
  timeframe: string; // timeframe as string
  hassubmissions: boolean; // true if example has already submissions
  solution_visible: boolean; // visibility for example solution in current context
  taskfiles?: diggrplus_get_examples_and_items_returns_item_example_taskfiles[]; // taskfiles of the example
  teacher_evaluation: number; // teacher_evaluation
  student_evaluation: number; // student_evaluation
}

export { enum_new_inprogress_submitted_completed as diggrplus_get_examples_and_items_returns_item_status };

export interface diggrplus_get_examples_and_items_returns_item {
  courseid: number;
  status: enum_new_inprogress_submitted_completed; // ENUM(new, inprogress, submitted, completed)
  subjectid: number; // id of subject
  subjecttitle: string; // title of subject
  topicid: number; // id of topic
  topictitle: string; // title of topic
  niveautitle: string; // title of niveau
  niveauid: number; // id of niveau
  timemodified: number; // time the item was last modified --> not gradings, but only changes to the item (files, comments, name, collaborators)
  example?: diggrplus_get_examples_and_items_returns_item_example; // example information
  item?: diggrplus_get_examples_and_items_returns_item_item; // item information
}

export interface diggrplus_get_teacher_examples_and_items_parameters {
  courseid: number;
  studentid: number;
  compid: number; // id of topic/descriptor/example if <= 0 then show all items for user
  comptype: number; // Type of competence: topic/descriptor/example if <= 0 then show all items for user
  type?: string; // default: string(0) "" // examples, own_items or empty
  search?: string; // default: string(0) "" // search string
  niveauid?: number; // default: int(-1) // niveauid normally stands for LFS1, LFS2, etc.
  status?: string; // default: string(0) "" // new, inprogress, submitted, completed. acts as a filter
}

export interface diggrplus_get_teacher_examples_and_items_returns_item_item_collaborators {
  userid: number; // userid of collaborator
  fullname: string;
  profileimageurl: string;
}

export interface diggrplus_get_teacher_examples_and_items_returns_item_item_studentfiles {
  id: number; // id
  filename: string; // filename
  file: string; // file url
  mimetype: string; // mime type for file
  fileindex: string; // fileindex, used for deleting this file
}

export interface diggrplus_get_teacher_examples_and_items_returns_item_item_owner {
  userid: number;
  fullname: string;
  profileimageurl: string;
}

export { enum_note_file_link as diggrplus_get_teacher_examples_and_items_returns_item_item_type };

export interface diggrplus_get_teacher_examples_and_items_returns_item_item {
  id: number; // id of item
  name: string; // title of item
  solutiondescription?: string; // description of item
  type?: enum_note_file_link; // type of item ENUM(note,file,link)
  url?: string; // url
  effort?: string; // description of the effort
  teachervalue?: number; // teacher grading
  studentvalue?: number; // student grading
  teachercomment?: string; // teacher comment
  studentcomment?: string; // student comment
  owner: diggrplus_get_teacher_examples_and_items_returns_item_item_owner;
  studentfiles?: diggrplus_get_teacher_examples_and_items_returns_item_item_studentfiles[]; // files of the student's submission
  collaborators?: diggrplus_get_teacher_examples_and_items_returns_item_item_collaborators[]; // collaborators
}

export interface diggrplus_get_teacher_examples_and_items_returns_item_example_taskfiles {
  name: string; // title of taskfile
  url: string; // file url
  type: string; // mime type for file
}

export interface diggrplus_get_teacher_examples_and_items_returns_item_example {
  id: number; // id of example
  title: string; // title of example
  description: string; // description of example
  annotation: string; // annotation by the teacher for this example in this course
  solutionfilename: string; // task filename
  externalurl: string; // externalurl of example
  externaltask: string; // url of associated module
  externaltask_embedded?: string; // url of associated module, link to embedded view in exacomp
  solution: string; // solution(url/description) of example
  timeframe: string; // timeframe as string
  hassubmissions: boolean; // true if example has already submissions
  solution_visible: boolean; // visibility for example solution in current context
  taskfiles?: diggrplus_get_teacher_examples_and_items_returns_item_example_taskfiles[]; // taskfiles of the example
  teacher_evaluation: number; // teacher_evaluation
  student_evaluation: number; // student_evaluation
}

export { enum_new_inprogress_submitted_completed as diggrplus_get_teacher_examples_and_items_returns_item_status };

export interface diggrplus_get_teacher_examples_and_items_returns_item {
  courseid: number;
  status: enum_new_inprogress_submitted_completed; // ENUM(new, inprogress, submitted, completed)
  subjectid: number; // id of subject
  subjecttitle: string; // title of subject
  topicid: number; // id of topic
  topictitle: string; // title of topic
  niveautitle: string; // title of niveau
  niveauid: number; // id of niveau
  timemodified: number; // time the item was last modified --> not gradings, but only changes to the item (files, comments, name, collaborators)
  example?: diggrplus_get_teacher_examples_and_items_returns_item_example; // example information
  item?: diggrplus_get_teacher_examples_and_items_returns_item_item; // item information
}

export interface diggrplus_get_all_subjects_for_course_as_tree_parameters {
  userid: number; // id of user
  courseid: number; // id of course
  subjectid?: number; // default: NULL // id of subject, if you only want one specific subject
  $select?: string; // default: NULL // select extra fields
}

export interface diggrplus_get_all_subjects_for_course_as_tree_returns_item_topics_descriptors_examples_taxonomies {
  id: number; // id
  title: string; // name
  source: string; // source
}

export { enum_new_inprogress_submitted_completed as diggrplus_get_all_subjects_for_course_as_tree_returns_item_topics_descriptors_examples_status };

export interface diggrplus_get_all_subjects_for_course_as_tree_returns_item_topics_descriptors_examples {
  id: number; // id of example
  title: string; // title of example
  creatorid: number; // creator of this example
  visible: boolean; // visibility of example in current context
  status: enum_new_inprogress_submitted_completed; // ENUM(new, inprogress, submitted, completed)
  teacherevaluation: number; // default: NULL
  studentevaluation: number; // default: NULL
  teacherevaluationcount?: number;
  taxonomies: diggrplus_get_all_subjects_for_course_as_tree_returns_item_topics_descriptors_examples_taxonomies[]; // values
}

export { enum_new_inprogress_submitted_completed as diggrplus_get_all_subjects_for_course_as_tree_returns_item_topics_descriptors_childdescriptors_examples_status };

export interface diggrplus_get_all_subjects_for_course_as_tree_returns_item_topics_descriptors_childdescriptors_examples {
  id: number; // id of example
  title: string; // title of example
  creatorid: number; // creator of this example
  visible: boolean; // visibility of example in current context
  status: enum_new_inprogress_submitted_completed; // ENUM(new, inprogress, submitted, completed)
  teacherevaluation: number; // default: NULL
  studentevaluation: number; // default: NULL
  teacherevaluationcount?: number;
}

export interface diggrplus_get_all_subjects_for_course_as_tree_returns_item_topics_descriptors_childdescriptors {
  id: number; // id of example
  niveauid: number; // id of the niveau (column) of this descriptor
  title: string; // title of example
  visible: boolean; // visibility of descriptor in current context
  used: boolean; // if descriptor is used
  teacherevaluation: number; // default: NULL
  studentevaluation: number; // default: NULL
  teacherevaluationcount?: number;
  examples: diggrplus_get_all_subjects_for_course_as_tree_returns_item_topics_descriptors_childdescriptors_examples[];
}

export interface diggrplus_get_all_subjects_for_course_as_tree_returns_item_topics_descriptors {
  id: number; // id of descriptor
  niveauid: number; // id of the niveau (column) of this descriptor
  title: string; // title of descriptor
  visible: boolean; // visibility of descriptor in current context
  used: boolean; // if descriptor is used
  teacherevaluation: number; // default: NULL
  studentevaluation: number; // default: NULL
  teacherevaluationcount?: number;
  childdescriptors: diggrplus_get_all_subjects_for_course_as_tree_returns_item_topics_descriptors_childdescriptors[];
  examples: diggrplus_get_all_subjects_for_course_as_tree_returns_item_topics_descriptors_examples[];
}

export interface diggrplus_get_all_subjects_for_course_as_tree_returns_item_topics {
  id: number; // id of topic
  title: string; // title of topic
  visible: boolean; // visibility of topic in current context
  used: boolean; // if topic is used
  teacherevaluation: number; // default: NULL
  studentevaluation: number; // default: NULL
  teacherevaluationcount?: number;
  descriptors: diggrplus_get_all_subjects_for_course_as_tree_returns_item_topics_descriptors[];
}

export interface diggrplus_get_all_subjects_for_course_as_tree_returns_item_used_niveaus {
  id: string; // id of niveau
  title: string; // title of niveau
}

export interface diggrplus_get_all_subjects_for_course_as_tree_returns_item {
  id: number; // id of subject
  title: string; // title of subject
  used_niveaus: diggrplus_get_all_subjects_for_course_as_tree_returns_item_used_niveaus[];
  courseid: number; // id of course
  courseshortname: string; // courseshortname
  coursefullname: string; // coursefullname
  topics: diggrplus_get_all_subjects_for_course_as_tree_returns_item_topics[];
}

export interface diggrplus_get_user_info_parameters {}

export interface diggrplus_get_user_info_returns {
  role: string;
}

export interface diggrplus_request_external_file_parameters {
  url: string;
}

type diggrplus_request_external_file_returns = string;

export interface diggrplus_grade_item_parameters_descriptorgradings {
  descriptorid: number; // id of descriptor
  teachervalue: number; // teachervalue of descriptorgrading
}

export interface diggrplus_grade_item_parameters {
  itemid: number;
  teachervalue?: number; // default: int(-1) // teacher grading of the item, -1 if none (leads to status "submitted" instead of "completed"
  descriptorgradings?: diggrplus_grade_item_parameters_descriptorgradings[]; // descriptors and gradingds
}

export interface diggrplus_grade_item_returns {
  success: boolean; // true if grading was successful
}

export interface diggrplus_get_competence_profile_statistic_parameters {
  userid?: number; // default: int(0)
  courseid?: number; // default: int(0)
}

export interface diggrplus_get_competence_profile_statistic_returns_competencetree_topics_descriptors_childdescriptors {
  id: number; // id of example
  title: string; // title of example
  teacherevaluation: number; // teacher evaluation of childdescriptor
  studentevaluation: number; // student evaluation of childdescriptor
}

export interface diggrplus_get_competence_profile_statistic_returns_competencetree_topics_descriptors {
  id: number; // id of example
  title: string; // title of descriptor
  teacherevaluation: number; // teacher evaluation of descriptor
  studentevaluation: number; // student evaluation of descriptor
  childdescriptors: diggrplus_get_competence_profile_statistic_returns_competencetree_topics_descriptors_childdescriptors[];
}

export interface diggrplus_get_competence_profile_statistic_returns_competencetree_topics {
  id: number; // id of example
  title: string; // title of topic
  teacherevaluation: number; // teacher evaluation of topic
  studentevaluation: number; // student evaluation of topic
  descriptors: diggrplus_get_competence_profile_statistic_returns_competencetree_topics_descriptors[];
}

export interface diggrplus_get_competence_profile_statistic_returns_competencetree {
  id: number; // id of subject
  title: string; // title of subject
  courseid: number; // id of course
  courseshortname: string; // courseshortname
  coursefullname: string; // coursefullname
  teacherevaluation: number; // teacher evaluation of subject
  studentevaluation: number; // student evaluation of subject
  topics: diggrplus_get_competence_profile_statistic_returns_competencetree_topics[];
}

export interface diggrplus_get_competence_profile_statistic_returns {
  items_and_examples_total: number; // number of free items + examples
  items_and_examples_completed: number; // number of solved items, those items can be free or related to an example
  competencies_total: number;
  competencies_gained: number;
  competencetree: diggrplus_get_competence_profile_statistic_returns_competencetree[];
}

export interface diggrplus_get_descriptors_for_example_parameters {
  exampleid: number; // id of example
  courseid: number; // id of course
  userid: number; // id of user
  forall: boolean; // for all users = true, for one user = false
  mindvisibility: boolean; // if a teacher wants to see the descriptors of an example in a hidden descriptor: set this to FALSE
}

export interface diggrplus_get_descriptors_for_example_returns_item {
  descriptorid: number; // id of descriptor
  title: string; // title of descriptor
  teacherevaluation: number; // evaluation of descriptor
  studentevaluation: number; // student evaluation of descriptor
  evalniveauid: number; // evaluation niveau id
  niveauid: number; // id of niveau
  additionalinfo: number; // additional grading for descriptor
  topicid: number; // id of topic
  numbering: string; // descriptor numbering
  child: boolean; // true: child, false: parent
  parentid: number; // parentid if child, 0 otherwise
  gradingisold: boolean; // default: NULL // true when there are newer gradings in the childcompetences
  reviewerid: number; // id of reviewer
  reviewername: string; // name of reviewer
}

export interface dakora_grade_example_parameters {
  userid: number; // userid
  courseid: number; // courseid
  exampleid: number; // exampleid
  examplevalue: number; // examplevalue
  additionalinfo: number; // additionalInfo
  exampleevalniveauid: number; // example evaluation niveau id
  itemid?: number; // default: int(-1) // itemid
  comment?: string; // default: string(0) "" // comment
  url?: string; // default: string(0) "" // url
  filename?: string; // default: string(0) "" // filename, used to look up file and create a new one in the exaport comment file area
  fileitemid?: string; // default: string(0) "" // fileitemid
}

export interface dakora_grade_example_returns {
  success: boolean; // status
  exampleid: number; // exampleid
}

export interface dakora_get_descriptors_details_parameters {
  courseid: number; // courseid
  descriptorids: string; // list of descriptors, seperated by comma
  userid: number; // userid
  forall: boolean; // forall
  crosssubjid: number; // crosssubjid
}

export interface dakora_get_descriptors_details_returns_item_examplegradings_student {
  sum: number; // number of gradings
}

export interface dakora_get_descriptors_details_returns_item_examplegradings_teacher {
  evalniveauid: number; // default: NULL // niveau id to according number
  value: number; // default: NULL // grading value
  sum: number; // number of gradings
}

export interface dakora_get_descriptors_details_returns_item_examplegradings {
  teacher: dakora_get_descriptors_details_returns_item_examplegradings_teacher[];
  student: dakora_get_descriptors_details_returns_item_examplegradings_student[];
}

export interface dakora_get_descriptors_details_returns_item_examples {
  exampleid: number; // id of example
  exampletitle: string; // title of example
  examplestate: number; // state of example, always 0 if for all students
  visible: number; // visibility of example in current context
  used: number; // used in current context
  teacherevaluation: number; // example evaluation of teacher
  studentevaluation: number; // example evaluation of student
  evalniveauid: number; // evaluation niveau id
  timestampteacher: number; // timestamp of teacher evaluation
  timestampstudent: number; // timestamp of student evaluation
  solution_visible: boolean; // visibility for example solution in current context
  exampletaxonomies?: string; // taxonomies seperated by comma
  exampletaxids?: string; // taxids seperated by comma
  examplecreatorid: number; // id of the creator of this example
  additionalinfo?: number; // additional grading
  resubmission?: boolean; // resubmission is allowed/not allowed
  is_teacherexample?: boolean; // is a teacher example?
}

export interface dakora_get_descriptors_details_returns_item_childrengradings_student {
  sum: number; // number of gradings
}

export interface dakora_get_descriptors_details_returns_item_childrengradings_teacher {
  evalniveauid: number; // default: NULL // niveau id to according number
  value: number; // default: NULL // grading value
  sum: number; // number of gradings
}

export interface dakora_get_descriptors_details_returns_item_childrengradings {
  teacher: dakora_get_descriptors_details_returns_item_childrengradings_teacher[];
  student: dakora_get_descriptors_details_returns_item_childrengradings_student[];
}

export interface dakora_get_descriptors_details_returns_item_children_examplegradings_student {
  sum: number; // number of gradings
}

export interface dakora_get_descriptors_details_returns_item_children_examplegradings_teacher {
  evalniveauid: number; // default: NULL // niveau id to according number
  value: number; // default: NULL // grading value
  sum: number; // number of gradings
}

export interface dakora_get_descriptors_details_returns_item_children_examplegradings {
  teacher: dakora_get_descriptors_details_returns_item_children_examplegradings_teacher[];
  student: dakora_get_descriptors_details_returns_item_children_examplegradings_student[];
}

export interface dakora_get_descriptors_details_returns_item_children_examples {
  exampleid: number; // id of example
  exampletitle: string; // title of example
  examplestate: number; // state of example, always 0 if for all students
  visible: number; // visibility of example in current context
  used: number; // used in current context
  teacherevaluation: number; // example evaluation of teacher
  studentevaluation: number; // example evaluation of student
  timestampteacher: number; // timestamp of teacher evaluation
  timestampstudent: number; // timestamp of student evaluation
  evalniveauid: number; // evaluation niveau id
  solution_visible: boolean; // visibility for example solution in current context
  exampletaxonomies?: string; // taxonomies seperated by comma
  exampletaxids?: string; // taxids seperated by comma
  examplecreatorid: number; // id of the creator of this example
  additionalinfo?: number; // additional grading
  resubmission?: boolean; // resubmission is allowed/not allowed
  is_teacherexample?: boolean; // is a teacher example?
}

export interface dakora_get_descriptors_details_returns_item_children {
  reviewerid: number; // id of reviewer
  reviewername: string; // name of reviewer
  descriptorid: number; // id of descriptor
  parentid: number; // id of parent of descriptor
  descriptortitle: string; // title of descriptor
  teacherevaluation: number; // teacher evaluation of descriptor
  additionalinfo: number; // additional grading for descriptor
  evalniveauid: number; // evaluation niveau id
  timestampteacher: number; // timestamp of teacher evaluation
  studentevaluation: number; // student evaluation of descriptor
  timestampstudent: number; // timestamp of student evaluation
  numbering: string; // numbering
  globalgradings?: string; // Globalgradings as text
  gradinghistory?: string; // Gradinghistory as text
  hasmaterial: boolean; // true or false if descriptor has material
  examples: dakora_get_descriptors_details_returns_item_children_examples[];
  examplestotal: number; // total number of material
  examplesvisible: number; // visible number of material
  examplesinwork: number; // number of material in work
  visible: number; // visibility of children in current context
  used: number; // used in current context
  examplesedited: number; // number of edited material
  examplegradings: dakora_get_descriptors_details_returns_item_children_examplegradings;
}

export interface dakora_get_descriptors_details_returns_item {
  reviewerid: number; // id of reviewer
  reviewername: string; // name of reviewer
  descriptorid: number; // id of descriptor
  parentid: number; // id of parent of descriptor
  descriptortitle: string; // title of descriptor
  teacherevaluation: number; // teacher evaluation of descriptor
  studentevaluation: number; // student evaluation of descriptor
  additionalinfo: number; // additional grading for descriptor
  timestampteacher: number; // timestamp for teacher evaluation
  timestampstudent: number; // timestamp for student evaluation
  evalniveauid: number; // evaluation niveau id
  numbering: string; // numbering
  categories?: string; // descriptor categories seperated by comma
  niveauid: number; // id of niveau
  niveautitle: string; // title of niveau
  gradingisold: boolean; // default: NULL // true when there are newer gradings in the childcompetences
  globalgradings?: string; // Globalgradings as text
  gradinghistory?: string; // Gradinghistory as text
  hasmaterial: boolean; // true or false if descriptor has material
  children: dakora_get_descriptors_details_returns_item_children[];
  childrengradings: dakora_get_descriptors_details_returns_item_childrengradings;
  examples: dakora_get_descriptors_details_returns_item_examples[];
  examplestotal: number; // total number of material
  examplesvisible: number; // visible number of material
  examplesinwork: number; // number of material in work
  examplesedited: number; // number of edited material
  examplegradings: dakora_get_descriptors_details_returns_item_examplegradings;
  visible: number; // visibility of example in current context
  used: number; // used in current context
}

export interface dakora_get_descriptor_details_parameters {
  courseid: number; // courseid
  descriptorid: number; // descriptorid
  userid: number; // userid
  forall: boolean; // forall
  crosssubjid: number; // crosssubjid
}

export interface dakora_get_descriptor_details_returns_examplegradings_student {
  sum: number; // number of gradings
}

export interface dakora_get_descriptor_details_returns_examplegradings_teacher {
  evalniveauid: number; // default: NULL // niveau id to according number
  value: number; // default: NULL // grading value
  sum: number; // number of gradings
}

export interface dakora_get_descriptor_details_returns_examplegradings {
  teacher: dakora_get_descriptor_details_returns_examplegradings_teacher[];
  student: dakora_get_descriptor_details_returns_examplegradings_student[];
}

export interface dakora_get_descriptor_details_returns_examples {
  exampleid: number; // id of example
  exampletitle: string; // title of example
  examplestate: number; // state of example, always 0 if for all students
  visible: number; // visibility of example in current context
  used: number; // used in current context
  teacherevaluation: number; // example evaluation of teacher
  studentevaluation: number; // example evaluation of student
  evalniveauid: number; // evaluation niveau id
  timestampteacher: number; // timestamp of teacher evaluation
  timestampstudent: number; // timestamp of student evaluation
  solution_visible: boolean; // visibility for example solution in current context
  exampletaxonomies?: string; // taxonomies seperated by comma
  exampletaxids?: string; // taxids seperated by comma
  examplecreatorid: number; // id of the creator of this example
  additionalinfo?: number; // additional grading
  resubmission?: boolean; // resubmission is allowed/not allowed
  is_teacherexample?: boolean; // is a teacher example?
}

export interface dakora_get_descriptor_details_returns_childrengradings_student {
  sum: number; // number of gradings
}

export interface dakora_get_descriptor_details_returns_childrengradings_teacher {
  evalniveauid: number; // default: NULL // niveau id to according number
  value: number; // default: NULL // grading value
  sum: number; // number of gradings
}

export interface dakora_get_descriptor_details_returns_childrengradings {
  teacher: dakora_get_descriptor_details_returns_childrengradings_teacher[];
  student: dakora_get_descriptor_details_returns_childrengradings_student[];
}

export interface dakora_get_descriptor_details_returns_children_examplegradings_student {
  sum: number; // number of gradings
}

export interface dakora_get_descriptor_details_returns_children_examplegradings_teacher {
  evalniveauid: number; // default: NULL // niveau id to according number
  value: number; // default: NULL // grading value
  sum: number; // number of gradings
}

export interface dakora_get_descriptor_details_returns_children_examplegradings {
  teacher: dakora_get_descriptor_details_returns_children_examplegradings_teacher[];
  student: dakora_get_descriptor_details_returns_children_examplegradings_student[];
}

export interface dakora_get_descriptor_details_returns_children_examples {
  exampleid: number; // id of example
  exampletitle: string; // title of example
  examplestate: number; // state of example, always 0 if for all students
  visible: number; // visibility of example in current context
  used: number; // used in current context
  teacherevaluation: number; // example evaluation of teacher
  studentevaluation: number; // example evaluation of student
  timestampteacher: number; // timestamp of teacher evaluation
  timestampstudent: number; // timestamp of student evaluation
  evalniveauid: number; // evaluation niveau id
  solution_visible: boolean; // visibility for example solution in current context
  exampletaxonomies?: string; // taxonomies seperated by comma
  exampletaxids?: string; // taxids seperated by comma
  examplecreatorid: number; // id of the creator of this example
  additionalinfo?: number; // additional grading
  resubmission?: boolean; // resubmission is allowed/not allowed
  is_teacherexample?: boolean; // is a teacher example?
}

export interface dakora_get_descriptor_details_returns_children {
  reviewerid: number; // id of reviewer
  reviewername: string; // name of reviewer
  descriptorid: number; // id of descriptor
  parentid: number; // id of parent of descriptor
  descriptortitle: string; // title of descriptor
  teacherevaluation: number; // teacher evaluation of descriptor
  additionalinfo: number; // additional grading for descriptor
  evalniveauid: number; // evaluation niveau id
  timestampteacher: number; // timestamp of teacher evaluation
  studentevaluation: number; // student evaluation of descriptor
  timestampstudent: number; // timestamp of student evaluation
  numbering: string; // numbering
  hasmaterial: boolean; // true or false if descriptor has material
  globalgradings?: string; // Globalgradings as text
  gradinghistory?: string; // Gradinghistory as text
  examples: dakora_get_descriptor_details_returns_children_examples[];
  examplestotal: number; // total number of material
  examplesvisible: number; // visible number of material
  examplesinwork: number; // number of material in work
  visible: number; // visibility of children in current context
  used: number; // used in current context
  examplesedited: number; // number of edited material
  examplegradings: dakora_get_descriptor_details_returns_children_examplegradings;
}

export interface dakora_get_descriptor_details_returns {
  reviewerid: number; // id of reviewer
  reviewername: string; // name of reviewer
  descriptorid: number; // id of descriptor
  parentid: number; // id of parent of descriptor
  descriptortitle: string; // title of descriptor
  teacherevaluation: number; // teacher evaluation of descriptor
  studentevaluation: number; // student evaluation of descriptor
  additionalinfo: number; // additional grading for descriptor
  timestampteacher: number; // timestamp for teacher evaluation
  timestampstudent: number; // timestamp for student evaluation
  evalniveauid: number; // evaluation niveau id
  numbering: string; // numbering
  categories?: string; // descriptor categories seperated by comma
  niveauid: number; // id of niveau
  niveautitle: string; // title of niveau
  gradingisold: boolean; // default: NULL // true when there are newer gradings in the childcompetences
  hasmaterial: boolean; // true or false if descriptor has material
  children: dakora_get_descriptor_details_returns_children[];
  childrengradings: dakora_get_descriptor_details_returns_childrengradings;
  examples: dakora_get_descriptor_details_returns_examples[];
  examplestotal: number; // total number of material
  examplesvisible: number; // visible number of material
  examplesinwork: number; // number of material in work
  examplesedited: number; // number of edited material
  examplegradings: dakora_get_descriptor_details_returns_examplegradings;
  visible: number; // visibility of example in current context
  used: number; // used in current context
  globalgradings?: string; // Globalgradings as text
  gradinghistory?: string; // Gradinghistory as text
}

export interface dakora_get_example_information_parameters {
  courseid: number; // id of course
  userid: number; // id of user
  exampleid: number; // id of example
}

export interface dakora_get_example_information_returns_studentfiles {
  filename: string; // title of item
  file: string; // file url
  mimetype: string; // mime type for file
  fileindex: string; // mime type for file
}

export interface dakora_get_example_information_returns_teacherfile {
  filename: string; // title of item
  file: string; // file url
  mimetype: string; // mime type for file
  fileindex: string; // mime type for file
}

export { enum_note_file_link as dakora_get_example_information_returns_type };

export interface dakora_get_example_information_returns {
  itemid: number; // id of item
  status: number; // status of the submission (-1 == no submission; 0 == not graded; 1 == graded
  name: string; // title of item
  type: enum_note_file_link; // type of item ENUM(note,file,link)
  url: string; // url
  teachervalue: number; // teacher grading
  teacherevaluation: number; // teacher grading (double of teachervalue?)
  studentvalue: number; // student grading
  evalniveauid: number; // evaluation niveau id
  timestampteacher: number; // timestamp for teacher evaluation
  timestampstudent: number; // timestamp for student evaluation
  teachercomment: string; // teacher comment
  teacherfile?: dakora_get_example_information_returns_teacherfile;
  studentcomment: string; // student comment
  teacheritemvalue: number; // item teacher grading
  resubmission: boolean; // resubmission is allowed/not allowed
  additionalinfo: number; // additional grading
  studentfiles: dakora_get_example_information_returns_studentfiles[];
  activityid: number; // activityid
  activitytitle?: string; // activity title
  activitytype?: string; // activity type - key for activity icons in Dakora
}

export interface dakora_get_user_information_parameters {}

export interface dakora_get_user_information_returns {
  id: number; // ID of the user
  username?: string; // The username
  firstname?: string; // The first name(s) of the user
  lastname?: string; // The family name of the user
  fullname: string; // The fullname of the user
  email?: string; // An email address - allow email as root@localhost
  firstaccess?: number; // first access to the site (0 if never)
  lastaccess?: number; // last access to the site (0 if never)
  auth?: string; // Auth plugins include manual, ldap, imap, etc
  confirmed?: number; // Active user: 1 if confirmed, 0 otherwise
  lang?: string; // Language code such as "en", must exist on server
  url?: string; // URL of the user
  profileimageurlsmall: string; // User image profile URL - small version
  profileimageurl: string; // User image profile URL - big version
  exarole: number; // 1=trainer, 2=student
}

export interface dakora_get_lang_information_parameters {
  lang: string; // language
}

export interface dakora_get_lang_information_returns_item {
  stringid: string; // key for the lang string
  master: string; // lang string in the chosen language
}

export interface dakora_create_blocking_event_parameters {
  courseid: number; // id of course
  title: string; // title of new blocking event
  description: string; // description of new blocking event
  timeframe: string; // timeframe
  externalurl: string; // external url
  userid: number; // id of user
  preplanningstorage: boolean; // in pre planning storage or for specific student
}

export interface dakora_create_blocking_event_returns {
  scheduleid: number; // scheduleid
}

export interface dakora_get_examples_by_descriptor_and_grading_parameters {
  courseid: number; // id of course
  userid: number; // id of user
  descriptorid: string; // id of descriptor
  grading: number; // grading value
}

export interface dakora_get_examples_by_descriptor_and_grading_returns_item {
  exampleid: number; // id of topic
  exampletitle: string; // title of topic
}

export interface dakora_allow_example_resubmission_parameters {
  courseid: number; // id of course
  userid: number; // id of user
  exampleid: number; // id of example
}

export interface dakora_allow_example_resubmission_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_get_competence_grid_for_profile_parameters {
  courseid: number; // id of course
  userid: number; // id of user
  subjectid?: number; // default: int(-1) // id of subject
  crosssubjid?: number; // default: int(-1) // id of crosssubject
}

export interface dakora_get_competence_grid_for_profile_returns_globalcompetences_rows_columns {
  text: string; // default: string(0) "" // cell text
  evaluation: number; // default: int(-1) // evaluation
  evaluation_text: string; // default: string(0) "" // evaluation text
  evaluation_mapped: number; // default: int(-1) // mapped evaluation
  evalniveauid: number; // default: int(0) // evaluation niveau id
  show: boolean; // default: bool(true) // show cell
  visible: boolean; // default: bool(true) // cell visibility
  topicid: number; // default: int(0) // topic id
  span: number; // colspan
  timestamp: number; // default: int(0) // evaluation timestamp, 0 if not set
  gradingisold: boolean; // default: NULL // true when there are childdescriptors with newer gradings than the parentdescriptor
}

export interface dakora_get_competence_grid_for_profile_returns_globalcompetences_rows {
  columns: dakora_get_competence_grid_for_profile_returns_globalcompetences_rows_columns[];
}

export interface dakora_get_competence_grid_for_profile_returns_globalcompetences {
  title: string; // default: string(0) "" // title of table
  rows: dakora_get_competence_grid_for_profile_returns_globalcompetences_rows[];
}

export interface dakora_get_competence_grid_for_profile_returns_student_gridgradings_rows_columns {
  text: string; // default: string(0) "" // cell text
  evaluation: number; // default: int(-1) // evaluation
  evaluation_text: string; // default: string(0) "" // evaluation text
  evaluation_mapped: number; // default: int(-1) // mapped evaluation
  evalniveauid: number; // default: int(0) // evaluation niveau id
  show: boolean; // default: bool(true) // show cell
  visible: boolean; // default: bool(true) // cell visibility
  topicid: number; // default: int(0) // topic id
  span: number; // colspan
  timestamp: number; // default: int(0) // evaluation timestamp, 0 if not set
  gradingisold: boolean; // default: NULL // true when there are childdescriptors with newer gradings than the parentdescriptor
}

export interface dakora_get_competence_grid_for_profile_returns_student_gridgradings_rows {
  columns: dakora_get_competence_grid_for_profile_returns_student_gridgradings_rows_columns[];
}

export interface dakora_get_competence_grid_for_profile_returns_student_gridgradings {
  title: string; // default: string(0) "" // title of table
  rows: dakora_get_competence_grid_for_profile_returns_student_gridgradings_rows[];
}

export interface dakora_get_competence_grid_for_profile_returns_student_crosssubjgrading {
  value?: number; // evaluation
  additionalinfo?: number; // evaluation
  evalniveauid?: number; // evaluation
}

export interface dakora_get_competence_grid_for_profile_returns_student {
  crosssubjgrading?: dakora_get_competence_grid_for_profile_returns_student_crosssubjgrading;
  gridgradings: dakora_get_competence_grid_for_profile_returns_student_gridgradings[];
}

export interface dakora_get_competence_grid_for_profile_returns_teacher_gridgradings_rows_columns {
  text: string; // default: string(0) "" // cell text
  evaluation: number; // default: int(-1) // evaluation
  evaluation_text: string; // default: string(0) "" // evaluation text
  evaluation_mapped: number; // default: int(-1) // mapped evaluation
  evalniveauid: number; // default: int(0) // evaluation niveau id
  show: boolean; // default: bool(true) // show cell
  visible: boolean; // default: bool(true) // cell visibility
  topicid: number; // default: int(0) // topic id
  span: number; // colspan
  timestamp: number; // default: int(0) // evaluation timestamp, 0 if not set
  gradingisold: boolean; // default: NULL // true when there are childdescriptors with newer gradings than the parentdescriptor
}

export interface dakora_get_competence_grid_for_profile_returns_teacher_gridgradings_rows {
  columns: dakora_get_competence_grid_for_profile_returns_teacher_gridgradings_rows_columns[];
}

export interface dakora_get_competence_grid_for_profile_returns_teacher_gridgradings {
  title: string; // default: string(0) "" // title of table
  rows: dakora_get_competence_grid_for_profile_returns_teacher_gridgradings_rows[];
}

export interface dakora_get_competence_grid_for_profile_returns_teacher_crosssubjgrading {
  value?: number; // evaluation
  additionalinfo?: number; // evaluation
  evalniveauid?: number; // evaluation
}

export interface dakora_get_competence_grid_for_profile_returns_teacher {
  crosssubjgrading?: dakora_get_competence_grid_for_profile_returns_teacher_crosssubjgrading;
  gridgradings: dakora_get_competence_grid_for_profile_returns_teacher_gridgradings[];
}

export interface dakora_get_competence_grid_for_profile_returns {
  teacher: dakora_get_competence_grid_for_profile_returns_teacher;
  student: dakora_get_competence_grid_for_profile_returns_student;
  globalcompetences: dakora_get_competence_grid_for_profile_returns_globalcompetences[]; // default: array(0) { }
}

export interface dakora_get_competence_profile_statistic_parameters {
  courseid: number; // id of course
  userid: number; // id of user
  subjectid: number; // id of subject
  start_timestamp: number; // start timestamp for evaluation range
  end_timestamp: number; // end timestamp for evaluation range
}

export interface dakora_get_competence_profile_statistic_returns_descriptor_evaluations_niveaus_evaluations {
  value: number; // value of evaluation
  sum: number; // sum of evaluations of current gradings
}

export interface dakora_get_competence_profile_statistic_returns_descriptor_evaluations_niveaus {
  id: number; // evalniveauid
  evaluations: dakora_get_competence_profile_statistic_returns_descriptor_evaluations_niveaus_evaluations[];
}

export interface dakora_get_competence_profile_statistic_returns_descriptor_evaluations {
  niveaus: dakora_get_competence_profile_statistic_returns_descriptor_evaluations_niveaus[];
  descriptorsToGain: number; // maximum number of descripotrs/competencies one can gain
}

export interface dakora_get_competence_profile_statistic_returns {
  descriptor_evaluations: dakora_get_competence_profile_statistic_returns_descriptor_evaluations;
}

export interface dakora_get_competence_profile_comparison_parameters {
  courseid: number; // id of course
  userid: number; // id of user
  topicid: number; // id of subject
}

export interface dakora_get_competence_profile_comparison_returns_descriptors_examples {
  example: boolean; // indicates if sub is example or grouping statement
  exampleid: number; // default: int(0) // id of example
  title: string; // title of sub
  teacherevaluation: number; // default: int(-1) // teacher evaluation
  evalniveauid: number; // default: int(-1) // teacher evaluation niveau id
  timestampteacher: number; // default: int(0) // timestamp for teacher example evaluation
  studentevaluation: number; // default: int(-1) // student evaluation
  timestampstudent: number; // default: int(0) // timestamp for student example evaluation
}

export interface dakora_get_competence_profile_comparison_returns_descriptors {
  descriptorid: number; // descriptorid
  title: string; // title of descriptor
  numbering: string; // descriptor numbering
  teacherevaluation: number; // teacher evaluation
  additionalinfo: number; // additional grading of descriptor
  evalniveauid: number; // teacher evaluation niveau id
  niveauid: number; // niveau id (ger: lfs)
  timestampteacher: number; // timestamp for teacher descriptor evaluation
  studentevaluation: number; // student evaluation
  timestampstudent: number; // timestamp for student descriptor evaluation
  examples: dakora_get_competence_profile_comparison_returns_descriptors_examples[];
}

export interface dakora_get_competence_profile_comparison_returns {
  descriptors: dakora_get_competence_profile_comparison_returns_descriptors[];
}

export interface dakora_get_competence_profile_topic_statistic_parameters {
  courseid: number; // id of course
  userid: number; // id of user
  topicid: number; // id of subject
  start_timestamp: number; // start timestamp for evaluation range
  end_timestamp: number; // end timestamp for evaluation range
}

export interface dakora_get_competence_profile_topic_statistic_returns_descriptor_evaluation_niveaus {
  title: string; // evalniveauid
  teacherevaluation: number; // evaluation value of current lfs
  evalniveauid: number; // evaluation niveau id
  studentevaluation: number; // student evaluation
}

export interface dakora_get_competence_profile_topic_statistic_returns_descriptor_evaluation {
  niveaus: dakora_get_competence_profile_topic_statistic_returns_descriptor_evaluation_niveaus[];
}

export interface dakora_get_competence_profile_topic_statistic_returns {
  descriptor_evaluation: dakora_get_competence_profile_topic_statistic_returns_descriptor_evaluation;
}

export interface block_exacomp_is_elove_student_self_assessment_enabled_parameters {}

export interface block_exacomp_is_elove_student_self_assessment_enabled_returns {
  enabled: boolean;
}

export interface dakora_get_site_policies_parameters {}

export interface dakora_get_site_policies_returns_item {
  name: string; // name
  summary: string; // summary
  content: string; // content
}

export interface dakora_get_example_h5p_activity_results_parameters {
  exampleid: number; // id of the example that is connected to an activity
}

export interface dakora_get_example_h5p_activity_results_returns_current_result {
  raw_score: number; // current score of the student on this hvp
  max_score: number; // maximum score you can get on this hvp
}

export interface dakora_get_example_h5p_activity_results_returns {
  current_result: dakora_get_example_h5p_activity_results_returns_current_result; // current result. The interactive content hvp module does not store a history of results
  resultpage_url: string; // content
}

export interface block_exacomp_diggr_create_cohort_parameters {
  name: string; // cohort name
  skz: string; // school number
}

export interface block_exacomp_diggr_create_cohort_returns {
  cohortcode: string; // cohortcode
}

export interface block_exacomp_diggr_get_students_of_cohort_parameters {
  cohortid: string; // cohort id
}

export interface block_exacomp_diggr_get_students_of_cohort_returns_students {
  userid: number; // id of student
  name: string; // name of student
}

export interface block_exacomp_diggr_get_students_of_cohort_returns {
  cohortid: number; // id of cohort
  cohortcode: string; // code of cohort
  students: block_exacomp_diggr_get_students_of_cohort_returns_students[];
}

export interface block_exacomp_diggr_get_cohorts_of_trainer_parameters {}

export interface block_exacomp_diggr_get_cohorts_of_trainer_returns_cohorts {
  cohortid: number; // id of cohort
  name: string; // name of user
  cohortcode: string; // code of cohort
}

export interface block_exacomp_diggr_get_cohorts_of_trainer_returns {
  cohorts: block_exacomp_diggr_get_cohorts_of_trainer_returns_cohorts[];
}

export interface dakora_get_evaluation_config_parameters {}

export interface dakora_get_evaluation_config_returns_values {
  0: string; // default: string(1) "0" // value title for id = 0
  1: string; // default: string(1) "1" // value title for id = 1
  2: string; // default: string(1) "2" // value title for id = 2
  3: string; // default: string(1) "3" // value title for id = 3
}

export interface dakora_get_evaluation_config_returns_evalniveaus {
  1?: string; // evaluation title for id = 1
  2?: string; // evaluation title for id = 2
  3?: string; // evaluation title for id = 3
}

export interface dakora_get_evaluation_config_returns {
  use_evalniveau: boolean; // use evaluation niveaus
  evalniveaus: dakora_get_evaluation_config_returns_evalniveaus;
  values: dakora_get_evaluation_config_returns_values;
}

export interface dakora_get_config_parameters {}

export interface dakora_get_config_returns_categories {
  id: number; // id
  title: string; // name
  source: string; // source
}

export interface dakora_get_config_returns_taxonomies {
  id: number; // id
  title: string; // name
  source: string; // source
}

export interface dakora_get_config_returns_gradingperiods {
  id: number; // id
  description: string; // name
  starttime: number; // active from
  endtime: number; // active to
}

export interface dakora_get_config_returns_studentevalitems_examples_short {
  id: number; // id
  name: string; // name
}

export interface dakora_get_config_returns_studentevalitems_examples {
  id: number; // id
  name: string; // name
}

export interface dakora_get_config_returns_studentevalitems_short {
  id: number; // id
  name: string; // name
}

export interface dakora_get_config_returns_studentevalitems {
  id: number; // id
  name: string; // name
}

export interface dakora_get_config_returns_teacherevalitems_short {
  id: number; // id
  name: string; // name
}

export interface dakora_get_config_returns_teacherevalitems {
  id: number; // id
  name: string; // name
}

export interface dakora_get_config_returns_evalniveaus {
  id: number; // id
  name: string; // name
}

export interface dakora_get_config_returns {
  points_limit: number; // points_limit
  grade_limit: number; // grade_limit
  points_negative_threshold: number; // points_negative_threshold. Values below this value are negative
  grade_negative_threshold: number; // grade_negative_threshold. Values below this value are negative
  verbal_negative_threshold: number; // grade_negative_threshold. Values below this value are negative
  example_scheme: number; // example_scheme
  example_diffLevel: boolean; // example_diffLevel
  example_SelfEval: boolean; // example_SelfEval
  childcomp_scheme: number; // childcomp_scheme
  childcomp_diffLevel: boolean; // childcomp_diffLevel
  childcomp_SelfEval: boolean; // childcomp_SelfEval
  comp_scheme: number; // comp_scheme
  comp_diffLevel: boolean; // comp_diffLevel
  comp_SelfEval: boolean; // comp_SelfEval
  topic_scheme: number; // topic_scheme
  topic_diffLevel: boolean; // topic_diffLevel
  topic_SelfEval: boolean; // topic_SelfEval
  subject_scheme: number; // subject_scheme
  subject_diffLevel: boolean; // subject_diffLevel
  subject_SelfEval: boolean; // subject_SelfEval
  theme_scheme: number; // theme_scheme
  theme_diffLevel: boolean; // theme_diffLevel
  theme_SelfEval: boolean; // theme_SelfEval
  use_evalniveau: boolean; // use evaluation niveaus
  evalniveaus: dakora_get_config_returns_evalniveaus[];
  teacherevalitems: dakora_get_config_returns_teacherevalitems[];
  teacherevalitems_short: dakora_get_config_returns_teacherevalitems_short[];
  studentevalitems: dakora_get_config_returns_studentevalitems[];
  studentevalitems_short: dakora_get_config_returns_studentevalitems_short[];
  studentevalitems_examples: dakora_get_config_returns_studentevalitems_examples[];
  studentevalitems_examples_short: dakora_get_config_returns_studentevalitems_examples_short[];
  gradingperiods: dakora_get_config_returns_gradingperiods[]; // grading periods from exastud
  taxonomies: dakora_get_config_returns_taxonomies[]; // values
  version: number; // exacomp version number in YYYYMMDDXX format
  moodleversion: number; // moodle version number in YYYYMMDDXX format
  release: string; // plugin release x.x.x format
  exaportactive: boolean; // flag if exaportfolio should be active
  customlanguagefile: string; // customlanguagefiel
  timeout: number; // a timeout timer
  show_overview: boolean; // flag if "show overview" is active
  show_eportfolio: boolean; // flag if "show ePortfolio" is active
  categories: dakora_get_config_returns_categories[]; // values
  assessment_verbose_lowerisbetter: boolean; // flag if "The lower the Assessment, the better" is active
}

export interface dakora_get_courseconfigs_parameters {}

export interface dakora_get_courseconfigs_returns_configs_categories {
  id: number; // id
  title: string; // name
  source: string; // source
}

export interface dakora_get_courseconfigs_returns_configs_taxonomies {
  id: number; // id
  title: string; // name
  source: string; // source
}

export interface dakora_get_courseconfigs_returns_configs_gradingperiods {
  id: number; // id
  description: string; // name
  starttime: number; // active from
  endtime: number; // active to
}

export interface dakora_get_courseconfigs_returns_configs_studentevalitems_examples_short {
  id: number; // id
  name: string; // name
}

export interface dakora_get_courseconfigs_returns_configs_studentevalitems_examples {
  id: number; // id
  name: string; // name
}

export interface dakora_get_courseconfigs_returns_configs_studentevalitems_short {
  id: number; // id
  name: string; // name
}

export interface dakora_get_courseconfigs_returns_configs_studentevalitems {
  id: number; // id
  name: string; // name
}

export interface dakora_get_courseconfigs_returns_configs_teacherevalitems_short {
  id: number; // id
  name: string; // name
}

export interface dakora_get_courseconfigs_returns_configs_teacherevalitems {
  id: number; // id
  name: string; // name
}

export interface dakora_get_courseconfigs_returns_configs_evalniveaus {
  id: number; // id
  name: string; // name
}

export interface dakora_get_courseconfigs_returns_configs {
  id: number;
  name: string;
  points_limit: number; // points_limit
  grade_limit: number; // grade_limit
  points_negative_threshold: number; // points_negative_threshold. Values below this value are negative
  grade_negative_threshold: number; // grade_negative_threshold. Values below this value are negative
  verbal_negative_threshold: number; // grade_negative_threshold. Values below this value are negative
  example_scheme: number; // example_scheme
  example_diffLevel: boolean; // example_diffLevel
  example_SelfEval: boolean; // example_SelfEval
  childcomp_scheme: number; // childcomp_scheme
  childcomp_diffLevel: boolean; // childcomp_diffLevel
  childcomp_SelfEval: boolean; // childcomp_SelfEval
  comp_scheme: number; // comp_scheme
  comp_diffLevel: boolean; // comp_diffLevel
  comp_SelfEval: boolean; // comp_SelfEval
  topic_scheme: number; // topic_scheme
  topic_diffLevel: boolean; // topic_diffLevel
  topic_SelfEval: boolean; // topic_SelfEval
  subject_scheme: number; // subject_scheme
  subject_diffLevel: boolean; // subject_diffLevel
  subject_SelfEval: boolean; // subject_SelfEval
  theme_scheme: number; // theme_scheme
  theme_diffLevel: boolean; // theme_diffLevel
  theme_SelfEval: boolean; // theme_SelfEval
  use_evalniveau: boolean; // use evaluation niveaus
  evalniveaus: dakora_get_courseconfigs_returns_configs_evalniveaus[];
  teacherevalitems: dakora_get_courseconfigs_returns_configs_teacherevalitems[];
  teacherevalitems_short: dakora_get_courseconfigs_returns_configs_teacherevalitems_short[];
  studentevalitems: dakora_get_courseconfigs_returns_configs_studentevalitems[];
  studentevalitems_short: dakora_get_courseconfigs_returns_configs_studentevalitems_short[];
  studentevalitems_examples: dakora_get_courseconfigs_returns_configs_studentevalitems_examples[];
  studentevalitems_examples_short: dakora_get_courseconfigs_returns_configs_studentevalitems_examples_short[];
  gradingperiods: dakora_get_courseconfigs_returns_configs_gradingperiods[]; // grading periods from exastud
  taxonomies: dakora_get_courseconfigs_returns_configs_taxonomies[]; // values
  version: number; // exacomp version number in YYYYMMDDXX format
  moodleversion: number; // moodle version number in YYYYMMDDXX format
  release: string; // plugin release x.x.x format
  exaportactive: boolean; // flag if exaportfolio should be active
  customlanguagefile: string; // customlanguagefiel
  timeout: number; // a timeout timer
  show_overview: boolean; // flag if "show overview" is active
  show_eportfolio: boolean; // flag if "show ePortfolio" is active
  categories: dakora_get_courseconfigs_returns_configs_categories[]; // values
  assessment_verbose_lowerisbetter: boolean; // flag if "The lower the Assessment, the better" is active
}

export { enum_basic_advanced as dakora_get_courseconfigs_returns_courses_experience_level };

export interface dakora_get_courseconfigs_returns_courses {
  courseid: number; // id of course
  fullname: string; // fullname of course
  shortname: string; // shortname of course
  exarole: number; // 1=trainer, 2=student
  assessment_config: number; // which course specific assessment_config is used
  experience_level?: enum_basic_advanced; // ENUM(basic, advanced)
}

export interface dakora_get_courseconfigs_returns {
  courses: dakora_get_courseconfigs_returns_courses[];
  configs: dakora_get_courseconfigs_returns_configs[];
}

export interface block_exacomp_login_parameters {
  app: number; // app accessing this service (eg. dakora)
  app_version: number; // version of the app (eg. 4.6.0)
  services?: number; // default: string(33) "moodle_mobile_app,exacompservices" // wanted webservice tokens (eg. exacomp,exaport)
}

export interface block_exacomp_login_returns_tokens {
  service: string; // name of service
  token: string; // token of the service
}

export interface block_exacomp_login_returns_courseconfigs_configs_categories {
  id: number; // id
  title: string; // name
  source: string; // source
}

export interface block_exacomp_login_returns_courseconfigs_configs_taxonomies {
  id: number; // id
  title: string; // name
  source: string; // source
}

export interface block_exacomp_login_returns_courseconfigs_configs_gradingperiods {
  id: number; // id
  description: string; // name
  starttime: number; // active from
  endtime: number; // active to
}

export interface block_exacomp_login_returns_courseconfigs_configs_studentevalitems_examples_short {
  id: number; // id
  name: string; // name
}

export interface block_exacomp_login_returns_courseconfigs_configs_studentevalitems_examples {
  id: number; // id
  name: string; // name
}

export interface block_exacomp_login_returns_courseconfigs_configs_studentevalitems_short {
  id: number; // id
  name: string; // name
}

export interface block_exacomp_login_returns_courseconfigs_configs_studentevalitems {
  id: number; // id
  name: string; // name
}

export interface block_exacomp_login_returns_courseconfigs_configs_teacherevalitems_short {
  id: number; // id
  name: string; // name
}

export interface block_exacomp_login_returns_courseconfigs_configs_teacherevalitems {
  id: number; // id
  name: string; // name
}

export interface block_exacomp_login_returns_courseconfigs_configs_evalniveaus {
  id: number; // id
  name: string; // name
}

export interface block_exacomp_login_returns_courseconfigs_configs {
  id: number;
  name: string;
  points_limit: number; // points_limit
  grade_limit: number; // grade_limit
  points_negative_threshold: number; // points_negative_threshold. Values below this value are negative
  grade_negative_threshold: number; // grade_negative_threshold. Values below this value are negative
  verbal_negative_threshold: number; // grade_negative_threshold. Values below this value are negative
  example_scheme: number; // example_scheme
  example_diffLevel: boolean; // example_diffLevel
  example_SelfEval: boolean; // example_SelfEval
  childcomp_scheme: number; // childcomp_scheme
  childcomp_diffLevel: boolean; // childcomp_diffLevel
  childcomp_SelfEval: boolean; // childcomp_SelfEval
  comp_scheme: number; // comp_scheme
  comp_diffLevel: boolean; // comp_diffLevel
  comp_SelfEval: boolean; // comp_SelfEval
  topic_scheme: number; // topic_scheme
  topic_diffLevel: boolean; // topic_diffLevel
  topic_SelfEval: boolean; // topic_SelfEval
  subject_scheme: number; // subject_scheme
  subject_diffLevel: boolean; // subject_diffLevel
  subject_SelfEval: boolean; // subject_SelfEval
  theme_scheme: number; // theme_scheme
  theme_diffLevel: boolean; // theme_diffLevel
  theme_SelfEval: boolean; // theme_SelfEval
  use_evalniveau: boolean; // use evaluation niveaus
  evalniveaus: block_exacomp_login_returns_courseconfigs_configs_evalniveaus[];
  teacherevalitems: block_exacomp_login_returns_courseconfigs_configs_teacherevalitems[];
  teacherevalitems_short: block_exacomp_login_returns_courseconfigs_configs_teacherevalitems_short[];
  studentevalitems: block_exacomp_login_returns_courseconfigs_configs_studentevalitems[];
  studentevalitems_short: block_exacomp_login_returns_courseconfigs_configs_studentevalitems_short[];
  studentevalitems_examples: block_exacomp_login_returns_courseconfigs_configs_studentevalitems_examples[];
  studentevalitems_examples_short: block_exacomp_login_returns_courseconfigs_configs_studentevalitems_examples_short[];
  gradingperiods: block_exacomp_login_returns_courseconfigs_configs_gradingperiods[]; // grading periods from exastud
  taxonomies: block_exacomp_login_returns_courseconfigs_configs_taxonomies[]; // values
  version: number; // exacomp version number in YYYYMMDDXX format
  moodleversion: number; // moodle version number in YYYYMMDDXX format
  release: string; // plugin release x.x.x format
  exaportactive: boolean; // flag if exaportfolio should be active
  customlanguagefile: string; // customlanguagefiel
  timeout: number; // a timeout timer
  show_overview: boolean; // flag if "show overview" is active
  show_eportfolio: boolean; // flag if "show ePortfolio" is active
  categories: block_exacomp_login_returns_courseconfigs_configs_categories[]; // values
  assessment_verbose_lowerisbetter: boolean; // flag if "The lower the Assessment, the better" is active
}

export { enum_basic_advanced as block_exacomp_login_returns_courseconfigs_courses_experience_level };

export interface block_exacomp_login_returns_courseconfigs_courses {
  courseid: number; // id of course
  fullname: string; // fullname of course
  shortname: string; // shortname of course
  exarole: number; // 1=trainer, 2=student
  assessment_config: number; // which course specific assessment_config is used
  experience_level?: enum_basic_advanced; // ENUM(basic, advanced)
}

export interface block_exacomp_login_returns_courseconfigs {
  courses: block_exacomp_login_returns_courseconfigs_courses[];
  configs: block_exacomp_login_returns_courseconfigs_configs[];
}

export { enum_basic_advanced as block_exacomp_login_returns_exacompcourses_experience_level };

export interface block_exacomp_login_returns_exacompcourses {
  courseid: number; // id of course
  fullname: string; // fullname of course
  shortname: string; // shortname of course
  exarole: number; // 1=trainer, 2=student
  teachercanedit: boolean;
  course_topics_configured?: boolean; // only available for teachers (used in diggr+)
  assessment_config: number; // which course specific assessment_config is used
  experience_level?: enum_basic_advanced; // ENUM(basic, advanced)
}

export interface block_exacomp_login_returns_user {
  id: number; // ID of the user
  username?: string; // The username
  firstname?: string; // The first name(s) of the user
  lastname?: string; // The family name of the user
  fullname: string; // The fullname of the user
  email?: string; // An email address - allow email as root@localhost
  firstaccess?: number; // first access to the site (0 if never)
  lastaccess?: number; // last access to the site (0 if never)
  auth?: string; // Auth plugins include manual, ldap, imap, etc
  confirmed?: number; // Active user: 1 if confirmed, 0 otherwise
  lang?: string; // Language code such as "en", must exist on server
  url?: string; // URL of the user
  profileimageurlsmall: string; // User image profile URL - small version
  profileimageurl: string; // User image profile URL - big version
  exarole: number; // 1=trainer, 2=student
}

export interface block_exacomp_login_returns {
  user: block_exacomp_login_returns_user;
  exacompcourses: block_exacomp_login_returns_exacompcourses[];
  courseconfigs: block_exacomp_login_returns_courseconfigs;
  tokens: block_exacomp_login_returns_tokens[]; // requested tokens
}

export interface dakora_set_descriptor_visibility_parameters {
  courseid: number; // id of course
  descriptorid: number; // id of descriptor
  userid: number; // id of user, 0 for current user
  forall: boolean; // for all users = true, for one user = false
  visible: boolean; // visibility for descriptor in current context
}

export interface dakora_set_descriptor_visibility_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_set_example_visibility_parameters {
  courseid: number; // id of course
  exampleid: number; // id of example
  userid: number; // id of user, 0 for current user
  forall: boolean; // for all users = true, for one user = false
  visible: boolean; // visibility for example in current context
}

export interface dakora_set_example_visibility_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_set_topic_visibility_parameters {
  courseid: number; // id of course
  topicid: number; // id of topic
  userid: number; // id of user, 0 for current user
  forall: boolean; // for all users = true, for one user = false
  visible: boolean; // visibility for topic in current context
  groupid?: number; // default: int(-1) // id of group
}

export interface dakora_set_topic_visibility_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_set_niveau_visibility_parameters {
  courseid: number; // id of course
  topicid: number; // id of topic
  userid: number; // id of user, 0 for current user
  forall: boolean; // for all users = true, for one user = false
  visible: boolean; // visibility for topic in current context
  groupid?: number; // default: int(-1) // id of group
  niveauid: number; // id of the descriptorniveau
}

export interface dakora_set_niveau_visibility_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_set_example_solution_visibility_parameters {
  courseid: number; // id of course
  exampleid: number; // id of example
  userid: number; // id of user, 0 for current user
  forall: boolean; // for all users = true, for one user = false
  visible: boolean; // visibility for example in current context
}

export interface dakora_set_example_solution_visibility_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface diggrplus_set_descriptor_visibility_parameters {
  courseid: number; // id of course
  ids: string; // list of descriptorids, seperated by comma
  userid: number; // id of user, 0 for current user
  forall: boolean; // for all users = true, for one user = false
  visible: boolean; // visibility for descriptor in current context
}

export interface diggrplus_set_descriptor_visibility_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface diggrplus_set_example_visibility_parameters {
  courseid: number; // id of course
  ids: string; // list of exampleids, seperated by comma
  userid: number; // id of user, 0 for current user
  forall: boolean; // for all users = true, for one user = false
  visible: boolean; // visibility for example in current context
}

export interface diggrplus_set_example_visibility_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface diggrplus_set_topic_visibility_parameters {
  courseid: number; // id of course
  ids: string; // list of topicids, seperated by comma
  userid: number; // id of user, 0 for current user
  forall: boolean; // for all users = true, for one user = false
  visible: boolean; // visibility for topic in current context
  groupid?: number; // default: int(-1) // id of group
}

export interface diggrplus_set_topic_visibility_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_create_cross_subject_parameters {
  courseid: number; // id of course
  title: string; // title of crosssubject
  description: string; // description of crosssubject
  subjectid: number; // id of subject crosssubject is assigned to
  draftid?: number; // default: int(0) // id of draft
  groupcategory?: string; // default: string(0) "" // name of groupcategory
}

export interface dakora_create_cross_subject_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_delete_cross_subject_parameters {
  courseid: number; // id of course
  crosssubjid: number; // id of crosssubject
}

export interface dakora_delete_cross_subject_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_edit_cross_subject_parameters {
  courseid: number; // id of course
  crosssubjid: number; // id of crosssubject
  title: string; // title of crosssubject
  description: string; // description of crosssubject
  subjectid: number; // id of subject crosssubject is assigned to
  groupcategory?: string; // default: string(0) "" // name of groupcategory
}

export interface dakora_edit_cross_subject_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_get_cross_subject_drafts_parameters {
  courseid: number; // id of course
}

export interface dakora_get_cross_subject_drafts_returns_item {
  id: number; // id of crosssubjet draft
  title: string; // title of draft
  description: string; // description of draft
}

export interface dakora_get_subjects_parameters {
  courseid: number; // id of course
}

export interface dakora_get_subjects_returns_item {
  id: number; // id of subject
  title: string; // title of subject
}

export interface dakora_get_students_for_cross_subject_parameters {
  courseid: number; // id of course
  crosssubjid: number; // id of crossssubj
}

export interface dakora_get_students_for_cross_subject_returns_students {
  id: number; // id of student
  firstname: string; // firstname of student
  lastname: string; // lastname of student
  visible: number; // visibility of crosssubject to student
}

export interface dakora_get_students_for_cross_subject_returns {
  students: dakora_get_students_for_cross_subject_returns_students[];
  visible_forall: number; // visibility of crosssubject to all students
}

export interface dakora_set_cross_subject_student_parameters {
  courseid: number; // id of course
  crosssubjid: number; // id of crosssubject
  userid: string; // title of crosssubject
  forall: number; // 0 or 1
  value: number; // value 0 or 1
}

export interface dakora_set_cross_subject_student_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_set_cross_subject_descriptor_parameters {
  courseid: number; // id of course
  crosssubjid: number; // id of crosssubject
  descriptorid: string; // title of crosssubject
  value: number; // value 0 or 1
}

export interface dakora_set_cross_subject_descriptor_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_dismiss_oldgrading_warning_parameters {
  courseid: number; // id of course
  descriptorid: number; // id of descriptor
  studentid: number; // id of student
}

export interface dakora_dismiss_oldgrading_warning_returns {
  success: boolean; // status of success, either true (1) or false (0)
}

export interface dakora_send_message_parameters {
  messagetext: string; // text of message
  userfrom: number; // id of user that sends the message
  userto: number; // id of user message is sent to
}

export interface dakora_send_message_returns {
  success: boolean; // status
}

export interface block_exacomp_update_descriptor_category_parameters {
  descriptorid: number; // id of descriptor
  categories?: string; // default: string(0) "" // list of categories
  newcategory?: string; // default: string(0) "" // new category title
  courseid?: number; // default: int(0) // courseid
}

export interface block_exacomp_update_descriptor_category_returns_newCategory {
  id: number; // id of new category
  title: string; // title of new category
  source: number; // cource of new category
}

export interface block_exacomp_update_descriptor_category_returns {
  success: boolean; // true if successful
  newCategory?: block_exacomp_update_descriptor_category_returns_newCategory; // data of new category
}

export interface block_exacomp_get_url_preview_parameters {
  url: string; // url to fetch preview
}

export interface block_exacomp_get_url_preview_returns {
  title: string; // true if successful
  description: string; // true if successful
  imageurl: string; // true if successful
}

export interface dakora_competencegrid_overview_parameters {
  courseid: number; // id of course
  userid: number; // id of user, if 0 current user
  subjectid: number; // subject id
  forall?: number; // default: int(0) // for all?
}

export interface dakora_competencegrid_overview_returns {
  overview: string; // result html
}

export interface dakora_delete_custom_example_parameters {
  exampleid: number; // id of example
}

export interface dakora_delete_custom_example_returns {
  success: boolean; // true if successful
}

export interface diggrplus_delete_custom_example_parameters {
  exampleid: number; // id of example
}

export interface diggrplus_delete_custom_example_returns {
  success: boolean; // true if successful
}

export interface diggrplus_get_course_schooltype_tree_parameters {
  courseid: number;
}

export interface diggrplus_get_course_schooltype_tree_returns_schooltypes_subjects_topics {
  id: number;
  title: string; // topic title
  active: boolean;
}

export interface diggrplus_get_course_schooltype_tree_returns_schooltypes_subjects {
  id: number;
  title: string; // subject title
  topics: diggrplus_get_course_schooltype_tree_returns_schooltypes_subjects_topics[];
}

export interface diggrplus_get_course_schooltype_tree_returns_schooltypes {
  id: number;
  title: string; // schooltype title
  subjects: diggrplus_get_course_schooltype_tree_returns_schooltypes_subjects[];
}

export interface diggrplus_get_course_schooltype_tree_returns {
  schooltypes: diggrplus_get_course_schooltype_tree_returns_schooltypes[];
}

type diggrplus_set_active_course_topics_parameters_topicids = number;

export interface diggrplus_set_active_course_topics_parameters {
  courseid: number;
  topicids?: diggrplus_set_active_course_topics_parameters_topicids[]; // default: array(0) { } // topicid optional so it can be empty
  hide_new_examples: boolean;
}

export interface diggrplus_set_active_course_topics_returns {
  success: boolean; // status
}

export interface diggrplus_get_config_parameters {}

export interface diggrplus_get_config_returns_plugins {
  name: string;
  versiondb?: number;
}

export interface diggrplus_get_config_returns {
  exacompversion: number; // exacomp version number in YYYYMMDDXX format
  moodleversion: number; // moodle version number in YYYYMMDDXX format
  msteams_import_enabled: boolean;
  msteams_azure_app_client_id: string;
  enrolcode_enabled: boolean;
  example_upload_global: boolean;
  plugins: diggrplus_get_config_returns_plugins[];
}

export interface diggrplus_annotate_example_parameters {
  exampleid?: number; // default: int(-1) // id of the example that is to be updated
  courseid?: number; // default: int(0) // courseid
  annotationtext?: string; // default: string(0) "" // title of example
}

export interface diggrplus_annotate_example_returns {
  success: boolean; // status
}

export interface diggrplus_get_student_enrolcode_parameters {
  courseid: number;
}

export interface diggrplus_get_student_enrolcode_returns {
  code: string;
  valid_until: number;
}

export interface diggrplus_create_student_enrolcode_parameters {
  courseid: number;
}

export interface diggrplus_create_student_enrolcode_returns {
  code: string;
  valid_until: number;
}

export interface diggrplus_enrol_by_enrolcode_parameters {
  code: string;
}

export interface diggrplus_enrol_by_enrolcode_returns {
  success: boolean; // status
}

export interface block_exacomp_diwipass_get_sections_with_materials_parameters {}

export interface block_exacomp_diwipass_get_sections_with_materials_returns_courses_sections_urls_introfiles {
  filename?: string; // File name.
  filepath?: string; // File path.
  filesize?: number; // File size.
  fileurl?: string; // Downloadable file url.
  timemodified?: number; // Time modified.
  mimetype?: string; // File mime type.
  isexternalfile?: boolean; // Whether is an external file.
  repositorytype?: string; // The repository type for external files.
}

export interface block_exacomp_diwipass_get_sections_with_materials_returns_courses_sections_urls {
  id: number; // Module id
  coursemodule: number; // Course module id
  course: number; // Course id
  name: string; // URL name
  intro: string; // Summary
  introformat: number; // intro format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  introfiles: block_exacomp_diwipass_get_sections_with_materials_returns_courses_sections_urls_introfiles[]; // Files in the introduction text
  externalurl: string; // External URL
  display: number; // How to display the url
  displayoptions: string; // Display options (width, height)
  parameters: string; // Parameters to append to the URL
  timemodified: number; // Last time the url was modified
  section: number; // Course section id
  visible: number; // Module visibility
  groupmode: number; // Group mode
  groupingid: number; // Grouping id
}

export interface block_exacomp_diwipass_get_sections_with_materials_returns_courses_sections_resources_contentfiles {
  filename?: string; // File name.
  filepath?: string; // File path.
  filesize?: number; // File size.
  fileurl?: string; // Downloadable file url.
  timemodified?: number; // Time modified.
  mimetype?: string; // File mime type.
  isexternalfile?: boolean; // Whether is an external file.
  repositorytype?: string; // The repository type for external files.
}

export interface block_exacomp_diwipass_get_sections_with_materials_returns_courses_sections_resources_introfiles {
  filename?: string; // File name.
  filepath?: string; // File path.
  filesize?: number; // File size.
  fileurl?: string; // Downloadable file url.
  timemodified?: number; // Time modified.
  mimetype?: string; // File mime type.
  isexternalfile?: boolean; // Whether is an external file.
  repositorytype?: string; // The repository type for external files.
}

export interface block_exacomp_diwipass_get_sections_with_materials_returns_courses_sections_resources {
  id: number; // Module id
  coursemodule: number; // Course module id
  course: number; // Course id
  name: string; // Page name
  intro: string; // Summary
  introformat: number; // intro format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  introfiles: block_exacomp_diwipass_get_sections_with_materials_returns_courses_sections_resources_introfiles[]; // Files in the introduction text
  contentfiles: block_exacomp_diwipass_get_sections_with_materials_returns_courses_sections_resources_contentfiles[]; // Files in the content
}

export interface block_exacomp_diwipass_get_sections_with_materials_returns_courses_sections {
  name: string; // default: string(19) "sectionname missing" // section name
  resources: block_exacomp_diwipass_get_sections_with_materials_returns_courses_sections_resources[];
  urls: block_exacomp_diwipass_get_sections_with_materials_returns_courses_sections_urls[];
}

export interface block_exacomp_diwipass_get_sections_with_materials_returns_courses {
  name: string; // course name
  sections: block_exacomp_diwipass_get_sections_with_materials_returns_courses_sections[];
}

export interface block_exacomp_diwipass_get_sections_with_materials_returns {
  courses: block_exacomp_diwipass_get_sections_with_materials_returns_courses[];
}

export interface dakoraplus_get_example_and_item_parameters {
  exampleid: number;
  courseid: number;
}

export interface dakoraplus_get_example_and_item_returns_item_collaborators {
  userid: number; // userid of collaborator
  fullname: string;
  profileimageurl: string;
}

export interface dakoraplus_get_example_and_item_returns_item_studentfiles {
  id: number; // id
  filename: string; // filename
  file: string; // file url
  mimetype: string; // mime type for file
  fileindex: string; // fileindex, used for deleting this file
}

export { enum_note_file_link as dakoraplus_get_example_and_item_returns_item_type };

export interface dakoraplus_get_example_and_item_returns_item {
  id: number; // id of item
  name: string; // title of item
  solutiondescription?: string; // description of item
  type?: enum_note_file_link; // type of item ENUM(note,file,link)
  url?: string; // url
  effort?: string; // description of the effort
  teachervalue?: number; // teacher grading
  studentvalue?: number; // student grading
  teachercomment?: string; // teacher comment
  studentcomment?: string; // student comment
  studentfiles?: dakoraplus_get_example_and_item_returns_item_studentfiles[]; // files of the student's submission
  collaborators?: dakoraplus_get_example_and_item_returns_item_collaborators[]; // collaborators
}

export interface dakoraplus_get_example_and_item_returns_example_taskfiles {
  name: string; // title of taskfile
  url: string; // file url
  type: string; // mime type for file
}

export interface dakoraplus_get_example_and_item_returns_example {
  id: number; // id of example
  title: string; // title of example
  description: string; // description of example
  annotation: string; // annotation by the teacher for this example in this course
  solutionfilename: string; // task filename
  externalurl: string; // externalurl of example
  externaltask: string; // url of associated module
  solution: string; // solution(url/description) of example
  timeframe: string; // timeframe as string
  hassubmissions: boolean; // true if example has already submissions
  solution_visible: boolean; // visibility for example solution in current context
  taskfiles?: dakoraplus_get_example_and_item_returns_example_taskfiles[]; // taskfiles of the example
  teacher_evaluation?: number; // teacher_evaluation
  student_evaluation?: number; // student_evaluation
}

export { enum_new_inprogress_submitted_completed as dakoraplus_get_example_and_item_returns_status };

export interface dakoraplus_get_example_and_item_returns {
  courseid: number;
  status: enum_new_inprogress_submitted_completed; // ENUM(new, inprogress, submitted, completed)
  subjectid: number; // id of subject
  subjecttitle: string; // title of subject
  topicid: number; // id of topic
  topictitle: string; // title of topic
  niveautitle: string; // title of niveau
  niveauid: number; // id of niveau
  timemodified: number; // time the item was last modified --> not gradings, but only changes to the item (files, comments, name, collaborators)
  example?: dakoraplus_get_example_and_item_returns_example; // example information
  item?: dakoraplus_get_example_and_item_returns_item; // item information
}

export interface dakoraplus_get_teacher_example_and_item_parameters {
  exampleid: number;
  courseid: number;
  studentid: number;
}

export interface dakoraplus_get_teacher_example_and_item_returns_item_collaborators {
  userid: number; // userid of collaborator
  fullname: string;
  profileimageurl: string;
}

export interface dakoraplus_get_teacher_example_and_item_returns_item_studentfiles {
  id: number; // id
  filename: string; // filename
  file: string; // file url
  mimetype: string; // mime type for file
  fileindex: string; // fileindex, used for deleting this file
}

export interface dakoraplus_get_teacher_example_and_item_returns_item_owner {
  userid: number;
  fullname: string;
  profileimageurl: string;
}

export { enum_note_file_link as dakoraplus_get_teacher_example_and_item_returns_item_type };

export interface dakoraplus_get_teacher_example_and_item_returns_item {
  id: number; // id of item
  name: string; // title of item
  solutiondescription?: string; // description of item
  type?: enum_note_file_link; // type of item ENUM(note,file,link)
  url?: string; // url
  effort?: string; // description of the effort
  teachervalue?: number; // teacher grading
  studentvalue?: number; // student grading
  teachercomment?: string; // teacher comment
  studentcomment?: string; // student comment
  owner?: dakoraplus_get_teacher_example_and_item_returns_item_owner;
  studentfiles?: dakoraplus_get_teacher_example_and_item_returns_item_studentfiles[]; // files of the student's submission
  collaborators?: dakoraplus_get_teacher_example_and_item_returns_item_collaborators[]; // collaborators
}

export interface dakoraplus_get_teacher_example_and_item_returns_example_taskfiles {
  name: string; // title of taskfile
  url: string; // file url
  type: string; // mime type for file
}

export interface dakoraplus_get_teacher_example_and_item_returns_example {
  id: number; // id of example
  title: string; // title of example
  description: string; // description of example
  annotation: string; // annotation by the teacher for this example in this course
  solutionfilename: string; // task filename
  externalurl: string; // externalurl of example
  externaltask: string; // url of associated module
  solution: string; // solution(url/description) of example
  timeframe: string; // timeframe as string
  hassubmissions: boolean; // true if example has already submissions
  solution_visible: boolean; // visibility for example solution in current context
  taskfiles?: dakoraplus_get_teacher_example_and_item_returns_example_taskfiles[]; // taskfiles of the example
  teacher_evaluation?: number; // teacher_evaluation
  student_evaluation?: number; // student_evaluation
}

export { enum_new_inprogress_submitted_completed as dakoraplus_get_teacher_example_and_item_returns_status };

export interface dakoraplus_get_teacher_example_and_item_returns {
  courseid: number;
  status: enum_new_inprogress_submitted_completed; // ENUM(new, inprogress, submitted, completed)
  subjectid: number; // id of subject
  subjecttitle: string; // title of subject
  topicid: number; // id of topic
  topictitle: string; // title of topic
  niveautitle: string; // title of niveau
  niveauid: number; // id of niveau
  timemodified: number; // time the item was last modified --> not gradings, but only changes to the item (files, comments, name, collaborators)
  example?: dakoraplus_get_teacher_example_and_item_returns_example; // example information
  item?: dakoraplus_get_teacher_example_and_item_returns_item; // item information
}

export interface dakoraplus_save_coursesettings_parameters {
  courseid: number;
  experience_level?: string; // default: NULL
}

export interface dakoraplus_save_coursesettings_returns {
  success: boolean; // status
}

export interface dakoraplus_get_learning_diary_parameters {}

export interface dakoraplus_get_learning_diary_returns_item {
  id: number;
  timecreated: number;
  timemodified: number;
  title: string;
  text: string;
}

export interface dakoraplus_save_learning_diary_entry_parameters {
  id?: number; // default: int(0)
  title: string;
  text: string;
}

export interface dakoraplus_save_learning_diary_entry_returns {
  success: boolean; // status
}

export interface block_exacomp_get_lang_parameters {
  lang: string;
  app: string;
}

export interface block_exacomp_get_lang_returns {
  string_id: string; // translation
}

export interface diggrplus_learningpath_list_parameters {
  courseid: number;
  studentid?: number; // default: int(0)
}

export interface diggrplus_learningpath_list_returns_learningpaths {
  id: number;
  courseid: number;
  title: string;
  description: string;
  visible: boolean;
  count_new: number; // default: int(0)
  count_inprogress: number; // default: int(0)
  count_completed: number; // default: int(0)
  count_submitted: number; // default: int(0)
}

export interface diggrplus_learningpath_list_returns {
  learningpaths: diggrplus_learningpath_list_returns_learningpaths[];
}

export interface diggrplus_learningpath_details_parameters {
  id: number;
  studentid: number;
}

export { enum_new_inprogress_submitted_completed as diggrplus_learningpath_details_returns_items_status };

export interface diggrplus_learningpath_details_returns_items {
  id: number;
  exampleid: number;
  exampletitle: string;
  status: enum_new_inprogress_submitted_completed; // ENUM(new, inprogress, submitted, completed)
  topictitle: string;
  sorting: number;
  visibleall: boolean;
  visiblestudent: boolean;
  count_new: number;
  count_inprogress: number;
  count_completed: number;
  count_submitted: number;
}

export interface diggrplus_learningpath_details_returns {
  id: number;
  courseid: number;
  title: string;
  description: string;
  visible: boolean;
  items: diggrplus_learningpath_details_returns_items[];
}

export interface diggrplus_learningpath_add_parameters {
  courseid: number;
  title: string;
  description: string;
  visible: boolean;
}

export interface diggrplus_learningpath_add_returns {
  success: boolean; // status
  id: number;
}

export interface diggrplus_learningpath_delete_parameters {
  id: number;
}

export interface diggrplus_learningpath_delete_returns {
  success: boolean; // status
}

export interface diggrplus_learningpath_update_parameters {
  id: number;
  title?: string; // default: NULL
  description?: string; // default: NULL
  visible?: boolean; // default: NULL
}

export interface diggrplus_learningpath_update_returns {
  success: boolean; // status
}

export interface diggrplus_learningpath_item_update_parameters {
  id: number;
  studentid?: number; // default: int(0)
  visibleall?: boolean; // default: NULL
  visiblestudent?: boolean; // default: NULL
}

export interface diggrplus_learningpath_item_update_returns {
  success: boolean; // status
}

type diggrplus_learningpath_add_items_parameters_studentids = number;

type diggrplus_learningpath_add_items_parameters_exampleids = number;

export interface diggrplus_learningpath_add_items_parameters {
  learningpathid: number;
  exampleids: diggrplus_learningpath_add_items_parameters_exampleids[];
  studentids?: diggrplus_learningpath_add_items_parameters_studentids[]; // default: array(0) { }
}

export interface diggrplus_learningpath_add_items_returns {
  success: boolean; // status
}

export interface diggrplus_learningpath_item_delete_parameters {
  id: number;
}

export interface diggrplus_learningpath_item_delete_returns {
  success: boolean; // status
}

type diggrplus_learningpath_item_sorting_parameters_itemids = number;

export interface diggrplus_learningpath_item_sorting_parameters {
  learningpathid: number;
  itemids: diggrplus_learningpath_item_sorting_parameters_itemids[];
}

export interface diggrplus_learningpath_item_sorting_returns {
  success: boolean; // status
}

export enum enum_html_pdf {
  HTML = 'html',
  PDF = 'pdf',
}

export { enum_html_pdf as dakoraplus_create_report_parameters_result_type };

export enum enum_list_grid {
  LIST = 'list',
  GRID = 'grid',
}

export { enum_list_grid as dakoraplus_create_report_parameters_output_style };

type dakoraplus_create_report_parameters_topicids = number;

type dakoraplus_create_report_parameters_studentids = number;

export interface dakoraplus_create_report_parameters {
  courseid: number;
  studentids?: dakoraplus_create_report_parameters_studentids[]; // default: array(0) { }
  topicids: dakoraplus_create_report_parameters_topicids[];
  with_childdescriptors?: boolean; // default: bool(false)
  with_examples?: boolean; // default: bool(true)
  only_achieved_competencies?: boolean; // default: bool(false)
  time_from?: number; // default: int(0)
  time_to?: number; // default: int(0)
  output_style: enum_list_grid; // ENUM(list, grid)
  result_type: enum_html_pdf; // ENUM(html, pdf)
}

type dakoraplus_create_report_returns = string;

export interface block_exacomp_get_fullcompetence_grid_for_profile_parameters {
  userid: number; // id of user
}

export interface block_exacomp_get_fullcompetence_grid_for_profile_returns_courses_subjects_student_gridgradings_rows_columns {
  text: string; // default: string(0) "" // cell text
  evaluation: number; // default: int(-1) // evaluation
  evaluation_text: string; // default: string(0) "" // evaluation text
  evaluation_mapped: number; // default: int(-1) // mapped evaluation
  evalniveauid: number; // default: int(0) // evaluation niveau id
  show: boolean; // default: bool(true) // show cell
  visible: boolean; // default: bool(true) // cell visibility
  topicid: number; // default: int(0) // topic id
  span: number; // colspan
  timestamp: number; // default: int(0) // evaluation timestamp, 0 if not set
  gradingisold: boolean; // default: NULL // true when there are childdescriptors with newer gradings than the parentdescriptor
}

export interface block_exacomp_get_fullcompetence_grid_for_profile_returns_courses_subjects_student_gridgradings_rows {
  columns: block_exacomp_get_fullcompetence_grid_for_profile_returns_courses_subjects_student_gridgradings_rows_columns[];
}

export interface block_exacomp_get_fullcompetence_grid_for_profile_returns_courses_subjects_student_gridgradings {
  title: string; // default: string(0) "" // title of table
  rows: block_exacomp_get_fullcompetence_grid_for_profile_returns_courses_subjects_student_gridgradings_rows[];
}

export interface block_exacomp_get_fullcompetence_grid_for_profile_returns_courses_subjects_student {
  gridgradings: block_exacomp_get_fullcompetence_grid_for_profile_returns_courses_subjects_student_gridgradings[];
}

export interface block_exacomp_get_fullcompetence_grid_for_profile_returns_courses_subjects_teacher_gridgradings_rows_columns {
  text: string; // default: string(0) "" // cell text
  evaluation: number; // default: int(-1) // evaluation
  evaluation_text: string; // default: string(0) "" // evaluation text
  evaluation_mapped: number; // default: int(-1) // mapped evaluation
  evalniveauid: number; // default: int(0) // evaluation niveau id
  show: boolean; // default: bool(true) // show cell
  visible: boolean; // default: bool(true) // cell visibility
  topicid: number; // default: int(0) // topic id
  span: number; // colspan
  timestamp: number; // default: int(0) // evaluation timestamp, 0 if not set
  gradingisold: boolean; // default: NULL // true when there are childdescriptors with newer gradings than the parentdescriptor
}

export interface block_exacomp_get_fullcompetence_grid_for_profile_returns_courses_subjects_teacher_gridgradings_rows {
  columns: block_exacomp_get_fullcompetence_grid_for_profile_returns_courses_subjects_teacher_gridgradings_rows_columns[];
}

export interface block_exacomp_get_fullcompetence_grid_for_profile_returns_courses_subjects_teacher_gridgradings {
  title: string; // default: string(0) "" // title of table
  rows: block_exacomp_get_fullcompetence_grid_for_profile_returns_courses_subjects_teacher_gridgradings_rows[];
}

export interface block_exacomp_get_fullcompetence_grid_for_profile_returns_courses_subjects_teacher {
  gridgradings: block_exacomp_get_fullcompetence_grid_for_profile_returns_courses_subjects_teacher_gridgradings[];
}

export interface block_exacomp_get_fullcompetence_grid_for_profile_returns_courses_subjects {
  id: number; // id of the subject
  title: string; // default: string(0) "" // title of the subject
  teacher: block_exacomp_get_fullcompetence_grid_for_profile_returns_courses_subjects_teacher;
  student: block_exacomp_get_fullcompetence_grid_for_profile_returns_courses_subjects_student;
}

export interface block_exacomp_get_fullcompetence_grid_for_profile_returns_courses {
  id: number; // id of the course
  title: string; // default: string(0) "" // title of the course
  subjects: block_exacomp_get_fullcompetence_grid_for_profile_returns_courses_subjects[];
}

export interface block_exacomp_get_fullcompetence_grid_for_profile_returns {
  courses: block_exacomp_get_fullcompetence_grid_for_profile_returns_courses[];
}

export interface diggrplus_v_edit_course_parameters {
  courseid: number; // courseid of course that should be edited
  fullname: string; // new fullname of course
}

export interface diggrplus_v_edit_course_returns {
  success: boolean; // status
}

export interface diggrplus_v_create_or_update_student_parameters {
  courseid: number; // courseid of course where the student should be added
  userid?: number; // default: int(0) // userid of student. 0 if new
  firstname: string; // firstname of student
  lastname: string; // lastname of student
  ausserordentlich: boolean;
}

export interface diggrplus_v_create_or_update_student_returns {
  userid: number; // userid of created or updated user
}

export interface diggrplus_v_delete_student_parameters {
  courseid: number;
  userid: number; // userid of student. 0 if new
}

export interface diggrplus_v_delete_student_returns {
  success: boolean; // status
}

export interface diggrplus_v_get_student_by_id_parameters {
  courseid: number;
  userid: number; // userid of student. 0 if new
}

export interface diggrplus_v_get_student_by_id_returns {
  id: number; // id
  username: string; // username
  firstname: string; // firstname
  lastname: string; // lastname
  email: string; // email
  suspended: boolean; // suspended
  ausserordentlich: boolean;
}

export interface diggrplus_v_get_student_grading_tree_parameters {
  userid: number;
  courseid: number;
}

export interface diggrplus_v_get_student_grading_tree_returns_competencetree_topics_descriptors {
  id: number;
  title: string;
  niveauid: number;
  niveau_title: string;
  teacherevaluation: number; // teacher evaluation of descriptor
  personalisedtext: string;
  sorting: number;
}

export interface diggrplus_v_get_student_grading_tree_returns_competencetree_topics {
  id: number; // id of example
  title: string; // title of topic
  descriptors: diggrplus_v_get_student_grading_tree_returns_competencetree_topics_descriptors[];
}

export interface diggrplus_v_get_student_grading_tree_returns_competencetree {
  id: number; // id of subject
  title: string; // title of subject
  class: string; // class number. E.g. "First Grade" or "1"
  personalisedtext: string;
  assess_with_grades: boolean;
  spf: boolean;
  is_religion: boolean;
  is_pflichtgegenstand: boolean;
  is_freigegenstand: boolean;
  topics: diggrplus_v_get_student_grading_tree_returns_competencetree_topics[];
}

export interface diggrplus_v_get_student_grading_tree_returns {
  competencetree: diggrplus_v_get_student_grading_tree_returns_competencetree[];
}

export interface diggrplus_v_save_student_grading_parameters_descriptors {
  id: number;
  teacherevaluation?: number;
  personalisedtext?: string;
}

export interface diggrplus_v_save_student_grading_parameters_subjects {
  id: number;
  personalisedtext?: string;
  assess_with_grades: boolean;
  is_pflichtgegenstand?: boolean;
  spf: boolean;
}

export interface diggrplus_v_save_student_grading_parameters {
  userid: number;
  courseid: number;
  subjects?: diggrplus_v_save_student_grading_parameters_subjects[];
  descriptors: diggrplus_v_save_student_grading_parameters_descriptors[];
}

export interface diggrplus_v_save_student_grading_returns {
  success: boolean; // status
}

export interface diggrplus_v_print_student_grading_report_parameters {
  userid: string; // userid (number) or 'all' to print all users
  courseid: number;
  output_format?: string; // default: string(3) "pdf" // pdf or html
  schoolname?: string; // default: string(0) ""
  assessment_period_title?: string; // default: string(0) ""
}

export interface diggrplus_v_print_student_grading_report_returns {
  pdf: string;
}

export interface diggrplus_v_get_course_edulevel_schooltype_tree_parameters {
  courseid: number;
}

export interface diggrplus_v_get_course_edulevel_schooltype_tree_returns_edulevels_schooltypes_subjects_topics {
  id: number;
  title: string; // topic title
  active: boolean;
}

export interface diggrplus_v_get_course_edulevel_schooltype_tree_returns_edulevels_schooltypes_subjects {
  id: number;
  class: string; // class number. E.g. "First Grade" or "1"
  title: string; // subject title
  topics: diggrplus_v_get_course_edulevel_schooltype_tree_returns_edulevels_schooltypes_subjects_topics[];
}

export interface diggrplus_v_get_course_edulevel_schooltype_tree_returns_edulevels_schooltypes {
  id: number;
  title: string; // schooltype title
  subjects: diggrplus_v_get_course_edulevel_schooltype_tree_returns_edulevels_schooltypes_subjects[];
}

export interface diggrplus_v_get_course_edulevel_schooltype_tree_returns_edulevels {
  id: number;
  title: string; // schooltype title
  schooltypes: diggrplus_v_get_course_edulevel_schooltype_tree_returns_edulevels_schooltypes[];
}

export interface diggrplus_v_get_course_edulevel_schooltype_tree_returns {
  edulevels: diggrplus_v_get_course_edulevel_schooltype_tree_returns_edulevels[];
}

type mod_quiz_get_quizzes_by_courses_parameters_courseids = number;

export interface mod_quiz_get_quizzes_by_courses_parameters {
  courseids?: mod_quiz_get_quizzes_by_courses_parameters_courseids[]; // default: array(0) { } // Array of course ids
}

export interface mod_quiz_get_quizzes_by_courses_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface mod_quiz_get_quizzes_by_courses_returns_quizzes_introfiles {
  filename?: string; // File name.
  filepath?: string; // File path.
  filesize?: number; // File size.
  fileurl?: string; // Downloadable file url.
  timemodified?: number; // Time modified.
  mimetype?: string; // File mime type.
  isexternalfile?: boolean; // Whether is an external file.
  repositorytype?: string; // The repository type for external files.
}

export interface mod_quiz_get_quizzes_by_courses_returns_quizzes {
  id: number; // Activity instance id
  coursemodule: number; // Course module id
  course: number; // Course id
  name: string; // Activity name
  intro?: string; // Activity introduction
  introformat?: number; // intro format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  introfiles?: mod_quiz_get_quizzes_by_courses_returns_quizzes_introfiles[]; // Files in the introduction
  section?: number; // Course section id
  visible?: boolean; // Visible
  groupmode?: number; // Group mode
  groupingid?: number; // Group id
  lang?: string; // Forced activity language
  timeopen?: number; // The time when this quiz opens. (0 = no restriction.)
  timeclose?: number; // The time when this quiz closes. (0 = no restriction.)
  timelimit?: number; // The time limit for quiz attempts, in seconds.
  overduehandling?: string; // The method used to handle overdue attempts. 'autosubmit', 'graceperiod' or 'autoabandon'.
  graceperiod?: number; // The amount of time (in seconds) after the time limit runs out during which attempts can still be submitted, if overduehandling is set to allow it.
  preferredbehaviour?: string; // The behaviour to ask questions to use.
  canredoquestions?: number; // Allows students to redo any completed question within a quiz attempt.
  attempts?: number; // The maximum number of attempts a student is allowed.
  attemptonlast?: number; // Whether subsequent attempts start from the answer to the previous attempt (1) or start blank (0).
  grademethod?: number; // One of the values QUIZ_GRADEHIGHEST, QUIZ_GRADEAVERAGE, QUIZ_ATTEMPTFIRST or QUIZ_ATTEMPTLAST.
  decimalpoints?: number; // Number of decimal points to use when displaying grades.
  questiondecimalpoints?: number; // Number of decimal points to use when displaying question grades. (-1 means use decimalpoints.)
  reviewattempt?: number; // Whether users are allowed to review their quiz attempts at various times. This is a bit field, decoded by the mod_quiz_display_options class. It is formed by ORing together the constants defined there.
  reviewcorrectness?: number; // Whether users are allowed to review their quiz attempts at various times. A bit field, like reviewattempt.
  reviewmarks?: number; // Whether users are allowed to review their quiz attempts at various times. A bit field, like reviewattempt.
  reviewspecificfeedback?: number; // Whether users are allowed to review their quiz attempts at various times. A bit field, like reviewattempt.
  reviewgeneralfeedback?: number; // Whether users are allowed to review their quiz attempts at various times. A bit field, like reviewattempt.
  reviewrightanswer?: number; // Whether users are allowed to review their quiz attempts at various times. A bit field, like reviewattempt.
  reviewoverallfeedback?: number; // Whether users are allowed to review their quiz attempts at various times. A bit field, like reviewattempt.
  questionsperpage?: number; // How often to insert a page break when editing the quiz, or when shuffling the question order.
  navmethod?: string; // Any constraints on how the user is allowed to navigate around the quiz. Currently recognised values are 'free' and 'seq'.
  shuffleanswers?: number; // Whether the parts of the question should be shuffled, in those question types that support it.
  sumgrades?: number; // The total of all the question instance maxmarks.
  grade?: number; // The total that the quiz overall grade is scaled to be out of.
  timecreated?: number; // The time when the quiz was added to the course.
  timemodified?: number; // Last modified time.
  password?: string; // A password that the student must enter before starting or continuing a quiz attempt.
  subnet?: string; // Used to restrict the IP addresses from which this quiz can be attempted. The format is as requried by the address_in_subnet function.
  browsersecurity?: string; // Restriciton on the browser the student must use. E.g. 'securewindow'.
  delay1?: number; // Delay that must be left between the first and second attempt, in seconds.
  delay2?: number; // Delay that must be left between the second and subsequent attempt, in seconds.
  showuserpicture?: number; // Option to show the user's picture during the attempt and on the review page.
  showblocks?: number; // Whether blocks should be shown on the attempt.php and review.php pages.
  completionattemptsexhausted?: number; // Mark quiz complete when the student has exhausted the maximum number of attempts
  completionpass?: number; // Whether to require passing grade
  allowofflineattempts?: number; // Whether to allow the quiz to be attempted offline in the mobile app
  autosaveperiod?: number; // Auto-save delay
  hasfeedback?: number; // Whether the quiz has any non-blank feedback text
  hasquestions?: number; // Whether the quiz has questions
}

export interface mod_quiz_get_quizzes_by_courses_returns {
  quizzes: mod_quiz_get_quizzes_by_courses_returns_quizzes[];
  warnings?: mod_quiz_get_quizzes_by_courses_returns_warnings[]; // list of warnings
}

export interface mod_quiz_view_quiz_parameters {
  quizid: number; // quiz instance id
}

export interface mod_quiz_view_quiz_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface mod_quiz_view_quiz_returns {
  status: boolean; // status: true if success
  warnings?: mod_quiz_view_quiz_returns_warnings[]; // list of warnings
}

export interface mod_quiz_get_user_attempts_parameters {
  quizid: number; // quiz instance id
  userid?: number; // default: int(0) // user id, empty for current user
  status?: string; // default: string(8) "finished" // quiz status: all, finished or unfinished
  includepreviews?: boolean; // default: bool(false) // whether to include previews or not
}

export interface mod_quiz_get_user_attempts_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface mod_quiz_get_user_attempts_returns_attempts {
  id?: number; // Attempt id.
  quiz?: number; // Foreign key reference to the quiz that was attempted.
  userid?: number; // Foreign key reference to the user whose attempt this is.
  attempt?: number; // Sequentially numbers this students attempts at this quiz.
  uniqueid?: number; // Foreign key reference to the question_usage that holds the details of the the question_attempts that make up this quiz attempt.
  layout?: string; // Attempt layout.
  currentpage?: number; // Attempt current page.
  preview?: number; // Whether is a preview attempt or not.
  state?: string; // The current state of the attempts. 'inprogress', 'overdue', 'finished' or 'abandoned'.
  timestart?: number; // Time when the attempt was started.
  timefinish?: number; // Time when the attempt was submitted. 0 if the attempt has not been submitted yet.
  timemodified?: number; // Last modified time.
  timemodifiedoffline?: number; // Last modified time via webservices.
  timecheckstate?: number; // Next time quiz cron should check attempt for state changes. NULL means never check.
  sumgrades?: number; // Total marks for this attempt.
  gradednotificationsenttime?: number; // Time when the student was notified that manual grading of their attempt was complete.
}

export interface mod_quiz_get_user_attempts_returns {
  attempts: mod_quiz_get_user_attempts_returns_attempts[];
  warnings?: mod_quiz_get_user_attempts_returns_warnings[]; // list of warnings
}

export interface mod_quiz_get_user_best_grade_parameters {
  quizid: number; // quiz instance id
  userid?: number; // default: int(0) // user id
}

export interface mod_quiz_get_user_best_grade_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface mod_quiz_get_user_best_grade_returns {
  hasgrade: boolean; // Whether the user has a grade on the given quiz.
  grade?: number; // The grade (only if the user has a grade).
  gradetopass?: number; // The grade to pass the quiz (only if set).
  warnings?: mod_quiz_get_user_best_grade_returns_warnings[]; // list of warnings
}

export interface mod_quiz_get_combined_review_options_parameters {
  quizid: number; // quiz instance id
  userid?: number; // default: int(0) // user id (empty for current user)
}

export interface mod_quiz_get_combined_review_options_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface mod_quiz_get_combined_review_options_returns_alloptions {
  name: string; // option name
  value: number; // option value
}

export interface mod_quiz_get_combined_review_options_returns_someoptions {
  name: string; // option name
  value: number; // option value
}

export interface mod_quiz_get_combined_review_options_returns {
  someoptions: mod_quiz_get_combined_review_options_returns_someoptions[];
  alloptions: mod_quiz_get_combined_review_options_returns_alloptions[];
  warnings?: mod_quiz_get_combined_review_options_returns_warnings[]; // list of warnings
}

export interface mod_quiz_start_attempt_parameters_preflightdata {
  name: string; // data name
  value: string; // data value
}

export interface mod_quiz_start_attempt_parameters {
  quizid: number; // quiz instance id
  preflightdata?: mod_quiz_start_attempt_parameters_preflightdata[]; // default: array(0) { } // Preflight required data (like passwords)
  forcenew?: boolean; // default: bool(false) // Whether to force a new attempt or not.
}

export interface mod_quiz_start_attempt_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface mod_quiz_start_attempt_returns_attempt {
  id?: number; // Attempt id.
  quiz?: number; // Foreign key reference to the quiz that was attempted.
  userid?: number; // Foreign key reference to the user whose attempt this is.
  attempt?: number; // Sequentially numbers this students attempts at this quiz.
  uniqueid?: number; // Foreign key reference to the question_usage that holds the details of the the question_attempts that make up this quiz attempt.
  layout?: string; // Attempt layout.
  currentpage?: number; // Attempt current page.
  preview?: number; // Whether is a preview attempt or not.
  state?: string; // The current state of the attempts. 'inprogress', 'overdue', 'finished' or 'abandoned'.
  timestart?: number; // Time when the attempt was started.
  timefinish?: number; // Time when the attempt was submitted. 0 if the attempt has not been submitted yet.
  timemodified?: number; // Last modified time.
  timemodifiedoffline?: number; // Last modified time via webservices.
  timecheckstate?: number; // Next time quiz cron should check attempt for state changes. NULL means never check.
  sumgrades?: number; // Total marks for this attempt.
  gradednotificationsenttime?: number; // Time when the student was notified that manual grading of their attempt was complete.
}

export interface mod_quiz_start_attempt_returns {
  attempt: mod_quiz_start_attempt_returns_attempt;
  warnings?: mod_quiz_start_attempt_returns_warnings[]; // list of warnings
}

export interface mod_quiz_get_attempt_data_parameters_preflightdata {
  name: string; // data name
  value: string; // data value
}

export interface mod_quiz_get_attempt_data_parameters {
  attemptid: number; // attempt id
  page: number; // page number
  preflightdata?: mod_quiz_get_attempt_data_parameters_preflightdata[]; // default: array(0) { } // Preflight required data (like passwords)
}

export interface mod_quiz_get_attempt_data_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface mod_quiz_get_attempt_data_returns_questions_responsefileareas_files {
  filename?: string; // File name.
  filepath?: string; // File path.
  filesize?: number; // File size.
  fileurl?: string; // Downloadable file url.
  timemodified?: number; // Time modified.
  mimetype?: string; // File mime type.
  isexternalfile?: boolean; // Whether is an external file.
  repositorytype?: string; // The repository type for external files.
}

export interface mod_quiz_get_attempt_data_returns_questions_responsefileareas {
  area: string; // File area name
  files?: mod_quiz_get_attempt_data_returns_questions_responsefileareas_files[]; // Response files for the question
}

export interface mod_quiz_get_attempt_data_returns_questions {
  slot: number; // slot number
  type: string; // question type, i.e: multichoice
  page: number; // page of the quiz this question appears on
  html: string; // the question rendered
  responsefileareas?: mod_quiz_get_attempt_data_returns_questions_responsefileareas[]; // Response file areas including files
  sequencecheck?: number; // the number of real steps in this attempt
  lastactiontime?: number; // the timestamp of the most recent step in this question attempt
  hasautosavedstep?: boolean; // whether this question attempt has autosaved data
  flagged: boolean; // whether the question is flagged or not
  number?: number; // question ordering number in the quiz
  state?: string; // the state where the question is in. It will not be returned if the user cannot see it due to the quiz display correctness settings.
  status?: string; // current formatted state of the question
  blockedbyprevious?: boolean; // whether the question is blocked by the previous question
  mark?: string; // the mark awarded. It will be returned only if the user is allowed to see it.
  maxmark?: number; // the maximum mark possible for this question attempt. It will be returned only if the user is allowed to see it.
  settings?: string; // Question settings (JSON encoded).
}

type mod_quiz_get_attempt_data_returns_messages = string;

export interface mod_quiz_get_attempt_data_returns_attempt {
  id?: number; // Attempt id.
  quiz?: number; // Foreign key reference to the quiz that was attempted.
  userid?: number; // Foreign key reference to the user whose attempt this is.
  attempt?: number; // Sequentially numbers this students attempts at this quiz.
  uniqueid?: number; // Foreign key reference to the question_usage that holds the details of the the question_attempts that make up this quiz attempt.
  layout?: string; // Attempt layout.
  currentpage?: number; // Attempt current page.
  preview?: number; // Whether is a preview attempt or not.
  state?: string; // The current state of the attempts. 'inprogress', 'overdue', 'finished' or 'abandoned'.
  timestart?: number; // Time when the attempt was started.
  timefinish?: number; // Time when the attempt was submitted. 0 if the attempt has not been submitted yet.
  timemodified?: number; // Last modified time.
  timemodifiedoffline?: number; // Last modified time via webservices.
  timecheckstate?: number; // Next time quiz cron should check attempt for state changes. NULL means never check.
  sumgrades?: number; // Total marks for this attempt.
  gradednotificationsenttime?: number; // Time when the student was notified that manual grading of their attempt was complete.
}

export interface mod_quiz_get_attempt_data_returns {
  attempt: mod_quiz_get_attempt_data_returns_attempt;
  messages: mod_quiz_get_attempt_data_returns_messages[]; // access messages, will only be returned for users with mod/quiz:preview capability, for other users this method will throw an exception if there are messages
  nextpage: number; // next page number
  questions: mod_quiz_get_attempt_data_returns_questions[];
  warnings?: mod_quiz_get_attempt_data_returns_warnings[]; // list of warnings
}

export interface mod_quiz_get_attempt_summary_parameters_preflightdata {
  name: string; // data name
  value: string; // data value
}

export interface mod_quiz_get_attempt_summary_parameters {
  attemptid: number; // attempt id
  preflightdata?: mod_quiz_get_attempt_summary_parameters_preflightdata[]; // default: array(0) { } // Preflight required data (like passwords)
}

export interface mod_quiz_get_attempt_summary_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface mod_quiz_get_attempt_summary_returns_questions_responsefileareas_files {
  filename?: string; // File name.
  filepath?: string; // File path.
  filesize?: number; // File size.
  fileurl?: string; // Downloadable file url.
  timemodified?: number; // Time modified.
  mimetype?: string; // File mime type.
  isexternalfile?: boolean; // Whether is an external file.
  repositorytype?: string; // The repository type for external files.
}

export interface mod_quiz_get_attempt_summary_returns_questions_responsefileareas {
  area: string; // File area name
  files?: mod_quiz_get_attempt_summary_returns_questions_responsefileareas_files[]; // Response files for the question
}

export interface mod_quiz_get_attempt_summary_returns_questions {
  slot: number; // slot number
  type: string; // question type, i.e: multichoice
  page: number; // page of the quiz this question appears on
  html: string; // the question rendered
  responsefileareas?: mod_quiz_get_attempt_summary_returns_questions_responsefileareas[]; // Response file areas including files
  sequencecheck?: number; // the number of real steps in this attempt
  lastactiontime?: number; // the timestamp of the most recent step in this question attempt
  hasautosavedstep?: boolean; // whether this question attempt has autosaved data
  flagged: boolean; // whether the question is flagged or not
  number?: number; // question ordering number in the quiz
  state?: string; // the state where the question is in. It will not be returned if the user cannot see it due to the quiz display correctness settings.
  status?: string; // current formatted state of the question
  blockedbyprevious?: boolean; // whether the question is blocked by the previous question
  mark?: string; // the mark awarded. It will be returned only if the user is allowed to see it.
  maxmark?: number; // the maximum mark possible for this question attempt. It will be returned only if the user is allowed to see it.
  settings?: string; // Question settings (JSON encoded).
}

export interface mod_quiz_get_attempt_summary_returns {
  questions: mod_quiz_get_attempt_summary_returns_questions[];
  warnings?: mod_quiz_get_attempt_summary_returns_warnings[]; // list of warnings
}

export interface mod_quiz_save_attempt_parameters_preflightdata {
  name: string; // data name
  value: string; // data value
}

export interface mod_quiz_save_attempt_parameters_data {
  name: string; // data name
  value: string; // data value
}

export interface mod_quiz_save_attempt_parameters {
  attemptid: number; // attempt id
  data: mod_quiz_save_attempt_parameters_data[]; // the data to be saved
  preflightdata?: mod_quiz_save_attempt_parameters_preflightdata[]; // default: array(0) { } // Preflight required data (like passwords)
}

export interface mod_quiz_save_attempt_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface mod_quiz_save_attempt_returns {
  status: boolean; // status: true if success
  warnings?: mod_quiz_save_attempt_returns_warnings[]; // list of warnings
}

export interface mod_quiz_process_attempt_parameters_preflightdata {
  name: string; // data name
  value: string; // data value
}

export interface mod_quiz_process_attempt_parameters_data {
  name: string; // data name
  value: string; // data value
}

export interface mod_quiz_process_attempt_parameters {
  attemptid: number; // attempt id
  data?: mod_quiz_process_attempt_parameters_data[]; // default: array(0) { } // the data to be saved
  finishattempt?: boolean; // default: bool(false) // whether to finish or not the attempt
  timeup?: boolean; // default: bool(false) // whether the WS was called by a timer when the time is up
  preflightdata?: mod_quiz_process_attempt_parameters_preflightdata[]; // default: array(0) { } // Preflight required data (like passwords)
}

export interface mod_quiz_process_attempt_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface mod_quiz_process_attempt_returns {
  state: string; // state: the new attempt state: inprogress, finished, overdue, abandoned
  warnings?: mod_quiz_process_attempt_returns_warnings[]; // list of warnings
}

export interface mod_quiz_get_attempt_review_parameters {
  attemptid: number; // attempt id
  page?: number; // default: int(-1) // page number, empty for all the questions in all the pages
}

export interface mod_quiz_get_attempt_review_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface mod_quiz_get_attempt_review_returns_questions_responsefileareas_files {
  filename?: string; // File name.
  filepath?: string; // File path.
  filesize?: number; // File size.
  fileurl?: string; // Downloadable file url.
  timemodified?: number; // Time modified.
  mimetype?: string; // File mime type.
  isexternalfile?: boolean; // Whether is an external file.
  repositorytype?: string; // The repository type for external files.
}

export interface mod_quiz_get_attempt_review_returns_questions_responsefileareas {
  area: string; // File area name
  files?: mod_quiz_get_attempt_review_returns_questions_responsefileareas_files[]; // Response files for the question
}

export interface mod_quiz_get_attempt_review_returns_questions {
  slot: number; // slot number
  type: string; // question type, i.e: multichoice
  page: number; // page of the quiz this question appears on
  html: string; // the question rendered
  responsefileareas?: mod_quiz_get_attempt_review_returns_questions_responsefileareas[]; // Response file areas including files
  sequencecheck?: number; // the number of real steps in this attempt
  lastactiontime?: number; // the timestamp of the most recent step in this question attempt
  hasautosavedstep?: boolean; // whether this question attempt has autosaved data
  flagged: boolean; // whether the question is flagged or not
  number?: number; // question ordering number in the quiz
  state?: string; // the state where the question is in. It will not be returned if the user cannot see it due to the quiz display correctness settings.
  status?: string; // current formatted state of the question
  blockedbyprevious?: boolean; // whether the question is blocked by the previous question
  mark?: string; // the mark awarded. It will be returned only if the user is allowed to see it.
  maxmark?: number; // the maximum mark possible for this question attempt. It will be returned only if the user is allowed to see it.
  settings?: string; // Question settings (JSON encoded).
}

export interface mod_quiz_get_attempt_review_returns_additionaldata {
  id: string; // id of the data
  title: string; // data title
  content: string; // data content
}

export interface mod_quiz_get_attempt_review_returns_attempt {
  id?: number; // Attempt id.
  quiz?: number; // Foreign key reference to the quiz that was attempted.
  userid?: number; // Foreign key reference to the user whose attempt this is.
  attempt?: number; // Sequentially numbers this students attempts at this quiz.
  uniqueid?: number; // Foreign key reference to the question_usage that holds the details of the the question_attempts that make up this quiz attempt.
  layout?: string; // Attempt layout.
  currentpage?: number; // Attempt current page.
  preview?: number; // Whether is a preview attempt or not.
  state?: string; // The current state of the attempts. 'inprogress', 'overdue', 'finished' or 'abandoned'.
  timestart?: number; // Time when the attempt was started.
  timefinish?: number; // Time when the attempt was submitted. 0 if the attempt has not been submitted yet.
  timemodified?: number; // Last modified time.
  timemodifiedoffline?: number; // Last modified time via webservices.
  timecheckstate?: number; // Next time quiz cron should check attempt for state changes. NULL means never check.
  sumgrades?: number; // Total marks for this attempt.
  gradednotificationsenttime?: number; // Time when the student was notified that manual grading of their attempt was complete.
}

export interface mod_quiz_get_attempt_review_returns {
  grade: string; // grade for the quiz (or empty or "notyetgraded")
  attempt: mod_quiz_get_attempt_review_returns_attempt;
  additionaldata: mod_quiz_get_attempt_review_returns_additionaldata[];
  questions: mod_quiz_get_attempt_review_returns_questions[];
  warnings?: mod_quiz_get_attempt_review_returns_warnings[]; // list of warnings
}

export interface mod_quiz_view_attempt_parameters_preflightdata {
  name: string; // data name
  value: string; // data value
}

export interface mod_quiz_view_attempt_parameters {
  attemptid: number; // attempt id
  page: number; // page number
  preflightdata?: mod_quiz_view_attempt_parameters_preflightdata[]; // default: array(0) { } // Preflight required data (like passwords)
}

export interface mod_quiz_view_attempt_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface mod_quiz_view_attempt_returns {
  status: boolean; // status: true if success
  warnings?: mod_quiz_view_attempt_returns_warnings[]; // list of warnings
}

export interface mod_quiz_view_attempt_summary_parameters_preflightdata {
  name: string; // data name
  value: string; // data value
}

export interface mod_quiz_view_attempt_summary_parameters {
  attemptid: number; // attempt id
  preflightdata?: mod_quiz_view_attempt_summary_parameters_preflightdata[]; // default: array(0) { } // Preflight required data (like passwords)
}

export interface mod_quiz_view_attempt_summary_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface mod_quiz_view_attempt_summary_returns {
  status: boolean; // status: true if success
  warnings?: mod_quiz_view_attempt_summary_returns_warnings[]; // list of warnings
}

export interface mod_quiz_view_attempt_review_parameters {
  attemptid: number; // attempt id
}

export interface mod_quiz_view_attempt_review_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface mod_quiz_view_attempt_review_returns {
  status: boolean; // status: true if success
  warnings?: mod_quiz_view_attempt_review_returns_warnings[]; // list of warnings
}

export interface mod_quiz_get_quiz_feedback_for_grade_parameters {
  quizid: number; // quiz instance id
  grade: number; // the grade to check
}

export interface mod_quiz_get_quiz_feedback_for_grade_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface mod_quiz_get_quiz_feedback_for_grade_returns_feedbackinlinefiles {
  filename?: string; // File name.
  filepath?: string; // File path.
  filesize?: number; // File size.
  fileurl?: string; // Downloadable file url.
  timemodified?: number; // Time modified.
  mimetype?: string; // File mime type.
  isexternalfile?: boolean; // Whether is an external file.
  repositorytype?: string; // The repository type for external files.
}

export interface mod_quiz_get_quiz_feedback_for_grade_returns {
  feedbacktext: string; // the comment that corresponds to this grade (empty for none)
  feedbacktextformat?: number; // feedbacktext format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  feedbackinlinefiles?: mod_quiz_get_quiz_feedback_for_grade_returns_feedbackinlinefiles[]; // feedback inline files
  warnings?: mod_quiz_get_quiz_feedback_for_grade_returns_warnings[]; // list of warnings
}

export interface mod_quiz_get_quiz_access_information_parameters {
  quizid: number; // quiz instance id
}

export interface mod_quiz_get_quiz_access_information_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

type mod_quiz_get_quiz_access_information_returns_preventaccessreasons = string;

type mod_quiz_get_quiz_access_information_returns_activerulenames = string;

type mod_quiz_get_quiz_access_information_returns_accessrules = string;

export interface mod_quiz_get_quiz_access_information_returns {
  canattempt: boolean; // Whether the user can do the quiz or not.
  canmanage: boolean; // Whether the user can edit the quiz settings or not.
  canpreview: boolean; // Whether the user can preview the quiz or not.
  canreviewmyattempts: boolean; // Whether the users can review their previous attempts or not.
  canviewreports: boolean; // Whether the user can view the quiz reports or not.
  accessrules: mod_quiz_get_quiz_access_information_returns_accessrules[]; // list of rules
  activerulenames: mod_quiz_get_quiz_access_information_returns_activerulenames[]; // list of active rules
  preventaccessreasons: mod_quiz_get_quiz_access_information_returns_preventaccessreasons[]; // list of reasons
  warnings?: mod_quiz_get_quiz_access_information_returns_warnings[]; // list of warnings
}

export interface mod_quiz_get_attempt_access_information_parameters {
  quizid: number; // quiz instance id
  attemptid?: number; // default: int(0) // attempt id, 0 for the user last attempt if exists
}

export interface mod_quiz_get_attempt_access_information_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

type mod_quiz_get_attempt_access_information_returns_preventnewattemptreasons = string;

export interface mod_quiz_get_attempt_access_information_returns {
  endtime?: number; // When the attempt must be submitted (determined by rules).
  isfinished: boolean; // Whether there is no way the user will ever be allowed to attempt.
  ispreflightcheckrequired?: boolean; // whether a check is required before the user starts/continues his attempt.
  preventnewattemptreasons: mod_quiz_get_attempt_access_information_returns_preventnewattemptreasons[]; // list of reasons
  warnings?: mod_quiz_get_attempt_access_information_returns_warnings[]; // list of warnings
}

export interface mod_quiz_get_quiz_required_qtypes_parameters {
  quizid: number; // quiz instance id
}

export interface mod_quiz_get_quiz_required_qtypes_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

type mod_quiz_get_quiz_required_qtypes_returns_questiontypes = string;

export interface mod_quiz_get_quiz_required_qtypes_returns {
  questiontypes: mod_quiz_get_quiz_required_qtypes_returns_questiontypes[]; // list of question types used in the quiz
  warnings?: mod_quiz_get_quiz_required_qtypes_returns_warnings[]; // list of warnings
}

export interface mod_quiz_set_question_version_parameters {
  slotid: number;
  newversion: number;
}

export interface mod_quiz_set_question_version_returns {
  result: boolean;
}

export interface core_auth_confirm_user_parameters {
  username: string; // User name
  secret: string; // Confirmation secret
}

export interface core_auth_confirm_user_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_auth_confirm_user_returns {
  success: boolean; // True if the user was confirmed, false if he was already confirmed
  warnings?: core_auth_confirm_user_returns_warnings[]; // list of warnings
}

export interface core_auth_request_password_reset_parameters {
  username?: string; // default: string(0) "" // User name
  email?: string; // default: string(0) "" // User email
}

export interface core_auth_request_password_reset_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_auth_request_password_reset_returns {
  status: string; // The returned status of the process: dataerror: Error in the sent data (username or email). More information in warnings field. emailpasswordconfirmmaybesent: Email sent or not (depends on user found in database). emailpasswordconfirmnotsent: Failure, user not found. emailpasswordconfirmnoemail: Failure, email not found. emailalreadysent: Email already sent. emailpasswordconfirmsent: User pending confirmation. emailresetconfirmsent: Email sent.
  notice: string; // Important information for the user about the process.
  warnings?: core_auth_request_password_reset_returns_warnings[]; // list of warnings
}

export interface core_auth_is_minor_parameters {
  age: number; // Age
  country: string; // Country of residence
}

export interface core_auth_is_minor_returns {
  status: boolean; // True if the user is considered to be a digital minor, false if not
}

export interface core_auth_is_age_digital_consent_verification_enabled_parameters {}

export interface core_auth_is_age_digital_consent_verification_enabled_returns {
  status: boolean; // True if digital consent verification is enabled, false otherwise.
}

export interface core_auth_resend_confirmation_email_parameters {
  username: string; // Username.
  password: string; // Plain text password.
  redirect?: string; // default: string(0) "" // Redirect the user to this site url after confirmation.
}

export interface core_auth_resend_confirmation_email_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_auth_resend_confirmation_email_returns {
  status: boolean; // True if the confirmation email was sent, false otherwise.
  warnings?: core_auth_resend_confirmation_email_returns_warnings[]; // list of warnings
}

type core_backup_get_async_backup_progress_parameters_backupids = string;

export interface core_backup_get_async_backup_progress_parameters {
  backupids: core_backup_get_async_backup_progress_parameters_backupids[]; // Backup id to get progress for
  contextid: number; // Context id
}

export interface core_backup_get_async_backup_progress_returns_item {
  status: number; // Backup Status
  progress: number; // Backup progress
  backupid: string; // Backup id
  operation: string; // operation type
}

export interface core_backup_get_async_backup_links_backup_parameters {
  filename: string; // Backup filename
  contextid: number; // Context id
  backupid: string; // Backup id
}

export interface core_backup_get_async_backup_links_backup_returns {
  filesize: string; // Backup file size
  fileurl: string; // Backup file URL
  restoreurl: string; // Backup restore URL
}

export interface core_backup_get_async_backup_links_restore_parameters {
  backupid: string; // Backup id
  contextid: number; // Context id
}

export interface core_backup_get_async_backup_links_restore_returns {
  restoreurl: string; // Restore url
}

export interface core_backup_get_copy_progress_parameters_copies {
  backupid: string; // Backup id
  restoreid: string; // Restore id
  operation: string; // Operation type
}

export interface core_backup_get_copy_progress_parameters {
  copies: core_backup_get_copy_progress_parameters_copies[]; // Copy data
}

export interface core_backup_get_copy_progress_returns_item {
  status: number; // Copy Status
  progress: number; // Copy progress
  backupid: string; // Copy id
  operation: string; // Operation type
}

export interface core_backup_submit_copy_form_parameters {
  jsonformdata: string; // The data from the create copy form, encoded as a json array
}

type core_backup_submit_copy_form_returns = string;

export interface core_badges_get_user_badges_parameters {
  userid?: number; // default: int(0) // Badges only for this user id, empty for current user
  courseid?: number; // default: int(0) // Filter badges by course id, empty all the courses
  page?: number; // default: int(0) // The page of records to return.
  perpage?: number; // default: int(0) // The number of records to return per page
  search?: string; // default: string(0) "" // A simple string to search for
  onlypublic?: boolean; // default: bool(false) // Whether to return only public badges
}

export interface core_badges_get_user_badges_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_badges_get_user_badges_returns_badges_relatedbadges {
  id: number; // Badge id
  name: string; // Badge name
  version?: string; // Version
  language?: string; // Language
  type?: number; // Type
}

export interface core_badges_get_user_badges_returns_badges_alignment {
  id?: number; // Alignment id
  badgeid?: number; // Badge id
  targetName?: string; // Target name
  targetUrl?: string; // Target URL
  targetDescription?: string; // Target description
  targetFramework?: string; // Target framework
  targetCode?: string; // Target code
}

export interface core_badges_get_user_badges_returns_badges_endorsement {
  id: number; // Endorsement id
  badgeid: number; // Badge id
  issuername: string; // Endorsement issuer name
  issuerurl: string; // Endorsement issuer URL
  issueremail: string; // Endorsement issuer email
  claimid: string; // Claim URL
  claimcomment: string; // Claim comment
  dateissued: number; // Date issued
}

export interface core_badges_get_user_badges_returns_badges {
  id?: number; // Badge id
  name: string; // Badge name
  description: string; // Badge description
  timecreated?: number; // Time created
  timemodified?: number; // Time modified
  usercreated?: number; // User created
  usermodified?: number; // User modified
  issuername: string; // Issuer name
  issuerurl: string; // Issuer URL
  issuercontact: string; // Issuer contact
  expiredate?: number; // Expire date
  expireperiod?: number; // Expire period
  type?: number; // Type
  courseid?: number; // Course id
  message?: string; // Message
  messagesubject?: string; // Message subject
  attachment?: number; // Attachment
  notification?: number; // Whether to notify when badge is awarded
  nextcron?: number; // Next cron
  status?: number; // Status
  issuedid?: number; // Issued id
  uniquehash: string; // Unique hash
  dateissued: number; // Date issued
  dateexpire: number; // Date expire
  visible?: number; // Visible
  email?: string; // User email
  version?: string; // Version
  language?: string; // Language
  imageauthorname?: string; // Name of the image author
  imageauthoremail?: string; // Email of the image author
  imageauthorurl?: string; // URL of the image author
  imagecaption?: string; // Caption of the image
  badgeurl: string; // Badge URL
  endorsement?: core_badges_get_user_badges_returns_badges_endorsement;
  alignment: core_badges_get_user_badges_returns_badges_alignment[]; // Badge alignments
  relatedbadges: core_badges_get_user_badges_returns_badges_relatedbadges[]; // Related badges
}

export interface core_badges_get_user_badges_returns {
  badges: core_badges_get_user_badges_returns_badges[];
  warnings?: core_badges_get_user_badges_returns_warnings[]; // list of warnings
}

export interface core_blog_get_entries_parameters_filters {
  name: string; // The expected keys (value format) are: tag PARAM_NOTAGS blog tag tagid PARAM_INT blog tag id userid PARAM_INT blog author (userid) cmid PARAM_INT course module id entryid PARAM_INT entry id groupid PARAM_INT group id courseid PARAM_INT course id search PARAM_RAW search term
  value: string; // The value of the filter.
}

export interface core_blog_get_entries_parameters {
  filters?: core_blog_get_entries_parameters_filters[]; // default: array(0) { } // Parameters to filter blog listings.
  page?: number; // default: int(0) // The blog page to return.
  perpage?: number; // default: int(10) // The number of posts to return per page.
}

export interface core_blog_get_entries_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_blog_get_entries_returns_entries_tags {
  id: number; // Tag id.
  name: string; // Tag name.
  rawname: string; // The raw, unnormalised name for the tag as entered by users.
  isstandard: boolean; // Whether this tag is standard.
  tagcollid: number; // Tag collection id.
  taginstanceid: number; // Tag instance id.
  taginstancecontextid: number; // Context the tag instance belongs to.
  itemid: number; // Id of the record tagged.
  ordering: number; // Tag ordering.
  flag: number; // Whether the tag is flagged as inappropriate.
}

export interface core_blog_get_entries_returns_entries_attachmentfiles {
  filename?: string; // File name.
  filepath?: string; // File path.
  filesize?: number; // File size.
  fileurl?: string; // Downloadable file url.
  timemodified?: number; // Time modified.
  mimetype?: string; // File mime type.
  isexternalfile?: boolean; // Whether is an external file.
  repositorytype?: string; // The repository type for the external files.
}

export interface core_blog_get_entries_returns_entries_summaryfiles {
  filename?: string; // File name.
  filepath?: string; // File path.
  filesize?: number; // File size.
  fileurl?: string; // Downloadable file url.
  timemodified?: number; // Time modified.
  mimetype?: string; // File mime type.
  isexternalfile?: boolean; // Whether is an external file.
  repositorytype?: string; // The repository type for the external files.
}

export interface core_blog_get_entries_returns_entries {
  id: number; // Post/entry id.
  module: string; // Where it was published the post (blog, blog_external...).
  userid: number; // Post author.
  courseid: number; // Course where the post was created.
  groupid: number; // Group post was created for.
  moduleid: number; // Module id where the post was created (not used anymore).
  coursemoduleid: number; // Course module id where the post was created.
  subject: string; // Post subject.
  summary: string; // Post summary.
  summaryformat: number; // default: string(1) "1" // summary format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  content: string; // Post content.
  uniquehash: string; // Post unique hash.
  rating: number; // Post rating.
  format: number; // Post content format.
  attachment: string; // Post atachment.
  publishstate: string; // Post publish state.
  lastmodified: number; // When it was last modified.
  created: number; // When it was created.
  usermodified: number; // User that updated the post.
  summaryfiles: core_blog_get_entries_returns_entries_summaryfiles[]; // summaryfiles
  attachmentfiles?: core_blog_get_entries_returns_entries_attachmentfiles[]; // attachmentfiles
  tags?: core_blog_get_entries_returns_entries_tags[]; // Tags.
}

export interface core_blog_get_entries_returns {
  entries: core_blog_get_entries_returns_entries[];
  totalentries: number; // The total number of entries found.
  warnings?: core_blog_get_entries_returns_warnings[]; // list of warnings
}

export interface core_blog_view_entries_parameters_filters {
  name: string; // The expected keys (value format) are: tag PARAM_NOTAGS blog tag tagid PARAM_INT blog tag id userid PARAM_INT blog author (userid) cmid PARAM_INT course module id entryid PARAM_INT entry id groupid PARAM_INT group id courseid PARAM_INT course id search PARAM_RAW search term
  value: string; // The value of the filter.
}

export interface core_blog_view_entries_parameters {
  filters?: core_blog_view_entries_parameters_filters[]; // default: array(0) { } // Parameters used in the filter of view_entries.
}

export interface core_blog_view_entries_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_blog_view_entries_returns {
  status: boolean; // status: true if success
  warnings?: core_blog_view_entries_returns_warnings[]; // list of warnings
}

export interface core_calendar_get_calendar_monthly_view_parameters {
  year: number; // Year to be viewed
  month: number; // Month to be viewed
  courseid?: number; // default: string(1) "1" // Course being viewed
  categoryid?: number; // default: NULL // Category being viewed
  includenavigation?: boolean; // default: bool(true) // Whether to show course navigation
  mini?: boolean; // default: bool(false) // Whether to return the mini month view or not
  day?: number; // default: int(1) // Day to be viewed
  view?: string; // default: string(5) "month" // The view mode of the calendar
}

export interface core_calendar_get_calendar_monthly_view_returns_nextperiod {
  seconds: number; // seconds
  minutes: number; // minutes
  hours: number; // hours
  mday: number; // mday
  wday: number; // wday
  mon: number; // mon
  year: number; // year
  yday: number; // yday
  weekday: string; // weekday
  month: string; // month
  timestamp: number; // timestamp
}

export interface core_calendar_get_calendar_monthly_view_returns_previousperiod {
  seconds: number; // seconds
  minutes: number; // minutes
  hours: number; // hours
  mday: number; // mday
  wday: number; // wday
  mon: number; // mon
  year: number; // year
  yday: number; // yday
  weekday: string; // weekday
  month: string; // month
  timestamp: number; // timestamp
}

export interface core_calendar_get_calendar_monthly_view_returns_date {
  seconds: number; // seconds
  minutes: number; // minutes
  hours: number; // hours
  mday: number; // mday
  wday: number; // wday
  mon: number; // mon
  year: number; // year
  yday: number; // yday
  weekday: string; // weekday
  month: string; // month
  timestamp: number; // timestamp
}

export interface core_calendar_get_calendar_monthly_view_returns_daynames {
  dayno: number; // dayno
  shortname: string; // shortname
  fullname: string; // fullname
}

type core_calendar_get_calendar_monthly_view_returns_weeks_days_calendareventtypes = string;

export interface core_calendar_get_calendar_monthly_view_returns_weeks_days_events_action {
  name: string; // name
  url: string; // url
  itemcount: number; // itemcount
  actionable: boolean; // actionable
  showitemcount: boolean; // showitemcount
}

export interface core_calendar_get_calendar_monthly_view_returns_weeks_days_events_subscription {
  displayeventsource: boolean; // displayeventsource
  subscriptionname?: string; // subscriptionname
  subscriptionurl?: string; // subscriptionurl
}

export interface core_calendar_get_calendar_monthly_view_returns_weeks_days_events_course {
  id: number; // id
  fullname: string; // fullname
  shortname: string; // shortname
  idnumber: string; // idnumber
  summary: string; // summary
  summaryformat: number; // summary format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  startdate: number; // startdate
  enddate: number; // enddate
  visible: boolean; // visible
  showactivitydates: boolean; // showactivitydates
  showcompletionconditions: boolean; // showcompletionconditions
  fullnamedisplay: string; // fullnamedisplay
  viewurl: string; // viewurl
  courseimage: string; // courseimage
  progress?: number; // progress
  hasprogress: boolean; // hasprogress
  isfavourite: boolean; // isfavourite
  hidden: boolean; // hidden
  timeaccess?: number; // timeaccess
  showshortname: boolean; // showshortname
  coursecategory: string; // coursecategory
}

export interface core_calendar_get_calendar_monthly_view_returns_weeks_days_events_category {
  id: number; // id
  name: string; // name
  idnumber: string; // idnumber
  description?: string; // description
  parent: number; // parent
  coursecount: number; // coursecount
  visible: number; // visible
  timemodified: number; // timemodified
  depth: number; // depth
  nestedname: string; // nestedname
  url: string; // url
}

export interface core_calendar_get_calendar_monthly_view_returns_weeks_days_events_icon {
  key: string; // key
  component: string; // component
  alttext: string; // alttext
  iconurl: string; // iconurl
  iconclass: string; // iconclass
}

export interface core_calendar_get_calendar_monthly_view_returns_weeks_days_events {
  id: number; // id
  name: string; // name
  description?: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  location?: string; // location
  categoryid?: number; // categoryid
  groupid?: number; // groupid
  userid?: number; // userid
  repeatid?: number; // repeatid
  eventcount?: number; // eventcount
  component?: string; // component
  modulename?: string; // modulename
  activityname?: string; // activityname
  activitystr?: string; // activitystr
  instance?: number; // instance
  eventtype: string; // eventtype
  timestart: number; // timestart
  timeduration: number; // timeduration
  timesort: number; // timesort
  timeusermidnight: number; // timeusermidnight
  visible: number; // visible
  timemodified: number; // timemodified
  overdue?: boolean; // overdue
  icon: core_calendar_get_calendar_monthly_view_returns_weeks_days_events_icon;
  category?: core_calendar_get_calendar_monthly_view_returns_weeks_days_events_category;
  course?: core_calendar_get_calendar_monthly_view_returns_weeks_days_events_course;
  subscription?: core_calendar_get_calendar_monthly_view_returns_weeks_days_events_subscription;
  canedit: boolean; // canedit
  candelete: boolean; // candelete
  deleteurl: string; // deleteurl
  editurl: string; // editurl
  viewurl: string; // viewurl
  formattedtime: string; // formattedtime
  formattedlocation: string; // formattedlocation
  isactionevent: boolean; // isactionevent
  iscourseevent: boolean; // iscourseevent
  iscategoryevent: boolean; // iscategoryevent
  groupname?: string; // groupname
  normalisedeventtype: string; // normalisedeventtype
  normalisedeventtypetext: string; // normalisedeventtypetext
  action?: core_calendar_get_calendar_monthly_view_returns_weeks_days_events_action;
  purpose: string; // purpose
  url: string; // url
  islastday: boolean; // islastday
  popupname: string; // popupname
  mindaytimestamp?: number; // mindaytimestamp
  mindayerror?: string; // mindayerror
  maxdaytimestamp?: number; // maxdaytimestamp
  maxdayerror?: string; // maxdayerror
  draggable: boolean; // draggable
}

export interface core_calendar_get_calendar_monthly_view_returns_weeks_days {
  seconds: number; // seconds
  minutes: number; // minutes
  hours: number; // hours
  mday: number; // mday
  wday: number; // wday
  year: number; // year
  yday: number; // yday
  istoday: boolean; // istoday
  isweekend: boolean; // isweekend
  timestamp: number; // timestamp
  neweventtimestamp: number; // neweventtimestamp
  viewdaylink?: string; // viewdaylink
  viewdaylinktitle?: string; // viewdaylinktitle
  events: core_calendar_get_calendar_monthly_view_returns_weeks_days_events[]; // events
  hasevents: boolean; // hasevents
  calendareventtypes: core_calendar_get_calendar_monthly_view_returns_weeks_days_calendareventtypes[]; // calendareventtypes
  previousperiod: number; // previousperiod
  nextperiod: number; // nextperiod
  navigation: string; // navigation
  haslastdayofevent: boolean; // haslastdayofevent
  popovertitle: string; // popovertitle
  daytitle: string; // daytitle
}

type core_calendar_get_calendar_monthly_view_returns_weeks_postpadding = number;

type core_calendar_get_calendar_monthly_view_returns_weeks_prepadding = number;

export interface core_calendar_get_calendar_monthly_view_returns_weeks {
  prepadding: core_calendar_get_calendar_monthly_view_returns_weeks_prepadding[]; // prepadding
  postpadding: core_calendar_get_calendar_monthly_view_returns_weeks_postpadding[]; // postpadding
  days: core_calendar_get_calendar_monthly_view_returns_weeks_days[]; // days
}

export interface core_calendar_get_calendar_monthly_view_returns {
  url: string; // url
  courseid: number; // courseid
  categoryid?: number; // categoryid
  filter_selector?: string; // filter_selector
  weeks: core_calendar_get_calendar_monthly_view_returns_weeks[]; // weeks
  daynames: core_calendar_get_calendar_monthly_view_returns_daynames[]; // daynames
  view: string; // view
  date: core_calendar_get_calendar_monthly_view_returns_date;
  periodname: string; // periodname
  includenavigation: boolean; // includenavigation
  initialeventsloaded: boolean; // initialeventsloaded
  previousperiod: core_calendar_get_calendar_monthly_view_returns_previousperiod;
  previousperiodlink: string; // previousperiodlink
  previousperiodname: string; // previousperiodname
  nextperiod: core_calendar_get_calendar_monthly_view_returns_nextperiod;
  nextperiodname: string; // nextperiodname
  nextperiodlink: string; // nextperiodlink
  larrow: string; // larrow
  rarrow: string; // rarrow
  defaulteventcontext: number; // defaulteventcontext
  calendarinstanceid: number; // calendarinstanceid
  viewingmonth: boolean; // viewingmonth
  showviewselector: boolean; // showviewselector
  viewinginblock: boolean; // viewinginblock
}

export interface core_calendar_get_calendar_day_view_parameters {
  year: number; // Year to be viewed
  month: number; // Month to be viewed
  day: number; // Day to be viewed
  courseid?: number; // default: string(1) "1" // Course being viewed
  categoryid?: number; // default: NULL // Category being viewed
}

export interface core_calendar_get_calendar_day_view_returns_nextperiod {
  seconds: number; // seconds
  minutes: number; // minutes
  hours: number; // hours
  mday: number; // mday
  wday: number; // wday
  mon: number; // mon
  year: number; // year
  yday: number; // yday
  weekday: string; // weekday
  month: string; // month
  timestamp: number; // timestamp
}

export interface core_calendar_get_calendar_day_view_returns_previousperiod {
  seconds: number; // seconds
  minutes: number; // minutes
  hours: number; // hours
  mday: number; // mday
  wday: number; // wday
  mon: number; // mon
  year: number; // year
  yday: number; // yday
  weekday: string; // weekday
  month: string; // month
  timestamp: number; // timestamp
}

export interface core_calendar_get_calendar_day_view_returns_date {
  seconds: number; // seconds
  minutes: number; // minutes
  hours: number; // hours
  mday: number; // mday
  wday: number; // wday
  mon: number; // mon
  year: number; // year
  yday: number; // yday
  weekday: string; // weekday
  month: string; // month
  timestamp: number; // timestamp
}

export interface core_calendar_get_calendar_day_view_returns_events_action {
  name: string; // name
  url: string; // url
  itemcount: number; // itemcount
  actionable: boolean; // actionable
  showitemcount: boolean; // showitemcount
}

export interface core_calendar_get_calendar_day_view_returns_events_subscription {
  displayeventsource: boolean; // displayeventsource
  subscriptionname?: string; // subscriptionname
  subscriptionurl?: string; // subscriptionurl
}

export interface core_calendar_get_calendar_day_view_returns_events_course {
  id: number; // id
  fullname: string; // fullname
  shortname: string; // shortname
  idnumber: string; // idnumber
  summary: string; // summary
  summaryformat: number; // summary format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  startdate: number; // startdate
  enddate: number; // enddate
  visible: boolean; // visible
  showactivitydates: boolean; // showactivitydates
  showcompletionconditions: boolean; // showcompletionconditions
  fullnamedisplay: string; // fullnamedisplay
  viewurl: string; // viewurl
  courseimage: string; // courseimage
  progress?: number; // progress
  hasprogress: boolean; // hasprogress
  isfavourite: boolean; // isfavourite
  hidden: boolean; // hidden
  timeaccess?: number; // timeaccess
  showshortname: boolean; // showshortname
  coursecategory: string; // coursecategory
}

export interface core_calendar_get_calendar_day_view_returns_events_category {
  id: number; // id
  name: string; // name
  idnumber: string; // idnumber
  description?: string; // description
  parent: number; // parent
  coursecount: number; // coursecount
  visible: number; // visible
  timemodified: number; // timemodified
  depth: number; // depth
  nestedname: string; // nestedname
  url: string; // url
}

export interface core_calendar_get_calendar_day_view_returns_events_icon {
  key: string; // key
  component: string; // component
  alttext: string; // alttext
  iconurl: string; // iconurl
  iconclass: string; // iconclass
}

export interface core_calendar_get_calendar_day_view_returns_events {
  id: number; // id
  name: string; // name
  description?: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  location?: string; // location
  categoryid?: number; // categoryid
  groupid?: number; // groupid
  userid?: number; // userid
  repeatid?: number; // repeatid
  eventcount?: number; // eventcount
  component?: string; // component
  modulename?: string; // modulename
  activityname?: string; // activityname
  activitystr?: string; // activitystr
  instance?: number; // instance
  eventtype: string; // eventtype
  timestart: number; // timestart
  timeduration: number; // timeduration
  timesort: number; // timesort
  timeusermidnight: number; // timeusermidnight
  visible: number; // visible
  timemodified: number; // timemodified
  overdue?: boolean; // overdue
  icon: core_calendar_get_calendar_day_view_returns_events_icon;
  category?: core_calendar_get_calendar_day_view_returns_events_category;
  course?: core_calendar_get_calendar_day_view_returns_events_course;
  subscription?: core_calendar_get_calendar_day_view_returns_events_subscription;
  canedit: boolean; // canedit
  candelete: boolean; // candelete
  deleteurl: string; // deleteurl
  editurl: string; // editurl
  viewurl: string; // viewurl
  formattedtime: string; // formattedtime
  formattedlocation: string; // formattedlocation
  isactionevent: boolean; // isactionevent
  iscourseevent: boolean; // iscourseevent
  iscategoryevent: boolean; // iscategoryevent
  groupname?: string; // groupname
  normalisedeventtype: string; // normalisedeventtype
  normalisedeventtypetext: string; // normalisedeventtypetext
  action?: core_calendar_get_calendar_day_view_returns_events_action;
  purpose: string; // purpose
  url: string; // url
  islastday: boolean; // islastday
  popupname: string; // popupname
  mindaytimestamp?: number; // mindaytimestamp
  mindayerror?: string; // mindayerror
  maxdaytimestamp?: number; // maxdaytimestamp
  maxdayerror?: string; // maxdayerror
  draggable: boolean; // draggable
}

export interface core_calendar_get_calendar_day_view_returns {
  events: core_calendar_get_calendar_day_view_returns_events[]; // events
  defaulteventcontext: number; // defaulteventcontext
  filter_selector: string; // filter_selector
  courseid: number; // courseid
  categoryid?: number; // categoryid
  neweventtimestamp: number; // neweventtimestamp
  date: core_calendar_get_calendar_day_view_returns_date;
  periodname: string; // periodname
  previousperiod: core_calendar_get_calendar_day_view_returns_previousperiod;
  previousperiodlink: string; // previousperiodlink
  previousperiodname: string; // previousperiodname
  nextperiod: core_calendar_get_calendar_day_view_returns_nextperiod;
  nextperiodname: string; // nextperiodname
  nextperiodlink: string; // nextperiodlink
  larrow: string; // larrow
  rarrow: string; // rarrow
}

export interface core_calendar_get_calendar_upcoming_view_parameters {
  courseid?: number; // default: string(1) "1" // Course being viewed
  categoryid?: number; // default: NULL // Category being viewed
}

export interface core_calendar_get_calendar_upcoming_view_returns_date {
  seconds: number; // seconds
  minutes: number; // minutes
  hours: number; // hours
  mday: number; // mday
  wday: number; // wday
  mon: number; // mon
  year: number; // year
  yday: number; // yday
  weekday: string; // weekday
  month: string; // month
  timestamp: number; // timestamp
}

export interface core_calendar_get_calendar_upcoming_view_returns_events_action {
  name: string; // name
  url: string; // url
  itemcount: number; // itemcount
  actionable: boolean; // actionable
  showitemcount: boolean; // showitemcount
}

export interface core_calendar_get_calendar_upcoming_view_returns_events_subscription {
  displayeventsource: boolean; // displayeventsource
  subscriptionname?: string; // subscriptionname
  subscriptionurl?: string; // subscriptionurl
}

export interface core_calendar_get_calendar_upcoming_view_returns_events_course {
  id: number; // id
  fullname: string; // fullname
  shortname: string; // shortname
  idnumber: string; // idnumber
  summary: string; // summary
  summaryformat: number; // summary format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  startdate: number; // startdate
  enddate: number; // enddate
  visible: boolean; // visible
  showactivitydates: boolean; // showactivitydates
  showcompletionconditions: boolean; // showcompletionconditions
  fullnamedisplay: string; // fullnamedisplay
  viewurl: string; // viewurl
  courseimage: string; // courseimage
  progress?: number; // progress
  hasprogress: boolean; // hasprogress
  isfavourite: boolean; // isfavourite
  hidden: boolean; // hidden
  timeaccess?: number; // timeaccess
  showshortname: boolean; // showshortname
  coursecategory: string; // coursecategory
}

export interface core_calendar_get_calendar_upcoming_view_returns_events_category {
  id: number; // id
  name: string; // name
  idnumber: string; // idnumber
  description?: string; // description
  parent: number; // parent
  coursecount: number; // coursecount
  visible: number; // visible
  timemodified: number; // timemodified
  depth: number; // depth
  nestedname: string; // nestedname
  url: string; // url
}

export interface core_calendar_get_calendar_upcoming_view_returns_events_icon {
  key: string; // key
  component: string; // component
  alttext: string; // alttext
  iconurl: string; // iconurl
  iconclass: string; // iconclass
}

export interface core_calendar_get_calendar_upcoming_view_returns_events {
  id: number; // id
  name: string; // name
  description?: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  location?: string; // location
  categoryid?: number; // categoryid
  groupid?: number; // groupid
  userid?: number; // userid
  repeatid?: number; // repeatid
  eventcount?: number; // eventcount
  component?: string; // component
  modulename?: string; // modulename
  activityname?: string; // activityname
  activitystr?: string; // activitystr
  instance?: number; // instance
  eventtype: string; // eventtype
  timestart: number; // timestart
  timeduration: number; // timeduration
  timesort: number; // timesort
  timeusermidnight: number; // timeusermidnight
  visible: number; // visible
  timemodified: number; // timemodified
  overdue?: boolean; // overdue
  icon: core_calendar_get_calendar_upcoming_view_returns_events_icon;
  category?: core_calendar_get_calendar_upcoming_view_returns_events_category;
  course?: core_calendar_get_calendar_upcoming_view_returns_events_course;
  subscription?: core_calendar_get_calendar_upcoming_view_returns_events_subscription;
  canedit: boolean; // canedit
  candelete: boolean; // candelete
  deleteurl: string; // deleteurl
  editurl: string; // editurl
  viewurl: string; // viewurl
  formattedtime: string; // formattedtime
  formattedlocation: string; // formattedlocation
  isactionevent: boolean; // isactionevent
  iscourseevent: boolean; // iscourseevent
  iscategoryevent: boolean; // iscategoryevent
  groupname?: string; // groupname
  normalisedeventtype: string; // normalisedeventtype
  normalisedeventtypetext: string; // normalisedeventtypetext
  action?: core_calendar_get_calendar_upcoming_view_returns_events_action;
  purpose: string; // purpose
  url: string; // url
  islastday: boolean; // islastday
  popupname: string; // popupname
  mindaytimestamp?: number; // mindaytimestamp
  mindayerror?: string; // mindayerror
  maxdaytimestamp?: number; // maxdaytimestamp
  maxdayerror?: string; // maxdayerror
  draggable: boolean; // draggable
}

export interface core_calendar_get_calendar_upcoming_view_returns {
  events: core_calendar_get_calendar_upcoming_view_returns_events[]; // events
  defaulteventcontext: number; // defaulteventcontext
  filter_selector: string; // filter_selector
  courseid: number; // courseid
  categoryid?: number; // categoryid
  isloggedin: boolean; // isloggedin
  date: core_calendar_get_calendar_upcoming_view_returns_date;
}

export interface core_calendar_update_event_start_day_parameters {
  eventid: number; // Id of event to be updated
  daytimestamp: number; // Timestamp for the new start day
}

export interface core_calendar_update_event_start_day_returns_event_action {
  name: string; // name
  url: string; // url
  itemcount: number; // itemcount
  actionable: boolean; // actionable
  showitemcount: boolean; // showitemcount
}

export interface core_calendar_update_event_start_day_returns_event_subscription {
  displayeventsource: boolean; // displayeventsource
  subscriptionname?: string; // subscriptionname
  subscriptionurl?: string; // subscriptionurl
}

export interface core_calendar_update_event_start_day_returns_event_course {
  id: number; // id
  fullname: string; // fullname
  shortname: string; // shortname
  idnumber: string; // idnumber
  summary: string; // summary
  summaryformat: number; // summary format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  startdate: number; // startdate
  enddate: number; // enddate
  visible: boolean; // visible
  showactivitydates: boolean; // showactivitydates
  showcompletionconditions: boolean; // showcompletionconditions
  fullnamedisplay: string; // fullnamedisplay
  viewurl: string; // viewurl
  courseimage: string; // courseimage
  progress?: number; // progress
  hasprogress: boolean; // hasprogress
  isfavourite: boolean; // isfavourite
  hidden: boolean; // hidden
  timeaccess?: number; // timeaccess
  showshortname: boolean; // showshortname
  coursecategory: string; // coursecategory
}

export interface core_calendar_update_event_start_day_returns_event_category {
  id: number; // id
  name: string; // name
  idnumber: string; // idnumber
  description?: string; // description
  parent: number; // parent
  coursecount: number; // coursecount
  visible: number; // visible
  timemodified: number; // timemodified
  depth: number; // depth
  nestedname: string; // nestedname
  url: string; // url
}

export interface core_calendar_update_event_start_day_returns_event_icon {
  key: string; // key
  component: string; // component
  alttext: string; // alttext
  iconurl: string; // iconurl
  iconclass: string; // iconclass
}

export interface core_calendar_update_event_start_day_returns_event {
  id: number; // id
  name: string; // name
  description?: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  location?: string; // location
  categoryid?: number; // categoryid
  groupid?: number; // groupid
  userid?: number; // userid
  repeatid?: number; // repeatid
  eventcount?: number; // eventcount
  component?: string; // component
  modulename?: string; // modulename
  activityname?: string; // activityname
  activitystr?: string; // activitystr
  instance?: number; // instance
  eventtype: string; // eventtype
  timestart: number; // timestart
  timeduration: number; // timeduration
  timesort: number; // timesort
  timeusermidnight: number; // timeusermidnight
  visible: number; // visible
  timemodified: number; // timemodified
  overdue?: boolean; // overdue
  icon: core_calendar_update_event_start_day_returns_event_icon;
  category?: core_calendar_update_event_start_day_returns_event_category;
  course?: core_calendar_update_event_start_day_returns_event_course;
  subscription?: core_calendar_update_event_start_day_returns_event_subscription;
  canedit: boolean; // canedit
  candelete: boolean; // candelete
  deleteurl: string; // deleteurl
  editurl: string; // editurl
  viewurl: string; // viewurl
  formattedtime: string; // formattedtime
  formattedlocation: string; // formattedlocation
  isactionevent: boolean; // isactionevent
  iscourseevent: boolean; // iscourseevent
  iscategoryevent: boolean; // iscategoryevent
  groupname?: string; // groupname
  normalisedeventtype: string; // normalisedeventtype
  normalisedeventtypetext: string; // normalisedeventtypetext
  action?: core_calendar_update_event_start_day_returns_event_action;
  purpose: string; // purpose
  url: string; // url
}

export interface core_calendar_update_event_start_day_returns {
  event: core_calendar_update_event_start_day_returns_event;
}

export interface core_calendar_create_calendar_events_parameters_events {
  name: string; // event name
  description?: string; // default: NULL // Description
  format?: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  courseid?: number; // default: int(0) // course id
  groupid?: number; // default: int(0) // group id
  repeats?: number; // default: int(0) // number of repeats
  eventtype?: string; // default: string(4) "user" // Event type
  timestart?: number; // default: time() // timestart
  timeduration?: number; // default: int(0) // time duration
  visible?: number; // default: int(1) // visible
  sequence?: number; // default: int(1) // sequence
}

export interface core_calendar_create_calendar_events_parameters {
  events: core_calendar_create_calendar_events_parameters_events[];
}

export interface core_calendar_create_calendar_events_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_calendar_create_calendar_events_returns_events {
  id: number; // event id
  name: string; // event name
  description?: string; // Description
  format: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  courseid: number; // course id
  groupid: number; // group id
  userid: number; // user id
  repeatid?: number; // repeat id
  modulename?: string; // module name
  instance: number; // instance id
  eventtype: string; // Event type
  timestart: number; // timestart
  timeduration: number; // time duration
  visible: number; // visible
  uuid?: string; // unique id of ical events
  sequence: number; // sequence
  timemodified: number; // time modified
  subscriptionid?: number; // Subscription id
}

export interface core_calendar_create_calendar_events_returns {
  events: core_calendar_create_calendar_events_returns_events[];
  warnings?: core_calendar_create_calendar_events_returns_warnings[]; // list of warnings
}

export interface core_calendar_delete_calendar_events_parameters_events {
  eventid: number; // Event ID
  repeat: boolean; // Delete comeplete series if repeated event
}

export interface core_calendar_delete_calendar_events_parameters {
  events: core_calendar_delete_calendar_events_parameters_events[];
}

type core_calendar_delete_calendar_events_returns = null;

export interface core_calendar_get_calendar_events_parameters_options {
  userevents?: boolean; // default: bool(true) // Set to true to return current user's user events
  siteevents?: boolean; // default: bool(true) // Set to true to return site events
  timestart?: number; // default: int(0) // Time from which events should be returned
  timeend?: number; // default: int(0) // Time to which the events should be returned. We treat 0 and null as no end
  ignorehidden?: boolean; // default: bool(true) // Ignore hidden events or not
}

type core_calendar_get_calendar_events_parameters_events_categoryids = number;

type core_calendar_get_calendar_events_parameters_events_groupids = number;

type core_calendar_get_calendar_events_parameters_events_courseids = number;

type core_calendar_get_calendar_events_parameters_events_eventids = number;

export interface core_calendar_get_calendar_events_parameters_events {
  eventids?: core_calendar_get_calendar_events_parameters_events_eventids[]; // default: array(0) { } // List of event ids
  courseids?: core_calendar_get_calendar_events_parameters_events_courseids[]; // default: array(0) { } // List of course ids for which events will be returned
  groupids?: core_calendar_get_calendar_events_parameters_events_groupids[]; // default: array(0) { } // List of group ids for which events should be returned
  categoryids?: core_calendar_get_calendar_events_parameters_events_categoryids[]; // default: array(0) { } // List of category ids for which events will be returned
}

export interface core_calendar_get_calendar_events_parameters {
  events?: core_calendar_get_calendar_events_parameters_events; // default: array(0) { } // Event details
  options?: core_calendar_get_calendar_events_parameters_options; // default: array(0) { } // Options
}

export interface core_calendar_get_calendar_events_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_calendar_get_calendar_events_returns_events {
  id: number; // event id
  name: string; // event name
  description?: string; // Description
  format: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  courseid: number; // course id
  categoryid?: number; // Category id (only for category events).
  groupid: number; // group id
  userid: number; // user id
  repeatid: number; // repeat id
  modulename?: string; // module name
  instance: number; // instance id
  eventtype: string; // Event type
  timestart: number; // timestart
  timeduration: number; // time duration
  visible: number; // visible
  uuid?: string; // unique id of ical events
  sequence: number; // sequence
  timemodified: number; // time modified
  subscriptionid?: number; // Subscription id
}

export interface core_calendar_get_calendar_events_returns {
  events: core_calendar_get_calendar_events_returns_events[];
  warnings?: core_calendar_get_calendar_events_returns_warnings[]; // list of warnings
}

export interface core_calendar_get_action_events_by_timesort_parameters {
  timesortfrom?: number; // default: int(0) // Time sort from
  timesortto?: number; // default: NULL // Time sort to
  aftereventid?: number; // default: int(0) // The last seen event id
  limitnum?: number; // default: int(20) // Limit number
  limittononsuspendedevents?: boolean; // default: bool(false) // Limit the events to courses the user is not suspended in
  userid?: number; // default: NULL // The user id
  searchvalue?: string; // default: NULL // The value a user wishes to search against
}

export interface core_calendar_get_action_events_by_timesort_returns_events_action {
  name: string; // name
  url: string; // url
  itemcount: number; // itemcount
  actionable: boolean; // actionable
  showitemcount: boolean; // showitemcount
}

export interface core_calendar_get_action_events_by_timesort_returns_events_subscription {
  displayeventsource: boolean; // displayeventsource
  subscriptionname?: string; // subscriptionname
  subscriptionurl?: string; // subscriptionurl
}

export interface core_calendar_get_action_events_by_timesort_returns_events_course {
  id: number; // id
  fullname: string; // fullname
  shortname: string; // shortname
  idnumber: string; // idnumber
  summary: string; // summary
  summaryformat: number; // summary format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  startdate: number; // startdate
  enddate: number; // enddate
  visible: boolean; // visible
  showactivitydates: boolean; // showactivitydates
  showcompletionconditions: boolean; // showcompletionconditions
  fullnamedisplay: string; // fullnamedisplay
  viewurl: string; // viewurl
  courseimage: string; // courseimage
  progress?: number; // progress
  hasprogress: boolean; // hasprogress
  isfavourite: boolean; // isfavourite
  hidden: boolean; // hidden
  timeaccess?: number; // timeaccess
  showshortname: boolean; // showshortname
  coursecategory: string; // coursecategory
}

export interface core_calendar_get_action_events_by_timesort_returns_events_category {
  id: number; // id
  name: string; // name
  idnumber: string; // idnumber
  description?: string; // description
  parent: number; // parent
  coursecount: number; // coursecount
  visible: number; // visible
  timemodified: number; // timemodified
  depth: number; // depth
  nestedname: string; // nestedname
  url: string; // url
}

export interface core_calendar_get_action_events_by_timesort_returns_events_icon {
  key: string; // key
  component: string; // component
  alttext: string; // alttext
  iconurl: string; // iconurl
  iconclass: string; // iconclass
}

export interface core_calendar_get_action_events_by_timesort_returns_events {
  id: number; // id
  name: string; // name
  description?: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  location?: string; // location
  categoryid?: number; // categoryid
  groupid?: number; // groupid
  userid?: number; // userid
  repeatid?: number; // repeatid
  eventcount?: number; // eventcount
  component?: string; // component
  modulename?: string; // modulename
  activityname?: string; // activityname
  activitystr?: string; // activitystr
  instance?: number; // instance
  eventtype: string; // eventtype
  timestart: number; // timestart
  timeduration: number; // timeduration
  timesort: number; // timesort
  timeusermidnight: number; // timeusermidnight
  visible: number; // visible
  timemodified: number; // timemodified
  overdue?: boolean; // overdue
  icon: core_calendar_get_action_events_by_timesort_returns_events_icon;
  category?: core_calendar_get_action_events_by_timesort_returns_events_category;
  course?: core_calendar_get_action_events_by_timesort_returns_events_course;
  subscription?: core_calendar_get_action_events_by_timesort_returns_events_subscription;
  canedit: boolean; // canedit
  candelete: boolean; // candelete
  deleteurl: string; // deleteurl
  editurl: string; // editurl
  viewurl: string; // viewurl
  formattedtime: string; // formattedtime
  formattedlocation: string; // formattedlocation
  isactionevent: boolean; // isactionevent
  iscourseevent: boolean; // iscourseevent
  iscategoryevent: boolean; // iscategoryevent
  groupname?: string; // groupname
  normalisedeventtype: string; // normalisedeventtype
  normalisedeventtypetext: string; // normalisedeventtypetext
  action?: core_calendar_get_action_events_by_timesort_returns_events_action;
  purpose: string; // purpose
  url: string; // url
}

export interface core_calendar_get_action_events_by_timesort_returns {
  events: core_calendar_get_action_events_by_timesort_returns_events[]; // events
  firstid: number; // firstid
  lastid: number; // lastid
}

export interface core_calendar_get_action_events_by_course_parameters {
  courseid: number; // Course id
  timesortfrom?: number; // default: NULL // Time sort from
  timesortto?: number; // default: NULL // Time sort to
  aftereventid?: number; // default: int(0) // The last seen event id
  limitnum?: number; // default: int(20) // Limit number
  searchvalue?: string; // default: NULL // The value a user wishes to search against
}

export interface core_calendar_get_action_events_by_course_returns_events_action {
  name: string; // name
  url: string; // url
  itemcount: number; // itemcount
  actionable: boolean; // actionable
  showitemcount: boolean; // showitemcount
}

export interface core_calendar_get_action_events_by_course_returns_events_subscription {
  displayeventsource: boolean; // displayeventsource
  subscriptionname?: string; // subscriptionname
  subscriptionurl?: string; // subscriptionurl
}

export interface core_calendar_get_action_events_by_course_returns_events_course {
  id: number; // id
  fullname: string; // fullname
  shortname: string; // shortname
  idnumber: string; // idnumber
  summary: string; // summary
  summaryformat: number; // summary format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  startdate: number; // startdate
  enddate: number; // enddate
  visible: boolean; // visible
  showactivitydates: boolean; // showactivitydates
  showcompletionconditions: boolean; // showcompletionconditions
  fullnamedisplay: string; // fullnamedisplay
  viewurl: string; // viewurl
  courseimage: string; // courseimage
  progress?: number; // progress
  hasprogress: boolean; // hasprogress
  isfavourite: boolean; // isfavourite
  hidden: boolean; // hidden
  timeaccess?: number; // timeaccess
  showshortname: boolean; // showshortname
  coursecategory: string; // coursecategory
}

export interface core_calendar_get_action_events_by_course_returns_events_category {
  id: number; // id
  name: string; // name
  idnumber: string; // idnumber
  description?: string; // description
  parent: number; // parent
  coursecount: number; // coursecount
  visible: number; // visible
  timemodified: number; // timemodified
  depth: number; // depth
  nestedname: string; // nestedname
  url: string; // url
}

export interface core_calendar_get_action_events_by_course_returns_events_icon {
  key: string; // key
  component: string; // component
  alttext: string; // alttext
  iconurl: string; // iconurl
  iconclass: string; // iconclass
}

export interface core_calendar_get_action_events_by_course_returns_events {
  id: number; // id
  name: string; // name
  description?: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  location?: string; // location
  categoryid?: number; // categoryid
  groupid?: number; // groupid
  userid?: number; // userid
  repeatid?: number; // repeatid
  eventcount?: number; // eventcount
  component?: string; // component
  modulename?: string; // modulename
  activityname?: string; // activityname
  activitystr?: string; // activitystr
  instance?: number; // instance
  eventtype: string; // eventtype
  timestart: number; // timestart
  timeduration: number; // timeduration
  timesort: number; // timesort
  timeusermidnight: number; // timeusermidnight
  visible: number; // visible
  timemodified: number; // timemodified
  overdue?: boolean; // overdue
  icon: core_calendar_get_action_events_by_course_returns_events_icon;
  category?: core_calendar_get_action_events_by_course_returns_events_category;
  course?: core_calendar_get_action_events_by_course_returns_events_course;
  subscription?: core_calendar_get_action_events_by_course_returns_events_subscription;
  canedit: boolean; // canedit
  candelete: boolean; // candelete
  deleteurl: string; // deleteurl
  editurl: string; // editurl
  viewurl: string; // viewurl
  formattedtime: string; // formattedtime
  formattedlocation: string; // formattedlocation
  isactionevent: boolean; // isactionevent
  iscourseevent: boolean; // iscourseevent
  iscategoryevent: boolean; // iscategoryevent
  groupname?: string; // groupname
  normalisedeventtype: string; // normalisedeventtype
  normalisedeventtypetext: string; // normalisedeventtypetext
  action?: core_calendar_get_action_events_by_course_returns_events_action;
  purpose: string; // purpose
  url: string; // url
}

export interface core_calendar_get_action_events_by_course_returns {
  events: core_calendar_get_action_events_by_course_returns_events[]; // events
  firstid: number; // firstid
  lastid: number; // lastid
}

type core_calendar_get_action_events_by_courses_parameters_courseids = number;

export interface core_calendar_get_action_events_by_courses_parameters {
  courseids: core_calendar_get_action_events_by_courses_parameters_courseids[];
  timesortfrom?: number; // default: NULL // Time sort from
  timesortto?: number; // default: NULL // Time sort to
  limitnum?: number; // default: int(10) // Limit number
  searchvalue?: string; // default: NULL // The value a user wishes to search against
}

export interface core_calendar_get_action_events_by_courses_returns_groupedbycourse_events_action {
  name: string; // name
  url: string; // url
  itemcount: number; // itemcount
  actionable: boolean; // actionable
  showitemcount: boolean; // showitemcount
}

export interface core_calendar_get_action_events_by_courses_returns_groupedbycourse_events_subscription {
  displayeventsource: boolean; // displayeventsource
  subscriptionname?: string; // subscriptionname
  subscriptionurl?: string; // subscriptionurl
}

export interface core_calendar_get_action_events_by_courses_returns_groupedbycourse_events_course {
  id: number; // id
  fullname: string; // fullname
  shortname: string; // shortname
  idnumber: string; // idnumber
  summary: string; // summary
  summaryformat: number; // summary format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  startdate: number; // startdate
  enddate: number; // enddate
  visible: boolean; // visible
  showactivitydates: boolean; // showactivitydates
  showcompletionconditions: boolean; // showcompletionconditions
  fullnamedisplay: string; // fullnamedisplay
  viewurl: string; // viewurl
  courseimage: string; // courseimage
  progress?: number; // progress
  hasprogress: boolean; // hasprogress
  isfavourite: boolean; // isfavourite
  hidden: boolean; // hidden
  timeaccess?: number; // timeaccess
  showshortname: boolean; // showshortname
  coursecategory: string; // coursecategory
}

export interface core_calendar_get_action_events_by_courses_returns_groupedbycourse_events_category {
  id: number; // id
  name: string; // name
  idnumber: string; // idnumber
  description?: string; // description
  parent: number; // parent
  coursecount: number; // coursecount
  visible: number; // visible
  timemodified: number; // timemodified
  depth: number; // depth
  nestedname: string; // nestedname
  url: string; // url
}

export interface core_calendar_get_action_events_by_courses_returns_groupedbycourse_events_icon {
  key: string; // key
  component: string; // component
  alttext: string; // alttext
  iconurl: string; // iconurl
  iconclass: string; // iconclass
}

export interface core_calendar_get_action_events_by_courses_returns_groupedbycourse_events {
  id: number; // id
  name: string; // name
  description?: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  location?: string; // location
  categoryid?: number; // categoryid
  groupid?: number; // groupid
  userid?: number; // userid
  repeatid?: number; // repeatid
  eventcount?: number; // eventcount
  component?: string; // component
  modulename?: string; // modulename
  activityname?: string; // activityname
  activitystr?: string; // activitystr
  instance?: number; // instance
  eventtype: string; // eventtype
  timestart: number; // timestart
  timeduration: number; // timeduration
  timesort: number; // timesort
  timeusermidnight: number; // timeusermidnight
  visible: number; // visible
  timemodified: number; // timemodified
  overdue?: boolean; // overdue
  icon: core_calendar_get_action_events_by_courses_returns_groupedbycourse_events_icon;
  category?: core_calendar_get_action_events_by_courses_returns_groupedbycourse_events_category;
  course?: core_calendar_get_action_events_by_courses_returns_groupedbycourse_events_course;
  subscription?: core_calendar_get_action_events_by_courses_returns_groupedbycourse_events_subscription;
  canedit: boolean; // canedit
  candelete: boolean; // candelete
  deleteurl: string; // deleteurl
  editurl: string; // editurl
  viewurl: string; // viewurl
  formattedtime: string; // formattedtime
  formattedlocation: string; // formattedlocation
  isactionevent: boolean; // isactionevent
  iscourseevent: boolean; // iscourseevent
  iscategoryevent: boolean; // iscategoryevent
  groupname?: string; // groupname
  normalisedeventtype: string; // normalisedeventtype
  normalisedeventtypetext: string; // normalisedeventtypetext
  action?: core_calendar_get_action_events_by_courses_returns_groupedbycourse_events_action;
  purpose: string; // purpose
  url: string; // url
}

export interface core_calendar_get_action_events_by_courses_returns_groupedbycourse {
  events: core_calendar_get_action_events_by_courses_returns_groupedbycourse_events[]; // events
  firstid: number; // firstid
  lastid: number; // lastid
  courseid: number; // courseid
}

export interface core_calendar_get_action_events_by_courses_returns {
  groupedbycourse: core_calendar_get_action_events_by_courses_returns_groupedbycourse[]; // groupedbycourse
}

export interface core_calendar_get_calendar_event_by_id_parameters {
  eventid: number; // The event id to be retrieved
}

export interface core_calendar_get_calendar_event_by_id_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_calendar_get_calendar_event_by_id_returns_event_action {
  name: string; // name
  url: string; // url
  itemcount: number; // itemcount
  actionable: boolean; // actionable
  showitemcount: boolean; // showitemcount
}

export interface core_calendar_get_calendar_event_by_id_returns_event_subscription {
  displayeventsource: boolean; // displayeventsource
  subscriptionname?: string; // subscriptionname
  subscriptionurl?: string; // subscriptionurl
}

export interface core_calendar_get_calendar_event_by_id_returns_event_course {
  id: number; // id
  fullname: string; // fullname
  shortname: string; // shortname
  idnumber: string; // idnumber
  summary: string; // summary
  summaryformat: number; // summary format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  startdate: number; // startdate
  enddate: number; // enddate
  visible: boolean; // visible
  showactivitydates: boolean; // showactivitydates
  showcompletionconditions: boolean; // showcompletionconditions
  fullnamedisplay: string; // fullnamedisplay
  viewurl: string; // viewurl
  courseimage: string; // courseimage
  progress?: number; // progress
  hasprogress: boolean; // hasprogress
  isfavourite: boolean; // isfavourite
  hidden: boolean; // hidden
  timeaccess?: number; // timeaccess
  showshortname: boolean; // showshortname
  coursecategory: string; // coursecategory
}

export interface core_calendar_get_calendar_event_by_id_returns_event_category {
  id: number; // id
  name: string; // name
  idnumber: string; // idnumber
  description?: string; // description
  parent: number; // parent
  coursecount: number; // coursecount
  visible: number; // visible
  timemodified: number; // timemodified
  depth: number; // depth
  nestedname: string; // nestedname
  url: string; // url
}

export interface core_calendar_get_calendar_event_by_id_returns_event_icon {
  key: string; // key
  component: string; // component
  alttext: string; // alttext
  iconurl: string; // iconurl
  iconclass: string; // iconclass
}

export interface core_calendar_get_calendar_event_by_id_returns_event {
  id: number; // id
  name: string; // name
  description?: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  location?: string; // location
  categoryid?: number; // categoryid
  groupid?: number; // groupid
  userid?: number; // userid
  repeatid?: number; // repeatid
  eventcount?: number; // eventcount
  component?: string; // component
  modulename?: string; // modulename
  activityname?: string; // activityname
  activitystr?: string; // activitystr
  instance?: number; // instance
  eventtype: string; // eventtype
  timestart: number; // timestart
  timeduration: number; // timeduration
  timesort: number; // timesort
  timeusermidnight: number; // timeusermidnight
  visible: number; // visible
  timemodified: number; // timemodified
  overdue?: boolean; // overdue
  icon: core_calendar_get_calendar_event_by_id_returns_event_icon;
  category?: core_calendar_get_calendar_event_by_id_returns_event_category;
  course?: core_calendar_get_calendar_event_by_id_returns_event_course;
  subscription?: core_calendar_get_calendar_event_by_id_returns_event_subscription;
  canedit: boolean; // canedit
  candelete: boolean; // candelete
  deleteurl: string; // deleteurl
  editurl: string; // editurl
  viewurl: string; // viewurl
  formattedtime: string; // formattedtime
  formattedlocation: string; // formattedlocation
  isactionevent: boolean; // isactionevent
  iscourseevent: boolean; // iscourseevent
  iscategoryevent: boolean; // iscategoryevent
  groupname?: string; // groupname
  normalisedeventtype: string; // normalisedeventtype
  normalisedeventtypetext: string; // normalisedeventtypetext
  action?: core_calendar_get_calendar_event_by_id_returns_event_action;
  purpose: string; // purpose
  url: string; // url
}

export interface core_calendar_get_calendar_event_by_id_returns {
  event: core_calendar_get_calendar_event_by_id_returns_event;
  warnings?: core_calendar_get_calendar_event_by_id_returns_warnings[]; // list of warnings
}

export interface core_calendar_submit_create_update_form_parameters {
  formdata: string; // The data from the event form
}

export interface core_calendar_submit_create_update_form_returns_event_action {
  name: string; // name
  url: string; // url
  itemcount: number; // itemcount
  actionable: boolean; // actionable
  showitemcount: boolean; // showitemcount
}

export interface core_calendar_submit_create_update_form_returns_event_subscription {
  displayeventsource: boolean; // displayeventsource
  subscriptionname?: string; // subscriptionname
  subscriptionurl?: string; // subscriptionurl
}

export interface core_calendar_submit_create_update_form_returns_event_course {
  id: number; // id
  fullname: string; // fullname
  shortname: string; // shortname
  idnumber: string; // idnumber
  summary: string; // summary
  summaryformat: number; // summary format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  startdate: number; // startdate
  enddate: number; // enddate
  visible: boolean; // visible
  showactivitydates: boolean; // showactivitydates
  showcompletionconditions: boolean; // showcompletionconditions
  fullnamedisplay: string; // fullnamedisplay
  viewurl: string; // viewurl
  courseimage: string; // courseimage
  progress?: number; // progress
  hasprogress: boolean; // hasprogress
  isfavourite: boolean; // isfavourite
  hidden: boolean; // hidden
  timeaccess?: number; // timeaccess
  showshortname: boolean; // showshortname
  coursecategory: string; // coursecategory
}

export interface core_calendar_submit_create_update_form_returns_event_category {
  id: number; // id
  name: string; // name
  idnumber: string; // idnumber
  description?: string; // description
  parent: number; // parent
  coursecount: number; // coursecount
  visible: number; // visible
  timemodified: number; // timemodified
  depth: number; // depth
  nestedname: string; // nestedname
  url: string; // url
}

export interface core_calendar_submit_create_update_form_returns_event_icon {
  key: string; // key
  component: string; // component
  alttext: string; // alttext
  iconurl: string; // iconurl
  iconclass: string; // iconclass
}

export interface core_calendar_submit_create_update_form_returns_event {
  id: number; // id
  name: string; // name
  description?: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  location?: string; // location
  categoryid?: number; // categoryid
  groupid?: number; // groupid
  userid?: number; // userid
  repeatid?: number; // repeatid
  eventcount?: number; // eventcount
  component?: string; // component
  modulename?: string; // modulename
  activityname?: string; // activityname
  activitystr?: string; // activitystr
  instance?: number; // instance
  eventtype: string; // eventtype
  timestart: number; // timestart
  timeduration: number; // timeduration
  timesort: number; // timesort
  timeusermidnight: number; // timeusermidnight
  visible: number; // visible
  timemodified: number; // timemodified
  overdue?: boolean; // overdue
  icon: core_calendar_submit_create_update_form_returns_event_icon;
  category?: core_calendar_submit_create_update_form_returns_event_category;
  course?: core_calendar_submit_create_update_form_returns_event_course;
  subscription?: core_calendar_submit_create_update_form_returns_event_subscription;
  canedit: boolean; // canedit
  candelete: boolean; // candelete
  deleteurl: string; // deleteurl
  editurl: string; // editurl
  viewurl: string; // viewurl
  formattedtime: string; // formattedtime
  formattedlocation: string; // formattedlocation
  isactionevent: boolean; // isactionevent
  iscourseevent: boolean; // iscourseevent
  iscategoryevent: boolean; // iscategoryevent
  groupname?: string; // groupname
  normalisedeventtype: string; // normalisedeventtype
  normalisedeventtypetext: string; // normalisedeventtypetext
  action?: core_calendar_submit_create_update_form_returns_event_action;
  purpose: string; // purpose
  url: string; // url
}

export interface core_calendar_submit_create_update_form_returns {
  event?: core_calendar_submit_create_update_form_returns_event;
  validationerror: boolean; // default: bool(false) // Invalid form data
}

export interface core_calendar_get_calendar_access_information_parameters {
  courseid?: number; // default: int(0) // Course to check, empty for site calendar events.
}

export interface core_calendar_get_calendar_access_information_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_calendar_get_calendar_access_information_returns {
  canmanageentries: boolean; // Whether the user can manage entries.
  canmanageownentries: boolean; // Whether the user can manage its own entries.
  canmanagegroupentries: boolean; // Whether the user can manage group entries.
  warnings?: core_calendar_get_calendar_access_information_returns_warnings[]; // list of warnings
}

export interface core_calendar_get_allowed_event_types_parameters {
  courseid?: number; // default: int(0) // Course to check, empty for site.
}

export interface core_calendar_get_allowed_event_types_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

type core_calendar_get_allowed_event_types_returns_allowedeventtypes = string;

export interface core_calendar_get_allowed_event_types_returns {
  allowedeventtypes: core_calendar_get_allowed_event_types_returns_allowedeventtypes[];
  warnings?: core_calendar_get_allowed_event_types_returns_warnings[]; // list of warnings
}

export interface core_calendar_get_timestamps_parameters_data {
  key?: string; // key
  year: number; // year
  month: number; // month
  day: number; // day
  hour?: number; // hour
  minute?: number; // minute
}

export interface core_calendar_get_timestamps_parameters {
  data: core_calendar_get_timestamps_parameters_data[];
}

export interface core_calendar_get_timestamps_returns_timestamps {
  key: string; // Timestamp key
  timestamp: number; // Unix timestamp
}

export interface core_calendar_get_timestamps_returns {
  timestamps: core_calendar_get_timestamps_returns_timestamps[];
}

export interface core_calendar_get_calendar_export_token_parameters {}

export interface core_calendar_get_calendar_export_token_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_calendar_get_calendar_export_token_returns {
  token: string; // The calendar permanent access token for calendar export.
  warnings?: core_calendar_get_calendar_export_token_returns_warnings[]; // list of warnings
}

export interface core_calendar_delete_subscription_parameters {
  subscriptionid: number; // The id of the subscription
}

export interface core_calendar_delete_subscription_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_calendar_delete_subscription_returns {
  status: boolean; // status: true if success
  warnings?: core_calendar_delete_subscription_returns_warnings[]; // list of warnings
}

export interface core_cohort_add_cohort_members_parameters_members_usertype {
  type: string; // The name of the field: id (numeric value of id) or username (alphanumeric value of username)
  value: string; // The value of the cohort
}

export interface core_cohort_add_cohort_members_parameters_members_cohorttype {
  type: string; // The name of the field: id (numeric value of cohortid) or idnumber (alphanumeric value of idnumber)
  value: string; // The value of the cohort
}

export interface core_cohort_add_cohort_members_parameters_members {
  cohorttype: core_cohort_add_cohort_members_parameters_members_cohorttype;
  usertype: core_cohort_add_cohort_members_parameters_members_usertype;
}

export interface core_cohort_add_cohort_members_parameters {
  members: core_cohort_add_cohort_members_parameters_members[];
}

export interface core_cohort_add_cohort_members_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_cohort_add_cohort_members_returns {
  warnings?: core_cohort_add_cohort_members_returns_warnings[]; // list of warnings
}

export interface core_cohort_create_cohorts_parameters_cohorts_categorytype {
  type: string; // the name of the field: id (numeric value of course category id) or idnumber (alphanumeric value of idnumber course category) or system (value ignored)
  value: string; // the value of the categorytype
}

export interface core_cohort_create_cohorts_parameters_cohorts {
  categorytype: core_cohort_create_cohorts_parameters_cohorts_categorytype;
  name: string; // cohort name
  idnumber: string; // cohort idnumber
  description?: string; // cohort description
  descriptionformat?: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  visible?: boolean; // cohort visible
  theme?: string; // the cohort theme. The allowcohortthemes setting must be enabled on Moodle
}

export interface core_cohort_create_cohorts_parameters {
  cohorts: core_cohort_create_cohorts_parameters_cohorts[];
}

export interface core_cohort_create_cohorts_returns_item {
  id: number; // cohort id
  name: string; // cohort name
  idnumber: string; // cohort idnumber
  description: string; // cohort description
  descriptionformat: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  visible: boolean; // cohort visible
  theme?: string; // cohort theme
}

export interface core_cohort_delete_cohort_members_parameters_members {
  cohortid: number; // cohort record id
  userid: number; // user id
}

export interface core_cohort_delete_cohort_members_parameters {
  members: core_cohort_delete_cohort_members_parameters_members[];
}

type core_cohort_delete_cohort_members_returns = null;

type core_cohort_delete_cohorts_parameters_cohortids = number;

export interface core_cohort_delete_cohorts_parameters {
  cohortids: core_cohort_delete_cohorts_parameters_cohortids[];
}

type core_cohort_delete_cohorts_returns = null;

type core_cohort_get_cohort_members_parameters_cohortids = number;

export interface core_cohort_get_cohort_members_parameters {
  cohortids: core_cohort_get_cohort_members_parameters_cohortids[];
}

type core_cohort_get_cohort_members_returns_item_userids = number;

export interface core_cohort_get_cohort_members_returns_item {
  cohortid: number; // cohort record id
  userids: core_cohort_get_cohort_members_returns_item_userids[];
}

export interface core_cohort_search_cohorts_parameters_context {
  contextid?: number; // default: int(0) // Context ID. Either use this value, or level and instanceid.
  contextlevel?: string; // default: string(0) "" // Context level. To be used with instanceid.
  instanceid?: number; // default: int(0) // Context instance ID. To be used with level
}

export interface core_cohort_search_cohorts_parameters {
  query: string; // Query string
  context: core_cohort_search_cohorts_parameters_context;
  includes?: string; // default: string(7) "parents" // What other contexts to fetch the frameworks from. (all, parents, self)
  limitfrom?: number; // default: int(0) // limitfrom we are fetching the records from
  limitnum?: number; // default: int(25) // Number of records to fetch
}

export interface core_cohort_search_cohorts_returns_cohorts {
  id: number; // ID of the cohort
  name: string; // cohort name
  idnumber: string; // cohort idnumber
  description: string; // cohort description
  descriptionformat: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  visible: boolean; // cohort visible
  theme?: string; // cohort theme
}

export interface core_cohort_search_cohorts_returns {
  cohorts: core_cohort_search_cohorts_returns_cohorts[];
}

type core_cohort_get_cohorts_parameters_cohortids = number;

export interface core_cohort_get_cohorts_parameters {
  cohortids?: core_cohort_get_cohorts_parameters_cohortids[]; // default: array(0) { } // List of cohort id. A cohort id is an integer.
}

export interface core_cohort_get_cohorts_returns_item {
  id: number; // ID of the cohort
  name: string; // cohort name
  idnumber: string; // cohort idnumber
  description: string; // cohort description
  descriptionformat: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  visible: boolean; // cohort visible
  theme?: string; // cohort theme
}

export interface core_cohort_update_cohorts_parameters_cohorts_categorytype {
  type: string; // the name of the field: id (numeric value of course category id) or idnumber (alphanumeric value of idnumber course category) or system (value ignored)
  value: string; // the value of the categorytype
}

export interface core_cohort_update_cohorts_parameters_cohorts {
  id: number; // ID of the cohort
  categorytype: core_cohort_update_cohorts_parameters_cohorts_categorytype;
  name: string; // cohort name
  idnumber: string; // cohort idnumber
  description?: string; // cohort description
  descriptionformat?: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  visible?: boolean; // cohort visible
  theme?: string; // the cohort theme. The allowcohortthemes setting must be enabled on Moodle
}

export interface core_cohort_update_cohorts_parameters {
  cohorts: core_cohort_update_cohorts_parameters_cohorts[];
}

type core_cohort_update_cohorts_returns = null;

export interface core_comment_get_comments_parameters {
  contextlevel: string; // contextlevel system, course, user...
  instanceid: number; // the Instance id of item associated with the context level
  component: string; // component
  itemid: number; // associated id
  area?: string; // default: string(0) "" // string comment area
  page?: number; // default: int(0) // page number (0 based)
  sortdirection?: string; // default: string(4) "DESC" // Sort direction: ASC or DESC
}

export interface core_comment_get_comments_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_comment_get_comments_returns_comments {
  id: number; // Comment ID
  content: string; // The content text formatted
  format: number; // content format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  timecreated: number; // Time created (timestamp)
  strftimeformat: string; // Time format
  profileurl: string; // URL profile
  fullname: string; // fullname
  time: string; // Time in human format
  avatar: string; // HTML user picture
  userid: number; // User ID
  delete?: boolean; // Permission to delete=true/false
}

export interface core_comment_get_comments_returns {
  comments: core_comment_get_comments_returns_comments[]; // List of comments
  count?: number; // Total number of comments.
  perpage?: number; // Number of comments per page.
  canpost?: boolean; // Whether the user can post in this comment area.
  warnings?: core_comment_get_comments_returns_warnings[]; // list of warnings
}

export interface core_comment_add_comments_parameters_comments {
  contextlevel: string; // contextlevel system, course, user...
  instanceid: number; // the id of item associated with the contextlevel
  component: string; // component
  content: string; // component
  itemid: number; // associated id
  area?: string; // default: string(0) "" // string comment area
}

export interface core_comment_add_comments_parameters {
  comments: core_comment_add_comments_parameters_comments[];
}

export interface core_comment_add_comments_returns_item {
  id: number; // Comment ID
  content: string; // The content text formatted
  format: number; // content format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  timecreated: number; // Time created (timestamp)
  strftimeformat: string; // Time format
  profileurl: string; // URL profile
  fullname: string; // fullname
  time: string; // Time in human format
  avatar: string; // HTML user picture
  userid: number; // User ID
  delete?: boolean; // Permission to delete=true/false
}

type core_comment_delete_comments_parameters_comments = number;

export interface core_comment_delete_comments_parameters {
  comments: core_comment_delete_comments_parameters_comments[];
}

export interface core_comment_delete_comments_returns_item {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_completion_get_activities_completion_status_parameters {
  courseid: number; // Course ID
  userid: number; // User ID
}

export interface core_completion_get_activities_completion_status_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_completion_get_activities_completion_status_returns_statuses_details_rulevalue {
  status: number; // Completion status
  description: string; // Completion description
}

export interface core_completion_get_activities_completion_status_returns_statuses_details {
  rulename: string; // Rule name
  rulevalue: core_completion_get_activities_completion_status_returns_statuses_details_rulevalue;
}

export interface core_completion_get_activities_completion_status_returns_statuses {
  cmid: number; // course module ID
  modname: string; // activity module name
  instance: number; // instance ID
  state: number; // Completion state value: 0 means incomplete, 1 complete, 2 complete pass, 3 complete fail
  timecompleted: number; // timestamp for completed activity
  tracking: number; // type of tracking: 0 means none, 1 manual, 2 automatic
  overrideby?: number; // The user id who has overriden the status, or null
  valueused?: boolean; // Whether the completion status affects the availability of another activity.
  hascompletion?: boolean; // Whether this activity module has completion enabled
  isautomatic?: boolean; // Whether this activity module instance tracks completion automatically.
  istrackeduser?: boolean; // Whether completion is being tracked for this user.
  uservisible?: boolean; // Whether this activity is visible to the user.
  details: core_completion_get_activities_completion_status_returns_statuses_details[]; // default: array(0) { } // Completion status details
}

export interface core_completion_get_activities_completion_status_returns {
  statuses: core_completion_get_activities_completion_status_returns_statuses[]; // List of activities status
  warnings?: core_completion_get_activities_completion_status_returns_warnings[]; // list of warnings
}

export interface core_completion_get_course_completion_status_parameters {
  courseid: number; // Course ID
  userid: number; // User ID
}

export interface core_completion_get_course_completion_status_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_completion_get_course_completion_status_returns_completionstatus_completions_details {
  type: string; // Type description
  criteria: string; // Criteria description
  requirement: string; // Requirement description
  status: string; // Status description, can be anything
}

export interface core_completion_get_course_completion_status_returns_completionstatus_completions {
  type: number; // Completion criteria type
  title: string; // Completion criteria Title
  status: string; // Completion status (Yes/No) a % or number
  complete: boolean; // Completion status (true/false)
  timecompleted: number; // Timestamp for criteria completetion
  details: core_completion_get_course_completion_status_returns_completionstatus_completions_details; // details
}

export interface core_completion_get_course_completion_status_returns_completionstatus {
  completed: boolean; // true if the course is complete, false otherwise
  aggregation: number; // aggregation method 1 means all, 2 means any
  completions: core_completion_get_course_completion_status_returns_completionstatus_completions[];
}

export interface core_completion_get_course_completion_status_returns {
  completionstatus: core_completion_get_course_completion_status_returns_completionstatus; // Course status
  warnings?: core_completion_get_course_completion_status_returns_warnings[]; // list of warnings
}

export interface core_completion_mark_course_self_completed_parameters {
  courseid: number; // Course ID
}

export interface core_completion_mark_course_self_completed_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_completion_mark_course_self_completed_returns {
  status: boolean; // status, true if success
  warnings?: core_completion_mark_course_self_completed_returns_warnings[]; // list of warnings
}

export interface core_completion_update_activity_completion_status_manually_parameters {
  cmid: number; // course module id
  completed: boolean; // activity completed or not
}

export interface core_completion_update_activity_completion_status_manually_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_completion_update_activity_completion_status_manually_returns {
  status: boolean; // status, true if success
  warnings?: core_completion_update_activity_completion_status_manually_returns_warnings[]; // list of warnings
}

export interface core_completion_override_activity_completion_status_parameters {
  userid: number; // user id
  cmid: number; // course module id
  newstate: number; // the new activity completion state
}

export interface core_completion_override_activity_completion_status_returns {
  cmid: number; // The course module id
  userid: number; // The user id to which the completion info belongs
  state: number; // The current completion state.
  timecompleted: number; // time of completion
  overrideby: number; // The user id who has overriden the status, or null
  tracking: number; // type of tracking: 0 means none, 1 manual, 2 automatic
}

export interface core_course_create_categories_parameters_categories {
  name: string; // new category name
  parent?: number; // default: int(0) // the parent category id inside which the new category will be created - set to 0 for a root category
  idnumber?: string; // the new category idnumber
  description?: string; // the new category description
  descriptionformat?: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  theme?: string; // the new category theme. This option must be enabled on moodle
}

export interface core_course_create_categories_parameters {
  categories: core_course_create_categories_parameters_categories[];
}

export interface core_course_create_categories_returns_item {
  id: number; // new category id
  name: string; // new category name
}

export interface core_course_create_courses_parameters_courses_customfields {
  shortname: string; // The shortname of the custom field
  value: string; // The value of the custom field
}

export interface core_course_create_courses_parameters_courses_courseformatoptions {
  name: string; // course format option name
  value: string; // course format option value
}

export interface core_course_create_courses_parameters_courses {
  fullname: string; // full name
  shortname: string; // course short name
  categoryid: number; // category id
  idnumber?: string; // id number
  summary?: string; // summary
  summaryformat?: number; // default: string(1) "1" // summary format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  format?: string; // default: string(6) "topics" // course format: weeks, topics, social, site,..
  showgrades?: number; // default: string(1) "1" // 1 if grades are shown, otherwise 0
  newsitems?: number; // default: string(1) "5" // number of recent items appearing on the course page
  startdate?: number; // timestamp when the course start
  enddate?: number; // timestamp when the course end
  numsections?: number; // (deprecated, use courseformatoptions) number of weeks/topics
  maxbytes?: number; // default: string(1) "0" // largest size of file that can be uploaded into the course
  showreports?: number; // default: string(1) "0" // are activity report shown (yes = 1, no =0)
  visible?: number; // 1: available to student, 0:not available
  hiddensections?: number; // (deprecated, use courseformatoptions) How the hidden sections in the course are displayed to students
  groupmode?: number; // default: string(1) "0" // no group, separate, visible
  groupmodeforce?: number; // default: string(1) "0" // 1: yes, 0: no
  defaultgroupingid?: number; // default: int(0) // default grouping id
  enablecompletion?: number; // Enabled, control via completion and activity settings. Disabled, not shown in activity settings.
  completionnotify?: number; // 1: yes 0: no
  lang?: string; // forced course language
  forcetheme?: string; // name of the force theme
  courseformatoptions?: core_course_create_courses_parameters_courses_courseformatoptions[]; // additional options for particular course format
  customfields?: core_course_create_courses_parameters_courses_customfields[]; // custom fields for the course
}

export interface core_course_create_courses_parameters {
  courses: core_course_create_courses_parameters_courses[]; // courses to create
}

export interface core_course_create_courses_returns_item {
  id: number; // course id
  shortname: string; // short name
}

export interface core_course_delete_categories_parameters_categories {
  id: number; // category id to delete
  newparent?: number; // the parent category to move the contents to, if specified
  recursive?: boolean; // default: int(0) // 1: recursively delete all contents inside this category, 0 (default): move contents to newparent or current parent category (except if parent is root)
}

export interface core_course_delete_categories_parameters {
  categories: core_course_delete_categories_parameters_categories[];
}

type core_course_delete_categories_returns = null;

type core_course_delete_courses_parameters_courseids = number;

export interface core_course_delete_courses_parameters {
  courseids: core_course_delete_courses_parameters_courseids[];
}

export interface core_course_delete_courses_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_course_delete_courses_returns {
  warnings?: core_course_delete_courses_returns_warnings[]; // list of warnings
}

type core_course_delete_modules_parameters_cmids = number;

export interface core_course_delete_modules_parameters {
  cmids: core_course_delete_modules_parameters_cmids[]; // Array of course module IDs
}

type core_course_delete_modules_returns = null;

export interface core_course_duplicate_course_parameters_options {
  name: string; // The backup option name: "activities" (int) Include course activites (default to 1 that is equal to yes), "blocks" (int) Include course blocks (default to 1 that is equal to yes), "filters" (int) Include course filters (default to 1 that is equal to yes), "users" (int) Include users (default to 0 that is equal to no), "enrolments" (int) Include enrolment methods (default to 1 - restore only with users), "role_assignments" (int) Include role assignments (default to 0 that is equal to no), "comments" (int) Include user comments (default to 0 that is equal to no), "userscompletion" (int) Include user course completion information (default to 0 that is equal to no), "logs" (int) Include course logs (default to 0 that is equal to no), "grade_histories" (int) Include histories (default to 0 that is equal to no)
  value: string; // the value for the option 1 (yes) or 0 (no)
}

export interface core_course_duplicate_course_parameters {
  courseid: number; // course to duplicate id
  fullname: string; // duplicated course full name
  shortname: string; // duplicated course short name
  categoryid: number; // duplicated course category parent
  visible?: number; // default: int(1) // duplicated course visible, default to yes
  options?: core_course_duplicate_course_parameters_options[]; // default: array(0) { } // Course duplication options
}

export interface core_course_duplicate_course_returns {
  id: number; // course id
  shortname: string; // short name
}

export interface core_course_get_categories_parameters_criteria {
  key: string; // The category column to search, expected keys (value format) are:"id" (int) the category id,"ids" (string) category ids separated by commas,"name" (string) the category name,"parent" (int) the parent category id,"idnumber" (string) category idnumber - user must have 'moodle/category:manage' to search on idnumber,"visible" (int) whether the returned categories must be visible or hidden. If the key is not passed, then the function return all categories that the user can see. - user must have 'moodle/category:manage' or 'moodle/category:viewhiddencategories' to search on visible,"theme" (string) only return the categories having this theme - user must have 'moodle/category:manage' to search on theme
  value: string; // the value to match
}

export interface core_course_get_categories_parameters {
  criteria?: core_course_get_categories_parameters_criteria[]; // default: array(0) { } // criteria
  addsubcategories?: boolean; // default: int(1) // return the sub categories infos (1 - default) otherwise only the category info (0)
}

export interface core_course_get_categories_returns_item {
  id: number; // category id
  name: string; // category name
  idnumber?: string; // category id number
  description: string; // category description
  descriptionformat: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  parent: number; // parent category id
  sortorder: number; // category sorting order
  coursecount: number; // number of courses in this category
  visible?: number; // 1: available, 0:not available
  visibleold?: number; // 1: available, 0:not available
  timemodified?: number; // timestamp
  depth: number; // category depth
  path: string; // category path
  theme?: string; // category theme
}

export interface core_course_get_contents_parameters_options {
  name: string; // The expected keys (value format) are: excludemodules (bool) Do not return modules, return only the sections structure excludecontents (bool) Do not return module contents (i.e: files inside a resource) includestealthmodules (bool) Return stealth modules for students in a special section (with id -1) sectionid (int) Return only this section sectionnumber (int) Return only this section with number (order) cmid (int) Return only this module information (among the whole sections structure) modname (string) Return only modules with this name "label, forum, etc..." modid (int) Return only the module with this id (to be used with modname
  value: string; // the value of the option, this param is personaly validated in the external function.
}

export interface core_course_get_contents_parameters {
  courseid: number; // course id
  options?: core_course_get_contents_parameters_options[]; // default: array(0) { } // Options, used since Moodle 2.9
}

type core_course_get_contents_returns_item_modules_contentsinfo_mimetypes = string;

export interface core_course_get_contents_returns_item_modules_contentsinfo {
  filescount: number; // Total number of files.
  filessize: number; // Total files size.
  lastmodified: number; // Last time files were modified.
  mimetypes: core_course_get_contents_returns_item_modules_contentsinfo_mimetypes[]; // Files mime types.
  repositorytype?: string; // The repository type for the main file.
}

export interface core_course_get_contents_returns_item_modules_contents_tags {
  id: number; // Tag id.
  name: string; // Tag name.
  rawname: string; // The raw, unnormalised name for the tag as entered by users.
  isstandard: boolean; // Whether this tag is standard.
  tagcollid: number; // Tag collection id.
  taginstanceid: number; // Tag instance id.
  taginstancecontextid: number; // Context the tag instance belongs to.
  itemid: number; // Id of the record tagged.
  ordering: number; // Tag ordering.
  flag: number; // Whether the tag is flagged as inappropriate.
}

export interface core_course_get_contents_returns_item_modules_contents {
  type: string; // a file or a folder or external link
  filename: string; // filename
  filepath: string; // filepath
  filesize: number; // filesize
  fileurl?: string; // downloadable file url
  content?: string; // Raw content, will be used when type is content
  timecreated: number; // Time created
  timemodified: number; // Time modified
  sortorder: number; // Content sort order
  mimetype?: string; // File mime type.
  isexternalfile?: boolean; // Whether is an external file.
  repositorytype?: string; // The repository type for external files.
  userid: number; // User who added this content to moodle
  author: string; // Content owner
  license: string; // Content license
  tags?: core_course_get_contents_returns_item_modules_contents_tags[]; // Tags
}

export interface core_course_get_contents_returns_item_modules_dates {
  label: string; // date label
  timestamp: number; // date timestamp
  relativeto?: number; // relative date timestamp
  dataid?: string; // cm data id
}

export interface core_course_get_contents_returns_item_modules_completiondata_details_rulevalue {
  status: number; // status
  description: string; // description
}

export interface core_course_get_contents_returns_item_modules_completiondata_details {
  rulename: string; // rulename
  rulevalue: core_course_get_contents_returns_item_modules_completiondata_details_rulevalue;
}

export interface core_course_get_contents_returns_item_modules_completiondata {
  state: number; // overall completion state of this course module.
  timecompleted: number; // course completion timestamp.
  overrideby: number; // user ID that has overridden the completion state of this activity for the user.
  valueused: boolean; // True if module is used in a condition, false otherwise.
  hascompletion: boolean; // Whether this activity module has completion enabled.
  isautomatic: boolean; // Whether this activity module instance tracks completion automatically.
  istrackeduser: boolean; // Checks whether completion is being tracked for this user.
  uservisible: boolean; // Whether this activity is visible to user.
  details: core_course_get_contents_returns_item_modules_completiondata_details[]; // An array of completion details containing the description and status.
}

export interface core_course_get_contents_returns_item_modules {
  id: number; // activity id
  url?: string; // activity url
  name: string; // activity module name
  instance?: number; // instance id
  contextid?: number; // Activity context id.
  description?: string; // activity description
  visible?: number; // is the module visible
  uservisible?: boolean; // Is the module visible for the user?
  availabilityinfo?: string; // Availability information.
  visibleoncoursepage?: number; // is the module visible on course page
  modicon: string; // activity icon url
  modname: string; // activity module type
  modplural: string; // activity module plural name
  availability?: string; // module availability settings
  indent: number; // number of identation in the site
  onclick?: string; // Onclick action.
  afterlink?: string; // After link info to be displayed.
  customdata?: string; // Custom data (JSON encoded).
  noviewlink?: boolean; // Whether the module has no view page
  completion?: number; // Type of completion tracking: 0 means none, 1 manual, 2 automatic.
  completiondata: core_course_get_contents_returns_item_modules_completiondata; // default: array(0) { }
  downloadcontent?: number; // The download content value
  dates: core_course_get_contents_returns_item_modules_dates[]; // default: array(0) { } // Course dates
  contents: core_course_get_contents_returns_item_modules_contents[]; // default: array(0) { } // Course contents
  contentsinfo?: core_course_get_contents_returns_item_modules_contentsinfo; // Contents summary information.
}

export interface core_course_get_contents_returns_item {
  id: number; // Section ID
  name: string; // Section name
  visible?: number; // is the section visible
  summary: string; // Section description
  summaryformat: number; // summary format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  section?: number; // Section number inside the course
  hiddenbynumsections?: number; // Whether is a section hidden in the course format
  uservisible?: boolean; // Is the section visible for the user?
  availabilityinfo?: string; // Availability information.
  modules: core_course_get_contents_returns_item_modules[]; // list of module
}

export interface core_course_get_course_module_parameters {
  cmid: number; // The course module id
}

export interface core_course_get_course_module_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_course_get_course_module_returns_cm_outcomes {
  id: string; // Outcome id
  name: string; // Outcome full name
  scale: string; // Scale items
}

export interface core_course_get_course_module_returns_cm_advancedgrading {
  area: string; // Gradable area name
  method: string; // Grading method
}

export interface core_course_get_course_module_returns_cm {
  id: number; // The course module id
  course: number; // The course id
  module: number; // The module type id
  name: string; // The activity name
  modname: string; // The module component name (forum, assign, etc..)
  instance: number; // The activity instance id
  section: number; // The module section id
  sectionnum: number; // The module section number
  groupmode: number; // Group mode
  groupingid: number; // Grouping id
  completion: number; // If completion is enabled
  idnumber?: string; // Module id number
  added?: number; // Time added
  score?: number; // Score
  indent?: number; // Indentation
  visible?: number; // If visible
  visibleoncoursepage?: number; // If visible on course page
  visibleold?: number; // Visible old
  completiongradeitemnumber?: number; // Completion grade item
  completionpassgrade?: number; // Completion pass grade setting
  completionview?: number; // Completion view setting
  completionexpected?: number; // Completion time expected
  showdescription?: number; // If the description is showed
  downloadcontent?: number; // The download content value
  availability?: string; // Availability settings
  grade?: number; // Grade (max value or scale id)
  scale?: string; // Scale items (if used)
  gradepass?: string; // Grade to pass (float)
  gradecat?: number; // Grade category
  advancedgrading?: core_course_get_course_module_returns_cm_advancedgrading[]; // Advanced grading settings
  outcomes?: core_course_get_course_module_returns_cm_outcomes[]; // Outcomes information
}

export interface core_course_get_course_module_returns {
  cm: core_course_get_course_module_returns_cm;
  warnings?: core_course_get_course_module_returns_warnings[]; // list of warnings
}

export interface core_course_get_course_module_by_instance_parameters {
  module: string; // The module name
  instance: number; // The module instance id
}

export interface core_course_get_course_module_by_instance_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_course_get_course_module_by_instance_returns_cm_outcomes {
  id: string; // Outcome id
  name: string; // Outcome full name
  scale: string; // Scale items
}

export interface core_course_get_course_module_by_instance_returns_cm_advancedgrading {
  area: string; // Gradable area name
  method: string; // Grading method
}

export interface core_course_get_course_module_by_instance_returns_cm {
  id: number; // The course module id
  course: number; // The course id
  module: number; // The module type id
  name: string; // The activity name
  modname: string; // The module component name (forum, assign, etc..)
  instance: number; // The activity instance id
  section: number; // The module section id
  sectionnum: number; // The module section number
  groupmode: number; // Group mode
  groupingid: number; // Grouping id
  completion: number; // If completion is enabled
  idnumber?: string; // Module id number
  added?: number; // Time added
  score?: number; // Score
  indent?: number; // Indentation
  visible?: number; // If visible
  visibleoncoursepage?: number; // If visible on course page
  visibleold?: number; // Visible old
  completiongradeitemnumber?: number; // Completion grade item
  completionpassgrade?: number; // Completion pass grade setting
  completionview?: number; // Completion view setting
  completionexpected?: number; // Completion time expected
  showdescription?: number; // If the description is showed
  downloadcontent?: number; // The download content value
  availability?: string; // Availability settings
  grade?: number; // Grade (max value or scale id)
  scale?: string; // Scale items (if used)
  gradepass?: string; // Grade to pass (float)
  gradecat?: number; // Grade category
  advancedgrading?: core_course_get_course_module_by_instance_returns_cm_advancedgrading[]; // Advanced grading settings
  outcomes?: core_course_get_course_module_by_instance_returns_cm_outcomes[]; // Outcomes information
}

export interface core_course_get_course_module_by_instance_returns {
  cm: core_course_get_course_module_by_instance_returns_cm;
  warnings?: core_course_get_course_module_by_instance_returns_warnings[]; // list of warnings
}

export interface core_course_get_module_parameters {
  id: number; // course module id
  sectionreturn?: number; // default: NULL // section to return to
}

type core_course_get_module_returns = string;

export interface core_courseformat_get_state_parameters {
  courseid: number; // course id
}

type core_courseformat_get_state_returns = string;

type core_courseformat_update_course_parameters_ids = number;

export interface core_courseformat_update_course_parameters {
  action: string; // action: cm_hide, cm_show, section_hide, section_show, cm_moveleft...
  courseid: number; // course id
  ids?: core_courseformat_update_course_parameters_ids[]; // default: array(0) { } // Affected ids
  targetsectionid?: number; // default: NULL // Optional target section id
  targetcmid?: number; // default: NULL // Optional target cm id
}

type core_courseformat_update_course_returns = string;

export interface core_course_edit_module_parameters {
  action: string; // action: hide, show, stealth, duplicate, delete, moveleft, moveright, group...
  id: number; // course module id
  sectionreturn?: number; // default: NULL // section to return to
}

type core_course_edit_module_returns = string;

export interface core_course_edit_section_parameters {
  action: string; // action: hide, show, stealth, setmarker, removemarker
  id: number; // course section id
  sectionreturn?: number; // default: NULL // section to return to
}

type core_course_edit_section_returns = string;

type core_course_get_courses_parameters_options_ids = number;

export interface core_course_get_courses_parameters_options {
  ids?: core_course_get_courses_parameters_options_ids[]; // List of course id. If empty return all courses except front page course.
}

export interface core_course_get_courses_parameters {
  options?: core_course_get_courses_parameters_options; // default: array(0) { } // options - operator OR is used
}

export interface core_course_get_courses_returns_item_customfields {
  name: string; // The name of the custom field
  shortname: string; // The shortname of the custom field
  type: string; // The type of the custom field - text, checkbox...
  valueraw: string; // The raw value of the custom field
  value: string; // The value of the custom field
}

export interface core_course_get_courses_returns_item_courseformatoptions {
  name: string; // course format option name
  value: string; // course format option value
}

export interface core_course_get_courses_returns_item {
  id: number; // course id
  shortname: string; // course short name
  categoryid: number; // category id
  categorysortorder?: number; // sort order into the category
  fullname: string; // full name
  displayname: string; // course display name
  idnumber?: string; // id number
  summary: string; // summary
  summaryformat: number; // summary format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  format: string; // course format: weeks, topics, social, site,..
  showgrades?: number; // 1 if grades are shown, otherwise 0
  newsitems?: number; // number of recent items appearing on the course page
  startdate: number; // timestamp when the course start
  enddate: number; // timestamp when the course end
  numsections?: number; // (deprecated, use courseformatoptions) number of weeks/topics
  maxbytes?: number; // largest size of file that can be uploaded into the course
  showreports?: number; // are activity report shown (yes = 1, no =0)
  visible?: number; // 1: available to student, 0:not available
  hiddensections?: number; // (deprecated, use courseformatoptions) How the hidden sections in the course are displayed to students
  groupmode?: number; // no group, separate, visible
  groupmodeforce?: number; // 1: yes, 0: no
  defaultgroupingid?: number; // default grouping id
  timecreated?: number; // timestamp when the course have been created
  timemodified?: number; // timestamp when the course have been modified
  enablecompletion?: number; // Enabled, control via completion and activity settings. Disbaled, not shown in activity settings.
  completionnotify?: number; // 1: yes 0: no
  lang?: string; // forced course language
  forcetheme?: string; // name of the force theme
  courseformatoptions?: core_course_get_courses_returns_item_courseformatoptions[]; // additional options for particular course format
  showactivitydates: boolean; // Whether the activity dates are shown or not
  showcompletionconditions: boolean; // Whether the activity completion conditions are shown or not
  customfields?: core_course_get_courses_returns_item_customfields[]; // Custom fields and associated values
}

export interface core_course_import_course_parameters_options {
  name: string; // The backup option name: "activities" (int) Include course activites (default to 1 that is equal to yes), "blocks" (int) Include course blocks (default to 1 that is equal to yes), "filters" (int) Include course filters (default to 1 that is equal to yes)
  value: string; // the value for the option 1 (yes) or 0 (no)
}

export interface core_course_import_course_parameters {
  importfrom: number; // the id of the course we are importing from
  importto: number; // the id of the course we are importing to
  deletecontent?: number; // default: int(0) // whether to delete the course content where we are importing to (default to 0 = No)
  options?: core_course_import_course_parameters_options[]; // default: array(0) { } // Course import options
}

type core_course_import_course_returns = null;

type core_course_search_courses_parameters_requiredcapabilities = string;

export interface core_course_search_courses_parameters {
  criterianame: string; // criteria name (search, modulelist (only admins), blocklist (only admins), tagid)
  criteriavalue: string; // criteria value
  page?: number; // default: int(0) // page number (0 based)
  perpage?: number; // default: int(0) // items per page
  requiredcapabilities?: core_course_search_courses_parameters_requiredcapabilities[]; // default: array(0) { } // Optional list of required capabilities (used to filter the list)
  limittoenrolled?: boolean; // default: int(0) // limit to enrolled courses
  onlywithcompletion?: boolean; // default: int(0) // limit to courses where completion is enabled
}

export interface core_course_search_courses_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_course_search_courses_returns_courses_customfields {
  name: string; // The name of the custom field
  shortname: string; // The shortname of the custom field - to be able to build the field class in the code
  type: string; // The type of the custom field - text field, checkbox...
  valueraw: string; // The raw value of the custom field
  value: string; // The value of the custom field
}

type core_course_search_courses_returns_courses_enrollmentmethods = string;

export interface core_course_search_courses_returns_courses_contacts {
  id: number; // contact user id
  fullname: string; // contact user fullname
}

export interface core_course_search_courses_returns_courses_overviewfiles {
  filename?: string; // File name.
  filepath?: string; // File path.
  filesize?: number; // File size.
  fileurl?: string; // Downloadable file url.
  timemodified?: number; // Time modified.
  mimetype?: string; // File mime type.
  isexternalfile?: boolean; // Whether is an external file.
  repositorytype?: string; // The repository type for external files.
}

export interface core_course_search_courses_returns_courses_summaryfiles {
  filename?: string; // File name.
  filepath?: string; // File path.
  filesize?: number; // File size.
  fileurl?: string; // Downloadable file url.
  timemodified?: number; // Time modified.
  mimetype?: string; // File mime type.
  isexternalfile?: boolean; // Whether is an external file.
  repositorytype?: string; // The repository type for external files.
}

export interface core_course_search_courses_returns_courses {
  id: number; // course id
  fullname: string; // course full name
  displayname: string; // course display name
  shortname: string; // course short name
  categoryid: number; // category id
  categoryname: string; // category name
  sortorder?: number; // Sort order in the category
  summary: string; // summary
  summaryformat: number; // summary format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  summaryfiles?: core_course_search_courses_returns_courses_summaryfiles[]; // summary files in the summary field
  overviewfiles: core_course_search_courses_returns_courses_overviewfiles[]; // additional overview files attached to this course
  showactivitydates: boolean; // Whether the activity dates are shown or not
  showcompletionconditions: boolean; // Whether the activity completion conditions are shown or not
  contacts: core_course_search_courses_returns_courses_contacts[]; // contact users
  enrollmentmethods: core_course_search_courses_returns_courses_enrollmentmethods[]; // enrollment methods list
  customfields?: core_course_search_courses_returns_courses_customfields[]; // Custom fields
}

export interface core_course_search_courses_returns {
  total: number; // total course count
  courses: core_course_search_courses_returns_courses[]; // course
  warnings?: core_course_search_courses_returns_warnings[]; // list of warnings
}

export interface core_course_update_categories_parameters_categories {
  id: number; // course id
  name?: string; // category name
  idnumber?: string; // category id number
  parent?: number; // parent category id
  description?: string; // category description
  descriptionformat?: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  theme?: string; // the category theme. This option must be enabled on moodle
}

export interface core_course_update_categories_parameters {
  categories: core_course_update_categories_parameters_categories[];
}

type core_course_update_categories_returns = null;

export interface core_course_update_courses_parameters_courses_customfields {
  shortname: string; // The shortname of the custom field
  value: string; // The value of the custom field
}

export interface core_course_update_courses_parameters_courses_courseformatoptions {
  name: string; // course format option name
  value: string; // course format option value
}

export interface core_course_update_courses_parameters_courses {
  id: number; // ID of the course
  fullname?: string; // full name
  shortname?: string; // course short name
  categoryid?: number; // category id
  idnumber?: string; // id number
  summary?: string; // summary
  summaryformat?: number; // summary format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  format?: string; // course format: weeks, topics, social, site,..
  showgrades?: number; // 1 if grades are shown, otherwise 0
  newsitems?: number; // number of recent items appearing on the course page
  startdate?: number; // timestamp when the course start
  enddate?: number; // timestamp when the course end
  numsections?: number; // (deprecated, use courseformatoptions) number of weeks/topics
  maxbytes?: number; // largest size of file that can be uploaded into the course
  showreports?: number; // are activity report shown (yes = 1, no =0)
  visible?: number; // 1: available to student, 0:not available
  hiddensections?: number; // (deprecated, use courseformatoptions) How the hidden sections in the course are displayed to students
  groupmode?: number; // no group, separate, visible
  groupmodeforce?: number; // 1: yes, 0: no
  defaultgroupingid?: number; // default grouping id
  enablecompletion?: number; // Enabled, control via completion and activity settings. Disabled, not shown in activity settings.
  completionnotify?: number; // 1: yes 0: no
  lang?: string; // forced course language
  forcetheme?: string; // name of the force theme
  courseformatoptions?: core_course_update_courses_parameters_courses_courseformatoptions[]; // additional options for particular course format
  customfields?: core_course_update_courses_parameters_courses_customfields[]; // Custom fields
}

export interface core_course_update_courses_parameters {
  courses: core_course_update_courses_parameters_courses[]; // courses to update
}

export interface core_course_update_courses_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_course_update_courses_returns {
  warnings?: core_course_update_courses_returns_warnings[]; // list of warnings
}

export interface core_course_view_course_parameters {
  courseid: number; // id of the course
  sectionnumber?: number; // default: int(0) // section number
}

export interface core_course_view_course_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_course_view_course_returns {
  status: boolean; // status: true if success
  warnings?: core_course_view_course_returns_warnings[]; // list of warnings
}

type core_course_get_user_navigation_options_parameters_courseids = number;

export interface core_course_get_user_navigation_options_parameters {
  courseids: core_course_get_user_navigation_options_parameters_courseids[];
}

export interface core_course_get_user_navigation_options_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_course_get_user_navigation_options_returns_courses_options {
  name: string; // Option name
  available: boolean; // Whether the option is available or not
}

export interface core_course_get_user_navigation_options_returns_courses {
  id: number; // Course id
  options: core_course_get_user_navigation_options_returns_courses_options[];
}

export interface core_course_get_user_navigation_options_returns {
  courses: core_course_get_user_navigation_options_returns_courses[]; // List of courses
  warnings?: core_course_get_user_navigation_options_returns_warnings[]; // list of warnings
}

type core_course_get_user_administration_options_parameters_courseids = number;

export interface core_course_get_user_administration_options_parameters {
  courseids: core_course_get_user_administration_options_parameters_courseids[];
}

export interface core_course_get_user_administration_options_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_course_get_user_administration_options_returns_courses_options {
  name: string; // Option name
  available: boolean; // Whether the option is available or not
}

export interface core_course_get_user_administration_options_returns_courses {
  id: number; // Course id
  options: core_course_get_user_administration_options_returns_courses_options[];
}

export interface core_course_get_user_administration_options_returns {
  courses: core_course_get_user_administration_options_returns_courses[]; // List of courses
  warnings?: core_course_get_user_administration_options_returns_warnings[]; // list of warnings
}

export interface core_course_get_courses_by_field_parameters {
  field?: string; // default: string(0) "" // The field to search can be left empty for all courses or: id: course id ids: comma separated course ids shortname: course short name idnumber: course id number category: category id the course belongs to
  value?: string; // default: string(0) "" // The value to match
}

export interface core_course_get_courses_by_field_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_course_get_courses_by_field_returns_courses_courseformatoptions {
  name: string; // Course format option name.
  value: string; // Course format option value.
}

export interface core_course_get_courses_by_field_returns_courses_filters {
  filter: string; // Filter plugin name
  localstate: number; // Filter state: 1 for on, -1 for off, 0 if inherit
  inheritedstate: number; // 1 or 0 to use when localstate is set to inherit
}

export interface core_course_get_courses_by_field_returns_courses_customfields {
  name: string; // The name of the custom field
  shortname: string; // The shortname of the custom field - to be able to build the field class in the code
  type: string; // The type of the custom field - text field, checkbox...
  valueraw: string; // The raw value of the custom field
  value: string; // The value of the custom field
}

type core_course_get_courses_by_field_returns_courses_enrollmentmethods = string;

export interface core_course_get_courses_by_field_returns_courses_contacts {
  id: number; // contact user id
  fullname: string; // contact user fullname
}

export interface core_course_get_courses_by_field_returns_courses_overviewfiles {
  filename?: string; // File name.
  filepath?: string; // File path.
  filesize?: number; // File size.
  fileurl?: string; // Downloadable file url.
  timemodified?: number; // Time modified.
  mimetype?: string; // File mime type.
  isexternalfile?: boolean; // Whether is an external file.
  repositorytype?: string; // The repository type for external files.
}

export interface core_course_get_courses_by_field_returns_courses_summaryfiles {
  filename?: string; // File name.
  filepath?: string; // File path.
  filesize?: number; // File size.
  fileurl?: string; // Downloadable file url.
  timemodified?: number; // Time modified.
  mimetype?: string; // File mime type.
  isexternalfile?: boolean; // Whether is an external file.
  repositorytype?: string; // The repository type for external files.
}

export interface core_course_get_courses_by_field_returns_courses {
  id: number; // course id
  fullname: string; // course full name
  displayname: string; // course display name
  shortname: string; // course short name
  categoryid: number; // category id
  categoryname: string; // category name
  sortorder?: number; // Sort order in the category
  summary: string; // summary
  summaryformat: number; // summary format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  summaryfiles?: core_course_get_courses_by_field_returns_courses_summaryfiles[]; // summary files in the summary field
  overviewfiles: core_course_get_courses_by_field_returns_courses_overviewfiles[]; // additional overview files attached to this course
  showactivitydates: boolean; // Whether the activity dates are shown or not
  showcompletionconditions: boolean; // Whether the activity completion conditions are shown or not
  contacts: core_course_get_courses_by_field_returns_courses_contacts[]; // contact users
  enrollmentmethods: core_course_get_courses_by_field_returns_courses_enrollmentmethods[]; // enrollment methods list
  customfields?: core_course_get_courses_by_field_returns_courses_customfields[]; // Custom fields
  idnumber?: string; // Id number
  format?: string; // Course format: weeks, topics, social, site,..
  showgrades?: number; // 1 if grades are shown, otherwise 0
  newsitems?: number; // Number of recent items appearing on the course page
  startdate?: number; // Timestamp when the course start
  enddate?: number; // Timestamp when the course end
  maxbytes?: number; // Largest size of file that can be uploaded into
  showreports?: number; // Are activity report shown (yes = 1, no =0)
  visible?: number; // 1: available to student, 0:not available
  groupmode?: number; // no group, separate, visible
  groupmodeforce?: number; // 1: yes, 0: no
  defaultgroupingid?: number; // default grouping id
  enablecompletion?: number; // Completion enabled? 1: yes 0: no
  completionnotify?: number; // 1: yes 0: no
  lang?: string; // Forced course language
  theme?: string; // Fame of the forced theme
  marker?: number; // Current course marker
  legacyfiles?: number; // If legacy files are enabled
  calendartype?: string; // Calendar type
  timecreated?: number; // Time when the course was created
  timemodified?: number; // Last time the course was updated
  requested?: number; // If is a requested course
  cacherev?: number; // Cache revision number
  filters?: core_course_get_courses_by_field_returns_courses_filters[]; // Course filters
  courseformatoptions?: core_course_get_courses_by_field_returns_courses_courseformatoptions[]; // Additional options for particular course format.
}

export interface core_course_get_courses_by_field_returns {
  courses: core_course_get_courses_by_field_returns_courses[]; // Course
  warnings?: core_course_get_courses_by_field_returns_warnings[]; // list of warnings
}

type core_course_check_updates_parameters_filter = string;

export interface core_course_check_updates_parameters_tocheck {
  contextlevel: string; // The context level for the file location. Only module supported right now.
  id: number; // Context instance id
  since: number; // Check updates since this time stamp
}

export interface core_course_check_updates_parameters {
  courseid: number; // Course id to check
  tocheck: core_course_check_updates_parameters_tocheck[]; // Instances to check
  filter?: core_course_check_updates_parameters_filter[]; // default: array(0) { } // Check only for updates in these areas
}

export interface core_course_check_updates_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

type core_course_check_updates_returns_instances_updates_itemids = number;

export interface core_course_check_updates_returns_instances_updates {
  name: string; // Name of the area updated.
  timeupdated?: number; // Last time was updated
  itemids?: core_course_check_updates_returns_instances_updates_itemids[]; // The ids of the items updated
}

export interface core_course_check_updates_returns_instances {
  contextlevel: string; // The context level
  id: number; // Instance id
  updates: core_course_check_updates_returns_instances_updates[];
}

export interface core_course_check_updates_returns {
  instances: core_course_check_updates_returns_instances[];
  warnings?: core_course_check_updates_returns_warnings[]; // list of warnings
}

type core_course_get_updates_since_parameters_filter = string;

export interface core_course_get_updates_since_parameters {
  courseid: number; // Course id to check
  since: number; // Check updates since this time stamp
  filter?: core_course_get_updates_since_parameters_filter[]; // default: array(0) { } // Check only for updates in these areas
}

export interface core_course_get_updates_since_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

type core_course_get_updates_since_returns_instances_updates_itemids = number;

export interface core_course_get_updates_since_returns_instances_updates {
  name: string; // Name of the area updated.
  timeupdated?: number; // Last time was updated
  itemids?: core_course_get_updates_since_returns_instances_updates_itemids[]; // The ids of the items updated
}

export interface core_course_get_updates_since_returns_instances {
  contextlevel: string; // The context level
  id: number; // Instance id
  updates: core_course_get_updates_since_returns_instances_updates[];
}

export interface core_course_get_updates_since_returns {
  instances: core_course_get_updates_since_returns_instances[];
  warnings?: core_course_get_updates_since_returns_warnings[]; // list of warnings
}

export interface core_course_get_enrolled_courses_by_timeline_classification_parameters {
  classification: string; // future, inprogress, or past
  limit?: number; // default: int(0) // Result set limit
  offset?: number; // default: int(0) // Result set offset
  sort?: string; // default: NULL // Sort string
  customfieldname?: string; // default: NULL // Used when classification = customfield
  customfieldvalue?: string; // default: NULL // Used when classification = customfield
  searchvalue?: string; // default: NULL // The value a user wishes to search against
}

export interface core_course_get_enrolled_courses_by_timeline_classification_returns_courses {
  id: number; // id
  fullname: string; // fullname
  shortname: string; // shortname
  idnumber: string; // idnumber
  summary: string; // summary
  summaryformat: number; // summary format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  startdate: number; // startdate
  enddate: number; // enddate
  visible: boolean; // visible
  showactivitydates: boolean; // showactivitydates
  showcompletionconditions: boolean; // showcompletionconditions
  fullnamedisplay: string; // fullnamedisplay
  viewurl: string; // viewurl
  courseimage: string; // courseimage
  progress?: number; // progress
  hasprogress: boolean; // hasprogress
  isfavourite: boolean; // isfavourite
  hidden: boolean; // hidden
  timeaccess?: number; // timeaccess
  showshortname: boolean; // showshortname
  coursecategory: string; // coursecategory
}

export interface core_course_get_enrolled_courses_by_timeline_classification_returns {
  courses: core_course_get_enrolled_courses_by_timeline_classification_returns_courses[]; // Course
  nextoffset: number; // Offset for the next request
}

export interface core_course_get_enrolled_courses_with_action_events_by_timeline_classification_parameters {
  classification: string; // future, inprogress, or past
  limit?: number; // default: int(0) // Result set limit
  offset?: number; // default: int(0) // Result set offset
  sort?: string; // default: NULL // Sort string
  customfieldname?: string; // default: NULL // Used when classification = customfield
  customfieldvalue?: string; // default: NULL // Used when classification = customfield
  searchvalue?: string; // default: NULL // The value a user wishes to search against
  eventsfrom?: number; // default: NULL // Optional starting timestamp for action events
  eventsto?: number; // default: NULL // Optional ending timestamp for action events
}

export interface core_course_get_enrolled_courses_with_action_events_by_timeline_classification_returns_courses {
  id: number; // id
  fullname: string; // fullname
  shortname: string; // shortname
  idnumber: string; // idnumber
  summary: string; // summary
  summaryformat: number; // summary format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  startdate: number; // startdate
  enddate: number; // enddate
  visible: boolean; // visible
  showactivitydates: boolean; // showactivitydates
  showcompletionconditions: boolean; // showcompletionconditions
  fullnamedisplay: string; // fullnamedisplay
  viewurl: string; // viewurl
  courseimage: string; // courseimage
  progress?: number; // progress
  hasprogress: boolean; // hasprogress
  isfavourite: boolean; // isfavourite
  hidden: boolean; // hidden
  timeaccess?: number; // timeaccess
  showshortname: boolean; // showshortname
  coursecategory: string; // coursecategory
}

export interface core_course_get_enrolled_courses_with_action_events_by_timeline_classification_returns {
  courses: core_course_get_enrolled_courses_with_action_events_by_timeline_classification_returns_courses[]; // Course
  nextoffset: number; // Offset for the next request
  morecoursesavailable: boolean; // Whether more courses with events exist within the provided parameters
}

export interface core_course_get_recent_courses_parameters {
  userid?: number; // default: int(0) // id of the user, default to current user
  limit?: number; // default: int(0) // result set limit
  offset?: number; // default: int(0) // Result set offset
  sort?: string; // default: NULL // Sort string
}

export interface core_course_get_recent_courses_returns_item {
  id: number; // id
  fullname: string; // fullname
  shortname: string; // shortname
  idnumber: string; // idnumber
  summary: string; // summary
  summaryformat: number; // summary format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  startdate: number; // startdate
  enddate: number; // enddate
  visible: boolean; // visible
  showactivitydates: boolean; // showactivitydates
  showcompletionconditions: boolean; // showcompletionconditions
  fullnamedisplay: string; // fullnamedisplay
  viewurl: string; // viewurl
  courseimage: string; // courseimage
  progress?: number; // progress
  hasprogress: boolean; // hasprogress
  isfavourite: boolean; // isfavourite
  hidden: boolean; // hidden
  timeaccess?: number; // timeaccess
  showshortname: boolean; // showshortname
  coursecategory: string; // coursecategory
}

export interface core_course_set_favourite_courses_parameters_courses {
  id: number; // course ID
  favourite: boolean; // favourite status
}

export interface core_course_set_favourite_courses_parameters {
  courses: core_course_set_favourite_courses_parameters_courses[];
}

export interface core_course_set_favourite_courses_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_course_set_favourite_courses_returns {
  warnings?: core_course_set_favourite_courses_returns_warnings[]; // list of warnings
}

export interface core_course_get_enrolled_users_by_cmid_parameters {
  cmid: number; // id of the course module
  groupid?: number; // default: int(0) // id of the group
  onlyactive?: boolean; // default: bool(false) // whether to return only active users or all.
}

export interface core_course_get_enrolled_users_by_cmid_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_course_get_enrolled_users_by_cmid_returns_users {
  id: number; // ID of the user
  profileimage?: string; // The location of the users larger image
  fullname?: string; // The full name of the user
  firstname?: string; // The first name(s) of the user
  lastname?: string; // The family name of the user
}

export interface core_course_get_enrolled_users_by_cmid_returns {
  users: core_course_get_enrolled_users_by_cmid_returns_users[];
  warnings?: core_course_get_enrolled_users_by_cmid_returns_warnings[]; // list of warnings
}

export interface core_course_add_content_item_to_user_favourites_parameters {
  componentname: string; // frankenstyle name of the component to which the content item belongs
  contentitemid: number; // id of the content item
}

export interface core_course_add_content_item_to_user_favourites_returns {
  id: number; // The id of the content item
  name: string; // Name of the content item
  title: string; // The string title of the content item, human readable
  link: string; // The link to the content item creation page
  icon: string; // Html containing the icon for the content item
  help: string; // Html description / help for the content item
  archetype: string; // The archetype of the module exposing the content item
  componentname: string; // The name of the component exposing the content item
  purpose: string; // The purpose of the component exposing the content item
  favourite: boolean; // Has the user favourited the content item
  legacyitem: boolean; // If this item was pulled from the old callback and has no item id.
  recommended: boolean; // Has this item been recommended
}

export interface core_course_remove_content_item_from_user_favourites_parameters {
  componentname: string; // frankenstyle name of the component to which the content item belongs
  contentitemid: number; // id of the content item
}

export interface core_course_remove_content_item_from_user_favourites_returns {
  id: number; // The id of the content item
  name: string; // Name of the content item
  title: string; // The string title of the content item, human readable
  link: string; // The link to the content item creation page
  icon: string; // Html containing the icon for the content item
  help: string; // Html description / help for the content item
  archetype: string; // The archetype of the module exposing the content item
  componentname: string; // The name of the component exposing the content item
  purpose: string; // The purpose of the component exposing the content item
  favourite: boolean; // Has the user favourited the content item
  legacyitem: boolean; // If this item was pulled from the old callback and has no item id.
  recommended: boolean; // Has this item been recommended
}

export interface core_course_get_course_content_items_parameters {
  courseid: number; // ID of the course
}

export interface core_course_get_course_content_items_returns_content_items {
  id: number; // The id of the content item
  name: string; // Name of the content item
  title: string; // The string title of the content item, human readable
  link: string; // The link to the content item creation page
  icon: string; // Html containing the icon for the content item
  help: string; // Html description / help for the content item
  archetype: string; // The archetype of the module exposing the content item
  componentname: string; // The name of the component exposing the content item
  purpose: string; // The purpose of the component exposing the content item
  favourite: boolean; // Has the user favourited the content item
  legacyitem: boolean; // If this item was pulled from the old callback and has no item id.
  recommended: boolean; // Has this item been recommended
}

export interface core_course_get_course_content_items_returns {
  content_items: core_course_get_course_content_items_returns_content_items[];
}

export interface core_course_get_activity_chooser_footer_parameters {
  courseid: number; // ID of the course
  sectionid: number; // ID of the section
}

export interface core_course_get_activity_chooser_footer_returns {
  footer: boolean; // Is a footer being return by this request?
  customfooterjs?: string; // The path to the plugin JS file
  customfootertemplate?: string; // The prerendered footer
  customcarouseltemplate?: string; // Either "" or the prerendered carousel page
}

export interface core_course_toggle_activity_recommendation_parameters {
  area: string; // The favourite area (itemtype)
  id: number; // id of the activity or whatever
}

export interface core_course_toggle_activity_recommendation_returns {
  id: number; // id of the activity or whatever
  area: string; // The favourite area (itemtype)
  status: boolean; // If created or deleted
}

export interface core_enrol_get_course_enrolment_methods_parameters {
  courseid: number; // Course id
}

export interface core_enrol_get_course_enrolment_methods_returns_item {
  id: number; // id of course enrolment instance
  courseid: number; // id of course
  type: string; // type of enrolment plugin
  name: string; // name of enrolment plugin
  status: string; // status of enrolment plugin
  wsfunction?: string; // webservice function to get more information
}

export interface core_enrol_get_enrolled_users_parameters_options {
  name: string; // option name
  value: string; // option value
}

export interface core_enrol_get_enrolled_users_parameters {
  courseid: number; // course id
  options?: core_enrol_get_enrolled_users_parameters_options[]; // default: array(0) { } // Option names: * withcapability (string) return only users with this capability. This option requires 'moodle/role:review' on the course context. * groupid (integer) return only users in this group id. If the course has groups enabled and this param isn't defined, returns all the viewable users. This option requires 'moodle/site:accessallgroups' on the course context if the user doesn't belong to the group. * onlyactive (integer) return only users with active enrolments and matching time restrictions. This option requires 'moodle/course:enrolreview' on the course context. Please note that this option can't be used together with onlysuspended (only one can be active). * onlysuspended (integer) return only suspended users. This option requires 'moodle/course:enrolreview' on the course context. Please note that this option can't be used together with onlyactive (only one can be active). * userfields ('string, string, ...') return only the values of these user fields. * limitfrom (integer) sql limit from. * limitnumber (integer) maximum number of returned users. * sortby (string) sort by id, firstname or lastname. For ordering like the site does, use siteorder. * sortdirection (string) ASC or DESC
}

export interface core_enrol_get_enrolled_users_returns_item_enrolledcourses {
  id: number; // Id of the course
  fullname: string; // Fullname of the course
  shortname: string; // Shortname of the course
}

export interface core_enrol_get_enrolled_users_returns_item_preferences {
  name: string; // The name of the preferences
  value: string; // The value of the custom field
}

export interface core_enrol_get_enrolled_users_returns_item_roles {
  roleid: number; // role id
  name: string; // role name
  shortname: string; // role shortname
  sortorder: number; // role sortorder
}

export interface core_enrol_get_enrolled_users_returns_item_groups {
  id: number; // group id
  name: string; // group name
  description: string; // group description
  descriptionformat: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
}

export interface core_enrol_get_enrolled_users_returns_item_customfields {
  type: string; // The type of the custom field - text field, checkbox...
  value: string; // The value of the custom field
  name: string; // The name of the custom field
  shortname: string; // The shortname of the custom field - to be able to build the field class in the code
}

export interface core_enrol_get_enrolled_users_returns_item {
  id: number; // ID of the user
  username?: string; // Username policy is defined in Moodle security config
  firstname?: string; // The first name(s) of the user
  lastname?: string; // The family name of the user
  fullname: string; // The fullname of the user
  email?: string; // An email address - allow email as root@localhost
  address?: string; // Postal address
  phone1?: string; // Phone 1
  phone2?: string; // Phone 2
  department?: string; // department
  institution?: string; // institution
  idnumber?: string; // An arbitrary ID code number perhaps from the institution
  interests?: string; // user interests (separated by commas)
  firstaccess?: number; // first access to the site (0 if never)
  lastaccess?: number; // last access to the site (0 if never)
  lastcourseaccess?: number; // last access to the course (0 if never)
  description?: string; // User profile description
  descriptionformat?: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  city?: string; // Home city of the user
  country?: string; // Home country code of the user, such as AU or CZ
  profileimageurlsmall?: string; // User image profile URL - small version
  profileimageurl?: string; // User image profile URL - big version
  customfields?: core_enrol_get_enrolled_users_returns_item_customfields[]; // User custom fields (also known as user profil fields)
  groups?: core_enrol_get_enrolled_users_returns_item_groups[]; // user groups
  roles?: core_enrol_get_enrolled_users_returns_item_roles[]; // user roles
  preferences?: core_enrol_get_enrolled_users_returns_item_preferences[]; // User preferences
  enrolledcourses?: core_enrol_get_enrolled_users_returns_item_enrolledcourses[]; // Courses where the user is enrolled - limited by which courses the user is able to see
}

export interface core_enrol_get_enrolled_users_with_capability_parameters_options {
  name: string; // option name
  value: string; // option value
}

type core_enrol_get_enrolled_users_with_capability_parameters_coursecapabilities_capabilities = string;

export interface core_enrol_get_enrolled_users_with_capability_parameters_coursecapabilities {
  courseid: number; // Course ID number in the Moodle course table
  capabilities: core_enrol_get_enrolled_users_with_capability_parameters_coursecapabilities_capabilities[];
}

export interface core_enrol_get_enrolled_users_with_capability_parameters {
  coursecapabilities: core_enrol_get_enrolled_users_with_capability_parameters_coursecapabilities[]; // course id and associated capability name
  options?: core_enrol_get_enrolled_users_with_capability_parameters_options[]; // default: array(0) { } // Option names: * groupid (integer) return only users in this group id. Requires 'moodle/site:accessallgroups' . * onlyactive (integer) only users with active enrolments. Requires 'moodle/course:enrolreview' . * userfields ('string, string, ...') return only the values of these user fields. * limitfrom (integer) sql limit from. * limitnumber (integer) max number of users per course and capability.
}

export interface core_enrol_get_enrolled_users_with_capability_returns_item_users_enrolledcourses {
  id: number; // Id of the course
  fullname: string; // Fullname of the course
  shortname: string; // Shortname of the course
}

export interface core_enrol_get_enrolled_users_with_capability_returns_item_users_preferences {
  name: string; // The name of the preferences
  value: string; // The value of the custom field
}

export interface core_enrol_get_enrolled_users_with_capability_returns_item_users_roles {
  roleid: number; // role id
  name: string; // role name
  shortname: string; // role shortname
  sortorder: number; // role sortorder
}

export interface core_enrol_get_enrolled_users_with_capability_returns_item_users_groups {
  id: number; // group id
  name: string; // group name
  description: string; // group description
}

export interface core_enrol_get_enrolled_users_with_capability_returns_item_users_customfields {
  type: string; // The type of the custom field
  value: string; // The value of the custom field
  name: string; // The name of the custom field
  shortname: string; // The shortname of the custom field
}

export interface core_enrol_get_enrolled_users_with_capability_returns_item_users {
  id: number; // ID of the user
  username?: string; // Username
  firstname?: string; // The first name(s) of the user
  lastname?: string; // The family name of the user
  fullname: string; // The fullname of the user
  email?: string; // Email address
  address?: string; // Postal address
  phone1?: string; // Phone 1
  phone2?: string; // Phone 2
  department?: string; // department
  institution?: string; // institution
  interests?: string; // user interests (separated by commas)
  firstaccess?: number; // first access to the site (0 if never)
  lastaccess?: number; // last access to the site (0 if never)
  lastcourseaccess?: number; // last access to the course (0 if never)
  description?: string; // User profile description
  descriptionformat?: number; // User profile description format
  city?: string; // Home city of the user
  country?: string; // Country code of the user, such as AU or CZ
  profileimageurlsmall?: string; // User image profile URL - small
  profileimageurl?: string; // User image profile URL - big
  customfields?: core_enrol_get_enrolled_users_with_capability_returns_item_users_customfields[]; // User custom fields (also known as user profil fields)
  groups?: core_enrol_get_enrolled_users_with_capability_returns_item_users_groups[]; // user groups
  roles?: core_enrol_get_enrolled_users_with_capability_returns_item_users_roles[]; // user roles
  preferences?: core_enrol_get_enrolled_users_with_capability_returns_item_users_preferences[]; // User preferences
  enrolledcourses?: core_enrol_get_enrolled_users_with_capability_returns_item_users_enrolledcourses[]; // Courses where the user is enrolled - limited by which courses the user is able to see
}

export interface core_enrol_get_enrolled_users_with_capability_returns_item {
  courseid: number; // Course ID number in the Moodle course table
  capability: string; // Capability name
  users: core_enrol_get_enrolled_users_with_capability_returns_item_users[]; // List of users that are enrolled in the course and have the specified capability
}

export interface core_enrol_get_potential_users_parameters {
  courseid: number; // course id
  enrolid: number; // enrolment id
  search: string; // query
  searchanywhere: boolean; // find a match anywhere, or only at the beginning
  page: number; // Page number
  perpage: number; // Number per page
}

export interface core_enrol_get_potential_users_returns_item_preferences {
  name: string; // The name of the preferences
  value: string; // The value of the preference
}

export interface core_enrol_get_potential_users_returns_item_customfields {
  type: string; // The type of the custom field - text field, checkbox...
  value: string; // The value of the custom field
  name: string; // The name of the custom field
  shortname: string; // The shortname of the custom field - to be able to build the field class in the code
}

export interface core_enrol_get_potential_users_returns_item {
  id: number; // ID of the user
  username?: string; // The username
  firstname?: string; // The first name(s) of the user
  lastname?: string; // The family name of the user
  fullname: string; // The fullname of the user
  email?: string; // An email address - allow email as root@localhost
  address?: string; // Postal address
  phone1?: string; // Phone 1
  phone2?: string; // Phone 2
  department?: string; // department
  institution?: string; // institution
  idnumber?: string; // An arbitrary ID code number perhaps from the institution
  interests?: string; // user interests (separated by commas)
  firstaccess?: number; // first access to the site (0 if never)
  lastaccess?: number; // last access to the site (0 if never)
  auth?: string; // Auth plugins include manual, ldap, etc
  suspended?: boolean; // Suspend user account, either false to enable user login or true to disable it
  confirmed?: boolean; // Active user: 1 if confirmed, 0 otherwise
  lang?: string; // Language code such as "en", must exist on server
  calendartype?: string; // Calendar type such as "gregorian", must exist on server
  theme?: string; // Theme name such as "standard", must exist on server
  timezone?: string; // Timezone code such as Australia/Perth, or 99 for default
  mailformat?: number; // Mail format code is 0 for plain text, 1 for HTML etc
  description?: string; // User profile description
  descriptionformat?: number; // int format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  city?: string; // Home city of the user
  country?: string; // Home country code of the user, such as AU or CZ
  profileimageurlsmall: string; // User image profile URL - small version
  profileimageurl: string; // User image profile URL - big version
  customfields?: core_enrol_get_potential_users_returns_item_customfields[]; // User custom fields (also known as user profile fields)
  preferences?: core_enrol_get_potential_users_returns_item_preferences[]; // Users preferences
}

export interface core_enrol_search_users_parameters {
  courseid: number; // course id
  search: string; // query
  searchanywhere: boolean; // find a match anywhere, or only at the beginning
  page: number; // Page number
  perpage: number; // Number per page
}

export interface core_enrol_search_users_returns_item_preferences {
  name: string; // The name of the preferences
  value: string; // The value of the preference
}

export interface core_enrol_search_users_returns_item_customfields {
  type: string; // The type of the custom field - text field, checkbox...
  value: string; // The value of the custom field
  name: string; // The name of the custom field
  shortname: string; // The shortname of the custom field - to be able to build the field class in the code
}

export interface core_enrol_search_users_returns_item {
  id: number; // ID of the user
  username?: string; // The username
  firstname?: string; // The first name(s) of the user
  lastname?: string; // The family name of the user
  fullname: string; // The fullname of the user
  email?: string; // An email address - allow email as root@localhost
  address?: string; // Postal address
  phone1?: string; // Phone 1
  phone2?: string; // Phone 2
  department?: string; // department
  institution?: string; // institution
  idnumber?: string; // An arbitrary ID code number perhaps from the institution
  interests?: string; // user interests (separated by commas)
  firstaccess?: number; // first access to the site (0 if never)
  lastaccess?: number; // last access to the site (0 if never)
  auth?: string; // Auth plugins include manual, ldap, etc
  suspended?: boolean; // Suspend user account, either false to enable user login or true to disable it
  confirmed?: boolean; // Active user: 1 if confirmed, 0 otherwise
  lang?: string; // Language code such as "en", must exist on server
  calendartype?: string; // Calendar type such as "gregorian", must exist on server
  theme?: string; // Theme name such as "standard", must exist on server
  timezone?: string; // Timezone code such as Australia/Perth, or 99 for default
  mailformat?: number; // Mail format code is 0 for plain text, 1 for HTML etc
  description?: string; // User profile description
  descriptionformat?: number; // int format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  city?: string; // Home city of the user
  country?: string; // Home country code of the user, such as AU or CZ
  profileimageurlsmall: string; // User image profile URL - small version
  profileimageurl: string; // User image profile URL - big version
  customfields?: core_enrol_search_users_returns_item_customfields[]; // User custom fields (also known as user profile fields)
  preferences?: core_enrol_search_users_returns_item_preferences[]; // Users preferences
}

export interface core_enrol_get_users_courses_parameters {
  userid: number; // user id
  returnusercount?: boolean; // default: bool(true) // Include count of enrolled users for each course? This can add several seconds to the response time if a user is on several large courses, so set this to false if the value will not be used to improve performance.
}

export interface core_enrol_get_users_courses_returns_item_overviewfiles {
  filename?: string; // File name.
  filepath?: string; // File path.
  filesize?: number; // File size.
  fileurl?: string; // Downloadable file url.
  timemodified?: number; // Time modified.
  mimetype?: string; // File mime type.
  isexternalfile?: boolean; // Whether is an external file.
  repositorytype?: string; // The repository type for external files.
}

export interface core_enrol_get_users_courses_returns_item {
  id: number; // id of course
  shortname: string; // short name of course
  fullname: string; // long name of course
  displayname?: string; // course display name for lists.
  enrolledusercount?: number; // Number of enrolled users in this course
  idnumber: string; // id number of course
  visible: number; // 1 means visible, 0 means not yet visible course
  summary?: string; // summary
  summaryformat?: number; // summary format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  format?: string; // course format: weeks, topics, social, site
  showgrades?: boolean; // true if grades are shown, otherwise false
  lang?: string; // forced course language
  enablecompletion?: boolean; // true if completion is enabled, otherwise false
  completionhascriteria?: boolean; // If completion criteria is set.
  completionusertracked?: boolean; // If the user is completion tracked.
  category?: number; // course category id
  progress?: number; // Progress percentage
  completed?: boolean; // Whether the course is completed.
  startdate?: number; // Timestamp when the course start
  enddate?: number; // Timestamp when the course end
  marker?: number; // Course section marker.
  lastaccess?: number; // Last access to the course (timestamp).
  isfavourite?: boolean; // If the user marked this course a favourite.
  hidden?: boolean; // If the user hide the course from the dashboard.
  overviewfiles?: core_enrol_get_users_courses_returns_item_overviewfiles[]; // Overview files attached to this course.
  showactivitydates: boolean; // Whether the activity dates are shown or not
  showcompletionconditions: boolean; // Whether the activity completion conditions are shown or not
  timemodified?: number; // Last time course settings were updated (timestamp).
}

export interface core_enrol_submit_user_enrolment_form_parameters {
  formdata: string; // The data from the event form
}

export interface core_enrol_submit_user_enrolment_form_returns {
  result: boolean; // True if the user's enrolment was successfully updated
  validationerror: boolean; // default: bool(false) // Indicates invalid form data
}

export interface core_enrol_unenrol_user_enrolment_parameters {
  ueid: number; // User enrolment ID
}

export interface core_enrol_unenrol_user_enrolment_returns_errors {
  key: string; // The data that failed the validation
  message: string; // The error message
}

export interface core_enrol_unenrol_user_enrolment_returns {
  result: boolean; // True if the user's enrolment was successfully updated
  errors: core_enrol_unenrol_user_enrolment_returns_errors[]; // List of validation errors
}

export interface core_fetch_notifications_parameters {
  contextid: number; // Context ID
}

export interface core_fetch_notifications_returns_item_variables {
  message: string; // HTML content of the Notification
  extraclasses: string; // Extra classes to provide to the tmeplate
  announce: string; // Whether to announce
  closebutton: string; // Whether to close
}

export interface core_fetch_notifications_returns_item {
  template: string; // Name of the template
  variables: core_fetch_notifications_returns_item_variables;
}

export interface core_session_touch_parameters {}

type core_session_touch_returns = boolean;

export interface core_session_time_remaining_parameters {}

export interface core_session_time_remaining_returns {
  userid: number; // The current user id.
  timeremaining: number; // The number of seconds remaining in this session.
}

export interface core_files_get_files_parameters {
  contextid: number; // context id Set to -1 to use contextlevel and instanceid.
  component: string; // component
  filearea: string; // file area
  itemid: number; // associated id
  filepath: string; // file path
  filename: string; // file name
  modified?: number; // default: NULL // timestamp to return files changed after this time.
  contextlevel?: string; // default: NULL // The context level for the file location.
  instanceid?: number; // default: NULL // The instance id for where the file is located.
}

export interface core_files_get_files_returns_files {
  contextid: number;
  component: string;
  filearea: string;
  itemid: number;
  filepath: string;
  filename: string;
  isdir: boolean;
  url: string;
  timemodified: number;
  timecreated?: number; // Time created
  filesize?: number; // File size
  author?: string; // File owner
  license?: string; // File license
}

export interface core_files_get_files_returns_parents {
  contextid: number;
  component: string;
  filearea: string;
  itemid: number;
  filepath: string;
  filename: string;
}

export interface core_files_get_files_returns {
  parents: core_files_get_files_returns_parents[];
  files: core_files_get_files_returns_files[];
}

export interface core_files_upload_parameters {
  contextid?: number; // default: NULL // context id
  component: string; // component
  filearea: string; // file area
  itemid: number; // associated id
  filepath: string; // file path
  filename: string; // file name
  filecontent: string; // file content
  contextlevel?: string; // default: NULL // The context level to put the file in, (block, course, coursecat, system, user, module)
  instanceid?: number; // default: NULL // The Instance id of item associated with the context level
}

export interface core_files_upload_returns {
  contextid: number;
  component: string;
  filearea: string;
  itemid: number;
  filepath: string;
  filename: string;
  url: string;
}

export interface core_files_delete_draft_files_parameters_files {
  filepath: string; // Path to the file or directory to delete.
  filename: string; // Name of the file to delete.
}

export interface core_files_delete_draft_files_parameters {
  draftitemid: number; // Item id of the draft file area
  files: core_files_delete_draft_files_parameters_files[]; // Files or directories to be deleted.
}

export interface core_files_delete_draft_files_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

type core_files_delete_draft_files_returns_parentpaths = string;

export interface core_files_delete_draft_files_returns {
  parentpaths: core_files_delete_draft_files_returns_parentpaths[];
  warnings?: core_files_delete_draft_files_returns_warnings[]; // list of warnings
}

export interface core_files_get_unused_draft_itemid_parameters {}

export interface core_files_get_unused_draft_itemid_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_files_get_unused_draft_itemid_returns {
  component: string; // File area component.
  contextid: number; // File area context.
  userid: number; // File area user id.
  filearea: string; // File area name.
  itemid: number; // File are item id.
  warnings?: core_files_get_unused_draft_itemid_returns_warnings[]; // list of warnings
}

export interface core_form_get_filetypes_browser_data_parameters {
  onlytypes?: string; // default: string(0) "" // Limit the browser to the given groups and extensions
  allowall?: boolean; // default: bool(true) // Allows to select All file types, does not apply with onlytypes are set.
  current?: string; // default: string(0) "" // Current types that should be selected.
}

export interface core_form_get_filetypes_browser_data_returns_groups_types {
  key: string; // The file type identifier
  name: string; // The file type name
  selected: boolean; // Should it be marked as selected
  ext: string; // The file extension associated with the file type
}

export interface core_form_get_filetypes_browser_data_returns_groups {
  key: string; // The file type group identifier
  name: string; // The file type group name
  selectable: boolean; // Can it be marked as selected
  selected: boolean; // Should it be marked as selected
  ext: string; // The list of file extensions associated with the group
  expanded: boolean; // Should the group start as expanded or collapsed
  types: core_form_get_filetypes_browser_data_returns_groups_types[]; // List of file types in the group
}

export interface core_form_get_filetypes_browser_data_returns {
  groups: core_form_get_filetypes_browser_data_returns_groups[]; // List of file type groups
}

export interface core_form_dynamic_form_parameters {
  form: string; // Form class
  formdata: string; // url-encoded form data
}

export interface core_form_dynamic_form_returns {
  submitted: boolean; // If form was submitted and validated
  data?: string; // JSON-encoded return data from form processing method
  html?: string; // HTML fragment of the form
  javascript?: string; // JavaScript fragment of the form
}

export interface core_get_component_strings_parameters {
  component: string; // component
  lang?: string; // default: NULL // lang
}

export interface core_get_component_strings_returns_item {
  stringid: string; // string id
  string: string; // translated string
}

export interface core_get_fragment_parameters_args {
  name: string; // param name
  value: string; // param value
}

export interface core_get_fragment_parameters {
  component: string; // Component for the callback e.g. mod_assign
  callback: string; // Name of the callback to execute
  contextid: number; // Context ID that the fragment is from
  args?: core_get_fragment_parameters_args[]; // args for the callback are optional
}

export interface core_get_fragment_returns {
  html: string; // HTML fragment.
  javascript: string; // JavaScript fragment
}

export interface core_get_string_parameters_stringparams {
  name?: string; // param name - if the string expect only one $a parameter then don't send this field, just send the value.
  value: string; // param value
}

export interface core_get_string_parameters {
  stringid: string; // string identifier
  component?: string; // default: string(6) "moodle" // component
  lang?: string; // default: NULL // lang
  stringparams?: core_get_string_parameters_stringparams[]; // default: array(0) { } // the definition of a string param (i.e. {$a->name})
}

type core_get_string_returns = string;

export interface core_get_strings_parameters_strings_stringparams {
  name?: string; // param name - if the string expect only one $a parameter then don't send this field, just send the value.
  value: string; // param value
}

export interface core_get_strings_parameters_strings {
  stringid: string; // string identifier
  component?: string; // default: string(6) "moodle" // component
  lang?: string; // default: NULL // lang
  stringparams?: core_get_strings_parameters_strings_stringparams[]; // default: array(0) { } // the definition of a string param (i.e. {$a->name})
}

export interface core_get_strings_parameters {
  strings: core_get_strings_parameters_strings[];
}

export interface core_get_strings_returns_item {
  stringid: string; // string id
  component: string; // string component
  lang: string; // lang
  string: string; // translated string
}

export interface core_get_user_dates_parameters_timestamps {
  timestamp: number; // unix timestamp
  format: string; // format string
  type?: string; // default: NULL // The calendar type
  fixday?: number; // default: int(1) // Remove leading zero for day
  fixhour?: number; // default: int(1) // Remove leading zero for hour
}

export interface core_get_user_dates_parameters {
  contextid?: number; // default: int(0) // Context ID. Either use this value, or level and instanceid.
  contextlevel?: string; // default: string(0) "" // Context level. To be used with instanceid.
  instanceid?: number; // default: int(0) // Context instance ID. To be used with level
  timestamps: core_get_user_dates_parameters_timestamps[];
}

type core_get_user_dates_returns_dates = string;

export interface core_get_user_dates_returns {
  dates: core_get_user_dates_returns_dates[];
}

export interface core_grades_update_grades_parameters_itemdetails {
  itemname?: string; // The grade item name
  idnumber?: number; // Arbitrary ID provided by the module responsible for the grade item
  gradetype?: number; // The type of grade (0 = none, 1 = value, 2 = scale, 3 = text)
  grademax?: number; // Maximum grade allowed
  grademin?: number; // Minimum grade allowed
  scaleid?: number; // The ID of the custom scale being is used
  multfactor?: number; // Multiply all grades by this number
  plusfactor?: number; // Add this to all grades
  deleted?: boolean; // True if the grade item should be deleted
  hidden?: boolean; // True if the grade item is hidden
}

export interface core_grades_update_grades_parameters_grades {
  studentid: number; // Student ID
  grade: number; // Student grade
  str_feedback?: string; // A string representation of the feedback from the grader
}

export interface core_grades_update_grades_parameters {
  source: string; // The source of the grade update
  courseid: number; // id of course
  component: string; // A component, for example mod_forum or mod_quiz
  activityid: number; // The activity ID
  itemnumber: number; // grade item ID number for modules that have multiple grades. Typically this is 0.
  grades?: core_grades_update_grades_parameters_grades[]; // default: array(0) { } // Any student grades to alter
  itemdetails?: core_grades_update_grades_parameters_itemdetails; // default: array(0) { } // Any grade item settings to alter
}

type core_grades_update_grades_returns = number;

export interface core_grades_grader_gradingpanel_point_fetch_parameters {
  component: string; // The name of the component
  contextid: number; // The ID of the context being graded
  itemname: string; // The grade item itemname being graded
  gradeduserid: number; // The ID of the user show
}

export interface core_grades_grader_gradingpanel_point_fetch_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_grades_grader_gradingpanel_point_fetch_returns_grade {
  grade: number; // The numeric grade
  usergrade: string; // Current user grade
  maxgrade: string; // Max possible grade
  gradedby: string; // The assumed grader of this grading instance
  timecreated: number; // The time that the grade was created
  timemodified: number; // The time that the grade was last updated
}

export interface core_grades_grader_gradingpanel_point_fetch_returns {
  templatename: string; // The template to use when rendering this data
  hasgrade: boolean; // Does the user have a grade?
  grade: core_grades_grader_gradingpanel_point_fetch_returns_grade;
  warnings?: core_grades_grader_gradingpanel_point_fetch_returns_warnings[]; // list of warnings
}

export interface core_grades_grader_gradingpanel_point_store_parameters {
  component: string; // The name of the component
  contextid: number; // The ID of the context being graded
  itemname: string; // The grade item itemname being graded
  gradeduserid: number; // The ID of the user show
  notifyuser?: boolean; // default: bool(false) // Wheteher to notify the user or not
  formdata: string; // The serialised form data representing the grade
}

export interface core_grades_grader_gradingpanel_point_store_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_grades_grader_gradingpanel_point_store_returns_grade {
  grade: number; // The numeric grade
  usergrade: string; // Current user grade
  maxgrade: string; // Max possible grade
  gradedby: string; // The assumed grader of this grading instance
  timecreated: number; // The time that the grade was created
  timemodified: number; // The time that the grade was last updated
}

export interface core_grades_grader_gradingpanel_point_store_returns {
  templatename: string; // The template to use when rendering this data
  hasgrade: boolean; // Does the user have a grade?
  grade: core_grades_grader_gradingpanel_point_store_returns_grade;
  warnings?: core_grades_grader_gradingpanel_point_store_returns_warnings[]; // list of warnings
}

export interface core_grades_grader_gradingpanel_scale_fetch_parameters {
  component: string; // The name of the component
  contextid: number; // The ID of the context being graded
  itemname: string; // The grade item itemname being graded
  gradeduserid: number; // The ID of the user show
}

export interface core_grades_grader_gradingpanel_scale_fetch_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_grades_grader_gradingpanel_scale_fetch_returns_grade_options {
  value: number; // The grade value
  title: string; // The description fo the option
  selected: boolean; // Whether this item is currently selected
}

export interface core_grades_grader_gradingpanel_scale_fetch_returns_grade {
  options: core_grades_grader_gradingpanel_scale_fetch_returns_grade_options[]; // The description of the grade option
  usergrade: string; // Current user grade
  maxgrade: string; // Max possible grade
  gradedby: string; // The assumed grader of this grading instance
  timecreated: number; // The time that the grade was created
  timemodified: number; // The time that the grade was last updated
}

export interface core_grades_grader_gradingpanel_scale_fetch_returns {
  templatename: string; // The template to use when rendering this data
  hasgrade: boolean; // Does the user have a grade?
  grade: core_grades_grader_gradingpanel_scale_fetch_returns_grade;
  warnings?: core_grades_grader_gradingpanel_scale_fetch_returns_warnings[]; // list of warnings
}

export interface core_grades_grader_gradingpanel_scale_store_parameters {
  component: string; // The name of the component
  contextid: number; // The ID of the context being graded
  itemname: string; // The grade item itemname being graded
  gradeduserid: number; // The ID of the user show
  notifyuser?: boolean; // default: bool(false) // Wheteher to notify the user or not
  formdata: string; // The serialised form data representing the grade
}

export interface core_grades_grader_gradingpanel_scale_store_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_grades_grader_gradingpanel_scale_store_returns_grade_options {
  value: number; // The grade value
  title: string; // The description fo the option
  selected: boolean; // Whether this item is currently selected
}

export interface core_grades_grader_gradingpanel_scale_store_returns_grade {
  options: core_grades_grader_gradingpanel_scale_store_returns_grade_options[]; // The description of the grade option
  usergrade: string; // Current user grade
  maxgrade: string; // Max possible grade
  gradedby: string; // The assumed grader of this grading instance
  timecreated: number; // The time that the grade was created
  timemodified: number; // The time that the grade was last updated
}

export interface core_grades_grader_gradingpanel_scale_store_returns {
  templatename: string; // The template to use when rendering this data
  hasgrade: boolean; // Does the user have a grade?
  grade: core_grades_grader_gradingpanel_scale_store_returns_grade;
  warnings?: core_grades_grader_gradingpanel_scale_store_returns_warnings[]; // list of warnings
}

export interface core_grades_create_gradecategory_parameters_options {
  aggregation?: number; // aggregation method
  aggregateonlygraded?: boolean; // exclude empty grades
  aggregateoutcomes?: boolean; // aggregate outcomes
  droplow?: number; // drop low grades
  itemname?: string; // the category total name
  iteminfo?: string; // the category iteminfo
  idnumber?: string; // the category idnumber
  gradetype?: number; // the grade type
  grademax?: number; // the grade max
  grademin?: number; // the grade min
  gradepass?: number; // the grade to pass
  display?: number; // the display type
  decimals?: number; // the decimal count
  hiddenuntil?: number; // grades hidden until
  locktime?: number; // lock grades after
  weightoverride?: boolean; // weight adjusted
  aggregationcoef2?: string; // weight coefficient
  parentcategoryid?: number; // The parent category id
  parentcategoryidnumber?: string; // the parent category idnumber
}

export interface core_grades_create_gradecategory_parameters {
  courseid: number; // id of course
  fullname: string; // fullname of category
  options?: core_grades_create_gradecategory_parameters_options; // default: array(0) { } // optional category data
}

export interface core_grades_create_gradecategory_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_grades_create_gradecategory_returns {
  categoryid?: number; // The ID of the created category
  warnings?: core_grades_create_gradecategory_returns_warnings[]; // list of warnings
}

export interface core_grades_create_gradecategories_parameters_categories_options {
  aggregation?: number; // aggregation method
  aggregateonlygraded?: boolean; // exclude empty grades
  aggregateoutcomes?: boolean; // aggregate outcomes
  droplow?: number; // drop low grades
  itemname?: string; // the category total name
  iteminfo?: string; // the category iteminfo
  idnumber?: string; // the category idnumber
  gradetype?: number; // the grade type
  grademax?: number; // the grade max
  grademin?: number; // the grade min
  gradepass?: number; // the grade to pass
  display?: number; // the display type
  decimals?: number; // the decimal count
  hiddenuntil?: number; // grades hidden until
  locktime?: number; // lock grades after
  weightoverride?: boolean; // weight adjusted
  aggregationcoef2?: string; // weight coefficient
  parentcategoryid?: number; // The parent category id
  parentcategoryidnumber?: string; // the parent category idnumber
}

export interface core_grades_create_gradecategories_parameters_categories {
  fullname: string; // fullname of category
  options?: core_grades_create_gradecategories_parameters_categories_options; // default: array(0) { } // optional category data
}

export interface core_grades_create_gradecategories_parameters {
  courseid: number; // id of course
  categories: core_grades_create_gradecategories_parameters_categories[]; // Categories to create
}

export interface core_grades_create_gradecategories_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

type core_grades_create_gradecategories_returns_categoryids = number;

export interface core_grades_create_gradecategories_returns {
  categoryids: core_grades_create_gradecategories_returns_categoryids[];
  warnings?: core_grades_create_gradecategories_returns_warnings[]; // list of warnings
}

export interface core_grades_get_enrolled_users_for_search_widget_parameters {
  courseid: number; // Course Id
  actionbaseurl: string; // The base URL for the user option
  groupid?: number; // default: int(0) // Group Id
}

export interface core_grades_get_enrolled_users_for_search_widget_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_grades_get_enrolled_users_for_search_widget_returns_users {
  id: number; // ID of the user
  profileimage?: string; // The location of the users larger image
  url?: string; // The link to the user report
  fullname?: string; // The full name of the user
  email?: string; // An email address - allow email as root@localhost
  active: boolean; // Are we currently on this item?
}

export interface core_grades_get_enrolled_users_for_search_widget_returns {
  users: core_grades_get_enrolled_users_for_search_widget_returns_users[];
  warnings?: core_grades_get_enrolled_users_for_search_widget_returns_warnings[]; // list of warnings
}

export interface core_grades_get_groups_for_search_widget_parameters {
  courseid: number; // Course Id
  actionbaseurl: string; // The base URL for the group action
}

export interface core_grades_get_groups_for_search_widget_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_grades_get_groups_for_search_widget_returns_groups {
  id: string; // An ID for the group
  url: string; // The link that applies the group action
  name: string; // The full name of the group
  active: boolean; // Are we currently on this item?
}

export interface core_grades_get_groups_for_search_widget_returns {
  groups: core_grades_get_groups_for_search_widget_returns_groups[];
  warnings?: core_grades_get_groups_for_search_widget_returns_warnings[]; // list of warnings
}

type core_grading_get_definitions_parameters_cmids = number;

export interface core_grading_get_definitions_parameters {
  cmids: core_grading_get_definitions_parameters_cmids[]; // 1 or more course module ids
  areaname: string; // area name
  activeonly?: boolean; // default: int(0) // Only the active method
}

export interface core_grading_get_definitions_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_grading_get_definitions_returns_areas_definitions_rubric_rubric_criteria_levels {
  id?: number; // level id
  score?: number; // score
  definition?: string; // definition
  definitionformat?: number; // definition format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
}

export interface core_grading_get_definitions_returns_areas_definitions_rubric_rubric_criteria {
  id?: number; // criterion id
  sortorder?: number; // sortorder
  description?: string; // description
  descriptionformat?: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  levels?: core_grading_get_definitions_returns_areas_definitions_rubric_rubric_criteria_levels[]; // levels
}

export interface core_grading_get_definitions_returns_areas_definitions_rubric {
  rubric_criteria?: core_grading_get_definitions_returns_areas_definitions_rubric_rubric_criteria[]; // definition details
}

export interface core_grading_get_definitions_returns_areas_definitions_guide_guide_comments {
  id?: number; // criterion id
  sortorder?: number; // sortorder
  description?: string; // description
  descriptionformat?: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
}

export interface core_grading_get_definitions_returns_areas_definitions_guide_guide_criteria {
  id?: number; // criterion id
  sortorder?: number; // sortorder
  description?: string; // description
  descriptionformat?: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  shortname: string; // description
  descriptionmarkers?: string; // markers description
  descriptionmarkersformat?: number; // descriptionmarkers format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  maxscore: number; // maximum score
}

export interface core_grading_get_definitions_returns_areas_definitions_guide {
  guide_criteria?: core_grading_get_definitions_returns_areas_definitions_guide_guide_criteria[];
  guide_comments?: core_grading_get_definitions_returns_areas_definitions_guide_guide_comments[]; // comments
}

export interface core_grading_get_definitions_returns_areas_definitions {
  id?: number; // definition id
  method: string; // method
  name: string; // name
  description?: string; // description
  descriptionformat?: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  status: number; // status
  copiedfromid?: number; // copied from id
  timecreated: number; // creation time
  usercreated: number; // user who created definition
  timemodified: number; // last modified time
  usermodified: number; // user who modified definition
  timecopied?: number; // time copied
  guide?: core_grading_get_definitions_returns_areas_definitions_guide; // items
  rubric?: core_grading_get_definitions_returns_areas_definitions_rubric; // items
}

export interface core_grading_get_definitions_returns_areas {
  cmid: number; // course module id
  contextid: number; // context id
  component: string; // component name
  areaname: string; // area name
  activemethod?: string; // active method
  definitions: core_grading_get_definitions_returns_areas_definitions[]; // definitions
}

export interface core_grading_get_definitions_returns {
  areas: core_grading_get_definitions_returns_areas[]; // list of grading areas
  warnings?: core_grading_get_definitions_returns_warnings[]; // list of warnings
}

export interface core_grading_get_gradingform_instances_parameters {
  definitionid: number; // definition id
  since?: number; // default: int(0) // submitted since
}

export interface core_grading_get_gradingform_instances_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_grading_get_gradingform_instances_returns_instances_rubric_criteria {
  id: number; // filling id
  criterionid: number; // criterion id
  levelid?: number; // level id
  remark?: string; // remark
  remarkformat?: number; // remark format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
}

export interface core_grading_get_gradingform_instances_returns_instances_rubric {
  criteria?: core_grading_get_gradingform_instances_returns_instances_rubric_criteria[]; // filling
}

export interface core_grading_get_gradingform_instances_returns_instances_guide_criteria {
  id: number; // filling id
  criterionid: number; // criterion id
  levelid?: number; // level id
  remark?: string; // remark
  remarkformat?: number; // remark format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  score: number; // maximum score
}

export interface core_grading_get_gradingform_instances_returns_instances_guide {
  criteria?: core_grading_get_gradingform_instances_returns_instances_guide_criteria[]; // filling
}

export interface core_grading_get_gradingform_instances_returns_instances {
  id: number; // instance id
  raterid: number; // rater id
  itemid: number; // item id
  rawgrade?: string; // raw grade
  status: number; // status
  feedback?: string; // feedback
  feedbackformat?: number; // feedback format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  timemodified: number; // modified time
  guide?: core_grading_get_gradingform_instances_returns_instances_guide; // items
  rubric?: core_grading_get_gradingform_instances_returns_instances_rubric; // items
}

export interface core_grading_get_gradingform_instances_returns {
  instances: core_grading_get_gradingform_instances_returns_instances[]; // list of grading instances
  warnings?: core_grading_get_gradingform_instances_returns_warnings[]; // list of warnings
}

export interface core_grading_save_definitions_parameters_areas_definitions_rubric_rubric_criteria_levels {
  id?: number; // level id
  score?: number; // score
  definition?: string; // definition
  definitionformat?: number; // definition format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
}

export interface core_grading_save_definitions_parameters_areas_definitions_rubric_rubric_criteria {
  id?: number; // criterion id
  sortorder?: number; // sortorder
  description?: string; // description
  descriptionformat?: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  levels?: core_grading_save_definitions_parameters_areas_definitions_rubric_rubric_criteria_levels[]; // levels
}

export interface core_grading_save_definitions_parameters_areas_definitions_rubric {
  rubric_criteria?: core_grading_save_definitions_parameters_areas_definitions_rubric_rubric_criteria[]; // definition details
}

export interface core_grading_save_definitions_parameters_areas_definitions_guide_guide_comments {
  id?: number; // criterion id
  sortorder?: number; // sortorder
  description?: string; // description
  descriptionformat?: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
}

export interface core_grading_save_definitions_parameters_areas_definitions_guide_guide_criteria {
  id?: number; // criterion id
  sortorder?: number; // sortorder
  description?: string; // description
  descriptionformat?: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  shortname: string; // description
  descriptionmarkers?: string; // markers description
  descriptionmarkersformat?: number; // descriptionmarkers format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  maxscore: number; // maximum score
}

export interface core_grading_save_definitions_parameters_areas_definitions_guide {
  guide_criteria?: core_grading_save_definitions_parameters_areas_definitions_guide_guide_criteria[];
  guide_comments?: core_grading_save_definitions_parameters_areas_definitions_guide_guide_comments[]; // comments
}

export interface core_grading_save_definitions_parameters_areas_definitions {
  id?: number; // definition id
  method: string; // method
  name: string; // name
  description?: string; // description
  descriptionformat?: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  status: number; // status
  copiedfromid?: number; // copied from id
  timecreated: number; // creation time
  usercreated: number; // user who created definition
  timemodified: number; // last modified time
  usermodified: number; // user who modified definition
  timecopied?: number; // time copied
  guide?: core_grading_save_definitions_parameters_areas_definitions_guide; // items
  rubric?: core_grading_save_definitions_parameters_areas_definitions_rubric; // items
}

export interface core_grading_save_definitions_parameters_areas {
  cmid: number; // course module id
  contextid: number; // context id
  component: string; // component name
  areaname: string; // area name
  activemethod?: string; // active method
  definitions: core_grading_save_definitions_parameters_areas_definitions[]; // definitions
}

export interface core_grading_save_definitions_parameters {
  areas: core_grading_save_definitions_parameters_areas[]; // areas with definitions to save
}

type core_grading_save_definitions_returns = null;

export interface core_group_add_group_members_parameters_members {
  groupid: number; // group record id
  userid: number; // user id
}

export interface core_group_add_group_members_parameters {
  members: core_group_add_group_members_parameters_members[];
}

type core_group_add_group_members_returns = null;

export interface core_group_assign_grouping_parameters_assignments {
  groupingid: number; // grouping record id
  groupid: number; // group record id
}

export interface core_group_assign_grouping_parameters {
  assignments: core_group_assign_grouping_parameters_assignments[];
}

type core_group_assign_grouping_returns = null;

export interface core_group_create_groupings_parameters_groupings {
  courseid: number; // id of course
  name: string; // multilang compatible name, course unique
  description: string; // grouping description text
  descriptionformat?: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  idnumber?: string; // id number
}

export interface core_group_create_groupings_parameters {
  groupings: core_group_create_groupings_parameters_groupings[]; // List of grouping object. A grouping has a courseid, a name and a description.
}

export interface core_group_create_groupings_returns_item {
  id: number; // grouping record id
  courseid: number; // id of course
  name: string; // multilang compatible name, course unique
  description: string; // grouping description text
  descriptionformat: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  idnumber: string; // id number
}

export interface core_group_create_groups_parameters_groups {
  courseid: number; // id of course
  name: string; // multilang compatible name, course unique
  description: string; // group description text
  descriptionformat?: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  enrolmentkey?: string; // group enrol secret phrase
  idnumber?: string; // id number
}

export interface core_group_create_groups_parameters {
  groups: core_group_create_groups_parameters_groups[]; // List of group object. A group has a courseid, a name, a description and an enrolment key.
}

export interface core_group_create_groups_returns_item {
  id: number; // group record id
  courseid: number; // id of course
  name: string; // multilang compatible name, course unique
  description: string; // group description text
  descriptionformat: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  enrolmentkey: string; // group enrol secret phrase
  idnumber: string; // id number
}

export interface core_group_delete_group_members_parameters_members {
  groupid: number; // group record id
  userid: number; // user id
}

export interface core_group_delete_group_members_parameters {
  members: core_group_delete_group_members_parameters_members[];
}

type core_group_delete_group_members_returns = null;

type core_group_delete_groupings_parameters_groupingids = number;

export interface core_group_delete_groupings_parameters {
  groupingids: core_group_delete_groupings_parameters_groupingids[];
}

type core_group_delete_groupings_returns = null;

type core_group_delete_groups_parameters_groupids = number;

export interface core_group_delete_groups_parameters {
  groupids: core_group_delete_groups_parameters_groupids[];
}

type core_group_delete_groups_returns = null;

export interface core_group_get_activity_allowed_groups_parameters {
  cmid: number; // course module id
  userid?: number; // default: int(0) // id of user, empty for current user
}

export interface core_group_get_activity_allowed_groups_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_group_get_activity_allowed_groups_returns_groups {
  id: number; // group record id
  name: string; // group name
  description: string; // group description text
  descriptionformat: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  idnumber: string; // id number
  courseid?: number; // course id
}

export interface core_group_get_activity_allowed_groups_returns {
  groups: core_group_get_activity_allowed_groups_returns_groups[];
  canaccessallgroups?: boolean; // Whether the user will be able to access all the activity groups.
  warnings?: core_group_get_activity_allowed_groups_returns_warnings[]; // list of warnings
}

export interface core_group_get_activity_groupmode_parameters {
  cmid: number; // course module id
}

export interface core_group_get_activity_groupmode_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_group_get_activity_groupmode_returns {
  groupmode: number; // group mode: 0 for no groups, 1 for separate groups, 2 for visible groups
  warnings?: core_group_get_activity_groupmode_returns_warnings[]; // list of warnings
}

export interface core_group_get_course_groupings_parameters {
  courseid: number; // id of course
}

export interface core_group_get_course_groupings_returns_item {
  id: number; // grouping record id
  courseid: number; // id of course
  name: string; // multilang compatible name, course unique
  description: string; // grouping description text
  descriptionformat: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  idnumber: string; // id number
}

export interface core_group_get_course_groups_parameters {
  courseid: number; // id of course
}

export interface core_group_get_course_groups_returns_item {
  id: number; // group record id
  courseid: number; // id of course
  name: string; // group name
  description: string; // group description text
  descriptionformat: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  enrolmentkey: string; // group enrol secret phrase
  idnumber: string; // id number
}

export interface core_group_get_course_user_groups_parameters {
  courseid?: number; // default: int(0) // Id of course (empty or 0 for all the courses where the user is enrolled).
  userid?: number; // default: int(0) // Id of user (empty or 0 for current user).
  groupingid?: number; // default: int(0) // returns only groups in the specified grouping
}

export interface core_group_get_course_user_groups_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_group_get_course_user_groups_returns_groups {
  id: number; // group record id
  name: string; // group name
  description: string; // group description text
  descriptionformat: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  idnumber: string; // id number
  courseid?: number; // course id
}

export interface core_group_get_course_user_groups_returns {
  groups: core_group_get_course_user_groups_returns_groups[];
  warnings?: core_group_get_course_user_groups_returns_warnings[]; // list of warnings
}

type core_group_get_group_members_parameters_groupids = number;

export interface core_group_get_group_members_parameters {
  groupids: core_group_get_group_members_parameters_groupids[];
}

type core_group_get_group_members_returns_item_userids = number;

export interface core_group_get_group_members_returns_item {
  groupid: number; // group record id
  userids: core_group_get_group_members_returns_item_userids[];
}

type core_group_get_groupings_parameters_groupingids = number;

export interface core_group_get_groupings_parameters {
  groupingids: core_group_get_groupings_parameters_groupingids[]; // List of grouping id. A grouping id is an integer.
  returngroups?: boolean; // default: int(0) // return associated groups
}

export interface core_group_get_groupings_returns_item_groups {
  id: number; // group record id
  courseid: number; // id of course
  name: string; // multilang compatible name, course unique
  description: string; // group description text
  descriptionformat: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  enrolmentkey: string; // group enrol secret phrase
  idnumber: string; // id number
}

export interface core_group_get_groupings_returns_item {
  id: number; // grouping record id
  courseid: number; // id of course
  name: string; // multilang compatible name, course unique
  description: string; // grouping description text
  descriptionformat: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  idnumber: string; // id number
  groups?: core_group_get_groupings_returns_item_groups[]; // optional groups
}

type core_group_get_groups_parameters_groupids = number;

export interface core_group_get_groups_parameters {
  groupids: core_group_get_groups_parameters_groupids[]; // List of group id. A group id is an integer.
}

export interface core_group_get_groups_returns_item {
  id: number; // group record id
  courseid: number; // id of course
  name: string; // group name
  description: string; // group description text
  descriptionformat: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  enrolmentkey: string; // group enrol secret phrase
  idnumber: string; // id number
}

export interface core_group_unassign_grouping_parameters_unassignments {
  groupingid: number; // grouping record id
  groupid: number; // group record id
}

export interface core_group_unassign_grouping_parameters {
  unassignments: core_group_unassign_grouping_parameters_unassignments[];
}

type core_group_unassign_grouping_returns = null;

export interface core_group_update_groupings_parameters_groupings {
  id: number; // id of grouping
  name: string; // multilang compatible name, course unique
  description: string; // grouping description text
  descriptionformat?: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  idnumber?: string; // id number
}

export interface core_group_update_groupings_parameters {
  groupings: core_group_update_groupings_parameters_groupings[]; // List of grouping object. A grouping has a courseid, a name and a description.
}

type core_group_update_groupings_returns = null;

export interface core_group_update_groups_parameters_groups {
  id: number; // ID of the group
  name: string; // multilang compatible name, course unique
  description?: string; // group description text
  descriptionformat?: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  enrolmentkey?: string; // group enrol secret phrase
  idnumber?: string; // id number
}

export interface core_group_update_groups_parameters {
  groups: core_group_update_groups_parameters_groups[]; // List of group objects. A group is found by the id, then all other details provided will be updated.
}

type core_group_update_groups_returns = null;

type core_message_mute_conversations_parameters_conversationids = number;

export interface core_message_mute_conversations_parameters {
  userid: number; // The id of the user who is blocking
  conversationids: core_message_mute_conversations_parameters_conversationids[];
}

export interface core_message_mute_conversations_returns_item {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

type core_message_unmute_conversations_parameters_conversationids = number;

export interface core_message_unmute_conversations_parameters {
  userid: number; // The id of the user who is unblocking
  conversationids: core_message_unmute_conversations_parameters_conversationids[];
}

export interface core_message_unmute_conversations_returns_item {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_message_block_user_parameters {
  userid: number; // The id of the user who is blocking
  blockeduserid: number; // The id of the user being blocked
}

export interface core_message_block_user_returns_item {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_message_get_contact_requests_parameters {
  userid: number; // The id of the user we want the requests for
  limitfrom?: number; // default: int(0) // Limit from
  limitnum?: number; // default: int(0) // Limit number
}

export interface core_message_get_contact_requests_returns_item_conversations {
  id: number; // Conversations id
  type: number; // Conversation type: private or public
  name: string; // Multilang compatible conversation name2
  timecreated: number; // The timecreated timestamp for the conversation
}

export interface core_message_get_contact_requests_returns_item_contactrequests {
  id: number; // The id of the contact request
  userid: number; // The id of the user who created the contact request
  requesteduserid: number; // The id of the user confirming the request
  timecreated: number; // The timecreated timestamp for the contact request
}

export interface core_message_get_contact_requests_returns_item {
  id: number; // The user id
  fullname: string; // The user's name
  profileurl: string; // The link to the user's profile page
  profileimageurl: string; // User picture URL
  profileimageurlsmall: string; // Small user picture URL
  isonline: boolean; // The user's online status
  showonlinestatus: boolean; // Show the user's online status?
  isblocked: boolean; // If the user has been blocked
  iscontact: boolean; // Is the user a contact?
  isdeleted: boolean; // Is the user deleted?
  canmessageevenifblocked: boolean; // If the user can still message even if they get blocked
  canmessage: boolean; // If the user can be messaged
  requirescontact: boolean; // If the user requires to be contacts
  contactrequests?: core_message_get_contact_requests_returns_item_contactrequests[]; // The contact requests
  conversations?: core_message_get_contact_requests_returns_item_conversations[]; // Conversations between users
}

export interface core_message_create_contact_request_parameters {
  userid: number; // The id of the user making the request
  requesteduserid: number; // The id of the user being requested
}

export interface core_message_create_contact_request_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_message_create_contact_request_returns_request {
  id: number; // Message id
  userid: number; // User from id
  requesteduserid: number; // User to id
  timecreated: number; // Time created
}

export interface core_message_create_contact_request_returns {
  request?: core_message_create_contact_request_returns_request; // request record
  warnings?: core_message_create_contact_request_returns_warnings[]; // list of warnings
}

export interface core_message_confirm_contact_request_parameters {
  userid: number; // The id of the user making the request
  requesteduserid: number; // The id of the user being requested
}

export interface core_message_confirm_contact_request_returns_item {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_message_decline_contact_request_parameters {
  userid: number; // The id of the user making the request
  requesteduserid: number; // The id of the user being requested
}

export interface core_message_decline_contact_request_returns_item {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_message_get_received_contact_requests_count_parameters {
  userid: number; // The id of the user we want to return the number of received contact requests for
}

type core_message_get_received_contact_requests_count_returns = number;

type core_message_delete_contacts_parameters_userids = number;

export interface core_message_delete_contacts_parameters {
  userids: core_message_delete_contacts_parameters_userids[]; // List of user IDs
  userid?: number; // default: int(0) // The id of the user we are deleting the contacts for, 0 for the current user
}

type core_message_delete_contacts_returns = null;

type core_message_delete_conversations_by_id_parameters_conversationids = number;

export interface core_message_delete_conversations_by_id_parameters {
  userid: number; // The user id of who we want to delete the conversation for
  conversationids: core_message_delete_conversations_by_id_parameters_conversationids[]; // List of conversation IDs
}

export interface core_message_delete_conversations_by_id_returns_item {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_message_delete_message_parameters {
  messageid: number; // The message id
  userid: number; // The user id of who we want to delete the message for
  read?: boolean; // default: bool(true) // If is a message read
}

export interface core_message_delete_message_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_message_delete_message_returns {
  status: boolean; // True if the message was deleted, false otherwise
  warnings?: core_message_delete_message_returns_warnings[]; // list of warnings
}

export interface core_message_get_blocked_users_parameters {
  userid: number; // the user whose blocked users we want to retrieve
}

export interface core_message_get_blocked_users_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_message_get_blocked_users_returns_users {
  id: number; // User ID
  fullname: string; // User full name
  profileimageurl?: string; // User picture URL
}

export interface core_message_get_blocked_users_returns {
  users: core_message_get_blocked_users_returns_users[]; // List of blocked users
  warnings?: core_message_get_blocked_users_returns_warnings[]; // list of warnings
}

export interface core_message_data_for_messagearea_search_messages_parameters {
  userid: number; // The id of the user who is performing the search
  search: string; // The string being searched
  limitfrom?: number; // default: int(0) // Limit from
  limitnum?: number; // default: int(0) // Limit number
}

export interface core_message_data_for_messagearea_search_messages_returns_contacts {
  userid: number; // The user's id
  fullname: string; // The user's name
  profileimageurl: string; // User picture URL
  profileimageurlsmall: string; // Small user picture URL
  ismessaging: boolean; // If we are messaging the user
  sentfromcurrentuser: boolean; // Was the last message sent from the current user?
  lastmessage: string; // The user's last message
  lastmessagedate: number; // default: NULL // Timestamp for last message
  messageid: number; // default: NULL // The unique search message id
  showonlinestatus: boolean; // Show the user's online status?
  isonline: boolean; // The user's online status
  isread: boolean; // If the user has read the message
  isblocked: boolean; // If the user has been blocked
  unreadcount: number; // default: NULL // The number of unread messages in this conversation
  conversationid: number; // default: NULL // The id of the conversation
}

export interface core_message_data_for_messagearea_search_messages_returns {
  contacts: core_message_data_for_messagearea_search_messages_returns_contacts[];
}

export interface core_message_message_search_users_parameters {
  userid: number; // The id of the user who is performing the search
  search: string; // The string being searched
  limitfrom?: number; // default: int(0) // Limit from
  limitnum?: number; // default: int(0) // Limit number
}

export interface core_message_message_search_users_returns_noncontacts_conversations {
  id: number; // Conversations id
  type: number; // Conversation type: private or public
  name: string; // Multilang compatible conversation name2
  timecreated: number; // The timecreated timestamp for the conversation
}

export interface core_message_message_search_users_returns_noncontacts_contactrequests {
  id: number; // The id of the contact request
  userid: number; // The id of the user who created the contact request
  requesteduserid: number; // The id of the user confirming the request
  timecreated: number; // The timecreated timestamp for the contact request
}

export interface core_message_message_search_users_returns_noncontacts {
  id: number; // The user id
  fullname: string; // The user's name
  profileurl: string; // The link to the user's profile page
  profileimageurl: string; // User picture URL
  profileimageurlsmall: string; // Small user picture URL
  isonline: boolean; // The user's online status
  showonlinestatus: boolean; // Show the user's online status?
  isblocked: boolean; // If the user has been blocked
  iscontact: boolean; // Is the user a contact?
  isdeleted: boolean; // Is the user deleted?
  canmessageevenifblocked: boolean; // If the user can still message even if they get blocked
  canmessage: boolean; // If the user can be messaged
  requirescontact: boolean; // If the user requires to be contacts
  contactrequests?: core_message_message_search_users_returns_noncontacts_contactrequests[]; // The contact requests
  conversations?: core_message_message_search_users_returns_noncontacts_conversations[]; // Conversations between users
}

export interface core_message_message_search_users_returns_contacts_conversations {
  id: number; // Conversations id
  type: number; // Conversation type: private or public
  name: string; // Multilang compatible conversation name2
  timecreated: number; // The timecreated timestamp for the conversation
}

export interface core_message_message_search_users_returns_contacts_contactrequests {
  id: number; // The id of the contact request
  userid: number; // The id of the user who created the contact request
  requesteduserid: number; // The id of the user confirming the request
  timecreated: number; // The timecreated timestamp for the contact request
}

export interface core_message_message_search_users_returns_contacts {
  id: number; // The user id
  fullname: string; // The user's name
  profileurl: string; // The link to the user's profile page
  profileimageurl: string; // User picture URL
  profileimageurlsmall: string; // Small user picture URL
  isonline: boolean; // The user's online status
  showonlinestatus: boolean; // Show the user's online status?
  isblocked: boolean; // If the user has been blocked
  iscontact: boolean; // Is the user a contact?
  isdeleted: boolean; // Is the user deleted?
  canmessageevenifblocked: boolean; // If the user can still message even if they get blocked
  canmessage: boolean; // If the user can be messaged
  requirescontact: boolean; // If the user requires to be contacts
  contactrequests?: core_message_message_search_users_returns_contacts_contactrequests[]; // The contact requests
  conversations?: core_message_message_search_users_returns_contacts_conversations[]; // Conversations between users
}

export interface core_message_message_search_users_returns {
  contacts: core_message_message_search_users_returns_contacts[];
  noncontacts: core_message_message_search_users_returns_noncontacts[];
}

export interface core_message_get_user_contacts_parameters {
  userid: number; // The id of the user who we retrieving the contacts for
  limitfrom?: number; // default: int(0) // Limit from
  limitnum?: number; // default: int(0) // Limit number
}

export interface core_message_get_user_contacts_returns_item_conversations {
  id: number; // Conversations id
  type: number; // Conversation type: private or public
  name: string; // Multilang compatible conversation name2
  timecreated: number; // The timecreated timestamp for the conversation
}

export interface core_message_get_user_contacts_returns_item_contactrequests {
  id: number; // The id of the contact request
  userid: number; // The id of the user who created the contact request
  requesteduserid: number; // The id of the user confirming the request
  timecreated: number; // The timecreated timestamp for the contact request
}

export interface core_message_get_user_contacts_returns_item {
  id: number; // The user id
  fullname: string; // The user's name
  profileurl: string; // The link to the user's profile page
  profileimageurl: string; // User picture URL
  profileimageurlsmall: string; // Small user picture URL
  isonline: boolean; // The user's online status
  showonlinestatus: boolean; // Show the user's online status?
  isblocked: boolean; // If the user has been blocked
  iscontact: boolean; // Is the user a contact?
  isdeleted: boolean; // Is the user deleted?
  canmessageevenifblocked: boolean; // If the user can still message even if they get blocked
  canmessage: boolean; // If the user can be messaged
  requirescontact: boolean; // If the user requires to be contacts
  contactrequests?: core_message_get_user_contacts_returns_item_contactrequests[]; // The contact requests
  conversations?: core_message_get_user_contacts_returns_item_conversations[]; // Conversations between users
}

export interface core_message_get_conversations_parameters {
  userid: number; // The id of the user who we are viewing conversations for
  limitfrom?: number; // default: int(0) // The offset to start at
  limitnum?: number; // default: int(0) // Limit number of conversations to this
  type?: number; // default: NULL // Filter by type
  favourites?: boolean; // default: NULL // Whether to restrict the results to contain NO favourite conversations (false), ONLY favourite conversation (true), or ignore any restriction altogether (null)
  mergeself?: boolean; // default: bool(false) // Whether to include self-conversations (true) or ONLY private conversations (false) when private conversations are requested.
}

export interface core_message_get_conversations_returns_conversations_messages {
  id: number; // The id of the message
  useridfrom: number; // The id of the user who sent the message
  text: string; // The text of the message
  timecreated: number; // The timecreated timestamp for the message
}

export interface core_message_get_conversations_returns_conversations_members_conversations {
  id: number; // Conversations id
  type: number; // Conversation type: private or public
  name: string; // Multilang compatible conversation name2
  timecreated: number; // The timecreated timestamp for the conversation
}

export interface core_message_get_conversations_returns_conversations_members_contactrequests {
  id: number; // The id of the contact request
  userid: number; // The id of the user who created the contact request
  requesteduserid: number; // The id of the user confirming the request
  timecreated: number; // The timecreated timestamp for the contact request
}

export interface core_message_get_conversations_returns_conversations_members {
  id: number; // The user id
  fullname: string; // The user's name
  profileurl: string; // The link to the user's profile page
  profileimageurl: string; // User picture URL
  profileimageurlsmall: string; // Small user picture URL
  isonline: boolean; // The user's online status
  showonlinestatus: boolean; // Show the user's online status?
  isblocked: boolean; // If the user has been blocked
  iscontact: boolean; // Is the user a contact?
  isdeleted: boolean; // Is the user deleted?
  canmessageevenifblocked: boolean; // If the user can still message even if they get blocked
  canmessage: boolean; // If the user can be messaged
  requirescontact: boolean; // If the user requires to be contacts
  contactrequests?: core_message_get_conversations_returns_conversations_members_contactrequests[]; // The contact requests
  conversations?: core_message_get_conversations_returns_conversations_members_conversations[]; // Conversations between users
}

export interface core_message_get_conversations_returns_conversations {
  id: number; // The conversation id
  name: string; // default: NULL // The conversation name, if set
  subname: string; // default: NULL // A subtitle for the conversation name, if set
  imageurl: string; // default: NULL // A link to the conversation picture, if set
  type: number; // The type of the conversation (1=individual,2=group,3=self)
  membercount: number; // Total number of conversation members
  ismuted: boolean; // If the user muted this conversation
  isfavourite: boolean; // If the user marked this conversation as a favourite
  isread: boolean; // If the user has read all messages in the conversation
  unreadcount: number; // default: NULL // The number of unread messages in this conversation
  members: core_message_get_conversations_returns_conversations_members[];
  messages: core_message_get_conversations_returns_conversations_messages[];
  candeletemessagesforallusers: boolean; // default: bool(false) // If the user can delete messages in the conversation for all users
}

export interface core_message_get_conversations_returns {
  conversations: core_message_get_conversations_returns_conversations[];
}

export interface core_message_get_conversation_parameters {
  userid: number; // The id of the user who we are viewing conversations for
  conversationid: number; // The id of the conversation to fetch
  includecontactrequests: boolean; // Include contact requests in the members
  includeprivacyinfo: boolean; // Include privacy info in the members
  memberlimit?: number; // default: int(0) // Limit for number of members
  memberoffset?: number; // default: int(0) // Offset for member list
  messagelimit?: number; // default: int(100) // Limit for number of messages
  messageoffset?: number; // default: int(0) // Offset for messages list
  newestmessagesfirst?: boolean; // default: bool(true) // Order messages by newest first
}

export interface core_message_get_conversation_returns_messages {
  id: number; // The id of the message
  useridfrom: number; // The id of the user who sent the message
  text: string; // The text of the message
  timecreated: number; // The timecreated timestamp for the message
}

export interface core_message_get_conversation_returns_members_conversations {
  id: number; // Conversations id
  type: number; // Conversation type: private or public
  name: string; // Multilang compatible conversation name2
  timecreated: number; // The timecreated timestamp for the conversation
}

export interface core_message_get_conversation_returns_members_contactrequests {
  id: number; // The id of the contact request
  userid: number; // The id of the user who created the contact request
  requesteduserid: number; // The id of the user confirming the request
  timecreated: number; // The timecreated timestamp for the contact request
}

export interface core_message_get_conversation_returns_members {
  id: number; // The user id
  fullname: string; // The user's name
  profileurl: string; // The link to the user's profile page
  profileimageurl: string; // User picture URL
  profileimageurlsmall: string; // Small user picture URL
  isonline: boolean; // The user's online status
  showonlinestatus: boolean; // Show the user's online status?
  isblocked: boolean; // If the user has been blocked
  iscontact: boolean; // Is the user a contact?
  isdeleted: boolean; // Is the user deleted?
  canmessageevenifblocked: boolean; // If the user can still message even if they get blocked
  canmessage: boolean; // If the user can be messaged
  requirescontact: boolean; // If the user requires to be contacts
  contactrequests?: core_message_get_conversation_returns_members_contactrequests[]; // The contact requests
  conversations?: core_message_get_conversation_returns_members_conversations[]; // Conversations between users
}

export interface core_message_get_conversation_returns {
  id: number; // The conversation id
  name: string; // default: NULL // The conversation name, if set
  subname: string; // default: NULL // A subtitle for the conversation name, if set
  imageurl: string; // default: NULL // A link to the conversation picture, if set
  type: number; // The type of the conversation (1=individual,2=group,3=self)
  membercount: number; // Total number of conversation members
  ismuted: boolean; // If the user muted this conversation
  isfavourite: boolean; // If the user marked this conversation as a favourite
  isread: boolean; // If the user has read all messages in the conversation
  unreadcount: number; // default: NULL // The number of unread messages in this conversation
  members: core_message_get_conversation_returns_members[];
  messages: core_message_get_conversation_returns_messages[];
  candeletemessagesforallusers: boolean; // default: bool(false) // If the user can delete messages in the conversation for all users
}

export interface core_message_get_conversation_between_users_parameters {
  userid: number; // The id of the user who we are viewing conversations for
  otheruserid: number; // The other user id
  includecontactrequests: boolean; // Include contact requests in the members
  includeprivacyinfo: boolean; // Include privacy info in the members
  memberlimit?: number; // default: int(0) // Limit for number of members
  memberoffset?: number; // default: int(0) // Offset for member list
  messagelimit?: number; // default: int(100) // Limit for number of messages
  messageoffset?: number; // default: int(0) // Offset for messages list
  newestmessagesfirst?: boolean; // default: bool(true) // Order messages by newest first
}

export interface core_message_get_conversation_between_users_returns_messages {
  id: number; // The id of the message
  useridfrom: number; // The id of the user who sent the message
  text: string; // The text of the message
  timecreated: number; // The timecreated timestamp for the message
}

export interface core_message_get_conversation_between_users_returns_members_conversations {
  id: number; // Conversations id
  type: number; // Conversation type: private or public
  name: string; // Multilang compatible conversation name2
  timecreated: number; // The timecreated timestamp for the conversation
}

export interface core_message_get_conversation_between_users_returns_members_contactrequests {
  id: number; // The id of the contact request
  userid: number; // The id of the user who created the contact request
  requesteduserid: number; // The id of the user confirming the request
  timecreated: number; // The timecreated timestamp for the contact request
}

export interface core_message_get_conversation_between_users_returns_members {
  id: number; // The user id
  fullname: string; // The user's name
  profileurl: string; // The link to the user's profile page
  profileimageurl: string; // User picture URL
  profileimageurlsmall: string; // Small user picture URL
  isonline: boolean; // The user's online status
  showonlinestatus: boolean; // Show the user's online status?
  isblocked: boolean; // If the user has been blocked
  iscontact: boolean; // Is the user a contact?
  isdeleted: boolean; // Is the user deleted?
  canmessageevenifblocked: boolean; // If the user can still message even if they get blocked
  canmessage: boolean; // If the user can be messaged
  requirescontact: boolean; // If the user requires to be contacts
  contactrequests?: core_message_get_conversation_between_users_returns_members_contactrequests[]; // The contact requests
  conversations?: core_message_get_conversation_between_users_returns_members_conversations[]; // Conversations between users
}

export interface core_message_get_conversation_between_users_returns {
  id: number; // The conversation id
  name: string; // default: NULL // The conversation name, if set
  subname: string; // default: NULL // A subtitle for the conversation name, if set
  imageurl: string; // default: NULL // A link to the conversation picture, if set
  type: number; // The type of the conversation (1=individual,2=group,3=self)
  membercount: number; // Total number of conversation members
  ismuted: boolean; // If the user muted this conversation
  isfavourite: boolean; // If the user marked this conversation as a favourite
  isread: boolean; // If the user has read all messages in the conversation
  unreadcount: number; // default: NULL // The number of unread messages in this conversation
  members: core_message_get_conversation_between_users_returns_members[];
  messages: core_message_get_conversation_between_users_returns_messages[];
  candeletemessagesforallusers: boolean; // default: bool(false) // If the user can delete messages in the conversation for all users
}

export interface core_message_get_self_conversation_parameters {
  userid: number; // The id of the user who we are viewing self-conversations for
  messagelimit?: number; // default: int(100) // Limit for number of messages
  messageoffset?: number; // default: int(0) // Offset for messages list
  newestmessagesfirst?: boolean; // default: bool(true) // Order messages by newest first
}

export interface core_message_get_self_conversation_returns_messages {
  id: number; // The id of the message
  useridfrom: number; // The id of the user who sent the message
  text: string; // The text of the message
  timecreated: number; // The timecreated timestamp for the message
}

export interface core_message_get_self_conversation_returns_members_conversations {
  id: number; // Conversations id
  type: number; // Conversation type: private or public
  name: string; // Multilang compatible conversation name2
  timecreated: number; // The timecreated timestamp for the conversation
}

export interface core_message_get_self_conversation_returns_members_contactrequests {
  id: number; // The id of the contact request
  userid: number; // The id of the user who created the contact request
  requesteduserid: number; // The id of the user confirming the request
  timecreated: number; // The timecreated timestamp for the contact request
}

export interface core_message_get_self_conversation_returns_members {
  id: number; // The user id
  fullname: string; // The user's name
  profileurl: string; // The link to the user's profile page
  profileimageurl: string; // User picture URL
  profileimageurlsmall: string; // Small user picture URL
  isonline: boolean; // The user's online status
  showonlinestatus: boolean; // Show the user's online status?
  isblocked: boolean; // If the user has been blocked
  iscontact: boolean; // Is the user a contact?
  isdeleted: boolean; // Is the user deleted?
  canmessageevenifblocked: boolean; // If the user can still message even if they get blocked
  canmessage: boolean; // If the user can be messaged
  requirescontact: boolean; // If the user requires to be contacts
  contactrequests?: core_message_get_self_conversation_returns_members_contactrequests[]; // The contact requests
  conversations?: core_message_get_self_conversation_returns_members_conversations[]; // Conversations between users
}

export interface core_message_get_self_conversation_returns {
  id: number; // The conversation id
  name: string; // default: NULL // The conversation name, if set
  subname: string; // default: NULL // A subtitle for the conversation name, if set
  imageurl: string; // default: NULL // A link to the conversation picture, if set
  type: number; // The type of the conversation (1=individual,2=group,3=self)
  membercount: number; // Total number of conversation members
  ismuted: boolean; // If the user muted this conversation
  isfavourite: boolean; // If the user marked this conversation as a favourite
  isread: boolean; // If the user has read all messages in the conversation
  unreadcount: number; // default: NULL // The number of unread messages in this conversation
  members: core_message_get_self_conversation_returns_members[];
  messages: core_message_get_self_conversation_returns_messages[];
  candeletemessagesforallusers: boolean; // default: bool(false) // If the user can delete messages in the conversation for all users
}

export interface core_message_get_messages_parameters {
  useridto: number; // the user id who received the message, 0 for any user
  useridfrom?: number; // default: int(0) // the user id who send the message, 0 for any user. -10 or -20 for no-reply or support user
  type?: string; // default: string(4) "both" // type of message to return, expected values are: notifications, conversations and both
  read?: number; // default: int(1) // 1 for getting read messages, 0 for unread, 2 for both
  newestfirst?: boolean; // default: bool(true) // true for ordering by newest first, false for oldest first
  limitfrom?: number; // default: int(0) // limit from
  limitnum?: number; // default: int(0) // limit number
}

export interface core_message_get_messages_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_message_get_messages_returns_messages {
  id: number; // Message id
  useridfrom: number; // User from id
  useridto: number; // User to id
  subject: string; // The message subject
  text: string; // The message text formated
  fullmessage: string; // The message
  fullmessageformat: number; // fullmessage format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  fullmessagehtml: string; // The message in html
  smallmessage: string; // The shorten message
  notification: number; // Is a notification?
  contexturl: string; // Context URL
  contexturlname: string; // Context URL link name
  timecreated: number; // Time created
  timeread: number; // Time read
  usertofullname: string; // User to full name
  userfromfullname: string; // User from full name
  component?: string; // The component that generated the notification
  eventtype?: string; // The type of notification
  customdata?: string; // Custom data to be passed to the message processor. The data here is serialised using json_encode().
  iconurl?: string; // URL for icon, only for notifications.
}

export interface core_message_get_messages_returns {
  messages: core_message_get_messages_returns_messages[];
  warnings?: core_message_get_messages_returns_warnings[]; // list of warnings
}

export interface core_message_get_conversation_counts_parameters {
  userid?: number; // default: int(0) // id of the user, 0 for current user
}

export interface core_message_get_conversation_counts_returns_types {
  1: number; // Total number of individual conversations
  2: number; // Total number of group conversations
  3: number; // Total number of self conversations
}

export interface core_message_get_conversation_counts_returns {
  favourites: number; // Total number of favourite conversations
  types: core_message_get_conversation_counts_returns_types;
}

export interface core_message_get_unread_conversation_counts_parameters {
  userid?: number; // default: int(0) // id of the user, 0 for current user
}

export interface core_message_get_unread_conversation_counts_returns_types {
  1: number; // Total number of unread individual conversations
  2: number; // Total number of unread group conversations
  3: number; // Total number of unread self conversations
}

export interface core_message_get_unread_conversation_counts_returns {
  favourites: number; // Total number of unread favourite conversations
  types: core_message_get_unread_conversation_counts_returns_types;
}

export interface core_message_get_conversation_members_parameters {
  userid: number; // The id of the user we are performing this action on behalf of
  conversationid: number; // The id of the conversation
  includecontactrequests?: boolean; // default: bool(false) // Do we want to include contact requests?
  includeprivacyinfo?: boolean; // default: bool(false) // Do we want to include privacy info?
  limitfrom?: number; // default: int(0) // Limit from
  limitnum?: number; // default: int(0) // Limit number
}

export interface core_message_get_conversation_members_returns_item_conversations {
  id: number; // Conversations id
  type: number; // Conversation type: private or public
  name: string; // Multilang compatible conversation name2
  timecreated: number; // The timecreated timestamp for the conversation
}

export interface core_message_get_conversation_members_returns_item_contactrequests {
  id: number; // The id of the contact request
  userid: number; // The id of the user who created the contact request
  requesteduserid: number; // The id of the user confirming the request
  timecreated: number; // The timecreated timestamp for the contact request
}

export interface core_message_get_conversation_members_returns_item {
  id: number; // The user id
  fullname: string; // The user's name
  profileurl: string; // The link to the user's profile page
  profileimageurl: string; // User picture URL
  profileimageurlsmall: string; // Small user picture URL
  isonline: boolean; // The user's online status
  showonlinestatus: boolean; // Show the user's online status?
  isblocked: boolean; // If the user has been blocked
  iscontact: boolean; // Is the user a contact?
  isdeleted: boolean; // Is the user deleted?
  canmessageevenifblocked: boolean; // If the user can still message even if they get blocked
  canmessage: boolean; // If the user can be messaged
  requirescontact: boolean; // If the user requires to be contacts
  contactrequests?: core_message_get_conversation_members_returns_item_contactrequests[]; // The contact requests
  conversations?: core_message_get_conversation_members_returns_item_conversations[]; // Conversations between users
}

type core_message_get_member_info_parameters_userids = number;

export interface core_message_get_member_info_parameters {
  referenceuserid: number; // id of the user
  userids: core_message_get_member_info_parameters_userids[];
  includecontactrequests?: boolean; // default: bool(false) // include contact requests in response
  includeprivacyinfo?: boolean; // default: bool(false) // include privacy info in response
}

export interface core_message_get_member_info_returns_item_conversations {
  id: number; // Conversations id
  type: number; // Conversation type: private or public
  name: string; // Multilang compatible conversation name2
  timecreated: number; // The timecreated timestamp for the conversation
}

export interface core_message_get_member_info_returns_item_contactrequests {
  id: number; // The id of the contact request
  userid: number; // The id of the user who created the contact request
  requesteduserid: number; // The id of the user confirming the request
  timecreated: number; // The timecreated timestamp for the contact request
}

export interface core_message_get_member_info_returns_item {
  id: number; // The user id
  fullname: string; // The user's name
  profileurl: string; // The link to the user's profile page
  profileimageurl: string; // User picture URL
  profileimageurlsmall: string; // Small user picture URL
  isonline: boolean; // The user's online status
  showonlinestatus: boolean; // Show the user's online status?
  isblocked: boolean; // If the user has been blocked
  iscontact: boolean; // Is the user a contact?
  isdeleted: boolean; // Is the user deleted?
  canmessageevenifblocked: boolean; // If the user can still message even if they get blocked
  canmessage: boolean; // If the user can be messaged
  requirescontact: boolean; // If the user requires to be contacts
  contactrequests?: core_message_get_member_info_returns_item_contactrequests[]; // The contact requests
  conversations?: core_message_get_member_info_returns_item_conversations[]; // Conversations between users
}

export interface core_message_get_unread_conversations_count_parameters {
  useridto: number; // the user id who received the message, 0 for any user
}

type core_message_get_unread_conversations_count_returns = number;

export interface core_message_mark_all_notifications_as_read_parameters {
  useridto: number; // the user id who received the message, 0 for any user
  useridfrom?: number; // default: int(0) // the user id who send the message, 0 for any user. -10 or -20 for no-reply or support user
  timecreatedto?: number; // default: int(0) // mark messages created before this time as read, 0 for all messages
}

type core_message_mark_all_notifications_as_read_returns = boolean;

export interface core_message_mark_all_conversation_messages_as_read_parameters {
  userid: number; // The user id who who we are marking the messages as read for
  conversationid: number; // The conversation id who who we are marking the messages as read for
}

type core_message_mark_all_conversation_messages_as_read_returns = null;

export interface core_message_mark_message_read_parameters {
  messageid: number; // id of the message in the messages table
  timeread?: number; // default: int(0) // timestamp for when the message should be marked read
}

export interface core_message_mark_message_read_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_message_mark_message_read_returns {
  messageid: number; // the id of the message in the messages table
  warnings?: core_message_mark_message_read_returns_warnings[]; // list of warnings
}

export interface core_message_mark_notification_read_parameters {
  notificationid: number; // id of the notification
  timeread?: number; // default: int(0) // timestamp for when the notification should be marked read
}

export interface core_message_mark_notification_read_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_message_mark_notification_read_returns {
  notificationid: number; // id of the notification
  warnings?: core_message_mark_notification_read_returns_warnings[]; // list of warnings
}

export interface core_message_message_processor_config_form_parameters_formvalues {
  name: string; // name of the form element
  value: string; // value of the form element
}

export interface core_message_message_processor_config_form_parameters {
  userid: number; // id of the user, 0 for current user
  name: string; // The name of the message processor
  formvalues: core_message_message_processor_config_form_parameters_formvalues[]; // Config form values
}

type core_message_message_processor_config_form_returns = null;

export interface core_message_get_message_processor_parameters {
  userid: number; // id of the user, 0 for current user
  name: string; // The name of the message processor
}

export interface core_message_get_message_processor_returns {
  systemconfigured: boolean; // Site configuration status
  userconfigured: boolean; // The user configuration status
}

export interface core_message_search_contacts_parameters {
  searchtext: string; // String the user's fullname has to match to be found
  onlymycourses?: boolean; // default: bool(false) // Limit search to the user's courses
}

export interface core_message_search_contacts_returns_item {
  id: number; // User ID
  fullname: string; // User full name
  profileimageurl?: string; // User picture URL
  profileimageurlsmall?: string; // Small user picture URL
}

export interface core_message_send_instant_messages_parameters_messages {
  touserid: number; // id of the user to send the private message
  text: string; // the text of the message
  textformat?: number; // default: string(1) "0" // text format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  clientmsgid?: string; // your own client id for the message. If this id is provided, the fail message id will be returned to you
}

export interface core_message_send_instant_messages_parameters {
  messages: core_message_send_instant_messages_parameters_messages[];
}

export interface core_message_send_instant_messages_returns_item {
  msgid: number; // test this to know if it succeeds: id of the created message if it succeeded, -1 when failed
  clientmsgid?: string; // your own id for the message
  errormessage?: string; // error message - if it failed
  text?: string; // The text of the message
  timecreated?: number; // The timecreated timestamp for the message
  conversationid?: number; // The conversation id for this message
  useridfrom?: number; // The user id who sent the message
  candeletemessagesforallusers: boolean; // default: bool(false) // If the user can delete messages in the conversation for all users
}

export interface core_message_send_messages_to_conversation_parameters_messages {
  text: string; // the text of the message
  textformat?: number; // default: string(1) "0" // text format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
}

export interface core_message_send_messages_to_conversation_parameters {
  conversationid: number; // id of the conversation
  messages: core_message_send_messages_to_conversation_parameters_messages[];
}

export interface core_message_send_messages_to_conversation_returns_item {
  id: number; // The id of the message
  useridfrom: number; // The id of the user who sent the message
  text: string; // The text of the message
  timecreated: number; // The timecreated timestamp for the message
}

export interface core_message_get_conversation_messages_parameters {
  currentuserid: number; // The current user's id
  convid: number; // The conversation id
  limitfrom?: number; // default: int(0) // Limit from
  limitnum?: number; // default: int(0) // Limit number
  newest?: boolean; // default: bool(false) // Newest first?
  timefrom?: number; // default: int(0) // The timestamp from which the messages were created
}

export interface core_message_get_conversation_messages_returns_messages {
  id: number; // The id of the message
  useridfrom: number; // The id of the user who sent the message
  text: string; // The text of the message
  timecreated: number; // The timecreated timestamp for the message
}

export interface core_message_get_conversation_messages_returns_members_conversations {
  id: number; // Conversations id
  type: number; // Conversation type: private or public
  name: string; // Multilang compatible conversation name2
  timecreated: number; // The timecreated timestamp for the conversation
}

export interface core_message_get_conversation_messages_returns_members_contactrequests {
  id: number; // The id of the contact request
  userid: number; // The id of the user who created the contact request
  requesteduserid: number; // The id of the user confirming the request
  timecreated: number; // The timecreated timestamp for the contact request
}

export interface core_message_get_conversation_messages_returns_members {
  id: number; // The user id
  fullname: string; // The user's name
  profileurl: string; // The link to the user's profile page
  profileimageurl: string; // User picture URL
  profileimageurlsmall: string; // Small user picture URL
  isonline: boolean; // The user's online status
  showonlinestatus: boolean; // Show the user's online status?
  isblocked: boolean; // If the user has been blocked
  iscontact: boolean; // Is the user a contact?
  isdeleted: boolean; // Is the user deleted?
  canmessageevenifblocked: boolean; // If the user can still message even if they get blocked
  canmessage: boolean; // If the user can be messaged
  requirescontact: boolean; // If the user requires to be contacts
  contactrequests?: core_message_get_conversation_messages_returns_members_contactrequests[]; // The contact requests
  conversations?: core_message_get_conversation_messages_returns_members_conversations[]; // Conversations between users
}

export interface core_message_get_conversation_messages_returns {
  id: number; // The conversation id
  members: core_message_get_conversation_messages_returns_members[];
  messages: core_message_get_conversation_messages_returns_messages[];
}

export interface core_message_unblock_user_parameters {
  userid: number; // The id of the user who is unblocking
  unblockeduserid: number; // The id of the user being unblocked
}

export interface core_message_unblock_user_returns_item {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_message_get_user_notification_preferences_parameters {
  userid?: number; // default: int(0) // id of the user, 0 for current user
}

export interface core_message_get_user_notification_preferences_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_message_get_user_notification_preferences_returns_preferences_components_notifications_processors_loggedoff {
  name: string; // Name
  displayname: string; // Display name
  checked: boolean; // Is checked?
}

export interface core_message_get_user_notification_preferences_returns_preferences_components_notifications_processors_loggedin {
  name: string; // Name
  displayname: string; // Display name
  checked: boolean; // Is checked?
}

export interface core_message_get_user_notification_preferences_returns_preferences_components_notifications_processors {
  displayname: string; // Display name
  name: string; // Processor name
  locked: boolean; // Is locked by admin?
  lockedmessage?: string; // Text to display if locked
  userconfigured: number; // Is configured?
  loggedin: core_message_get_user_notification_preferences_returns_preferences_components_notifications_processors_loggedin; // DEPRECATED ATTRIBUTE - Kept for backward compatibility, use enabled instead.
  loggedoff: core_message_get_user_notification_preferences_returns_preferences_components_notifications_processors_loggedoff; // DEPRECATED ATTRIBUTE - Kept for backward compatibility, use enabled instead.
  enabled: boolean; // Is enabled?
}

export interface core_message_get_user_notification_preferences_returns_preferences_components_notifications {
  displayname: string; // Display name
  preferencekey: string; // Preference key
  processors: core_message_get_user_notification_preferences_returns_preferences_components_notifications_processors[]; // Processors values for this notification
}

export interface core_message_get_user_notification_preferences_returns_preferences_components {
  displayname: string; // Display name
  notifications: core_message_get_user_notification_preferences_returns_preferences_components_notifications[]; // List of notificaitons for the component
}

export interface core_message_get_user_notification_preferences_returns_preferences_processors {
  displayname: string; // Display name
  name: string; // Processor name
  hassettings: boolean; // Whether has settings
  contextid: number; // Context id
  userconfigured: number; // Whether is configured by the user
}

export interface core_message_get_user_notification_preferences_returns_preferences {
  userid: number; // User id
  disableall: number; // Whether all the preferences are disabled
  processors: core_message_get_user_notification_preferences_returns_preferences_processors[]; // Config form values
  components: core_message_get_user_notification_preferences_returns_preferences_components[]; // Available components
}

export interface core_message_get_user_notification_preferences_returns {
  preferences: core_message_get_user_notification_preferences_returns_preferences;
  warnings?: core_message_get_user_notification_preferences_returns_warnings[]; // list of warnings
}

export interface core_message_get_user_message_preferences_parameters {
  userid?: number; // default: int(0) // id of the user, 0 for current user
}

export interface core_message_get_user_message_preferences_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_message_get_user_message_preferences_returns_preferences_components_notifications_processors_loggedoff {
  name: string; // Name
  displayname: string; // Display name
  checked: boolean; // Is checked?
}

export interface core_message_get_user_message_preferences_returns_preferences_components_notifications_processors_loggedin {
  name: string; // Name
  displayname: string; // Display name
  checked: boolean; // Is checked?
}

export interface core_message_get_user_message_preferences_returns_preferences_components_notifications_processors {
  displayname: string; // Display name
  name: string; // Processor name
  locked: boolean; // Is locked by admin?
  lockedmessage?: string; // Text to display if locked
  userconfigured: number; // Is configured?
  loggedin: core_message_get_user_message_preferences_returns_preferences_components_notifications_processors_loggedin; // DEPRECATED ATTRIBUTE - Kept for backward compatibility, use enabled instead.
  loggedoff: core_message_get_user_message_preferences_returns_preferences_components_notifications_processors_loggedoff; // DEPRECATED ATTRIBUTE - Kept for backward compatibility, use enabled instead.
  enabled: boolean; // Is enabled?
}

export interface core_message_get_user_message_preferences_returns_preferences_components_notifications {
  displayname: string; // Display name
  preferencekey: string; // Preference key
  processors: core_message_get_user_message_preferences_returns_preferences_components_notifications_processors[]; // Processors values for this notification
}

export interface core_message_get_user_message_preferences_returns_preferences_components {
  displayname: string; // Display name
  notifications: core_message_get_user_message_preferences_returns_preferences_components_notifications[]; // List of notificaitons for the component
}

export interface core_message_get_user_message_preferences_returns_preferences_processors {
  displayname: string; // Display name
  name: string; // Processor name
  hassettings: boolean; // Whether has settings
  contextid: number; // Context id
  userconfigured: number; // Whether is configured by the user
}

export interface core_message_get_user_message_preferences_returns_preferences {
  userid: number; // User id
  disableall: number; // Whether all the preferences are disabled
  processors: core_message_get_user_message_preferences_returns_preferences_processors[]; // Config form values
  components: core_message_get_user_message_preferences_returns_preferences_components[]; // Available components
}

export interface core_message_get_user_message_preferences_returns {
  preferences: core_message_get_user_message_preferences_returns_preferences;
  blocknoncontacts: number; // Privacy messaging setting to define who can message you
  entertosend: boolean; // User preference for using enter to send messages
  warnings?: core_message_get_user_message_preferences_returns_warnings[]; // list of warnings
}

type core_message_set_favourite_conversations_parameters_conversations = number;

export interface core_message_set_favourite_conversations_parameters {
  userid?: number; // default: int(0) // id of the user, 0 for current user
  conversations: core_message_set_favourite_conversations_parameters_conversations[];
}

export interface core_message_set_favourite_conversations_returns_item {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

type core_message_unset_favourite_conversations_parameters_conversations = number;

export interface core_message_unset_favourite_conversations_parameters {
  userid?: number; // default: int(0) // id of the user, 0 for current user
  conversations: core_message_unset_favourite_conversations_parameters_conversations[];
}

export interface core_message_unset_favourite_conversations_returns_item {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_message_delete_message_for_all_users_parameters {
  messageid: number; // The message id
  userid: number; // The user id of who we want to delete the message for all users
}

export interface core_message_delete_message_for_all_users_returns_item {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_message_get_unread_notification_count_parameters {
  useridto: number; // user id who received the notification, 0 for any user
}

type core_message_get_unread_notification_count_returns = number;

export interface core_notes_create_notes_parameters_notes {
  userid: number; // id of the user the note is about
  publishstate: string; // 'personal', 'course' or 'site'
  courseid: number; // course id of the note (in Moodle a note can only be created into a course, even for site and personal notes)
  text: string; // the text of the message - text or HTML
  format?: number; // default: string(1) "1" // text format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  clientnoteid?: string; // your own client id for the note. If this id is provided, the fail message id will be returned to you
}

export interface core_notes_create_notes_parameters {
  notes: core_notes_create_notes_parameters_notes[];
}

export interface core_notes_create_notes_returns_item {
  clientnoteid?: string; // your own id for the note
  noteid: number; // ID of the created note when successful, -1 when failed
  errormessage?: string; // error message - if failed
}

type core_notes_delete_notes_parameters_notes = number;

export interface core_notes_delete_notes_parameters {
  notes: core_notes_delete_notes_parameters_notes[]; // Array of Note Ids to be deleted.
}

export interface core_notes_delete_notes_returns_item {
  item?: string; // item is always 'note'
  itemid?: number; // When errorcode is savedfailed the note could not be modified.When errorcode is badparam, an incorrect parameter was provided.When errorcode is badid, the note does not exist
  warningcode: string; // errorcode can be badparam (incorrect parameter), savedfailed (could not be modified), or badid (note does not exist)
  message: string; // untranslated english message to explain the warning
}

export interface core_notes_get_course_notes_parameters {
  courseid: number; // course id, 0 for SITE
  userid?: number; // default: int(0) // user id
}

export interface core_notes_get_course_notes_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_notes_get_course_notes_returns_personalnotes {
  id: number; // id of this note
  courseid: number; // id of the course
  userid: number; // user id
  content: string; // the content text formated
  format: number; // content format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  created: number; // time created (timestamp)
  lastmodified: number; // time of last modification (timestamp)
  usermodified: number; // user id of the creator of this note
  publishstate: string; // state of the note (i.e. draft, public, site)
}

export interface core_notes_get_course_notes_returns_coursenotes {
  id: number; // id of this note
  courseid: number; // id of the course
  userid: number; // user id
  content: string; // the content text formated
  format: number; // content format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  created: number; // time created (timestamp)
  lastmodified: number; // time of last modification (timestamp)
  usermodified: number; // user id of the creator of this note
  publishstate: string; // state of the note (i.e. draft, public, site)
}

export interface core_notes_get_course_notes_returns_sitenotes {
  id: number; // id of this note
  courseid: number; // id of the course
  userid: number; // user id
  content: string; // the content text formated
  format: number; // content format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  created: number; // time created (timestamp)
  lastmodified: number; // time of last modification (timestamp)
  usermodified: number; // user id of the creator of this note
  publishstate: string; // state of the note (i.e. draft, public, site)
}

export interface core_notes_get_course_notes_returns {
  sitenotes?: core_notes_get_course_notes_returns_sitenotes[]; // site notes
  coursenotes?: core_notes_get_course_notes_returns_coursenotes[]; // couse notes
  personalnotes?: core_notes_get_course_notes_returns_personalnotes[]; // personal notes
  canmanagesystemnotes?: boolean; // Whether the user can manage notes at system level.
  canmanagecoursenotes?: boolean; // Whether the user can manage notes at the given course.
  warnings?: core_notes_get_course_notes_returns_warnings[]; // list of warnings
}

type core_notes_get_notes_parameters_notes = number;

export interface core_notes_get_notes_parameters {
  notes: core_notes_get_notes_parameters_notes[]; // Array of Note Ids to be retrieved.
}

export interface core_notes_get_notes_returns_warnings {
  item?: string; // item is always 'note'
  itemid?: number; // When errorcode is savedfailed the note could not be modified.When errorcode is badparam, an incorrect parameter was provided.When errorcode is badid, the note does not exist
  warningcode: string; // errorcode can be badparam (incorrect parameter), savedfailed (could not be modified), or badid (note does not exist)
  message: string; // untranslated english message to explain the warning
}

export interface core_notes_get_notes_returns_notes {
  noteid?: number; // id of the note
  userid?: number; // id of the user the note is about
  publishstate?: string; // 'personal', 'course' or 'site'
  courseid?: number; // course id of the note
  text?: string; // the text of the message - text or HTML
  format?: number; // text format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
}

export interface core_notes_get_notes_returns {
  notes: core_notes_get_notes_returns_notes[];
  warnings?: core_notes_get_notes_returns_warnings[]; // list of warnings
}

export interface core_notes_update_notes_parameters_notes {
  id: number; // id of the note
  publishstate: string; // 'personal', 'course' or 'site'
  text: string; // the text of the message - text or HTML
  format?: number; // default: string(1) "1" // text format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
}

export interface core_notes_update_notes_parameters {
  notes?: core_notes_update_notes_parameters_notes[]; // default: array(0) { } // Array of Notes
}

export interface core_notes_update_notes_returns_item {
  item?: string; // item is always 'note'
  itemid?: number; // When errorcode is savedfailed the note could not be modified.When errorcode is badparam, an incorrect parameter was provided.When errorcode is badid, the note does not exist
  warningcode: string; // errorcode can be badparam (incorrect parameter), savedfailed (could not be modified), or badid (note does not exist)
  message: string; // untranslated english message to explain the warning
}

export interface core_notes_view_notes_parameters {
  courseid: number; // course id, 0 for notes at system level
  userid?: number; // default: int(0) // user id, 0 means view all the user notes
}

export interface core_notes_view_notes_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_notes_view_notes_returns {
  status: boolean; // status: true if success
  warnings?: core_notes_view_notes_returns_warnings[]; // list of warnings
}

export interface core_output_load_template_parameters {
  component: string; // component containing the template
  template: string; // name of the template
  themename: string; // The current theme.
  includecomments?: boolean; // default: bool(false) // Include comments or not
}

type core_output_load_template_returns = string;

export interface core_output_load_template_with_dependencies_parameters {
  component: string; // component containing the template
  template: string; // name of the template
  themename: string; // The current theme.
  includecomments?: boolean; // default: bool(false) // Include comments or not
  lang?: string; // default: NULL // lang
}

export interface core_output_load_template_with_dependencies_returns_strings {
  component: string; // component containing the resource
  name: string; // name of the resource
  value: string; // resource value
}

export interface core_output_load_template_with_dependencies_returns_templates {
  component: string; // component containing the resource
  name: string; // name of the resource
  value: string; // resource value
}

export interface core_output_load_template_with_dependencies_returns {
  templates: core_output_load_template_with_dependencies_returns_templates[];
  strings: core_output_load_template_with_dependencies_returns_strings[];
}

export interface core_output_load_fontawesome_icon_map_parameters {}

export interface core_output_load_fontawesome_icon_map_returns_item {
  component: string; // The component for the icon.
  pix: string; // Value to map the icon from.
  to: string; // Value to map the icon to.
}

export interface core_output_load_fontawesome_icon_system_map_parameters {
  themename: string; // The theme to fetch the map for
}

export interface core_output_load_fontawesome_icon_system_map_returns_item {
  component: string; // The component for the icon.
  pix: string; // Value to map the icon from.
  to: string; // Value to map the icon to.
}

export interface core_question_update_flag_parameters {
  qubaid: number; // the question usage id.
  questionid: number; // the question id
  qaid: number; // the question_attempt id
  slot: number; // the slot number within the usage
  checksum: string; // computed checksum with the last three arguments and the users username
  newstate: boolean; // the new state of the flag. true = flagged
}

export interface core_question_update_flag_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_question_update_flag_returns {
  status: boolean; // status: true if success
  warnings?: core_question_update_flag_returns_warnings[]; // list of warnings
}

export interface core_question_submit_tags_form_parameters {
  questionid: number; // The question id
  contextid: number; // The editing context id
  formdata: string; // The data from the tag form
}

export interface core_question_submit_tags_form_returns {
  status: boolean; // status: true if success
}

type core_question_get_random_question_summaries_parameters_tagids = number;

export interface core_question_get_random_question_summaries_parameters {
  categoryid: number; // Category id to find random questions
  includesubcategories: boolean; // Include the subcategories in the search
  tagids: core_question_get_random_question_summaries_parameters_tagids[];
  contextid: number; // Context id that the questions will be rendered in (used for exporting)
  limit?: number; // default: int(0) // Maximum number of results to return
  offset?: number; // default: int(0) // Number of items to skip from the begging of the result set
}

export interface core_question_get_random_question_summaries_returns_questions_icon {
  key: string; // key
  component: string; // component
  alttext: string; // alttext
}

export interface core_question_get_random_question_summaries_returns_questions {
  id: number; // id
  category: number; // category
  parent: number; // parent
  name: string; // name
  qtype: string; // qtype
  icon: core_question_get_random_question_summaries_returns_questions_icon;
}

export interface core_question_get_random_question_summaries_returns {
  totalcount: number; // total number of questions in result set
  questions: core_question_get_random_question_summaries_returns_questions[];
}

export interface core_rating_get_item_ratings_parameters {
  contextlevel: string; // context level: course, module, user, etc...
  instanceid: number; // the instance id of item associated with the context level
  component: string; // component
  ratingarea: string; // rating area
  itemid: number; // associated id
  scaleid: number; // scale id
  sort: string; // sort order (firstname, rating or timemodified)
}

export interface core_rating_get_item_ratings_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_rating_get_item_ratings_returns_ratings {
  id: number; // rating id
  userid: number; // user id
  userpictureurl: string; // URL user picture
  userfullname: string; // user fullname
  rating: string; // rating on scale
  timemodified: number; // time modified (timestamp)
}

export interface core_rating_get_item_ratings_returns {
  ratings: core_rating_get_item_ratings_returns_ratings[]; // list of ratings
  warnings?: core_rating_get_item_ratings_returns_warnings[]; // list of warnings
}

export interface core_rating_add_rating_parameters {
  contextlevel: string; // context level: course, module, user, etc...
  instanceid: number; // the instance id of item associated with the context level
  component: string; // component
  ratingarea: string; // rating area
  itemid: number; // associated id
  scaleid: number; // scale id
  rating: number; // user rating
  rateduserid: number; // rated user id
  aggregation?: number; // default: int(0) // agreggation method
}

export interface core_rating_add_rating_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_rating_add_rating_returns {
  success: boolean; // Whether the rate was successfully created
  aggregate?: string; // New aggregate
  count?: number; // Ratings count
  itemid?: number; // Rating item id
  warnings?: core_rating_add_rating_returns_warnings[]; // list of warnings
}

export interface core_role_assign_roles_parameters_assignments {
  roleid: number; // Role to assign to the user
  userid: number; // The user that is going to be assigned
  contextid?: number; // The context to assign the user role in
  contextlevel?: string; // The context level to assign the user role in (block, course, coursecat, system, user, module)
  instanceid?: number; // The Instance id of item where the role needs to be assigned
}

export interface core_role_assign_roles_parameters {
  assignments: core_role_assign_roles_parameters_assignments[];
}

type core_role_assign_roles_returns = null;

export interface core_role_unassign_roles_parameters_unassignments {
  roleid: number; // Role to assign to the user
  userid: number; // The user that is going to be assigned
  contextid?: number; // The context to unassign the user role from
  contextlevel?: string; // The context level to unassign the user role in + (block, course, coursecat, system, user, module)
  instanceid?: number; // The Instance id of item where the role needs to be unassigned
}

export interface core_role_unassign_roles_parameters {
  unassignments: core_role_unassign_roles_parameters_unassignments[];
}

type core_role_unassign_roles_returns = null;

export interface core_search_get_relevant_users_parameters {
  query: string; // Query string (full or partial user full name or other details)
  courseid: number; // Course id (0 if none)
}

export interface core_search_get_relevant_users_returns_item {
  id: number; // User id
  fullname: string; // Full name as text
  profileimageurlsmall: string; // URL to small profile image
}

export interface core_tag_get_tagindex_parameters_tagindex {
  tag: string; // tag name
  tc: number; // tag collection id
  ta: number; // tag area id
  excl?: boolean; // exlusive mode for this tag area
  from?: number; // context id where the link was displayed
  ctx?: number; // context id where to search for items
  rec?: number; // search in the context recursive
  page?: number; // page number (0-based)
}

export interface core_tag_get_tagindex_parameters {
  tagindex: core_tag_get_tagindex_parameters_tagindex; // parameters
}

export interface core_tag_get_tagindex_returns {
  tagid: number; // tag id
  ta: number; // tag area id
  component: string; // component
  itemtype: string; // itemtype
  nextpageurl?: string; // URL for the next page
  prevpageurl?: string; // URL for the next page
  exclusiveurl?: string; // URL for exclusive link
  exclusivetext?: string; // text for exclusive link
  title: string; // title
  content: string; // title
  hascontent: number; // whether the content is present
  anchor?: string; // name of anchor
}

export interface core_tag_get_tags_parameters_tags {
  id: number; // tag id
}

export interface core_tag_get_tags_parameters {
  tags: core_tag_get_tags_parameters_tags[];
}

export interface core_tag_get_tags_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_tag_get_tags_returns_tags {
  id: number; // tag id
  tagcollid: number; // tag collection id
  name: string; // name
  rawname: string; // tag raw name (may contain capital letters)
  description: string; // tag description
  descriptionformat: number; // int format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  flag?: number; // flag
  official?: number; // whether this flag is standard (deprecated, use isstandard)
  isstandard?: number; // whether this flag is standard
  viewurl: string; // URL to view
}

export interface core_tag_get_tags_returns {
  tags: core_tag_get_tags_returns_tags[];
  warnings?: core_tag_get_tags_returns_warnings[]; // list of warnings
}

export interface core_tag_update_tags_parameters_tags {
  id: number; // tag id
  rawname?: string; // tag raw name (may contain capital letters)
  description?: string; // tag description
  descriptionformat?: number; // tag description format
  flag?: number; // flag
  official?: number; // (deprecated, use isstandard) whether this flag is standard
  isstandard?: number; // whether this flag is standard
}

export interface core_tag_update_tags_parameters {
  tags: core_tag_update_tags_parameters_tags[];
}

export interface core_tag_update_tags_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_tag_update_tags_returns {
  warnings?: core_tag_update_tags_returns_warnings[]; // list of warnings
}

export interface core_tag_get_tagindex_per_area_parameters_tagindex {
  id?: number; // tag id
  tag?: string; // tag name
  tc?: number; // tag collection id
  ta?: number; // tag area id
  excl?: boolean; // exlusive mode for this tag area
  from?: number; // context id where the link was displayed
  ctx?: number; // context id where to search for items
  rec?: number; // search in the context recursive
  page?: number; // page number (0-based)
}

export interface core_tag_get_tagindex_per_area_parameters {
  tagindex: core_tag_get_tagindex_per_area_parameters_tagindex; // parameters
}

export interface core_tag_get_tagindex_per_area_returns_item {
  tagid: number; // tag id
  ta: number; // tag area id
  component: string; // component
  itemtype: string; // itemtype
  nextpageurl?: string; // URL for the next page
  prevpageurl?: string; // URL for the next page
  exclusiveurl?: string; // URL for exclusive link
  exclusivetext?: string; // text for exclusive link
  title: string; // title
  content: string; // title
  hascontent: number; // whether the content is present
  anchor?: string; // name of anchor
}

export interface core_tag_get_tag_areas_parameters {}

export interface core_tag_get_tag_areas_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_tag_get_tag_areas_returns_areas {
  id: number; // Area id.
  component: string; // Component the area is related to.
  itemtype: string; // Type of item in the component.
  enabled: boolean; // Whether this area is enabled.
  tagcollid: number; // The tag collection this are belongs to.
  callback: string; // Component callback for processing tags.
  callbackfile: string; // Component callback file.
  showstandard: number; // Return whether to display only standard, only non-standard or both tags.
  multiplecontexts: boolean; // Whether the tag area allows tag instances to be created in multiple contexts.
  locked?: boolean; // Whether the area is locked.
}

export interface core_tag_get_tag_areas_returns {
  areas: core_tag_get_tag_areas_returns_areas[];
  warnings?: core_tag_get_tag_areas_returns_warnings[]; // list of warnings
}

export interface core_tag_get_tag_collections_parameters {}

export interface core_tag_get_tag_collections_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_tag_get_tag_collections_returns_collections {
  id: number; // Collection id.
  name: string; // Collection name.
  isdefault: boolean; // Whether is the default collection.
  component: string; // Component the collection is related to.
  sortorder: number; // Collection ordering in the list.
  searchable: boolean; // Whether the tag collection is searchable.
  customurl: string; // Custom URL for the tag page instead of /tag/index.php.
}

export interface core_tag_get_tag_collections_returns {
  collections: core_tag_get_tag_collections_returns_collections[];
  warnings?: core_tag_get_tag_collections_returns_warnings[]; // list of warnings
}

export interface core_tag_get_tag_cloud_parameters {
  tagcollid?: number; // default: int(0) // Tag collection id.
  isstandard?: boolean; // default: bool(false) // Whether to return only standard tags.
  limit?: number; // default: int(150) // Maximum number of tags to retrieve.
  sort?: string; // default: string(4) "name" // Sort order for display (id, name, rawname, count, flag, isstandard, tagcollid).
  search?: string; // default: string(0) "" // Search string.
  fromctx?: number; // default: int(0) // Context id where this tag cloud is displayed.
  ctx?: number; // default: int(0) // Only retrieve tag instances in this context.
  rec?: number; // default: int(1) // Retrieve tag instances in the $ctx context and it's children.
}

export interface core_tag_get_tag_cloud_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_tag_get_tag_cloud_returns_tags {
  name: string; // Tag name.
  viewurl: string; // URL to view the tag index.
  flag?: boolean; // Whether the tag is flagged as inappropriate.
  isstandard?: boolean; // Whether is a standard tag or not.
  count?: number; // Number of tag instances.
  size?: number; // Proportional size to display the tag.
}

export interface core_tag_get_tag_cloud_returns {
  tags: core_tag_get_tag_cloud_returns_tags[];
  tagscount: number; // Number of tags returned.
  totalcount: number; // Total count of tags.
  warnings?: core_tag_get_tag_cloud_returns_warnings[]; // list of warnings
}

export interface core_update_inplace_editable_parameters {
  component: string; // component responsible for the update
  itemtype: string; // type of the updated item inside the component
  itemid: string; // identifier of the updated item
  value: string; // new value
}

export interface core_update_inplace_editable_returns_editicon {
  key?: string; // Edit icon key
  component?: string; // Edit icon component
  title?: string; // Edit icon title
}

export interface core_update_inplace_editable_returns {
  displayvalue: string; // display value (may contain link or other html tags)
  component?: string; // component responsible for the update
  itemtype?: string; // itemtype
  value?: string; // value of the item as it is stored
  itemid?: string; // identifier of the updated item
  edithint?: string; // hint for editing element
  editlabel?: string; // label for editing element
  editicon: core_update_inplace_editable_returns_editicon;
  type?: string; // type of the element (text, toggle, select)
  options?: string; // options of the element, format depends on type
  linkeverything?: number; // Should everything be wrapped in the edit link or link displayed separately
}

export interface core_user_add_user_device_parameters {
  appid: string; // the app id, usually something like com.moodle.moodlemobile
  name: string; // the device name, 'occam' or 'iPhone' etc.
  model: string; // the device model 'Nexus4' or 'iPad1,1' etc.
  platform: string; // the device platform 'iOS' or 'Android' etc.
  version: string; // the device version '6.1.2' or '4.2.2' etc.
  pushid: string; // the device PUSH token/key/identifier/registration id
  uuid: string; // the device UUID
  publickey?: string; // default: NULL // the app generated public key
}

export interface core_user_add_user_device_returns_item_item {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export type core_user_add_user_device_returns_item = core_user_add_user_device_returns_item_item[];

export interface core_user_update_user_device_public_key_parameters {
  uuid: string; // the device UUID
  appid: string; // The app id, something like com.moodle.moodlemobile
  publickey: string; // the app generated public key
}

export interface core_user_update_user_device_public_key_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_user_update_user_device_public_key_returns {
  status: boolean; // Whether the request was successful
  warnings?: core_user_update_user_device_public_key_returns_warnings[]; // list of warnings
}

export interface core_user_add_user_private_files_parameters {
  draftid: number; // draft area id
}

type core_user_add_user_private_files_returns = null;

export interface core_user_create_users_parameters_users_preferences {
  type: string; // The name of the preference
  value: string; // The value of the preference
}

export interface core_user_create_users_parameters_users_customfields {
  type: string; // The name of the custom field
  value: string; // The value of the custom field
}

export interface core_user_create_users_parameters_users {
  createpassword?: boolean; // True if password should be created and mailed to user.
  username: string; // Username policy is defined in Moodle security config.
  auth?: string; // default: string(6) "manual" // Auth plugins include manual, ldap, etc
  password?: string; // Plain text password consisting of any characters
  firstname: string; // The first name(s) of the user
  lastname: string; // The family name of the user
  email: string; // A valid and unique email address
  maildisplay?: number; // Email visibility
  city?: string; // Home city of the user
  country?: string; // Home country code of the user, such as AU or CZ
  timezone?: string; // Timezone code such as Australia/Perth, or 99 for default
  description?: string; // User profile description, no HTML
  firstnamephonetic?: string; // The first name(s) phonetically of the user
  lastnamephonetic?: string; // The family name phonetically of the user
  middlename?: string; // The middle name of the user
  alternatename?: string; // The alternate name of the user
  interests?: string; // User interests (separated by commas)
  idnumber?: string; // default: string(0) "" // An arbitrary ID code number perhaps from the institution
  institution?: string; // institution
  department?: string; // department
  phone1?: string; // Phone 1
  phone2?: string; // Phone 2
  address?: string; // Postal address
  lang?: string; // default: string(2) "en" // Language code such as "en", must exist on server
  calendartype?: string; // default: string(9) "gregorian" // Calendar type such as "gregorian", must exist on server
  theme?: string; // Theme name such as "standard", must exist on server
  mailformat?: number; // Mail format code is 0 for plain text, 1 for HTML etc
  customfields?: core_user_create_users_parameters_users_customfields[]; // User custom fields (also known as user profil fields)
  preferences?: core_user_create_users_parameters_users_preferences[]; // User preferences
}

export interface core_user_create_users_parameters {
  users: core_user_create_users_parameters_users[];
}

export interface core_user_create_users_returns_item {
  id: number; // user id
  username: string; // user name
}

type core_user_delete_users_parameters_userids = number;

export interface core_user_delete_users_parameters {
  userids: core_user_delete_users_parameters_userids[];
}

type core_user_delete_users_returns = null;

export interface core_user_get_course_user_profiles_parameters_userlist {
  userid: number; // userid
  courseid: number; // courseid
}

export interface core_user_get_course_user_profiles_parameters {
  userlist: core_user_get_course_user_profiles_parameters_userlist[];
}

export interface core_user_get_course_user_profiles_returns_item_enrolledcourses {
  id: number; // Id of the course
  fullname: string; // Fullname of the course
  shortname: string; // Shortname of the course
}

export interface core_user_get_course_user_profiles_returns_item_roles {
  roleid: number; // role id
  name: string; // role name
  shortname: string; // role shortname
  sortorder: number; // role sortorder
}

export interface core_user_get_course_user_profiles_returns_item_groups {
  id: number; // group id
  name: string; // group name
  description: string; // group description
  descriptionformat: number; // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
}

export interface core_user_get_course_user_profiles_returns_item_preferences {
  name: string; // The name of the preferences
  value: string; // The value of the preference
}

export interface core_user_get_course_user_profiles_returns_item_customfields {
  type: string; // The type of the custom field - text field, checkbox...
  value: string; // The value of the custom field
  name: string; // The name of the custom field
  shortname: string; // The shortname of the custom field - to be able to build the field class in the code
}

export interface core_user_get_course_user_profiles_returns_item {
  id: number; // ID of the user
  username?: string; // The username
  firstname?: string; // The first name(s) of the user
  lastname?: string; // The family name of the user
  fullname: string; // The fullname of the user
  email?: string; // An email address - allow email as root@localhost
  address?: string; // Postal address
  phone1?: string; // Phone 1
  phone2?: string; // Phone 2
  department?: string; // department
  institution?: string; // institution
  idnumber?: string; // An arbitrary ID code number perhaps from the institution
  interests?: string; // user interests (separated by commas)
  firstaccess?: number; // first access to the site (0 if never)
  lastaccess?: number; // last access to the site (0 if never)
  auth?: string; // Auth plugins include manual, ldap, etc
  suspended?: boolean; // Suspend user account, either false to enable user login or true to disable it
  confirmed?: boolean; // Active user: 1 if confirmed, 0 otherwise
  lang?: string; // Language code such as "en", must exist on server
  calendartype?: string; // Calendar type such as "gregorian", must exist on server
  theme?: string; // Theme name such as "standard", must exist on server
  timezone?: string; // Timezone code such as Australia/Perth, or 99 for default
  mailformat?: number; // Mail format code is 0 for plain text, 1 for HTML etc
  description?: string; // User profile description
  descriptionformat?: number; // int format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  city?: string; // Home city of the user
  country?: string; // Home country code of the user, such as AU or CZ
  profileimageurlsmall: string; // User image profile URL - small version
  profileimageurl: string; // User image profile URL - big version
  customfields?: core_user_get_course_user_profiles_returns_item_customfields[]; // User custom fields (also known as user profile fields)
  preferences?: core_user_get_course_user_profiles_returns_item_preferences[]; // Users preferences
  groups?: core_user_get_course_user_profiles_returns_item_groups[]; // user groups
  roles?: core_user_get_course_user_profiles_returns_item_roles[]; // user roles
  enrolledcourses?: core_user_get_course_user_profiles_returns_item_enrolledcourses[]; // Courses where the user is enrolled - limited by which courses the user is able to see
}

export interface core_user_get_users_parameters_criteria {
  key: string; // the user column to search, expected keys (value format) are: "id" (int) matching user id, "lastname" (string) user last name (Note: you can use % for searching but it may be considerably slower!), "firstname" (string) user first name (Note: you can use % for searching but it may be considerably slower!), "idnumber" (string) matching user idnumber, "username" (string) matching user username, "email" (string) user email (Note: you can use % for searching but it may be considerably slower!), "auth" (string) matching user auth plugin
  value: string; // the value to search
}

export interface core_user_get_users_parameters {
  criteria: core_user_get_users_parameters_criteria[]; // the key/value pairs to be considered in user search. Values can not be empty. Specify different keys only once (fullname => 'user1', auth => 'manual', ...) - key occurences are forbidden. The search is executed with AND operator on the criterias. Invalid criterias (keys) are ignored, the search is still executed on the valid criterias. You can search without criteria, but the function is not designed for it. It could very slow or timeout. The function is designed to search some specific users.
}

export interface core_user_get_users_returns_warnings {
  item?: string; // always set to 'key'
  itemid?: number; // faulty key name
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_user_get_users_returns_users_preferences {
  name: string; // The name of the preferences
  value: string; // The value of the preference
}

export interface core_user_get_users_returns_users_customfields {
  type: string; // The type of the custom field - text field, checkbox...
  value: string; // The value of the custom field
  name: string; // The name of the custom field
  shortname: string; // The shortname of the custom field - to be able to build the field class in the code
}

export interface core_user_get_users_returns_users {
  id: number; // ID of the user
  username?: string; // The username
  firstname?: string; // The first name(s) of the user
  lastname?: string; // The family name of the user
  fullname: string; // The fullname of the user
  email?: string; // An email address - allow email as root@localhost
  address?: string; // Postal address
  phone1?: string; // Phone 1
  phone2?: string; // Phone 2
  department?: string; // department
  institution?: string; // institution
  idnumber?: string; // An arbitrary ID code number perhaps from the institution
  interests?: string; // user interests (separated by commas)
  firstaccess?: number; // first access to the site (0 if never)
  lastaccess?: number; // last access to the site (0 if never)
  auth?: string; // Auth plugins include manual, ldap, etc
  suspended?: boolean; // Suspend user account, either false to enable user login or true to disable it
  confirmed?: boolean; // Active user: 1 if confirmed, 0 otherwise
  lang?: string; // Language code such as "en", must exist on server
  calendartype?: string; // Calendar type such as "gregorian", must exist on server
  theme?: string; // Theme name such as "standard", must exist on server
  timezone?: string; // Timezone code such as Australia/Perth, or 99 for default
  mailformat?: number; // Mail format code is 0 for plain text, 1 for HTML etc
  description?: string; // User profile description
  descriptionformat?: number; // int format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  city?: string; // Home city of the user
  country?: string; // Home country code of the user, such as AU or CZ
  profileimageurlsmall: string; // User image profile URL - small version
  profileimageurl: string; // User image profile URL - big version
  customfields?: core_user_get_users_returns_users_customfields[]; // User custom fields (also known as user profile fields)
  preferences?: core_user_get_users_returns_users_preferences[]; // Users preferences
}

export interface core_user_get_users_returns {
  users: core_user_get_users_returns_users[];
  warnings?: core_user_get_users_returns_warnings[]; // list of warnings
}

type core_user_get_users_by_field_parameters_values = string;

export interface core_user_get_users_by_field_parameters {
  field: string; // the search field can be 'id' or 'idnumber' or 'username' or 'email'
  values: core_user_get_users_by_field_parameters_values[];
}

export interface core_user_get_users_by_field_returns_item_preferences {
  name: string; // The name of the preferences
  value: string; // The value of the preference
}

export interface core_user_get_users_by_field_returns_item_customfields {
  type: string; // The type of the custom field - text field, checkbox...
  value: string; // The value of the custom field
  name: string; // The name of the custom field
  shortname: string; // The shortname of the custom field - to be able to build the field class in the code
}

export interface core_user_get_users_by_field_returns_item {
  id: number; // ID of the user
  username?: string; // The username
  firstname?: string; // The first name(s) of the user
  lastname?: string; // The family name of the user
  fullname: string; // The fullname of the user
  email?: string; // An email address - allow email as root@localhost
  address?: string; // Postal address
  phone1?: string; // Phone 1
  phone2?: string; // Phone 2
  department?: string; // department
  institution?: string; // institution
  idnumber?: string; // An arbitrary ID code number perhaps from the institution
  interests?: string; // user interests (separated by commas)
  firstaccess?: number; // first access to the site (0 if never)
  lastaccess?: number; // last access to the site (0 if never)
  auth?: string; // Auth plugins include manual, ldap, etc
  suspended?: boolean; // Suspend user account, either false to enable user login or true to disable it
  confirmed?: boolean; // Active user: 1 if confirmed, 0 otherwise
  lang?: string; // Language code such as "en", must exist on server
  calendartype?: string; // Calendar type such as "gregorian", must exist on server
  theme?: string; // Theme name such as "standard", must exist on server
  timezone?: string; // Timezone code such as Australia/Perth, or 99 for default
  mailformat?: number; // Mail format code is 0 for plain text, 1 for HTML etc
  description?: string; // User profile description
  descriptionformat?: number; // int format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  city?: string; // Home city of the user
  country?: string; // Home country code of the user, such as AU or CZ
  profileimageurlsmall: string; // User image profile URL - small version
  profileimageurl: string; // User image profile URL - big version
  customfields?: core_user_get_users_by_field_returns_item_customfields[]; // User custom fields (also known as user profile fields)
  preferences?: core_user_get_users_by_field_returns_item_preferences[]; // Users preferences
}

export interface core_user_search_identity_parameters {
  query: string; // The search query
}

export interface core_user_search_identity_returns_list_extrafields {
  name: string; // Name of the extrafield.
  value: string; // Value of the extrafield.
}

export interface core_user_search_identity_returns_list {
  id: number; // ID of the user
  fullname: string; // The fullname of the user
  extrafields?: core_user_search_identity_returns_list_extrafields[]; // List of extra fields
}

export interface core_user_search_identity_returns {
  list: core_user_search_identity_returns_list[];
  maxusersperpage: number; // Configured maximum users per page.
  overflow: boolean; // Were there more records than maxusersperpage found?
}

export interface core_user_remove_user_device_parameters {
  uuid: string; // the device UUID
  appid?: string; // default: string(0) "" // the app id, if empty devices matching the UUID for the user will be removed
}

export interface core_user_remove_user_device_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_user_remove_user_device_returns {
  removed: boolean; // True if removed, false if not removed because it doesn't exists
  warnings?: core_user_remove_user_device_returns_warnings[]; // list of warnings
}

export interface core_user_update_users_parameters_users_preferences {
  type: string; // The name of the preference
  value: string; // The value of the preference
}

export interface core_user_update_users_parameters_users_customfields {
  type: string; // The name of the custom field
  value: string; // The value of the custom field
}

export interface core_user_update_users_parameters_users {
  id: number; // ID of the user
  username?: string; // Username policy is defined in Moodle security config.
  auth?: string; // Auth plugins include manual, ldap, etc
  suspended?: boolean; // Suspend user account, either false to enable user login or true to disable it
  password?: string; // Plain text password consisting of any characters
  firstname?: string; // The first name(s) of the user
  lastname?: string; // The family name of the user
  email?: string; // A valid and unique email address
  maildisplay?: number; // Email visibility
  city?: string; // Home city of the user
  country?: string; // Home country code of the user, such as AU or CZ
  timezone?: string; // Timezone code such as Australia/Perth, or 99 for default
  description?: string; // User profile description, no HTML
  userpicture?: number; // The itemid where the new user picture has been uploaded to, 0 to delete
  firstnamephonetic?: string; // The first name(s) phonetically of the user
  lastnamephonetic?: string; // The family name phonetically of the user
  middlename?: string; // The middle name of the user
  alternatename?: string; // The alternate name of the user
  interests?: string; // User interests (separated by commas)
  idnumber?: string; // An arbitrary ID code number perhaps from the institution
  institution?: string; // Institution
  department?: string; // Department
  phone1?: string; // Phone
  phone2?: string; // Mobile phone
  address?: string; // Postal address
  lang?: string; // Language code such as "en", must exist on server
  calendartype?: string; // Calendar type such as "gregorian", must exist on server
  theme?: string; // Theme name such as "standard", must exist on server
  mailformat?: number; // Mail format code is 0 for plain text, 1 for HTML etc
  customfields?: core_user_update_users_parameters_users_customfields[]; // User custom fields (also known as user profil fields)
  preferences?: core_user_update_users_parameters_users_preferences[]; // User preferences
}

export interface core_user_update_users_parameters {
  users: core_user_update_users_parameters_users[];
}

export interface core_user_update_users_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_user_update_users_returns {
  warnings?: core_user_update_users_returns_warnings[]; // list of warnings
}

export interface core_user_update_user_preferences_parameters_preferences {
  type: string; // The name of the preference
  value?: string; // default: NULL // The value of the preference, do not set this field if you want to remove (unset) the current value.
}

export interface core_user_update_user_preferences_parameters {
  userid?: number; // default: int(0) // id of the user, default to current user
  emailstop?: number; // default: NULL // Enable or disable notifications for this user
  preferences?: core_user_update_user_preferences_parameters_preferences[]; // default: array(0) { } // User preferences
}

type core_user_update_user_preferences_returns = null;

export interface core_user_view_user_list_parameters {
  courseid: number; // id of the course, 0 for site
}

export interface core_user_view_user_list_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_user_view_user_list_returns {
  status: boolean; // status: true if success
  warnings?: core_user_view_user_list_returns_warnings[]; // list of warnings
}

export interface core_user_view_user_profile_parameters {
  userid: number; // id of the user, 0 for current user
  courseid?: number; // default: int(0) // id of the course, default site course
}

export interface core_user_view_user_profile_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_user_view_user_profile_returns {
  status: boolean; // status: true if success
  warnings?: core_user_view_user_profile_returns_warnings[]; // list of warnings
}

export interface core_user_get_user_preferences_parameters {
  name?: string; // default: string(0) "" // preference name, empty for all
  userid?: number; // default: int(0) // id of the user, default to current user
}

export interface core_user_get_user_preferences_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_user_get_user_preferences_returns_preferences {
  name: string; // The name of the preference
  value: string; // The value of the preference
}

export interface core_user_get_user_preferences_returns {
  preferences: core_user_get_user_preferences_returns_preferences[]; // User custom fields (also known as user profile fields)
  warnings?: core_user_get_user_preferences_returns_warnings[]; // list of warnings
}

export interface core_user_update_picture_parameters {
  draftitemid: number; // Id of the user draft file to use as image
  delete?: boolean; // default: bool(false) // If we should delete the user picture
  userid?: number; // default: int(0) // Id of the user, 0 for current user
}

export interface core_user_update_picture_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_user_update_picture_returns {
  success: boolean; // True if the image was updated, false otherwise.
  profileimageurl?: string; // New profile user image url
  warnings?: core_user_update_picture_returns_warnings[]; // list of warnings
}

export interface core_user_set_user_preferences_parameters_preferences {
  name: string; // The name of the preference
  value: string; // The value of the preference
  userid: number; // Id of the user to set the preference
}

export interface core_user_set_user_preferences_parameters {
  preferences: core_user_set_user_preferences_parameters_preferences[];
}

export interface core_user_set_user_preferences_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_user_set_user_preferences_returns_saved {
  name: string; // The name of the preference
  userid: number; // The user the preference was set for
}

export interface core_user_set_user_preferences_returns {
  saved: core_user_set_user_preferences_returns_saved[]; // Preferences saved
  warnings?: core_user_set_user_preferences_returns_warnings[]; // list of warnings
}

export interface core_user_agree_site_policy_parameters {}

export interface core_user_agree_site_policy_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_user_agree_site_policy_returns {
  status: boolean; // Status: true only if we set the policyagreed to 1 for the user
  warnings?: core_user_agree_site_policy_returns_warnings[]; // list of warnings
}

export interface core_user_get_private_files_info_parameters {
  userid?: number; // default: int(0) // Id of the user, default to current user.
}

export interface core_user_get_private_files_info_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_user_get_private_files_info_returns {
  filecount: number; // Number of files in the area.
  foldercount: number; // Number of folders in the area.
  filesize: number; // Total size of the files in the area.
  filesizewithoutreferences: number; // Total size of the area excluding file references
  warnings?: core_user_get_private_files_info_returns_warnings[]; // list of warnings
}

export interface core_competency_create_competency_framework_parameters_competencyframework {
  shortname: string; // shortname
  idnumber: string; // idnumber
  description?: string; // default: string(0) "" // description
  descriptionformat?: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  visible?: boolean; // default: int(1) // visible
  scaleid: number; // scaleid
  scaleconfiguration: string; // scaleconfiguration
  contextid?: number; // The context id
  contextlevel?: string; // The context level
  instanceid?: number; // The Instance id
  taxonomies?: string; // default: string(0) "" // taxonomies
  timecreated?: number; // default: int(0) // timecreated
  timemodified?: number; // default: int(0) // timemodified
  usermodified?: number; // default: int(0) // usermodified
}

export interface core_competency_create_competency_framework_parameters {
  competencyframework: core_competency_create_competency_framework_parameters_competencyframework;
}

export interface core_competency_create_competency_framework_returns {
  shortname: string; // shortname
  idnumber: string; // idnumber
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  visible: boolean; // visible
  scaleid: number; // scaleid
  scaleconfiguration: string; // scaleconfiguration
  contextid: number; // contextid
  taxonomies: string; // taxonomies
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  canmanage: boolean; // canmanage
  competenciescount: number; // competenciescount
  contextname: string; // contextname
  contextnamenoprefix: string; // contextnamenoprefix
}

export interface core_competency_read_competency_framework_parameters {
  id: number; // Data base record id for the framework
}

export interface core_competency_read_competency_framework_returns {
  shortname: string; // shortname
  idnumber: string; // idnumber
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  visible: boolean; // visible
  scaleid: number; // scaleid
  scaleconfiguration: string; // scaleconfiguration
  contextid: number; // contextid
  taxonomies: string; // taxonomies
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  canmanage: boolean; // canmanage
  competenciescount: number; // competenciescount
  contextname: string; // contextname
  contextnamenoprefix: string; // contextnamenoprefix
}

export interface core_competency_duplicate_competency_framework_parameters {
  id: number; // Data base record id for the framework
}

export interface core_competency_duplicate_competency_framework_returns {
  shortname: string; // shortname
  idnumber: string; // idnumber
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  visible: boolean; // visible
  scaleid: number; // scaleid
  scaleconfiguration: string; // scaleconfiguration
  contextid: number; // contextid
  taxonomies: string; // taxonomies
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  canmanage: boolean; // canmanage
  competenciescount: number; // competenciescount
  contextname: string; // contextname
  contextnamenoprefix: string; // contextnamenoprefix
}

export interface core_competency_delete_competency_framework_parameters {
  id: number; // Data base record id for the framework
}

type core_competency_delete_competency_framework_returns = boolean;

export interface core_competency_update_competency_framework_parameters_competencyframework {
  shortname?: string; // shortname
  idnumber?: string; // idnumber
  description?: string; // description
  descriptionformat?: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  visible?: boolean; // visible
  scaleid?: number; // scaleid
  scaleconfiguration?: string; // scaleconfiguration
  contextid?: number; // The context id
  contextlevel?: string; // The context level
  instanceid?: number; // The Instance id
  taxonomies?: string; // taxonomies
  id: number; // id
  timecreated?: number; // timecreated
  timemodified?: number; // timemodified
  usermodified?: number; // usermodified
}

export interface core_competency_update_competency_framework_parameters {
  competencyframework: core_competency_update_competency_framework_parameters_competencyframework;
}

type core_competency_update_competency_framework_returns = boolean;

export interface core_competency_list_competency_frameworks_parameters_context {
  contextid?: number; // default: int(0) // Context ID. Either use this value, or level and instanceid.
  contextlevel?: string; // default: string(0) "" // Context level. To be used with instanceid.
  instanceid?: number; // default: int(0) // Context instance ID. To be used with level
}

export interface core_competency_list_competency_frameworks_parameters {
  sort?: string; // default: string(9) "shortname" // Column to sort by.
  order?: string; // default: string(0) "" // Sort direction. Should be either ASC or DESC
  skip?: number; // default: int(0) // Skip this number of records before returning results
  limit?: number; // default: int(0) // Return this number of records at most.
  context: core_competency_list_competency_frameworks_parameters_context;
  includes?: string; // default: string(8) "children" // What other contextes to fetch the frameworks from. (children, parents, self)
  onlyvisible?: boolean; // default: bool(false) // Only visible frameworks will be returned if visible true
  query?: string; // default: string(0) "" // A query string to filter the results
}

export interface core_competency_list_competency_frameworks_returns_item {
  shortname: string; // shortname
  idnumber: string; // idnumber
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  visible: boolean; // visible
  scaleid: number; // scaleid
  scaleconfiguration: string; // scaleconfiguration
  contextid: number; // contextid
  taxonomies: string; // taxonomies
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  canmanage: boolean; // canmanage
  competenciescount: number; // competenciescount
  contextname: string; // contextname
  contextnamenoprefix: string; // contextnamenoprefix
}

export interface core_competency_count_competency_frameworks_parameters_context {
  contextid?: number; // default: int(0) // Context ID. Either use this value, or level and instanceid.
  contextlevel?: string; // default: string(0) "" // Context level. To be used with instanceid.
  instanceid?: number; // default: int(0) // Context instance ID. To be used with level
}

export interface core_competency_count_competency_frameworks_parameters {
  context: core_competency_count_competency_frameworks_parameters_context;
  includes?: string; // default: string(8) "children" // What other contextes to fetch the frameworks from. (children, parents, self)
}

type core_competency_count_competency_frameworks_returns = number;

export interface core_competency_competency_framework_viewed_parameters {
  id: number; // The competency framework id
}

type core_competency_competency_framework_viewed_returns = boolean;

export interface core_competency_create_competency_parameters_competency {
  shortname: string; // shortname
  idnumber: string; // idnumber
  description?: string; // default: string(0) "" // description
  descriptionformat?: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  sortorder?: number; // default: int(0) // sortorder
  parentid?: number; // default: int(0) // parentid
  path?: string; // default: string(3) "/0/" // path
  ruleoutcome?: number; // default: int(0) // ruleoutcome
  ruletype?: string; // default: NULL // ruletype
  ruleconfig?: string; // default: NULL // ruleconfig
  scaleid?: number; // default: NULL // scaleid
  scaleconfiguration?: string; // default: NULL // scaleconfiguration
  competencyframeworkid?: number; // default: int(0) // competencyframeworkid
  timecreated?: number; // default: int(0) // timecreated
  timemodified?: number; // default: int(0) // timemodified
  usermodified?: number; // default: int(0) // usermodified
}

export interface core_competency_create_competency_parameters {
  competency: core_competency_create_competency_parameters_competency;
}

export interface core_competency_create_competency_returns {
  shortname: string; // shortname
  idnumber: string; // idnumber
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  sortorder: number; // sortorder
  parentid: number; // parentid
  path: string; // path
  ruleoutcome: number; // ruleoutcome
  ruletype: string; // ruletype
  ruleconfig: string; // ruleconfig
  scaleid: number; // scaleid
  scaleconfiguration: string; // scaleconfiguration
  competencyframeworkid: number; // competencyframeworkid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
}

export interface core_competency_read_competency_parameters {
  id: number; // Data base record id for the competency
}

export interface core_competency_read_competency_returns {
  shortname: string; // shortname
  idnumber: string; // idnumber
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  sortorder: number; // sortorder
  parentid: number; // parentid
  path: string; // path
  ruleoutcome: number; // ruleoutcome
  ruletype: string; // ruletype
  ruleconfig: string; // ruleconfig
  scaleid: number; // scaleid
  scaleconfiguration: string; // scaleconfiguration
  competencyframeworkid: number; // competencyframeworkid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
}

export interface core_competency_competency_viewed_parameters {
  id: number; // The competency id
}

type core_competency_competency_viewed_returns = boolean;

export interface core_competency_delete_competency_parameters {
  id: number; // Data base record id for the competency
}

type core_competency_delete_competency_returns = boolean;

export interface core_competency_update_competency_parameters_competency {
  shortname?: string; // shortname
  idnumber?: string; // idnumber
  description?: string; // description
  descriptionformat?: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  sortorder?: number; // sortorder
  parentid?: number; // parentid
  path?: string; // path
  ruleoutcome?: number; // ruleoutcome
  ruletype?: string; // ruletype
  ruleconfig?: string; // ruleconfig
  scaleid?: number; // scaleid
  scaleconfiguration?: string; // scaleconfiguration
  competencyframeworkid?: number; // competencyframeworkid
  id: number; // id
  timecreated?: number; // timecreated
  timemodified?: number; // timemodified
  usermodified?: number; // usermodified
}

export interface core_competency_update_competency_parameters {
  competency: core_competency_update_competency_parameters_competency;
}

type core_competency_update_competency_returns = boolean;

export interface core_competency_list_competencies_parameters_filters {
  column: string; // Column name to filter by
  value: string; // Value to filter by. Must be exact match
}

export interface core_competency_list_competencies_parameters {
  filters: core_competency_list_competencies_parameters_filters[];
  sort?: string; // default: string(0) "" // Column to sort by.
  order?: string; // default: string(0) "" // Sort direction. Should be either ASC or DESC
  skip?: number; // default: int(0) // Skip this number of records before returning results
  limit?: number; // default: int(0) // Return this number of records at most.
}

export interface core_competency_list_competencies_returns_item {
  shortname: string; // shortname
  idnumber: string; // idnumber
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  sortorder: number; // sortorder
  parentid: number; // parentid
  path: string; // path
  ruleoutcome: number; // ruleoutcome
  ruletype: string; // ruletype
  ruleconfig: string; // ruleconfig
  scaleid: number; // scaleid
  scaleconfiguration: string; // scaleconfiguration
  competencyframeworkid: number; // competencyframeworkid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
}

export interface core_competency_list_competencies_in_template_parameters {
  id: number; // The template id
}

export interface core_competency_list_competencies_in_template_returns_item {
  shortname: string; // shortname
  idnumber: string; // idnumber
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  sortorder: number; // sortorder
  parentid: number; // parentid
  path: string; // path
  ruleoutcome: number; // ruleoutcome
  ruletype: string; // ruletype
  ruleconfig: string; // ruleconfig
  scaleid: number; // scaleid
  scaleconfiguration: string; // scaleconfiguration
  competencyframeworkid: number; // competencyframeworkid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
}

export interface core_competency_count_competencies_parameters_filters {
  column: string; // Column name to filter by
  value: string; // Value to filter by. Must be exact match
}

export interface core_competency_count_competencies_parameters {
  filters: core_competency_count_competencies_parameters_filters[];
}

type core_competency_count_competencies_returns = number;

export interface core_competency_count_competencies_in_template_parameters {
  id: number; // The template id
}

type core_competency_count_competencies_in_template_returns = number;

export interface core_competency_search_competencies_parameters {
  searchtext: string; // Text to search for
  competencyframeworkid: number; // Competency framework id
}

export interface core_competency_search_competencies_returns_item {
  shortname: string; // shortname
  idnumber: string; // idnumber
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  sortorder: number; // sortorder
  parentid: number; // parentid
  path: string; // path
  ruleoutcome: number; // ruleoutcome
  ruletype: string; // ruletype
  ruleconfig: string; // ruleconfig
  scaleid: number; // scaleid
  scaleconfiguration: string; // scaleconfiguration
  competencyframeworkid: number; // competencyframeworkid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
}

export interface core_competency_set_parent_competency_parameters {
  competencyid: number; // The competency id
  parentid: number; // The new competency parent id
}

type core_competency_set_parent_competency_returns = boolean;

export interface core_competency_move_up_competency_parameters {
  id: number; // The competency id
}

type core_competency_move_up_competency_returns = boolean;

export interface core_competency_move_down_competency_parameters {
  id: number; // The competency id
}

type core_competency_move_down_competency_returns = boolean;

export interface core_competency_list_course_module_competencies_parameters {
  cmid: number; // The course module id
}

export interface core_competency_list_course_module_competencies_returns_item_coursemodulecompetency {
  cmid: number; // cmid
  competencyid: number; // competencyid
  sortorder: number; // sortorder
  ruleoutcome: number; // ruleoutcome
  overridegrade: boolean; // overridegrade
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
}

export interface core_competency_list_course_module_competencies_returns_item_competency {
  shortname: string; // shortname
  idnumber: string; // idnumber
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  sortorder: number; // sortorder
  parentid: number; // parentid
  path: string; // path
  ruleoutcome: number; // ruleoutcome
  ruletype: string; // ruletype
  ruleconfig: string; // ruleconfig
  scaleid: number; // scaleid
  scaleconfiguration: string; // scaleconfiguration
  competencyframeworkid: number; // competencyframeworkid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
}

export interface core_competency_list_course_module_competencies_returns_item {
  competency: core_competency_list_course_module_competencies_returns_item_competency;
  coursemodulecompetency: core_competency_list_course_module_competencies_returns_item_coursemodulecompetency;
}

export interface core_competency_count_course_module_competencies_parameters {
  cmid: number; // The course module id
}

type core_competency_count_course_module_competencies_returns = number;

export interface core_competency_list_course_competencies_parameters {
  id: number; // The course id
}

export interface core_competency_list_course_competencies_returns_item_coursecompetency {
  courseid: number; // courseid
  competencyid: number; // competencyid
  sortorder: number; // sortorder
  ruleoutcome: number; // ruleoutcome
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
}

export interface core_competency_list_course_competencies_returns_item_competency {
  shortname: string; // shortname
  idnumber: string; // idnumber
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  sortorder: number; // sortorder
  parentid: number; // parentid
  path: string; // path
  ruleoutcome: number; // ruleoutcome
  ruletype: string; // ruletype
  ruleconfig: string; // ruleconfig
  scaleid: number; // scaleid
  scaleconfiguration: string; // scaleconfiguration
  competencyframeworkid: number; // competencyframeworkid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
}

export interface core_competency_list_course_competencies_returns_item {
  competency: core_competency_list_course_competencies_returns_item_competency;
  coursecompetency: core_competency_list_course_competencies_returns_item_coursecompetency;
}

export interface core_competency_count_competencies_in_course_parameters {
  id: number; // The course id
}

type core_competency_count_competencies_in_course_returns = number;

export interface core_competency_count_courses_using_competency_parameters {
  id: number; // The competency id
}

type core_competency_count_courses_using_competency_returns = number;

export interface core_competency_add_competency_to_course_parameters {
  courseid: number; // The course id
  competencyid: number; // The competency id
}

type core_competency_add_competency_to_course_returns = boolean;

export interface core_competency_add_competency_to_template_parameters {
  templateid: number; // The template id
  competencyid: number; // The competency id
}

type core_competency_add_competency_to_template_returns = boolean;

export interface core_competency_remove_competency_from_course_parameters {
  courseid: number; // The course id
  competencyid: number; // The competency id
}

type core_competency_remove_competency_from_course_returns = boolean;

export interface core_competency_set_course_competency_ruleoutcome_parameters {
  coursecompetencyid: number; // Data base record id for the course competency
  ruleoutcome: number; // Ruleoutcome value
}

type core_competency_set_course_competency_ruleoutcome_returns = boolean;

export interface core_competency_remove_competency_from_template_parameters {
  templateid: number; // The template id
  competencyid: number; // The competency id
}

type core_competency_remove_competency_from_template_returns = boolean;

export interface core_competency_reorder_course_competency_parameters {
  courseid: number; // The course id
  competencyidfrom: number; // The competency id we are moving
  competencyidto: number; // The competency id we are moving to
}

type core_competency_reorder_course_competency_returns = boolean;

export interface core_competency_reorder_template_competency_parameters {
  templateid: number; // The template id
  competencyidfrom: number; // The competency id we are moving
  competencyidto: number; // The competency id we are moving to
}

type core_competency_reorder_template_competency_returns = boolean;

export interface core_competency_create_template_parameters_template {
  shortname: string; // shortname
  description?: string; // default: string(0) "" // description
  descriptionformat?: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  duedate?: number; // default: int(0) // duedate
  visible?: boolean; // default: int(1) // visible
  contextid?: number; // The context id
  contextlevel?: string; // The context level
  instanceid?: number; // The Instance id
  timecreated?: number; // default: int(0) // timecreated
  timemodified?: number; // default: int(0) // timemodified
  usermodified?: number; // default: int(0) // usermodified
}

export interface core_competency_create_template_parameters {
  template: core_competency_create_template_parameters_template;
}

export interface core_competency_create_template_returns {
  shortname: string; // shortname
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  duedate: number; // duedate
  visible: boolean; // visible
  contextid: number; // contextid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  duedateformatted: string; // duedateformatted
  cohortscount: number; // cohortscount
  planscount: number; // planscount
  canmanage: boolean; // canmanage
  canread: boolean; // canread
  contextname: string; // contextname
  contextnamenoprefix: string; // contextnamenoprefix
}

export interface core_competency_duplicate_template_parameters {
  id: number; // The template id
}

export interface core_competency_duplicate_template_returns {
  shortname: string; // shortname
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  duedate: number; // duedate
  visible: boolean; // visible
  contextid: number; // contextid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  duedateformatted: string; // duedateformatted
  cohortscount: number; // cohortscount
  planscount: number; // planscount
  canmanage: boolean; // canmanage
  canread: boolean; // canread
  contextname: string; // contextname
  contextnamenoprefix: string; // contextnamenoprefix
}

export interface core_competency_read_template_parameters {
  id: number; // Data base record id for the template
}

export interface core_competency_read_template_returns {
  shortname: string; // shortname
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  duedate: number; // duedate
  visible: boolean; // visible
  contextid: number; // contextid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  duedateformatted: string; // duedateformatted
  cohortscount: number; // cohortscount
  planscount: number; // planscount
  canmanage: boolean; // canmanage
  canread: boolean; // canread
  contextname: string; // contextname
  contextnamenoprefix: string; // contextnamenoprefix
}

export interface core_competency_delete_template_parameters {
  id: number; // Data base record id for the template
  deleteplans: boolean; // Boolean to indicate if plans must be deleted
}

type core_competency_delete_template_returns = boolean;

export interface core_competency_update_template_parameters_template {
  shortname?: string; // shortname
  description?: string; // description
  descriptionformat?: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  duedate?: number; // duedate
  visible?: boolean; // visible
  contextid?: number; // The context id
  contextlevel?: string; // The context level
  instanceid?: number; // The Instance id
  id: number; // id
  timecreated?: number; // timecreated
  timemodified?: number; // timemodified
  usermodified?: number; // usermodified
}

export interface core_competency_update_template_parameters {
  template: core_competency_update_template_parameters_template;
}

type core_competency_update_template_returns = boolean;

export interface core_competency_list_templates_parameters_context {
  contextid?: number; // default: int(0) // Context ID. Either use this value, or level and instanceid.
  contextlevel?: string; // default: string(0) "" // Context level. To be used with instanceid.
  instanceid?: number; // default: int(0) // Context instance ID. To be used with level
}

export interface core_competency_list_templates_parameters {
  sort?: string; // default: string(0) "" // Column to sort by.
  order?: string; // default: string(0) "" // Sort direction. Should be either ASC or DESC
  skip?: number; // default: int(0) // Skip this number of records before returning results
  limit?: number; // default: int(0) // Return this number of records at most.
  context: core_competency_list_templates_parameters_context;
  includes?: string; // default: string(8) "children" // What other contexts to fetch the templates from. (children, parents, self)
  onlyvisible?: boolean; // default: bool(false) // If should list only visible templates
}

export interface core_competency_list_templates_returns_item {
  shortname: string; // shortname
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  duedate: number; // duedate
  visible: boolean; // visible
  contextid: number; // contextid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  duedateformatted: string; // duedateformatted
  cohortscount: number; // cohortscount
  planscount: number; // planscount
  canmanage: boolean; // canmanage
  canread: boolean; // canread
  contextname: string; // contextname
  contextnamenoprefix: string; // contextnamenoprefix
}

export interface core_competency_list_templates_using_competency_parameters {
  id: number; // The competency id
}

export interface core_competency_list_templates_using_competency_returns_item {
  shortname: string; // shortname
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  duedate: number; // duedate
  visible: boolean; // visible
  contextid: number; // contextid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  duedateformatted: string; // duedateformatted
  cohortscount: number; // cohortscount
  planscount: number; // planscount
  canmanage: boolean; // canmanage
  canread: boolean; // canread
  contextname: string; // contextname
  contextnamenoprefix: string; // contextnamenoprefix
}

export interface core_competency_count_templates_parameters_context {
  contextid?: number; // default: int(0) // Context ID. Either use this value, or level and instanceid.
  contextlevel?: string; // default: string(0) "" // Context level. To be used with instanceid.
  instanceid?: number; // default: int(0) // Context instance ID. To be used with level
}

export interface core_competency_count_templates_parameters {
  context: core_competency_count_templates_parameters_context;
  includes?: string; // default: string(8) "children" // What other contextes to fetch the frameworks from. (children, parents, self)
}

type core_competency_count_templates_returns = number;

export interface core_competency_count_templates_using_competency_parameters {
  id: number; // The competency id
}

type core_competency_count_templates_using_competency_returns = number;

export interface core_competency_create_plan_parameters_plan {
  name: string; // name
  description?: string; // default: string(0) "" // description
  descriptionformat?: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  userid: number; // userid
  templateid?: number; // default: NULL // templateid
  origtemplateid?: number; // default: NULL // origtemplateid
  status?: number; // default: int(0) // status
  duedate?: number; // default: int(0) // duedate
  reviewerid?: number; // default: NULL // reviewerid
  timecreated?: number; // default: int(0) // timecreated
  timemodified?: number; // default: int(0) // timemodified
  usermodified?: number; // default: int(0) // usermodified
}

export interface core_competency_create_plan_parameters {
  plan: core_competency_create_plan_parameters_plan;
}

export interface core_competency_create_plan_returns_template {
  shortname: string; // shortname
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  duedate: number; // duedate
  visible: boolean; // visible
  contextid: number; // contextid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  duedateformatted: string; // duedateformatted
  cohortscount: number; // cohortscount
  planscount: number; // planscount
  canmanage: boolean; // canmanage
  canread: boolean; // canread
  contextname: string; // contextname
  contextnamenoprefix: string; // contextnamenoprefix
}

export interface core_competency_create_plan_returns_reviewer {
  id: number; // id
  email: string; // email
  idnumber: string; // idnumber
  phone1: string; // phone1
  phone2: string; // phone2
  department: string; // department
  institution: string; // institution
  fullname: string; // fullname
  identity: string; // identity
  profileurl: string; // profileurl
  profileimageurl: string; // profileimageurl
  profileimageurlsmall: string; // profileimageurlsmall
}

export interface core_competency_create_plan_returns_commentarea {
  component: string; // component
  commentarea: string; // commentarea
  itemid: number; // itemid
  courseid: number; // courseid
  contextid: number; // contextid
  cid: string; // cid
  autostart: boolean; // autostart
  canpost: boolean; // canpost
  canview: boolean; // canview
  count: number; // count
  collapsediconkey: string; // collapsediconkey
  displaytotalcount: boolean; // displaytotalcount
  displaycancel: boolean; // displaycancel
  fullwidth: boolean; // fullwidth
  linktext: string; // linktext
  notoggle: boolean; // notoggle
  template: string; // template
  canpostorhascomments: boolean; // canpostorhascomments
}

export interface core_competency_create_plan_returns {
  name: string; // name
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  userid: number; // userid
  templateid: number; // templateid
  origtemplateid: number; // origtemplateid
  status: number; // status
  duedate: number; // duedate
  reviewerid: number; // reviewerid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  statusname: string; // statusname
  isbasedontemplate: boolean; // isbasedontemplate
  canmanage: boolean; // canmanage
  canrequestreview: boolean; // canrequestreview
  canreview: boolean; // canreview
  canbeedited: boolean; // canbeedited
  isactive: boolean; // isactive
  isdraft: boolean; // isdraft
  iscompleted: boolean; // iscompleted
  isinreview: boolean; // isinreview
  iswaitingforreview: boolean; // iswaitingforreview
  isreopenallowed: boolean; // isreopenallowed
  iscompleteallowed: boolean; // iscompleteallowed
  isunlinkallowed: boolean; // isunlinkallowed
  isrequestreviewallowed: boolean; // isrequestreviewallowed
  iscancelreviewrequestallowed: boolean; // iscancelreviewrequestallowed
  isstartreviewallowed: boolean; // isstartreviewallowed
  isstopreviewallowed: boolean; // isstopreviewallowed
  isapproveallowed: boolean; // isapproveallowed
  isunapproveallowed: boolean; // isunapproveallowed
  duedateformatted: string; // duedateformatted
  commentarea: core_competency_create_plan_returns_commentarea;
  reviewer?: core_competency_create_plan_returns_reviewer;
  template?: core_competency_create_plan_returns_template;
  url: string; // url
}

export interface core_competency_update_plan_parameters_plan {
  name?: string; // name
  description?: string; // description
  descriptionformat?: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  userid?: number; // userid
  templateid?: number; // templateid
  origtemplateid?: number; // origtemplateid
  status?: number; // status
  duedate?: number; // duedate
  reviewerid?: number; // reviewerid
  id: number; // id
  timecreated?: number; // timecreated
  timemodified?: number; // timemodified
  usermodified?: number; // usermodified
}

export interface core_competency_update_plan_parameters {
  plan: core_competency_update_plan_parameters_plan;
}

export interface core_competency_update_plan_returns_template {
  shortname: string; // shortname
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  duedate: number; // duedate
  visible: boolean; // visible
  contextid: number; // contextid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  duedateformatted: string; // duedateformatted
  cohortscount: number; // cohortscount
  planscount: number; // planscount
  canmanage: boolean; // canmanage
  canread: boolean; // canread
  contextname: string; // contextname
  contextnamenoprefix: string; // contextnamenoprefix
}

export interface core_competency_update_plan_returns_reviewer {
  id: number; // id
  email: string; // email
  idnumber: string; // idnumber
  phone1: string; // phone1
  phone2: string; // phone2
  department: string; // department
  institution: string; // institution
  fullname: string; // fullname
  identity: string; // identity
  profileurl: string; // profileurl
  profileimageurl: string; // profileimageurl
  profileimageurlsmall: string; // profileimageurlsmall
}

export interface core_competency_update_plan_returns_commentarea {
  component: string; // component
  commentarea: string; // commentarea
  itemid: number; // itemid
  courseid: number; // courseid
  contextid: number; // contextid
  cid: string; // cid
  autostart: boolean; // autostart
  canpost: boolean; // canpost
  canview: boolean; // canview
  count: number; // count
  collapsediconkey: string; // collapsediconkey
  displaytotalcount: boolean; // displaytotalcount
  displaycancel: boolean; // displaycancel
  fullwidth: boolean; // fullwidth
  linktext: string; // linktext
  notoggle: boolean; // notoggle
  template: string; // template
  canpostorhascomments: boolean; // canpostorhascomments
}

export interface core_competency_update_plan_returns {
  name: string; // name
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  userid: number; // userid
  templateid: number; // templateid
  origtemplateid: number; // origtemplateid
  status: number; // status
  duedate: number; // duedate
  reviewerid: number; // reviewerid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  statusname: string; // statusname
  isbasedontemplate: boolean; // isbasedontemplate
  canmanage: boolean; // canmanage
  canrequestreview: boolean; // canrequestreview
  canreview: boolean; // canreview
  canbeedited: boolean; // canbeedited
  isactive: boolean; // isactive
  isdraft: boolean; // isdraft
  iscompleted: boolean; // iscompleted
  isinreview: boolean; // isinreview
  iswaitingforreview: boolean; // iswaitingforreview
  isreopenallowed: boolean; // isreopenallowed
  iscompleteallowed: boolean; // iscompleteallowed
  isunlinkallowed: boolean; // isunlinkallowed
  isrequestreviewallowed: boolean; // isrequestreviewallowed
  iscancelreviewrequestallowed: boolean; // iscancelreviewrequestallowed
  isstartreviewallowed: boolean; // isstartreviewallowed
  isstopreviewallowed: boolean; // isstopreviewallowed
  isapproveallowed: boolean; // isapproveallowed
  isunapproveallowed: boolean; // isunapproveallowed
  duedateformatted: string; // duedateformatted
  commentarea: core_competency_update_plan_returns_commentarea;
  reviewer?: core_competency_update_plan_returns_reviewer;
  template?: core_competency_update_plan_returns_template;
  url: string; // url
}

export interface core_competency_complete_plan_parameters {
  planid: number; // The plan id
}

type core_competency_complete_plan_returns = boolean;

export interface core_competency_reopen_plan_parameters {
  planid: number; // The plan id
}

type core_competency_reopen_plan_returns = boolean;

export interface core_competency_read_plan_parameters {
  id: number; // Data base record id for the plan
}

export interface core_competency_read_plan_returns_template {
  shortname: string; // shortname
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  duedate: number; // duedate
  visible: boolean; // visible
  contextid: number; // contextid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  duedateformatted: string; // duedateformatted
  cohortscount: number; // cohortscount
  planscount: number; // planscount
  canmanage: boolean; // canmanage
  canread: boolean; // canread
  contextname: string; // contextname
  contextnamenoprefix: string; // contextnamenoprefix
}

export interface core_competency_read_plan_returns_reviewer {
  id: number; // id
  email: string; // email
  idnumber: string; // idnumber
  phone1: string; // phone1
  phone2: string; // phone2
  department: string; // department
  institution: string; // institution
  fullname: string; // fullname
  identity: string; // identity
  profileurl: string; // profileurl
  profileimageurl: string; // profileimageurl
  profileimageurlsmall: string; // profileimageurlsmall
}

export interface core_competency_read_plan_returns_commentarea {
  component: string; // component
  commentarea: string; // commentarea
  itemid: number; // itemid
  courseid: number; // courseid
  contextid: number; // contextid
  cid: string; // cid
  autostart: boolean; // autostart
  canpost: boolean; // canpost
  canview: boolean; // canview
  count: number; // count
  collapsediconkey: string; // collapsediconkey
  displaytotalcount: boolean; // displaytotalcount
  displaycancel: boolean; // displaycancel
  fullwidth: boolean; // fullwidth
  linktext: string; // linktext
  notoggle: boolean; // notoggle
  template: string; // template
  canpostorhascomments: boolean; // canpostorhascomments
}

export interface core_competency_read_plan_returns {
  name: string; // name
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  userid: number; // userid
  templateid: number; // templateid
  origtemplateid: number; // origtemplateid
  status: number; // status
  duedate: number; // duedate
  reviewerid: number; // reviewerid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  statusname: string; // statusname
  isbasedontemplate: boolean; // isbasedontemplate
  canmanage: boolean; // canmanage
  canrequestreview: boolean; // canrequestreview
  canreview: boolean; // canreview
  canbeedited: boolean; // canbeedited
  isactive: boolean; // isactive
  isdraft: boolean; // isdraft
  iscompleted: boolean; // iscompleted
  isinreview: boolean; // isinreview
  iswaitingforreview: boolean; // iswaitingforreview
  isreopenallowed: boolean; // isreopenallowed
  iscompleteallowed: boolean; // iscompleteallowed
  isunlinkallowed: boolean; // isunlinkallowed
  isrequestreviewallowed: boolean; // isrequestreviewallowed
  iscancelreviewrequestallowed: boolean; // iscancelreviewrequestallowed
  isstartreviewallowed: boolean; // isstartreviewallowed
  isstopreviewallowed: boolean; // isstopreviewallowed
  isapproveallowed: boolean; // isapproveallowed
  isunapproveallowed: boolean; // isunapproveallowed
  duedateformatted: string; // duedateformatted
  commentarea: core_competency_read_plan_returns_commentarea;
  reviewer?: core_competency_read_plan_returns_reviewer;
  template?: core_competency_read_plan_returns_template;
  url: string; // url
}

export interface core_competency_delete_plan_parameters {
  id: number; // Data base record id for the learning plan
}

type core_competency_delete_plan_returns = boolean;

export interface core_competency_list_user_plans_parameters {
  userid: number; // The user ID
}

export interface core_competency_list_user_plans_returns_item_template {
  shortname: string; // shortname
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  duedate: number; // duedate
  visible: boolean; // visible
  contextid: number; // contextid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  duedateformatted: string; // duedateformatted
  cohortscount: number; // cohortscount
  planscount: number; // planscount
  canmanage: boolean; // canmanage
  canread: boolean; // canread
  contextname: string; // contextname
  contextnamenoprefix: string; // contextnamenoprefix
}

export interface core_competency_list_user_plans_returns_item_reviewer {
  id: number; // id
  email: string; // email
  idnumber: string; // idnumber
  phone1: string; // phone1
  phone2: string; // phone2
  department: string; // department
  institution: string; // institution
  fullname: string; // fullname
  identity: string; // identity
  profileurl: string; // profileurl
  profileimageurl: string; // profileimageurl
  profileimageurlsmall: string; // profileimageurlsmall
}

export interface core_competency_list_user_plans_returns_item_commentarea {
  component: string; // component
  commentarea: string; // commentarea
  itemid: number; // itemid
  courseid: number; // courseid
  contextid: number; // contextid
  cid: string; // cid
  autostart: boolean; // autostart
  canpost: boolean; // canpost
  canview: boolean; // canview
  count: number; // count
  collapsediconkey: string; // collapsediconkey
  displaytotalcount: boolean; // displaytotalcount
  displaycancel: boolean; // displaycancel
  fullwidth: boolean; // fullwidth
  linktext: string; // linktext
  notoggle: boolean; // notoggle
  template: string; // template
  canpostorhascomments: boolean; // canpostorhascomments
}

export interface core_competency_list_user_plans_returns_item {
  name: string; // name
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  userid: number; // userid
  templateid: number; // templateid
  origtemplateid: number; // origtemplateid
  status: number; // status
  duedate: number; // duedate
  reviewerid: number; // reviewerid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  statusname: string; // statusname
  isbasedontemplate: boolean; // isbasedontemplate
  canmanage: boolean; // canmanage
  canrequestreview: boolean; // canrequestreview
  canreview: boolean; // canreview
  canbeedited: boolean; // canbeedited
  isactive: boolean; // isactive
  isdraft: boolean; // isdraft
  iscompleted: boolean; // iscompleted
  isinreview: boolean; // isinreview
  iswaitingforreview: boolean; // iswaitingforreview
  isreopenallowed: boolean; // isreopenallowed
  iscompleteallowed: boolean; // iscompleteallowed
  isunlinkallowed: boolean; // isunlinkallowed
  isrequestreviewallowed: boolean; // isrequestreviewallowed
  iscancelreviewrequestallowed: boolean; // iscancelreviewrequestallowed
  isstartreviewallowed: boolean; // isstartreviewallowed
  isstopreviewallowed: boolean; // isstopreviewallowed
  isapproveallowed: boolean; // isapproveallowed
  isunapproveallowed: boolean; // isunapproveallowed
  duedateformatted: string; // duedateformatted
  commentarea: core_competency_list_user_plans_returns_item_commentarea;
  reviewer?: core_competency_list_user_plans_returns_item_reviewer;
  template?: core_competency_list_user_plans_returns_item_template;
  url: string; // url
}

export interface core_competency_list_plan_competencies_parameters {
  id: number; // The plan ID.
}

export interface core_competency_list_plan_competencies_returns_item_usercompetencyplan {
  userid: number; // userid
  competencyid: number; // competencyid
  proficiency: boolean; // proficiency
  grade: number; // grade
  planid: number; // planid
  sortorder: number; // sortorder
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  gradename: string; // gradename
  proficiencyname: string; // proficiencyname
}

export interface core_competency_list_plan_competencies_returns_item_usercompetency_reviewer {
  id: number; // id
  email: string; // email
  idnumber: string; // idnumber
  phone1: string; // phone1
  phone2: string; // phone2
  department: string; // department
  institution: string; // institution
  fullname: string; // fullname
  identity: string; // identity
  profileurl: string; // profileurl
  profileimageurl: string; // profileimageurl
  profileimageurlsmall: string; // profileimageurlsmall
}

export interface core_competency_list_plan_competencies_returns_item_usercompetency {
  userid: number; // userid
  competencyid: number; // competencyid
  status: number; // status
  reviewerid: number; // reviewerid
  proficiency: boolean; // proficiency
  grade: number; // grade
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  canrequestreview: boolean; // canrequestreview
  canreview: boolean; // canreview
  gradename: string; // gradename
  isrequestreviewallowed: boolean; // isrequestreviewallowed
  iscancelreviewrequestallowed: boolean; // iscancelreviewrequestallowed
  isstartreviewallowed: boolean; // isstartreviewallowed
  isstopreviewallowed: boolean; // isstopreviewallowed
  isstatusidle: boolean; // isstatusidle
  isstatusinreview: boolean; // isstatusinreview
  isstatuswaitingforreview: boolean; // isstatuswaitingforreview
  proficiencyname: string; // proficiencyname
  reviewer?: core_competency_list_plan_competencies_returns_item_usercompetency_reviewer;
  statusname: string; // statusname
  url: string; // url
}

export interface core_competency_list_plan_competencies_returns_item_competency {
  shortname: string; // shortname
  idnumber: string; // idnumber
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  sortorder: number; // sortorder
  parentid: number; // parentid
  path: string; // path
  ruleoutcome: number; // ruleoutcome
  ruletype: string; // ruletype
  ruleconfig: string; // ruleconfig
  scaleid: number; // scaleid
  scaleconfiguration: string; // scaleconfiguration
  competencyframeworkid: number; // competencyframeworkid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
}

export interface core_competency_list_plan_competencies_returns_item {
  competency: core_competency_list_plan_competencies_returns_item_competency;
  usercompetency?: core_competency_list_plan_competencies_returns_item_usercompetency;
  usercompetencyplan?: core_competency_list_plan_competencies_returns_item_usercompetencyplan;
}

export interface core_competency_add_competency_to_plan_parameters {
  planid: number; // The plan id
  competencyid: number; // The competency id
}

type core_competency_add_competency_to_plan_returns = boolean;

export interface core_competency_remove_competency_from_plan_parameters {
  planid: number; // The plan id
  competencyid: number; // The competency id
}

type core_competency_remove_competency_from_plan_returns = boolean;

export interface core_competency_reorder_plan_competency_parameters {
  planid: number; // The plan id
  competencyidfrom: number; // The competency id we are moving
  competencyidto: number; // The competency id we are moving to
}

type core_competency_reorder_plan_competency_returns = boolean;

export interface core_competency_plan_request_review_parameters {
  id: number; // The plan ID
}

type core_competency_plan_request_review_returns = boolean;

export interface core_competency_plan_start_review_parameters {
  id: number; // The plan ID
}

type core_competency_plan_start_review_returns = boolean;

export interface core_competency_plan_stop_review_parameters {
  id: number; // The plan ID
}

type core_competency_plan_stop_review_returns = boolean;

export interface core_competency_plan_cancel_review_request_parameters {
  id: number; // The plan ID
}

type core_competency_plan_cancel_review_request_returns = boolean;

export interface core_competency_approve_plan_parameters {
  id: number; // The plan ID
}

type core_competency_approve_plan_returns = boolean;

export interface core_competency_unapprove_plan_parameters {
  id: number; // The plan ID
}

type core_competency_unapprove_plan_returns = boolean;

export interface core_competency_template_has_related_data_parameters {
  id: number; // The template id
}

type core_competency_template_has_related_data_returns = boolean;

export interface core_competency_get_scale_values_parameters {
  scaleid: number; // The scale id
}

export interface core_competency_get_scale_values_returns_item {
  id: number; // Scale value ID
  name: string; // Scale value name
}

export interface core_competency_add_related_competency_parameters {
  competencyid: number; // The competency id
  relatedcompetencyid: number; // The related competency id
}

type core_competency_add_related_competency_returns = boolean;

export interface core_competency_remove_related_competency_parameters {
  competencyid: number; // The competency id
  relatedcompetencyid: number; // The related competency id
}

type core_competency_remove_related_competency_returns = boolean;

export interface core_competency_read_user_evidence_parameters {
  id: number; // The user evidence ID.
}

export interface core_competency_read_user_evidence_returns_files {
  contextid: number; // contextid
  component: string; // component
  filearea: string; // filearea
  itemid: number; // itemid
  filepath: string; // filepath
  filename: string; // filename
  isdir: boolean; // isdir
  isimage: boolean; // isimage
  timemodified: number; // timemodified
  timecreated: number; // timecreated
  filesize: number; // filesize
  author: string; // author
  license: string; // license
  filenameshort: string; // filenameshort
  filesizeformatted: string; // filesizeformatted
  icon: string; // icon
  timecreatedformatted: string; // timecreatedformatted
  timemodifiedformatted: string; // timemodifiedformatted
  url: string; // url
}

export interface core_competency_read_user_evidence_returns_competencies {
  shortname: string; // shortname
  idnumber: string; // idnumber
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  sortorder: number; // sortorder
  parentid: number; // parentid
  path: string; // path
  ruleoutcome: number; // ruleoutcome
  ruletype: string; // ruletype
  ruleconfig: string; // ruleconfig
  scaleid: number; // scaleid
  scaleconfiguration: string; // scaleconfiguration
  competencyframeworkid: number; // competencyframeworkid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
}

export interface core_competency_read_user_evidence_returns {
  userid: number; // userid
  name: string; // name
  description: string; // description
  descriptionformat: number; // default: string(1) "1" // description format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  url: string; // url
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  canmanage: boolean; // canmanage
  competencycount: number; // competencycount
  competencies: core_competency_read_user_evidence_returns_competencies[]; // competencies
  filecount: number; // filecount
  files: core_competency_read_user_evidence_returns_files[]; // files
  hasurlorfiles: boolean; // hasurlorfiles
  urlshort: string; // urlshort
}

export interface core_competency_delete_user_evidence_parameters {
  id: number; // The user evidence ID.
}

type core_competency_delete_user_evidence_returns = boolean;

export interface core_competency_create_user_evidence_competency_parameters {
  userevidenceid: number; // The user evidence ID.
  competencyid: number; // The competency ID.
}

export interface core_competency_create_user_evidence_competency_returns {
  userevidenceid: number; // userevidenceid
  competencyid: number; // competencyid
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
}

export interface core_competency_delete_user_evidence_competency_parameters {
  userevidenceid: number; // The user evidence ID.
  competencyid: number; // The competency ID.
}

type core_competency_delete_user_evidence_competency_returns = boolean;

export interface core_competency_user_competency_cancel_review_request_parameters {
  userid: number; // The user ID
  competencyid: number; // The competency ID
}

type core_competency_user_competency_cancel_review_request_returns = boolean;

export interface core_competency_user_competency_request_review_parameters {
  userid: number; // The user ID
  competencyid: number; // The competency ID
}

type core_competency_user_competency_request_review_returns = boolean;

export interface core_competency_user_competency_start_review_parameters {
  userid: number; // The user ID
  competencyid: number; // The competency ID
}

type core_competency_user_competency_start_review_returns = boolean;

export interface core_competency_user_competency_stop_review_parameters {
  userid: number; // The user ID
  competencyid: number; // The competency ID
}

type core_competency_user_competency_stop_review_returns = boolean;

export interface core_competency_user_competency_viewed_parameters {
  usercompetencyid: number; // The user competency id
}

type core_competency_user_competency_viewed_returns = boolean;

export interface core_competency_user_competency_viewed_in_plan_parameters {
  competencyid: number; // The competency id
  userid: number; // The user id
  planid: number; // The plan id
}

type core_competency_user_competency_viewed_in_plan_returns = boolean;

export interface core_competency_user_competency_viewed_in_course_parameters {
  competencyid: number; // The competency id
  userid: number; // The user id
  courseid: number; // The course id
}

type core_competency_user_competency_viewed_in_course_returns = boolean;

export interface core_competency_user_competency_plan_viewed_parameters {
  competencyid: number; // The competency id
  userid: number; // The user id
  planid: number; // The plan id
}

type core_competency_user_competency_plan_viewed_returns = boolean;

export interface core_competency_grade_competency_parameters {
  userid: number; // User ID
  competencyid: number; // Competency ID
  grade: number; // New grade
  note?: string; // default: NULL // A note to attach to the evidence
}

export interface core_competency_grade_competency_returns_actionuser {
  id: number; // id
  email: string; // email
  idnumber: string; // idnumber
  phone1: string; // phone1
  phone2: string; // phone2
  department: string; // department
  institution: string; // institution
  fullname: string; // fullname
  identity: string; // identity
  profileurl: string; // profileurl
  profileimageurl: string; // profileimageurl
  profileimageurlsmall: string; // profileimageurlsmall
}

export interface core_competency_grade_competency_returns {
  usercompetencyid: number; // usercompetencyid
  contextid: number; // contextid
  action: number; // action
  actionuserid: number; // actionuserid
  descidentifier: string; // descidentifier
  desccomponent: string; // desccomponent
  desca: string; // desca
  url: string; // url
  grade: number; // grade
  note: string; // note
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  actionuser?: core_competency_grade_competency_returns_actionuser;
  description: string; // description
  gradename: string; // gradename
  userdate: string; // userdate
  candelete: boolean; // candelete
}

export interface core_competency_grade_competency_in_plan_parameters {
  planid: number; // Plan id
  competencyid: number; // Competency id
  grade: number; // New grade
  note?: string; // default: NULL // A note to attach to the evidence
}

export interface core_competency_grade_competency_in_plan_returns_actionuser {
  id: number; // id
  email: string; // email
  idnumber: string; // idnumber
  phone1: string; // phone1
  phone2: string; // phone2
  department: string; // department
  institution: string; // institution
  fullname: string; // fullname
  identity: string; // identity
  profileurl: string; // profileurl
  profileimageurl: string; // profileimageurl
  profileimageurlsmall: string; // profileimageurlsmall
}

export interface core_competency_grade_competency_in_plan_returns {
  usercompetencyid: number; // usercompetencyid
  contextid: number; // contextid
  action: number; // action
  actionuserid: number; // actionuserid
  descidentifier: string; // descidentifier
  desccomponent: string; // desccomponent
  desca: string; // desca
  url: string; // url
  grade: number; // grade
  note: string; // note
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  actionuser?: core_competency_grade_competency_in_plan_returns_actionuser;
  description: string; // description
  gradename: string; // gradename
  userdate: string; // userdate
  candelete: boolean; // candelete
}

export interface core_competency_grade_competency_in_course_parameters {
  courseid: number; // Course id
  userid: number; // User id
  competencyid: number; // Competency id
  grade: number; // New grade
  note?: string; // default: NULL // A note to attach to the evidence
}

export interface core_competency_grade_competency_in_course_returns_actionuser {
  id: number; // id
  email: string; // email
  idnumber: string; // idnumber
  phone1: string; // phone1
  phone2: string; // phone2
  department: string; // department
  institution: string; // institution
  fullname: string; // fullname
  identity: string; // identity
  profileurl: string; // profileurl
  profileimageurl: string; // profileimageurl
  profileimageurlsmall: string; // profileimageurlsmall
}

export interface core_competency_grade_competency_in_course_returns {
  usercompetencyid: number; // usercompetencyid
  contextid: number; // contextid
  action: number; // action
  actionuserid: number; // actionuserid
  descidentifier: string; // descidentifier
  desccomponent: string; // desccomponent
  desca: string; // desca
  url: string; // url
  grade: number; // grade
  note: string; // note
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  actionuser?: core_competency_grade_competency_in_course_returns_actionuser;
  description: string; // description
  gradename: string; // gradename
  userdate: string; // userdate
  candelete: boolean; // candelete
}

export interface core_competency_unlink_plan_from_template_parameters {
  planid: number; // Data base record id for the plan
}

type core_competency_unlink_plan_from_template_returns = boolean;

export interface core_competency_template_viewed_parameters {
  id: number; // Data base record id for the template
}

type core_competency_template_viewed_returns = boolean;

export interface core_competency_request_review_of_user_evidence_linked_competencies_parameters {
  id: number; // The user evidence ID.
}

type core_competency_request_review_of_user_evidence_linked_competencies_returns = boolean;

export interface core_competency_update_course_competency_settings_parameters_settings {
  pushratingstouserplans: boolean; // New value of the setting
}

export interface core_competency_update_course_competency_settings_parameters {
  courseid: number; // Course id for the course to update
  settings: core_competency_update_course_competency_settings_parameters_settings;
}

type core_competency_update_course_competency_settings_returns = boolean;

export interface core_competency_delete_evidence_parameters {
  id: number; // The evidence ID
}

type core_competency_delete_evidence_returns = boolean;

type core_webservice_get_site_info_parameters_serviceshortnames = string;

export interface core_webservice_get_site_info_parameters {
  serviceshortnames?: core_webservice_get_site_info_parameters_serviceshortnames[]; // default: array(0) { } // DEPRECATED PARAMETER - it was a design error in the original implementation. \ It is ignored now. (parameter kept for backward compatibility)
}

export interface core_webservice_get_site_info_returns_advancedfeatures {
  name: string; // feature name
  value: number; // feature value. Usually 1 means enabled.
}

export interface core_webservice_get_site_info_returns_functions {
  name: string; // function name
  version: string; // The version number of the component to which the function belongs
}

export interface core_webservice_get_site_info_returns {
  sitename: string; // site name
  username: string; // username
  firstname: string; // first name
  lastname: string; // last name
  fullname: string; // user full name
  lang: string; // Current language.
  userid: number; // user id
  siteurl: string; // site url
  userpictureurl: string; // the user profile picture. Warning: this url is the public URL that only works when forcelogin is set to NO and guestaccess is set to YES. In order to retrieve user profile pictures independently of the Moodle config, replace "pluginfile.php" by "webservice/pluginfile.php?token=WSTOKEN&file=" Of course the user can only see profile picture depending on his/her permissions. Moreover it is recommended to use HTTPS too.
  functions: core_webservice_get_site_info_returns_functions[];
  downloadfiles?: number; // 1 if users are allowed to download files, 0 if not
  uploadfiles?: number; // 1 if users are allowed to upload files, 0 if not
  release?: string; // Moodle release number
  version?: string; // Moodle version number
  mobilecssurl?: string; // Mobile custom CSS theme
  advancedfeatures?: core_webservice_get_site_info_returns_advancedfeatures[]; // Advanced features availability
  usercanmanageownfiles?: boolean; // true if the user can manage his own files
  userquota?: number; // user quota (bytes). 0 means user can ignore the quota
  usermaxuploadfilesize?: number; // user max upload file size (bytes). -1 means the user can ignore the upload file size
  userhomepage?: number; // the default home page for the user: 0 for the site home, 1 for dashboard
  userprivateaccesskey?: string; // Private user access key for fetching files.
  siteid?: number; // Site course ID
  sitecalendartype?: string; // Calendar type set in the site.
  usercalendartype?: string; // Calendar typed used by the user.
  userissiteadmin?: boolean; // Whether the user is a site admin or not.
  theme?: string; // Current theme for the user.
  limitconcurrentlogins?: number; // Number of concurrent sessions allowed
  usersessionscount?: number; // Number of active sessions for current user. Only returned when limitconcurrentlogins is used.
}

export interface core_block_get_course_blocks_parameters {
  courseid: number; // course id
  returncontents?: boolean; // default: bool(false) // Whether to return the block contents.
}

export interface core_block_get_course_blocks_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_block_get_course_blocks_returns_blocks_configs {
  name: string; // Name.
  value: string; // JSON encoded representation of the config value.
  type: string; // Type (instance or plugin).
}

export interface core_block_get_course_blocks_returns_blocks_contents_files {
  filename?: string; // File name.
  filepath?: string; // File path.
  filesize?: number; // File size.
  fileurl?: string; // Downloadable file url.
  timemodified?: number; // Time modified.
  mimetype?: string; // File mime type.
  isexternalfile?: boolean; // Whether is an external file.
  repositorytype?: string; // The repository type for external files.
}

export interface core_block_get_course_blocks_returns_blocks_contents {
  title: string; // Block title.
  content: string; // Block contents.
  contentformat: number; // content format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  footer: string; // Block footer.
  files: core_block_get_course_blocks_returns_blocks_contents_files[]; // Block files.
}

export interface core_block_get_course_blocks_returns_blocks {
  instanceid: number; // Block instance id.
  name: string; // Block name.
  region: string; // Block region.
  positionid: number; // Position id.
  collapsible: boolean; // Whether the block is collapsible.
  dockable: boolean; // Whether the block is dockable.
  weight?: number; // Used to order blocks within a region.
  visible?: boolean; // Whether the block is visible.
  contents?: core_block_get_course_blocks_returns_blocks_contents; // Block contents (if required).
  configs?: core_block_get_course_blocks_returns_blocks_configs[]; // Block instance and plugin configuration settings.
}

export interface core_block_get_course_blocks_returns {
  blocks: core_block_get_course_blocks_returns_blocks[]; // List of blocks in the course.
  warnings?: core_block_get_course_blocks_returns_warnings[]; // list of warnings
}

export interface core_block_get_dashboard_blocks_parameters {
  userid?: number; // default: int(0) // User id (optional), default is current user.
  returncontents?: boolean; // default: bool(false) // Whether to return the block contents.
  mypage?: string; // default: string(9) "__default" // What my page to return blocks of
}

export interface core_block_get_dashboard_blocks_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_block_get_dashboard_blocks_returns_blocks_configs {
  name: string; // Name.
  value: string; // JSON encoded representation of the config value.
  type: string; // Type (instance or plugin).
}

export interface core_block_get_dashboard_blocks_returns_blocks_contents_files {
  filename?: string; // File name.
  filepath?: string; // File path.
  filesize?: number; // File size.
  fileurl?: string; // Downloadable file url.
  timemodified?: number; // Time modified.
  mimetype?: string; // File mime type.
  isexternalfile?: boolean; // Whether is an external file.
  repositorytype?: string; // The repository type for external files.
}

export interface core_block_get_dashboard_blocks_returns_blocks_contents {
  title: string; // Block title.
  content: string; // Block contents.
  contentformat: number; // content format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  footer: string; // Block footer.
  files: core_block_get_dashboard_blocks_returns_blocks_contents_files[]; // Block files.
}

export interface core_block_get_dashboard_blocks_returns_blocks {
  instanceid: number; // Block instance id.
  name: string; // Block name.
  region: string; // Block region.
  positionid: number; // Position id.
  collapsible: boolean; // Whether the block is collapsible.
  dockable: boolean; // Whether the block is dockable.
  weight?: number; // Used to order blocks within a region.
  visible?: boolean; // Whether the block is visible.
  contents?: core_block_get_dashboard_blocks_returns_blocks_contents; // Block contents (if required).
  configs?: core_block_get_dashboard_blocks_returns_blocks_configs[]; // Block instance and plugin configuration settings.
}

export interface core_block_get_dashboard_blocks_returns {
  blocks: core_block_get_dashboard_blocks_returns_blocks[]; // List of blocks in the dashboard.
  warnings?: core_block_get_dashboard_blocks_returns_warnings[]; // list of warnings
}

export interface core_block_fetch_addable_blocks_parameters {
  pagecontextid: number; // The context ID of the page.
  pagetype: string; // The type of the page.
  pagelayout: string; // The layout of the page.
  subpage?: string; // default: string(0) "" // The subpage identifier
}

export interface core_block_fetch_addable_blocks_returns_item {
  name: string; // The name of the block.
  title: string; // The title of the block.
}

export interface core_filters_get_available_in_context_parameters_contexts {
  contextlevel: string; // The context level where the filters are: (coursecat, course, module)
  instanceid: number; // The instance id of item associated with the context.
}

export interface core_filters_get_available_in_context_parameters {
  contexts: core_filters_get_available_in_context_parameters_contexts[]; // The list of contexts to check.
}

export interface core_filters_get_available_in_context_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_filters_get_available_in_context_returns_filters {
  contextlevel: string; // The context level where the filters are: (coursecat, course, module).
  instanceid: number; // The instance id of item associated with the context.
  contextid: number; // The context id.
  filter: string; // Filter plugin name.
  localstate: number; // Filter state: 1 for on, -1 for off, 0 if inherit.
  inheritedstate: number; // 1 or 0 to use when localstate is set to inherit.
}

export interface core_filters_get_available_in_context_returns {
  filters: core_filters_get_available_in_context_returns_filters[]; // Available filters
  warnings?: core_filters_get_available_in_context_returns_warnings[]; // list of warnings
}

export interface core_customfield_delete_field_parameters {
  id: number; // Custom field ID to delete
}

type core_customfield_delete_field_returns = null;

export interface core_customfield_reload_template_parameters {
  component: string; // component
  area: string; // area
  itemid: number; // itemid
}

export interface core_customfield_reload_template_returns_categories_fields {
  name: string; // name
  shortname: string; // shortname
  type: string; // type
  id: number; // id
}

export interface core_customfield_reload_template_returns_categories {
  id: number; // id
  nameeditable: string; // inplace editable name
  addfieldmenu: string; // addfieldmenu
  fields?: core_customfield_reload_template_returns_categories_fields[];
}

export interface core_customfield_reload_template_returns {
  component: string; // component
  area: string; // area
  itemid: number; // itemid
  usescategories: boolean; // view has categories
  categories: core_customfield_reload_template_returns_categories[];
}

export interface core_customfield_create_category_parameters {
  component: string; // component
  area: string; // area
  itemid: number; // itemid
}

type core_customfield_create_category_returns = number;

export interface core_customfield_delete_category_parameters {
  id: number; // category ID to delete
}

type core_customfield_delete_category_returns = null;

export interface core_customfield_move_field_parameters {
  id: number; // Id of the field to move
  categoryid: number; // New parent category id
  beforeid?: number; // default: int(0) // Id of the field before which it needs to be moved
}

type core_customfield_move_field_returns = null;

export interface core_customfield_move_category_parameters {
  id: number; // Category ID to move
  beforeid?: number; // default: int(0) // Id of the category before which it needs to be moved
}

type core_customfield_move_category_returns = null;

export interface core_h5p_get_trusted_h5p_file_parameters {
  url: string; // H5P file url.
  frame?: number; // default: int(0) // The frame allow to show the bar options below the content
  export?: number; // default: int(0) // The export allow to download the package
  embed?: number; // default: int(0) // The embed allow to copy the code to your site
  copyright?: number; // default: int(0) // The copyright option
}

export interface core_h5p_get_trusted_h5p_file_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_h5p_get_trusted_h5p_file_returns_files {
  filename?: string; // File name.
  filepath?: string; // File path.
  filesize?: number; // File size.
  fileurl?: string; // Downloadable file url.
  timemodified?: number; // Time modified.
  mimetype?: string; // File mime type.
  isexternalfile?: boolean; // Whether is an external file.
  repositorytype?: string; // The repository type for external files.
}

export interface core_h5p_get_trusted_h5p_file_returns {
  files: core_h5p_get_trusted_h5p_file_returns_files[]; // H5P file trusted.
  warnings?: core_h5p_get_trusted_h5p_file_returns_warnings[]; // list of warnings
}

type core_table_get_dynamic_table_content_parameters_hiddencolumns = string;

type core_table_get_dynamic_table_content_parameters_filters_values = string;

export interface core_table_get_dynamic_table_content_parameters_filters {
  name: string; // Name of the filter
  jointype: number; // Type of join for filter values
  values: core_table_get_dynamic_table_content_parameters_filters_values[]; // The value to filter on
}

export interface core_table_get_dynamic_table_content_parameters_sortdata {
  sortby: string; // The name of a sortable column
  sortorder: string; // The direction that this column should be sorted by
}

export interface core_table_get_dynamic_table_content_parameters {
  component: string; // Component
  handler: string; // Handler
  uniqueid: string; // Unique ID for the container
  sortdata?: core_table_get_dynamic_table_content_parameters_sortdata[]; // The combined sort order of the table. Multiple fields can be specified.
  filters?: core_table_get_dynamic_table_content_parameters_filters[]; // The filters that will be applied in the request
  jointype: number; // Type of join to join all filters together
  firstinitial: string; // The first initial to sort filter on
  lastinitial: string; // The last initial to sort filter on
  pagenumber: number; // The page number
  pagesize: number; // The number of records per page
  hiddencolumns: core_table_get_dynamic_table_content_parameters_hiddencolumns[];
  resetpreferences: boolean; // Whether the table preferences should be reset
}

export interface core_table_get_dynamic_table_content_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_table_get_dynamic_table_content_returns {
  html: string; // The raw html of the requested table.
  warnings?: core_table_get_dynamic_table_content_returns_warnings[]; // list of warnings
}

export interface core_xapi_statement_post_parameters {
  component: string; // Component name
  requestjson: string; // json object with all the statements to post
}

type core_xapi_statement_post_returns_item = boolean;

type core_contentbank_delete_content_parameters_contentids = number;

export interface core_contentbank_delete_content_parameters {
  contentids: core_contentbank_delete_content_parameters_contentids[];
}

export interface core_contentbank_delete_content_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_contentbank_delete_content_returns {
  result: boolean; // The processing result
  warnings?: core_contentbank_delete_content_returns_warnings[]; // list of warnings
}

export interface core_contentbank_rename_content_parameters {
  contentid: number; // The content id to rename
  name: string; // The new name for the content
}

export interface core_contentbank_rename_content_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_contentbank_rename_content_returns {
  result: boolean; // The processing result
  warnings?: core_contentbank_rename_content_returns_warnings[]; // list of warnings
}

export interface core_contentbank_set_content_visibility_parameters {
  contentid: number; // The content id to rename
  visibility: number; // The new visibility for the content
}

export interface core_contentbank_set_content_visibility_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_contentbank_set_content_visibility_returns {
  result: boolean; // The processing result
  warnings?: core_contentbank_set_content_visibility_returns_warnings[]; // list of warnings
}

export interface core_create_userfeedback_action_record_parameters {
  action: string; // The action taken by user
  contextid: number; // The context id of the page the user is in
}

type core_create_userfeedback_action_record_returns = null;

export interface core_payment_get_available_gateways_parameters {
  component: string; // Component
  paymentarea: string; // Payment area in the component
  itemid: number; // An identifier for payment area in the component
}

export interface core_payment_get_available_gateways_returns_item {
  shortname: string; // Name of the plugin
  name: string; // Human readable name of the gateway
  description: string; // description of the gateway
  surcharge: number; // percentage of surcharge when using the gateway
  cost: string; // Cost in human-readable form (amount plus surcharge with currency sign)
}

export interface core_reportbuilder_filters_reset_parameters {
  reportid: number; // Report ID
  parameters?: string; // default: string(0) "" // JSON encoded report parameters
}

type core_reportbuilder_filters_reset_returns = boolean;

export interface core_reportbuilder_set_filters_parameters {
  reportid: number; // Report ID
  parameters?: string; // default: string(0) "" // JSON encoded report parameters
  values: string; // JSON encoded filter values
}

type core_reportbuilder_set_filters_returns = boolean;

export interface core_dynamic_tabs_get_content_parameters {
  tab: string; // Tab class
  jsondata: string; // Json-encoded data
}

export interface core_dynamic_tabs_get_content_returns {
  template: string; // Template name
  content: string; // JSON-encoded data for template
  javascript: string; // JavaScript fragment
}

export interface core_change_editmode_parameters {
  setmode: boolean; // Set edit mode to
  context: number; // Page context id
}

export interface core_change_editmode_returns {
  success: boolean; // The edit mode was changed
}

export interface core_reportbuilder_reports_delete_parameters {
  reportid: number; // Report ID
}

type core_reportbuilder_reports_delete_returns = boolean;

export interface core_reportbuilder_reports_get_parameters {
  reportid: number; // Report ID
  editmode?: boolean; // default: int(0) // Whether editing mode is enabled
}

export interface core_reportbuilder_reports_get_returns_cardview {
  form: string; // form
  helpicon: string; // helpicon
}

export interface core_reportbuilder_reports_get_returns_sorting_sortablecolumns_sorticon {
  key: string; // key
  component: string; // component
  title: string; // title
}

export interface core_reportbuilder_reports_get_returns_sorting_sortablecolumns {
  id: number; // id
  title: string; // title
  heading: string; // heading
  sortdirection: number; // sortdirection
  sortenabled: boolean; // sortenabled
  sortorder: number; // sortorder
  sorticon: core_reportbuilder_reports_get_returns_sorting_sortablecolumns_sorticon;
  movetitle: string; // movetitle
  sortenabledtitle: string; // sortenabledtitle
}

export interface core_reportbuilder_reports_get_returns_sorting {
  hassortablecolumns: boolean; // hassortablecolumns
  sortablecolumns: core_reportbuilder_reports_get_returns_sorting_sortablecolumns[]; // sortablecolumns
  helpicon: string; // helpicon
}

export interface core_reportbuilder_reports_get_returns_filters_activefilters {
  id: number; // id
  heading: string; // heading
  headingeditable: string; // headingeditable
  sortorder: number; // sortorder
  movetitle: string; // movetitle
  entityname: string; // entityname
}

export interface core_reportbuilder_reports_get_returns_filters_availablefilters_optiongroup_values {
  value: string; // value
  visiblename: string; // visiblename
}

export interface core_reportbuilder_reports_get_returns_filters_availablefilters_optiongroup {
  text: string; // text
  values: core_reportbuilder_reports_get_returns_filters_availablefilters_optiongroup_values[]; // values
}

export interface core_reportbuilder_reports_get_returns_filters_availablefilters {
  optiongroup: core_reportbuilder_reports_get_returns_filters_availablefilters_optiongroup;
}

export interface core_reportbuilder_reports_get_returns_filters {
  hasavailablefilters: boolean; // hasavailablefilters
  availablefilters: core_reportbuilder_reports_get_returns_filters_availablefilters[]; // availablefilters
  hasactivefilters: boolean; // hasactivefilters
  activefilters: core_reportbuilder_reports_get_returns_filters_activefilters[]; // activefilters
  helpicon: string; // helpicon
}

export interface core_reportbuilder_reports_get_returns_conditions_availableconditions_optiongroup_values {
  value: string; // value
  visiblename: string; // visiblename
}

export interface core_reportbuilder_reports_get_returns_conditions_availableconditions_optiongroup {
  text: string; // text
  values: core_reportbuilder_reports_get_returns_conditions_availableconditions_optiongroup_values[]; // values
}

export interface core_reportbuilder_reports_get_returns_conditions_availableconditions {
  optiongroup: core_reportbuilder_reports_get_returns_conditions_availableconditions_optiongroup;
}

export interface core_reportbuilder_reports_get_returns_conditions {
  hasavailableconditions: boolean; // hasavailableconditions
  availableconditions: core_reportbuilder_reports_get_returns_conditions_availableconditions[]; // availableconditions
  hasactiveconditions: boolean; // hasactiveconditions
  activeconditionsform: string; // activeconditionsform
  helpicon: string; // helpicon
  javascript?: string; // javascript
}

export interface core_reportbuilder_reports_get_returns_sidebarmenucards_menucards_items {
  name: string; // name
  identifier: string; // identifier
  title: string; // title
  action: string; // action
  disabled?: boolean; // disabled
}

export interface core_reportbuilder_reports_get_returns_sidebarmenucards_menucards {
  name: string; // name
  key: string; // key
  items?: core_reportbuilder_reports_get_returns_sidebarmenucards_menucards_items[]; // items
}

export interface core_reportbuilder_reports_get_returns_sidebarmenucards {
  menucards?: core_reportbuilder_reports_get_returns_sidebarmenucards_menucards[]; // menucards
}

export interface core_reportbuilder_reports_get_returns {
  name: string; // name
  source: string; // source
  type: number; // type
  uniquerows: boolean; // uniquerows
  conditiondata: string; // conditiondata
  settingsdata: string; // settingsdata
  contextid: number; // contextid
  component: string; // component
  area: string; // area
  itemid: number; // itemid
  usercreated: number; // usercreated
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  table: string; // table
  filtersapplied: number; // filtersapplied
  filterspresent: boolean; // filterspresent
  filtersform: string; // filtersform
  editmode: boolean; // editmode
  sidebarmenucards?: core_reportbuilder_reports_get_returns_sidebarmenucards;
  conditions?: core_reportbuilder_reports_get_returns_conditions;
  filters?: core_reportbuilder_reports_get_returns_filters;
  sorting?: core_reportbuilder_reports_get_returns_sorting;
  cardview?: core_reportbuilder_reports_get_returns_cardview;
  javascript: string; // javascript
}

export interface core_reportbuilder_list_reports_parameters {
  page?: number; // default: int(0) // Page number
  perpage?: number; // default: int(10) // Reports per page
}

export interface core_reportbuilder_list_reports_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_reportbuilder_list_reports_returns_reports_modifiedby {
  id: number; // id
  email: string; // email
  idnumber: string; // idnumber
  phone1: string; // phone1
  phone2: string; // phone2
  department: string; // department
  institution: string; // institution
  fullname: string; // fullname
  identity: string; // identity
  profileurl: string; // profileurl
  profileimageurl: string; // profileimageurl
  profileimageurlsmall: string; // profileimageurlsmall
}

export interface core_reportbuilder_list_reports_returns_reports {
  name: string; // name
  source: string; // source
  type: number; // type
  uniquerows: boolean; // uniquerows
  conditiondata: string; // conditiondata
  settingsdata: string; // settingsdata
  contextid: number; // contextid
  component: string; // component
  area: string; // area
  itemid: number; // itemid
  usercreated: number; // usercreated
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  sourcename: string; // sourcename
  modifiedby: core_reportbuilder_list_reports_returns_reports_modifiedby;
}

export interface core_reportbuilder_list_reports_returns {
  reports: core_reportbuilder_list_reports_returns_reports[];
  warnings?: core_reportbuilder_list_reports_returns_warnings[]; // list of warnings
}

export interface core_reportbuilder_retrieve_report_parameters {
  reportid: number; // Report ID
  page?: number; // default: int(0) // Page number
  perpage?: number; // default: int(10) // Reports per page
}

export interface core_reportbuilder_retrieve_report_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

type core_reportbuilder_retrieve_report_returns_data_rows_columns = string;

export interface core_reportbuilder_retrieve_report_returns_data_rows {
  columns: core_reportbuilder_retrieve_report_returns_data_rows_columns[]; // columns
}

type core_reportbuilder_retrieve_report_returns_data_headers = string;

export interface core_reportbuilder_retrieve_report_returns_data {
  headers: core_reportbuilder_retrieve_report_returns_data_headers[]; // headers
  rows: core_reportbuilder_retrieve_report_returns_data_rows[]; // rows
  totalrowcount: number; // totalrowcount
}

export interface core_reportbuilder_retrieve_report_returns_details_modifiedby {
  id: number; // id
  email: string; // email
  idnumber: string; // idnumber
  phone1: string; // phone1
  phone2: string; // phone2
  department: string; // department
  institution: string; // institution
  fullname: string; // fullname
  identity: string; // identity
  profileurl: string; // profileurl
  profileimageurl: string; // profileimageurl
  profileimageurlsmall: string; // profileimageurlsmall
}

export interface core_reportbuilder_retrieve_report_returns_details {
  name: string; // name
  source: string; // source
  type: number; // type
  uniquerows: boolean; // uniquerows
  conditiondata: string; // conditiondata
  settingsdata: string; // settingsdata
  contextid: number; // contextid
  component: string; // component
  area: string; // area
  itemid: number; // itemid
  usercreated: number; // usercreated
  id: number; // id
  timecreated: number; // timecreated
  timemodified: number; // timemodified
  usermodified: number; // usermodified
  sourcename: string; // sourcename
  modifiedby: core_reportbuilder_retrieve_report_returns_details_modifiedby;
}

export interface core_reportbuilder_retrieve_report_returns {
  details: core_reportbuilder_retrieve_report_returns_details;
  data: core_reportbuilder_retrieve_report_returns_data;
  warnings?: core_reportbuilder_retrieve_report_returns_warnings[]; // list of warnings
}

export interface core_reportbuilder_view_report_parameters {
  reportid: number; // Report ID
}

export interface core_reportbuilder_view_report_returns_warnings {
  item?: string; // item
  itemid?: number; // item id
  warningcode: string; // the warning code can be used by the client app to implement specific behaviour
  message: string; // untranslated english message to explain the warning
}

export interface core_reportbuilder_view_report_returns {
  status: boolean; // Success
  warnings?: core_reportbuilder_view_report_returns_warnings[]; // list of warnings
}

export interface core_reportbuilder_columns_add_parameters {
  reportid: number; // Report ID
  uniqueidentifier: string; // Unique identifier of the column
}

export interface core_reportbuilder_columns_add_returns_sortablecolumns_sorticon {
  key: string; // key
  component: string; // component
  title: string; // title
}

export interface core_reportbuilder_columns_add_returns_sortablecolumns {
  id: number; // id
  title: string; // title
  heading: string; // heading
  sortdirection: number; // sortdirection
  sortenabled: boolean; // sortenabled
  sortorder: number; // sortorder
  sorticon: core_reportbuilder_columns_add_returns_sortablecolumns_sorticon;
  movetitle: string; // movetitle
  sortenabledtitle: string; // sortenabledtitle
}

export interface core_reportbuilder_columns_add_returns {
  hassortablecolumns: boolean; // hassortablecolumns
  sortablecolumns: core_reportbuilder_columns_add_returns_sortablecolumns[]; // sortablecolumns
  helpicon: string; // helpicon
}

export interface core_reportbuilder_columns_delete_parameters {
  reportid: number; // Report ID
  columnid: number; // Column ID
}

export interface core_reportbuilder_columns_delete_returns_sortablecolumns_sorticon {
  key: string; // key
  component: string; // component
  title: string; // title
}

export interface core_reportbuilder_columns_delete_returns_sortablecolumns {
  id: number; // id
  title: string; // title
  heading: string; // heading
  sortdirection: number; // sortdirection
  sortenabled: boolean; // sortenabled
  sortorder: number; // sortorder
  sorticon: core_reportbuilder_columns_delete_returns_sortablecolumns_sorticon;
  movetitle: string; // movetitle
  sortenabledtitle: string; // sortenabledtitle
}

export interface core_reportbuilder_columns_delete_returns {
  hassortablecolumns: boolean; // hassortablecolumns
  sortablecolumns: core_reportbuilder_columns_delete_returns_sortablecolumns[]; // sortablecolumns
  helpicon: string; // helpicon
}

export interface core_reportbuilder_columns_reorder_parameters {
  reportid: number; // Report ID
  columnid: number; // Column ID
  position: number; // New column position
}

type core_reportbuilder_columns_reorder_returns = boolean;

export interface core_reportbuilder_columns_sort_get_parameters {
  reportid: number; // Report ID
}

export interface core_reportbuilder_columns_sort_get_returns_sortablecolumns_sorticon {
  key: string; // key
  component: string; // component
  title: string; // title
}

export interface core_reportbuilder_columns_sort_get_returns_sortablecolumns {
  id: number; // id
  title: string; // title
  heading: string; // heading
  sortdirection: number; // sortdirection
  sortenabled: boolean; // sortenabled
  sortorder: number; // sortorder
  sorticon: core_reportbuilder_columns_sort_get_returns_sortablecolumns_sorticon;
  movetitle: string; // movetitle
  sortenabledtitle: string; // sortenabledtitle
}

export interface core_reportbuilder_columns_sort_get_returns {
  hassortablecolumns: boolean; // hassortablecolumns
  sortablecolumns: core_reportbuilder_columns_sort_get_returns_sortablecolumns[]; // sortablecolumns
  helpicon: string; // helpicon
}

export interface core_reportbuilder_columns_sort_reorder_parameters {
  reportid: number; // Report ID
  columnid: number; // Column ID
  position: number; // New column sort position
}

export interface core_reportbuilder_columns_sort_reorder_returns_sortablecolumns_sorticon {
  key: string; // key
  component: string; // component
  title: string; // title
}

export interface core_reportbuilder_columns_sort_reorder_returns_sortablecolumns {
  id: number; // id
  title: string; // title
  heading: string; // heading
  sortdirection: number; // sortdirection
  sortenabled: boolean; // sortenabled
  sortorder: number; // sortorder
  sorticon: core_reportbuilder_columns_sort_reorder_returns_sortablecolumns_sorticon;
  movetitle: string; // movetitle
  sortenabledtitle: string; // sortenabledtitle
}

export interface core_reportbuilder_columns_sort_reorder_returns {
  hassortablecolumns: boolean; // hassortablecolumns
  sortablecolumns: core_reportbuilder_columns_sort_reorder_returns_sortablecolumns[]; // sortablecolumns
  helpicon: string; // helpicon
}

export interface core_reportbuilder_columns_sort_toggle_parameters {
  reportid: number; // Report ID
  columnid: number; // Column ID
  enabled: boolean; // Sort enabled
  direction?: number; // default: int(4) // Sort direction
}

export interface core_reportbuilder_columns_sort_toggle_returns_sortablecolumns_sorticon {
  key: string; // key
  component: string; // component
  title: string; // title
}

export interface core_reportbuilder_columns_sort_toggle_returns_sortablecolumns {
  id: number; // id
  title: string; // title
  heading: string; // heading
  sortdirection: number; // sortdirection
  sortenabled: boolean; // sortenabled
  sortorder: number; // sortorder
  sorticon: core_reportbuilder_columns_sort_toggle_returns_sortablecolumns_sorticon;
  movetitle: string; // movetitle
  sortenabledtitle: string; // sortenabledtitle
}

export interface core_reportbuilder_columns_sort_toggle_returns {
  hassortablecolumns: boolean; // hassortablecolumns
  sortablecolumns: core_reportbuilder_columns_sort_toggle_returns_sortablecolumns[]; // sortablecolumns
  helpicon: string; // helpicon
}

export interface core_reportbuilder_conditions_add_parameters {
  reportid: number; // Report ID
  uniqueidentifier: string; // Unique identifier of the condition
}

export interface core_reportbuilder_conditions_add_returns_availableconditions_optiongroup_values {
  value: string; // value
  visiblename: string; // visiblename
}

export interface core_reportbuilder_conditions_add_returns_availableconditions_optiongroup {
  text: string; // text
  values: core_reportbuilder_conditions_add_returns_availableconditions_optiongroup_values[]; // values
}

export interface core_reportbuilder_conditions_add_returns_availableconditions {
  optiongroup: core_reportbuilder_conditions_add_returns_availableconditions_optiongroup;
}

export interface core_reportbuilder_conditions_add_returns {
  hasavailableconditions: boolean; // hasavailableconditions
  availableconditions: core_reportbuilder_conditions_add_returns_availableconditions[]; // availableconditions
  hasactiveconditions: boolean; // hasactiveconditions
  activeconditionsform: string; // activeconditionsform
  helpicon: string; // helpicon
  javascript?: string; // javascript
}

export interface core_reportbuilder_conditions_delete_parameters {
  reportid: number; // Report ID
  conditionid: number; // Condition ID
}

export interface core_reportbuilder_conditions_delete_returns_availableconditions_optiongroup_values {
  value: string; // value
  visiblename: string; // visiblename
}

export interface core_reportbuilder_conditions_delete_returns_availableconditions_optiongroup {
  text: string; // text
  values: core_reportbuilder_conditions_delete_returns_availableconditions_optiongroup_values[]; // values
}

export interface core_reportbuilder_conditions_delete_returns_availableconditions {
  optiongroup: core_reportbuilder_conditions_delete_returns_availableconditions_optiongroup;
}

export interface core_reportbuilder_conditions_delete_returns {
  hasavailableconditions: boolean; // hasavailableconditions
  availableconditions: core_reportbuilder_conditions_delete_returns_availableconditions[]; // availableconditions
  hasactiveconditions: boolean; // hasactiveconditions
  activeconditionsform: string; // activeconditionsform
  helpicon: string; // helpicon
  javascript?: string; // javascript
}

export interface core_reportbuilder_conditions_reorder_parameters {
  reportid: number; // Report ID
  conditionid: number; // Condition ID
  position: number; // New condition position
}

export interface core_reportbuilder_conditions_reorder_returns_availableconditions_optiongroup_values {
  value: string; // value
  visiblename: string; // visiblename
}

export interface core_reportbuilder_conditions_reorder_returns_availableconditions_optiongroup {
  text: string; // text
  values: core_reportbuilder_conditions_reorder_returns_availableconditions_optiongroup_values[]; // values
}

export interface core_reportbuilder_conditions_reorder_returns_availableconditions {
  optiongroup: core_reportbuilder_conditions_reorder_returns_availableconditions_optiongroup;
}

export interface core_reportbuilder_conditions_reorder_returns {
  hasavailableconditions: boolean; // hasavailableconditions
  availableconditions: core_reportbuilder_conditions_reorder_returns_availableconditions[]; // availableconditions
  hasactiveconditions: boolean; // hasactiveconditions
  activeconditionsform: string; // activeconditionsform
  helpicon: string; // helpicon
  javascript?: string; // javascript
}

export interface core_reportbuilder_conditions_reset_parameters {
  reportid: number; // Report ID
}

export interface core_reportbuilder_conditions_reset_returns_availableconditions_optiongroup_values {
  value: string; // value
  visiblename: string; // visiblename
}

export interface core_reportbuilder_conditions_reset_returns_availableconditions_optiongroup {
  text: string; // text
  values: core_reportbuilder_conditions_reset_returns_availableconditions_optiongroup_values[]; // values
}

export interface core_reportbuilder_conditions_reset_returns_availableconditions {
  optiongroup: core_reportbuilder_conditions_reset_returns_availableconditions_optiongroup;
}

export interface core_reportbuilder_conditions_reset_returns {
  hasavailableconditions: boolean; // hasavailableconditions
  availableconditions: core_reportbuilder_conditions_reset_returns_availableconditions[]; // availableconditions
  hasactiveconditions: boolean; // hasactiveconditions
  activeconditionsform: string; // activeconditionsform
  helpicon: string; // helpicon
  javascript?: string; // javascript
}

export interface core_reportbuilder_filters_add_parameters {
  reportid: number; // Report ID
  uniqueidentifier: string; // Unique identifier of the filter
}

export interface core_reportbuilder_filters_add_returns_activefilters {
  id: number; // id
  heading: string; // heading
  headingeditable: string; // headingeditable
  sortorder: number; // sortorder
  movetitle: string; // movetitle
  entityname: string; // entityname
}

export interface core_reportbuilder_filters_add_returns_availablefilters_optiongroup_values {
  value: string; // value
  visiblename: string; // visiblename
}

export interface core_reportbuilder_filters_add_returns_availablefilters_optiongroup {
  text: string; // text
  values: core_reportbuilder_filters_add_returns_availablefilters_optiongroup_values[]; // values
}

export interface core_reportbuilder_filters_add_returns_availablefilters {
  optiongroup: core_reportbuilder_filters_add_returns_availablefilters_optiongroup;
}

export interface core_reportbuilder_filters_add_returns {
  hasavailablefilters: boolean; // hasavailablefilters
  availablefilters: core_reportbuilder_filters_add_returns_availablefilters[]; // availablefilters
  hasactivefilters: boolean; // hasactivefilters
  activefilters: core_reportbuilder_filters_add_returns_activefilters[]; // activefilters
  helpicon: string; // helpicon
}

export interface core_reportbuilder_filters_delete_parameters {
  reportid: number; // Report ID
  filterid: number; // Filter ID
}

export interface core_reportbuilder_filters_delete_returns_activefilters {
  id: number; // id
  heading: string; // heading
  headingeditable: string; // headingeditable
  sortorder: number; // sortorder
  movetitle: string; // movetitle
  entityname: string; // entityname
}

export interface core_reportbuilder_filters_delete_returns_availablefilters_optiongroup_values {
  value: string; // value
  visiblename: string; // visiblename
}

export interface core_reportbuilder_filters_delete_returns_availablefilters_optiongroup {
  text: string; // text
  values: core_reportbuilder_filters_delete_returns_availablefilters_optiongroup_values[]; // values
}

export interface core_reportbuilder_filters_delete_returns_availablefilters {
  optiongroup: core_reportbuilder_filters_delete_returns_availablefilters_optiongroup;
}

export interface core_reportbuilder_filters_delete_returns {
  hasavailablefilters: boolean; // hasavailablefilters
  availablefilters: core_reportbuilder_filters_delete_returns_availablefilters[]; // availablefilters
  hasactivefilters: boolean; // hasactivefilters
  activefilters: core_reportbuilder_filters_delete_returns_activefilters[]; // activefilters
  helpicon: string; // helpicon
}

export interface core_reportbuilder_filters_reorder_parameters {
  reportid: number; // Report ID
  filterid: number; // Filter ID
  position: number; // New filter position
}

export interface core_reportbuilder_filters_reorder_returns_activefilters {
  id: number; // id
  heading: string; // heading
  headingeditable: string; // headingeditable
  sortorder: number; // sortorder
  movetitle: string; // movetitle
  entityname: string; // entityname
}

export interface core_reportbuilder_filters_reorder_returns_availablefilters_optiongroup_values {
  value: string; // value
  visiblename: string; // visiblename
}

export interface core_reportbuilder_filters_reorder_returns_availablefilters_optiongroup {
  text: string; // text
  values: core_reportbuilder_filters_reorder_returns_availablefilters_optiongroup_values[]; // values
}

export interface core_reportbuilder_filters_reorder_returns_availablefilters {
  optiongroup: core_reportbuilder_filters_reorder_returns_availablefilters_optiongroup;
}

export interface core_reportbuilder_filters_reorder_returns {
  hasavailablefilters: boolean; // hasavailablefilters
  availablefilters: core_reportbuilder_filters_reorder_returns_availablefilters[]; // availablefilters
  hasactivefilters: boolean; // hasactivefilters
  activefilters: core_reportbuilder_filters_reorder_returns_activefilters[]; // activefilters
  helpicon: string; // helpicon
}

export interface core_reportbuilder_audiences_delete_parameters {
  reportid: number; // Report id
  instanceid: number; // Audience instance id
}

type core_reportbuilder_audiences_delete_returns = boolean;

export interface core_reportbuilder_schedules_delete_parameters {
  reportid: number; // Report ID
  scheduleid: number; // Schedule ID
}

type core_reportbuilder_schedules_delete_returns = boolean;

export interface core_reportbuilder_schedules_send_parameters {
  reportid: number; // Report ID
  scheduleid: number; // Schedule ID
}

type core_reportbuilder_schedules_send_returns = boolean;

export interface core_reportbuilder_schedules_toggle_parameters {
  reportid: number; // Report ID
  scheduleid: number; // Schedule ID
  enabled: boolean; // Schedule enabled
}

type core_reportbuilder_schedules_toggle_returns = boolean;

export interface message_popup_get_popup_notifications_parameters {
  useridto: number; // the user id who received the message, 0 for current user
  newestfirst?: boolean; // default: bool(true) // true for ordering by newest first, false for oldest first
  limit?: number; // default: int(0) // the number of results to return
  offset?: number; // default: int(0) // offset the result set by a given amount
}

export interface message_popup_get_popup_notifications_returns_notifications {
  id: number; // Notification id (this is not guaranteed to be unique within this result set)
  useridfrom: number; // User from id
  useridto: number; // User to id
  subject: string; // The notification subject
  shortenedsubject: string; // The notification subject shortened with ellipsis
  text: string; // The message text formated
  fullmessage: string; // The message
  fullmessageformat: number; // fullmessage format (1 = HTML, 0 = MOODLE, 2 = PLAIN or 4 = MARKDOWN)
  fullmessagehtml: string; // The message in html
  smallmessage: string; // The shorten message
  contexturl: string; // Context URL
  contexturlname: string; // Context URL link name
  timecreated: number; // Time created
  timecreatedpretty: string; // Time created in a pretty format
  timeread: number; // Time read
  read: boolean; // notification read status
  deleted: boolean; // notification deletion status
  iconurl: string; // URL for notification icon
  component?: string; // The component that generated the notification
  eventtype?: string; // The type of notification
  customdata?: string; // Custom data to be passed to the message processor. The data here is serialised using json_encode().
}

export interface message_popup_get_popup_notifications_returns {
  notifications: message_popup_get_popup_notifications_returns_notifications[];
  unreadcount: number; // the number of unread message for the given user
}

export interface message_popup_get_unread_popup_notification_count_parameters {
  useridto: number; // the user id who received the message, 0 for any user
}

type message_popup_get_unread_popup_notification_count_returns = number;

// Idea from: https://www.typescriptlang.org/docs/handbook/mixins.html
type GConstructor<T = {}> = new (...args: any[]) => T;
type WebserviceBase = GConstructor<{ callWebservice<T = any>(wsfunction: string, payload: any): Promise<T> }>;

export default function WebserviceDefinitions<TBase extends WebserviceBase>(Base: TBase) {
  return class Extended extends Base {
    /**
     * set the item status
     */
    public diggrplus_set_item_status = async <T extends diggrplus_set_item_status_parameters>(params: NoExtraProperties<diggrplus_set_item_status_parameters, T>): Promise<diggrplus_set_item_status_returns> =>
      this.callWebservice('diggrplus_set_item_status', params);
  
    /**
     * Get courses with exacomp block instances. get courses
     */
    public block_exacomp_get_courses = async <T extends block_exacomp_get_courses_parameters>(params: NoExtraProperties<block_exacomp_get_courses_parameters, T>): Promise<block_exacomp_get_courses_returns_item[]> =>
      this.callWebservice('block_exacomp_get_courses', params);
  
    /**
     * Get examples for subtopic Get examples
     */
    public block_exacomp_get_examples_for_subject = async <T extends block_exacomp_get_examples_for_subject_parameters>(params: NoExtraProperties<block_exacomp_get_examples_for_subject_parameters, T>): Promise<block_exacomp_get_examples_for_subject_returns_item[]> =>
      this.callWebservice('block_exacomp_get_examples_for_subject', params);
  
    /**
     * Get examples for subtopic Get examples
     */
    public block_exacomp_get_examples_for_subject_with_lfs_infos = async <T extends block_exacomp_get_examples_for_subject_with_lfs_infos_parameters>(params: NoExtraProperties<block_exacomp_get_examples_for_subject_with_lfs_infos_parameters, T>): Promise<block_exacomp_get_examples_for_subject_with_lfs_infos_returns_item[]> =>
      this.callWebservice('block_exacomp_get_examples_for_subject_with_lfs_infos', params);
  
    /**
     * Get example Get example
     */
    public block_exacomp_get_example_by_id = async <T extends block_exacomp_get_example_by_id_parameters>(params: NoExtraProperties<block_exacomp_get_example_by_id_parameters, T>): Promise<block_exacomp_get_example_by_id_returns> =>
      this.callWebservice('block_exacomp_get_example_by_id', params);
  
    /**
     * Get desciptors for example Get descriptors for example
     */
    public block_exacomp_get_descriptors_for_example = async <T extends block_exacomp_get_descriptors_for_example_parameters>(params: NoExtraProperties<block_exacomp_get_descriptors_for_example_parameters, T>): Promise<block_exacomp_get_descriptors_for_example_returns_item[]> =>
      this.callWebservice('block_exacomp_get_descriptors_for_example', params);
  
    /**
     * Get desciptors for quiz Get descriptors for quiz
     */
    public block_exacomp_get_descriptors_for_quiz = async <T extends block_exacomp_get_descriptors_for_quiz_parameters>(params: NoExtraProperties<block_exacomp_get_descriptors_for_quiz_parameters, T>): Promise<block_exacomp_get_descriptors_for_quiz_returns_item[]> =>
      this.callWebservice('block_exacomp_get_descriptors_for_quiz', params);
  
    /**
     * Get role for user: 1=trainer 2=student
     */
    public block_exacomp_get_user_role = async <T extends block_exacomp_get_user_role_parameters>(params?: NoExtraProperties<block_exacomp_get_user_role_parameters, T>): Promise<block_exacomp_get_user_role_returns> =>
      this.callWebservice('block_exacomp_get_user_role', params);
  
    /**
     * Get role for user: 1=trainer 2=student
     */
    public block_exacomp_diggr_get_user_role = async <T extends block_exacomp_diggr_get_user_role_parameters>(params?: NoExtraProperties<block_exacomp_diggr_get_user_role_parameters, T>): Promise<block_exacomp_diggr_get_user_role_returns> =>
      this.callWebservice('block_exacomp_diggr_get_user_role', params);
  
    /**
     * Get external trainer's students Get all students for an external trainer
     */
    public block_exacomp_get_external_trainer_students = async <T extends block_exacomp_get_external_trainer_students_parameters>(params?: NoExtraProperties<block_exacomp_get_external_trainer_students_parameters, T>): Promise<block_exacomp_get_external_trainer_students_returns_item[]> =>
      this.callWebservice('block_exacomp_get_external_trainer_students', params);
  
    /**
     * Get Subjects get subjects from one user for all his courses
     */
    public block_exacomp_get_subjects_for_user = async <T extends block_exacomp_get_subjects_for_user_parameters>(params: NoExtraProperties<block_exacomp_get_subjects_for_user_parameters, T>): Promise<block_exacomp_get_subjects_for_user_returns_item[]> =>
      this.callWebservice('block_exacomp_get_subjects_for_user', params);
  
    /**
     * Get Subjects get subjects from one user for all his courses or for one specific course.
     */
    public diggrplus_get_subjects_and_topics_for_user = async <T extends diggrplus_get_subjects_and_topics_for_user_parameters>(params: NoExtraProperties<diggrplus_get_subjects_and_topics_for_user_parameters, T>): Promise<diggrplus_get_subjects_and_topics_for_user_returns_item[]> =>
      this.callWebservice('diggrplus_get_subjects_and_topics_for_user', params);
  
    /**
     * Get Subjects get subjects from one user for all his courses
     */
    public diggrplus_get_niveaus_for_subject = async <T extends diggrplus_get_niveaus_for_subject_parameters>(params: NoExtraProperties<diggrplus_get_niveaus_for_subject_parameters, T>): Promise<diggrplus_get_niveaus_for_subject_returns_item[]> =>
      this.callWebservice('diggrplus_get_niveaus_for_subject', params);
  
    /**
     * delete a submitted and wrong item Deletes one user item if it is not graded already
     */
    public block_exacomp_delete_item = async <T extends block_exacomp_delete_item_parameters>(params: NoExtraProperties<block_exacomp_delete_item_parameters, T>): Promise<block_exacomp_delete_item_returns> =>
      this.callWebservice('block_exacomp_delete_item', params);
  
    /**
     * Set a student evaluation for a particular competence Set student evaluation
     */
    public block_exacomp_set_competence = async <T extends block_exacomp_set_competence_parameters>(params: NoExtraProperties<block_exacomp_set_competence_parameters, T>): Promise<block_exacomp_set_competence_returns> =>
      this.callWebservice('block_exacomp_set_competence', params);
  
    /**
     * Get Item get subjects from one user for all his courses
     */
    public block_exacomp_get_item_for_example = async <T extends block_exacomp_get_item_for_example_parameters>(params: NoExtraProperties<block_exacomp_get_item_for_example_parameters, T>): Promise<block_exacomp_get_item_for_example_returns> =>
      this.callWebservice('block_exacomp_get_item_for_example', params);
  
    /**
     * Get competencetree Get all available competencies
     */
    public block_exacomp_get_competencies_for_upload = async <T extends block_exacomp_get_competencies_for_upload_parameters>(params: NoExtraProperties<block_exacomp_get_competencies_for_upload_parameters, T>): Promise<block_exacomp_get_competencies_for_upload_returns_item[]> =>
      this.callWebservice('block_exacomp_get_competencies_for_upload', params);
  
    /**
     * Submit example submit example for elove and diggr Add item
     */
    public block_exacomp_submit_example = async <T extends block_exacomp_submit_example_parameters>(params: NoExtraProperties<block_exacomp_submit_example_parameters, T>): Promise<block_exacomp_submit_example_returns> =>
      this.callWebservice('block_exacomp_submit_example', params);
  
    /**
     * Create an example or update it create example
     */
    public block_exacomp_create_or_update_example = async <T extends block_exacomp_create_or_update_example_parameters>(params: NoExtraProperties<block_exacomp_create_or_update_example_parameters, T>): Promise<block_exacomp_create_or_update_example_returns> =>
      this.callWebservice('block_exacomp_create_or_update_example', params);
  
    /**
     * Create an example or update it create example
     */
    public diggrplus_create_or_update_example = async <T extends diggrplus_create_or_update_example_parameters>(params: NoExtraProperties<diggrplus_create_or_update_example_parameters, T>): Promise<diggrplus_create_or_update_example_returns> =>
      this.callWebservice('diggrplus_create_or_update_example', params);
  
    /**
     * Grade a descriptor
     */
    public diggrplus_grade_descriptor = async <T extends diggrplus_grade_descriptor_parameters>(params: NoExtraProperties<diggrplus_grade_descriptor_parameters, T>): Promise<diggrplus_grade_descriptor_returns> =>
      this.callWebservice('diggrplus_grade_descriptor', params);
  
    /**
     * Grade a element
     */
    public diggrplus_grade_element = async <T extends diggrplus_grade_element_parameters>(params: NoExtraProperties<diggrplus_grade_element_parameters, T>): Promise<diggrplus_grade_element_returns> =>
      this.callWebservice('diggrplus_grade_element', params);
  
    /**
     * Grade a element
     */
    public diggrplus_grade_competency = async <T extends diggrplus_grade_competency_parameters>(params: NoExtraProperties<diggrplus_grade_competency_parameters, T>): Promise<diggrplus_grade_competency_returns> =>
      this.callWebservice('diggrplus_grade_competency', params);
  
    /**
     * Get all gradings in all courses
     */
    public diggrplus_get_all_competency_gradings = async <T extends diggrplus_get_all_competency_gradings_parameters>(params: NoExtraProperties<diggrplus_get_all_competency_gradings_parameters, T>): Promise<diggrplus_get_all_competency_gradings_returns_item[]> =>
      this.callWebservice('diggrplus_get_all_competency_gradings', params);
  
    /**
     * 
     */
    public diggrplus_msteams_import_students = async <T extends diggrplus_msteams_import_students_parameters>(params: NoExtraProperties<diggrplus_msteams_import_students_parameters, T>): Promise<diggrplus_msteams_import_students_returns> =>
      this.callWebservice('diggrplus_msteams_import_students', params);
  
    /**
     * 
     */
    public diggrplus_msteams_get_access_token = async <T extends diggrplus_msteams_get_access_token_parameters>(params: NoExtraProperties<diggrplus_msteams_get_access_token_parameters, T>): Promise<diggrplus_msteams_get_access_token_returns> =>
      this.callWebservice('diggrplus_msteams_get_access_token', params);
  
    /**
     * Grade an item grade an item
     */
    public block_exacomp_grade_item = async <T extends block_exacomp_grade_item_parameters>(params: NoExtraProperties<block_exacomp_grade_item_parameters, T>): Promise<block_exacomp_grade_item_returns> =>
      this.callWebservice('block_exacomp_grade_item', params);
  
    /**
     * get examples created by a specific user grade an item
     */
    public block_exacomp_get_user_examples = async <T extends block_exacomp_get_user_examples_parameters>(params?: NoExtraProperties<block_exacomp_get_user_examples_parameters, T>): Promise<block_exacomp_get_user_examples_returns_item[]> =>
      this.callWebservice('block_exacomp_get_user_examples', params);
  
    /**
     * get a list of courses with their competencies
     */
    public block_exacomp_get_user_profile = async <T extends block_exacomp_get_user_profile_parameters>(params: NoExtraProperties<block_exacomp_get_user_profile_parameters, T>): Promise<block_exacomp_get_user_profile_returns> =>
      this.callWebservice('block_exacomp_get_user_profile', params);
  
    /**
     * update an example
     */
    public block_exacomp_update_example = async <T extends block_exacomp_update_example_parameters>(params: NoExtraProperties<block_exacomp_update_example_parameters, T>): Promise<block_exacomp_update_example_returns> =>
      this.callWebservice('block_exacomp_update_example', params);
  
    /**
     * delete a custom item delete example
     */
    public block_exacomp_delete_example = async <T extends block_exacomp_delete_example_parameters>(params: NoExtraProperties<block_exacomp_delete_example_parameters, T>): Promise<block_exacomp_delete_example_returns> =>
      this.callWebservice('block_exacomp_delete_example', params);
  
    /**
     * get competencies for a specific topic Get all available competencies
     */
    public block_exacomp_get_competencies_by_topic = async <T extends block_exacomp_get_competencies_by_topic_parameters>(params: NoExtraProperties<block_exacomp_get_competencies_by_topic_parameters, T>): Promise<block_exacomp_get_competencies_by_topic_returns_item[]> =>
      this.callWebservice('block_exacomp_get_competencies_by_topic', params);
  
    /**
     * set competence for student Set a competence for a user
     */
    public dakora_set_competence = async <T extends dakora_set_competence_parameters>(params: NoExtraProperties<dakora_set_competence_parameters, T>): Promise<dakora_set_competence_returns> =>
      this.callWebservice('dakora_set_competence', params);
  
    /**
     * get courses for user for dakora app get courses
     */
    public dakora_get_courses = async <T extends dakora_get_courses_parameters>(params: NoExtraProperties<dakora_get_courses_parameters, T>): Promise<dakora_get_courses_returns_item[]> =>
      this.callWebservice('dakora_get_courses', params);
  
    /**
     * get topics for course for dakora app associated with examples get courses
     */
    public dakora_get_topics_by_course = async <T extends dakora_get_topics_by_course_parameters>(params: NoExtraProperties<dakora_get_topics_by_course_parameters, T>): Promise<dakora_get_topics_by_course_returns_item[]> =>
      this.callWebservice('dakora_get_topics_by_course', params);
  
    /**
     * get topics for course for dakora app get courses
     */
    public dakora_get_all_topics_by_course = async <T extends dakora_get_all_topics_by_course_parameters>(params: NoExtraProperties<dakora_get_all_topics_by_course_parameters, T>): Promise<dakora_get_all_topics_by_course_returns> =>
      this.callWebservice('dakora_get_all_topics_by_course', params);
  
    /**
     * get descriptors for topic for dakora app associated with examples get descriptors for one topic, considering the visibility
     */
    public dakora_get_descriptors = async <T extends dakora_get_descriptors_parameters>(params: NoExtraProperties<dakora_get_descriptors_parameters, T>): Promise<dakora_get_descriptors_returns_item[]> =>
      this.callWebservice('dakora_get_descriptors', params);
  
    /**
     * get descriptors for topic for dakora app get courses
     */
    public dakora_get_all_descriptors = async <T extends dakora_get_all_descriptors_parameters>(params: NoExtraProperties<dakora_get_all_descriptors_parameters, T>): Promise<dakora_get_all_descriptors_returns_item[]> =>
      this.callWebservice('dakora_get_all_descriptors', params);
  
    /**
     * get children (childdescriptor and examples) for descriptor for dakora app (only childs associated with examples) get courses
     */
    public dakora_get_descriptor_children = async <T extends dakora_get_descriptor_children_parameters>(params: NoExtraProperties<dakora_get_descriptor_children_parameters, T>): Promise<dakora_get_descriptor_children_returns> =>
      this.callWebservice('dakora_get_descriptor_children', params);
  
    /**
     * get examples for descriptor for dakora app
     */
    public dakora_get_examples_for_descriptor = async <T extends dakora_get_examples_for_descriptor_parameters>(params: NoExtraProperties<dakora_get_examples_for_descriptor_parameters, T>): Promise<dakora_get_examples_for_descriptor_returns_item[]> =>
      this.callWebservice('dakora_get_examples_for_descriptor', params);
  
    /**
     * get examples for descriptor with additional grading information
     */
    public dakora_get_examples_for_descriptor_with_grading = async <T extends dakora_get_examples_for_descriptor_with_grading_parameters>(params: NoExtraProperties<dakora_get_examples_for_descriptor_with_grading_parameters, T>): Promise<dakora_get_examples_for_descriptor_with_grading_returns_item[]> =>
      this.callWebservice('dakora_get_examples_for_descriptor_with_grading', params);
  
    /**
     * get examples for descriptor for dakora app
     */
    public dakora_get_examples_for_descriptor_for_crosssubject = async <T extends dakora_get_examples_for_descriptor_for_crosssubject_parameters>(params: NoExtraProperties<dakora_get_examples_for_descriptor_for_crosssubject_parameters, T>): Promise<dakora_get_examples_for_descriptor_for_crosssubject_returns_item[]> =>
      this.callWebservice('dakora_get_examples_for_descriptor_for_crosssubject', params);
  
    /**
     * get examples for descriptor with additional grading information
     */
    public dakora_get_examples_for_descriptor_for_crosssubject_with_grading = async <T extends dakora_get_examples_for_descriptor_for_crosssubject_with_grading_parameters>(params: NoExtraProperties<dakora_get_examples_for_descriptor_for_crosssubject_with_grading_parameters, T>): Promise<dakora_get_examples_for_descriptor_for_crosssubject_with_grading_returns_item[]> =>
      this.callWebservice('dakora_get_examples_for_descriptor_for_crosssubject_with_grading', params);
  
    /**
     * get example overview for dakora app
     */
    public dakora_get_example_overview = async <T extends dakora_get_example_overview_parameters>(params: NoExtraProperties<dakora_get_example_overview_parameters, T>): Promise<dakora_get_example_overview_returns> =>
      this.callWebservice('dakora_get_example_overview', params);
  
    /**
     * get example overview for dakora app
     */
    public diggrplus_get_example_overview = async <T extends diggrplus_get_example_overview_parameters>(params: NoExtraProperties<diggrplus_get_example_overview_parameters, T>): Promise<diggrplus_get_example_overview_returns> =>
      this.callWebservice('diggrplus_get_example_overview', params);
  
    /**
     * add example to learning calendar for dakora get courses
     */
    public dakora_add_example_to_learning_calendar = async <T extends dakora_add_example_to_learning_calendar_parameters>(params: NoExtraProperties<dakora_add_example_to_learning_calendar_parameters, T>): Promise<dakora_add_example_to_learning_calendar_returns> =>
      this.callWebservice('dakora_add_example_to_learning_calendar', params);
  
    /**
     * get descriptors where example is associated Get descriptors for example
     */
    public dakora_get_descriptors_for_example = async <T extends dakora_get_descriptors_for_example_parameters>(params: NoExtraProperties<dakora_get_descriptors_for_example_parameters, T>): Promise<dakora_get_descriptors_for_example_returns_item[]> =>
      this.callWebservice('dakora_get_descriptors_for_example', params);
  
    /**
     * get student and teacher evaluation for example Get example grading for user
     */
    public dakora_get_example_grading = async <T extends dakora_get_example_grading_parameters>(params: NoExtraProperties<dakora_get_example_grading_parameters, T>): Promise<dakora_get_example_grading_returns> =>
      this.callWebservice('dakora_get_example_grading', params);
  
    /**
     * get student and teacher evaluation for topic Get topic grading for user
     */
    public dakora_get_topic_grading = async <T extends dakora_get_topic_grading_parameters>(params: NoExtraProperties<dakora_get_topic_grading_parameters, T>): Promise<dakora_get_topic_grading_returns> =>
      this.callWebservice('dakora_get_topic_grading', params);
  
    /**
     * get student and teacher evaluation for subject Get subject grading for user
     */
    public dakora_get_subject_grading = async <T extends dakora_get_subject_grading_parameters>(params: NoExtraProperties<dakora_get_subject_grading_parameters, T>): Promise<dakora_get_subject_grading_returns> =>
      this.callWebservice('dakora_get_subject_grading', params);
  
    /**
     * get user role 1= trainer, 2= student return 1 for trainer 2 for student 0 if false
     */
    public dakora_get_user_role = async <T extends dakora_get_user_role_parameters>(params?: NoExtraProperties<dakora_get_user_role_parameters, T>): Promise<dakora_get_user_role_returns> =>
      this.callWebservice('dakora_get_user_role', params);
  
    /**
     * get list of students for course
     */
    public dakora_get_students_and_groups_for_course = async <T extends dakora_get_students_and_groups_for_course_parameters>(params: NoExtraProperties<dakora_get_students_and_groups_for_course_parameters, T>): Promise<dakora_get_students_and_groups_for_course_returns> =>
      this.callWebservice('dakora_get_students_and_groups_for_course', params);
  
    /**
     * get list of students that are enrolled in any course of a teacher
     */
    public dakora_get_students_for_teacher = async <T extends dakora_get_students_for_teacher_parameters>(params: NoExtraProperties<dakora_get_students_for_teacher_parameters, T>): Promise<dakora_get_students_for_teacher_returns_item[]> =>
      this.callWebservice('dakora_get_students_for_teacher', params);
  
    /**
     * get list of teachers in any course of the student
     */
    public dakora_get_teachers_for_student = async <T extends dakora_get_teachers_for_student_parameters>(params: NoExtraProperties<dakora_get_teachers_for_student_parameters, T>): Promise<dakora_get_teachers_for_student_returns_item[]> =>
      this.callWebservice('dakora_get_teachers_for_student', params);
  
    /**
     * get list of examples for weekly schedule pool Get examples for pool
     */
    public dakora_get_examples_pool = async <T extends dakora_get_examples_pool_parameters>(params: NoExtraProperties<dakora_get_examples_pool_parameters, T>): Promise<dakora_get_examples_pool_returns_item[]> =>
      this.callWebservice('dakora_get_examples_pool', params);
  
    /**
     * get examples for trash bin Get examples for trash
     */
    public dakora_get_examples_trash = async <T extends dakora_get_examples_trash_parameters>(params: NoExtraProperties<dakora_get_examples_trash_parameters, T>): Promise<dakora_get_examples_trash_returns_item[]> =>
      this.callWebservice('dakora_get_examples_trash', params);
  
    /**
     * set start and end time for example set example time slot
     */
    public dakora_set_example_time_slot = async <T extends dakora_set_example_time_slot_parameters>(params: NoExtraProperties<dakora_set_example_time_slot_parameters, T>): Promise<dakora_set_example_time_slot_returns> =>
      this.callWebservice('dakora_set_example_time_slot', params);
  
    /**
     * remove example from weekly schedule remove example from time slot
     */
    public dakora_remove_example_from_schedule = async <T extends dakora_remove_example_from_schedule_parameters>(params: NoExtraProperties<dakora_remove_example_from_schedule_parameters, T>): Promise<dakora_remove_example_from_schedule_returns> =>
      this.callWebservice('dakora_remove_example_from_schedule', params);
  
    /**
     * add examples to the schedules of all course students remove example from time slot
     */
    public dakora_add_examples_to_schedule_for_all = async <T extends dakora_add_examples_to_schedule_for_all_parameters>(params: NoExtraProperties<dakora_add_examples_to_schedule_for_all_parameters, T>): Promise<dakora_add_examples_to_schedule_for_all_returns> =>
      this.callWebservice('dakora_add_examples_to_schedule_for_all', params);
  
    /**
     * get examples for a special start to end period (e.g. day) Get examples for time slot
     */
    public dakora_get_examples_for_time_slot = async <T extends dakora_get_examples_for_time_slot_parameters>(params: NoExtraProperties<dakora_get_examples_for_time_slot_parameters, T>): Promise<dakora_get_examples_for_time_slot_returns_item[]> =>
      this.callWebservice('dakora_get_examples_for_time_slot', params);
  
    /**
     * get cross subjects for an user in course context (allways all crosssubjs, even if not associated) Get cross subjects
     */
    public dakora_get_cross_subjects_by_course = async <T extends dakora_get_cross_subjects_by_course_parameters>(params: NoExtraProperties<dakora_get_cross_subjects_by_course_parameters, T>): Promise<dakora_get_cross_subjects_by_course_returns_item[]> =>
      this.callWebservice('dakora_get_cross_subjects_by_course', params);
  
    /**
     * get descriptors for a cross subject associated with examples Get cross subjects
     */
    public dakora_get_descriptors_by_cross_subject = async <T extends dakora_get_descriptors_by_cross_subject_parameters>(params: NoExtraProperties<dakora_get_descriptors_by_cross_subject_parameters, T>): Promise<dakora_get_descriptors_by_cross_subject_returns_item[]> =>
      this.callWebservice('dakora_get_descriptors_by_cross_subject', params);
  
    /**
     * get descriptors for a cross subject Get cross subjects
     */
    public dakora_get_all_descriptors_by_cross_subject = async <T extends dakora_get_all_descriptors_by_cross_subject_parameters>(params: NoExtraProperties<dakora_get_all_descriptors_by_cross_subject_parameters, T>): Promise<dakora_get_all_descriptors_by_cross_subject_returns_item[]> =>
      this.callWebservice('dakora_get_all_descriptors_by_cross_subject', params);
  
    /**
     * get children in context of cross subject, associated with examples get children for descriptor in cross subject context
     */
    public dakora_get_descriptor_children_for_cross_subject = async <T extends dakora_get_descriptor_children_for_cross_subject_parameters>(params: NoExtraProperties<dakora_get_descriptor_children_for_cross_subject_parameters, T>): Promise<dakora_get_descriptor_children_for_cross_subject_returns> =>
      this.callWebservice('dakora_get_descriptor_children_for_cross_subject', params);
  
    /**
     * get children in context of cross subject get children for descriptor in cross subject context
     */
    public dakora_get_all_descriptor_children_for_cross_subject = async <T extends dakora_get_all_descriptor_children_for_cross_subject_parameters>(params: NoExtraProperties<dakora_get_all_descriptor_children_for_cross_subject_parameters, T>): Promise<dakora_get_all_descriptor_children_for_cross_subject_returns> =>
      this.callWebservice('dakora_get_all_descriptor_children_for_cross_subject', params);
  
    /**
     * get configuration options for schedule units get children for descriptor in cross subject context
     */
    public dakora_get_schedule_config = async <T extends dakora_get_schedule_config_parameters>(params?: NoExtraProperties<dakora_get_schedule_config_parameters, T>): Promise<dakora_get_schedule_config_returns> =>
      this.callWebservice('dakora_get_schedule_config', params);
  
    /**
     * get examples for pre planning storage get pre planning storage examples for current teacher
     */
    public dakora_get_pre_planning_storage_examples = async <T extends dakora_get_pre_planning_storage_examples_parameters>(params: NoExtraProperties<dakora_get_pre_planning_storage_examples_parameters, T>): Promise<dakora_get_pre_planning_storage_examples_returns_item[]> =>
      this.callWebservice('dakora_get_pre_planning_storage_examples', params);
  
    /**
     * get students for pre planning storage get pre planning storage students for current teacher
     */
    public dakora_get_pre_planning_storage_students = async <T extends dakora_get_pre_planning_storage_students_parameters>(params: NoExtraProperties<dakora_get_pre_planning_storage_students_parameters, T>): Promise<dakora_get_pre_planning_storage_students_returns_item[]> =>
      this.callWebservice('dakora_get_pre_planning_storage_students', params);
  
    /**
     * get students for pre planning storage get pre planning storage students for current teacher
     */
    public dakora_get_pre_planning_storage_groups = async <T extends dakora_get_pre_planning_storage_groups_parameters>(params: NoExtraProperties<dakora_get_pre_planning_storage_groups_parameters, T>): Promise<dakora_get_pre_planning_storage_groups_returns_item[]> =>
      this.callWebservice('dakora_get_pre_planning_storage_groups', params);
  
    /**
     * return 0 if no items, 1 otherwise get pre planning storage students for current teacher
     */
    public dakora_has_items_in_pre_planning_storage = async <T extends dakora_has_items_in_pre_planning_storage_parameters>(params: NoExtraProperties<dakora_has_items_in_pre_planning_storage_parameters, T>): Promise<dakora_has_items_in_pre_planning_storage_returns> =>
      this.callWebservice('dakora_has_items_in_pre_planning_storage', params);
  
    /**
     * delte all items from current pre planning storage empty pre planning storage for current teacher
     */
    public dakora_empty_pre_planning_storage = async <T extends dakora_empty_pre_planning_storage_parameters>(params: NoExtraProperties<dakora_empty_pre_planning_storage_parameters, T>): Promise<dakora_empty_pre_planning_storage_returns> =>
      this.callWebservice('dakora_empty_pre_planning_storage', params);
  
    /**
     * add example to current pre planning storage add example to current pre planning storage
     */
    public dakora_add_example_to_pre_planning_storage = async <T extends dakora_add_example_to_pre_planning_storage_parameters>(params: NoExtraProperties<dakora_add_example_to_pre_planning_storage_parameters, T>): Promise<dakora_add_example_to_pre_planning_storage_returns> =>
      this.callWebservice('dakora_add_example_to_pre_planning_storage', params);
  
    /**
     * add examples from current pre planning storage to students weekly schedule add example to current pre planning storage
     */
    public dakora_add_examples_to_students_schedule = async <T extends dakora_add_examples_to_students_schedule_parameters>(params: NoExtraProperties<dakora_add_examples_to_students_schedule_parameters, T>): Promise<dakora_add_examples_to_students_schedule_returns> =>
      this.callWebservice('dakora_add_examples_to_students_schedule', params);
  
    /**
     * add examples from current pre planning storage to students weekly schedule add example to current pre planning storage
     */
    public dakora_add_examples_to_selected_students_schedule = async <T extends dakora_add_examples_to_selected_students_schedule_parameters>(params: NoExtraProperties<dakora_add_examples_to_selected_students_schedule_parameters, T>): Promise<dakora_add_examples_to_selected_students_schedule_returns> =>
      this.callWebservice('dakora_add_examples_to_selected_students_schedule', params);
  
    /**
     * remove example from weekly schedule by teacherid and distribution id used for 'undo' button
     */
    public dakora_delete_examples_from_schedule = async <T extends dakora_delete_examples_from_schedule_parameters>(params: NoExtraProperties<dakora_delete_examples_from_schedule_parameters, T>): Promise<dakora_delete_examples_from_schedule_returns> =>
      this.callWebservice('dakora_delete_examples_from_schedule', params);
  
    /**
     * remove example from weekly schedule by teacherid and distribution id used for 'undo' button
     */
    public dakora_undo_examples_from_schedule = async <T extends dakora_undo_examples_from_schedule_parameters>(params: NoExtraProperties<dakora_undo_examples_from_schedule_parameters, T>): Promise<dakora_undo_examples_from_schedule_returns> =>
      this.callWebservice('dakora_undo_examples_from_schedule', params);
  
    /**
     * submit example solution Add student submission to example.
     */
    public dakora_submit_example = async <T extends dakora_submit_example_parameters>(params: NoExtraProperties<dakora_submit_example_parameters, T>): Promise<dakora_submit_example_returns> =>
      this.callWebservice('dakora_submit_example', params);
  
    /**
     * Add studentsubmission (exaportitem) to topic, descriptor or example
     */
    public diggrplus_submit_item = async <T extends diggrplus_submit_item_parameters>(params: NoExtraProperties<diggrplus_submit_item_parameters, T>): Promise<diggrplus_submit_item_returns> =>
      this.callWebservice('diggrplus_submit_item', params);
  
    /**
     * Add studentsubmission (exaportitem) to topic, descriptor or example
     */
    public diggrplus_submit_item_comment = async <T extends diggrplus_submit_item_comment_parameters>(params: NoExtraProperties<diggrplus_submit_item_comment_parameters, T>): Promise<diggrplus_submit_item_comment_returns> =>
      this.callWebservice('diggrplus_submit_item_comment', params);
  
    /**
     * Add studentsubmission (exaportitem) to topic, descriptor or example
     */
    public diggrplus_get_item_comments = async <T extends diggrplus_get_item_comments_parameters>(params: NoExtraProperties<diggrplus_get_item_comments_parameters, T>): Promise<diggrplus_get_item_comments_returns_item[]> =>
      this.callWebservice('diggrplus_get_item_comments', params);
  
    /**
     * Get Items get all items AND examples for a competence they will be returned in one array, even though their fields may vary, but it makes ordering according to filters easier for the backend
     */
    public diggrplus_get_examples_and_items = async <T extends diggrplus_get_examples_and_items_parameters>(params: NoExtraProperties<diggrplus_get_examples_and_items_parameters, T>): Promise<diggrplus_get_examples_and_items_returns_item[]> =>
      this.callWebservice('diggrplus_get_examples_and_items', params);
  
    /**
     * Get Items get all items AND examples for a competence they will be returned in one array, even though their fields may vary, but it makes ordering according to filters easier for the backend
     */
    public diggrplus_get_teacher_examples_and_items = async <T extends diggrplus_get_teacher_examples_and_items_parameters>(params: NoExtraProperties<diggrplus_get_teacher_examples_and_items_parameters, T>): Promise<diggrplus_get_teacher_examples_and_items_returns_item[]> =>
      this.callWebservice('diggrplus_get_teacher_examples_and_items', params);
  
    /**
     * Get Subjects get subjects from one user for one course
     */
    public diggrplus_get_all_subjects_for_course_as_tree = async <T extends diggrplus_get_all_subjects_for_course_as_tree_parameters>(params: NoExtraProperties<diggrplus_get_all_subjects_for_course_as_tree_parameters, T>): Promise<diggrplus_get_all_subjects_for_course_as_tree_returns_item[]> =>
      this.callWebservice('diggrplus_get_all_subjects_for_course_as_tree', params);
  
    /**
     * 
     */
    public diggrplus_get_user_info = async <T extends diggrplus_get_user_info_parameters>(params?: NoExtraProperties<diggrplus_get_user_info_parameters, T>): Promise<diggrplus_get_user_info_returns> =>
      this.callWebservice('diggrplus_get_user_info', params);
  
    /**
     * Load a file from an external Domain to prevent CORS when loading directly in the App
     */
    public diggrplus_request_external_file = async <T extends diggrplus_request_external_file_parameters>(params: NoExtraProperties<diggrplus_request_external_file_parameters, T>): Promise<diggrplus_request_external_file_returns> =>
      this.callWebservice('diggrplus_request_external_file', params);
  
    /**
     * teacher grades and item in diggrplus
     */
    public diggrplus_grade_item = async <T extends diggrplus_grade_item_parameters>(params: NoExtraProperties<diggrplus_grade_item_parameters, T>): Promise<diggrplus_grade_item_returns> =>
      this.callWebservice('diggrplus_grade_item', params);
  
    /**
     * Get competence statistic for profile
     */
    public diggrplus_get_competence_profile_statistic = async <T extends diggrplus_get_competence_profile_statistic_parameters>(params: NoExtraProperties<diggrplus_get_competence_profile_statistic_parameters, T>): Promise<diggrplus_get_competence_profile_statistic_returns> =>
      this.callWebservice('diggrplus_get_competence_profile_statistic', params);
  
    /**
     * get descriptors where example is associated Get descriptors for example
     */
    public diggrplus_get_descriptors_for_example = async <T extends diggrplus_get_descriptors_for_example_parameters>(params: NoExtraProperties<diggrplus_get_descriptors_for_example_parameters, T>): Promise<diggrplus_get_descriptors_for_example_returns_item[]> =>
      this.callWebservice('diggrplus_get_descriptors_for_example', params);
  
    /**
     * grade example solution Add student submission to example.
     */
    public dakora_grade_example = async <T extends dakora_grade_example_parameters>(params: NoExtraProperties<dakora_grade_example_parameters, T>): Promise<dakora_grade_example_returns> =>
      this.callWebservice('dakora_grade_example', params);
  
    /**
     * get descriptor details incl. grading and children for many descriptors
     */
    public dakora_get_descriptors_details = async <T extends dakora_get_descriptors_details_parameters>(params: NoExtraProperties<dakora_get_descriptors_details_parameters, T>): Promise<dakora_get_descriptors_details_returns_item[]> =>
      this.callWebservice('dakora_get_descriptors_details', params);
  
    /**
     * get descriptor details incl. grading and children
     */
    public dakora_get_descriptor_details = async <T extends dakora_get_descriptor_details_parameters>(params: NoExtraProperties<dakora_get_descriptor_details_parameters, T>): Promise<dakora_get_descriptor_details_returns> =>
      this.callWebservice('dakora_get_descriptor_details', params);
  
    /**
     * get information and submission for example get example with all submission details and gradings
     */
    public dakora_get_example_information = async <T extends dakora_get_example_information_parameters>(params: NoExtraProperties<dakora_get_example_information_parameters, T>): Promise<dakora_get_example_information_returns> =>
      this.callWebservice('dakora_get_example_information', params);
  
    /**
     * get information about current user get example with all submission details and gradings
     */
    public dakora_get_user_information = async <T extends dakora_get_user_information_parameters>(params?: NoExtraProperties<dakora_get_user_information_parameters, T>): Promise<dakora_get_user_information_returns> =>
      this.callWebservice('dakora_get_user_information', params);
  
    /**
     * Returns lang information from exacomp
     */
    public dakora_get_lang_information = async <T extends dakora_get_lang_information_parameters>(params: NoExtraProperties<dakora_get_lang_information_parameters, T>): Promise<dakora_get_lang_information_returns_item[]> =>
      this.callWebservice('dakora_get_lang_information', params);
  
    /**
     * create a blocking event Create a new blocking event
     */
    public dakora_create_blocking_event = async <T extends dakora_create_blocking_event_parameters>(params: NoExtraProperties<dakora_create_blocking_event_parameters, T>): Promise<dakora_create_blocking_event_returns> =>
      this.callWebservice('dakora_create_blocking_event', params);
  
    /**
     * returns examples for given descriptor and grading Create a new blocking event
     */
    public dakora_get_examples_by_descriptor_and_grading = async <T extends dakora_get_examples_by_descriptor_and_grading_parameters>(params: NoExtraProperties<dakora_get_examples_by_descriptor_and_grading_parameters, T>): Promise<dakora_get_examples_by_descriptor_and_grading_returns_item[]> =>
      this.callWebservice('dakora_get_examples_by_descriptor_and_grading', params);
  
    /**
     * allow student to resubmit example Create a new blocking event
     */
    public dakora_allow_example_resubmission = async <T extends dakora_allow_example_resubmission_parameters>(params: NoExtraProperties<dakora_allow_example_resubmission_parameters, T>): Promise<dakora_allow_example_resubmission_returns> =>
      this.callWebservice('dakora_allow_example_resubmission', params);
  
    /**
     * get grid for profile Get competence grid for profile
     */
    public dakora_get_competence_grid_for_profile = async <T extends dakora_get_competence_grid_for_profile_parameters>(params: NoExtraProperties<dakora_get_competence_grid_for_profile_parameters, T>): Promise<dakora_get_competence_grid_for_profile_returns> =>
      this.callWebservice('dakora_get_competence_grid_for_profile', params);
  
    /**
     * get statistic in user and subject context Get competence statistic for profile
     */
    public dakora_get_competence_profile_statistic = async <T extends dakora_get_competence_profile_statistic_parameters>(params: NoExtraProperties<dakora_get_competence_profile_statistic_parameters, T>): Promise<dakora_get_competence_profile_statistic_returns> =>
      this.callWebservice('dakora_get_competence_profile_statistic', params);
  
    /**
     * get list for student and teacher comparison Get competence comparison for profile
     */
    public dakora_get_competence_profile_comparison = async <T extends dakora_get_competence_profile_comparison_parameters>(params: NoExtraProperties<dakora_get_competence_profile_comparison_parameters, T>): Promise<dakora_get_competence_profile_comparison_returns> =>
      this.callWebservice('dakora_get_competence_profile_comparison', params);
  
    /**
     * get data for 3D graph Get competence statistic for topic in profile for 3D graph
     */
    public dakora_get_competence_profile_topic_statistic = async <T extends dakora_get_competence_profile_topic_statistic_parameters>(params: NoExtraProperties<dakora_get_competence_profile_topic_statistic_parameters, T>): Promise<dakora_get_competence_profile_topic_statistic_returns> =>
      this.callWebservice('dakora_get_competence_profile_topic_statistic', params);
  
    /**
     * check the corresponding config setting
     */
    public block_exacomp_is_elove_student_self_assessment_enabled = async <T extends block_exacomp_is_elove_student_self_assessment_enabled_parameters>(params?: NoExtraProperties<block_exacomp_is_elove_student_self_assessment_enabled_parameters, T>): Promise<block_exacomp_is_elove_student_self_assessment_enabled_returns> =>
      this.callWebservice('block_exacomp_is_elove_student_self_assessment_enabled', params);
  
    /**
     * 
     */
    public dakora_get_site_policies = async <T extends dakora_get_site_policies_parameters>(params?: NoExtraProperties<dakora_get_site_policies_parameters, T>): Promise<dakora_get_site_policies_returns_item[]> =>
      this.callWebservice('dakora_get_site_policies', params);
  
    /**
     * 
     */
    public dakora_get_example_h5p_activity_results = async <T extends dakora_get_example_h5p_activity_results_parameters>(params: NoExtraProperties<dakora_get_example_h5p_activity_results_parameters, T>): Promise<dakora_get_example_h5p_activity_results_returns> =>
      this.callWebservice('dakora_get_example_h5p_activity_results', params);
  
    /**
     * Create one or more cohorts
     */
    public block_exacomp_diggr_create_cohort = async <T extends block_exacomp_diggr_create_cohort_parameters>(params: NoExtraProperties<block_exacomp_diggr_create_cohort_parameters, T>): Promise<block_exacomp_diggr_create_cohort_returns> =>
      this.callWebservice('block_exacomp_diggr_create_cohort', params);
  
    /**
     * Create one or more cohorts
     */
    public block_exacomp_diggr_get_students_of_cohort = async <T extends block_exacomp_diggr_get_students_of_cohort_parameters>(params: NoExtraProperties<block_exacomp_diggr_get_students_of_cohort_parameters, T>): Promise<block_exacomp_diggr_get_students_of_cohort_returns> =>
      this.callWebservice('block_exacomp_diggr_get_students_of_cohort', params);
  
    /**
     * Create one or more cohorts
     */
    public block_exacomp_diggr_get_cohorts_of_trainer = async <T extends block_exacomp_diggr_get_cohorts_of_trainer_parameters>(params?: NoExtraProperties<block_exacomp_diggr_get_cohorts_of_trainer_parameters, T>): Promise<block_exacomp_diggr_get_cohorts_of_trainer_returns> =>
      this.callWebservice('block_exacomp_diggr_get_cohorts_of_trainer', params);
  
    /**
     * get evaluation configuration get admin evaluation configurations
     */
    public dakora_get_evaluation_config = async <T extends dakora_get_evaluation_config_parameters>(params?: NoExtraProperties<dakora_get_evaluation_config_parameters, T>): Promise<dakora_get_evaluation_config_returns> =>
      this.callWebservice('dakora_get_evaluation_config', params);
  
    /**
     * 
     */
    public dakora_get_config = async <T extends dakora_get_config_parameters>(params?: NoExtraProperties<dakora_get_config_parameters, T>): Promise<dakora_get_config_returns> =>
      this.callWebservice('dakora_get_config', params);
  
    /**
     * 
     */
    public dakora_get_courseconfigs = async <T extends dakora_get_courseconfigs_parameters>(params?: NoExtraProperties<dakora_get_courseconfigs_parameters, T>): Promise<dakora_get_courseconfigs_returns> =>
      this.callWebservice('dakora_get_courseconfigs', params);
  
    /**
     * webservice called through token.php
     */
    public block_exacomp_login = async <T extends block_exacomp_login_parameters>(params: NoExtraProperties<block_exacomp_login_parameters, T>): Promise<block_exacomp_login_returns> =>
      this.callWebservice('block_exacomp_login', params);
  
    /**
     * set visibility for descriptor
     */
    public dakora_set_descriptor_visibility = async <T extends dakora_set_descriptor_visibility_parameters>(params: NoExtraProperties<dakora_set_descriptor_visibility_parameters, T>): Promise<dakora_set_descriptor_visibility_returns> =>
      this.callWebservice('dakora_set_descriptor_visibility', params);
  
    /**
     * set visibility for example
     */
    public dakora_set_example_visibility = async <T extends dakora_set_example_visibility_parameters>(params: NoExtraProperties<dakora_set_example_visibility_parameters, T>): Promise<dakora_set_example_visibility_returns> =>
      this.callWebservice('dakora_set_example_visibility', params);
  
    /**
     * set visibility for topic
     */
    public dakora_set_topic_visibility = async <T extends dakora_set_topic_visibility_parameters>(params: NoExtraProperties<dakora_set_topic_visibility_parameters, T>): Promise<dakora_set_topic_visibility_returns> =>
      this.callWebservice('dakora_set_topic_visibility', params);
  
    /**
     * set visibility for topic
     */
    public dakora_set_niveau_visibility = async <T extends dakora_set_niveau_visibility_parameters>(params: NoExtraProperties<dakora_set_niveau_visibility_parameters, T>): Promise<dakora_set_niveau_visibility_returns> =>
      this.callWebservice('dakora_set_niveau_visibility', params);
  
    /**
     * set visibility for example solutions
     */
    public dakora_set_example_solution_visibility = async <T extends dakora_set_example_solution_visibility_parameters>(params: NoExtraProperties<dakora_set_example_solution_visibility_parameters, T>): Promise<dakora_set_example_solution_visibility_returns> =>
      this.callWebservice('dakora_set_example_solution_visibility', params);
  
    /**
     * set visibility for descriptor
     */
    public diggrplus_set_descriptor_visibility = async <T extends diggrplus_set_descriptor_visibility_parameters>(params: NoExtraProperties<diggrplus_set_descriptor_visibility_parameters, T>): Promise<diggrplus_set_descriptor_visibility_returns> =>
      this.callWebservice('diggrplus_set_descriptor_visibility', params);
  
    /**
     * set visibility for example
     */
    public diggrplus_set_example_visibility = async <T extends diggrplus_set_example_visibility_parameters>(params: NoExtraProperties<diggrplus_set_example_visibility_parameters, T>): Promise<diggrplus_set_example_visibility_returns> =>
      this.callWebservice('diggrplus_set_example_visibility', params);
  
    /**
     * set visibility for topic
     */
    public diggrplus_set_topic_visibility = async <T extends diggrplus_set_topic_visibility_parameters>(params: NoExtraProperties<diggrplus_set_topic_visibility_parameters, T>): Promise<diggrplus_set_topic_visibility_returns> =>
      this.callWebservice('diggrplus_set_topic_visibility', params);
  
    /**
     * create new crosssubject
     */
    public dakora_create_cross_subject = async <T extends dakora_create_cross_subject_parameters>(params: NoExtraProperties<dakora_create_cross_subject_parameters, T>): Promise<dakora_create_cross_subject_returns> =>
      this.callWebservice('dakora_create_cross_subject', params);
  
    /**
     * delete cross subject
     */
    public dakora_delete_cross_subject = async <T extends dakora_delete_cross_subject_parameters>(params: NoExtraProperties<dakora_delete_cross_subject_parameters, T>): Promise<dakora_delete_cross_subject_returns> =>
      this.callWebservice('dakora_delete_cross_subject', params);
  
    /**
     * edit existing crosssubject
     */
    public dakora_edit_cross_subject = async <T extends dakora_edit_cross_subject_parameters>(params: NoExtraProperties<dakora_edit_cross_subject_parameters, T>): Promise<dakora_edit_cross_subject_returns> =>
      this.callWebservice('dakora_edit_cross_subject', params);
  
    /**
     * get available drafts
     */
    public dakora_get_cross_subject_drafts = async <T extends dakora_get_cross_subject_drafts_parameters>(params: NoExtraProperties<dakora_get_cross_subject_drafts_parameters, T>): Promise<dakora_get_cross_subject_drafts_returns_item[]> =>
      this.callWebservice('dakora_get_cross_subject_drafts', params);
  
    /**
     * get subjects
     */
    public dakora_get_subjects = async <T extends dakora_get_subjects_parameters>(params: NoExtraProperties<dakora_get_subjects_parameters, T>): Promise<dakora_get_subjects_returns_item[]> =>
      this.callWebservice('dakora_get_subjects', params);
  
    /**
     * get_students_for_crosssubject
     */
    public dakora_get_students_for_cross_subject = async <T extends dakora_get_students_for_cross_subject_parameters>(params: NoExtraProperties<dakora_get_students_for_cross_subject_parameters, T>): Promise<dakora_get_students_for_cross_subject_returns> =>
      this.callWebservice('dakora_get_students_for_cross_subject', params);
  
    /**
     * set visibility for crosssubject and student
     */
    public dakora_set_cross_subject_student = async <T extends dakora_set_cross_subject_student_parameters>(params: NoExtraProperties<dakora_set_cross_subject_student_parameters, T>): Promise<dakora_set_cross_subject_student_returns> =>
      this.callWebservice('dakora_set_cross_subject_student', params);
  
    /**
     * set descriptor crosssubject association
     */
    public dakora_set_cross_subject_descriptor = async <T extends dakora_set_cross_subject_descriptor_parameters>(params: NoExtraProperties<dakora_set_cross_subject_descriptor_parameters, T>): Promise<dakora_set_cross_subject_descriptor_returns> =>
      this.callWebservice('dakora_set_cross_subject_descriptor', params);
  
    /**
     * set descriptor crosssubject association
     */
    public dakora_dismiss_oldgrading_warning = async <T extends dakora_dismiss_oldgrading_warning_parameters>(params: NoExtraProperties<dakora_dismiss_oldgrading_warning_parameters, T>): Promise<dakora_dismiss_oldgrading_warning_returns> =>
      this.callWebservice('dakora_dismiss_oldgrading_warning', params);
  
    /**
     * send message
     */
    public dakora_send_message = async <T extends dakora_send_message_parameters>(params: NoExtraProperties<dakora_send_message_parameters, T>): Promise<dakora_send_message_returns> =>
      this.callWebservice('dakora_send_message', params);
  
    /**
     * update an descriptor category
     */
    public block_exacomp_update_descriptor_category = async <T extends block_exacomp_update_descriptor_category_parameters>(params: NoExtraProperties<block_exacomp_update_descriptor_category_parameters, T>): Promise<block_exacomp_update_descriptor_category_returns> =>
      this.callWebservice('block_exacomp_update_descriptor_category', params);
  
    /**
     * gets title description and image of website
     */
    public block_exacomp_get_url_preview = async <T extends block_exacomp_get_url_preview_parameters>(params: NoExtraProperties<block_exacomp_get_url_preview_parameters, T>): Promise<block_exacomp_get_url_preview_returns> =>
      this.callWebservice('block_exacomp_get_url_preview', params);
  
    /**
     * view competence overview
     */
    public dakora_competencegrid_overview = async <T extends dakora_competencegrid_overview_parameters>(params: NoExtraProperties<dakora_competencegrid_overview_parameters, T>): Promise<dakora_competencegrid_overview_returns> =>
      this.callWebservice('dakora_competencegrid_overview', params);
  
    /**
     * delete example
     */
    public dakora_delete_custom_example = async <T extends dakora_delete_custom_example_parameters>(params: NoExtraProperties<dakora_delete_custom_example_parameters, T>): Promise<dakora_delete_custom_example_returns> =>
      this.callWebservice('dakora_delete_custom_example', params);
  
    /**
     * delete example
     */
    public diggrplus_delete_custom_example = async <T extends diggrplus_delete_custom_example_parameters>(params: NoExtraProperties<diggrplus_delete_custom_example_parameters, T>): Promise<diggrplus_delete_custom_example_returns> =>
      this.callWebservice('diggrplus_delete_custom_example', params);
  
    /**
     * 
     */
    public diggrplus_get_course_schooltype_tree = async <T extends diggrplus_get_course_schooltype_tree_parameters>(params: NoExtraProperties<diggrplus_get_course_schooltype_tree_parameters, T>): Promise<diggrplus_get_course_schooltype_tree_returns> =>
      this.callWebservice('diggrplus_get_course_schooltype_tree', params);
  
    /**
     * 
     */
    public diggrplus_set_active_course_topics = async <T extends diggrplus_set_active_course_topics_parameters>(params: NoExtraProperties<diggrplus_set_active_course_topics_parameters, T>): Promise<diggrplus_set_active_course_topics_returns> =>
      this.callWebservice('diggrplus_set_active_course_topics', params);
  
    /**
     * 
     */
    public diggrplus_get_config = async <T extends diggrplus_get_config_parameters>(params?: NoExtraProperties<diggrplus_get_config_parameters, T>): Promise<diggrplus_get_config_returns> =>
      this.callWebservice('diggrplus_get_config', params);
  
    /**
     * Create an example or update it create example
     */
    public diggrplus_annotate_example = async <T extends diggrplus_annotate_example_parameters>(params: NoExtraProperties<diggrplus_annotate_example_parameters, T>): Promise<diggrplus_annotate_example_returns> =>
      this.callWebservice('diggrplus_annotate_example', params);
  
    /**
     * get active code for student enrollment
     */
    public diggrplus_get_student_enrolcode = async <T extends diggrplus_get_student_enrolcode_parameters>(params: NoExtraProperties<diggrplus_get_student_enrolcode_parameters, T>): Promise<diggrplus_get_student_enrolcode_returns> =>
      this.callWebservice('diggrplus_get_student_enrolcode', params);
  
    /**
     * Create new enrolcode and delete old ones
     */
    public diggrplus_create_student_enrolcode = async <T extends diggrplus_create_student_enrolcode_parameters>(params: NoExtraProperties<diggrplus_create_student_enrolcode_parameters, T>): Promise<diggrplus_create_student_enrolcode_returns> =>
      this.callWebservice('diggrplus_create_student_enrolcode', params);
  
    /**
     * Use a QR-Code to enrol
     */
    public diggrplus_enrol_by_enrolcode = async <T extends diggrplus_enrol_by_enrolcode_parameters>(params: NoExtraProperties<diggrplus_enrol_by_enrolcode_parameters, T>): Promise<diggrplus_enrol_by_enrolcode_returns> =>
      this.callWebservice('diggrplus_enrol_by_enrolcode', params);
  
    /**
     * Get urls and resources per section for every course of current user
     */
    public block_exacomp_diwipass_get_sections_with_materials = async <T extends block_exacomp_diwipass_get_sections_with_materials_parameters>(params?: NoExtraProperties<block_exacomp_diwipass_get_sections_with_materials_parameters, T>): Promise<block_exacomp_diwipass_get_sections_with_materials_returns> =>
      this.callWebservice('block_exacomp_diwipass_get_sections_with_materials', params);
  
    /**
     * 
     */
    public dakoraplus_get_example_and_item = async <T extends dakoraplus_get_example_and_item_parameters>(params: NoExtraProperties<dakoraplus_get_example_and_item_parameters, T>): Promise<dakoraplus_get_example_and_item_returns> =>
      this.callWebservice('dakoraplus_get_example_and_item', params);
  
    /**
     * 
     */
    public dakoraplus_get_teacher_example_and_item = async <T extends dakoraplus_get_teacher_example_and_item_parameters>(params: NoExtraProperties<dakoraplus_get_teacher_example_and_item_parameters, T>): Promise<dakoraplus_get_teacher_example_and_item_returns> =>
      this.callWebservice('dakoraplus_get_teacher_example_and_item', params);
  
    /**
     * 
     */
    public dakoraplus_save_coursesettings = async <T extends dakoraplus_save_coursesettings_parameters>(params: NoExtraProperties<dakoraplus_save_coursesettings_parameters, T>): Promise<dakoraplus_save_coursesettings_returns> =>
      this.callWebservice('dakoraplus_save_coursesettings', params);
  
    /**
     * 
     */
    public dakoraplus_get_learning_diary = async <T extends dakoraplus_get_learning_diary_parameters>(params?: NoExtraProperties<dakoraplus_get_learning_diary_parameters, T>): Promise<dakoraplus_get_learning_diary_returns_item[]> =>
      this.callWebservice('dakoraplus_get_learning_diary', params);
  
    /**
     * 
     */
    public dakoraplus_save_learning_diary_entry = async <T extends dakoraplus_save_learning_diary_entry_parameters>(params: NoExtraProperties<dakoraplus_save_learning_diary_entry_parameters, T>): Promise<dakoraplus_save_learning_diary_entry_returns> =>
      this.callWebservice('dakoraplus_save_learning_diary_entry', params);
  
    /**
     * Get language definitions in json format for diggr-plus and dakora-plus apps
     */
    public block_exacomp_get_lang = async <T extends block_exacomp_get_lang_parameters>(params: NoExtraProperties<block_exacomp_get_lang_parameters, T>): Promise<block_exacomp_get_lang_returns> =>
      this.callWebservice('block_exacomp_get_lang', params);
  
    /**
     * 
     */
    public diggrplus_learningpath_list = async <T extends diggrplus_learningpath_list_parameters>(params: NoExtraProperties<diggrplus_learningpath_list_parameters, T>): Promise<diggrplus_learningpath_list_returns> =>
      this.callWebservice('diggrplus_learningpath_list', params);
  
    /**
     * 
     */
    public diggrplus_learningpath_details = async <T extends diggrplus_learningpath_details_parameters>(params: NoExtraProperties<diggrplus_learningpath_details_parameters, T>): Promise<diggrplus_learningpath_details_returns> =>
      this.callWebservice('diggrplus_learningpath_details', params);
  
    /**
     * 
     */
    public diggrplus_learningpath_add = async <T extends diggrplus_learningpath_add_parameters>(params: NoExtraProperties<diggrplus_learningpath_add_parameters, T>): Promise<diggrplus_learningpath_add_returns> =>
      this.callWebservice('diggrplus_learningpath_add', params);
  
    /**
     * 
     */
    public diggrplus_learningpath_delete = async <T extends diggrplus_learningpath_delete_parameters>(params: NoExtraProperties<diggrplus_learningpath_delete_parameters, T>): Promise<diggrplus_learningpath_delete_returns> =>
      this.callWebservice('diggrplus_learningpath_delete', params);
  
    /**
     * 
     */
    public diggrplus_learningpath_update = async <T extends diggrplus_learningpath_update_parameters>(params: NoExtraProperties<diggrplus_learningpath_update_parameters, T>): Promise<diggrplus_learningpath_update_returns> =>
      this.callWebservice('diggrplus_learningpath_update', params);
  
    /**
     * 
     */
    public diggrplus_learningpath_item_update = async <T extends diggrplus_learningpath_item_update_parameters>(params: NoExtraProperties<diggrplus_learningpath_item_update_parameters, T>): Promise<diggrplus_learningpath_item_update_returns> =>
      this.callWebservice('diggrplus_learningpath_item_update', params);
  
    /**
     * 
     */
    public diggrplus_learningpath_add_items = async <T extends diggrplus_learningpath_add_items_parameters>(params: NoExtraProperties<diggrplus_learningpath_add_items_parameters, T>): Promise<diggrplus_learningpath_add_items_returns> =>
      this.callWebservice('diggrplus_learningpath_add_items', params);
  
    /**
     * 
     */
    public diggrplus_learningpath_item_delete = async <T extends diggrplus_learningpath_item_delete_parameters>(params: NoExtraProperties<diggrplus_learningpath_item_delete_parameters, T>): Promise<diggrplus_learningpath_item_delete_returns> =>
      this.callWebservice('diggrplus_learningpath_item_delete', params);
  
    /**
     * 
     */
    public diggrplus_learningpath_item_sorting = async <T extends diggrplus_learningpath_item_sorting_parameters>(params: NoExtraProperties<diggrplus_learningpath_item_sorting_parameters, T>): Promise<diggrplus_learningpath_item_sorting_returns> =>
      this.callWebservice('diggrplus_learningpath_item_sorting', params);
  
    /**
     * 
     */
    public dakoraplus_create_report = async <T extends dakoraplus_create_report_parameters>(params: NoExtraProperties<dakoraplus_create_report_parameters, T>): Promise<dakoraplus_create_report_returns> =>
      this.callWebservice('dakoraplus_create_report', params);
  
    /**
     * Returns full competence grid data for needed profile. (NO crossubjects data). Useful in next HTML generation Based and have similar output as in 'dakora_get_competence_grid_for_profile', but right now is used only for skillswork needs
     */
    public block_exacomp_get_fullcompetence_grid_for_profile = async <T extends block_exacomp_get_fullcompetence_grid_for_profile_parameters>(params: NoExtraProperties<block_exacomp_get_fullcompetence_grid_for_profile_parameters, T>): Promise<block_exacomp_get_fullcompetence_grid_for_profile_returns> =>
      this.callWebservice('block_exacomp_get_fullcompetence_grid_for_profile', params);
  
    /**
     * Create an example or update it create example
     */
    public diggrplus_v_edit_course = async <T extends diggrplus_v_edit_course_parameters>(params: NoExtraProperties<diggrplus_v_edit_course_parameters, T>): Promise<diggrplus_v_edit_course_returns> =>
      this.callWebservice('diggrplus_v_edit_course', params);
  
    /**
     * Create an example or update it create example
     */
    public diggrplus_v_create_or_update_student = async <T extends diggrplus_v_create_or_update_student_parameters>(params: NoExtraProperties<diggrplus_v_create_or_update_student_parameters, T>): Promise<diggrplus_v_create_or_update_student_returns> =>
      this.callWebservice('diggrplus_v_create_or_update_student', params);
  
    /**
     * Create an example or update it create example
     */
    public diggrplus_v_delete_student = async <T extends diggrplus_v_delete_student_parameters>(params: NoExtraProperties<diggrplus_v_delete_student_parameters, T>): Promise<diggrplus_v_delete_student_returns> =>
      this.callWebservice('diggrplus_v_delete_student', params);
  
    /**
     * Create an example or update it create example
     */
    public diggrplus_v_get_student_by_id = async <T extends diggrplus_v_get_student_by_id_parameters>(params: NoExtraProperties<diggrplus_v_get_student_by_id_parameters, T>): Promise<diggrplus_v_get_student_by_id_returns> =>
      this.callWebservice('diggrplus_v_get_student_by_id', params);
  
    /**
     * Get competence statistic for profile
     */
    public diggrplus_v_get_student_grading_tree = async <T extends diggrplus_v_get_student_grading_tree_parameters>(params: NoExtraProperties<diggrplus_v_get_student_grading_tree_parameters, T>): Promise<diggrplus_v_get_student_grading_tree_returns> =>
      this.callWebservice('diggrplus_v_get_student_grading_tree', params);
  
    /**
     * 
     */
    public diggrplus_v_save_student_grading = async <T extends diggrplus_v_save_student_grading_parameters>(params: NoExtraProperties<diggrplus_v_save_student_grading_parameters, T>): Promise<diggrplus_v_save_student_grading_returns> =>
      this.callWebservice('diggrplus_v_save_student_grading', params);
  
    /**
     * 
     */
    public diggrplus_v_print_student_grading_report = async <T extends diggrplus_v_print_student_grading_report_parameters>(params: NoExtraProperties<diggrplus_v_print_student_grading_report_parameters, T>): Promise<diggrplus_v_print_student_grading_report_returns> =>
      this.callWebservice('diggrplus_v_print_student_grading_report', params);
  
    /**
     * 
     */
    public diggrplus_v_get_course_edulevel_schooltype_tree = async <T extends diggrplus_v_get_course_edulevel_schooltype_tree_parameters>(params: NoExtraProperties<diggrplus_v_get_course_edulevel_schooltype_tree_parameters, T>): Promise<diggrplus_v_get_course_edulevel_schooltype_tree_returns> =>
      this.callWebservice('diggrplus_v_get_course_edulevel_schooltype_tree', params);
  
    /**
     * Returns a list of quizzes in a provided list of courses, if no list is provided all quizzes that the user can view will be returned.
     */
    public mod_quiz_get_quizzes_by_courses = async <T extends mod_quiz_get_quizzes_by_courses_parameters>(params: NoExtraProperties<mod_quiz_get_quizzes_by_courses_parameters, T>): Promise<mod_quiz_get_quizzes_by_courses_returns> =>
      this.callWebservice('mod_quiz_get_quizzes_by_courses', params);
  
    /**
     * Trigger the course module viewed event and update the module completion status.
     */
    public mod_quiz_view_quiz = async <T extends mod_quiz_view_quiz_parameters>(params: NoExtraProperties<mod_quiz_view_quiz_parameters, T>): Promise<mod_quiz_view_quiz_returns> =>
      this.callWebservice('mod_quiz_view_quiz', params);
  
    /**
     * Return a list of attempts for the given quiz and user.
     */
    public mod_quiz_get_user_attempts = async <T extends mod_quiz_get_user_attempts_parameters>(params: NoExtraProperties<mod_quiz_get_user_attempts_parameters, T>): Promise<mod_quiz_get_user_attempts_returns> =>
      this.callWebservice('mod_quiz_get_user_attempts', params);
  
    /**
     * Get the best current grade for the given user on a quiz.
     */
    public mod_quiz_get_user_best_grade = async <T extends mod_quiz_get_user_best_grade_parameters>(params: NoExtraProperties<mod_quiz_get_user_best_grade_parameters, T>): Promise<mod_quiz_get_user_best_grade_returns> =>
      this.callWebservice('mod_quiz_get_user_best_grade', params);
  
    /**
     * Combines the review options from a number of different quiz attempts.
     */
    public mod_quiz_get_combined_review_options = async <T extends mod_quiz_get_combined_review_options_parameters>(params: NoExtraProperties<mod_quiz_get_combined_review_options_parameters, T>): Promise<mod_quiz_get_combined_review_options_returns> =>
      this.callWebservice('mod_quiz_get_combined_review_options', params);
  
    /**
     * Starts a new attempt at a quiz.
     */
    public mod_quiz_start_attempt = async <T extends mod_quiz_start_attempt_parameters>(params: NoExtraProperties<mod_quiz_start_attempt_parameters, T>): Promise<mod_quiz_start_attempt_returns> =>
      this.callWebservice('mod_quiz_start_attempt', params);
  
    /**
     * Returns information for the given attempt page for a quiz attempt in progress.
     */
    public mod_quiz_get_attempt_data = async <T extends mod_quiz_get_attempt_data_parameters>(params: NoExtraProperties<mod_quiz_get_attempt_data_parameters, T>): Promise<mod_quiz_get_attempt_data_returns> =>
      this.callWebservice('mod_quiz_get_attempt_data', params);
  
    /**
     * Returns a summary of a quiz attempt before it is submitted.
     */
    public mod_quiz_get_attempt_summary = async <T extends mod_quiz_get_attempt_summary_parameters>(params: NoExtraProperties<mod_quiz_get_attempt_summary_parameters, T>): Promise<mod_quiz_get_attempt_summary_returns> =>
      this.callWebservice('mod_quiz_get_attempt_summary', params);
  
    /**
     * Processes save requests during the quiz. This function is intended for the quiz auto-save feature.
     */
    public mod_quiz_save_attempt = async <T extends mod_quiz_save_attempt_parameters>(params: NoExtraProperties<mod_quiz_save_attempt_parameters, T>): Promise<mod_quiz_save_attempt_returns> =>
      this.callWebservice('mod_quiz_save_attempt', params);
  
    /**
     * Process responses during an attempt at a quiz and also deals with attempts finishing.
     */
    public mod_quiz_process_attempt = async <T extends mod_quiz_process_attempt_parameters>(params: NoExtraProperties<mod_quiz_process_attempt_parameters, T>): Promise<mod_quiz_process_attempt_returns> =>
      this.callWebservice('mod_quiz_process_attempt', params);
  
    /**
     * Returns review information for the given finished attempt, can be used by users or teachers.
     */
    public mod_quiz_get_attempt_review = async <T extends mod_quiz_get_attempt_review_parameters>(params: NoExtraProperties<mod_quiz_get_attempt_review_parameters, T>): Promise<mod_quiz_get_attempt_review_returns> =>
      this.callWebservice('mod_quiz_get_attempt_review', params);
  
    /**
     * Trigger the attempt viewed event.
     */
    public mod_quiz_view_attempt = async <T extends mod_quiz_view_attempt_parameters>(params: NoExtraProperties<mod_quiz_view_attempt_parameters, T>): Promise<mod_quiz_view_attempt_returns> =>
      this.callWebservice('mod_quiz_view_attempt', params);
  
    /**
     * Trigger the attempt summary viewed event.
     */
    public mod_quiz_view_attempt_summary = async <T extends mod_quiz_view_attempt_summary_parameters>(params: NoExtraProperties<mod_quiz_view_attempt_summary_parameters, T>): Promise<mod_quiz_view_attempt_summary_returns> =>
      this.callWebservice('mod_quiz_view_attempt_summary', params);
  
    /**
     * Trigger the attempt reviewed event.
     */
    public mod_quiz_view_attempt_review = async <T extends mod_quiz_view_attempt_review_parameters>(params: NoExtraProperties<mod_quiz_view_attempt_review_parameters, T>): Promise<mod_quiz_view_attempt_review_returns> =>
      this.callWebservice('mod_quiz_view_attempt_review', params);
  
    /**
     * Get the feedback text that should be show to a student who got the given grade in the given quiz.
     */
    public mod_quiz_get_quiz_feedback_for_grade = async <T extends mod_quiz_get_quiz_feedback_for_grade_parameters>(params: NoExtraProperties<mod_quiz_get_quiz_feedback_for_grade_parameters, T>): Promise<mod_quiz_get_quiz_feedback_for_grade_returns> =>
      this.callWebservice('mod_quiz_get_quiz_feedback_for_grade', params);
  
    /**
     * Return access information for a given quiz.
     */
    public mod_quiz_get_quiz_access_information = async <T extends mod_quiz_get_quiz_access_information_parameters>(params: NoExtraProperties<mod_quiz_get_quiz_access_information_parameters, T>): Promise<mod_quiz_get_quiz_access_information_returns> =>
      this.callWebservice('mod_quiz_get_quiz_access_information', params);
  
    /**
     * Return access information for a given attempt in a quiz.
     */
    public mod_quiz_get_attempt_access_information = async <T extends mod_quiz_get_attempt_access_information_parameters>(params: NoExtraProperties<mod_quiz_get_attempt_access_information_parameters, T>): Promise<mod_quiz_get_attempt_access_information_returns> =>
      this.callWebservice('mod_quiz_get_attempt_access_information', params);
  
    /**
     * Return the potential question types that would be required for a given quiz.
     */
    public mod_quiz_get_quiz_required_qtypes = async <T extends mod_quiz_get_quiz_required_qtypes_parameters>(params: NoExtraProperties<mod_quiz_get_quiz_required_qtypes_parameters, T>): Promise<mod_quiz_get_quiz_required_qtypes_returns> =>
      this.callWebservice('mod_quiz_get_quiz_required_qtypes', params);
  
    /**
     * Set the version of question that would be required for a given quiz.
     */
    public mod_quiz_set_question_version = async <T extends mod_quiz_set_question_version_parameters>(params: NoExtraProperties<mod_quiz_set_question_version_parameters, T>): Promise<mod_quiz_set_question_version_returns> =>
      this.callWebservice('mod_quiz_set_question_version', params);
  
    /**
     * Confirm a user account.
     */
    public core_auth_confirm_user = async <T extends core_auth_confirm_user_parameters>(params: NoExtraProperties<core_auth_confirm_user_parameters, T>): Promise<core_auth_confirm_user_returns> =>
      this.callWebservice('core_auth_confirm_user', params);
  
    /**
     * Requests a password reset.
     */
    public core_auth_request_password_reset = async <T extends core_auth_request_password_reset_parameters>(params: NoExtraProperties<core_auth_request_password_reset_parameters, T>): Promise<core_auth_request_password_reset_returns> =>
      this.callWebservice('core_auth_request_password_reset', params);
  
    /**
     * Requests a check if a user is a digital minor.
     */
    public core_auth_is_minor = async <T extends core_auth_is_minor_parameters>(params: NoExtraProperties<core_auth_is_minor_parameters, T>): Promise<core_auth_is_minor_returns> =>
      this.callWebservice('core_auth_is_minor', params);
  
    /**
     * Checks if age digital consent verification is enabled.
     */
    public core_auth_is_age_digital_consent_verification_enabled = async <T extends core_auth_is_age_digital_consent_verification_enabled_parameters>(params?: NoExtraProperties<core_auth_is_age_digital_consent_verification_enabled_parameters, T>): Promise<core_auth_is_age_digital_consent_verification_enabled_returns> =>
      this.callWebservice('core_auth_is_age_digital_consent_verification_enabled', params);
  
    /**
     * Resend confirmation email.
     */
    public core_auth_resend_confirmation_email = async <T extends core_auth_resend_confirmation_email_parameters>(params: NoExtraProperties<core_auth_resend_confirmation_email_parameters, T>): Promise<core_auth_resend_confirmation_email_returns> =>
      this.callWebservice('core_auth_resend_confirmation_email', params);
  
    /**
     * Get the progress of an Asyncronhous backup.
     */
    public core_backup_get_async_backup_progress = async <T extends core_backup_get_async_backup_progress_parameters>(params: NoExtraProperties<core_backup_get_async_backup_progress_parameters, T>): Promise<core_backup_get_async_backup_progress_returns_item[]> =>
      this.callWebservice('core_backup_get_async_backup_progress', params);
  
    /**
     * Gets the data to use when updating the status table row in the UI for when an async backup completes.
     */
    public core_backup_get_async_backup_links_backup = async <T extends core_backup_get_async_backup_links_backup_parameters>(params: NoExtraProperties<core_backup_get_async_backup_links_backup_parameters, T>): Promise<core_backup_get_async_backup_links_backup_returns> =>
      this.callWebservice('core_backup_get_async_backup_links_backup', params);
  
    /**
     * Gets the data to use when updating the status table row in the UI for when an async restore completes.
     */
    public core_backup_get_async_backup_links_restore = async <T extends core_backup_get_async_backup_links_restore_parameters>(params: NoExtraProperties<core_backup_get_async_backup_links_restore_parameters, T>): Promise<core_backup_get_async_backup_links_restore_returns> =>
      this.callWebservice('core_backup_get_async_backup_links_restore', params);
  
    /**
     * Gets the progress of course copy operations.
     */
    public core_backup_get_copy_progress = async <T extends core_backup_get_copy_progress_parameters>(params: NoExtraProperties<core_backup_get_copy_progress_parameters, T>): Promise<core_backup_get_copy_progress_returns_item[]> =>
      this.callWebservice('core_backup_get_copy_progress', params);
  
    /**
     * Handles ajax submission of course copy form.
     */
    public core_backup_submit_copy_form = async <T extends core_backup_submit_copy_form_parameters>(params: NoExtraProperties<core_backup_submit_copy_form_parameters, T>): Promise<core_backup_submit_copy_form_returns> =>
      this.callWebservice('core_backup_submit_copy_form', params);
  
    /**
     * Returns the list of badges awarded to a user.
     */
    public core_badges_get_user_badges = async <T extends core_badges_get_user_badges_parameters>(params: NoExtraProperties<core_badges_get_user_badges_parameters, T>): Promise<core_badges_get_user_badges_returns> =>
      this.callWebservice('core_badges_get_user_badges', params);
  
    /**
     * Returns blog entries.
     */
    public core_blog_get_entries = async <T extends core_blog_get_entries_parameters>(params: NoExtraProperties<core_blog_get_entries_parameters, T>): Promise<core_blog_get_entries_returns> =>
      this.callWebservice('core_blog_get_entries', params);
  
    /**
     * Trigger the blog_entries_viewed event.
     */
    public core_blog_view_entries = async <T extends core_blog_view_entries_parameters>(params: NoExtraProperties<core_blog_view_entries_parameters, T>): Promise<core_blog_view_entries_returns> =>
      this.callWebservice('core_blog_view_entries', params);
  
    /**
     * Fetch the monthly view data for a calendar
     */
    public core_calendar_get_calendar_monthly_view = async <T extends core_calendar_get_calendar_monthly_view_parameters>(params: NoExtraProperties<core_calendar_get_calendar_monthly_view_parameters, T>): Promise<core_calendar_get_calendar_monthly_view_returns> =>
      this.callWebservice('core_calendar_get_calendar_monthly_view', params);
  
    /**
     * Fetch the day view data for a calendar
     */
    public core_calendar_get_calendar_day_view = async <T extends core_calendar_get_calendar_day_view_parameters>(params: NoExtraProperties<core_calendar_get_calendar_day_view_parameters, T>): Promise<core_calendar_get_calendar_day_view_returns> =>
      this.callWebservice('core_calendar_get_calendar_day_view', params);
  
    /**
     * Fetch the upcoming view data for a calendar
     */
    public core_calendar_get_calendar_upcoming_view = async <T extends core_calendar_get_calendar_upcoming_view_parameters>(params: NoExtraProperties<core_calendar_get_calendar_upcoming_view_parameters, T>): Promise<core_calendar_get_calendar_upcoming_view_returns> =>
      this.callWebservice('core_calendar_get_calendar_upcoming_view', params);
  
    /**
     * Update the start day (but not time) for an event.
     */
    public core_calendar_update_event_start_day = async <T extends core_calendar_update_event_start_day_parameters>(params: NoExtraProperties<core_calendar_update_event_start_day_parameters, T>): Promise<core_calendar_update_event_start_day_returns> =>
      this.callWebservice('core_calendar_update_event_start_day', params);
  
    /**
     * Create calendar events
     */
    public core_calendar_create_calendar_events = async <T extends core_calendar_create_calendar_events_parameters>(params: NoExtraProperties<core_calendar_create_calendar_events_parameters, T>): Promise<core_calendar_create_calendar_events_returns> =>
      this.callWebservice('core_calendar_create_calendar_events', params);
  
    /**
     * Delete calendar events
     */
    public core_calendar_delete_calendar_events = async <T extends core_calendar_delete_calendar_events_parameters>(params: NoExtraProperties<core_calendar_delete_calendar_events_parameters, T>): Promise<core_calendar_delete_calendar_events_returns> =>
      this.callWebservice('core_calendar_delete_calendar_events', params);
  
    /**
     * Get calendar events
     */
    public core_calendar_get_calendar_events = async <T extends core_calendar_get_calendar_events_parameters>(params: NoExtraProperties<core_calendar_get_calendar_events_parameters, T>): Promise<core_calendar_get_calendar_events_returns> =>
      this.callWebservice('core_calendar_get_calendar_events', params);
  
    /**
     * Get calendar action events by tiemsort
     */
    public core_calendar_get_action_events_by_timesort = async <T extends core_calendar_get_action_events_by_timesort_parameters>(params: NoExtraProperties<core_calendar_get_action_events_by_timesort_parameters, T>): Promise<core_calendar_get_action_events_by_timesort_returns> =>
      this.callWebservice('core_calendar_get_action_events_by_timesort', params);
  
    /**
     * Get calendar action events by course
     */
    public core_calendar_get_action_events_by_course = async <T extends core_calendar_get_action_events_by_course_parameters>(params: NoExtraProperties<core_calendar_get_action_events_by_course_parameters, T>): Promise<core_calendar_get_action_events_by_course_returns> =>
      this.callWebservice('core_calendar_get_action_events_by_course', params);
  
    /**
     * Get calendar action events by courses
     */
    public core_calendar_get_action_events_by_courses = async <T extends core_calendar_get_action_events_by_courses_parameters>(params: NoExtraProperties<core_calendar_get_action_events_by_courses_parameters, T>): Promise<core_calendar_get_action_events_by_courses_returns> =>
      this.callWebservice('core_calendar_get_action_events_by_courses', params);
  
    /**
     * Get calendar event by id
     */
    public core_calendar_get_calendar_event_by_id = async <T extends core_calendar_get_calendar_event_by_id_parameters>(params: NoExtraProperties<core_calendar_get_calendar_event_by_id_parameters, T>): Promise<core_calendar_get_calendar_event_by_id_returns> =>
      this.callWebservice('core_calendar_get_calendar_event_by_id', params);
  
    /**
     * Submit form data for event form
     */
    public core_calendar_submit_create_update_form = async <T extends core_calendar_submit_create_update_form_parameters>(params: NoExtraProperties<core_calendar_submit_create_update_form_parameters, T>): Promise<core_calendar_submit_create_update_form_returns> =>
      this.callWebservice('core_calendar_submit_create_update_form', params);
  
    /**
     * Convenience function to retrieve some permissions/access information for the given course calendar.
     */
    public core_calendar_get_calendar_access_information = async <T extends core_calendar_get_calendar_access_information_parameters>(params: NoExtraProperties<core_calendar_get_calendar_access_information_parameters, T>): Promise<core_calendar_get_calendar_access_information_returns> =>
      this.callWebservice('core_calendar_get_calendar_access_information', params);
  
    /**
     * Get the type of events a user can create in the given course.
     */
    public core_calendar_get_allowed_event_types = async <T extends core_calendar_get_allowed_event_types_parameters>(params: NoExtraProperties<core_calendar_get_allowed_event_types_parameters, T>): Promise<core_calendar_get_allowed_event_types_returns> =>
      this.callWebservice('core_calendar_get_allowed_event_types', params);
  
    /**
     * Fetch unix timestamps for given date times.
     */
    public core_calendar_get_timestamps = async <T extends core_calendar_get_timestamps_parameters>(params: NoExtraProperties<core_calendar_get_timestamps_parameters, T>): Promise<core_calendar_get_timestamps_returns> =>
      this.callWebservice('core_calendar_get_timestamps', params);
  
    /**
     * Return the auth token required for exporting a calendar.
     */
    public core_calendar_get_calendar_export_token = async <T extends core_calendar_get_calendar_export_token_parameters>(params?: NoExtraProperties<core_calendar_get_calendar_export_token_parameters, T>): Promise<core_calendar_get_calendar_export_token_returns> =>
      this.callWebservice('core_calendar_get_calendar_export_token', params);
  
    /**
     * Delete the calendar subscription
     */
    public core_calendar_delete_subscription = async <T extends core_calendar_delete_subscription_parameters>(params: NoExtraProperties<core_calendar_delete_subscription_parameters, T>): Promise<core_calendar_delete_subscription_returns> =>
      this.callWebservice('core_calendar_delete_subscription', params);
  
    /**
     * Adds cohort members.
     */
    public core_cohort_add_cohort_members = async <T extends core_cohort_add_cohort_members_parameters>(params: NoExtraProperties<core_cohort_add_cohort_members_parameters, T>): Promise<core_cohort_add_cohort_members_returns> =>
      this.callWebservice('core_cohort_add_cohort_members', params);
  
    /**
     * Creates new cohorts.
     */
    public core_cohort_create_cohorts = async <T extends core_cohort_create_cohorts_parameters>(params: NoExtraProperties<core_cohort_create_cohorts_parameters, T>): Promise<core_cohort_create_cohorts_returns_item[]> =>
      this.callWebservice('core_cohort_create_cohorts', params);
  
    /**
     * Deletes cohort members.
     */
    public core_cohort_delete_cohort_members = async <T extends core_cohort_delete_cohort_members_parameters>(params: NoExtraProperties<core_cohort_delete_cohort_members_parameters, T>): Promise<core_cohort_delete_cohort_members_returns> =>
      this.callWebservice('core_cohort_delete_cohort_members', params);
  
    /**
     * Deletes all specified cohorts.
     */
    public core_cohort_delete_cohorts = async <T extends core_cohort_delete_cohorts_parameters>(params: NoExtraProperties<core_cohort_delete_cohorts_parameters, T>): Promise<core_cohort_delete_cohorts_returns> =>
      this.callWebservice('core_cohort_delete_cohorts', params);
  
    /**
     * Returns cohort members.
     */
    public core_cohort_get_cohort_members = async <T extends core_cohort_get_cohort_members_parameters>(params: NoExtraProperties<core_cohort_get_cohort_members_parameters, T>): Promise<core_cohort_get_cohort_members_returns_item[]> =>
      this.callWebservice('core_cohort_get_cohort_members', params);
  
    /**
     * Search for cohorts.
     */
    public core_cohort_search_cohorts = async <T extends core_cohort_search_cohorts_parameters>(params: NoExtraProperties<core_cohort_search_cohorts_parameters, T>): Promise<core_cohort_search_cohorts_returns> =>
      this.callWebservice('core_cohort_search_cohorts', params);
  
    /**
     * Returns cohort details.
     */
    public core_cohort_get_cohorts = async <T extends core_cohort_get_cohorts_parameters>(params: NoExtraProperties<core_cohort_get_cohorts_parameters, T>): Promise<core_cohort_get_cohorts_returns_item[]> =>
      this.callWebservice('core_cohort_get_cohorts', params);
  
    /**
     * Updates existing cohorts.
     */
    public core_cohort_update_cohorts = async <T extends core_cohort_update_cohorts_parameters>(params: NoExtraProperties<core_cohort_update_cohorts_parameters, T>): Promise<core_cohort_update_cohorts_returns> =>
      this.callWebservice('core_cohort_update_cohorts', params);
  
    /**
     * Returns comments.
     */
    public core_comment_get_comments = async <T extends core_comment_get_comments_parameters>(params: NoExtraProperties<core_comment_get_comments_parameters, T>): Promise<core_comment_get_comments_returns> =>
      this.callWebservice('core_comment_get_comments', params);
  
    /**
     * Adds a comment or comments.
     */
    public core_comment_add_comments = async <T extends core_comment_add_comments_parameters>(params: NoExtraProperties<core_comment_add_comments_parameters, T>): Promise<core_comment_add_comments_returns_item[]> =>
      this.callWebservice('core_comment_add_comments', params);
  
    /**
     * Deletes a comment or comments.
     */
    public core_comment_delete_comments = async <T extends core_comment_delete_comments_parameters>(params: NoExtraProperties<core_comment_delete_comments_parameters, T>): Promise<core_comment_delete_comments_returns_item[]> =>
      this.callWebservice('core_comment_delete_comments', params);
  
    /**
     * Return the activities completion status for a user in a course.
     */
    public core_completion_get_activities_completion_status = async <T extends core_completion_get_activities_completion_status_parameters>(params: NoExtraProperties<core_completion_get_activities_completion_status_parameters, T>): Promise<core_completion_get_activities_completion_status_returns> =>
      this.callWebservice('core_completion_get_activities_completion_status', params);
  
    /**
     * Returns course completion status.
     */
    public core_completion_get_course_completion_status = async <T extends core_completion_get_course_completion_status_parameters>(params: NoExtraProperties<core_completion_get_course_completion_status_parameters, T>): Promise<core_completion_get_course_completion_status_returns> =>
      this.callWebservice('core_completion_get_course_completion_status', params);
  
    /**
     * Update the course completion status for the current user (if course self-completion is enabled).
     */
    public core_completion_mark_course_self_completed = async <T extends core_completion_mark_course_self_completed_parameters>(params: NoExtraProperties<core_completion_mark_course_self_completed_parameters, T>): Promise<core_completion_mark_course_self_completed_returns> =>
      this.callWebservice('core_completion_mark_course_self_completed', params);
  
    /**
     * Update completion status for the current user in an activity, only for activities with manual tracking.
     */
    public core_completion_update_activity_completion_status_manually = async <T extends core_completion_update_activity_completion_status_manually_parameters>(params: NoExtraProperties<core_completion_update_activity_completion_status_manually_parameters, T>): Promise<core_completion_update_activity_completion_status_manually_returns> =>
      this.callWebservice('core_completion_update_activity_completion_status_manually', params);
  
    /**
     * Update completion status for a user in an activity by overriding it.
     */
    public core_completion_override_activity_completion_status = async <T extends core_completion_override_activity_completion_status_parameters>(params: NoExtraProperties<core_completion_override_activity_completion_status_parameters, T>): Promise<core_completion_override_activity_completion_status_returns> =>
      this.callWebservice('core_completion_override_activity_completion_status', params);
  
    /**
     * Create course categories
     */
    public core_course_create_categories = async <T extends core_course_create_categories_parameters>(params: NoExtraProperties<core_course_create_categories_parameters, T>): Promise<core_course_create_categories_returns_item[]> =>
      this.callWebservice('core_course_create_categories', params);
  
    /**
     * Create new courses
     */
    public core_course_create_courses = async <T extends core_course_create_courses_parameters>(params: NoExtraProperties<core_course_create_courses_parameters, T>): Promise<core_course_create_courses_returns_item[]> =>
      this.callWebservice('core_course_create_courses', params);
  
    /**
     * Delete course categories
     */
    public core_course_delete_categories = async <T extends core_course_delete_categories_parameters>(params: NoExtraProperties<core_course_delete_categories_parameters, T>): Promise<core_course_delete_categories_returns> =>
      this.callWebservice('core_course_delete_categories', params);
  
    /**
     * Deletes all specified courses
     */
    public core_course_delete_courses = async <T extends core_course_delete_courses_parameters>(params: NoExtraProperties<core_course_delete_courses_parameters, T>): Promise<core_course_delete_courses_returns> =>
      this.callWebservice('core_course_delete_courses', params);
  
    /**
     * Deletes all specified module instances
     */
    public core_course_delete_modules = async <T extends core_course_delete_modules_parameters>(params: NoExtraProperties<core_course_delete_modules_parameters, T>): Promise<core_course_delete_modules_returns> =>
      this.callWebservice('core_course_delete_modules', params);
  
    /**
     * Duplicate an existing course (creating a new one).
     */
    public core_course_duplicate_course = async <T extends core_course_duplicate_course_parameters>(params: NoExtraProperties<core_course_duplicate_course_parameters, T>): Promise<core_course_duplicate_course_returns> =>
      this.callWebservice('core_course_duplicate_course', params);
  
    /**
     * Return category details
     */
    public core_course_get_categories = async <T extends core_course_get_categories_parameters>(params: NoExtraProperties<core_course_get_categories_parameters, T>): Promise<core_course_get_categories_returns_item[]> =>
      this.callWebservice('core_course_get_categories', params);
  
    /**
     * Get course contents
     */
    public core_course_get_contents = async <T extends core_course_get_contents_parameters>(params: NoExtraProperties<core_course_get_contents_parameters, T>): Promise<core_course_get_contents_returns_item[]> =>
      this.callWebservice('core_course_get_contents', params);
  
    /**
     * Return information about a course module
     */
    public core_course_get_course_module = async <T extends core_course_get_course_module_parameters>(params: NoExtraProperties<core_course_get_course_module_parameters, T>): Promise<core_course_get_course_module_returns> =>
      this.callWebservice('core_course_get_course_module', params);
  
    /**
     * Return information about a given module name and instance id
     */
    public core_course_get_course_module_by_instance = async <T extends core_course_get_course_module_by_instance_parameters>(params: NoExtraProperties<core_course_get_course_module_by_instance_parameters, T>): Promise<core_course_get_course_module_by_instance_returns> =>
      this.callWebservice('core_course_get_course_module_by_instance', params);
  
    /**
     * Returns html with one activity module on course page
     */
    public core_course_get_module = async <T extends core_course_get_module_parameters>(params: NoExtraProperties<core_course_get_module_parameters, T>): Promise<core_course_get_module_returns> =>
      this.callWebservice('core_course_get_module', params);
  
    /**
     * Get the current course state.
     */
    public core_courseformat_get_state = async <T extends core_courseformat_get_state_parameters>(params: NoExtraProperties<core_courseformat_get_state_parameters, T>): Promise<core_courseformat_get_state_returns> =>
      this.callWebservice('core_courseformat_get_state', params);
  
    /**
     * Update course contents.
     */
    public core_courseformat_update_course = async <T extends core_courseformat_update_course_parameters>(params: NoExtraProperties<core_courseformat_update_course_parameters, T>): Promise<core_courseformat_update_course_returns> =>
      this.callWebservice('core_courseformat_update_course', params);
  
    /**
     * Performs an action on course module (change visibility, duplicate, delete, etc.)
     */
    public core_course_edit_module = async <T extends core_course_edit_module_parameters>(params: NoExtraProperties<core_course_edit_module_parameters, T>): Promise<core_course_edit_module_returns> =>
      this.callWebservice('core_course_edit_module', params);
  
    /**
     * Performs an action on course section (change visibility, set marker, delete)
     */
    public core_course_edit_section = async <T extends core_course_edit_section_parameters>(params: NoExtraProperties<core_course_edit_section_parameters, T>): Promise<core_course_edit_section_returns> =>
      this.callWebservice('core_course_edit_section', params);
  
    /**
     * Return course details
     */
    public core_course_get_courses = async <T extends core_course_get_courses_parameters>(params: NoExtraProperties<core_course_get_courses_parameters, T>): Promise<core_course_get_courses_returns_item[]> =>
      this.callWebservice('core_course_get_courses', params);
  
    /**
     * Import course data from a course into another course. Does not include any user data.
     */
    public core_course_import_course = async <T extends core_course_import_course_parameters>(params: NoExtraProperties<core_course_import_course_parameters, T>): Promise<core_course_import_course_returns> =>
      this.callWebservice('core_course_import_course', params);
  
    /**
     * Search courses by (name, module, block, tag)
     */
    public core_course_search_courses = async <T extends core_course_search_courses_parameters>(params: NoExtraProperties<core_course_search_courses_parameters, T>): Promise<core_course_search_courses_returns> =>
      this.callWebservice('core_course_search_courses', params);
  
    /**
     * Update categories
     */
    public core_course_update_categories = async <T extends core_course_update_categories_parameters>(params: NoExtraProperties<core_course_update_categories_parameters, T>): Promise<core_course_update_categories_returns> =>
      this.callWebservice('core_course_update_categories', params);
  
    /**
     * Update courses
     */
    public core_course_update_courses = async <T extends core_course_update_courses_parameters>(params: NoExtraProperties<core_course_update_courses_parameters, T>): Promise<core_course_update_courses_returns> =>
      this.callWebservice('core_course_update_courses', params);
  
    /**
     * Log that the course was viewed
     */
    public core_course_view_course = async <T extends core_course_view_course_parameters>(params: NoExtraProperties<core_course_view_course_parameters, T>): Promise<core_course_view_course_returns> =>
      this.callWebservice('core_course_view_course', params);
  
    /**
     * Return a list of navigation options in a set of courses that are avaialable or not for the current user.
     */
    public core_course_get_user_navigation_options = async <T extends core_course_get_user_navigation_options_parameters>(params: NoExtraProperties<core_course_get_user_navigation_options_parameters, T>): Promise<core_course_get_user_navigation_options_returns> =>
      this.callWebservice('core_course_get_user_navigation_options', params);
  
    /**
     * Return a list of administration options in a set of courses that are avaialable or not for the current user.
     */
    public core_course_get_user_administration_options = async <T extends core_course_get_user_administration_options_parameters>(params: NoExtraProperties<core_course_get_user_administration_options_parameters, T>): Promise<core_course_get_user_administration_options_returns> =>
      this.callWebservice('core_course_get_user_administration_options', params);
  
    /**
     * Get courses matching a specific field (id/s, shortname, idnumber, category)
     */
    public core_course_get_courses_by_field = async <T extends core_course_get_courses_by_field_parameters>(params: NoExtraProperties<core_course_get_courses_by_field_parameters, T>): Promise<core_course_get_courses_by_field_returns> =>
      this.callWebservice('core_course_get_courses_by_field', params);
  
    /**
     * Check if there is updates affecting the user for the given course and contexts.
     */
    public core_course_check_updates = async <T extends core_course_check_updates_parameters>(params: NoExtraProperties<core_course_check_updates_parameters, T>): Promise<core_course_check_updates_returns> =>
      this.callWebservice('core_course_check_updates', params);
  
    /**
     * Check if there are updates affecting the user for the given course since the given time stamp.
     */
    public core_course_get_updates_since = async <T extends core_course_get_updates_since_parameters>(params: NoExtraProperties<core_course_get_updates_since_parameters, T>): Promise<core_course_get_updates_since_returns> =>
      this.callWebservice('core_course_get_updates_since', params);
  
    /**
     * List of enrolled courses for the given timeline classification (past, inprogress, or future).
     */
    public core_course_get_enrolled_courses_by_timeline_classification = async <T extends core_course_get_enrolled_courses_by_timeline_classification_parameters>(params: NoExtraProperties<core_course_get_enrolled_courses_by_timeline_classification_parameters, T>): Promise<core_course_get_enrolled_courses_by_timeline_classification_returns> =>
      this.callWebservice('core_course_get_enrolled_courses_by_timeline_classification', params);
  
    /**
     * List of enrolled courses with action events in a given timeframe, for the given timeline classification.
     */
    public core_course_get_enrolled_courses_with_action_events_by_timeline_classification = async <T extends core_course_get_enrolled_courses_with_action_events_by_timeline_classification_parameters>(params: NoExtraProperties<core_course_get_enrolled_courses_with_action_events_by_timeline_classification_parameters, T>): Promise<core_course_get_enrolled_courses_with_action_events_by_timeline_classification_returns> =>
      this.callWebservice('core_course_get_enrolled_courses_with_action_events_by_timeline_classification', params);
  
    /**
     * List of courses a user has accessed most recently.
     */
    public core_course_get_recent_courses = async <T extends core_course_get_recent_courses_parameters>(params: NoExtraProperties<core_course_get_recent_courses_parameters, T>): Promise<core_course_get_recent_courses_returns_item[]> =>
      this.callWebservice('core_course_get_recent_courses', params);
  
    /**
     * Add a list of courses to the list of favourite courses.
     */
    public core_course_set_favourite_courses = async <T extends core_course_set_favourite_courses_parameters>(params: NoExtraProperties<core_course_set_favourite_courses_parameters, T>): Promise<core_course_set_favourite_courses_returns> =>
      this.callWebservice('core_course_set_favourite_courses', params);
  
    /**
     * List users by course module id, filter by group and active enrolment status.
     */
    public core_course_get_enrolled_users_by_cmid = async <T extends core_course_get_enrolled_users_by_cmid_parameters>(params: NoExtraProperties<core_course_get_enrolled_users_by_cmid_parameters, T>): Promise<core_course_get_enrolled_users_by_cmid_returns> =>
      this.callWebservice('core_course_get_enrolled_users_by_cmid', params);
  
    /**
     * Adds a content item (activity, resource or their subtypes) to the favourites for the user.
     */
    public core_course_add_content_item_to_user_favourites = async <T extends core_course_add_content_item_to_user_favourites_parameters>(params: NoExtraProperties<core_course_add_content_item_to_user_favourites_parameters, T>): Promise<core_course_add_content_item_to_user_favourites_returns> =>
      this.callWebservice('core_course_add_content_item_to_user_favourites', params);
  
    /**
     * Removes a content item (activity, resource or their subtypes) from the favourites for the user.
     */
    public core_course_remove_content_item_from_user_favourites = async <T extends core_course_remove_content_item_from_user_favourites_parameters>(params: NoExtraProperties<core_course_remove_content_item_from_user_favourites_parameters, T>): Promise<core_course_remove_content_item_from_user_favourites_returns> =>
      this.callWebservice('core_course_remove_content_item_from_user_favourites', params);
  
    /**
     * Fetch all the content items (activities, resources and their subtypes) for the activity picker
     */
    public core_course_get_course_content_items = async <T extends core_course_get_course_content_items_parameters>(params: NoExtraProperties<core_course_get_course_content_items_parameters, T>): Promise<core_course_get_course_content_items_returns> =>
      this.callWebservice('core_course_get_course_content_items', params);
  
    /**
     * Fetch the data for the activity chooser footer.
     */
    public core_course_get_activity_chooser_footer = async <T extends core_course_get_activity_chooser_footer_parameters>(params: NoExtraProperties<core_course_get_activity_chooser_footer_parameters, T>): Promise<core_course_get_activity_chooser_footer_returns> =>
      this.callWebservice('core_course_get_activity_chooser_footer', params);
  
    /**
     * Adds or removes an activity as a recommendation in the activity chooser.
     */
    public core_course_toggle_activity_recommendation = async <T extends core_course_toggle_activity_recommendation_parameters>(params: NoExtraProperties<core_course_toggle_activity_recommendation_parameters, T>): Promise<core_course_toggle_activity_recommendation_returns> =>
      this.callWebservice('core_course_toggle_activity_recommendation', params);
  
    /**
     * Get the list of course enrolment methods
     */
    public core_enrol_get_course_enrolment_methods = async <T extends core_enrol_get_course_enrolment_methods_parameters>(params: NoExtraProperties<core_enrol_get_course_enrolment_methods_parameters, T>): Promise<core_enrol_get_course_enrolment_methods_returns_item[]> =>
      this.callWebservice('core_enrol_get_course_enrolment_methods', params);
  
    /**
     * Get enrolled users by course id.
     */
    public core_enrol_get_enrolled_users = async <T extends core_enrol_get_enrolled_users_parameters>(params: NoExtraProperties<core_enrol_get_enrolled_users_parameters, T>): Promise<core_enrol_get_enrolled_users_returns_item[]> =>
      this.callWebservice('core_enrol_get_enrolled_users', params);
  
    /**
     * For each course and capability specified, return a list of the users that are enrolled in the course and have that capability
     */
    public core_enrol_get_enrolled_users_with_capability = async <T extends core_enrol_get_enrolled_users_with_capability_parameters>(params: NoExtraProperties<core_enrol_get_enrolled_users_with_capability_parameters, T>): Promise<core_enrol_get_enrolled_users_with_capability_returns_item[]> =>
      this.callWebservice('core_enrol_get_enrolled_users_with_capability', params);
  
    /**
     * Get the list of potential users to enrol
     */
    public core_enrol_get_potential_users = async <T extends core_enrol_get_potential_users_parameters>(params: NoExtraProperties<core_enrol_get_potential_users_parameters, T>): Promise<core_enrol_get_potential_users_returns_item[]> =>
      this.callWebservice('core_enrol_get_potential_users', params);
  
    /**
     * Search within the list of course participants
     */
    public core_enrol_search_users = async <T extends core_enrol_search_users_parameters>(params: NoExtraProperties<core_enrol_search_users_parameters, T>): Promise<core_enrol_search_users_returns_item[]> =>
      this.callWebservice('core_enrol_search_users', params);
  
    /**
     * Get the list of courses where a user is enrolled in
     */
    public core_enrol_get_users_courses = async <T extends core_enrol_get_users_courses_parameters>(params: NoExtraProperties<core_enrol_get_users_courses_parameters, T>): Promise<core_enrol_get_users_courses_returns_item[]> =>
      this.callWebservice('core_enrol_get_users_courses', params);
  
    /**
     * Submit form data for enrolment form
     */
    public core_enrol_submit_user_enrolment_form = async <T extends core_enrol_submit_user_enrolment_form_parameters>(params: NoExtraProperties<core_enrol_submit_user_enrolment_form_parameters, T>): Promise<core_enrol_submit_user_enrolment_form_returns> =>
      this.callWebservice('core_enrol_submit_user_enrolment_form', params);
  
    /**
     * External function that unenrols a given user enrolment
     */
    public core_enrol_unenrol_user_enrolment = async <T extends core_enrol_unenrol_user_enrolment_parameters>(params: NoExtraProperties<core_enrol_unenrol_user_enrolment_parameters, T>): Promise<core_enrol_unenrol_user_enrolment_returns> =>
      this.callWebservice('core_enrol_unenrol_user_enrolment', params);
  
    /**
     * Return a list of notifications for the current session
     */
    public core_fetch_notifications = async <T extends core_fetch_notifications_parameters>(params: NoExtraProperties<core_fetch_notifications_parameters, T>): Promise<core_fetch_notifications_returns_item[]> =>
      this.callWebservice('core_fetch_notifications', params);
  
    /**
     * Keep the users session alive
     */
    public core_session_touch = async <T extends core_session_touch_parameters>(params?: NoExtraProperties<core_session_touch_parameters, T>): Promise<core_session_touch_returns> =>
      this.callWebservice('core_session_touch', params);
  
    /**
     * Count the seconds remaining in this session
     */
    public core_session_time_remaining = async <T extends core_session_time_remaining_parameters>(params?: NoExtraProperties<core_session_time_remaining_parameters, T>): Promise<core_session_time_remaining_returns> =>
      this.callWebservice('core_session_time_remaining', params);
  
    /**
     * browse moodle files
     */
    public core_files_get_files = async <T extends core_files_get_files_parameters>(params: NoExtraProperties<core_files_get_files_parameters, T>): Promise<core_files_get_files_returns> =>
      this.callWebservice('core_files_get_files', params);
  
    /**
     * upload a file to moodle
     */
    public core_files_upload = async <T extends core_files_upload_parameters>(params: NoExtraProperties<core_files_upload_parameters, T>): Promise<core_files_upload_returns> =>
      this.callWebservice('core_files_upload', params);
  
    /**
     * Delete the indicated files (or directories) from a user draft file area.
     */
    public core_files_delete_draft_files = async <T extends core_files_delete_draft_files_parameters>(params: NoExtraProperties<core_files_delete_draft_files_parameters, T>): Promise<core_files_delete_draft_files_returns> =>
      this.callWebservice('core_files_delete_draft_files', params);
  
    /**
     * Generate a new draft itemid for the current user.
     */
    public core_files_get_unused_draft_itemid = async <T extends core_files_get_unused_draft_itemid_parameters>(params?: NoExtraProperties<core_files_get_unused_draft_itemid_parameters, T>): Promise<core_files_get_unused_draft_itemid_returns> =>
      this.callWebservice('core_files_get_unused_draft_itemid', params);
  
    /**
     * Provides data for the filetypes element browser.
     */
    public core_form_get_filetypes_browser_data = async <T extends core_form_get_filetypes_browser_data_parameters>(params: NoExtraProperties<core_form_get_filetypes_browser_data_parameters, T>): Promise<core_form_get_filetypes_browser_data_returns> =>
      this.callWebservice('core_form_get_filetypes_browser_data', params);
  
    /**
     * Process submission of a dynamic (modal) form
     */
    public core_form_dynamic_form = async <T extends core_form_dynamic_form_parameters>(params: NoExtraProperties<core_form_dynamic_form_parameters, T>): Promise<core_form_dynamic_form_returns> =>
      this.callWebservice('core_form_dynamic_form', params);
  
    /**
     * Return all raw strings (with {$a->xxx}), for a specific component - similar to core get_component_strings(), call
     */
    public core_get_component_strings = async <T extends core_get_component_strings_parameters>(params: NoExtraProperties<core_get_component_strings_parameters, T>): Promise<core_get_component_strings_returns_item[]> =>
      this.callWebservice('core_get_component_strings', params);
  
    /**
     * Return a fragment for inclusion, such as a JavaScript page.
     */
    public core_get_fragment = async <T extends core_get_fragment_parameters>(params: NoExtraProperties<core_get_fragment_parameters, T>): Promise<core_get_fragment_returns> =>
      this.callWebservice('core_get_fragment', params);
  
    /**
     * Return a translated string - similar to core get_string(), call
     */
    public core_get_string = async <T extends core_get_string_parameters>(params: NoExtraProperties<core_get_string_parameters, T>): Promise<core_get_string_returns> =>
      this.callWebservice('core_get_string', params);
  
    /**
     * Return some translated strings - like several core get_string(), calls
     */
    public core_get_strings = async <T extends core_get_strings_parameters>(params: NoExtraProperties<core_get_strings_parameters, T>): Promise<core_get_strings_returns_item[]> =>
      this.callWebservice('core_get_strings', params);
  
    /**
     * Return formatted timestamps
     */
    public core_get_user_dates = async <T extends core_get_user_dates_parameters>(params: NoExtraProperties<core_get_user_dates_parameters, T>): Promise<core_get_user_dates_returns> =>
      this.callWebservice('core_get_user_dates', params);
  
    /**
     * Update a grade item and associated student grades.
     */
    public core_grades_update_grades = async <T extends core_grades_update_grades_parameters>(params: NoExtraProperties<core_grades_update_grades_parameters, T>): Promise<core_grades_update_grades_returns> =>
      this.callWebservice('core_grades_update_grades', params);
  
    /**
     * Fetch the data required to display the grader grading panel for simple grading, creating the grade item if required
     */
    public core_grades_grader_gradingpanel_point_fetch = async <T extends core_grades_grader_gradingpanel_point_fetch_parameters>(params: NoExtraProperties<core_grades_grader_gradingpanel_point_fetch_parameters, T>): Promise<core_grades_grader_gradingpanel_point_fetch_returns> =>
      this.callWebservice('core_grades_grader_gradingpanel_point_fetch', params);
  
    /**
     * Store the data required to display the grader grading panel for simple grading
     */
    public core_grades_grader_gradingpanel_point_store = async <T extends core_grades_grader_gradingpanel_point_store_parameters>(params: NoExtraProperties<core_grades_grader_gradingpanel_point_store_parameters, T>): Promise<core_grades_grader_gradingpanel_point_store_returns> =>
      this.callWebservice('core_grades_grader_gradingpanel_point_store', params);
  
    /**
     * Fetch the data required to display the grader grading panel for scale-based grading, creating the grade item if required
     */
    public core_grades_grader_gradingpanel_scale_fetch = async <T extends core_grades_grader_gradingpanel_scale_fetch_parameters>(params: NoExtraProperties<core_grades_grader_gradingpanel_scale_fetch_parameters, T>): Promise<core_grades_grader_gradingpanel_scale_fetch_returns> =>
      this.callWebservice('core_grades_grader_gradingpanel_scale_fetch', params);
  
    /**
     * Store the data required to display the grader grading panel for scale-based grading
     */
    public core_grades_grader_gradingpanel_scale_store = async <T extends core_grades_grader_gradingpanel_scale_store_parameters>(params: NoExtraProperties<core_grades_grader_gradingpanel_scale_store_parameters, T>): Promise<core_grades_grader_gradingpanel_scale_store_returns> =>
      this.callWebservice('core_grades_grader_gradingpanel_scale_store', params);
  
    /**
     * ** DEPRECATED ** Please do not call this function any more. Use core_grades_create_gradecategories. Create a grade category inside a course gradebook.
     */
    public core_grades_create_gradecategory = async <T extends core_grades_create_gradecategory_parameters>(params: NoExtraProperties<core_grades_create_gradecategory_parameters, T>): Promise<core_grades_create_gradecategory_returns> =>
      this.callWebservice('core_grades_create_gradecategory', params);
  
    /**
     * Create grade categories inside a course gradebook.
     */
    public core_grades_create_gradecategories = async <T extends core_grades_create_gradecategories_parameters>(params: NoExtraProperties<core_grades_create_gradecategories_parameters, T>): Promise<core_grades_create_gradecategories_returns> =>
      this.callWebservice('core_grades_create_gradecategories', params);
  
    /**
     * Returns the enrolled users within and map some fields to the returned array of user objects.
     */
    public core_grades_get_enrolled_users_for_search_widget = async <T extends core_grades_get_enrolled_users_for_search_widget_parameters>(params: NoExtraProperties<core_grades_get_enrolled_users_for_search_widget_parameters, T>): Promise<core_grades_get_enrolled_users_for_search_widget_returns> =>
      this.callWebservice('core_grades_get_enrolled_users_for_search_widget', params);
  
    /**
     * Get the group/(s) for a course
     */
    public core_grades_get_groups_for_search_widget = async <T extends core_grades_get_groups_for_search_widget_parameters>(params: NoExtraProperties<core_grades_get_groups_for_search_widget_parameters, T>): Promise<core_grades_get_groups_for_search_widget_returns> =>
      this.callWebservice('core_grades_get_groups_for_search_widget', params);
  
    /**
     * Get grading definitions
     */
    public core_grading_get_definitions = async <T extends core_grading_get_definitions_parameters>(params: NoExtraProperties<core_grading_get_definitions_parameters, T>): Promise<core_grading_get_definitions_returns> =>
      this.callWebservice('core_grading_get_definitions', params);
  
    /**
     * Get grading form instances
     */
    public core_grading_get_gradingform_instances = async <T extends core_grading_get_gradingform_instances_parameters>(params: NoExtraProperties<core_grading_get_gradingform_instances_parameters, T>): Promise<core_grading_get_gradingform_instances_returns> =>
      this.callWebservice('core_grading_get_gradingform_instances', params);
  
    /**
     * Save grading definitions
     */
    public core_grading_save_definitions = async <T extends core_grading_save_definitions_parameters>(params: NoExtraProperties<core_grading_save_definitions_parameters, T>): Promise<core_grading_save_definitions_returns> =>
      this.callWebservice('core_grading_save_definitions', params);
  
    /**
     * Adds group members.
     */
    public core_group_add_group_members = async <T extends core_group_add_group_members_parameters>(params: NoExtraProperties<core_group_add_group_members_parameters, T>): Promise<core_group_add_group_members_returns> =>
      this.callWebservice('core_group_add_group_members', params);
  
    /**
     * Assing groups from groupings
     */
    public core_group_assign_grouping = async <T extends core_group_assign_grouping_parameters>(params: NoExtraProperties<core_group_assign_grouping_parameters, T>): Promise<core_group_assign_grouping_returns> =>
      this.callWebservice('core_group_assign_grouping', params);
  
    /**
     * Creates new groupings
     */
    public core_group_create_groupings = async <T extends core_group_create_groupings_parameters>(params: NoExtraProperties<core_group_create_groupings_parameters, T>): Promise<core_group_create_groupings_returns_item[]> =>
      this.callWebservice('core_group_create_groupings', params);
  
    /**
     * Creates new groups.
     */
    public core_group_create_groups = async <T extends core_group_create_groups_parameters>(params: NoExtraProperties<core_group_create_groups_parameters, T>): Promise<core_group_create_groups_returns_item[]> =>
      this.callWebservice('core_group_create_groups', params);
  
    /**
     * Deletes group members.
     */
    public core_group_delete_group_members = async <T extends core_group_delete_group_members_parameters>(params: NoExtraProperties<core_group_delete_group_members_parameters, T>): Promise<core_group_delete_group_members_returns> =>
      this.callWebservice('core_group_delete_group_members', params);
  
    /**
     * Deletes all specified groupings.
     */
    public core_group_delete_groupings = async <T extends core_group_delete_groupings_parameters>(params: NoExtraProperties<core_group_delete_groupings_parameters, T>): Promise<core_group_delete_groupings_returns> =>
      this.callWebservice('core_group_delete_groupings', params);
  
    /**
     * Deletes all specified groups.
     */
    public core_group_delete_groups = async <T extends core_group_delete_groups_parameters>(params: NoExtraProperties<core_group_delete_groups_parameters, T>): Promise<core_group_delete_groups_returns> =>
      this.callWebservice('core_group_delete_groups', params);
  
    /**
     * Gets a list of groups that the user is allowed to access within the specified activity.
     */
    public core_group_get_activity_allowed_groups = async <T extends core_group_get_activity_allowed_groups_parameters>(params: NoExtraProperties<core_group_get_activity_allowed_groups_parameters, T>): Promise<core_group_get_activity_allowed_groups_returns> =>
      this.callWebservice('core_group_get_activity_allowed_groups', params);
  
    /**
     * Returns effective groupmode used in a given activity.
     */
    public core_group_get_activity_groupmode = async <T extends core_group_get_activity_groupmode_parameters>(params: NoExtraProperties<core_group_get_activity_groupmode_parameters, T>): Promise<core_group_get_activity_groupmode_returns> =>
      this.callWebservice('core_group_get_activity_groupmode', params);
  
    /**
     * Returns all groupings in specified course.
     */
    public core_group_get_course_groupings = async <T extends core_group_get_course_groupings_parameters>(params: NoExtraProperties<core_group_get_course_groupings_parameters, T>): Promise<core_group_get_course_groupings_returns_item[]> =>
      this.callWebservice('core_group_get_course_groupings', params);
  
    /**
     * Returns all groups in specified course.
     */
    public core_group_get_course_groups = async <T extends core_group_get_course_groups_parameters>(params: NoExtraProperties<core_group_get_course_groups_parameters, T>): Promise<core_group_get_course_groups_returns_item[]> =>
      this.callWebservice('core_group_get_course_groups', params);
  
    /**
     * Returns all groups in specified course for the specified user.
     */
    public core_group_get_course_user_groups = async <T extends core_group_get_course_user_groups_parameters>(params: NoExtraProperties<core_group_get_course_user_groups_parameters, T>): Promise<core_group_get_course_user_groups_returns> =>
      this.callWebservice('core_group_get_course_user_groups', params);
  
    /**
     * Returns group members.
     */
    public core_group_get_group_members = async <T extends core_group_get_group_members_parameters>(params: NoExtraProperties<core_group_get_group_members_parameters, T>): Promise<core_group_get_group_members_returns_item[]> =>
      this.callWebservice('core_group_get_group_members', params);
  
    /**
     * Returns groupings details.
     */
    public core_group_get_groupings = async <T extends core_group_get_groupings_parameters>(params: NoExtraProperties<core_group_get_groupings_parameters, T>): Promise<core_group_get_groupings_returns_item[]> =>
      this.callWebservice('core_group_get_groupings', params);
  
    /**
     * Returns group details.
     */
    public core_group_get_groups = async <T extends core_group_get_groups_parameters>(params: NoExtraProperties<core_group_get_groups_parameters, T>): Promise<core_group_get_groups_returns_item[]> =>
      this.callWebservice('core_group_get_groups', params);
  
    /**
     * Unassing groups from groupings
     */
    public core_group_unassign_grouping = async <T extends core_group_unassign_grouping_parameters>(params: NoExtraProperties<core_group_unassign_grouping_parameters, T>): Promise<core_group_unassign_grouping_returns> =>
      this.callWebservice('core_group_unassign_grouping', params);
  
    /**
     * Updates existing groupings
     */
    public core_group_update_groupings = async <T extends core_group_update_groupings_parameters>(params: NoExtraProperties<core_group_update_groupings_parameters, T>): Promise<core_group_update_groupings_returns> =>
      this.callWebservice('core_group_update_groupings', params);
  
    /**
     * Updates existing groups.
     */
    public core_group_update_groups = async <T extends core_group_update_groups_parameters>(params: NoExtraProperties<core_group_update_groups_parameters, T>): Promise<core_group_update_groups_returns> =>
      this.callWebservice('core_group_update_groups', params);
  
    /**
     * Mutes a list of conversations
     */
    public core_message_mute_conversations = async <T extends core_message_mute_conversations_parameters>(params: NoExtraProperties<core_message_mute_conversations_parameters, T>): Promise<core_message_mute_conversations_returns_item[]> =>
      this.callWebservice('core_message_mute_conversations', params);
  
    /**
     * Unmutes a list of conversations
     */
    public core_message_unmute_conversations = async <T extends core_message_unmute_conversations_parameters>(params: NoExtraProperties<core_message_unmute_conversations_parameters, T>): Promise<core_message_unmute_conversations_returns_item[]> =>
      this.callWebservice('core_message_unmute_conversations', params);
  
    /**
     * Blocks a user
     */
    public core_message_block_user = async <T extends core_message_block_user_parameters>(params: NoExtraProperties<core_message_block_user_parameters, T>): Promise<core_message_block_user_returns_item[]> =>
      this.callWebservice('core_message_block_user', params);
  
    /**
     * Returns contact requests for a user
     */
    public core_message_get_contact_requests = async <T extends core_message_get_contact_requests_parameters>(params: NoExtraProperties<core_message_get_contact_requests_parameters, T>): Promise<core_message_get_contact_requests_returns_item[]> =>
      this.callWebservice('core_message_get_contact_requests', params);
  
    /**
     * Creates a contact request
     */
    public core_message_create_contact_request = async <T extends core_message_create_contact_request_parameters>(params: NoExtraProperties<core_message_create_contact_request_parameters, T>): Promise<core_message_create_contact_request_returns> =>
      this.callWebservice('core_message_create_contact_request', params);
  
    /**
     * Confirms a contact request
     */
    public core_message_confirm_contact_request = async <T extends core_message_confirm_contact_request_parameters>(params: NoExtraProperties<core_message_confirm_contact_request_parameters, T>): Promise<core_message_confirm_contact_request_returns_item[]> =>
      this.callWebservice('core_message_confirm_contact_request', params);
  
    /**
     * Declines a contact request
     */
    public core_message_decline_contact_request = async <T extends core_message_decline_contact_request_parameters>(params: NoExtraProperties<core_message_decline_contact_request_parameters, T>): Promise<core_message_decline_contact_request_returns_item[]> =>
      this.callWebservice('core_message_decline_contact_request', params);
  
    /**
     * Gets the number of received contact requests
     */
    public core_message_get_received_contact_requests_count = async <T extends core_message_get_received_contact_requests_count_parameters>(params: NoExtraProperties<core_message_get_received_contact_requests_count_parameters, T>): Promise<core_message_get_received_contact_requests_count_returns> =>
      this.callWebservice('core_message_get_received_contact_requests_count', params);
  
    /**
     * Remove contacts from the contact list
     */
    public core_message_delete_contacts = async <T extends core_message_delete_contacts_parameters>(params: NoExtraProperties<core_message_delete_contacts_parameters, T>): Promise<core_message_delete_contacts_returns> =>
      this.callWebservice('core_message_delete_contacts', params);
  
    /**
     * Deletes a list of conversations.
     */
    public core_message_delete_conversations_by_id = async <T extends core_message_delete_conversations_by_id_parameters>(params: NoExtraProperties<core_message_delete_conversations_by_id_parameters, T>): Promise<core_message_delete_conversations_by_id_returns_item[]> =>
      this.callWebservice('core_message_delete_conversations_by_id', params);
  
    /**
     * Deletes a message.
     */
    public core_message_delete_message = async <T extends core_message_delete_message_parameters>(params: NoExtraProperties<core_message_delete_message_parameters, T>): Promise<core_message_delete_message_returns> =>
      this.callWebservice('core_message_delete_message', params);
  
    /**
     * Retrieve a list of users blocked
     */
    public core_message_get_blocked_users = async <T extends core_message_get_blocked_users_parameters>(params: NoExtraProperties<core_message_get_blocked_users_parameters, T>): Promise<core_message_get_blocked_users_returns> =>
      this.callWebservice('core_message_get_blocked_users', params);
  
    /**
     * Retrieve the template data for searching for messages
     */
    public core_message_data_for_messagearea_search_messages = async <T extends core_message_data_for_messagearea_search_messages_parameters>(params: NoExtraProperties<core_message_data_for_messagearea_search_messages_parameters, T>): Promise<core_message_data_for_messagearea_search_messages_returns> =>
      this.callWebservice('core_message_data_for_messagearea_search_messages', params);
  
    /**
     * Retrieve the data for searching for people
     */
    public core_message_message_search_users = async <T extends core_message_message_search_users_parameters>(params: NoExtraProperties<core_message_message_search_users_parameters, T>): Promise<core_message_message_search_users_returns> =>
      this.callWebservice('core_message_message_search_users', params);
  
    /**
     * Retrieve the contact list
     */
    public core_message_get_user_contacts = async <T extends core_message_get_user_contacts_parameters>(params: NoExtraProperties<core_message_get_user_contacts_parameters, T>): Promise<core_message_get_user_contacts_returns_item[]> =>
      this.callWebservice('core_message_get_user_contacts', params);
  
    /**
     * Retrieve a list of conversations for a user
     */
    public core_message_get_conversations = async <T extends core_message_get_conversations_parameters>(params: NoExtraProperties<core_message_get_conversations_parameters, T>): Promise<core_message_get_conversations_returns> =>
      this.callWebservice('core_message_get_conversations', params);
  
    /**
     * Retrieve a conversation for a user
     */
    public core_message_get_conversation = async <T extends core_message_get_conversation_parameters>(params: NoExtraProperties<core_message_get_conversation_parameters, T>): Promise<core_message_get_conversation_returns> =>
      this.callWebservice('core_message_get_conversation', params);
  
    /**
     * Retrieve a conversation for a user between another user
     */
    public core_message_get_conversation_between_users = async <T extends core_message_get_conversation_between_users_parameters>(params: NoExtraProperties<core_message_get_conversation_between_users_parameters, T>): Promise<core_message_get_conversation_between_users_returns> =>
      this.callWebservice('core_message_get_conversation_between_users', params);
  
    /**
     * Retrieve a self-conversation for a user
     */
    public core_message_get_self_conversation = async <T extends core_message_get_self_conversation_parameters>(params: NoExtraProperties<core_message_get_self_conversation_parameters, T>): Promise<core_message_get_self_conversation_returns> =>
      this.callWebservice('core_message_get_self_conversation', params);
  
    /**
     * Retrieve a list of messages sent and received by a user (conversations, notifications or both)
     */
    public core_message_get_messages = async <T extends core_message_get_messages_parameters>(params: NoExtraProperties<core_message_get_messages_parameters, T>): Promise<core_message_get_messages_returns> =>
      this.callWebservice('core_message_get_messages', params);
  
    /**
     * Retrieve a list of conversation counts, indexed by type.
     */
    public core_message_get_conversation_counts = async <T extends core_message_get_conversation_counts_parameters>(params: NoExtraProperties<core_message_get_conversation_counts_parameters, T>): Promise<core_message_get_conversation_counts_returns> =>
      this.callWebservice('core_message_get_conversation_counts', params);
  
    /**
     * Retrieve a list of unread conversation counts, indexed by type.
     */
    public core_message_get_unread_conversation_counts = async <T extends core_message_get_unread_conversation_counts_parameters>(params: NoExtraProperties<core_message_get_unread_conversation_counts_parameters, T>): Promise<core_message_get_unread_conversation_counts_returns> =>
      this.callWebservice('core_message_get_unread_conversation_counts', params);
  
    /**
     * Retrieve a list of members in a conversation
     */
    public core_message_get_conversation_members = async <T extends core_message_get_conversation_members_parameters>(params: NoExtraProperties<core_message_get_conversation_members_parameters, T>): Promise<core_message_get_conversation_members_returns_item[]> =>
      this.callWebservice('core_message_get_conversation_members', params);
  
    /**
     * Retrieve a user message profiles
     */
    public core_message_get_member_info = async <T extends core_message_get_member_info_parameters>(params: NoExtraProperties<core_message_get_member_info_parameters, T>): Promise<core_message_get_member_info_returns_item[]> =>
      this.callWebservice('core_message_get_member_info', params);
  
    /**
     * Retrieve the count of unread conversations for a given user
     */
    public core_message_get_unread_conversations_count = async <T extends core_message_get_unread_conversations_count_parameters>(params: NoExtraProperties<core_message_get_unread_conversations_count_parameters, T>): Promise<core_message_get_unread_conversations_count_returns> =>
      this.callWebservice('core_message_get_unread_conversations_count', params);
  
    /**
     * Mark all notifications as read for a given user
     */
    public core_message_mark_all_notifications_as_read = async <T extends core_message_mark_all_notifications_as_read_parameters>(params: NoExtraProperties<core_message_mark_all_notifications_as_read_parameters, T>): Promise<core_message_mark_all_notifications_as_read_returns> =>
      this.callWebservice('core_message_mark_all_notifications_as_read', params);
  
    /**
     * Mark all conversation messages as read for a given user
     */
    public core_message_mark_all_conversation_messages_as_read = async <T extends core_message_mark_all_conversation_messages_as_read_parameters>(params: NoExtraProperties<core_message_mark_all_conversation_messages_as_read_parameters, T>): Promise<core_message_mark_all_conversation_messages_as_read_returns> =>
      this.callWebservice('core_message_mark_all_conversation_messages_as_read', params);
  
    /**
     * Mark a single message as read, trigger message_viewed event.
     */
    public core_message_mark_message_read = async <T extends core_message_mark_message_read_parameters>(params: NoExtraProperties<core_message_mark_message_read_parameters, T>): Promise<core_message_mark_message_read_returns> =>
      this.callWebservice('core_message_mark_message_read', params);
  
    /**
     * Mark a single notification as read, trigger notification_viewed event.
     */
    public core_message_mark_notification_read = async <T extends core_message_mark_notification_read_parameters>(params: NoExtraProperties<core_message_mark_notification_read_parameters, T>): Promise<core_message_mark_notification_read_returns> =>
      this.callWebservice('core_message_mark_notification_read', params);
  
    /**
     * Process the message processor config form
     */
    public core_message_message_processor_config_form = async <T extends core_message_message_processor_config_form_parameters>(params: NoExtraProperties<core_message_message_processor_config_form_parameters, T>): Promise<core_message_message_processor_config_form_returns> =>
      this.callWebservice('core_message_message_processor_config_form', params);
  
    /**
     * Get a message processor
     */
    public core_message_get_message_processor = async <T extends core_message_get_message_processor_parameters>(params: NoExtraProperties<core_message_get_message_processor_parameters, T>): Promise<core_message_get_message_processor_returns> =>
      this.callWebservice('core_message_get_message_processor', params);
  
    /**
     * Search for contacts
     */
    public core_message_search_contacts = async <T extends core_message_search_contacts_parameters>(params: NoExtraProperties<core_message_search_contacts_parameters, T>): Promise<core_message_search_contacts_returns_item[]> =>
      this.callWebservice('core_message_search_contacts', params);
  
    /**
     * Send instant messages
     */
    public core_message_send_instant_messages = async <T extends core_message_send_instant_messages_parameters>(params: NoExtraProperties<core_message_send_instant_messages_parameters, T>): Promise<core_message_send_instant_messages_returns_item[]> =>
      this.callWebservice('core_message_send_instant_messages', params);
  
    /**
     * Send messages to an existing conversation between users
     */
    public core_message_send_messages_to_conversation = async <T extends core_message_send_messages_to_conversation_parameters>(params: NoExtraProperties<core_message_send_messages_to_conversation_parameters, T>): Promise<core_message_send_messages_to_conversation_returns_item[]> =>
      this.callWebservice('core_message_send_messages_to_conversation', params);
  
    /**
     * Retrieve the conversation messages and relevant member information
     */
    public core_message_get_conversation_messages = async <T extends core_message_get_conversation_messages_parameters>(params: NoExtraProperties<core_message_get_conversation_messages_parameters, T>): Promise<core_message_get_conversation_messages_returns> =>
      this.callWebservice('core_message_get_conversation_messages', params);
  
    /**
     * Unblocks a user
     */
    public core_message_unblock_user = async <T extends core_message_unblock_user_parameters>(params: NoExtraProperties<core_message_unblock_user_parameters, T>): Promise<core_message_unblock_user_returns_item[]> =>
      this.callWebservice('core_message_unblock_user', params);
  
    /**
     * Get the notification preferences for a given user.
     */
    public core_message_get_user_notification_preferences = async <T extends core_message_get_user_notification_preferences_parameters>(params: NoExtraProperties<core_message_get_user_notification_preferences_parameters, T>): Promise<core_message_get_user_notification_preferences_returns> =>
      this.callWebservice('core_message_get_user_notification_preferences', params);
  
    /**
     * Get the message preferences for a given user.
     */
    public core_message_get_user_message_preferences = async <T extends core_message_get_user_message_preferences_parameters>(params: NoExtraProperties<core_message_get_user_message_preferences_parameters, T>): Promise<core_message_get_user_message_preferences_returns> =>
      this.callWebservice('core_message_get_user_message_preferences', params);
  
    /**
     * Mark a conversation or group of conversations as favourites/starred conversations.
     */
    public core_message_set_favourite_conversations = async <T extends core_message_set_favourite_conversations_parameters>(params: NoExtraProperties<core_message_set_favourite_conversations_parameters, T>): Promise<core_message_set_favourite_conversations_returns_item[]> =>
      this.callWebservice('core_message_set_favourite_conversations', params);
  
    /**
     * Unset a conversation or group of conversations as favourites/starred conversations.
     */
    public core_message_unset_favourite_conversations = async <T extends core_message_unset_favourite_conversations_parameters>(params: NoExtraProperties<core_message_unset_favourite_conversations_parameters, T>): Promise<core_message_unset_favourite_conversations_returns_item[]> =>
      this.callWebservice('core_message_unset_favourite_conversations', params);
  
    /**
     * Deletes a message for all users.
     */
    public core_message_delete_message_for_all_users = async <T extends core_message_delete_message_for_all_users_parameters>(params: NoExtraProperties<core_message_delete_message_for_all_users_parameters, T>): Promise<core_message_delete_message_for_all_users_returns_item[]> =>
      this.callWebservice('core_message_delete_message_for_all_users', params);
  
    /**
     * Get number of unread notifications.
     */
    public core_message_get_unread_notification_count = async <T extends core_message_get_unread_notification_count_parameters>(params: NoExtraProperties<core_message_get_unread_notification_count_parameters, T>): Promise<core_message_get_unread_notification_count_returns> =>
      this.callWebservice('core_message_get_unread_notification_count', params);
  
    /**
     * Create notes
     */
    public core_notes_create_notes = async <T extends core_notes_create_notes_parameters>(params: NoExtraProperties<core_notes_create_notes_parameters, T>): Promise<core_notes_create_notes_returns_item[]> =>
      this.callWebservice('core_notes_create_notes', params);
  
    /**
     * Delete notes
     */
    public core_notes_delete_notes = async <T extends core_notes_delete_notes_parameters>(params: NoExtraProperties<core_notes_delete_notes_parameters, T>): Promise<core_notes_delete_notes_returns_item[]> =>
      this.callWebservice('core_notes_delete_notes', params);
  
    /**
     * Returns all notes in specified course (or site), for the specified user.
     */
    public core_notes_get_course_notes = async <T extends core_notes_get_course_notes_parameters>(params: NoExtraProperties<core_notes_get_course_notes_parameters, T>): Promise<core_notes_get_course_notes_returns> =>
      this.callWebservice('core_notes_get_course_notes', params);
  
    /**
     * Get notes
     */
    public core_notes_get_notes = async <T extends core_notes_get_notes_parameters>(params: NoExtraProperties<core_notes_get_notes_parameters, T>): Promise<core_notes_get_notes_returns> =>
      this.callWebservice('core_notes_get_notes', params);
  
    /**
     * Update notes
     */
    public core_notes_update_notes = async <T extends core_notes_update_notes_parameters>(params: NoExtraProperties<core_notes_update_notes_parameters, T>): Promise<core_notes_update_notes_returns_item[]> =>
      this.callWebservice('core_notes_update_notes', params);
  
    /**
     * Simulates the web interface view of notes/index.php: trigger events.
     */
    public core_notes_view_notes = async <T extends core_notes_view_notes_parameters>(params: NoExtraProperties<core_notes_view_notes_parameters, T>): Promise<core_notes_view_notes_returns> =>
      this.callWebservice('core_notes_view_notes', params);
  
    /**
     * Load a template for a renderable
     */
    public core_output_load_template = async <T extends core_output_load_template_parameters>(params: NoExtraProperties<core_output_load_template_parameters, T>): Promise<core_output_load_template_returns> =>
      this.callWebservice('core_output_load_template', params);
  
    /**
     * Load a template and its dependencies for a renderable
     */
    public core_output_load_template_with_dependencies = async <T extends core_output_load_template_with_dependencies_parameters>(params: NoExtraProperties<core_output_load_template_with_dependencies_parameters, T>): Promise<core_output_load_template_with_dependencies_returns> =>
      this.callWebservice('core_output_load_template_with_dependencies', params);
  
    /**
     * Load the mapping of names to icons
     */
    public core_output_load_fontawesome_icon_map = async <T extends core_output_load_fontawesome_icon_map_parameters>(params?: NoExtraProperties<core_output_load_fontawesome_icon_map_parameters, T>): Promise<core_output_load_fontawesome_icon_map_returns_item[]> =>
      this.callWebservice('core_output_load_fontawesome_icon_map', params);
  
    /**
     * Load the mapping of moodle pix names to fontawesome icon names
     */
    public core_output_load_fontawesome_icon_system_map = async <T extends core_output_load_fontawesome_icon_system_map_parameters>(params: NoExtraProperties<core_output_load_fontawesome_icon_system_map_parameters, T>): Promise<core_output_load_fontawesome_icon_system_map_returns_item[]> =>
      this.callWebservice('core_output_load_fontawesome_icon_system_map', params);
  
    /**
     * Update the flag state of a question attempt.
     */
    public core_question_update_flag = async <T extends core_question_update_flag_parameters>(params: NoExtraProperties<core_question_update_flag_parameters, T>): Promise<core_question_update_flag_returns> =>
      this.callWebservice('core_question_update_flag', params);
  
    /**
     * Update the question tags.
     */
    public core_question_submit_tags_form = async <T extends core_question_submit_tags_form_parameters>(params: NoExtraProperties<core_question_submit_tags_form_parameters, T>): Promise<core_question_submit_tags_form_returns> =>
      this.callWebservice('core_question_submit_tags_form', params);
  
    /**
     * Get the random question set for a criteria
     */
    public core_question_get_random_question_summaries = async <T extends core_question_get_random_question_summaries_parameters>(params: NoExtraProperties<core_question_get_random_question_summaries_parameters, T>): Promise<core_question_get_random_question_summaries_returns> =>
      this.callWebservice('core_question_get_random_question_summaries', params);
  
    /**
     * Retrieve all the ratings for an item.
     */
    public core_rating_get_item_ratings = async <T extends core_rating_get_item_ratings_parameters>(params: NoExtraProperties<core_rating_get_item_ratings_parameters, T>): Promise<core_rating_get_item_ratings_returns> =>
      this.callWebservice('core_rating_get_item_ratings', params);
  
    /**
     * Rates an item.
     */
    public core_rating_add_rating = async <T extends core_rating_add_rating_parameters>(params: NoExtraProperties<core_rating_add_rating_parameters, T>): Promise<core_rating_add_rating_returns> =>
      this.callWebservice('core_rating_add_rating', params);
  
    /**
     * Manual role assignments.
     */
    public core_role_assign_roles = async <T extends core_role_assign_roles_parameters>(params: NoExtraProperties<core_role_assign_roles_parameters, T>): Promise<core_role_assign_roles_returns> =>
      this.callWebservice('core_role_assign_roles', params);
  
    /**
     * Manual role unassignments.
     */
    public core_role_unassign_roles = async <T extends core_role_unassign_roles_parameters>(params: NoExtraProperties<core_role_unassign_roles_parameters, T>): Promise<core_role_unassign_roles_returns> =>
      this.callWebservice('core_role_unassign_roles', params);
  
    /**
     * Gets relevant users for a search request.
     */
    public core_search_get_relevant_users = async <T extends core_search_get_relevant_users_parameters>(params: NoExtraProperties<core_search_get_relevant_users_parameters, T>): Promise<core_search_get_relevant_users_returns_item[]> =>
      this.callWebservice('core_search_get_relevant_users', params);
  
    /**
     * Gets tag index page for one tag and one tag area
     */
    public core_tag_get_tagindex = async <T extends core_tag_get_tagindex_parameters>(params: NoExtraProperties<core_tag_get_tagindex_parameters, T>): Promise<core_tag_get_tagindex_returns> =>
      this.callWebservice('core_tag_get_tagindex', params);
  
    /**
     * Gets tags by their ids
     */
    public core_tag_get_tags = async <T extends core_tag_get_tags_parameters>(params: NoExtraProperties<core_tag_get_tags_parameters, T>): Promise<core_tag_get_tags_returns> =>
      this.callWebservice('core_tag_get_tags', params);
  
    /**
     * Updates tags
     */
    public core_tag_update_tags = async <T extends core_tag_update_tags_parameters>(params: NoExtraProperties<core_tag_update_tags_parameters, T>): Promise<core_tag_update_tags_returns> =>
      this.callWebservice('core_tag_update_tags', params);
  
    /**
     * Gets tag index page per different areas.
     */
    public core_tag_get_tagindex_per_area = async <T extends core_tag_get_tagindex_per_area_parameters>(params: NoExtraProperties<core_tag_get_tagindex_per_area_parameters, T>): Promise<core_tag_get_tagindex_per_area_returns_item[]> =>
      this.callWebservice('core_tag_get_tagindex_per_area', params);
  
    /**
     * Retrieves existing tag areas.
     */
    public core_tag_get_tag_areas = async <T extends core_tag_get_tag_areas_parameters>(params?: NoExtraProperties<core_tag_get_tag_areas_parameters, T>): Promise<core_tag_get_tag_areas_returns> =>
      this.callWebservice('core_tag_get_tag_areas', params);
  
    /**
     * Retrieves existing tag collections.
     */
    public core_tag_get_tag_collections = async <T extends core_tag_get_tag_collections_parameters>(params?: NoExtraProperties<core_tag_get_tag_collections_parameters, T>): Promise<core_tag_get_tag_collections_returns> =>
      this.callWebservice('core_tag_get_tag_collections', params);
  
    /**
     * Retrieves a tag cloud for the given collection and/or query search.
     */
    public core_tag_get_tag_cloud = async <T extends core_tag_get_tag_cloud_parameters>(params: NoExtraProperties<core_tag_get_tag_cloud_parameters, T>): Promise<core_tag_get_tag_cloud_returns> =>
      this.callWebservice('core_tag_get_tag_cloud', params);
  
    /**
     * Generic service to update title
     */
    public core_update_inplace_editable = async <T extends core_update_inplace_editable_parameters>(params: NoExtraProperties<core_update_inplace_editable_parameters, T>): Promise<core_update_inplace_editable_returns> =>
      this.callWebservice('core_update_inplace_editable', params);
  
    /**
     * Store mobile user devices information for PUSH Notifications.
     */
    public core_user_add_user_device = async <T extends core_user_add_user_device_parameters>(params: NoExtraProperties<core_user_add_user_device_parameters, T>): Promise<core_user_add_user_device_returns_item[]> =>
      this.callWebservice('core_user_add_user_device', params);
  
    /**
     * Store mobile user public key.
     */
    public core_user_update_user_device_public_key = async <T extends core_user_update_user_device_public_key_parameters>(params: NoExtraProperties<core_user_update_user_device_public_key_parameters, T>): Promise<core_user_update_user_device_public_key_returns> =>
      this.callWebservice('core_user_update_user_device_public_key', params);
  
    /**
     * Copy files from a draft area to users private files area.
     */
    public core_user_add_user_private_files = async <T extends core_user_add_user_private_files_parameters>(params: NoExtraProperties<core_user_add_user_private_files_parameters, T>): Promise<core_user_add_user_private_files_returns> =>
      this.callWebservice('core_user_add_user_private_files', params);
  
    /**
     * Create users.
     */
    public core_user_create_users = async <T extends core_user_create_users_parameters>(params: NoExtraProperties<core_user_create_users_parameters, T>): Promise<core_user_create_users_returns_item[]> =>
      this.callWebservice('core_user_create_users', params);
  
    /**
     * Delete users.
     */
    public core_user_delete_users = async <T extends core_user_delete_users_parameters>(params: NoExtraProperties<core_user_delete_users_parameters, T>): Promise<core_user_delete_users_returns> =>
      this.callWebservice('core_user_delete_users', params);
  
    /**
     * Get course user profiles (each of the profils matching a course id and a user id),.
     */
    public core_user_get_course_user_profiles = async <T extends core_user_get_course_user_profiles_parameters>(params: NoExtraProperties<core_user_get_course_user_profiles_parameters, T>): Promise<core_user_get_course_user_profiles_returns_item[]> =>
      this.callWebservice('core_user_get_course_user_profiles', params);
  
    /**
     * search for users matching the parameters
     */
    public core_user_get_users = async <T extends core_user_get_users_parameters>(params: NoExtraProperties<core_user_get_users_parameters, T>): Promise<core_user_get_users_returns> =>
      this.callWebservice('core_user_get_users', params);
  
    /**
     * Retrieve users' information for a specified unique field - If you want to do a user search, use core_user_get_users() or core_user_search_identity().
     */
    public core_user_get_users_by_field = async <T extends core_user_get_users_by_field_parameters>(params: NoExtraProperties<core_user_get_users_by_field_parameters, T>): Promise<core_user_get_users_by_field_returns_item[]> =>
      this.callWebservice('core_user_get_users_by_field', params);
  
    /**
     * Return list of users identities matching the given criteria in their name or other identity fields.
     */
    public core_user_search_identity = async <T extends core_user_search_identity_parameters>(params: NoExtraProperties<core_user_search_identity_parameters, T>): Promise<core_user_search_identity_returns> =>
      this.callWebservice('core_user_search_identity', params);
  
    /**
     * Remove a user device from the Moodle database.
     */
    public core_user_remove_user_device = async <T extends core_user_remove_user_device_parameters>(params: NoExtraProperties<core_user_remove_user_device_parameters, T>): Promise<core_user_remove_user_device_returns> =>
      this.callWebservice('core_user_remove_user_device', params);
  
    /**
     * Update users.
     */
    public core_user_update_users = async <T extends core_user_update_users_parameters>(params: NoExtraProperties<core_user_update_users_parameters, T>): Promise<core_user_update_users_returns> =>
      this.callWebservice('core_user_update_users', params);
  
    /**
     * Update a user's preferences
     */
    public core_user_update_user_preferences = async <T extends core_user_update_user_preferences_parameters>(params: NoExtraProperties<core_user_update_user_preferences_parameters, T>): Promise<core_user_update_user_preferences_returns> =>
      this.callWebservice('core_user_update_user_preferences', params);
  
    /**
     * Simulates the web-interface view of user/index.php (triggering events),.
     */
    public core_user_view_user_list = async <T extends core_user_view_user_list_parameters>(params: NoExtraProperties<core_user_view_user_list_parameters, T>): Promise<core_user_view_user_list_returns> =>
      this.callWebservice('core_user_view_user_list', params);
  
    /**
     * Simulates the web-interface view of user/view.php and user/profile.php (triggering events),.
     */
    public core_user_view_user_profile = async <T extends core_user_view_user_profile_parameters>(params: NoExtraProperties<core_user_view_user_profile_parameters, T>): Promise<core_user_view_user_profile_returns> =>
      this.callWebservice('core_user_view_user_profile', params);
  
    /**
     * Return user preferences.
     */
    public core_user_get_user_preferences = async <T extends core_user_get_user_preferences_parameters>(params: NoExtraProperties<core_user_get_user_preferences_parameters, T>): Promise<core_user_get_user_preferences_returns> =>
      this.callWebservice('core_user_get_user_preferences', params);
  
    /**
     * Update or delete the user picture in the site
     */
    public core_user_update_picture = async <T extends core_user_update_picture_parameters>(params: NoExtraProperties<core_user_update_picture_parameters, T>): Promise<core_user_update_picture_returns> =>
      this.callWebservice('core_user_update_picture', params);
  
    /**
     * Set user preferences.
     */
    public core_user_set_user_preferences = async <T extends core_user_set_user_preferences_parameters>(params: NoExtraProperties<core_user_set_user_preferences_parameters, T>): Promise<core_user_set_user_preferences_returns> =>
      this.callWebservice('core_user_set_user_preferences', params);
  
    /**
     * Agree the site policy for the current user.
     */
    public core_user_agree_site_policy = async <T extends core_user_agree_site_policy_parameters>(params?: NoExtraProperties<core_user_agree_site_policy_parameters, T>): Promise<core_user_agree_site_policy_returns> =>
      this.callWebservice('core_user_agree_site_policy', params);
  
    /**
     * Returns general information about files in the user private files area.
     */
    public core_user_get_private_files_info = async <T extends core_user_get_private_files_info_parameters>(params: NoExtraProperties<core_user_get_private_files_info_parameters, T>): Promise<core_user_get_private_files_info_returns> =>
      this.callWebservice('core_user_get_private_files_info', params);
  
    /**
     * Creates new competency frameworks.
     */
    public core_competency_create_competency_framework = async <T extends core_competency_create_competency_framework_parameters>(params: NoExtraProperties<core_competency_create_competency_framework_parameters, T>): Promise<core_competency_create_competency_framework_returns> =>
      this.callWebservice('core_competency_create_competency_framework', params);
  
    /**
     * Load a summary of a competency framework.
     */
    public core_competency_read_competency_framework = async <T extends core_competency_read_competency_framework_parameters>(params: NoExtraProperties<core_competency_read_competency_framework_parameters, T>): Promise<core_competency_read_competency_framework_returns> =>
      this.callWebservice('core_competency_read_competency_framework', params);
  
    /**
     * Duplicate a competency framework.
     */
    public core_competency_duplicate_competency_framework = async <T extends core_competency_duplicate_competency_framework_parameters>(params: NoExtraProperties<core_competency_duplicate_competency_framework_parameters, T>): Promise<core_competency_duplicate_competency_framework_returns> =>
      this.callWebservice('core_competency_duplicate_competency_framework', params);
  
    /**
     * Delete a competency framework.
     */
    public core_competency_delete_competency_framework = async <T extends core_competency_delete_competency_framework_parameters>(params: NoExtraProperties<core_competency_delete_competency_framework_parameters, T>): Promise<core_competency_delete_competency_framework_returns> =>
      this.callWebservice('core_competency_delete_competency_framework', params);
  
    /**
     * Update a competency framework.
     */
    public core_competency_update_competency_framework = async <T extends core_competency_update_competency_framework_parameters>(params: NoExtraProperties<core_competency_update_competency_framework_parameters, T>): Promise<core_competency_update_competency_framework_returns> =>
      this.callWebservice('core_competency_update_competency_framework', params);
  
    /**
     * Load a list of a competency frameworks.
     */
    public core_competency_list_competency_frameworks = async <T extends core_competency_list_competency_frameworks_parameters>(params: NoExtraProperties<core_competency_list_competency_frameworks_parameters, T>): Promise<core_competency_list_competency_frameworks_returns_item[]> =>
      this.callWebservice('core_competency_list_competency_frameworks', params);
  
    /**
     * Count a list of a competency frameworks.
     */
    public core_competency_count_competency_frameworks = async <T extends core_competency_count_competency_frameworks_parameters>(params: NoExtraProperties<core_competency_count_competency_frameworks_parameters, T>): Promise<core_competency_count_competency_frameworks_returns> =>
      this.callWebservice('core_competency_count_competency_frameworks', params);
  
    /**
     * Log event competency framework viewed
     */
    public core_competency_competency_framework_viewed = async <T extends core_competency_competency_framework_viewed_parameters>(params: NoExtraProperties<core_competency_competency_framework_viewed_parameters, T>): Promise<core_competency_competency_framework_viewed_returns> =>
      this.callWebservice('core_competency_competency_framework_viewed', params);
  
    /**
     * Creates new competencies.
     */
    public core_competency_create_competency = async <T extends core_competency_create_competency_parameters>(params: NoExtraProperties<core_competency_create_competency_parameters, T>): Promise<core_competency_create_competency_returns> =>
      this.callWebservice('core_competency_create_competency', params);
  
    /**
     * Load a summary of a competency.
     */
    public core_competency_read_competency = async <T extends core_competency_read_competency_parameters>(params: NoExtraProperties<core_competency_read_competency_parameters, T>): Promise<core_competency_read_competency_returns> =>
      this.callWebservice('core_competency_read_competency', params);
  
    /**
     * Log event competency viewed
     */
    public core_competency_competency_viewed = async <T extends core_competency_competency_viewed_parameters>(params: NoExtraProperties<core_competency_competency_viewed_parameters, T>): Promise<core_competency_competency_viewed_returns> =>
      this.callWebservice('core_competency_competency_viewed', params);
  
    /**
     * Delete a competency.
     */
    public core_competency_delete_competency = async <T extends core_competency_delete_competency_parameters>(params: NoExtraProperties<core_competency_delete_competency_parameters, T>): Promise<core_competency_delete_competency_returns> =>
      this.callWebservice('core_competency_delete_competency', params);
  
    /**
     * Update a competency.
     */
    public core_competency_update_competency = async <T extends core_competency_update_competency_parameters>(params: NoExtraProperties<core_competency_update_competency_parameters, T>): Promise<core_competency_update_competency_returns> =>
      this.callWebservice('core_competency_update_competency', params);
  
    /**
     * Load a list of a competencies.
     */
    public core_competency_list_competencies = async <T extends core_competency_list_competencies_parameters>(params: NoExtraProperties<core_competency_list_competencies_parameters, T>): Promise<core_competency_list_competencies_returns_item[]> =>
      this.callWebservice('core_competency_list_competencies', params);
  
    /**
     * Load a list of a competencies for a given template.
     */
    public core_competency_list_competencies_in_template = async <T extends core_competency_list_competencies_in_template_parameters>(params: NoExtraProperties<core_competency_list_competencies_in_template_parameters, T>): Promise<core_competency_list_competencies_in_template_returns_item[]> =>
      this.callWebservice('core_competency_list_competencies_in_template', params);
  
    /**
     * Count a list of a competencies.
     */
    public core_competency_count_competencies = async <T extends core_competency_count_competencies_parameters>(params: NoExtraProperties<core_competency_count_competencies_parameters, T>): Promise<core_competency_count_competencies_returns> =>
      this.callWebservice('core_competency_count_competencies', params);
  
    /**
     * Count a list of a competencies for a given template.
     */
    public core_competency_count_competencies_in_template = async <T extends core_competency_count_competencies_in_template_parameters>(params: NoExtraProperties<core_competency_count_competencies_in_template_parameters, T>): Promise<core_competency_count_competencies_in_template_returns> =>
      this.callWebservice('core_competency_count_competencies_in_template', params);
  
    /**
     * Search a list of a competencies.
     */
    public core_competency_search_competencies = async <T extends core_competency_search_competencies_parameters>(params: NoExtraProperties<core_competency_search_competencies_parameters, T>): Promise<core_competency_search_competencies_returns_item[]> =>
      this.callWebservice('core_competency_search_competencies', params);
  
    /**
     * Set a new parent for a competency.
     */
    public core_competency_set_parent_competency = async <T extends core_competency_set_parent_competency_parameters>(params: NoExtraProperties<core_competency_set_parent_competency_parameters, T>): Promise<core_competency_set_parent_competency_returns> =>
      this.callWebservice('core_competency_set_parent_competency', params);
  
    /**
     * Re-order a competency.
     */
    public core_competency_move_up_competency = async <T extends core_competency_move_up_competency_parameters>(params: NoExtraProperties<core_competency_move_up_competency_parameters, T>): Promise<core_competency_move_up_competency_returns> =>
      this.callWebservice('core_competency_move_up_competency', params);
  
    /**
     * Re-order a competency.
     */
    public core_competency_move_down_competency = async <T extends core_competency_move_down_competency_parameters>(params: NoExtraProperties<core_competency_move_down_competency_parameters, T>): Promise<core_competency_move_down_competency_returns> =>
      this.callWebservice('core_competency_move_down_competency', params);
  
    /**
     * List the competencies in a course module
     */
    public core_competency_list_course_module_competencies = async <T extends core_competency_list_course_module_competencies_parameters>(params: NoExtraProperties<core_competency_list_course_module_competencies_parameters, T>): Promise<core_competency_list_course_module_competencies_returns_item[]> =>
      this.callWebservice('core_competency_list_course_module_competencies', params);
  
    /**
     * Count the competencies in a course module
     */
    public core_competency_count_course_module_competencies = async <T extends core_competency_count_course_module_competencies_parameters>(params: NoExtraProperties<core_competency_count_course_module_competencies_parameters, T>): Promise<core_competency_count_course_module_competencies_returns> =>
      this.callWebservice('core_competency_count_course_module_competencies', params);
  
    /**
     * List the competencies in a course
     */
    public core_competency_list_course_competencies = async <T extends core_competency_list_course_competencies_parameters>(params: NoExtraProperties<core_competency_list_course_competencies_parameters, T>): Promise<core_competency_list_course_competencies_returns_item[]> =>
      this.callWebservice('core_competency_list_course_competencies', params);
  
    /**
     * List the competencies in a course
     */
    public core_competency_count_competencies_in_course = async <T extends core_competency_count_competencies_in_course_parameters>(params: NoExtraProperties<core_competency_count_competencies_in_course_parameters, T>): Promise<core_competency_count_competencies_in_course_returns> =>
      this.callWebservice('core_competency_count_competencies_in_course', params);
  
    /**
     * List the courses using a competency
     */
    public core_competency_count_courses_using_competency = async <T extends core_competency_count_courses_using_competency_parameters>(params: NoExtraProperties<core_competency_count_courses_using_competency_parameters, T>): Promise<core_competency_count_courses_using_competency_returns> =>
      this.callWebservice('core_competency_count_courses_using_competency', params);
  
    /**
     * Add the competency to a course
     */
    public core_competency_add_competency_to_course = async <T extends core_competency_add_competency_to_course_parameters>(params: NoExtraProperties<core_competency_add_competency_to_course_parameters, T>): Promise<core_competency_add_competency_to_course_returns> =>
      this.callWebservice('core_competency_add_competency_to_course', params);
  
    /**
     * Add the competency to a template
     */
    public core_competency_add_competency_to_template = async <T extends core_competency_add_competency_to_template_parameters>(params: NoExtraProperties<core_competency_add_competency_to_template_parameters, T>): Promise<core_competency_add_competency_to_template_returns> =>
      this.callWebservice('core_competency_add_competency_to_template', params);
  
    /**
     * Remove a competency from a course
     */
    public core_competency_remove_competency_from_course = async <T extends core_competency_remove_competency_from_course_parameters>(params: NoExtraProperties<core_competency_remove_competency_from_course_parameters, T>): Promise<core_competency_remove_competency_from_course_returns> =>
      this.callWebservice('core_competency_remove_competency_from_course', params);
  
    /**
     * Modify the ruleoutcome value for course competency
     */
    public core_competency_set_course_competency_ruleoutcome = async <T extends core_competency_set_course_competency_ruleoutcome_parameters>(params: NoExtraProperties<core_competency_set_course_competency_ruleoutcome_parameters, T>): Promise<core_competency_set_course_competency_ruleoutcome_returns> =>
      this.callWebservice('core_competency_set_course_competency_ruleoutcome', params);
  
    /**
     * Remove a competency from a template
     */
    public core_competency_remove_competency_from_template = async <T extends core_competency_remove_competency_from_template_parameters>(params: NoExtraProperties<core_competency_remove_competency_from_template_parameters, T>): Promise<core_competency_remove_competency_from_template_returns> =>
      this.callWebservice('core_competency_remove_competency_from_template', params);
  
    /**
     * Move a course competency to a new relative sort order.
     */
    public core_competency_reorder_course_competency = async <T extends core_competency_reorder_course_competency_parameters>(params: NoExtraProperties<core_competency_reorder_course_competency_parameters, T>): Promise<core_competency_reorder_course_competency_returns> =>
      this.callWebservice('core_competency_reorder_course_competency', params);
  
    /**
     * Move a template competency to a new relative sort order.
     */
    public core_competency_reorder_template_competency = async <T extends core_competency_reorder_template_competency_parameters>(params: NoExtraProperties<core_competency_reorder_template_competency_parameters, T>): Promise<core_competency_reorder_template_competency_returns> =>
      this.callWebservice('core_competency_reorder_template_competency', params);
  
    /**
     * Creates new learning plan templates.
     */
    public core_competency_create_template = async <T extends core_competency_create_template_parameters>(params: NoExtraProperties<core_competency_create_template_parameters, T>): Promise<core_competency_create_template_returns> =>
      this.callWebservice('core_competency_create_template', params);
  
    /**
     * Duplicate learning plan template.
     */
    public core_competency_duplicate_template = async <T extends core_competency_duplicate_template_parameters>(params: NoExtraProperties<core_competency_duplicate_template_parameters, T>): Promise<core_competency_duplicate_template_returns> =>
      this.callWebservice('core_competency_duplicate_template', params);
  
    /**
     * Load a summary of a learning plan template.
     */
    public core_competency_read_template = async <T extends core_competency_read_template_parameters>(params: NoExtraProperties<core_competency_read_template_parameters, T>): Promise<core_competency_read_template_returns> =>
      this.callWebservice('core_competency_read_template', params);
  
    /**
     * Delete a learning plan template.
     */
    public core_competency_delete_template = async <T extends core_competency_delete_template_parameters>(params: NoExtraProperties<core_competency_delete_template_parameters, T>): Promise<core_competency_delete_template_returns> =>
      this.callWebservice('core_competency_delete_template', params);
  
    /**
     * Update a learning plan template.
     */
    public core_competency_update_template = async <T extends core_competency_update_template_parameters>(params: NoExtraProperties<core_competency_update_template_parameters, T>): Promise<core_competency_update_template_returns> =>
      this.callWebservice('core_competency_update_template', params);
  
    /**
     * Load a list of a learning plan templates.
     */
    public core_competency_list_templates = async <T extends core_competency_list_templates_parameters>(params: NoExtraProperties<core_competency_list_templates_parameters, T>): Promise<core_competency_list_templates_returns_item[]> =>
      this.callWebservice('core_competency_list_templates', params);
  
    /**
     * Load a list of a learning plan templates for a given competency.
     */
    public core_competency_list_templates_using_competency = async <T extends core_competency_list_templates_using_competency_parameters>(params: NoExtraProperties<core_competency_list_templates_using_competency_parameters, T>): Promise<core_competency_list_templates_using_competency_returns_item[]> =>
      this.callWebservice('core_competency_list_templates_using_competency', params);
  
    /**
     * Count a list of a learning plan templates.
     */
    public core_competency_count_templates = async <T extends core_competency_count_templates_parameters>(params: NoExtraProperties<core_competency_count_templates_parameters, T>): Promise<core_competency_count_templates_returns> =>
      this.callWebservice('core_competency_count_templates', params);
  
    /**
     * Count a list of a learning plan templates for a given competency.
     */
    public core_competency_count_templates_using_competency = async <T extends core_competency_count_templates_using_competency_parameters>(params: NoExtraProperties<core_competency_count_templates_using_competency_parameters, T>): Promise<core_competency_count_templates_using_competency_returns> =>
      this.callWebservice('core_competency_count_templates_using_competency', params);
  
    /**
     * Creates a learning plan.
     */
    public core_competency_create_plan = async <T extends core_competency_create_plan_parameters>(params: NoExtraProperties<core_competency_create_plan_parameters, T>): Promise<core_competency_create_plan_returns> =>
      this.callWebservice('core_competency_create_plan', params);
  
    /**
     * Updates a learning plan.
     */
    public core_competency_update_plan = async <T extends core_competency_update_plan_parameters>(params: NoExtraProperties<core_competency_update_plan_parameters, T>): Promise<core_competency_update_plan_returns> =>
      this.callWebservice('core_competency_update_plan', params);
  
    /**
     * Complete learning plan.
     */
    public core_competency_complete_plan = async <T extends core_competency_complete_plan_parameters>(params: NoExtraProperties<core_competency_complete_plan_parameters, T>): Promise<core_competency_complete_plan_returns> =>
      this.callWebservice('core_competency_complete_plan', params);
  
    /**
     * Reopen learning plan.
     */
    public core_competency_reopen_plan = async <T extends core_competency_reopen_plan_parameters>(params: NoExtraProperties<core_competency_reopen_plan_parameters, T>): Promise<core_competency_reopen_plan_returns> =>
      this.callWebservice('core_competency_reopen_plan', params);
  
    /**
     * Load a learning plan.
     */
    public core_competency_read_plan = async <T extends core_competency_read_plan_parameters>(params: NoExtraProperties<core_competency_read_plan_parameters, T>): Promise<core_competency_read_plan_returns> =>
      this.callWebservice('core_competency_read_plan', params);
  
    /**
     * Delete a learning plan.
     */
    public core_competency_delete_plan = async <T extends core_competency_delete_plan_parameters>(params: NoExtraProperties<core_competency_delete_plan_parameters, T>): Promise<core_competency_delete_plan_returns> =>
      this.callWebservice('core_competency_delete_plan', params);
  
    /**
     * List a user's learning plans.
     */
    public core_competency_list_user_plans = async <T extends core_competency_list_user_plans_parameters>(params: NoExtraProperties<core_competency_list_user_plans_parameters, T>): Promise<core_competency_list_user_plans_returns_item[]> =>
      this.callWebservice('core_competency_list_user_plans', params);
  
    /**
     * List the competencies in a plan
     */
    public core_competency_list_plan_competencies = async <T extends core_competency_list_plan_competencies_parameters>(params: NoExtraProperties<core_competency_list_plan_competencies_parameters, T>): Promise<core_competency_list_plan_competencies_returns_item[]> =>
      this.callWebservice('core_competency_list_plan_competencies', params);
  
    /**
     * Add the competency to a learning plan
     */
    public core_competency_add_competency_to_plan = async <T extends core_competency_add_competency_to_plan_parameters>(params: NoExtraProperties<core_competency_add_competency_to_plan_parameters, T>): Promise<core_competency_add_competency_to_plan_returns> =>
      this.callWebservice('core_competency_add_competency_to_plan', params);
  
    /**
     * Remove the competency from a learning plan
     */
    public core_competency_remove_competency_from_plan = async <T extends core_competency_remove_competency_from_plan_parameters>(params: NoExtraProperties<core_competency_remove_competency_from_plan_parameters, T>): Promise<core_competency_remove_competency_from_plan_returns> =>
      this.callWebservice('core_competency_remove_competency_from_plan', params);
  
    /**
     * Move a plan competency to a new relative sort order.
     */
    public core_competency_reorder_plan_competency = async <T extends core_competency_reorder_plan_competency_parameters>(params: NoExtraProperties<core_competency_reorder_plan_competency_parameters, T>): Promise<core_competency_reorder_plan_competency_returns> =>
      this.callWebservice('core_competency_reorder_plan_competency', params);
  
    /**
     * Request for a plan to be reviewed.
     */
    public core_competency_plan_request_review = async <T extends core_competency_plan_request_review_parameters>(params: NoExtraProperties<core_competency_plan_request_review_parameters, T>): Promise<core_competency_plan_request_review_returns> =>
      this.callWebservice('core_competency_plan_request_review', params);
  
    /**
     * Start the review of a plan.
     */
    public core_competency_plan_start_review = async <T extends core_competency_plan_start_review_parameters>(params: NoExtraProperties<core_competency_plan_start_review_parameters, T>): Promise<core_competency_plan_start_review_returns> =>
      this.callWebservice('core_competency_plan_start_review', params);
  
    /**
     * Stop the review of a plan.
     */
    public core_competency_plan_stop_review = async <T extends core_competency_plan_stop_review_parameters>(params: NoExtraProperties<core_competency_plan_stop_review_parameters, T>): Promise<core_competency_plan_stop_review_returns> =>
      this.callWebservice('core_competency_plan_stop_review', params);
  
    /**
     * Cancel the review of a plan.
     */
    public core_competency_plan_cancel_review_request = async <T extends core_competency_plan_cancel_review_request_parameters>(params: NoExtraProperties<core_competency_plan_cancel_review_request_parameters, T>): Promise<core_competency_plan_cancel_review_request_returns> =>
      this.callWebservice('core_competency_plan_cancel_review_request', params);
  
    /**
     * Approve a plan.
     */
    public core_competency_approve_plan = async <T extends core_competency_approve_plan_parameters>(params: NoExtraProperties<core_competency_approve_plan_parameters, T>): Promise<core_competency_approve_plan_returns> =>
      this.callWebservice('core_competency_approve_plan', params);
  
    /**
     * Unapprove a plan.
     */
    public core_competency_unapprove_plan = async <T extends core_competency_unapprove_plan_parameters>(params: NoExtraProperties<core_competency_unapprove_plan_parameters, T>): Promise<core_competency_unapprove_plan_returns> =>
      this.callWebservice('core_competency_unapprove_plan', params);
  
    /**
     * Check if a template has related data
     */
    public core_competency_template_has_related_data = async <T extends core_competency_template_has_related_data_parameters>(params: NoExtraProperties<core_competency_template_has_related_data_parameters, T>): Promise<core_competency_template_has_related_data_returns> =>
      this.callWebservice('core_competency_template_has_related_data', params);
  
    /**
     * Fetch the values for a specific scale
     */
    public core_competency_get_scale_values = async <T extends core_competency_get_scale_values_parameters>(params: NoExtraProperties<core_competency_get_scale_values_parameters, T>): Promise<core_competency_get_scale_values_returns_item[]> =>
      this.callWebservice('core_competency_get_scale_values', params);
  
    /**
     * Adds a related competency
     */
    public core_competency_add_related_competency = async <T extends core_competency_add_related_competency_parameters>(params: NoExtraProperties<core_competency_add_related_competency_parameters, T>): Promise<core_competency_add_related_competency_returns> =>
      this.callWebservice('core_competency_add_related_competency', params);
  
    /**
     * Remove a related competency
     */
    public core_competency_remove_related_competency = async <T extends core_competency_remove_related_competency_parameters>(params: NoExtraProperties<core_competency_remove_related_competency_parameters, T>): Promise<core_competency_remove_related_competency_returns> =>
      this.callWebservice('core_competency_remove_related_competency', params);
  
    /**
     * Read an evidence of prior learning.
     */
    public core_competency_read_user_evidence = async <T extends core_competency_read_user_evidence_parameters>(params: NoExtraProperties<core_competency_read_user_evidence_parameters, T>): Promise<core_competency_read_user_evidence_returns> =>
      this.callWebservice('core_competency_read_user_evidence', params);
  
    /**
     * Delete an evidence of prior learning.
     */
    public core_competency_delete_user_evidence = async <T extends core_competency_delete_user_evidence_parameters>(params: NoExtraProperties<core_competency_delete_user_evidence_parameters, T>): Promise<core_competency_delete_user_evidence_returns> =>
      this.callWebservice('core_competency_delete_user_evidence', params);
  
    /**
     * Create an evidence of prior learning relationship with a competency.
     */
    public core_competency_create_user_evidence_competency = async <T extends core_competency_create_user_evidence_competency_parameters>(params: NoExtraProperties<core_competency_create_user_evidence_competency_parameters, T>): Promise<core_competency_create_user_evidence_competency_returns> =>
      this.callWebservice('core_competency_create_user_evidence_competency', params);
  
    /**
     * Delete an evidence of prior learning relationship with a competency.
     */
    public core_competency_delete_user_evidence_competency = async <T extends core_competency_delete_user_evidence_competency_parameters>(params: NoExtraProperties<core_competency_delete_user_evidence_competency_parameters, T>): Promise<core_competency_delete_user_evidence_competency_returns> =>
      this.callWebservice('core_competency_delete_user_evidence_competency', params);
  
    /**
     * Cancel a review request.
     */
    public core_competency_user_competency_cancel_review_request = async <T extends core_competency_user_competency_cancel_review_request_parameters>(params: NoExtraProperties<core_competency_user_competency_cancel_review_request_parameters, T>): Promise<core_competency_user_competency_cancel_review_request_returns> =>
      this.callWebservice('core_competency_user_competency_cancel_review_request', params);
  
    /**
     * Request a review.
     */
    public core_competency_user_competency_request_review = async <T extends core_competency_user_competency_request_review_parameters>(params: NoExtraProperties<core_competency_user_competency_request_review_parameters, T>): Promise<core_competency_user_competency_request_review_returns> =>
      this.callWebservice('core_competency_user_competency_request_review', params);
  
    /**
     * Start a review.
     */
    public core_competency_user_competency_start_review = async <T extends core_competency_user_competency_start_review_parameters>(params: NoExtraProperties<core_competency_user_competency_start_review_parameters, T>): Promise<core_competency_user_competency_start_review_returns> =>
      this.callWebservice('core_competency_user_competency_start_review', params);
  
    /**
     * Stop a review.
     */
    public core_competency_user_competency_stop_review = async <T extends core_competency_user_competency_stop_review_parameters>(params: NoExtraProperties<core_competency_user_competency_stop_review_parameters, T>): Promise<core_competency_user_competency_stop_review_returns> =>
      this.callWebservice('core_competency_user_competency_stop_review', params);
  
    /**
     * Log the user competency viewed event.
     */
    public core_competency_user_competency_viewed = async <T extends core_competency_user_competency_viewed_parameters>(params: NoExtraProperties<core_competency_user_competency_viewed_parameters, T>): Promise<core_competency_user_competency_viewed_returns> =>
      this.callWebservice('core_competency_user_competency_viewed', params);
  
    /**
     * Log the user competency viewed in plan event.
     */
    public core_competency_user_competency_viewed_in_plan = async <T extends core_competency_user_competency_viewed_in_plan_parameters>(params: NoExtraProperties<core_competency_user_competency_viewed_in_plan_parameters, T>): Promise<core_competency_user_competency_viewed_in_plan_returns> =>
      this.callWebservice('core_competency_user_competency_viewed_in_plan', params);
  
    /**
     * Log the user competency viewed in course event
     */
    public core_competency_user_competency_viewed_in_course = async <T extends core_competency_user_competency_viewed_in_course_parameters>(params: NoExtraProperties<core_competency_user_competency_viewed_in_course_parameters, T>): Promise<core_competency_user_competency_viewed_in_course_returns> =>
      this.callWebservice('core_competency_user_competency_viewed_in_course', params);
  
    /**
     * Log the user competency plan viewed event.
     */
    public core_competency_user_competency_plan_viewed = async <T extends core_competency_user_competency_plan_viewed_parameters>(params: NoExtraProperties<core_competency_user_competency_plan_viewed_parameters, T>): Promise<core_competency_user_competency_plan_viewed_returns> =>
      this.callWebservice('core_competency_user_competency_plan_viewed', params);
  
    /**
     * Grade a competency.
     */
    public core_competency_grade_competency = async <T extends core_competency_grade_competency_parameters>(params: NoExtraProperties<core_competency_grade_competency_parameters, T>): Promise<core_competency_grade_competency_returns> =>
      this.callWebservice('core_competency_grade_competency', params);
  
    /**
     * Grade a competency from the user plan page.
     */
    public core_competency_grade_competency_in_plan = async <T extends core_competency_grade_competency_in_plan_parameters>(params: NoExtraProperties<core_competency_grade_competency_in_plan_parameters, T>): Promise<core_competency_grade_competency_in_plan_returns> =>
      this.callWebservice('core_competency_grade_competency_in_plan', params);
  
    /**
     * Grade a competency from the course page.
     */
    public core_competency_grade_competency_in_course = async <T extends core_competency_grade_competency_in_course_parameters>(params: NoExtraProperties<core_competency_grade_competency_in_course_parameters, T>): Promise<core_competency_grade_competency_in_course_returns> =>
      this.callWebservice('core_competency_grade_competency_in_course', params);
  
    /**
     * Unlink a plan form it template.
     */
    public core_competency_unlink_plan_from_template = async <T extends core_competency_unlink_plan_from_template_parameters>(params: NoExtraProperties<core_competency_unlink_plan_from_template_parameters, T>): Promise<core_competency_unlink_plan_from_template_returns> =>
      this.callWebservice('core_competency_unlink_plan_from_template', params);
  
    /**
     * Log event template viewed
     */
    public core_competency_template_viewed = async <T extends core_competency_template_viewed_parameters>(params: NoExtraProperties<core_competency_template_viewed_parameters, T>): Promise<core_competency_template_viewed_returns> =>
      this.callWebservice('core_competency_template_viewed', params);
  
    /**
     * Send user evidence competencies in review
     */
    public core_competency_request_review_of_user_evidence_linked_competencies = async <T extends core_competency_request_review_of_user_evidence_linked_competencies_parameters>(params: NoExtraProperties<core_competency_request_review_of_user_evidence_linked_competencies_parameters, T>): Promise<core_competency_request_review_of_user_evidence_linked_competencies_returns> =>
      this.callWebservice('core_competency_request_review_of_user_evidence_linked_competencies', params);
  
    /**
     * Update the course competency settings
     */
    public core_competency_update_course_competency_settings = async <T extends core_competency_update_course_competency_settings_parameters>(params: NoExtraProperties<core_competency_update_course_competency_settings_parameters, T>): Promise<core_competency_update_course_competency_settings_returns> =>
      this.callWebservice('core_competency_update_course_competency_settings', params);
  
    /**
     * Delete an evidence
     */
    public core_competency_delete_evidence = async <T extends core_competency_delete_evidence_parameters>(params: NoExtraProperties<core_competency_delete_evidence_parameters, T>): Promise<core_competency_delete_evidence_returns> =>
      this.callWebservice('core_competency_delete_evidence', params);
  
    /**
     * Return some site info / user info / list web service functions
     */
    public core_webservice_get_site_info = async <T extends core_webservice_get_site_info_parameters>(params: NoExtraProperties<core_webservice_get_site_info_parameters, T>): Promise<core_webservice_get_site_info_returns> =>
      this.callWebservice('core_webservice_get_site_info', params);
  
    /**
     * Returns blocks information for a course.
     */
    public core_block_get_course_blocks = async <T extends core_block_get_course_blocks_parameters>(params: NoExtraProperties<core_block_get_course_blocks_parameters, T>): Promise<core_block_get_course_blocks_returns> =>
      this.callWebservice('core_block_get_course_blocks', params);
  
    /**
     * Returns blocks information for the given user dashboard.
     */
    public core_block_get_dashboard_blocks = async <T extends core_block_get_dashboard_blocks_parameters>(params: NoExtraProperties<core_block_get_dashboard_blocks_parameters, T>): Promise<core_block_get_dashboard_blocks_returns> =>
      this.callWebservice('core_block_get_dashboard_blocks', params);
  
    /**
     * Returns all addable blocks in a given page.
     */
    public core_block_fetch_addable_blocks = async <T extends core_block_fetch_addable_blocks_parameters>(params: NoExtraProperties<core_block_fetch_addable_blocks_parameters, T>): Promise<core_block_fetch_addable_blocks_returns_item[]> =>
      this.callWebservice('core_block_fetch_addable_blocks', params);
  
    /**
     * Returns the filters available in the given contexts.
     */
    public core_filters_get_available_in_context = async <T extends core_filters_get_available_in_context_parameters>(params: NoExtraProperties<core_filters_get_available_in_context_parameters, T>): Promise<core_filters_get_available_in_context_returns> =>
      this.callWebservice('core_filters_get_available_in_context', params);
  
    /**
     * Deletes an entry
     */
    public core_customfield_delete_field = async <T extends core_customfield_delete_field_parameters>(params: NoExtraProperties<core_customfield_delete_field_parameters, T>): Promise<core_customfield_delete_field_returns> =>
      this.callWebservice('core_customfield_delete_field', params);
  
    /**
     * Reloads template
     */
    public core_customfield_reload_template = async <T extends core_customfield_reload_template_parameters>(params: NoExtraProperties<core_customfield_reload_template_parameters, T>): Promise<core_customfield_reload_template_returns> =>
      this.callWebservice('core_customfield_reload_template', params);
  
    /**
     * Creates a new category
     */
    public core_customfield_create_category = async <T extends core_customfield_create_category_parameters>(params: NoExtraProperties<core_customfield_create_category_parameters, T>): Promise<core_customfield_create_category_returns> =>
      this.callWebservice('core_customfield_create_category', params);
  
    /**
     * Deletes a category
     */
    public core_customfield_delete_category = async <T extends core_customfield_delete_category_parameters>(params: NoExtraProperties<core_customfield_delete_category_parameters, T>): Promise<core_customfield_delete_category_returns> =>
      this.callWebservice('core_customfield_delete_category', params);
  
    /**
     * Drag and drop
     */
    public core_customfield_move_field = async <T extends core_customfield_move_field_parameters>(params: NoExtraProperties<core_customfield_move_field_parameters, T>): Promise<core_customfield_move_field_returns> =>
      this.callWebservice('core_customfield_move_field', params);
  
    /**
     * Drag and drop categories
     */
    public core_customfield_move_category = async <T extends core_customfield_move_category_parameters>(params: NoExtraProperties<core_customfield_move_category_parameters, T>): Promise<core_customfield_move_category_returns> =>
      this.callWebservice('core_customfield_move_category', params);
  
    /**
     * Get the H5P file cleaned for Mobile App.
     */
    public core_h5p_get_trusted_h5p_file = async <T extends core_h5p_get_trusted_h5p_file_parameters>(params: NoExtraProperties<core_h5p_get_trusted_h5p_file_parameters, T>): Promise<core_h5p_get_trusted_h5p_file_returns> =>
      this.callWebservice('core_h5p_get_trusted_h5p_file', params);
  
    /**
     * Get the dynamic table content raw html
     */
    public core_table_get_dynamic_table_content = async <T extends core_table_get_dynamic_table_content_parameters>(params: NoExtraProperties<core_table_get_dynamic_table_content_parameters, T>): Promise<core_table_get_dynamic_table_content_returns> =>
      this.callWebservice('core_table_get_dynamic_table_content', params);
  
    /**
     * Post an xAPI statement.
     */
    public core_xapi_statement_post = async <T extends core_xapi_statement_post_parameters>(params: NoExtraProperties<core_xapi_statement_post_parameters, T>): Promise<core_xapi_statement_post_returns_item[]> =>
      this.callWebservice('core_xapi_statement_post', params);
  
    /**
     * Delete a content from the content bank.
     */
    public core_contentbank_delete_content = async <T extends core_contentbank_delete_content_parameters>(params: NoExtraProperties<core_contentbank_delete_content_parameters, T>): Promise<core_contentbank_delete_content_returns> =>
      this.callWebservice('core_contentbank_delete_content', params);
  
    /**
     * Rename a content in the content bank.
     */
    public core_contentbank_rename_content = async <T extends core_contentbank_rename_content_parameters>(params: NoExtraProperties<core_contentbank_rename_content_parameters, T>): Promise<core_contentbank_rename_content_returns> =>
      this.callWebservice('core_contentbank_rename_content', params);
  
    /**
     * Set the visibility of a content in the content bank.
     */
    public core_contentbank_set_content_visibility = async <T extends core_contentbank_set_content_visibility_parameters>(params: NoExtraProperties<core_contentbank_set_content_visibility_parameters, T>): Promise<core_contentbank_set_content_visibility_returns> =>
      this.callWebservice('core_contentbank_set_content_visibility', params);
  
    /**
     * Record the action that the user takes in the user feedback notification for future use.
     */
    public core_create_userfeedback_action_record = async <T extends core_create_userfeedback_action_record_parameters>(params: NoExtraProperties<core_create_userfeedback_action_record_parameters, T>): Promise<core_create_userfeedback_action_record_returns> =>
      this.callWebservice('core_create_userfeedback_action_record', params);
  
    /**
     * Get the list of payment gateways that support the given component/area
     */
    public core_payment_get_available_gateways = async <T extends core_payment_get_available_gateways_parameters>(params: NoExtraProperties<core_payment_get_available_gateways_parameters, T>): Promise<core_payment_get_available_gateways_returns_item[]> =>
      this.callWebservice('core_payment_get_available_gateways', params);
  
    /**
     * Reset filters for given report
     */
    public core_reportbuilder_filters_reset = async <T extends core_reportbuilder_filters_reset_parameters>(params: NoExtraProperties<core_reportbuilder_filters_reset_parameters, T>): Promise<core_reportbuilder_filters_reset_returns> =>
      this.callWebservice('core_reportbuilder_filters_reset', params);
  
    /**
     * Set filter values for given report
     */
    public core_reportbuilder_set_filters = async <T extends core_reportbuilder_set_filters_parameters>(params: NoExtraProperties<core_reportbuilder_set_filters_parameters, T>): Promise<core_reportbuilder_set_filters_returns> =>
      this.callWebservice('core_reportbuilder_set_filters', params);
  
    /**
     * Returns the content for a dynamic tab
     */
    public core_dynamic_tabs_get_content = async <T extends core_dynamic_tabs_get_content_parameters>(params: NoExtraProperties<core_dynamic_tabs_get_content_parameters, T>): Promise<core_dynamic_tabs_get_content_returns> =>
      this.callWebservice('core_dynamic_tabs_get_content', params);
  
    /**
     * Change the editing mode
     */
    public core_change_editmode = async <T extends core_change_editmode_parameters>(params: NoExtraProperties<core_change_editmode_parameters, T>): Promise<core_change_editmode_returns> =>
      this.callWebservice('core_change_editmode', params);
  
    /**
     * Delete report
     */
    public core_reportbuilder_reports_delete = async <T extends core_reportbuilder_reports_delete_parameters>(params: NoExtraProperties<core_reportbuilder_reports_delete_parameters, T>): Promise<core_reportbuilder_reports_delete_returns> =>
      this.callWebservice('core_reportbuilder_reports_delete', params);
  
    /**
     * Get custom report
     */
    public core_reportbuilder_reports_get = async <T extends core_reportbuilder_reports_get_parameters>(params: NoExtraProperties<core_reportbuilder_reports_get_parameters, T>): Promise<core_reportbuilder_reports_get_returns> =>
      this.callWebservice('core_reportbuilder_reports_get', params);
  
    /**
     * List custom reports for current user
     */
    public core_reportbuilder_list_reports = async <T extends core_reportbuilder_list_reports_parameters>(params: NoExtraProperties<core_reportbuilder_list_reports_parameters, T>): Promise<core_reportbuilder_list_reports_returns> =>
      this.callWebservice('core_reportbuilder_list_reports', params);
  
    /**
     * Retrieve custom report content
     */
    public core_reportbuilder_retrieve_report = async <T extends core_reportbuilder_retrieve_report_parameters>(params: NoExtraProperties<core_reportbuilder_retrieve_report_parameters, T>): Promise<core_reportbuilder_retrieve_report_returns> =>
      this.callWebservice('core_reportbuilder_retrieve_report', params);
  
    /**
     * Trigger custom report viewed
     */
    public core_reportbuilder_view_report = async <T extends core_reportbuilder_view_report_parameters>(params: NoExtraProperties<core_reportbuilder_view_report_parameters, T>): Promise<core_reportbuilder_view_report_returns> =>
      this.callWebservice('core_reportbuilder_view_report', params);
  
    /**
     * Add column to report
     */
    public core_reportbuilder_columns_add = async <T extends core_reportbuilder_columns_add_parameters>(params: NoExtraProperties<core_reportbuilder_columns_add_parameters, T>): Promise<core_reportbuilder_columns_add_returns> =>
      this.callWebservice('core_reportbuilder_columns_add', params);
  
    /**
     * Delete column from report
     */
    public core_reportbuilder_columns_delete = async <T extends core_reportbuilder_columns_delete_parameters>(params: NoExtraProperties<core_reportbuilder_columns_delete_parameters, T>): Promise<core_reportbuilder_columns_delete_returns> =>
      this.callWebservice('core_reportbuilder_columns_delete', params);
  
    /**
     * Re-order column within report
     */
    public core_reportbuilder_columns_reorder = async <T extends core_reportbuilder_columns_reorder_parameters>(params: NoExtraProperties<core_reportbuilder_columns_reorder_parameters, T>): Promise<core_reportbuilder_columns_reorder_returns> =>
      this.callWebservice('core_reportbuilder_columns_reorder', params);
  
    /**
     * Retrieve column sorting for report
     */
    public core_reportbuilder_columns_sort_get = async <T extends core_reportbuilder_columns_sort_get_parameters>(params: NoExtraProperties<core_reportbuilder_columns_sort_get_parameters, T>): Promise<core_reportbuilder_columns_sort_get_returns> =>
      this.callWebservice('core_reportbuilder_columns_sort_get', params);
  
    /**
     * Re-order column sorting within report
     */
    public core_reportbuilder_columns_sort_reorder = async <T extends core_reportbuilder_columns_sort_reorder_parameters>(params: NoExtraProperties<core_reportbuilder_columns_sort_reorder_parameters, T>): Promise<core_reportbuilder_columns_sort_reorder_returns> =>
      this.callWebservice('core_reportbuilder_columns_sort_reorder', params);
  
    /**
     * Toggle sorting of column within report
     */
    public core_reportbuilder_columns_sort_toggle = async <T extends core_reportbuilder_columns_sort_toggle_parameters>(params: NoExtraProperties<core_reportbuilder_columns_sort_toggle_parameters, T>): Promise<core_reportbuilder_columns_sort_toggle_returns> =>
      this.callWebservice('core_reportbuilder_columns_sort_toggle', params);
  
    /**
     * Add condition to report
     */
    public core_reportbuilder_conditions_add = async <T extends core_reportbuilder_conditions_add_parameters>(params: NoExtraProperties<core_reportbuilder_conditions_add_parameters, T>): Promise<core_reportbuilder_conditions_add_returns> =>
      this.callWebservice('core_reportbuilder_conditions_add', params);
  
    /**
     * Delete condition from report
     */
    public core_reportbuilder_conditions_delete = async <T extends core_reportbuilder_conditions_delete_parameters>(params: NoExtraProperties<core_reportbuilder_conditions_delete_parameters, T>): Promise<core_reportbuilder_conditions_delete_returns> =>
      this.callWebservice('core_reportbuilder_conditions_delete', params);
  
    /**
     * Re-order condition within report
     */
    public core_reportbuilder_conditions_reorder = async <T extends core_reportbuilder_conditions_reorder_parameters>(params: NoExtraProperties<core_reportbuilder_conditions_reorder_parameters, T>): Promise<core_reportbuilder_conditions_reorder_returns> =>
      this.callWebservice('core_reportbuilder_conditions_reorder', params);
  
    /**
     * Reset conditions for given report
     */
    public core_reportbuilder_conditions_reset = async <T extends core_reportbuilder_conditions_reset_parameters>(params: NoExtraProperties<core_reportbuilder_conditions_reset_parameters, T>): Promise<core_reportbuilder_conditions_reset_returns> =>
      this.callWebservice('core_reportbuilder_conditions_reset', params);
  
    /**
     * Add filter to report
     */
    public core_reportbuilder_filters_add = async <T extends core_reportbuilder_filters_add_parameters>(params: NoExtraProperties<core_reportbuilder_filters_add_parameters, T>): Promise<core_reportbuilder_filters_add_returns> =>
      this.callWebservice('core_reportbuilder_filters_add', params);
  
    /**
     * Delete filter from report
     */
    public core_reportbuilder_filters_delete = async <T extends core_reportbuilder_filters_delete_parameters>(params: NoExtraProperties<core_reportbuilder_filters_delete_parameters, T>): Promise<core_reportbuilder_filters_delete_returns> =>
      this.callWebservice('core_reportbuilder_filters_delete', params);
  
    /**
     * Re-order filter within report
     */
    public core_reportbuilder_filters_reorder = async <T extends core_reportbuilder_filters_reorder_parameters>(params: NoExtraProperties<core_reportbuilder_filters_reorder_parameters, T>): Promise<core_reportbuilder_filters_reorder_returns> =>
      this.callWebservice('core_reportbuilder_filters_reorder', params);
  
    /**
     * Delete audience from report
     */
    public core_reportbuilder_audiences_delete = async <T extends core_reportbuilder_audiences_delete_parameters>(params: NoExtraProperties<core_reportbuilder_audiences_delete_parameters, T>): Promise<core_reportbuilder_audiences_delete_returns> =>
      this.callWebservice('core_reportbuilder_audiences_delete', params);
  
    /**
     * Delete schedule from report
     */
    public core_reportbuilder_schedules_delete = async <T extends core_reportbuilder_schedules_delete_parameters>(params: NoExtraProperties<core_reportbuilder_schedules_delete_parameters, T>): Promise<core_reportbuilder_schedules_delete_returns> =>
      this.callWebservice('core_reportbuilder_schedules_delete', params);
  
    /**
     * Send report schedule
     */
    public core_reportbuilder_schedules_send = async <T extends core_reportbuilder_schedules_send_parameters>(params: NoExtraProperties<core_reportbuilder_schedules_send_parameters, T>): Promise<core_reportbuilder_schedules_send_returns> =>
      this.callWebservice('core_reportbuilder_schedules_send', params);
  
    /**
     * Toggle state of report schedule
     */
    public core_reportbuilder_schedules_toggle = async <T extends core_reportbuilder_schedules_toggle_parameters>(params: NoExtraProperties<core_reportbuilder_schedules_toggle_parameters, T>): Promise<core_reportbuilder_schedules_toggle_returns> =>
      this.callWebservice('core_reportbuilder_schedules_toggle', params);
  
    /**
     * Retrieve a list of popup notifications for a user
     */
    public message_popup_get_popup_notifications = async <T extends message_popup_get_popup_notifications_parameters>(params: NoExtraProperties<message_popup_get_popup_notifications_parameters, T>): Promise<message_popup_get_popup_notifications_returns> =>
      this.callWebservice('message_popup_get_popup_notifications', params);
  
    /**
     * Retrieve the count of unread popup notifications for a given user
     */
    public message_popup_get_unread_popup_notification_count = async <T extends message_popup_get_unread_popup_notification_count_parameters>(params: NoExtraProperties<message_popup_get_unread_popup_notification_count_parameters, T>): Promise<message_popup_get_unread_popup_notification_count_returns> =>
      this.callWebservice('message_popup_get_unread_popup_notification_count', params);
  }
}
