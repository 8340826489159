import { ItemStatus } from '@gtn/app-common/api/model/ExampleAndItemResponse';
import { getItemStatusIcon, ITEM_STATUS_DISPLAY_VALUES_STUDENT } from '@gtn/app-common/components/example-item-list/ItemStatusDisplay';
import { CreateEditExample } from '@gtn/app-common/components/examples/create-edit-example/CreateEditExample';
import ExampleItemDialog from '@gtn/app-common/components/submit-item/ExampleItemDialog';
import { useAppDispatch, useSelectedCourse, useSelectedStudent } from '@gtn/app-common/store/app.store.hooks';
import { MoodleWebservice } from '@gtn/common/api/webservice/MoodleWebservice';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import LoadingContainer from '@gtn/common/components/loading-container/LoadingContainerV2';
import { useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useIsTeacher } from '@gtn/common/store/user/user.hooks';
import { ThemeManager } from '@gtn/common/theme/ThemeManager';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { DakoraAPI } from '@gtn/dakora/api/DakoraAPI';
import { appDAKORAActions } from '@gtn/dakora/store/DakoraState';
import { useDakoraSelector } from '@gtn/dakora/store/DakoraStore';
import { LearningPathUtils } from '@gtn/dakora/utils/LearningPathUtils';
import { ArrowBack, ChevronRight } from '@material-ui/icons';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './ChooseLearningPathExample.module.scss';

export interface ChooseLearningPathExampleProps {
  courseId: number;
  userId?: number;

  title?: string;
  description?: string;
}

export function ChooseLearningPathExample(props: ChooseLearningPathExampleProps) {
  const t = useAppTranslation();
  const dispatch = useAppDispatch();
  const selectedCourse = useSelectedCourse();
  const selectedStudent = useSelectedStudent();
  const isTeacher = useIsTeacher();
  const [selectedLearningPathId, setSelectedLearningPathId] = useState(-1);

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isDraggingHover, setIsDraggingHover] = useState<boolean>(false);
  const draggingExample = useDakoraSelector((state) => state.appdakora.learningPlan.draggingPlanningStorageExample);
  const exampleItemDialog = useGtnDialog(ExampleItemDialog);

  const moodleAPI = InjectionContainer.resolve(MoodleWebservice);
  const themeManager = InjectionContainer.resolve(ThemeManager);

  const { data: learningPathsData, progressState: learningPathsProgressState } = useAPI(moodleAPI.diggrplus_learningpath_list, [
    {
      courseid: selectedCourse?.id ?? 0,
      studentid: selectedStudent?.id ?? 0,
    },
  ]);

  const learningPaths = useMemo(
    () =>
      learningPathsData?.learningpaths
        ?.filter((lp) => LearningPathUtils.getLearningPathStatus(lp).statusMapping !== ItemStatus.COMPLETED)
        ?.filter((lp) => {
          if (isTeacher && selectedStudent == null) {
            return true;
          }
          return lp.visible;
        }) || [],
    [learningPathsData]
  );

  const {
    data: learningPathDetails,
    progressState: learningPathDetailsProgressState,
    mutate: reloadLearningPathDetails,
  } = useAPI(
    moodleAPI.diggrplus_learningpath_details,
    [
      {
        id: selectedLearningPathId,
        studentid: selectedStudent?.id ?? 0,
      },
    ],
    {
      enabled: selectedLearningPathId >= 0,
    }
  );

  useEffect(() => {
    if (draggingExample) {
      setIsDragging(false);
      dispatch(appDAKORAActions.setDraggingExercise());
    }
  }, []);

  const onDragEnter = (e: React.DragEvent<HTMLElement>) => {
    if (!isDragging && selectedLearningPathId > 0) {
      e.dataTransfer.dropEffect = 'move';
      setIsDraggingHover(true);
    }
    e.preventDefault();
  };

  const onDragLeave = (e: React.DragEvent<HTMLElement>) => {
    if (isDraggingHover) {
      setIsDraggingHover(false);
    }
  };

  const onDragOver = (e: React.DragEvent<HTMLElement>) => {
    if (!isDragging) {
      e.preventDefault();
    }
  };

  const onDrop = async (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();

    if (draggingExample && !learningPathDetails?.items?.some((e) => e.exampleid === draggingExample.exampleid)) {
      try {
        await moodleAPI.diggrplus_learningpath_add_items({
          learningpathid: learningPathDetails!.id,
          exampleids: [draggingExample.exampleid],
          studentids: isTeacher && props.userId ? [props.userId] : undefined,
        });
      } catch (e) {
        GtnLogger.warn(e);
      }
    }

    await onDragEnd(e);
    await reloadLearningPathDetails();
  };

  const onDragStart = (e: React.DragEvent<HTMLElement>) => {
    setIsDragging(true);

    const example = learningPathDetails?.items?.find((ex) => ex.exampleid === Number((e.target as HTMLElement).id));
    if (example) {
      dispatch(appDAKORAActions.setDraggingExercise({ exampleid: example.exampleid, title: example.exampletitle, courseid: selectedCourse!.id, userId: props.userId } as any));
    }
  };

  const onDragEnd = async (e: React.DragEvent<HTMLElement>) => {
    setIsDragging(false);
    dispatch(appDAKORAActions.setDraggingExercise());
  };

  const title = t('learning-paths.headline', { name: props.title });

  function renderLearningPlansList() {
    return (
      <LoadingContainer state={learningPathsProgressState} className={styles.examplesContainer}>
        {learningPaths?.map((learningPath) => (
          <div className={classNames(styles.example)} onClick={() => setSelectedLearningPathId(learningPath.id)} key={'learning-path-' + learningPath.id} style={{ cursor: 'pointer' }}>
            <div className={styles.textContainer}>
              <p className={styles.title}>{learningPath.title}</p>
              <p className={styles.description}>
                {isTeacher && selectedStudent == null
                  ? t(learningPath.visible ? 'learning-paths.visible-for-all-students' : 'learning-paths.visible-for-only-teachers')
                  : t(LearningPathUtils.getLearningPathStatus(learningPath)?.textResId ?? '')}
              </p>
            </div>
            <ChevronRight />
          </div>
        ))}
      </LoadingContainer>
    );
  }

  function renderLearningPathDetails() {
    return (
      <>
        <div className={styles.showAll} onClick={() => setSelectedLearningPathId(-1)}>
          <ArrowBack fontSize="small" />
          <span>{t('learning-paths.show-all')}</span>
        </div>

        <LoadingContainer state={learningPathDetailsProgressState} className={classNames(styles.examplesContainer)}>
          {learningPathDetails?.items
            ?.filter((i) => i.visiblestudent)
            .map((item) => {
              const itemStatusDisplay = ITEM_STATUS_DISPLAY_VALUES_STUDENT.find((s) => s.statusMapping === item.status);

              return (
                <div
                  key={'learning-path-item-' + item.id}
                  id={String(item.exampleid)}
                  className={classNames({
                    [styles.example]: true,
                    [styles.dragSource]: isDragging && draggingExample?.exampleid === item.exampleid,
                  })}
                  style={{ backgroundColor: themeManager.getColorForId(selectedCourse?.id, 'grey') }}
                  draggable
                  onDragStart={onDragStart}
                  onDragEnd={onDragEnd}
                >
                  <img src={getItemStatusIcon(item.status)} className={styles.icon} />
                  <div className={styles.textContainer}>
                    <p
                      className={styles.title}
                      onClick={() => {
                        exampleItemDialog.open({ exampleId: item.exampleid, studentId: isTeacher ? props.userId : undefined });
                      }}
                    >
                      {item.exampletitle}
                    </p>
                    <p className={styles.description}>{isTeacher && !props.userId ? null : t(itemStatusDisplay?.textResId ?? '')}</p>
                  </div>
                </div>
              );
            })}
        </LoadingContainer>
      </>
    );
  }

  return (
    <div
      className={classNames(styles.container, selectedLearningPathId > 0 && !isDragging && draggingExample ? styles.dragHover : undefined)}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onDragOver={onDragOver}
    >
      <div className={styles.header}>
        <h3 className={styles.title}>{learningPathDetails?.title ?? title}</h3>
      </div>

      {props.description && <p className={styles.info}>{props.description}</p>}

      {selectedLearningPathId <= 0 ? renderLearningPlansList() : renderLearningPathDetails()}

      <exampleItemDialog.Component onSave={() => reloadLearningPathDetails()} />
    </div>
  );
}
