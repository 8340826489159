import { BaseMoodleAPI } from '@gtn/common/api/BaseMoodleAPI';
import { CourseExperienceLevel } from '@gtn/common/api/model/Course';
import { PlanningStorageExample } from '@gtn/common/api/model/exacomp';
import { SuccessOperationResponse } from '@gtn/common/api/model/SuccessOperationResponse';
import { dakora_get_schedule_config_returns } from '@gtn/common/api/webservice/MoodleWebserviceDefinitions';
import { Utils } from '@gtn/common/utils/Utils';
import { BlockingEvent } from '@gtn/dakora/api/model/BlockingEvent';
import { FreeExample } from '@gtn/dakora/api/model/FreeExample';
import { ScheduledExample } from '@gtn/dakora/api/model/ScheduledExample';
import { endOfWeek } from 'date-fns';
import startOfWeek from 'date-fns/startOfWeek';
import { singleton } from 'tsyringe';

@singleton()
export class DakoraAPI extends BaseMoodleAPI {
  public getPlanningStorageExamples = async (courseId: number, userId: number = 0): Promise<PlanningStorageExample[]> => {
    const result = await this.moodleWebservice.dakora_get_examples_pool({
      courseid: courseId,
      userid: userId,
    });

    // Remove duplicates
    return result.filter((v, i, a) => a.findIndex((t) => t.exampleid === v.exampleid) === i);
  };

  public getScheduleCalendarConfig = async (): Promise<dakora_get_schedule_config_returns> => {
    return this.moodleWebservice.dakora_get_schedule_config();
  };

  public getScheduledExamples = async (
    userId: number = 0,
    start: Date = startOfWeek(new Date(), { weekStartsOn: 1 }),
    end: Date = endOfWeek(new Date(), { weekStartsOn: 1 })
  ): Promise<ScheduledExample[]> => {
    return this.moodleWebservice.dakora_get_examples_for_time_slot({
      userid: userId,
      start: this.toTimestamp(start),
      end: this.toTimestamp(end),
    });
  };

  public addBlockingEventToPlanningStorage = async (courseId: number, userId: number = 0, event: BlockingEvent): Promise<{ scheduleid: number }> => {
    return this.moodleWebservice.dakora_create_blocking_event({
      courseid: courseId,
      userid: userId,
      preplanningstorage: false,
      ...event,
      description: event.description ?? '',
      timeframe: event.timeframe ?? '',
      externalurl: Utils.fixUrl(event.externalurl) ?? '',
    });
  };

  public createFreeExample = async (courseId: number, example: FreeExample): Promise<{ exampleid?: number }> => {
    return this.moodleWebservice.block_exacomp_create_or_update_example({
      courseid: courseId,
      crosssubjectid: -1,
      activityid: 0,
      name: example.name,
      comps: 'freemat',
      description: example.description ?? '',
      fileitemids: this.toList(example.fileitemIds),
      solutionfileitemid: example.solutionFileitemId as any, // hack: weil solutionfileitemid einen string erwartet
      taxonomies: this.toList(example.taxonomies),
      newtaxonomy: example.newTaxonomy,
      timeframe: example.timeframe,
      externalurl: Utils.fixUrl(example.externalurl) ?? '',
      is_teacherexample: this.toBoolean(example.isTeacherExample),
    });
  };

  public addExampleToPlanningStorage = async (courseId: number, exampleId: number, userId: number = 0): Promise<SuccessOperationResponse & { scheduleid: number }> => {
    return this.moodleWebservice.dakora_add_example_to_learning_calendar({
      courseid: courseId,
      exampleid: exampleId,
      userid: userId,
      forall: false,
      groupid: 0,
      creatorid: 0,
    });
  };

  public distributeExamplesToStudentsPlanningStorage = async (courseId: number, exampleIds: number[], studentIds: number[]): Promise<SuccessOperationResponse> => {
    return this.moodleWebservice.dakora_add_examples_to_students_schedule({
      courseid: courseId,
      examples: this.toList(exampleIds, true),
      students: this.toList(studentIds, true),
    });
  };

  public distributeMyScheduleToStudentSchedules = async (courseId: number, studentIds: number[], distributionId: number = new Date().getTime()): Promise<SuccessOperationResponse> => {
    return this.moodleWebservice.dakora_add_examples_to_selected_students_schedule({
      courseid: courseId,
      students: this.toList(studentIds, true),
      distributionid: distributionId as any, // hack: weil solutionfileitemid einen string erwartet
    });
  };

  public scheduleExample = async (scheduleId: number, start: Date, end: Date): Promise<SuccessOperationResponse> => {
    return this.moodleWebservice.dakora_set_example_time_slot({
      scheduleid: scheduleId,
      start: this.toTimestamp(start),
      end: this.toTimestamp(end),
      deleted: 0,
    });
  };

  public removeExampleFromSchedule = async (scheduleId: number): Promise<SuccessOperationResponse> => {
    return this.moodleWebservice.dakora_set_example_time_slot({
      scheduleid: scheduleId,
      start: 0,
      end: 0,
      deleted: 0,
    });
  };

  public deleteScheduledExample = async (scheduleId: number): Promise<SuccessOperationResponse> => {
    return this.moodleWebservice.dakora_set_example_time_slot({
      scheduleid: scheduleId,
      start: 0,
      end: 0,
      deleted: 1,
    });
  };

  public saveCourseSettings = async (courseId: number, settings: { experienceLevel: CourseExperienceLevel }): Promise<SuccessOperationResponse> => {
    return this.moodleWebservice.dakoraplus_save_coursesettings({
      courseid: courseId,
      experience_level: settings.experienceLevel,
    });
  };
}
