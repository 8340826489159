import { AppInitializer } from '@gtn/app-common/AppInitializer';
import { AppConfigManager } from '@gtn/app-common/config/AppConfigManager';
import { AppCommonState } from '@gtn/app-common/store/app.store';
import { ChildrenProps } from '@gtn/common/components/ChildrenProps';
import { ConfigManagerToken } from '@gtn/common/config/ConfigManager';
import { StoreToken } from '@gtn/common/store/shared.store';
import { ThemeManager } from '@gtn/common/theme/ThemeManager';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { StylesProvider, ThemeProvider } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { Suspense, useMemo } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route as ReactRoute } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { Store } from 'redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import useAsyncEffect from 'use-async-effect';
import { QueryParamProvider } from 'use-query-params';

const Loading = () => (
  <div
    style={{
      textAlign: 'center',
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}
  >
    <CircularProgress />
  </div>
);

function AppShell(props: ChildrenProps) {
  const appStore =
    InjectionContainer.resolve<Store<AppCommonState>>(StoreToken);
  const appPersistor = useMemo(() => persistStore(appStore), [appStore]);
  const configProvider =
    InjectionContainer.resolve<AppConfigManager>(ConfigManagerToken);
  const themeManager = InjectionContainer.resolve(ThemeManager);

  const [ready, setReady] = React.useState(false);

  useAsyncEffect(async () => {
    await InjectionContainer.resolve(AppInitializer).init();
    setReady(true);
  }, []);

  return (
    <StylesProvider injectFirst={true}>
      <Suspense fallback={<Loading />}>
        <Provider store={appStore}>
          <PersistGate loading={<Loading />} persistor={appPersistor}>
            {!ready ? (
              <Loading />
            ) : (
              <Router basename={configProvider.getConfig().basePath}>
                <QueryParamProvider ReactRouterRoute={ReactRoute}>
                  <LastLocationProvider>
                    <ThemeProvider theme={themeManager.materialUITheme!}>
                      {props.children}
                    </ThemeProvider>
                  </LastLocationProvider>
                </QueryParamProvider>
              </Router>
            )}
          </PersistGate>
        </Provider>
      </Suspense>
    </StylesProvider>
  );
}

export default AppShell;
