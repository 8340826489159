import { QuizAttempt } from '@gtn/common/api/model/quizzes/QuizAttempt';
import { MoodleQuizAPI } from '@gtn/common/api/MoodleQuizAPI';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import { GtnAvatar } from '@gtn/common/components/gtn-avatar/GtnAvatar';
import LoadingContainer from '@gtn/common/components/loading-container/LoadingContainer';
import GtnDialog, { GtnDialogProps } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useIsTeacher } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { ProgressState } from '@gtn/common/utils/ProgressState';
import { Utils } from '@gtn/common/utils/Utils';
import { ConductQuiz } from '@gtn/dakora/routes/quizzes/quiz/conduct-quiz/ConductQuiz';
import { ListItem, ListItemText } from '@material-ui/core';
import { useMemo, useState } from 'react';
import styles from './Quiz.module.scss';

export interface QuizProps {
  quizId?: number;
  quizName?: string;
}

export function Quiz(props: QuizProps & GtnDialogProps) {
  const t = useAppTranslation();
  const moodleQuizAPI = InjectionContainer.resolve(MoodleQuizAPI);
  const [attempt, setAttempt] = useState<QuizAttempt | undefined>();
  const isTeacher = useIsTeacher();

  const { data: attempts, progressState, mutate: reload } = useAPI(moodleQuizAPI.getQuizAttempts, [props.quizId!]);
  const ongoingAttempt = useMemo(() => {
    if (attempts) {
      return attempts.find((a) => a.state === 'inprogress');
    }
    return undefined;
  }, [attempts]);

  return (
    <GtnDialog {...props} title={props.quizName}>
      {props.quizId && (
        <div className={styles.dialogContentContainer}>
          {attempt == null && (
            <div className={styles.container}>
              <h3>{t('Versuche')}</h3>
              <LoadingContainer
                state={progressState}
                style={{
                  alignItems: progressState === ProgressState.Content ? 'stretch' : 'center',
                  justifyContent: progressState === ProgressState.Content ? 'flex-start' : 'center',
                }}
              >
                {attempts?.map((attempt, index) => (
                  <ListItem key={`attempt-${attempt.id}`}>
                    <GtnAvatar name={String(index + 1)} style={{ marginRight: 8 }} />
                    <ListItemText primary={attempt.state === 'inprogress' ? 'Laufender Versuch' : attempt.sumgrades + ' Punkte'} secondary={Utils.toDate(attempt.timefinish).toLocaleString()} />
                  </ListItem>
                ))}
              </LoadingContainer>

              {!isTeacher && (
                <GtnButton
                  actionType="primary"
                  label={t(ongoingAttempt != null ? 'Letzten Versuch weiterführen' : 'Neuen Versuch starten')}
                  onClick={async () => {
                    if (ongoingAttempt == null) {
                      try {
                        const result = await moodleQuizAPI.startQuizAttempt(props.quizId!);
                        setAttempt(result);
                      } catch (e) {
                        GtnLogger.warn(e);
                      }
                    } else {
                      setAttempt(ongoingAttempt);
                    }
                  }}
                />
              )}
            </div>
          )}

          {attempt != null && (
            <ConductQuiz
              quizId={props.quizId}
              attemptId={attempt.id}
              initialPageNumber={attempt.currentpage}
              onFinished={() => {
                setAttempt(undefined);
                reload();
              }}
            />
          )}
        </div>
      )}
    </GtnDialog>
  );
}
