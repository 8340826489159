import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import { InputAdornment, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import classNames from 'classnames';
import React from 'react';
import { StyleProps } from '@gtn/common/components/StyleProps';
import styles from '@gtn/common/components/search-bar/SearchBar.module.scss';

export interface GtnSearchBarProps extends StyleProps {
  label: string;
  value?: string;
  onValueChanged?: (value: string) => void;
}

export default function GtnSearchBar(props: GtnSearchBarProps) {
  const t = useAppTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onValueChanged?.(event.target.value);
  };

  return (
    <TextField
      className={classNames(props.className, styles.searchBar, styles.small)}
      style={props.style}
      variant="outlined"
      placeholder={t(props.label)}
      type="search"
      value={props.value}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
}
