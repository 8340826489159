import { CommonRoutingParams, CommonRoutingPaths } from '@gtn/app-common/routes/AppCommonRouting';
import { ConfigManagerToken } from '@gtn/common/config/ConfigManager';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect } from 'react';
import { AppInitializer } from '@gtn/app-common/AppInitializer';
import AppInfo from '@gtn/app-common/components/app-info/AppInfo';
import { AppConfigManager } from '@gtn/app-common/config/AppConfigManager';
import { useAppDispatch, useAppCommonSelector } from '@gtn/app-common/store/app.store.hooks';
import { LoginService } from '@gtn/app-common/api/LoginService';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import GtnForm from '@gtn/common/components/forms/GtnForm';
import GtnTextField from '@gtn/common/components/forms/GtnTextField';
import { useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { preferencesActions } from '@gtn/common/store/preferences/preferences.state';
import { useUser } from '@gtn/common/store/user/user.hooks';
import { userActions } from '@gtn/common/store/user/user.state';
import { ThemeManager } from '@gtn/common/theme/ThemeManager';
import { useAllParams, useAppTranslation, useHistory, useQueryParams } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import useAsyncEffect from 'use-async-effect';
import { MSTeamsManager } from '@gtn/app-common/microsoft/MSTeamsManager';
import { navigationActions } from '@gtn/app-common/store/navigation.state';
import styles from './Login.module.scss';
import { TutorialDialog } from '@gtn/app-common/routes/login/tutorial-dialog/TutorialDialog';
import { Utils } from '@gtn/common/utils/Utils';
import { LoadingIndicatorOverlay } from '@gtn/common/components/LoadingIndicator';

function useStateMerge(initialState) {
  const [state, setState] = React.useState(initialState);
  return [state, (newState) => setState({ ...state, ...newState }), setState];
}

export default function Login() {
  const appInitializer = InjectionContainer.resolve(AppInitializer);
  const msTeamsManager = InjectionContainer.resolve(MSTeamsManager);
  const themeManager = InjectionContainer.resolve(ThemeManager);
  const loginService = InjectionContainer.resolve(LoginService);

  const [loading, setLoading] = React.useState('');
  const [error, setError] = React.useState('');

  const configManager = InjectionContainer.resolve<AppConfigManager>(ConfigManagerToken);
  const config = configManager.getConfig();
  const preferences = useAppCommonSelector((state) => state.preferences);
  const loginTutorialSeen = useAppCommonSelector((state) => state.navigation.loginTutorialSeen);

  const tutorialDialog = useGtnDialog(TutorialDialog);

  const t = useAppTranslation();
  const history = useHistory();
  const user = useUser();
  const params = useAllParams();
  const dispatch = useAppDispatch();

  let initialMoodleUrl;
  if (!config.allowChangeMoodleUrl && config.moodleUrl) {
    initialMoodleUrl = config.moodleUrl;
  } else {
    initialMoodleUrl = params[CommonRoutingParams.MOODLE_URL] || preferences.moodleUrl || config.moodleUrl;
  }
  const paramMoodleToken = params[CommonRoutingParams.MOODLE_TOKEN];
  const paramRecheckLogin = Utils.parseBool(params['recheck_login']);

  const [formData, setFormData] = useStateMerge({
    moodleUrl: initialMoodleUrl,
  });

  function fixUrlPart(startCharacter, urlPart) {
    if (!urlPart) {
      return '';
    }
    urlPart = urlPart.replace(new RegExp('^\\' + startCharacter + '+'), '');

    if (urlPart) {
      urlPart = startCharacter + urlPart;
    }

    return urlPart;
  }

  function doRedirectAfterLogin() {
    // use saved redirectAfterLogin if available
    if (preferences.redirectAfterLogin) {
      // stellt sicher, dass ein url search oder hash immer mit ? bzw. # beginnt
      history.push(preferences.redirectAfterLogin.pathname + fixUrlPart('?', preferences.redirectAfterLogin.search?.replace(/do_login=1/, '')) + fixUrlPart('#', preferences.redirectAfterLogin.hash));
      dispatch(preferencesActions.set({ redirectAfterLogin: null }));
    } else {
      history.push(CommonRoutingPaths.HOME);
    }
  }

  useEffect(() => {
    if (user.isLoggedIn && !paramRecheckLogin) {
      // this call may be triggered twice, with also at loginWithMoodleToken()
      doRedirectAfterLogin();
    } else {
      // from is passed from <Redirect/> if not logged in
      // save it for later, after the login was successfull redirect back to this location
      const from = (history.location.state as any)?.from;
      if (from) {
        dispatch(preferencesActions.set({ redirectAfterLogin: from }));
      }

      const enrolCode = params[CommonRoutingParams.ENROL_CODE];
      if (enrolCode) {
        // Save enrol code until user is logged in
        dispatch(preferencesActions.set({ unusedEnrolCode: enrolCode }));
      }

      if (config.showLoginTutorial && !loginTutorialSeen) {
        tutorialDialog.open();
        dispatch(navigationActions.setLoginTutorialSeen());
      }

      if ((Utils.parseBool(params[CommonRoutingParams.DO_LOGIN]) || paramRecheckLogin) && initialMoodleUrl && !paramMoodleToken) {
        onLogin('auto-login');
      }
    }
  }, [user.isLoggedIn]);

  useAsyncEffect(async () => {
    const moodleUrl = (config.allowChangeMoodleUrl && params[CommonRoutingParams.MOODLE_URL]) || preferences.moodleUrl || config.moodleUrl;

    if (!user.isLoggedIn) {
      if (moodleUrl !== preferences.moodleUrl) {
        dispatch(preferencesActions.set({ moodleUrl }));
      }
    }

    if ((!user.isLoggedIn || paramRecheckLogin) && paramMoodleToken) {
      setLoading('token-check');
      setError('');

      setFormData({ moodleUrl });

      try {
        await loginService.loginWithMoodleToken(moodleUrl!, paramMoodleToken);

        await loginService.loadUserData();

        await appInitializer.initMoodleDependantSettings();
        await appInitializer.initUserDependantSettings();

        if (Utils.parseBool(params[CommonRoutingParams.MS_TEAMS_NOTIFY_AUTH_SUCCESS])) {
          msTeamsManager.notifyAuthSuccess();
        } else {
          doRedirectAfterLogin();
        }
        return;
      } catch (e: any) {
        // on error remove authTokens
        dispatch(userActions.authTokenClear());

        GtnLogger.warn(e);
        setLoading('');
        setError('Kann nicht zu Moodle verbinden: ' + e.message);
      }
    }
  }, []);

  async function onLogin(loadingType) {
    setLoading(loadingType);
    setError('');

    loginService.saveInitialQueryParams();

    const { moodleUrl } = formData;
    dispatch(preferencesActions.set({ moodleUrl }));

    try {
      let loginUrl = await loginService.getLoginUrl(moodleUrl);
      if (window !== window.parent && location.host.match(/dakoraplus\.eu$/)) {
        // is in iframe
        // für cors im iframe ist ein extra redirect mit manueller Benutzerbestätigung notwendig
        // sonst werden die cookies nicht geladen bzw. können auch keine neuen cookies gesetzt werden

        // vorerst nur beim exa_annotation plugin + dakoraplus.eu relevant
        loginUrl += '&extra_iframe_redirect=1';
      }

      document.location.href = loginUrl;
      return;
    } catch (e: any) {
      GtnLogger.error(e);
      setLoading('');

      let errorMessage;
      if (e.message === 'Failed to fetch') {
        errorMessage = 'Kein Moodle unter dieser Url gefunden';
      } else {
        errorMessage = e.message;
      }
      setError('Kann nicht zu Moodle verbinden: ' + errorMessage);
    }
  }

  function setMoodleUrl(event) {
    let moodleUrl = event.target.value;
    if (moodleUrl === 'other') {
      moodleUrl = '';
    }
    setFormData({ moodleUrl });
    dispatch(preferencesActions.set({ moodleUrl }));
  }

  const predefinedMoodleInstance = config.predefinedMoodleInstances?.find((mi) => mi.url === formData.moodleUrl);

  if (loading === 'auto-login' || loading == 'token-check') {
    return <LoadingIndicatorOverlay />;
  }

  return (
    <Grid container component="main" className={styles.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={styles.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={styles.paper}>
          <Avatar className={styles.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <h2 className={styles.title}>{t('login.title', { appName: config.theme.appName })}</h2>
          {config.showLoginTutorial && (
            <a className={styles.helpLink} onClick={() => tutorialDialog.open()}>
              {t('tutorial.title')}
            </a>
          )}
          <GtnForm className={styles.form}>
            <Select value={predefinedMoodleInstance?.url ?? 'other'} style={{ width: '100%', marginBottom: 12 }} variant="outlined" onChange={(event) => setMoodleUrl(event)}>
              {config.predefinedMoodleInstances?.map((moodleInstance) => (
                <MenuItem key={moodleInstance.url} value={moodleInstance.url}>
                  {moodleInstance.displayName}
                </MenuItem>
              ))}
              <MenuItem key="other" value="other">
                {t('login.custom-moodle-url')}
              </MenuItem>
            </Select>

            {!predefinedMoodleInstance && (
              <GtnTextField
                name="moodleUrl"
                label={t('login.moodle-url')}
                disabled={!!loading || !config.allowChangeMoodleUrl || params[CommonRoutingParams.ALLOW_CHANGE_MOODLE_URL] === 'false'}
                value={formData.moodleUrl}
                required
                onChange={(event) => setMoodleUrl(event)}
              />
            )}

            {!!error && <Alert severity="error">{error}</Alert>}
            {!!loading && <LinearProgress />}

            <GtnButton
              label={t('login.sign-in')}
              disabled={!!loading || !formData.moodleUrl}
              type="submit"
              actionType="primary"
              className={styles.loginButton}
              onClick={(e) => {
                e.preventDefault();
                onLogin('from-form');
              }}
            />
          </GtnForm>

          <img className={styles.logo} src={configManager.getAssetPath(themeManager.theme?.logo?.imageWithName ?? 'img/logo-with-name.png')} alt="Logo" />
          <div className={styles.spacing} />

          <AppInfo />
        </div>
      </Grid>

      <tutorialDialog.Component />
    </Grid>
  );
}
