import { Utils } from '@gtn/common/utils/Utils';
import styles from './QuizQuestion.module.scss';

export interface QuizQuestionProps {
  questionHTML: string;
}

export function QuizQuestion(props: QuizQuestionProps) {
  return <div id="quizQuestion" dangerouslySetInnerHTML={Utils.getAsInnerHTML(props.questionHTML)} />;
}
