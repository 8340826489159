import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TFunction, TOptions } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useHistory as useHistoryRouter,
  useLocation,
  useParams as useParamsRouter,
} from 'react-router-dom';
import { useRole } from '@gtn/common/store/user/user.hooks';
import { Utils } from '@gtn/common/utils/Utils';

export const useHistory = <T>() => {
  const history = useHistoryRouter<T>();

  const requestUri = window.location.href;

  return {
    ...history,
    requestUri,
    push: (path, state?: T) =>
      history.push(path === null ? requestUri : path, state),
    replace: (path, state?: T) =>
      history.replace(path === null ? requestUri : path, state),
  };
};

export const useQueryParams = () => {
  const location = useLocation();

  return Utils.parseQuery(location.search);
};

export const useAllParams = (): { [key: string]: string } => {
  const params = useParamsRouter();
  const queryParams = useQueryParams();

  return {
    ...queryParams,
    ...params,
  };
};

export interface AppTranslate extends TFunction {
  /**
   * Translate a key by appending ".teacher" or ".student" depending on current role
   */
  withRole(key: string, options?: TOptions | string);
}

export const useAppTranslation = () => {
  const role = useRole();
  const t = useTranslation().t as AppTranslate;

  t.withRole = (key, options) => {
    return t(`${key}.${role}`, options);
  };
  return t;
};

// from: https://github.com/vercel/swr/issues/110
export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((val) => ++val);
}

export function useIsScreenMinWidth(width: number) {
  return useMediaQuery('(min-width:' + width + 'px)');
}
