import { useAppConfig } from '@gtn/app-common/config/AppConfigHooks';
import { useAppCommonSelector } from '@gtn/app-common/store/app.store.hooks';
import { MainContent } from '@gtn/common/components/MainContent';
import React from 'react';

export default function Profile() {
  const appConfig = useAppConfig();
  const state = useAppCommonSelector((state) => state);
  const profile = state.user.profile!;

  return (
    <MainContent>
      <h2>{profile.fullName}</h2>

      {!appConfig.isProduction && (
        <>
          <h2>Info only shown in dev:</h2>
          <pre>{JSON.stringify(state, null, 2)}</pre>
        </>
      )}
    </MainContent>
  );
}
