import AppInfo from '@gtn/app-common/components/app-info/AppInfo';
import { CommonRoutingPaths } from '@gtn/app-common/routes/AppCommonRouting';
import { useAppCommonSelector, useAppDispatch } from '@gtn/app-common/store/app.store.hooks';
import { navigationActions } from '@gtn/app-common/store/navigation.state';
import GtnNavigationDrawer from '@gtn/common/components/navigation/gtn-navigation-drawer/GtnNavigationDrawer';
import { NavigationItem } from '@gtn/common/components/navigation/NavigationItem';
import { useIsTeacher } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation, useHistory } from '@gtn/common/utils/HookUtils';
import { ExitToApp, Settings } from '@material-ui/icons';
import React from 'react';

export interface AppNavigationDrawerProps {
  showAsPersistent?: boolean;
  primaryItems: NavigationItem[];
}

export default function AppNavigationDrawer(props: AppNavigationDrawerProps) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const t = useAppTranslation();

  const navigationDrawerOpened = useAppCommonSelector((state) => state.navigation.navigationDrawerOpened);

  const drawerSecondaryItems: NavigationItem[] = [
    {
      title: t('menu.settings'),
      href: CommonRoutingPaths.SETTINGS,
      icon: <Settings />,
    },
    {
      title: t('menu.logout'),
      onClick: () => history.push(CommonRoutingPaths.LOGOUT),
      icon: <ExitToApp />,
    },
  ];

  return (
    <GtnNavigationDrawer
      variant={props.showAsPersistent ? 'persistent' : 'temporary'}
      isOpen={navigationDrawerOpened}
      onClose={() => dispatch(navigationActions.setNavigationDrawerOpened(false))}
      primaryItems={props.primaryItems}
      secondaryItems={drawerSecondaryItems}
      footer={<AppInfo style={{ marginBottom: '0' }} />}
    />
  );
}
