import { LearningPath } from '@gtn/app-common/api/model/models';
import { ItemStatus } from '@gtn/app-common/api/model/ExampleAndItemResponse';
import { ITEM_STATUS_DISPLAY_VALUES_STUDENT, ItemStatusDisplay } from '@gtn/app-common/components/example-item-list/ItemStatusDisplay';

export abstract class LearningPathUtils {
  public static getLearningPathStatus(learningPath: LearningPath): ItemStatusDisplay {
    let status: ItemStatus = ItemStatus.NEW;

    if (learningPath.count_inprogress) {
      status = ItemStatus.INPROGRESS;
    } else if (learningPath.count_submitted && !learningPath.count_new) {
      status = ItemStatus.SUBMITTED;
    } else if (learningPath.count_completed && !learningPath.count_submitted && !learningPath.count_new) {
      status = ItemStatus.COMPLETED;
    }

    return ITEM_STATUS_DISPLAY_VALUES_STUDENT.find((s) => s.statusMapping === status)!;
  }
}
