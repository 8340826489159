import styles from '@gtn/app-common/components/app-navigation/course-student-selection/selector/CourseStudentSelector.module.scss';
import { useAppCommonSelector, useAppDispatch, useSelectedCourse, useSelectedStudent } from '@gtn/app-common/store/app.store.hooks';
import { navigationActions } from '@gtn/app-common/store/navigation.state';
import { GtnAvatar } from '@gtn/common/components/gtn-avatar/GtnAvatar';
import { useIsTeacher } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import { Tooltip } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import React from 'react';

export interface CourseStudentSelectorProps {
  showCompetenceProfileButton?: boolean;
  classOnly?: boolean;
}

export function CourseStudentSelector(props: CourseStudentSelectorProps) {
  const t = useAppTranslation();
  const dispatch = useAppDispatch();

  const isTeacher = useIsTeacher();
  const selectedStudent = useSelectedStudent();
  const selectedCourse = useSelectedCourse();

  return (
    <>
      {isTeacher && (selectedCourse || selectedStudent) && (
        <Tooltip title={t('navigation.switch-course-and-student') as string}>
          <div className={styles.selectedStudentContainer} onClick={() => dispatch(navigationActions.setCourseStudentDrawerOpened(true))}>
            <MoreVert className={styles.icon} />

            {!props.classOnly && selectedStudent && <GtnAvatar imageUrl={selectedStudent.profileimageurl} name={selectedStudent.fullname} className={styles.avatar} />}
            <div className={styles.textContainer}>
              <h3>{props.classOnly || !selectedStudent ? t('navigation.all-students') : selectedStudent.fullname}</h3>
              {selectedCourse && <p>{selectedCourse.fullname}</p>}
            </div>
          </div>
        </Tooltip>
      )}
    </>
  );
}
