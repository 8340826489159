import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route as ReactRoute } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';
import { SharedState } from '@gtn/common/store/shared.store';
import { GtnRoute } from '@gtn/common/utils/routing/GtnRoute';
import { preferencesActions } from '@gtn/common/store/preferences/preferences.state';

const PublicRoute = ReactRoute;
const PrivateRoute = (props) => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector<SharedState>((state: SharedState) => state.user.isLoggedIn);

  if (isLoggedIn) {
    return <ReactRoute {...props} />;
  } else {
    return (
      <ReactRoute
        {...props}
        children={null}
        component={null}
        render={({ location }) => {
          let loginSearch = new URLSearchParams(location.search);
          let returnSearch = new URLSearchParams(location.search);

          // remove unwanted parameters
          returnSearch.delete('moodle_userid');
          returnSearch.delete('moodle_url');
          returnSearch.delete('recheck_login');

          if (loginSearch.get('moodle_url')) {
            const moodleUrl = loginSearch.get('moodle_url') || '';
            // TODO: fix, this gets dispatch on each render!
            dispatch(preferencesActions.set({ moodleUrl }));

            loginSearch.delete('moodle_url');
          }

          if (loginSearch.get('recheck_login')) {
            loginSearch.delete('recheck_login');
            loginSearch.set('do_login', '1');
          }

          return (
            <Redirect
              to={{
                pathname: '/login',
                // forward parameters, eg. moodle_url or do_login for eg. annotation tool
                search: loginSearch.toString(),
                state: { from: { ...location, search: returnSearch.toString() } },
              }}
            />
          );
        }}
      />
    );
  }
};

export interface GtnRouteSwitchProps {
  routes: GtnRoute[];
  render(route: GtnRoute);
}

export const GtnRouteSwitch = (props: GtnRouteSwitchProps) => (
  <Switch>
    {props.routes.map((route, index) => {
      // standardmäßig sind alle routes requireLogin=true
      const Route = route.requireLogin || route.requireLogin === undefined ? PrivateRoute : PublicRoute;
      return (
        <Route
          key={route.path || index /* <NoMatch/> Route has no path, then use index */}
          path={route.path}
          exact={route.exact}
          render={route.isAccessible === undefined || route.isAccessible ? () => props.render(route) : () => <div>not allowed</div>}
        />
      );
    })}
  </Switch>
);
