import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import styles from '@gtn/app-common/components/item-comments/ItemComments.module.scss';
import { useAPI } from '@gtn/common/api//webservice/WebserviceHookUtils';
import { ExacompFile } from '@gtn/common/api/model/exacomp/ExacompFile';
import ChatMessage from '@gtn/common/components/chat/ChatMessage';
import LoadingContainer from '@gtn/common/components/loading-container/LoadingContainer';
import { StyleProps } from '@gtn/common/components/StyleProps';
import { useUser } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { ProgressState } from '@gtn/common/utils/ProgressState';
import { IconButton, Tooltip } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Send } from '@material-ui/icons';
import classNames from 'classnames';
import React, { useState } from 'react';

export interface ItemCommentsProps {
  itemId?: number;

  beforeSave?: () => Promise<boolean | number>;

  onOpenFile?: (file: ExacompFile) => void;
}

interface ItemCommentsRef {
  submitComment: () => Promise<any>;
  reloadComments: () => void;
}

export default React.forwardRef<ItemCommentsRef, ItemCommentsProps & StyleProps>(function ItemComments(props, ref) {
  const t = useAppTranslation();
  const appCommonAPI = InjectionContainer.resolve(AppCommonAPI);
  const user = useUser();
  const [submitCommentProgressState, setSubmitCommentProgressState] = useState(ProgressState.Content);
  const [commentText, setCommentText] = useState('');

  const { data: comments, progressState: loadCommentProgressState, mutate: reloadItems } = useAPI(appCommonAPI.getItemComments, [props.itemId]);

  const handleCommentTextChange = (event: any) => {
    setCommentText(event.target.value);
  };

  const handleCommentInputKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      submitComment(false);
      event.preventDefault();
    }
  };

  const submitComment = async (fromOutside) => {
    if (!commentText) {
      return;
    }

    let itemId = props.itemId;
    try {
      setSubmitCommentProgressState(ProgressState.Loading);
      if (props.beforeSave) {
        const beforeSaveResult = await props.beforeSave();
        if (beforeSaveResult === false) {
          setSubmitCommentProgressState(ProgressState.Content);
          return;
        }
        if (typeof beforeSaveResult === 'number') {
          itemId = beforeSaveResult;
        }
      }

      if (itemId) {
        const response = await appCommonAPI.submitComment(itemId, commentText);

        if (response.success) {
          setCommentText('');
          setSubmitCommentProgressState(ProgressState.Content);
          if (!fromOutside) {
            await reloadItems();
          }
        } else {
          setSubmitCommentProgressState(ProgressState.Error);
        }
      } else {
        setSubmitCommentProgressState(ProgressState.Content);
      }
    } catch (e) {
      GtnLogger.warn(e);
      setSubmitCommentProgressState(ProgressState.Error);
    }
  };

  React.useImperativeHandle(ref, () => ({
    submitComment: () => submitComment(true),
    reloadComments: () => reloadItems(),
  }));

  return (
    <div className={styles.container}>
      <LoadingContainer state={loadCommentProgressState} className={classNames(styles.list, props.className)} style={props.style} emptyText="">
        {comments?.map((comment) => (
          <ChatMessage
            key={comment.id}
            className={styles.comment}
            message={comment.comment}
            incoming={comment.userid === user.profile?.id}
            userName={comment.fullname}
            userProfileImageUrl={comment.profileimageurl}
            date={new Date(comment.timemodified * 1000)}
            file={comment.file}
            onOpenFile={props.onOpenFile}
          />
        ))}
      </LoadingContainer>
      <div className={styles.submitCommentContainer}>
        <textarea
          placeholder={t('item-comments.write-comment')}
          value={commentText}
          onChange={handleCommentTextChange}
          onKeyDown={handleCommentInputKeyPress}
          autoCapitalize="sentences"
          autoComplete="off"
        />

        {submitCommentProgressState === ProgressState.Error && <p className={styles.submitError}>{t('item-comments.submit-error')}</p>}

        {submitCommentProgressState === ProgressState.Loading && <CircularProgress className={styles.loadingIndicator} size={24} />}

        {submitCommentProgressState !== ProgressState.Loading && (
          <Tooltip title={t('item-comments.submit') as string}>
            <div>
              <IconButton onClick={() => submitComment(false)} disabled={commentText.length === 0}>
                <Send />
              </IconButton>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
});
