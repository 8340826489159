import { AppCommonAPI } from '@gtn/app-common/api/AppCommonAPI';
import { MSTeamsManager } from '@gtn/app-common/microsoft/MSTeamsManager';
import { CommonRoutingPaths } from '@gtn/app-common/routes/AppCommonRouting';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { Utils } from '@gtn/common/utils/Utils';
import { LoginType, Msal2Provider, Providers, ProviderState, TeamsProvider } from '@microsoft/mgt';
import * as microsoftTeams from '@microsoft/teams-js';
import { singleton } from 'tsyringe';

@singleton()
export class MSAuthManager {
  private readonly msTeamsManager = InjectionContainer.resolve(MSTeamsManager);
  private readonly sharedAPI = InjectionContainer.resolve(AppCommonAPI);

  private msTeamsAuthToken?: string;
  private msTeamsTenantId?: string;

  private accessToken?: string;

  public init(basePath: string, clientId: string, scopes: string[] = ['GroupMember.Read.All', 'User.Read.All']) {
    const baseConfig = {
      clientId: clientId,
      scopes,
    };
    const handleAuthPath = `${Utils.normalizeBasePath(basePath)}/${CommonRoutingPaths.MS_TEAMS_AUTH}`;

    if (this.msTeamsManager.isInMsTeams()) {
      TeamsProvider.microsoftTeamsLib = microsoftTeams;
      Providers.globalProvider = new TeamsProvider({
        ...baseConfig,
        authPopupUrl: handleAuthPath,
      });
    } else {
      const redirectUri = document.location.origin + handleAuthPath;

      Providers.globalProvider = new Msal2Provider({
        ...baseConfig,
        loginType: LoginType.Popup,
        redirectUri: redirectUri,
      });
    }
  }

  public async handleAuthResult() {
    await TeamsProvider.handleAuth();
  }

  public async trySilentMSTeamsLogin(tenantId: string) {
    if (this.msTeamsManager.isInMsTeams()) {
      this.msTeamsTenantId = tenantId;
      this.msTeamsAuthToken = await this.msTeamsManager.getAuthToken();
      GtnLogger.log('Got auth token from MS Teams: ' + this.msTeamsAuthToken);

      this.accessToken = await this.exchangeMSTeamsAuthToken();
      return true;
    }
    return false;
  }

  private async exchangeMSTeamsAuthToken() {
    try {
      return (await this.sharedAPI.exchangeMSTeamsAuthToken(this.msTeamsAuthToken!!, this.msTeamsTenantId!!)).access_token;
    } catch (e) {
      GtnLogger.warn('Failed to exchange auth token: ' + e);
      throw e;
    }
  }

  public isLoggedIn() {
    return Providers.globalProvider?.state === ProviderState.SignedIn || this.msTeamsAuthToken;
  }

  public isLoggedInViaMSTeams() {
    return !!this.msTeamsAuthToken && !!this.accessToken;
  }

  public async getAccessToken() {
    if (!this.accessToken) {
      if (this.msTeamsAuthToken) {
        this.accessToken = await this.exchangeMSTeamsAuthToken();
      } else {
        this.accessToken = await Providers.globalProvider.getAccessToken();
      }
    }

    return this.accessToken;
  }

  public clearTokens() {
    this.msTeamsTenantId = undefined;
    this.accessToken = undefined;
    this.msTeamsAuthToken = undefined;
  }
}
