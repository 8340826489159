import styles from '@gtn/app-common/components/app-navigation/course-student-selection/course-users-list/CourseUsersList.module.scss';
import { useAppDispatch } from '@gtn/app-common/store/app.store.hooks';
import { navigationActions } from '@gtn/app-common/store/navigation.state';
import { MoodleUser, MoodleUserUtils } from '@gtn/common/api//model/moodle-core/MoodleUser';
import { MoodleCoreAPI } from '@gtn/common/api//MoodleCoreAPI';
import { useAPI } from '@gtn/common/api//webservice/WebserviceHookUtils';
import { GtnAvatar } from '@gtn/common/components/gtn-avatar/GtnAvatar';
import { GtnSelectionIndicator } from '@gtn/common/components/gtn-selection-indicator/GtnSelectionIndicator.component';
import LoadingContainer from '@gtn/common/components/loading-container/LoadingContainer';
import GtnSearchBar from '@gtn/common/components/search-bar/GtnSearchBar';
import { StyleProps } from '@gtn/common/components/StyleProps';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { ProgressState } from '@gtn/common/utils/ProgressState';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { People } from '@material-ui/icons';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';

export interface CourseUsersListProps {
  courseId: number;
  courseName: string;
  selectedUsers?: MoodleUser[] | null;
  canSelect?: 'all-students-only' | 'single-student-only' | 'single-and-all-students' | 'multiple-students';
  onUserSelected?: (user: MoodleUser | MoodleUser[] | null, mutliSelection?: boolean) => void;
}

export default function CourseUsersList(props: CourseUsersListProps & StyleProps) {
  const t = useAppTranslation();
  const dispatch = useAppDispatch();

  const moodleAPI = InjectionContainer.resolve(MoodleCoreAPI);

  const { data: users, progressState } = useAPI(moodleAPI.getEnrolledUsersForCourse, [props.courseId]);

  const [searchFilter, setSearchFilter] = useState<string | undefined>(undefined);

  const students = useMemo(() => {
    return users
      ?.filter((user) => MoodleUserUtils.isStudent(user) && (searchFilter ? user.fullname.toLowerCase().includes(searchFilter.toLowerCase()) : true))
      .sort((a, b) => (a.lastname ?? a.fullname)?.localeCompare(b.lastname ?? b.fullname));
  }, [users, searchFilter]);

  useEffect(() => {
    dispatch(navigationActions.setSelectedCourseStudents(students));
  }, [students]);

  useEffect(() => {
    if (props.canSelect) {
      if (props.canSelect === 'single-student-only' && !props.selectedUsers?.length && students?.length) {
        props.onUserSelected?.(students[0]);
      } else if (props.canSelect !== 'multiple-students' && props.selectedUsers && props.selectedUsers.length > 1) {
        props.onUserSelected?.(props.selectedUsers[0]);
      } else if (props.canSelect === 'all-students-only' && props.selectedUsers != null) {
        props.onUserSelected?.(null);
      }
    }
  }, [props.selectedUsers, props.canSelect, students]);

  function renderStudentList() {
    if (students?.length) {
      return students?.map((user) => {
        const isSelected = props.selectedUsers?.some((u) => u?.id === user.id);
        return (
          <ListItem
            className={classNames(styles.listItem, isSelected ? styles.selected : null)}
            onClick={() => props.onUserSelected?.(user, props.canSelect === 'multiple-students')}
            button
            key={user.id}
          >
            <GtnAvatar className={styles.icon} imageUrl={user.profileimageurlsmall} name={user.fullname} />
            <ListItemText primary={user.fullname} style={{ wordBreak: 'break-all' }} />
            {props.canSelect === 'multiple-students' && <GtnSelectionIndicator isSelected={isSelected || props.selectedUsers === null} />}
          </ListItem>
        );
      });
    } else {
      return <p>{t('navigation.no-students')}</p>;
    }
  }

  return (
    <div className={classNames(styles.container, props.className)} style={props.style}>
      {progressState === ProgressState.Content && (students?.length !== 0 || searchFilter) && props.canSelect !== 'all-students-only' && (
        <GtnSearchBar label="navigation.search-student" value={searchFilter} onValueChanged={setSearchFilter} />
      )}

      <LoadingContainer
        className={styles.loadingContainer}
        state={progressState}
        emptyText={t('navigation.no-students')}
        style={{
          justifyContent: progressState === ProgressState.Content ? 'flex-start' : 'center',
        }}
      >
        <div className={styles.list}>
          {props.canSelect !== 'single-student-only' && !searchFilter && !!students?.length && (
            <ListItem
              className={classNames(styles.listItem, props.selectedUsers === null ? styles.selected : null)}
              onClick={() => {
                const canSelectMultiple = props.canSelect === 'multiple-students';
                let selectedUsers: MoodleUser[] | null = null;
                if (canSelectMultiple) {
                  selectedUsers = props.selectedUsers?.length === students?.length ? [] : students!;
                }
                props.onUserSelected?.(selectedUsers, canSelectMultiple);
              }}
              button
              disabled={props.canSelect === 'all-students-only'}
            >
              <ListItemIcon className={styles.allUsersIcon + ' ' + styles.icon}>
                <People />
              </ListItemIcon>
              <ListItemText primary={t('navigation.all-students')} />
            </ListItem>
          )}

          {props.canSelect !== 'all-students-only' && renderStudentList()}
        </div>
      </LoadingContainer>
    </div>
  );
}
