export interface MoodleUserRole {
	roleid: number;
	shortname: 'student' | 'editingteacher'; // TODO: are there more teacher roles?
}

export interface MoodleUser {
	id: number;
	fullname: string;
	firstname?: string;
	lastname?: string;
	email: string;
	profileimageurl: string;
	profileimageurlsmall: string;
	roles: MoodleUserRole[];
}

export class MoodleUserUtils {
	public static isStudent(user: MoodleUser) {
		return user.roles?.some((role) => role.shortname === 'student');
	}
}
