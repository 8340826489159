import { MoodleWebservice } from '@gtn/common/api/webservice/MoodleWebservice';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import classNames from 'classnames';
import { isToday } from 'date-fns';
import React, { useMemo, useState } from 'react';
import Split from 'react-split';
import styles from './LearningDiary.module.scss';

type LearningDiaryEntry = Pick<Awaited<ReturnType<MoodleWebservice['dakoraplus_get_learning_diary']>>[0], 'id' | 'timecreated' | 'text'>;

export const LearningDiary: React.FC = () => {
  const t = useAppTranslation();
  const moodleAPI = InjectionContainer.resolve(MoodleWebservice);
  const { data: diaryEntriesResponse, mutate: reloadDiaryEntries } = useAPI(moodleAPI.dakoraplus_get_learning_diary);

  const [selectedEntry, setSelectedEntry] = useState<LearningDiaryEntry | undefined>(undefined);

  const diaryEntries = useMemo(() => {
    let diaryEntries: LearningDiaryEntry[] = [];

    if (diaryEntriesResponse) {
      diaryEntries = [...(diaryEntriesResponse as LearningDiaryEntry[])];
      const hasEntryForToday = diaryEntries.some((entry) => isToday(new Date(entry.timecreated * 1000)));
      diaryEntries = diaryEntries.sort((a, b) => b.timecreated - a.timecreated);

      if (!hasEntryForToday) {
        diaryEntries.unshift({ id: 0, timecreated: new Date().getTime() / 1000, text: '' });
      }

      let selectedEntryCopy = diaryEntries.find((entry) => entry.id == selectedEntry?.id);
      if (selectedEntryCopy) {
        setSelectedEntry(selectedEntryCopy);
      } else {
        setSelectedEntry(diaryEntries[0]);
      }
    } else {
      diaryEntries.unshift({ id: 0, timecreated: new Date().getTime() / 1000, text: '' });
    }

    return diaryEntries;
  }, [diaryEntriesResponse]);

  const getDateText = (dateInSeconds: number) => {
    const date = new Date(dateInSeconds * 1000);
    return isToday(date) ? t('learning-diary.today') : date.toLocaleDateString();
  };

  const save = async () => {
    if (selectedEntry) {
      try {
        await moodleAPI.dakoraplus_save_learning_diary_entry({ id: selectedEntry.id, title: '', text: selectedEntry.text });
        reloadDiaryEntries();
      } catch (e) {
        GtnLogger.warn(e);
      }
    }
  };

  return (
    <Split className={classNames('split horizontal', styles.container)} direction="horizontal" sizes={[20, 80]} minSize={200} maxSize={[500, Infinity]} expandToMin={true}>
      <div className={styles.leftContainer}>
        <div className={styles.list}>
          {diaryEntries?.map((entry) => (
            <ListItem className={classNames(styles.listItem, entry.id === selectedEntry?.id ? styles.selected : null)} onClick={() => setSelectedEntry(entry)} button key={entry.id}>
              <ListItemText primary={getDateText(entry.timecreated)} style={{ wordBreak: 'break-all' }} />
            </ListItem>
          ))}
        </div>
      </div>
      <div className={styles.mainContentContainer}>
        {selectedEntry && (
          <>
            <div className={styles.selectedEntryHeader}>
              <h2>{getDateText(selectedEntry.timecreated)}</h2>
              <GtnButton label={t('save')} actionType="secondary" onClick={save} />
            </div>
            <div className={styles.selectedEntryText}>
              <textarea
                value={selectedEntry.text}
                placeholder={t('learning-diary.what-did-you-do')}
                onChange={(e) => {
                  setSelectedEntry({ ...selectedEntry, text: e.target.value });
                }}
              />
            </div>
          </>
        )}
      </div>
    </Split>
  );
};
