import React from 'react';
import { MSAuthManager } from '@gtn/app-common/microsoft/MSAuthManager';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import useAsyncEffect from 'use-async-effect';

export default function MSTeamsAuth() {
  const msAuthManager = InjectionContainer.resolve(MSAuthManager);

  useAsyncEffect(async () => {
    await msAuthManager.handleAuthResult();
  }, []);

  return <>Weiterleitung zu Microsoft Login...</>;
}
