import CourseStudentSelectorSidebar from '@gtn/app-common/components/app-navigation/course-student-selection/sidebar/CourseStudentSelectorSidebar';
import { useSelectedCourse } from '@gtn/app-common/store/app.store.hooks';
import { MoodleQuizAPI } from '@gtn/common/api/MoodleQuizAPI';
import { useAPI } from '@gtn/common/api/webservice/WebserviceHookUtils';
import LoadingContainer from '@gtn/common/components/loading-container/LoadingContainer';
import { useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useIsTeacher } from '@gtn/common/store/user/user.hooks';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { Quiz } from '@gtn/dakora/routes/quizzes/quiz/Quiz';
import { Spellcheck } from '@material-ui/icons';
import React from 'react';
import styles from './Quizzes.module.scss';

export function Quizzes() {
  const moodleQuizAPI = InjectionContainer.resolve(MoodleQuizAPI);
  const selectedCourse = useSelectedCourse();
  const quizDialog = useGtnDialog(Quiz);
  const isTeacher = useIsTeacher();

  const { data: quizzes, progressState } = useAPI(moodleQuizAPI.getQuizzes, [selectedCourse ? [selectedCourse.id] : undefined]);

  return (
    <>
      <CourseStudentSelectorSidebar selectionMode={isTeacher ? 'single-student-only' : 'course-only'}>
        <LoadingContainer state={progressState} className={styles.list}>
          {quizzes?.map((quiz) => (
            <div key={`quiz-${quiz.id}`} className={styles.quizItem} onClick={() => quizDialog.open({ quizId: quiz.id, quizName: quiz.name })}>
              <Spellcheck className={styles.icon} />
              <div className={styles.textContainer}>
                <h3>{quiz.name}</h3>
              </div>
            </div>
          ))}
        </LoadingContainer>
      </CourseStudentSelectorSidebar>

      <quizDialog.Component />
    </>
  );
}
