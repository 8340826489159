import classNames from 'classnames';
import React from 'react';
import { useAppConfig } from '@gtn/app-common/config/AppConfigHooks';
import { useAppCommonSelector, useAppDispatch } from '@gtn/app-common/store/app.store.hooks';
import { navigationActions } from '@gtn/app-common/store/navigation.state';
import { StyleProps } from '@gtn/common/components/StyleProps';
import styles from '@gtn/app-common/components/app-info/AppInfo.module.scss';
import { useGtnDialog } from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import AboutDialog from '@gtn/app-common/components/about-dialog/AboutDialog';
import { ServerInfoManager } from '@gtn/app-common/utils/ServerInfoManager';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { useUser } from '@gtn/common/store/user/user.hooks';

export default function AppInfo(props: StyleProps) {
  const t = useAppTranslation();
  const dispatch = useAppDispatch();
  const config = useAppConfig();
  const aboutDialog = useGtnDialog(AboutDialog);
  const serverInfo = InjectionContainer.resolve(ServerInfoManager).getServerInfo();
  const preferences = useAppCommonSelector((state) => state.preferences);
  const user = useUser();

  return (
    <>
      <div className={classNames(styles.buildInfo, props.className)} style={props.style}>
        <p>
          {config.theme.appName} v{config.version.name} ({config.buildTimeString.replace(/(^20|[:])/g, '').replace(' ', '.')})
          {user.isLoggedIn && (
            <>
              {preferences.moodleUrl && (
                <>
                  <br />
                  {preferences.moodleUrl.replace(/^https?:\/\//, '')}
                </>
              )}
              {serverInfo?.exacompversion && (
                <>
                  <br />
                  Exacomp: {serverInfo?.exacompversion}
                </>
              )}
            </>
          )}
        </p>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            dispatch(navigationActions.setNavigationDrawerOpened(false));
            aboutDialog.open();
          }}
        >
          {t('about.title')}
        </a>
      </div>
      <aboutDialog.Component />
    </>
  );
}
