import { ErrorMessage, Field, FieldAttributes } from 'formik';
import { TextField as MaterialTextField } from 'formik-material-ui';
import * as React from 'react';
import { StyleProps } from '@gtn/common/components/StyleProps';

export interface GtnTextFieldProps extends FieldAttributes<any> {
  disabled?: boolean;
  name: string;
  placeholder?: string;
}

export default function GtnTextField(props: GtnTextFieldProps & StyleProps) {
  return (
    <Field
      component={MaterialTextField}
      variant="outlined"
      fullWidth
      className={props.className}
      style={props.style}
      datetype="date"
      disabled={props.disabled ?? false}
      helperText={<ErrorMessage name={props.name} />}
      {...props}
    />
  );
}
