import { DEFAULT_APP_CONFIG } from '@gtn/app-common/config/default-app.config';
import { AppType, IAppConfig } from '@gtn/app-common/config/IAppConfig';
import de from '../i18n/de.json';
import en from '../i18n/en.json';

let pspdfLicenceKey = '';
if (window.location.hostname == 'dakoraplus.eu') {
  pspdfLicenceKey =
    'x7q9husybLWDA38Nb35Lz5cc-oxK5gGxJ4mixtrz8B1eoUaKomTxUag2HuhVUqSWj0fgaI7CHqs2eDAAhZhPyaFrYHiR3l_fB-Q4efVRrDHGFAq7UQAGttAuJcp2Z2lf8zVAWOuhXyHt2Xswey8jxRR-MkGLmHis1dAJnMeAnClnM7pRnSatk0zcD8TKElVbM-wV9EyKVHbIYIGstaeiO6XsGWcLvUPpdJLa7ncrWUTlTzlWy_abla-QTwT7aSJ3pOcamtFgl_2McqBdwaxxyehJCGMP3J_0wQBYT87eauSiiU8C96HDSTiAgVgWOrgsO6B1HAL3eqEbSeA4IAnrQ-wdTduNCE3sf22zxArznLLbRthk4Knidvg4kZqpQ_UbVVOjTD9bizwc5XPh2wZWGJo5P6LHYbOilsFv14Lwk2iWLMCCnwl32knMEzXzQFXSbY3yIMOezYpKinpA65SHiyuhWnM6LUkzr3aSxTtUHD306aQUgDk5k_Vmmks5hn96xTW8wlPkJHePBxcHhQJCYrKOlMrqDMRjcYGD3SASTB020QiKSlxescRj39otUBOqGFOHj6rUBdOWY6Y59bTfqA==';
} else if (window.location.hostname == 'dev.dakoraplus.eu') {
  pspdfLicenceKey =
    'Dmg74yl5iuo0OQZj3YtooUxmTaTR_9PqXFsWKOyZvucA2aXxcjkel_fY_t80A_FiupZCRUu5GHJPT964X2pjFL7IE4qDBpmdzWJoDmMDsQmEJpBJPgFBmWDacapoXcQ7in7ZKIHkl3nOInz3RcOQLs92qwFDPUccRxpNSvFRye27b3j2cJdXHCyFy1g4s0zKR8MFUlJ_fOAF5FO-5xO_T8U_71jy_UPG9fBG6UOqYsPyAdQr4s2xuDUY5ckCpHIKSnivW_vXMyRl43k3WGYC2BXQlkf-lpG9zPZLoIvMmV4-eALsTCPxuNZ35nRXYcg08WAI60x-vsm5dm_c-9bvFhzMup5Cx5XzrroOb_QZp50LVhv9BJOl5Qu6Tb9h1KFAyvZxK50FaaV0Z86aPIBfCKbwo47t9hIv0chqKAWJOYGPieddb0fJPXVZaS5PTFaB_F8_9nSrNHXXPP4QVXryCwBUE1GoSU5OUWVzaCmqwpvO5UqEkp03P8YsnNEvuiIJfAqjVPuuKpAeguJ-FN52gAO8gMWwA461ty79s9D7-cBTOc1MhVhs7mjuZuKIbXZuyMALYdNbRpV9h7oGwSVV-_OnDLaaJfnBC0IkQMukwyA=';
} else if (window.location.hostname == 'localhost') {
  pspdfLicenceKey =
    'a9wvmllPhYkSA1ftRaed0z27gyt-az41z-cCwIoGohC5ieMkIU1QwcKAt723sEzfnag0dxw3A91qPP_N06VAWXjfHCWzswEtARj-WRqKcKkMo9KjMGOeRDUSHV4u3AY-06JDOonTfk3BD1wI_rokUtW8Bol9QlvUj8GmtuN56-PQZCj12lK_n-azGzZtxphCVZ9GCxUzh3mT75uqnBXYzqWV80OJ4zXJOZ0PvrLNbpi4TG2KYTwTdpyYS-plT55WRbCWFJtdOapvEaD_VJ9j3rLUOyMSDrI9xV4HInrbb1Av6cmJ8CqKC_FcYO-HD4mp9yfvgXNMf3djz-dVXcivusadzjREolZVJpLqCXETKZpwSqEDLPrIaLLjFSGUfXSvPfNoaBH7ATQ9kQdC-pCuR_KuZLOElbJql42-FMnQNmHTkNDgxZWUCAja1b7UvC69uTzTFjcBsNvTPmnuX6Cao4ky_4ZA5ThgJYsD0wwh7jm--5_Wo1W5h_OK0NhupB2HW2md2a1HKwuwm5DUipCptA7-qdgDvcTRpJe-Vucjco4UX5BOYoinrtr9RyNcw-Spo7yjD1xnNv_xkG6BsR15gg==';
} else {
  // empty value = evaluation license
}

export const DEFAULT_DAKORA_CONFIG: IAppConfig = {
  ...DEFAULT_APP_CONFIG,
  appType: AppType.DAKORA,
  minRequiredExacompVersion: 2021062200,
  pspdfLicenceKey,
  i18n: {
    ...DEFAULT_APP_CONFIG.i18n,
    translations: {
      de,
      en,
    },
  },
  theme: {
    appName: 'DAKORA+',
    colors: {
      primary: '#1C55C5',
      primaryLight: '#E8EFFB',
      text1OnPrimary: '#fff',

      secondary: '#FFB22D',
      text1OnSecondary: 'rgba(0,0,0,0.85)',

      error: '#f44336',
      text1OnError: '#fff',

      input: '#E3E5E7',
      text1OnInput: '#79797A',

      tertiaryButton: '#ABB6BE',
      text1OnTertiaryButton: 'white',

      foreground: '#fff',
      text1OnForeground: '#292929',
      text2OnForeground: '#767676',

      background: '#F4F4F4',
      text1OnBackground: 'rgba(0, 0, 0, 0.87)',
      text2OnBackground: 'rgba(0, 0, 0, 0.54)',
    },
  },
};
