import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import classNames from 'classnames';
import { Field } from 'formik';
import React, { ReactNode } from 'react';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import { StyleProps } from '@gtn/common/components/StyleProps';
import { nl2br } from '@gtn/common/components/utilities';
import styles from '@gtn/common/components/forms/gtn-select/GtnSelect.module.scss';

export const GTN_SELECT_DEFAULT_OPTION = '_default_';

export interface GtnSelectOption {
  value?: string | number;
  displayValue: string | ReactNode;
  backgroundColor?: string;
  color?: string;
}

export interface GtnSelectProps extends StyleProps {
  name: string;
  label: string | ReactNode;
  disableLabelAsDefaultOption?: boolean;
  disabled?: boolean;
  options: GtnSelectOption[];
  value?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  onChange?: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

export function GtnSelect(props: GtnSelectProps) {
  const t = useAppTranslation();
  const variant = props.variant ?? 'standard';

  function renderSelect(field?: any, value?: any, selectedOption?: GtnSelectOption, labelId?: string) {
    return (
      <Select
        {...field}
        label={props.disableLabelAsDefaultOption ? props.label : undefined}
        labelId={labelId}
        value={value}
        variant={variant}
        disabled={props.disabled}
        onChange={(e) => {
          props.onChange?.(e);
          field.onChange(e);
        }}
        className={classNames({ [styles.bubbleSelect]: variant === 'standard' }, props.className)}
        style={{
          backgroundColor: selectedOption?.backgroundColor,
          color: selectedOption?.color,
          borderColor: selectedOption?.backgroundColor,
          ...props.style,
        }}
      >
        {props.disableLabelAsDefaultOption !== true && <MenuItem value={GTN_SELECT_DEFAULT_OPTION}>{typeof props.label === 'string' ? nl2br(props.label) : props.label}</MenuItem>}

        {props.options.map((option) => (
          <MenuItem value={option.value} style={{ color: option.backgroundColor }}>
            {typeof option.displayValue === 'string' ? t(option.displayValue) : option.displayValue}
          </MenuItem>
        ))}
      </Select>
    );
  }

  return (
    <Field
      name={props.name}
      children={({ field }) => {
        let value = field.value ?? props.value;

        if (props.disableLabelAsDefaultOption !== true) {
          if (value === null || value === undefined || value === '' || value === -1 || value === '-1') {
            value = GTN_SELECT_DEFAULT_OPTION;
          }
        }
        const selectedOption = props.options.find((option) => String(option.value) === String(value));

        if (props.disableLabelAsDefaultOption) {
          const labelId = `label-${props.name}`;
          return (
            <FormControl fullWidth>
              <InputLabel id={labelId} style={{ marginLeft: 12, marginTop: 6 }}>
                {props.label}
              </InputLabel>
              {renderSelect(field, value, selectedOption, labelId)}
            </FormControl>
          );
        }
        return <>{renderSelect(field, value, selectedOption)}</>;
      }}
    />
  );
}
