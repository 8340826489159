import GtnDrawer from '@gtn/common/components/navigation/gtn-drawer/GtnDrawer';
import styles from '@gtn/common/components/navigation/gtn-navigation-drawer/GtnNavigationDrawer.module.scss';
import { NavigationItem } from '@gtn/common/components/navigation/NavigationItem';
import Profile from '@gtn/common/components/profile/Profile';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { NavLink } from 'react-router-dom';

export interface GtnNavigationDrawerProps {
  primaryItems: NavigationItem[];
  secondaryItems?: NavigationItem[];
  infoText?: string | React.ReactElement;

  isOpen: boolean;
  onClose: () => void;

  side?: 'left' | 'right';
  variant?: 'permanent' | 'persistent' | 'temporary';

  footer?: React.ReactNode;
}

export default function GtnNavigationDrawer(props: GtnNavigationDrawerProps) {
  const Link = React.forwardRef((linkProps: { to: string }, linkRef: any) => (
    <NavLink {...linkProps} ref={linkRef} onClick={() => props.onClose()} activeClassName={styles.selected} draggable={false} />
  ));

  function renderItems(items?: NavigationItem[]) {
    return items
      ?.filter((i) => i.hide !== true)
      .map((item) => {
        const itemContent = (
          <>
            {item.icon && <ListItemIcon style={{ minWidth: 38 }}>{item.icon}</ListItemIcon>}
            <ListItemText primary={item.title} />
          </>
        );

        if (item.href) {
          return (
            <ListItem className={styles.listItem} component={Link} to={item.href} button key={item.title}>
              {itemContent}
            </ListItem>
          );
        } else {
          return (
            <ListItem className={styles.listItem} onClick={item.onClick} button key={item.title}>
              {itemContent}
            </ListItem>
          );
        }
      });
  }

  const drawerContent = (
    <>
      <Profile />

      <div className={styles.itemsList}>
        {renderItems(props.primaryItems)}

        <div className={styles.itemsListSpace} />

        {renderItems(props.secondaryItems)}
      </div>

      {props.infoText && <p className={styles.infoText}>{props.infoText}</p>}
      {props.footer}
    </>
  );

  return (
    <GtnDrawer className={styles.navigationDrawer} isOpen={props.isOpen} side={props.side ?? 'left'} variant={props.variant} onClose={props.onClose}>
      {drawerContent}
    </GtnDrawer>
  );
}
