import { Popover } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import styles from './GtnChooser.module.scss';

export interface GtnChooserOption<T = string | number> {
  id: T;
  title: string | ReactNode;
  disabled?: boolean;
  data?: any;
}

export interface GtnChooserProps {
  label: string | ReactNode;
  disabled?: boolean;
  options: GtnChooserOption[];
  selected?: GtnChooserOption;
  onSelected?: (option: GtnChooserOption) => void;
}

export function GtnChooser(props: GtnChooserProps) {
  const [popoverAnchor, setPopoverAnchor] = React.useState<HTMLElement | null>(null);

  return (
    <>
      <div className={styles.header} onClick={(e) => setPopoverAnchor(e.currentTarget)}>
        <div>
          <p>{props.label}</p>
          <h3>{props.selected?.title ?? props.label}</h3>
        </div>
        <ArrowDropDown />
      </div>

      <Popover
        open={popoverAnchor != null}
        onClose={() => setPopoverAnchor(null)}
        anchorEl={popoverAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {props.options?.map((option) => (
          <div
            key={`chooser-option-${option.id || option.title}`}
            className={classNames(styles.courseItem, {
              [styles.selected]: props.selected?.id === option.id,
              [styles.disabled]: option?.disabled,
            })}
            onClick={() => {
              props.onSelected?.(option);
              setPopoverAnchor(null);
            }}
          >
            <h3>{option.title}</h3>
          </div>
        ))}
      </Popover>
    </>
  );
}
