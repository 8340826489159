import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Course } from '@gtn/common/api/model/Course';
import { MoodleUser } from '@gtn/common/api/model/moodle-core/MoodleUser';
import { ErrorTrackingService } from '@gtn/app-common/utils/error-tracking/ErrorTrackingService';
import { ErrorLogLevel } from '@gtn/app-common/utils/error-tracking/IErrorTracker';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';

export interface NavigationState {
  navigationDrawerOpened: boolean;
  courseStudentDrawerOpened: boolean;
  notificationsDrawerOpened: boolean;

  courses: Course[];
  coursesLoaded: boolean;
  selectedCourse?: Course;
  selectedCourseStudents?: MoodleUser[];
  selectedStudent?: MoodleUser | MoodleUser[] | null; // null means all students
  loginTutorialSeen: boolean;
}

const initialState: NavigationState = {
  courses: [],
  coursesLoaded: false,
  navigationDrawerOpened: false,
  courseStudentDrawerOpened: false,
  notificationsDrawerOpened: false,
  loginTutorialSeen: false,
};

export const navigationFeatureKey = 'navigation';

const navigationSlicer = createSlice({
  name: navigationFeatureKey,
  initialState,
  reducers: {
    setNavigationDrawerOpened: (state, action: PayloadAction<boolean>): NavigationState => {
      return {
        ...state,
        navigationDrawerOpened: action.payload,
      };
    },

    setCourses: (state, action: PayloadAction<Course[]>): NavigationState => {
      const selectedCourse = state.selectedCourse ? action.payload.find((c) => c.id === state.selectedCourse?.id) : undefined;
      return {
        ...state,
        selectedCourse,
        courses: action.payload,
        coursesLoaded: true,
      };
    },

    setCourseStudentDrawerOpened: (state, action: PayloadAction<boolean>): NavigationState => {
      return {
        ...state,
        courseStudentDrawerOpened: action.payload,
        navigationDrawerOpened: false,
      };
    },

    setNotificationsDrawerOpened: (state, action: PayloadAction<boolean>): NavigationState => {
      return { ...state, notificationsDrawerOpened: action.payload };
    },

    setSelectedCourse: (state, action: PayloadAction<Course | undefined>): NavigationState => {
      InjectionContainer.resolve(ErrorTrackingService).trackLog('Selected course changed: ' + action.payload?.fullname, ErrorLogLevel.Info);
      // reset student selection as well
      return {
        ...state,
        selectedCourse: action.payload,
        selectedStudent: action.payload ? null : undefined,
      };
    },

    setSelectedCourseStudents: (state, action: PayloadAction<MoodleUser[] | undefined>): NavigationState => {
      return {
        ...state,
        selectedCourseStudents: action.payload,
      };
    },

    setSelectedStudent: (state, action: PayloadAction<{ student: MoodleUser | MoodleUser[] | undefined | null; multiSelection?: boolean } | undefined>): NavigationState => {
      const newStudent = action.payload?.student;
      let newStudentState: MoodleUser | MoodleUser[] | null | undefined = action.payload?.student;

      // InjectionContainer.resolve(ErrorTrackingService).trackLog('Selected student changed: ' + newStudent?.fullname, ErrorLogLevel.Info);

      if (Array.isArray(newStudent)) {
        newStudentState = newStudent;
      } else {
        if (action.payload?.multiSelection && newStudent) {
          if (Array.isArray(state.selectedStudent)) {
            if (state.selectedStudent.some((s) => s.id === newStudent?.id)) {
              newStudentState = state.selectedStudent.filter((s) => s.id !== newStudent?.id);
            } else {
              newStudentState = [...state.selectedStudent, newStudent];
            }
          } else if (state.selectedStudent) {
            // @ts-ignore
            newStudentState = [state.selectedStudent, newStudent];
          } else {
            newStudentState = [newStudent];
          }
        }
      }

      return { ...state, selectedStudent: newStudentState };
    },

    setLoginTutorialSeen: (state): NavigationState => {
      return { ...state, loginTutorialSeen: true };
    },

    reset: (state): NavigationState => {
      return { ...initialState, loginTutorialSeen: state.loginTutorialSeen };
    },
  },
});

export const navigationActions = navigationSlicer.actions;
export const navigationReducer = navigationSlicer.reducer;
