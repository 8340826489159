import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MainContent } from '@gtn/common/components/MainContent';

export default function NoMatch() {
  const location = useLocation();

  return (
    <MainContent>
      <h3>
        Seite nicht gefunden <code>{location.pathname}</code>
        <br />
        <Link to="/">Home</Link>
      </h3>
    </MainContent>
  );
}
