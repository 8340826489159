import React from 'react';
import { LoadingIndicatorInline } from '@gtn/common/components/LoadingIndicator';
import GtnDialog, {
  DialogProps,
} from '@gtn/common/components/navigation/gtn-dialog/GtnDialog';

const Whiteboard = React.lazy(
  () =>
    import(
      /* webpackChunkName: 'whiteboard' */ '@gtn/common/components/whiteboard/Whiteboard'
    )
);
const MIN_ZOOM = 0.5;
const MAX_ZOOM = 2.5;
const AVAILABLE_COLORS = ['black', '#D32F2F', '#4CAF50', '#1976D2', '#FFEB3B'];
const AVAILABLE_LINE_WIDTHS = [1, 3, 5, 8, 10];

export interface AnnotateImageDialogProps {
  backgroundImageUrl?: string;
  saveImageFileName?: string;
  onSavedAsImage?: (imageFile: File) => void;
}

function AnnotateImageDialog(props: AnnotateImageDialogProps & DialogProps) {
  return (
    <GtnDialog {...props} fullscreen="always">
      <React.Suspense fallback={<LoadingIndicatorInline />}>
        <Whiteboard
          {...props}
          minZoomFactor={MIN_ZOOM}
          maxZoomFactor={MAX_ZOOM}
          availableColors={AVAILABLE_COLORS}
          availableLineWidths={AVAILABLE_LINE_WIDTHS}
        />
      </React.Suspense>
    </GtnDialog>
  );
}

export default AnnotateImageDialog;
