import { AppConfigManager } from '@gtn/app-common/config/AppConfigManager';
import { getStore } from '@gtn/app-common/utils/store';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import { ConfigManagerToken } from '@gtn/common/config/ConfigManager';
import { TranslationManager } from '@gtn/common/i18n/TranslationManager';
import { preferencesActions } from '@gtn/common/store/preferences/preferences.state';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { Utils } from '@gtn/common/utils/Utils';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import React, { useEffect, useRef, useState } from 'react';

interface PdfViewerProps {
  fileUrl: string;
  fileName?: string;
  onSave?: (pdfContent: ArrayBuffer, originalFileName?: string) => void;
}

export async function initPspdfInstance(PSPDFKit, instance) {
  const store = getStore();

  // restore old annotation presets
  let annotationPresets = Utils.deepClone(store.getState().preferences.pspdfkitAnnotationPresets || {});
  for (let key in annotationPresets) {
    annotationPresets[key] = PSPDFKit.AnnotationPresets.fromSerializableObject(annotationPresets[key]);
  }

  instance.setAnnotationPresets((presets) => {
    for (let key in annotationPresets) {
      presets[key] = {
        ...presets[key],
        ...annotationPresets[key],
      };
    }
    return presets;
  });
  GtnLogger.log('annotationPresets loaded:', annotationPresets);

  // save changed presets to session
  instance.addEventListener('annotationPresets.update', (event) => {
    annotationPresets[event.currentPreset] = { ...event.currentPresetProperties, ...event.newPresetProperties };

    // convert all presets (for all tools), to serializable format
    const presetsToSave = {};
    for (const key in annotationPresets) {
      presetsToSave[key] = PSPDFKit.AnnotationPresets.toSerializableObject(annotationPresets[key]);
    }

    console.log({ presetsToSave });

    store.dispatch(
      preferencesActions.set({
        pspdfkitAnnotationPresets: presetsToSave,
      })
    );
  });

  // always switch to annotation tool
  // instance.setViewState((viewState) => {
  //   instance.setCurrentAnnotationPreset('ink');
  //   return viewState.set('interactionMode', PSPDFKit.InteractionMode.INK);
  // });
}

export default function PdfViewer(props: PdfViewerProps) {
  const t = useAppTranslation();
  const containerRef = useRef(null);
  const configManager = InjectionContainer.resolve<AppConfigManager>(ConfigManagerToken);
  const translationManager = InjectionContainer.resolve(TranslationManager);
  const [pspdfInstance, setPspdfInstance] = useState<any>();

  useEffect(() => {
    const container = containerRef.current;
    let instance, PSPDFKit;
    (async function () {
      PSPDFKit = await import('pspdfkit');
      instance = await PSPDFKit.load({
        licenseKey: configManager.getConfig().pspdfLicenceKey,
        // Container where PSPDFKit should be mounted.
        container,
        locale: translationManager.getLanguage(),
        document: props.fileUrl,
        enableClipboardActions: true,
        enableHistory: true,
        // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
        baseUrl: `${window.location.protocol}//${window.location.host}${Utils.normalizeBasePath(configManager.getConfig().basePath)}/`,
      });
      setPspdfInstance(instance);
      await initPspdfInstance(PSPDFKit, instance);

      instance.setToolbarItems((items) => {
        // this is a spacer placeholder
        // the real button gets positioned absolute above it
        const placeholder = document.createElement('div');
        placeholder.style.width = '125px';
        items.push({
          type: 'custom',
          id: 'save-button-placeholder',
          node: placeholder,
        });

        // link tool entfernen, da Benutzung nicht intuitiv
        // https://zsl-bw.openproject.com/projects/dakora-plus/work_packages/396
        items = items.filter(item => item.type != 'link');

        return items;
      });
    })();

    return () => PSPDFKit && PSPDFKit.unload(container);
  }, []);

  async function save() {
    if (pspdfInstance) {
      const result = await pspdfInstance.exportPDF();
      if (result) {
        props.onSave?.(result, props.fileName);
      }
    }
  }

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
      <div style={{ position: 'absolute', right: 4, top: 3 }}>
        <GtnButton actionType="primary" label={t('submit-item.save')} onClick={save} disabled={pspdfInstance == null} />
      </div>
      <div ref={containerRef} style={{ width: '100%', flex: '1' }} />
    </div>
  );
}
