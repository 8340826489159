// assignfeedback_exapdf version: 2023053100

// https://stackoverflow.com/questions/49580725/is-it-possible-to-restrict-typescript-object-to-contain-only-properties-defined/57117594#57117594
// ensure that an object passed to a function does not contain any properties beyond those in a specified (object) type.
type Impossible<K extends keyof any> = {
  [P in K]: never;
};
type NoExtraProperties<T, U extends T = T> = U extends Array<infer V>
  ? NoExtraProperties<V>[]
  : U & Impossible<Exclude<keyof U, keyof T>>;

export interface assignfeedback_exapdf_get_file_infos_parameters {
  fileid: string;
}

export interface assignfeedback_exapdf_get_file_infos_returns {
  submission_file_url: string;
  annotations: string;
  annotations_changed: boolean;
}

export interface assignfeedback_exapdf_save_annotations_parameters {
  fileid: string;
  annotations: string;
}

export interface assignfeedback_exapdf_save_annotations_returns {
  success: boolean; // status
}

export interface assignfeedback_exapdf_save_annotated_parameters {
  fileid: string;
  draftitemid: number;
}

export interface assignfeedback_exapdf_save_annotated_returns {
  success: boolean; // status
}

// Idea from: https://www.typescriptlang.org/docs/handbook/mixins.html
type GConstructor<T = {}> = new (...args: any[]) => T;
type WebserviceBase = GConstructor<{ callWebservice<T = any>(wsfunction: string, payload: any): Promise<T> }>;

export default function WebserviceDefinitions<TBase extends WebserviceBase>(Base: TBase) {
  return class Extended extends Base {
    /**
     * 
     */
    public assignfeedback_exapdf_get_file_infos = async <T extends assignfeedback_exapdf_get_file_infos_parameters>(params: NoExtraProperties<assignfeedback_exapdf_get_file_infos_parameters, T>): Promise<assignfeedback_exapdf_get_file_infos_returns> =>
      this.callWebservice('assignfeedback_exapdf_get_file_infos', params);
  
    /**
     * 
     */
    public assignfeedback_exapdf_save_annotations = async <T extends assignfeedback_exapdf_save_annotations_parameters>(params: NoExtraProperties<assignfeedback_exapdf_save_annotations_parameters, T>): Promise<assignfeedback_exapdf_save_annotations_returns> =>
      this.callWebservice('assignfeedback_exapdf_save_annotations', params);
  
    /**
     * 
     */
    public assignfeedback_exapdf_save_annotated = async <T extends assignfeedback_exapdf_save_annotated_parameters>(params: NoExtraProperties<assignfeedback_exapdf_save_annotated_parameters, T>): Promise<assignfeedback_exapdf_save_annotated_returns> =>
      this.callWebservice('assignfeedback_exapdf_save_annotated', params);
  }
}
