import { LoginService } from '@gtn/app-common/api/LoginService';
import AppInfo from '@gtn/app-common/components/app-info/AppInfo';
import { useAppConfig } from '@gtn/app-common/config/AppConfigHooks';
import { MSTeamsManager } from '@gtn/app-common/microsoft/MSTeamsManager';
import { CommonRoutingParams, CommonRoutingPaths } from '@gtn/app-common/routes/AppCommonRouting';
import styles from '@gtn/app-common/routes/login/Login.module.scss';
import { useAppDispatch } from '@gtn/app-common/store/app.store.hooks';
import { GtnButton } from '@gtn/common/components/forms/gtn-button/GtnButton';
import { preferencesActions } from '@gtn/common/store/preferences/preferences.state';
import { useUser } from '@gtn/common/store/user/user.hooks';
import { ThemeManager } from '@gtn/common/theme/ThemeManager';
import { useAllParams, useAppTranslation, useHistory } from '@gtn/common/utils/HookUtils';
import InjectionContainer from '@gtn/common/utils/InjectionContainer';
import { GtnLogger } from '@gtn/common/utils/logger/GtnLogger';
import { Utils } from '@gtn/common/utils/Utils';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import React from 'react';
import useAsyncEffect from 'use-async-effect';

export default function MSTeamsTab() {
  const msTeamsManager = InjectionContainer.resolve(MSTeamsManager);
  const themeManager = InjectionContainer.resolve(ThemeManager);
  const loginService = InjectionContainer.resolve(LoginService);

  const config = useAppConfig();
  const t = useAppTranslation();
  const user = useUser();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const params = useAllParams();
  const moodleUrl = params[CommonRoutingParams.MOODLE_URL] || config.moodleUrl;

  useAsyncEffect(async () => {
    if (user.isLoggedIn) {
      history.push(CommonRoutingPaths.HOME);
    }
  }, [user.isLoggedIn]);

  const loginWithMSTeams = async () => {
    loginService.saveInitialQueryParams();

    dispatch(preferencesActions.set({ moodleUrl }));

    msTeamsManager.showLoginDialog(
      () => {
        window.location.reload();
      },
      (reason) => {}
    );
  };

  const loginWithUsernamePassword = async () => {
    loginService.saveInitialQueryParams();

    dispatch(preferencesActions.set({ moodleUrl }));

    try {
      const loginUrl = await loginService.getLoginUrl(moodleUrl, `${document.location.origin + Utils.normalizeBasePath(config.basePath ?? '')}/${CommonRoutingPaths.LOGIN}`);
      document.location.href = loginUrl;
      return;
    } catch (e) {
      GtnLogger.error(e);
    }
  };

  return (
    <Grid container component="main" className={styles.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={styles.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={styles.paper}>
          <Avatar className={styles.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <h2>{t('login.title', { appName: config.theme.appName })}</h2>

          <GtnButton label={t('login.sign-in-ms-teams')} actionType="primary" className={styles.loginButton} onClick={loginWithMSTeams} />

          {!Utils.isEduvidual(moodleUrl) && ( //hide on eduvidual since it doesn't allow embedding iframes
            <a href="#" onClick={loginWithUsernamePassword} style={{ marginTop: '12px' }}>
              {t('login.sign-in-ms-teams-username-password')}
            </a>
          )}

          <img className={styles.logo} src={themeManager.theme?.logo?.imageWithName ?? 'img/logo-with-name.png'} alt="Logo" />

          <div className={styles.spacing} />
          <AppInfo />
        </div>
      </Grid>
    </Grid>
  );
}
