import { ExampleAndItemResponse, ItemStatus } from '@gtn/app-common/api/model/ExampleAndItemResponse';
import { ITEM_STATUS_DISPLAY_VALUES_STUDENT } from '@gtn/app-common/components/example-item-list/ItemStatusDisplay';
import styles from '@gtn/app-common/components/example-item-list/large-item-list/LargeItemList.module.scss';
import CollaboratorsOverview from '@gtn/app-common/components/workbook/collaborators-overview/CollaboratorsOverview';
import { useUnreadItemNotifications } from '@gtn/common/api/MoodleCoreAPIHooks';
import LinkPreview from '@gtn/common/components/link-preview/LinkPreview';
import { useAppTranslation } from '@gtn/common/utils/HookUtils';
import { Utils } from '@gtn/common/utils/Utils';
import { Accordion, AccordionDetails, AccordionSummary, Paper } from '@material-ui/core';
import GeoPattern from 'geopattern';
import React from 'react';

export interface LargeItemListProps {
  items?: ExampleAndItemResponse[];
  onItemClicked?: (entry: ExampleAndItemResponse) => void;
}

export function LargeItemList(props: LargeItemListProps) {
  const t = useAppTranslation();
  const { hasUnreadNotifications } = useUnreadItemNotifications();

  let groupedItems;
  if (props.items) {
    const sortedItems = props.items.sort((a, b) => Number(!hasUnreadNotifications(a.item?.id)) - Number(!hasUnreadNotifications(b.item?.id)));
    groupedItems = Utils.groupBy(sortedItems, (entry) => {
      const groupName = !entry.example ? t.withRole('workbook.type-filter.own-items') : entry.subjecttitle;
      if (hasUnreadNotifications(entry.item?.id)) {
        return t('workbook.new-changes-in-course', {
          name: groupName,
        }) as string;
      }
      return groupName;
    });
  }

  const renderListGroup = (groupName: string, entries: ExampleAndItemResponse[]) => {
    return (
      <Accordion classes={{ root: styles.listGroup }} TransitionProps={{ unmountOnExit: true }} defaultExpanded={true}>
        <AccordionSummary
          classes={{
            root: styles.listGroupSummary,
            content: styles.listGroupSummaryContent,
          }}
        >
          {groupName}
        </AccordionSummary>
        <AccordionDetails classes={{ root: styles.listGroupDetails }}>
          {entries.map(renderListEntry)}
          <div style={{ width: '32%' }}></div>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderListEntry = (entry: ExampleAndItemResponse) => {
    const status = entry.item ? (entry.status as ItemStatus) || ItemStatus.INPROGRESS : ItemStatus.NEW;

    const previewImageUrl =
      entry.example?.taskfiles?.find((file) => file.type.match(/^image\//))?.url || // task files
      entry.item?.studentfiles?.find((file) => file.mimetype.match(/^image\//))?.file || // student solution
      (Utils.isImagePath(entry.example?.externaltask) ? entry.example?.externaltask : false) || // workaround for ePop
      GeoPattern.generate(entry.example?.title || entry.item?.name).toDataUri();

    return (
      <Paper key={entry.item?.id} className={styles.listEntry} onClick={() => props.onItemClicked?.(entry)}>
        <div className={styles.listEntryHeader}>
          {hasUnreadNotifications(entry.item?.id) && <div className={styles.listEntryNotificationIndicator} />}

          <div style={{ flex: 1 }} />

          {renderStateIndicator(status)}
        </div>

        <div className={styles.listEntryImage} style={{ ['--aspect-ratio' as any]: 16 / 9 }}>
          <img src={previewImageUrl} alt="" />
        </div>

        <div className={styles.listEntryTextContainer}>
          <p>{entry.example?.title || entry.item?.name}</p>
        </div>

        {!!entry.item?.extractedStudentCommentUrls?.length && (
          <div className={styles.linkPreviewContainer}>
            {entry.item.extractedStudentCommentUrls.map((url) => (
              <LinkPreview url={url} key={url} />
            ))}
          </div>
        )}

        <div className={styles.listEntryFooter}>
          {entry.subjecttitle} / {entry.topictitle}
          {entry.item && (
            <div>
              <CollaboratorsOverview className={styles.listEntryCollaborators} users={[entry.item.owner, ...(entry.item.collaborators ?? [])]} visibleNamesCount={1} />

              {entry.timemodified && <div>{Utils.toDate(entry.timemodified).toLocaleDateString()}</div>}
            </div>
          )}
        </div>
      </Paper>
    );
  };

  const renderStateIndicator = (status: ItemStatus) => {
    const itemStatusDisplay = ITEM_STATUS_DISPLAY_VALUES_STUDENT.find((statusDisplay) => statusDisplay.statusMapping === status);

    if (!itemStatusDisplay) {
      return null;
    }

    return (
      <div className={styles.listEntryStateIndicatorContainer} style={{ color: itemStatusDisplay.color }}>
        <div />
        <p style={{ color: itemStatusDisplay.color }}>{t(itemStatusDisplay.textResId)}</p>
      </div>
    );
  };

  return <>{Object.keys(groupedItems).map((groupName) => renderListGroup(groupName, groupedItems[groupName]))}</>;
}
